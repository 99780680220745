import React from 'react';
import { Route } from 'react-router';

import { UserRoles } from 'common/constants';

import { RouteConfigOptions as RouteConfig } from './routes.interface';
import { RouteType } from './routes.constants';
import { PrivateRoute } from './private-route.component';
import { ProtectedRoute } from './protected-route.component';

interface Props {
  authenticated: boolean;
  routes: RouteConfig[];
  userRole: UserRoles;
}

export const Routes: React.FC<Props> = ({
  authenticated,
  routes,
  userRole,
}) => (
  <>
    {routes.map((route) => {
      if (route.type === RouteType.PRIVATE) {
        return (
          <PrivateRoute
            key={route.path}
            authenticated={authenticated}
            route={route}
            exact={!!route.exact}
          />
        );
      }

      if (route.type === RouteType.PROTECTED) {
        return (
          <ProtectedRoute
            key={route.path}
            authenticated={authenticated}
            route={route}
            userRole={userRole}
            exact={!!route.exact}
          />
        );
      }

      return (
        <Route
          key={route.path}
          path={route.path}
          component={route.component}
          exact={!!route.exact}
        />
      );
    })}
  </>
);
