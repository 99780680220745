import { FinanceBulkActionButtonMapByStatus } from 'types';

import { UserRoles } from './roles.constants';
import { FINANCE_BUTTONS } from './finance-buttons.constant';

export const FINANCE_BULK_ACTION_BUTTON_MAP_BY_STATUS: FinanceBulkActionButtonMapByStatus = {
  due_for_reimbursement: [
    {
      actionButton: FINANCE_BUTTONS.MARK_AS_REIMBURSED,
      allowedRoles: [
        UserRoles.FINANCE,
      ],
    },
  ],
  due_for_disbursement: [
    {
      actionButton: FINANCE_BUTTONS.MARK_AS_DISBURSED,
      allowedRoles: [
        UserRoles.FINANCE,
      ],
    },
  ]
}
