/* eslint-disable no-restricted-globals */
import React from 'reactn';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

import { ColorType } from 'types';
import { Dialog, Button, CreatableAutoComplete } from 'common/components';
import { OrderReturnType } from 'common/services/apis/v3/swipe-rx-pt';

import { ValueType, OptionTypeBase } from 'react-select';
import { S } from './order-return.styles';
import { returnItemOptions } from './order-return-form.config';

interface Props {
  form: {
    itemsToReturn: {
      [key: string]: {
        selected: boolean;
        quantity: number;
        reason: string;
      };
    };
    handleCheckBoxChange: (id: number) => void;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
    handleReasonChange: (reason: string, id: number) => void;
    selectedReason: ValueType<OptionTypeBase> | null;
    setSelectedReason: (reason: ValueType<OptionTypeBase> | null) => void;
  };
  returnType: {
    type: string;
    handleReturnTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
  data: {
    orderId: number;
    orderDetails: any[];
    orderItems: { [key: string]: any };
  };
  steps: { step: number; nextStep: () => void; clearSteps: () => void };
}

interface AutocompletOption {
  value: string;
  label: string;
}

export const OrderReturnItemsModal: React.FC<Props> = ({
  form: {
    itemsToReturn,
    handleCheckBoxChange,
    handleInputChange,
    handleReasonChange,
    selectedReason,
    setSelectedReason,
  },
  returnType: { type },
  steps: { step, nextStep, clearSteps },
  data: { orderDetails, orderItems },
}) => {
  const selected = Object.keys(itemsToReturn)
    .map((id) => itemsToReturn[id])
    .filter(({ selected }) => selected);
  const isDisabled =
    selected.length > 0
      ? selected.some(({ quantity, reason }) => {
          return !quantity || !reason || reason === '';
        })
      : true;

  const handleAutocompleteChange = (option: AutocompletOption, id: number): void => {
    handleReasonChange(option.value, Number(id));
    setSelectedReason(option);
  };

  const handleAutocompleteInputChange = (inputValue: string, id: number): void => {
    if (!inputValue) return;
    handleReasonChange(inputValue, Number(id));
  };

  const renderContent = (): JSX.Element => (
    <>
      <S.Container style={{ paddingTop: 0 }}>
        <S.Text>Please select returned item(s)</S.Text>
      </S.Container>
      {Object.keys(itemsToReturn).map((id) => {
        if (orderItems[id] && orderItems[id].quantity === 0) {
          return null;
        }

        return (
          <S.Container key={id}>
            <Checkbox
              style={{ alignSelf: 'flex-start' }}
              color="primary"
              checked={itemsToReturn[id].selected}
              onChange={() => handleCheckBoxChange(Number(id))}
              inputProps={{ 'aria-label': 'order-return-checkbox' }}
            />
            <Grid style={{ paddingBottom: 10 }} xs={12}>
              <S.ProductName>{orderItems[id]?.product?.name}</S.ProductName>
              <S.SubTitle>{orderItems[id]?.product?.package}</S.SubTitle>
              <S.SubTitle>Current Quantity: {orderItems[id]?.quantity}</S.SubTitle>
              <S.Label>Returned Quantity</S.Label>
              <S.NumberInput
                name="quantity"
                type="number"
                placeholder="Qty"
                disabled={!itemsToReturn[id].selected}
                value={itemsToReturn[id].quantity.toString().replace(/^0+/, '')}
                onChange={(e: any) => handleInputChange(e, Number(id))}
              />
              <S.Label>Returned Reason</S.Label>
              <CreatableAutoComplete
                value={selectedReason}
                name="reason"
                isDisabled={!itemsToReturn[id].selected}
                onChange={(option: AutocompletOption) => {
                  handleAutocompleteChange(option, Number(id));
                }}
                options={returnItemOptions}
                onInputChange={(reason: string) => {
                  handleAutocompleteInputChange(reason, Number(id));
                }}
              />
            </Grid>
          </S.Container>
        );
      })}
    </>
  );

  const renderActions = (): JSX.Element => (
    <Button color={ColorType.PRIMARY} disabled={isDisabled} type="button" onClick={nextStep}>
      Submit
    </Button>
  );

  return (
    <Dialog
      open={type === OrderReturnType.PARTIAL && step === 2}
      primary
      title="Return Order"
      onClose={clearSteps}
      renderActions={renderActions}
      renderContent={renderContent}
      subTitle="Order ID"
      value={orderDetails.length === 1 ? orderDetails[0].po_number : ''}
    />
  );
};
