import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Wrapper = styled.div`
  && {
    min-width: ${(props: any) => props.minWidth || '300px'};
    position: relative;
    padding-bottom: 20px;
  }
`;

const Label = styled(Typography)`
  font-size: 14px;
  display: block;
  padding-bottom: 5px;
  margin-top: 10px;
`;

const Asterisk = styled(Typography)`
  color: red;
  margin-left: 7px;
  font-weight: bold;
`;

const LabelExtend = styled(Typography)`
  font-size: 13px;
  display: block;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-left: 4px;
  color: #888888
`;
export const S = { Wrapper, Label, Asterisk, LabelExtend };
