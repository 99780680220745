import moment from 'moment';
import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';
import { Query, PtAPIv2 } from '../utils';

export class DownloadAPI {
  private dashboardSvc = PtAPIv2.dashboard;

  public async download(tableName: string, query: Query): Promise<void> {
    try {
      const { pharmacy_id, ...restQuery } = query;
      let path = '';

      switch (tableName) {
        case 'invoices_by_pharmacy':
          path = `/invoice/${pharmacy_id}/download`;
          break;
        case 'overdue_invoices':
          restQuery.due_at = moment.utc().format('DD/MM/YYYY').toString();
          await SwipeRxPt.instance.invoice.downloadOverdue(restQuery);
          return;
        case 'payment_history':
          await SwipeRxPt.instance.deposit.report({
            pharmacy_id: pharmacy_id as number,
            type: 'payment-history',
          });
          return;
        case 'pending_deposits':
          await SwipeRxPt.instance.deposit.report({
            page: query.page as number,
            page_size: query.page_size as number,
            order_by: query.order_by as string,
            sort_by: query.sort_by as string,
            status: query.status as string,
            type: 'pending-deposit',
          });
          return;
        default:
          break;
      }

      if (!path) {
        throw new Error('No path provided for download request.');
      }

      await this.dashboardSvc.get(path, restQuery);
    } catch (e) {
      console.error('@InvoiceAPI.download', e.message);
      throw e;
    }
  }
}
