import styled from 'styled-components';

const Wrapper = styled.div`
  && {
    background: #fff;
    border-radius: 7px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.09),
      0px 0px 1px rgba(0, 0, 0, 0.19);
    margin: 15px 0 30px;
    padding: 0 15px;
  }
`;

const ItemWrapper = styled.div`
  padding: 25px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

export const S = {
  Wrapper,
  ItemWrapper,
};
