import { TransactionActionButton } from 'types';
import { TRANSACTION_BUTTONS } from '.';
import { TRANSACTION_LOGISTIC_DELIVERY_ROLES } from './roles.constants';

export type TransactionBulkActionButtonMapByStatus = Record<
  string,
  Array<TransactionActionButton>
>;

export const TRANSACTION_BULK_ACTION_BUTTON_MAP_BY_STATUS: TransactionBulkActionButtonMapByStatus = {
  dispatched: [
    {
      actionButton: TRANSACTION_BUTTONS.UPLOAD_TRACKING_NUMBERS,
      allowedRoles: TRANSACTION_LOGISTIC_DELIVERY_ROLES
    }
  ]
};
