/* eslint-disable no-console */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance, CancelTokenSource } from 'axios';
import {
  SwipeRxPtOrders,
  SwipeRxPtOrganizations,
  SwipeRxPtDocumentManagement,
  SwipeRxPtDocumentManagementPharmacy,
  SwipeRxPtMarket,
  SwipeRxPtFinance,
  SwipeRxPtProducts,
  SwipeRxPtProductCategories,
  SwipeRxPtRegions,
  SwipeRxPtAreas,
  SwipeRxPtMarketings,
  SwipeRxPtMarketingSegments,
  SwipeRxPtLoyalty,
  SwipeRxPtLogisticsArea,
  SwipeRxPtLogisticsDistributor,
  SwipeRxPtLogisticsCalendar,
  SwipeRxPtLogisticsPharmacyArea,
  SwipeRxPtLogisticsPoDelivery,
  SwipeRxPtVoucherType,
  SwipeRxPtDeposit,
  SwipeRxPtDepositRequest,
  SwipeRxPtInvoice,
  SwipeRxPtInvoiceAllocation,
  SwipeRxPtDepositTransaction,
  SwipeRxPtCredit,
  SwiperxPtCreditLimitRequest,
  SwipeRxPtCrm,
  SwipeRxPtPayment,
  SwipeRxPtDistributorProduct,
} from './resources';
import { ProcurementAxios, ProcurementAxiosParams } from './utils/ProcurementAxios.class';
import { DEFAULT_MARKET_ID } from '../../../constants';
import { SwipeRxPtDistributors } from './resources/distributors';
import { SwipeRxPtBanners } from './resources/banner';
import { SwipeRxPtPBFAssignments } from './resources/pbf-assignments';
import { SwipeRxPtUsers } from './resources/users';
import { SwipeRxPtHomesquare } from './resources/homesquare';
import { SwipeRxPtSimilarProductsInternal } from './resources/products/SwipeRxPtSimilarProductsInternal.class';
import { SwipeRxPtMarketingRewards } from './resources/marketing-rewards';
import { SwipeRxPtReturnManagement } from './resources/return-management';
import { SwipeRxPtPrecursor } from './resources/precursor/SwipeRxPtPrecursor.class';
import { SwipeRxPtMasterList } from './resources/master-list';
import { SwipeRxPtSearchsquare } from './resources/searchsquare';
import { SwipeRxPtDoku } from './resources/doku/SwipeRxPtDoku.class';
import { SwipeRxPtLogistics3PL } from './resources/logistics-3pl';
import { SwipeRxPtPriceGroup } from './resources/price-group';

export type Query = Record<string, string | number | undefined>;
export type QsStringifyParam = { [key: string]: any };

export enum SwipeRxPtV3Resources {
  ORGANIZATIONS = 'organizations',
  DOCUMENT_MANAGEMENT = 'document-management',
  DOCUMENT_MANAGEMENT_PHARMACY = 'document-management-pharmacy',
  DOCUMENT_TYPE = 'document-type',
  ORDERS = 'orders',
  MARKET = 'market',
  FINANCE = 'finance',
  PRODUCTS = 'products',
  SIMILAR_PRODUCTS = 'similar-products-internal',
  DISTRIBUTORS = 'distributors',
  DISTRIBUTOR_PRODUCT = 'distributor-products',
  PRODUCTS_CATEGORIES = 'product-categories',
  REGIONS = 'regions',
  AREAS = 'areas',
  MARKETING = 'marketing',
  BANNERS = 'banners',
  CREDIT = 'credit',
  PBF_ASSIGNMENTS = 'pbf-assignments',
  USERS = 'users',
  MARKETING_SEGMENTS = 'marketing-segments',
  MARKETING_REWARDS = 'marketing-rewards',
  LOYALTY = 'loyalty-missions',
  LOGISTICS_AREA = 'logistics-sla/area',
  LOGISTICS_DISTRIBUTOR = 'logistics-sla/distributor',
  LOGISTICS_DISTRIBUTOR_CALENDAR = 'logistics-sla/calendar',
  LOGISTICS_PHARMACY_AREA = 'logistics-sla/pharmacy',
  LOGISTICS_PO_DELIVERY = 'logistics-sla/po-delivery',
  LOGISTICS_3PL = 'logistics-delivery/3pl',
  VOUCHER_TYPE = 'voucher/type',
  VOUCHER = 'admin-voucher',
  DEPOSIT = 'deposit',
  DEPOSIT_REQUEST = 'deposit-request',
  DEPOSIT_TRANSACTION = 'deposit-transaction',
  INVOICE = 'invoice',
  INVOICE_ALLOCATION = 'invoice-allocation',
  CREDIT_LIMIT_REQUEST = 'credit/limit-requests',
  CRM = 'crm',
  HOMESQUARE = 'homesquares',
  SEARCHSQUARE = 'searchsquares',
  RETURN_MANAGEMENT = 'return',
  PRECURSOR = 'precursor',
  MASTER_LIST = 'master-list-data',
  PAYMENT = 'payments',
  DOKU = 'deposit/doku',
  PRICE_GROUP = 'price-group',
}

export class SwipeRxPt {
  private static _instance: SwipeRxPt;

  private api: AxiosInstance;

  private cancelTokenSource: CancelTokenSource | null = null;

  readonly orders: SwipeRxPtOrders;

  readonly organizations: SwipeRxPtOrganizations;

  readonly documentManagement: SwipeRxPtDocumentManagement;

  readonly documentManagementPharmacy: SwipeRxPtDocumentManagementPharmacy;

  readonly market: SwipeRxPtMarket;

  readonly finance: SwipeRxPtFinance;

  readonly products: SwipeRxPtProducts;

  readonly similarProducts: SwipeRxPtSimilarProductsInternal;

  readonly distributors: SwipeRxPtDistributors;

  readonly distributorProducts: SwipeRxPtDistributorProduct;

  readonly productCategories: SwipeRxPtProductCategories;

  readonly regions: SwipeRxPtRegions;

  readonly areas: SwipeRxPtAreas;

  readonly marketings: SwipeRxPtMarketings;

  readonly banners: SwipeRxPtBanners;

  readonly pbfAssignments: SwipeRxPtPBFAssignments;

  readonly users: SwipeRxPtUsers;

  readonly marketingSegments: SwipeRxPtMarketingSegments;

  readonly marketingRewards: SwipeRxPtMarketingRewards;

  readonly loyalty: SwipeRxPtLoyalty;

  readonly logisticsArea: SwipeRxPtLogisticsArea;

  readonly logisticsDistributor: SwipeRxPtLogisticsDistributor;

  readonly logisticsCalendar: SwipeRxPtLogisticsCalendar;

  readonly logisticsPharmacyArea: SwipeRxPtLogisticsPharmacyArea;

  readonly logisticsPoDelivery: SwipeRxPtLogisticsPoDelivery;

  readonly logistics3PL: SwipeRxPtLogistics3PL;

  readonly voucherType: SwipeRxPtVoucherType;

  readonly deposit: SwipeRxPtDeposit;

  readonly depositRequest: SwipeRxPtDepositRequest;

  readonly depositTransaction: SwipeRxPtDepositTransaction;

  readonly invoice: SwipeRxPtInvoice;

  readonly invoiceAllocation: SwipeRxPtInvoiceAllocation;

  readonly credit: SwipeRxPtCredit;

  readonly creditLimitRequest: SwiperxPtCreditLimitRequest;

  readonly crm: SwipeRxPtCrm;

  readonly homesquare: SwipeRxPtHomesquare;

  readonly searchsquare: SwipeRxPtSearchsquare;

  readonly returnManagement: SwipeRxPtReturnManagement;

  readonly precursor: SwipeRxPtPrecursor;

  readonly masterList: SwipeRxPtMasterList;

  readonly payment: SwipeRxPtPayment;

  readonly doku: SwipeRxPtDoku;

  readonly priceGroup: SwipeRxPtPriceGroup;

  constructor(params: Partial<ProcurementAxiosParams>) {
    const ptAxios = new ProcurementAxios({
      baseUrl: process.env.REACT_APP_API_BASE_URL || '',
      version: 'v3',
      marketId: params.marketId || DEFAULT_MARKET_ID,
      warpSessionToken: undefined,
    });
    this.api = ptAxios.apiServer;

    this.orders = new SwipeRxPtOrders(this);
    this.organizations = new SwipeRxPtOrganizations(this);
    this.documentManagement = new SwipeRxPtDocumentManagement(this);
    this.documentManagementPharmacy = new SwipeRxPtDocumentManagementPharmacy(this);
    this.market = new SwipeRxPtMarket(this);
    this.finance = new SwipeRxPtFinance(this);
    this.products = new SwipeRxPtProducts(this);
    this.similarProducts = new SwipeRxPtSimilarProductsInternal(this);
    this.distributors = new SwipeRxPtDistributors(this);
    this.distributorProducts = new SwipeRxPtDistributorProduct(this);
    this.productCategories = new SwipeRxPtProductCategories(this);
    this.regions = new SwipeRxPtRegions(this);
    this.areas = new SwipeRxPtAreas(this);
    this.marketings = new SwipeRxPtMarketings(this);
    this.banners = new SwipeRxPtBanners(this);
    this.pbfAssignments = new SwipeRxPtPBFAssignments(this);
    this.users = new SwipeRxPtUsers(this);
    this.marketingRewards = new SwipeRxPtMarketingRewards(this);
    this.marketingSegments = new SwipeRxPtMarketingSegments(this);
    this.loyalty = new SwipeRxPtLoyalty(this);
    this.logisticsArea = new SwipeRxPtLogisticsArea(this);
    this.logisticsDistributor = new SwipeRxPtLogisticsDistributor(this);
    this.logisticsCalendar = new SwipeRxPtLogisticsCalendar(this);
    this.logisticsPharmacyArea = new SwipeRxPtLogisticsPharmacyArea(this);
    this.logisticsPoDelivery = new SwipeRxPtLogisticsPoDelivery(this);
    this.logistics3PL = new SwipeRxPtLogistics3PL(this);
    this.voucherType = new SwipeRxPtVoucherType(this);
    this.deposit = new SwipeRxPtDeposit(this);
    this.depositRequest = new SwipeRxPtDepositRequest(this);
    this.depositTransaction = new SwipeRxPtDepositTransaction(this);
    this.invoice = new SwipeRxPtInvoice(this);
    this.invoiceAllocation = new SwipeRxPtInvoiceAllocation(this);
    this.credit = new SwipeRxPtCredit(this);
    this.creditLimitRequest = new SwiperxPtCreditLimitRequest(this);
    this.crm = new SwipeRxPtCrm(this);
    this.homesquare = new SwipeRxPtHomesquare(this);
    this.searchsquare = new SwipeRxPtSearchsquare(this);
    this.returnManagement = new SwipeRxPtReturnManagement(this);
    this.precursor = new SwipeRxPtPrecursor(this);
    this.masterList = new SwipeRxPtMasterList(this);
    this.payment = new SwipeRxPtPayment(this);
    this.doku = new SwipeRxPtDoku(this);
    this.priceGroup = new SwipeRxPtPriceGroup(this);
  }

  public static initialize(marketId: string): void {
    SwipeRxPt._instance = new SwipeRxPt({
      marketId,
    });
  }

  private static throwError(e: any): void {
    if (e.response) {
      const { data } = e.response;
      if (Array.isArray(data.error)) throw data.error || data.message;
      if (typeof data.error === 'object') throw new Error(data.error.message);
      throw new Error(data.error || data.message);
    }
    throw new Error((e as Error).message);
  }

  public static get instance(): SwipeRxPt {
    if (!SwipeRxPt._instance) {
      throw new Error('call initialize first');
    }
    return SwipeRxPt._instance;
  }

  public cancelRequest(): void {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }
  }

  public async get<T = any>(
    path: string,
    query?: Query | (QsStringifyParam & { params?: never }),
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>['data']> {
    try {
      const { api: client } = this;

      this.cancelTokenSource = axios.CancelToken.source();

      const { data } = await client.get<T>(path, {
        ...config,
        cancelToken: this.cancelTokenSource.token,
        params: query,
      });

      return data;
    } catch (e) {
      console.error('@SwipeRxPt::get', (e as Error).message);
      throw SwipeRxPt.throwError(e);
    }
  }

  /**
   * @deprecated Prefer to use `postV2` for LogicExceptions
   *
   * Only use this when error cannot be handled from the API
   *
   */
  public async post<T = any>(
    path: string,
    payload: any,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<T>['data']> {
    try {
      const { api: client } = this;

      const { data } = await client.post<T>(path, payload, config);

      return data;
    } catch (e) {
      console.error('@SwipeRxPt::post', (e as Error).message);
      throw SwipeRxPt.throwError(e);
    }
  }

  /**
   * Handles `LogicException` messages so it's safe to display to user.
   * Simplify the exception first on API so that it will be formatted to type `src/types/LogicError.type.ts`
   * @param path
   * @param payload
   * @param config
   * @returns
   */
  public async postV2<T = any>(
    path: string,
    payload: any,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<T>['data']> {
    try {
      const { api: client } = this;

      const { data } = await client.post<T>(path, payload, config);

      return data;
    } catch (e) {
      console.error('@SwipeRxPt::post', (e as Error).message);
      throw SwipeRxPt.throwError(e);
    }
  }

  public async patch<T = any>(
    path: string,
    payload: any,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<T>['data']> {
    try {
      const { data } = await this.api.patch(path, payload, config);

      return data;
    } catch (e) {
      console.error('@SwipeRxPt::patch', (e as Error).message);
      throw SwipeRxPt.throwError(e);
    }
  }

  public async delete<T = any>(path: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>['data']> {
    try {
      const { data } = await this.api.delete(path, config);

      return data;
    } catch (e) {
      throw SwipeRxPt.throwError(e);
    }
  }
}
