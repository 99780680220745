/* eslint-disable no-param-reassign */

import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { RegionList } from './interfaces/RegionList.interface';

export class SwipeRxPtRegions {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.REGIONS;
  }

  async fetchRegions(params: any): Promise<RegionList> {
    const result = await this.base.get(`${this.resource}/`, params);

    return result;
  }
}
