import React from 'reactn';
import { useOrderReturnForm } from './use-order-return-form';
import { OrderReturnTypeModal } from './order-return-type-modal.component';
import { OrderReturnModal } from './order-return-modal.component';
import { OrderReturnItemsModal } from './order-return-items-modal.component';
import { OrderReturnConfirmationModal } from './order-return-confirmation-modal.component';

export const OrderReturnModalContainer: React.FC = () => {
  const {
    fullReturnForm,
    partialReturnForm,
    steps,
    returnType,
    order,
  } = useOrderReturnForm();

  return (
    <>
      <OrderReturnTypeModal
        data={order}
        returnType={returnType}
        steps={steps}
      />
      <OrderReturnModal
        form={fullReturnForm}
        data={order}
        steps={steps}
        returnType={returnType}
      />
      <OrderReturnItemsModal
        form={partialReturnForm}
        data={order}
        returnType={returnType}
        steps={steps}
      />
      <OrderReturnConfirmationModal
        form={fullReturnForm}
        items={partialReturnForm.itemsToReturn}
        data={order}
        type={returnType.type}
        steps={steps}
      />
    </>
  );
};
