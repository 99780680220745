/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDispatch, useGlobal, useState } from 'reactn';
import { OrderItemUpdate } from 'common/interfaces';
import { useFullOrderReturnForm } from './use-full-order-return-form';
import { usePartialOrderReturnForm } from './use-partial-order-return-form';

export const useOrderReturnForm = () => {
  const [{ returnOrder: order }] = useGlobal('transactions');
  const [returnType, setReturnType] = useState<string>('');
  const [step, setStep] = useState(1);

  const setOrderIdForReturn = useDispatch('setOrderIdForReturn');

  const nextStep = (): void => {
    setStep((x) => x + 1);
  };

  const handleReturnTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setReturnType((event.target as HTMLInputElement).value);
  };

  const clearSteps = (): void => {
    setOrderIdForReturn({ orderId: 0 });
    setStep(1);
    setReturnType('');
  };

  const partialReturnForm = usePartialOrderReturnForm(order);

  const { itemsToReturn } = partialReturnForm;

  const itemUpdates = Object.keys(itemsToReturn).reduce((acc, id) => {
    const isSelectedItem =
      itemsToReturn[id].selected && itemsToReturn[id].quantity;

    if (isSelectedItem) {
      acc.push({
        id: Number(id),
        quantity: itemsToReturn[id].quantity,
        return_reason: itemsToReturn[id].reason,
      });
    }

    return acc;
  }, [] as OrderItemUpdate[]);

  const fullReturnForm = useFullOrderReturnForm({
    return_type: returnType,
    order_id: order.orderId,
    clearSteps,
    item_updates: itemUpdates,
  });

  return {
    fullReturnForm,
    partialReturnForm,
    order,
    steps: { step, nextStep, clearSteps },
    returnType: { type: returnType, handleReturnTypeChange },
  };
};
