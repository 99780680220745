interface CryptoProvider {
  sha256: (message: string) => Promise<string>;
}

export const useCrypto = (): CryptoProvider => {
  const sha256 = async (message: string): Promise<string> => {
    const byteBuffer = new TextEncoder().encode(message);
    const digest = await crypto.subtle.digest('SHA-256', byteBuffer);

    return Array
      .from(new Uint8Array(digest))                      // Convert 256bit to array of byte
      .map((byte) => byte.toString(16).padStart(2, '0')) // convert to hex array pad it with '00'
      .join('');                                         // make array of hex into string
  }

  return {
    sha256
  };
}