/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { addReducer } from 'reactn';

import { uniq } from 'lodash';

import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';
import { getSelectedDocumentsByIds, pluralize } from 'common/utils';
import { DOCUMENT_MANAGEMENT_ASSIGNED_MAP_BY_TYPE } from 'common/constants';
import { recordException } from 'utils/Reporting/Sentry';
import { DocumentManagementState } from './interfaces';
import { DocumentManagementTableParams } from './types';

export const DOCUMENT_MANAGEMENT_INITIAL_STATE: DocumentManagementState = {
  documentUploadStats: {
    uploadedCount: 0,
    uploadingCount: 0,
    failedCount: 0,
    followUpRequestCount: 0,
  },
  uploadMessage: null,
  loading: false,
  distributor: {
    data: null,
  },
  documentUploadStatus: {
    submitted: [],
    processing: [],
    uploaded: [],
    assigned: [],
    unassigned: [],
    failed: [],
    assigned_types: {
      DLV: [],
      INV: [],
      TAX: [],
    },
  },
  replace: {
    documentIds: [],
    documentDetails: [],
  },
  reassign: {
    documentIds: [],
    documentDetails: [],
  },
  remove: {
    documentIds: [],
    documentDetails: [],
  },
  unassignedDocumentCount: 0,
};

addReducer('fetchDocumentManagementDatatable', async (_, dispatch, { params }) => {
  try {
    dispatch.setDocumentLoading(true);

    // to avoid initial request from search bar
    if (!params?.page || !params?.page_size || params.status_type === 'unassigned') return;

    const { data, meta } = await SwipeRxPt.instance.documentManagement.list(params);
    if (params && params.no_count) {
      dispatch.setDataTableRows({
        params,
        name: 'document-management',
        rows: data,
      });
    } else {
      dispatch.setDataTableRows({
        params: {
          ...params,
          page_count: meta.page_count,
          total_count: meta.total_count,
        },
        name: 'document-management',
        rows: data,
      });
    }
  } catch (error) {
    recordException(error, 'fetchDocumentManagementDatatable', { params });
    dispatch.setDataTableError({ name: 'document-management', error: error.message });
  }
  dispatch.setDocumentLoading(false);
});

addReducer('fetchDocumentManagementAndUploadStatus', async (_, dispatch, params) => {
  try {
    const { data: uploadStatus } = await SwipeRxPt.instance.documentManagement.uploadStatus();
    dispatch.setUploadingStatus(uploadStatus);

    // eslint-disable-next-line
    params.is_processing = uploadStatus.submitted.length > 0;
    dispatch.fetchDocumentManagementDatatable({ params });
  } catch (error) {
    recordException(error, 'fetchDocumentManagementAndUploadStatus', { params });
    dispatch.setDataTableError({ name: 'document-management', error: error.message });
  }
});

addReducer('deleteDocumentById', async (_, dispatch, id) => {
  try {
    dispatch.setDocumentLoading(true);
    await SwipeRxPt.instance.documentManagement.deleteById(id);

    dispatch.fetchDataTableRows({
      name: 'document-management',
    });
    dispatch.getUnassignedDocumentCount({});

    dispatch.setDocumentSuccessMessage({ message: 'Document removed' });
  } catch (error) {
    recordException(error, 'deleteDocumentById', { id });
    dispatch.setDocumentError({ error: error.message });
  } finally {
    dispatch.setDocumentLoading(false);
  }
});

addReducer('increaseUploadingCount', (state, _, { count = 1 }) => {
  const { documentManagement } = state;
  const { documentUploadStats, documentUploadStatus } = documentManagement;

  const { submitted: currentSubmitted, processing: currentProcessing } = documentUploadStatus;
  const submitted = [...currentSubmitted, ...new Array(count).fill(0)];
  const processing = [...currentProcessing, ...new Array(count).fill(0)];

  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      documentUploadStats: {
        ...documentUploadStats,
        uploadingCount: documentUploadStats.uploadingCount + count,
      },
      documentUploadStatus: {
        ...documentUploadStatus,
        submitted,
        processing,
      },
    },
  };
});

// eslint-disable-next-line
addReducer('fetchUploadingStatus', async (state, dispatch) => {
  const { documentManagement, dataTables } = state;
  const { distributor } = documentManagement;
  const doc = dataTables['document-management'];

  const updateDocumentManagementDataTable = (): void => {
    const documentParams = doc?.params as DocumentManagementTableParams;
    const fetchParams = {
      order_by: 'updated_at',
      sort_by: 'desc',
      distributor_id: distributor?.data?.id,
      status_type: documentParams.status_type,
    };

    dispatch.fetchDocumentManagementDatatable({
      params: {
        ...fetchParams,
        is_processing: false,
      },
    });
  };

  try {
    const { data: uploadStatus } = await SwipeRxPt.instance.documentManagement.uploadStatus();

    dispatch.setUploadingStatus(uploadStatus);

    if (uploadStatus.submitted.length > 0 && uploadStatus.processing.length <= 0) {
      if (doc) updateDocumentManagementDataTable();

      const { assigned, failed, unassigned } = uploadStatus;
      const docMessages: string[] = [];
      if (assigned) {
        docMessages.push(`${assigned.length} ${pluralize('doc', assigned.length)} assigned`);
      }
      if (unassigned) {
        docMessages.push(`${unassigned.length} ${pluralize('doc', unassigned.length)} unassigned`);
        dispatch.getUnassignedDocumentCount({});
      }
      if (failed) {
        docMessages.push(`${failed.length} ${pluralize('doc', failed.length)} failed`);
      }
      const message = `The ${uploadStatus.uploaded.length} documents has been uploaded. ${docMessages.join(', ')}`;
      dispatch.setDocumentSuccessMessage({ message });
      await SwipeRxPt.instance.documentManagement.uploadDone();
    }
  } catch (error) {
    recordException(error, 'fetchUploadingStatus');
    return state;
  }
});

addReducer('clearDocumentManagement', (state) => {
  return {
    ...state,
    documentManagement: DOCUMENT_MANAGEMENT_INITIAL_STATE,
  };
});

addReducer('setUploadingStatus', (state, _, payload) => {
  const { documentManagement } = state;
  const { documentUploadStats, documentUploadStatus } = documentManagement;
  const { followUpRequestCount } = documentUploadStats;

  const submitted = uniq([...documentUploadStatus.submitted, ...payload.submitted]).filter((nonZero) => nonZero);
  const processing = [...payload.processing];
  const uploaded = uniq([...documentUploadStatus.uploaded, ...payload.uploaded]);
  const unassigned = uniq([...documentUploadStatus.unassigned, ...payload.unassigned]);
  const assigned = uniq([...documentUploadStatus.assigned, ...payload.assigned]);
  const failed = uniq([...documentUploadStatus.failed, ...payload.failed]);

  const delivery = uniq([...documentUploadStatus.assigned_types.DLV, ...payload.delivery]);
  const invoice = uniq([...documentUploadStatus.assigned_types.INV, ...payload.invoice]);
  const tax = uniq([...documentUploadStatus.assigned_types.TAX, ...payload.tax]);

  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      documentUploadStats: {
        ...documentUploadStats,
        followUpRequestCount: followUpRequestCount + 1,
      },
      documentUploadStatus: {
        ...documentUploadStatus,
        submitted,
        processing,
        uploaded,
        unassigned,
        assigned,
        failed,
        assigned_types: {
          DLV: delivery,
          INV: invoice,
          TAX: tax,
        },
      },
    },
  };
});

addReducer('scanDocument', async (_, dispatch, payload) => {
  try {
    await SwipeRxPt.instance.documentManagement.scanDocument(payload);
  } catch (e) {
    recordException(e, 'scanDocument', { payload });
    dispatch.setDocumentError({ error: e.message });
  }
});

addReducer('uploadDocument', async (_, dispatch, payload) => {
  try {
    await SwipeRxPt.instance.documentManagement.uploadDocument(payload);
  } catch (e) {
    recordException(e, 'uploadDocument', { payload });
    dispatch.setDocumentError({ error: e.message });
  }
});

addReducer('clearDocumentDataTable', (_, dispatch) => {
  dispatch.setDataTableRows({
    name: 'document-management',
    rows: [],
  });
});

addReducer('setDocumentLoading', (state, _, payload) => {
  const { documentManagement } = state;
  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      loading: payload,
    },
  };
});

addReducer('setDocumentError', (state, _, payload) => {
  const { documentManagement } = state;
  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      error: payload.error,
    },
  };
});

addReducer('setDocumentSuccessMessage', (state, _, payload) => {
  const { documentManagement } = state;

  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      successMessage: payload.message,
    },
  };
});

addReducer('fetchDocumentManagementDistributor', async (_, dispatch, { id }) => {
  try {
    const { name } = await SwipeRxPt.instance.organizations.retrieve(id, {
      type: 'distributor',
    });

    dispatch.setDocumentManagementDistributor({ id, name });
  } catch (e) {
    recordException(e, 'fetchDocumentManagementDistributor', { id });
    dispatch.setDocumentManagementDistributorError({ error: e.message });
  }
});

addReducer('setDocumentManagementDistributor', (state, _, payload) => {
  const { documentManagement } = state;

  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      distributor: {
        data: payload,
        error: null,
      },
    },
  };
});

addReducer('setDocumentManagementDistributorError', (state, _, payload) => {
  const { documentManagement } = state;

  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      distributor: {
        ...documentManagement.distributor,
        error: payload.error,
      },
    },
  };
});

addReducer('setDocumentReplaceState', (state, _, payload) => {
  const { documentManagement } = state;
  const { documentIds, documentDetails } = payload;
  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      replace: {
        documentIds,
        documentDetails,
      },
    },
  };
});

addReducer('setDocumentIdsForReplace', (state, _, payload) => {
  const { documentManagement } = state;
  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      replace: {
        ...documentManagement.replace,
        documentIds: payload.documentIds,
      },
    },
  };
});

addReducer('initializeDocumentReplace', ({ dataTables }, dispatch, { ids }) => {
  const documentDetails = getSelectedDocumentsByIds(ids, dataTables);
  dispatch.setDocumentReplaceState({
    documentIds: ids,
    documentDetails,
  });
});

addReducer('setDocumentReassignState', (state, _, payload) => {
  const { documentManagement } = state;
  const { documentIds, documentDetails } = payload;
  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      reassign: {
        documentIds,
        documentDetails,
      },
    },
  };
});

addReducer('setDocumentIdsForReassign', (state, _, payload) => {
  const { documentManagement } = state;
  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      reassign: {
        ...documentManagement.reassign,
        documentIds: payload.documentIds,
      },
    },
  };
});

addReducer('initializeDocumentReassign', ({ dataTables }, dispatch, { ids }) => {
  const documentDetails = getSelectedDocumentsByIds(ids, dataTables);
  dispatch.setDocumentReassignState({
    documentIds: ids,
    documentDetails,
  });
});

addReducer('setDocumentRemoveState', (state, _, payload) => {
  const { documentManagement } = state;
  const { documentIds, documentDetails } = payload;
  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      remove: {
        documentIds,
        documentDetails,
      },
    },
  };
});

addReducer('setDocumentIdsForRemove', (state, _, payload) => {
  const { documentManagement } = state;
  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      remove: {
        ...documentManagement.remove,
        documentIds: payload.documentIds,
      },
    },
  };
});

addReducer('initializeDocumentRemove', ({ dataTables }, dispatch, { ids }) => {
  const documentDetails = getSelectedDocumentsByIds(ids, dataTables);
  dispatch.setDocumentRemoveState({
    documentIds: ids,
    documentDetails,
  });
});

addReducer('removeDocument', async (state, dispatch) => {
  const {
    documentManagement: {
      remove: { documentIds, documentDetails },
    },
  } = state;
  const documentId = documentIds[0];

  dispatch.setDocumentLoading(true);

  try {
    await SwipeRxPt.instance.documentManagement.deleteById(documentId);
    dispatch.fetchDataTableRows({
      name: 'document-management',
    });
    dispatch.setDocumentSuccessMessage({
      message: `Document ${documentDetails[0]?.purchase_order?.po_number} removed`,
    });
  } catch (e) {
    recordException(e, 'removeDocument', { documentId });
    dispatch.setDocumentError({ error: e.message });
  } finally {
    dispatch.setDocumentLoading(false);
  }
});

addReducer('replaceDocument', async (state, dispatch, payload) => {
  const {
    documentManagement: {
      replace: { documentIds, documentDetails },
    },
  } = state;
  const documentId = documentIds[0];

  dispatch.setDocumentLoading(true);

  try {
    await SwipeRxPt.instance.documentManagement.replaceDoment(documentId, payload);

    dispatch.fetchDataTableRows({
      name: 'document-management',
    });
    dispatch.setDocumentSuccessMessage({
      message: `Document ${documentDetails[0]?.purchase_order?.po_number} replaced`,
    });
  } catch (e) {
    recordException(e, 'replaceDocument', { documentId });
    dispatch.setDocumentError({ error: e.message });
    throw e;
  } finally {
    dispatch.setDocumentLoading(false);
  }
});

addReducer('reassignDocument', async (state, dispatch, payload) => {
  const {
    documentManagement: {
      reassign: { documentIds, documentDetails },
    },
  } = state;
  const documentId = documentIds[0];

  dispatch.setDocumentLoading(true);

  try {
    await SwipeRxPt.instance.documentManagement.reassignDocument(documentId, payload);

    dispatch.fetchDataTableRows({
      name: 'document-management',
    });
    dispatch.setDocumentSuccessMessage({
      message: `Document ${documentDetails[0]?.purchase_order?.po_number} reassigned to ${payload.po_number}`,
    });
  } finally {
    dispatch.setDocumentLoading(false);
  }
});

addReducer('assignDocument', async (_, dispatch, payload) => {
  try {
    dispatch.setDocumentLoading(true);

    const { document_id, ...body } = payload;

    const { data } = await SwipeRxPt.instance.documentManagement.assignDocument(document_id, body);

    if (data && data.error) {
      dispatch.setDocumentError({ error: data.error });
      return;
    }

    const documentType = DOCUMENT_MANAGEMENT_ASSIGNED_MAP_BY_TYPE[body.document_type];

    dispatch.setDocumentSuccessMessage({
      message: `${documentType} Document assigned to invoice ${payload.invoice_number}`,
    });
  } catch (e) {
    recordException(e, 'assignDocument', { payload });
    dispatch.setDocumentError({ error: e.message });
    throw e;
  } finally {
    dispatch.setDocumentLoading(false);
  }
});

addReducer('getUnassignedDocumentCount', async (state, dispatch) => {
  // temporarily remove this function implementation to reduce server cost
  // since this function is being called everytime user change tab, filter criteria, etc
  // might refactor this function in the future.
  // try {
  //     const { documentManagement } = state;
  //     const distributorId = documentManagement.distributor.data?.id || null;
  //     const { data } = await SwipeRxPt.instance.documentManagement.getUnassignedDocuments({ distributor_id: distributorId });
  //     dispatch.setUnassignedDocumentCount({ count: data.length });
  // } catch (error) {
  //     recordException(error, 'getUnassignedDocumentCount');
  //     dispatch.setUnassignedDocumentCount({ count: 0 });
  // }
});

addReducer('setUnassignedDocumentCount', (state, _, payload) => {
  const { documentManagement } = state;
  return {
    ...state,
    documentManagement: {
      ...documentManagement,
      unassignedDocumentCount: payload.count,
    },
  };
});
