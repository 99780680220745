import React from 'react';
import ReactQuill from 'react-quill';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

export type FieldInputProps = TextFieldProps;

export enum EditorConfig {
  Default = 'default',
}

const editorConfigs = {
  [EditorConfig.Default]: {
    modules: {
      toolbar: [['bold', 'italic', 'underline', 'link'], [{ list: 'ordered' }, { list: 'bullet' }], ['break']],
    },
    formats: ['bold', 'italic', 'underline', 'list', 'bullet', 'break', 'link'],
  },
};

export const FieldRichText: React.FC<any> = ({ label, name, setFieldValue, values, enabled = true, ...props }) => {
  const { modules, formats } = editorConfigs[EditorConfig.Default];

  return (
    <>
      {label}
      <ReactQuill
        theme="snow"
        onChange={(event) => setFieldValue(name, event)}
        modules={modules}
        formats={formats}
        value={values}
        readOnly={!enabled}
        {...props}
      />
    </>
  );
};
