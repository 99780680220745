import { ITranslation } from './types';

export default {
  SwipeRx: 'SwipeRx',
  deliverer: 'Delivered By',
  recipient: 'Delivered To',
  date: 'date',
  time: 'time',
  invoiceReceipt: 'Invoice Receipt',
  invoiceNo: 'NO',
  invoiceNumber: 'INVOICE NUMBER',
  invoiceDate: 'INVOICE DATE',
  invoiceTotalAmount: 'TOTAL',
  invoiceDueDate: 'DUE DATE',
  invoiceNote: 'NOTE',
  retourNo: 'NO.',
  retourForm: 'RETOUR FORM',
  retourItem: 'ITEM',
  retoruQuantity: 'QTY',
  retourReason: 'REASON',
  footerNoteTitle: 'Note',
  footerNotes: [
    'Exchange invoice (fill the receipt, authorized signature, and stamp)',
    'Bring 3 copy of invoices',
  ],
  footerPaymentMethod: 'Payment',
  footerCustomerService: 'For more information contact #CONTACT_NUMBER#',
} as ITranslation;
