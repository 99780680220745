import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const Input = styled(TextField)`
  && {
    width: ${(props: any) => props.style?.width || '100%'};
    min-width: ${(props: any) => props.minWidth || '300px'};
    font-size: 14px;
    height: ${(props: any) => props.height || 'auto'};
  }
`;

export const S = { Input };
