import React, { useState, useEffect } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Main from 'common/containers/Main';
import Login from 'common/containers/Login/Login.container';
import ErrorContainer from 'common/containers/Error/Error.container';
import { PtApiConfig as PtApiConfigV2 } from 'common/services/apis/v2';
import { SwipeRxPt as PtApiConfigV3 } from 'common/services/apis/v3';
import { DEFAULT_MARKET_ID } from 'common/services/constants';
import { GlobalMoment } from 'common/utils/formatters/global-moment.util';
import { PrintPage, PreviewPage } from 'pages';
import { IMarketParams } from 'utils/get-router-path';
import { Locale, setLocale } from 'utils/locale';

import UnauthorizedRedirect from '../UnauthorizedRedirect.component';

type AppRouteContainerProps = {
  path: string;
};

export const AppRouteContainer: React.FC<AppRouteContainerProps> = ({ path }) => {
  const { params } = useRouteMatch<IMarketParams>();

  useEffect(() => {
    if (params.marketId) setLocale(params.marketId as Locale);

    GlobalMoment.instance.initialize(params.marketId);
  }, [params.marketId]);

  const [marketFlag, setMarketFlag] = useState(false);

  if (!params.marketId) {
    return null;
  }

  if (!marketFlag) {
    PtApiConfigV2.initialize(params.marketId || DEFAULT_MARKET_ID);
    PtApiConfigV3.initialize(params.marketId || DEFAULT_MARKET_ID);
    setMarketFlag(true);
  }

  if (!marketFlag) {
    return null;
  }

  return (
    <>
      <UnauthorizedRedirect />
      <Switch>
        <Route path={`${path}/print-page/:template`} component={PrintPage} />
        <Route path={`${path}/preview-page/:url`} component={PreviewPage} />
        <Route path={`${path}/login`} component={Login} />
        <Route path={`${path}/`} component={Main} />
        <Route path={`${path}/404`} component={ErrorContainer} />
      </Switch>
    </>
  );
};
