/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { addReducer } from 'reactn';

import { uniq } from 'lodash';

import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';
import { pluralize } from 'common/utils';
import { recordException } from 'utils/Reporting/Sentry';
import { DocumentManagementPharmacyState } from './interfaces';
import { DocumentManagementPharmacyTableParams } from './types';

export const DOCUMENT_MANAGEMENT_PHARMACY_INITIAL_STATE: DocumentManagementPharmacyState = {
  documentUploadStats: {
    uploadedCount: 0,
    uploadingCount: 0,
    failedCount: 0,
    followUpRequestCount: 0,
  },
  uploadMessage: null,
  loading: false,
  documentUploadStatus: {
    submitted: [],
    uploaded: [],
    failed: [],
  },
  documentType: {
    selected: undefined,
    data: [],
  },
  pharmacy: {
    selected: undefined,
    data: [],
  },
  documentPharmacyUpdateInfo: null,
  documentPharmacyUploadInfo: null,
  documentManagementPharmacyHistoryList: [],
  signedUrl: '',
};

addReducer('fetchDocumentManagementPharmacyDatatable', async (_, dispatch, { params }) => {
  try {
    const { data, meta } = await SwipeRxPt.instance.documentManagementPharmacy.list(params);
    dispatch.setDataTableRows({
      name: 'document-management-pharmacy',
      rows: data,
      params: {
        ...params,
        page_count: meta.page_count,
        total_count: meta.total_count,
      },
    });
  } catch (error) {
    recordException(error, 'fetchDocumentManagementPharmacyDatatable', { params });
    dispatch.setDataTableError({ name: 'document-management-pharmacy', error: error.message });
  }
});

addReducer('fetchDocumentManagementPharmacyHistoryDatatable', async (_, dispatch, { params }) => {
  try {
    const { data, meta } = await SwipeRxPt.instance.documentManagementPharmacy.getAllhistory(params);
    dispatch.setDataTableRows({
      name: 'document-management-pharmacy-history',
      rows: data,
      params: {
        ...params,
        page_count: meta.page_count,
        total_count: meta.total_count,
      },
    });
  } catch (error) {
    recordException(error as any, 'fetchDocumentManagementPharmacyHistory', { params });
  }
});

addReducer('fetchDocumentManagementPharmacyAndUploadStatus', async (_, dispatch, params) => {
  try {
    const { data: uploadStatus } = await SwipeRxPt.instance.documentManagementPharmacy.uploadStatus();
    dispatch.setUploadingPharmacyStatus(uploadStatus);

    dispatch.fetchDocumentManagementPharmacyDatatable({ params });
  } catch (error) {
    recordException(error, 'fetchDocumentManagementPharmacyAndUploadStatus', { params });
    dispatch.setDataTableError({ name: 'document-management-pharmacy', error: error.message });
  }
});

addReducer('increaseUploadingPharmacyCount', (state, _, { count = 1 }) => {
  const { documentManagementPharmacy } = state;
  const { documentUploadStats, documentUploadStatus } = documentManagementPharmacy;

  const { submitted: currentSubmitted } = documentUploadStatus;
  const submitted = [...currentSubmitted, ...new Array(count).fill(0)];

  return {
    ...state,
    documentManagementPharmacy: {
      ...documentManagementPharmacy,
      documentUploadStats: {
        ...documentUploadStats,
        uploadingCount: documentUploadStats.uploadingCount + count,
      },
      documentUploadStatus: {
        ...documentUploadStatus,
        submitted,
      },
    },
  };
});

// eslint-disable-next-line
addReducer('fetchUploadingPharmacyStatus', async (state, dispatch, params) => {
  const {
    documentManagementPharmacy: { documentUploadStatus },
    dataTables,
  } = state;

  const doc = dataTables['document-management-pharmacy'];

  const updateDocumentPharmacyManagementDataTable = (): void => {
    const documentParams = doc?.params as DocumentManagementPharmacyTableParams;
    const fetchParams = {
      order_by: 'updated_at',
      sort_by: 'desc',
      status_type: documentParams.status_type,
      ...params,
    };

    dispatch.fetchDocumentManagementPharmacyDatatable({
      params: {
        ...fetchParams,
        is_processing: false,
      },
    });
  };

  try {
    const { data: uploadStatus } = await SwipeRxPt.instance.documentManagementPharmacy.uploadStatus();

    if (documentUploadStatus.uploaded.length !== uploadStatus.uploaded.length && uploadStatus.uploaded.length > 0) {
      updateDocumentPharmacyManagementDataTable();
    }
    dispatch.setUploadingPharmacyStatus(uploadStatus);

    if (
      uploadStatus.uploaded.length + uploadStatus.failed.length >= documentUploadStatus.submitted.length &&
      uploadStatus.uploaded.length + uploadStatus.failed.length > 0
    ) {
      if (doc) updateDocumentPharmacyManagementDataTable();

      const { failed } = uploadStatus;
      const docMessages: string[] = [];
      if (failed) {
        docMessages.push(`${failed.length} ${pluralize('doc', failed.length)} failed`);
      }
      const message = `${uploadStatus.uploaded.length} documents has been uploaded, ${docMessages.join(', ')}.`;
      dispatch.setDocumentPharmacySuccessMessage({ message });
      await SwipeRxPt.instance.documentManagementPharmacy.uploadDone();
    }
  } catch (error) {
    recordException(error, 'fetchUploadingPharmacyStatus');
    return state;
  }
});

addReducer('clearDocumentManagementPharmacy', (state) => {
  return {
    ...state,
    documentManagementPharmacy: DOCUMENT_MANAGEMENT_PHARMACY_INITIAL_STATE,
  };
});

addReducer('setUploadingPharmacyStatus', (state, _, payload) => {
  const { documentManagementPharmacy } = state;
  const { documentUploadStats, documentUploadStatus } = documentManagementPharmacy;
  const { followUpRequestCount } = documentUploadStats;

  const uploaded = uniq([...documentUploadStatus.uploaded, ...payload.uploaded]);
  const failed = uniq([...documentUploadStatus.failed, ...payload.failed]);

  return {
    ...state,
    documentManagementPharmacy: {
      ...documentManagementPharmacy,
      documentUploadStats: {
        ...documentUploadStats,
        followUpRequestCount: followUpRequestCount + 1,
      },
      documentUploadStatus: {
        ...documentUploadStatus,
        uploaded,
        failed,
      },
    },
  };
});

addReducer('scanDocumentPharmacy', async (_, dispatch, payload) => {
  try {
    await SwipeRxPt.instance.documentManagementPharmacy.scanDocument(payload);
  } catch (e) {
    recordException(e, 'scanDocumentPharmacy', { payload });
    dispatch.setDocumentPharmacyError({ error: e.message });
  }
});

addReducer('clearDocumentPharmacyDataTable', (_, dispatch) => {
  dispatch.setDataTableRows({
    name: 'document-management-pharmacy',
    rows: [],
  });
});

addReducer('setDocumentPharmacyLoading', (state, _, payload) => {
  const { documentManagementPharmacy } = state;
  return {
    ...state,
    documentManagementPharmacy: {
      ...documentManagementPharmacy,
      loading: payload,
    },
  };
});

addReducer('setDocumentPharmacyError', (state, _, payload) => {
  const { documentManagementPharmacy } = state;
  return {
    ...state,
    documentManagementPharmacy: {
      ...documentManagementPharmacy,
      error: payload.error,
    },
  };
});

addReducer('setDocumentPharmacySuccessMessage', (state, _, payload) => {
  const { documentManagementPharmacy } = state;

  return {
    ...state,
    documentManagementPharmacy: {
      ...documentManagementPharmacy,
      successMessage: payload.message,
    },
  };
});

addReducer('searchDocumentType', async (_, dispatch, payload) => {
  try {
    const data = await SwipeRxPt.instance.documentManagementPharmacy.getDocumentTypes(payload);
    dispatch.setDocumentTypeData({ data });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'getDocumentTypes', payload);
    dispatch.setDocumentPharmacyError({ error: error.message });
  }
});

addReducer('setDocumentTypeData', (state, _, { data }) => {
  const { documentManagementPharmacy } = state;

  return {
    ...state,
    documentManagementPharmacy: {
      ...documentManagementPharmacy,
      documentType: {
        ...documentManagementPharmacy.documentType,
        data,
      },
    },
  };
});

addReducer('setDocumentTypeSelected', (state, _, { selected }) => {
  const { documentManagementPharmacy } = state;

  return {
    ...state,
    documentManagementPharmacy: {
      ...documentManagementPharmacy,
      documentType: {
        ...documentManagementPharmacy.documentType,
        selected,
      },
    },
  };
});

addReducer('searchPharmacy', async (_, dispatch, payload) => {
  try {
    const data = await SwipeRxPt.instance.documentManagementPharmacy.getPharmacys(payload);
    dispatch.setPharmacyData({ data });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'getDocumentTypes', payload);
    dispatch.setDocumentPharmacyError({ error: error.message });
  }
});

addReducer('setPharmacyData', (state, _, { data }) => {
  const { documentManagementPharmacy } = state;

  return {
    ...state,
    documentManagementPharmacy: {
      ...documentManagementPharmacy,
      pharmacy: {
        ...documentManagementPharmacy.pharmacy,
        data,
      },
    },
  };
});

addReducer('setPharmacySelected', (state, _, { selected }) => {
  const { documentManagementPharmacy } = state;

  return {
    ...state,
    documentManagementPharmacy: {
      ...documentManagementPharmacy,
      pharmacy: {
        ...documentManagementPharmacy.pharmacy,
        selected,
      },
    },
  };
});

addReducer('setDocumentPharmacyUpdateInfo', (state, _, payload) => {
  const { documentManagementPharmacy } = state;

  return {
    ...state,
    documentManagementPharmacy: {
      ...documentManagementPharmacy,
      documentPharmacyUpdateInfo: payload,
    },
  };
});

addReducer('setDocumentPharmacyUploadInfo', (state, _, payload) => {
  const { documentManagementPharmacy } = state;

  return {
    ...state,
    documentManagementPharmacy: {
      ...documentManagementPharmacy,
      documentPharmacyUploadInfo: payload,
    },
  };
});

addReducer('updateDocumentPharmacyUploadInfo', async (state, dispatch, payload) => {
  try {
    const result = await SwipeRxPt.instance.documentManagementPharmacy.updateDocument(payload);
    await dispatch.fetchDataTableRows({ name: 'document-management-pharmacy' });
    const message = `File ${result.data.filename} properties has been successfully updated.`;
    dispatch.setDocumentPharmacySuccessMessage({ message });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'updateDocumentPharmacyUpdateInfo', payload);
    dispatch.setDocumentPharmacyError({ error: error.message });
  }
});

addReducer('updateDocumentPharmacyUpdateInfo', async (state, dispatch, payload) => {
  try {
    const result = await SwipeRxPt.instance.documentManagementPharmacy.updateDocument(payload);
    await dispatch.fetchDataTableRows({ name: 'document-management-pharmacy' });
    const message = `File ${result.data.filename} properties has been successfully updated.`;
    dispatch.setDocumentPharmacySuccessMessage({ message });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'updateDocumentPharmacyUpdateInfo', payload);
    dispatch.setDocumentPharmacyError({ error: error.message });
  }
});

addReducer('deleteDocumentPharmacyById', async (_, dispatch, id) => {
  try {
    dispatch.setDocumentPharmacyLoading(true);

    await SwipeRxPt.instance.documentManagementPharmacy.deleteById(id);

    dispatch.fetchDataTableRows({
      name: 'document-management-pharmacy',
    });

    dispatch.setDocumentPharmacySuccessMessage({ message: 'Document has been removed' });
  } catch (error) {
    recordException(error, 'deleteDocumentPharmacyById', { id });

    dispatch.setDocumentPharmacyError({ error: error.message });
  } finally {
    dispatch.setDocumentPharmacyLoading(false);
  }
});
