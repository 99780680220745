import React from 'react';

import { BulkActionItemCard } from './bulk-action-item-card.component';

interface Props {
  data: any[];
}

export const BulkActionItemCardMapper: React.FC<Props> = ({ data }) => (
  <>
    {data.length <= 1
      ? null
      : data.map(({ pharmacy, po_number }) => (
          <BulkActionItemCard
            key={po_number}
            title={pharmacy.name}
            subTitle="Order ID"
            value={po_number}
          />
        ))}
  </>
);
