/* eslint-disable class-methods-use-this */
import stores from '../../../../models/index.model';
import { PtAPIv2 } from '../utils';

/* Default Items. TODO: get items from api */
const defaultOrderItems = [
  {
    name: 'Abisoprolol 5 mg Dexa Medica',
    packaging: 'Dus, 3 Blister @ 10 Tablet Salut Selaput',
    original_price: 25000.0,
    discount: 15,
    net_price: 20000.0,
    quantity: 5,
    status: 1, // checked
  },
  {
    name: 'Abisoprolol 3 mg Dexa Medica',
    packaging: 'Dus, 3 Blister @ 10 Tablet Salut Selaput',
    original_price: 25000.0,
    discount: 15,
    net_price: 20000.0,
    quantity: 3,
    status: 1, // checked
  },
  {
    name: 'Abisoprolol 2 mg Dexa Medica',
    packaging: 'Dus, 3 Blister @ 10 Tablet Salut Selaput',
    original_price: 25000.0,
    discount: 15,
    net_price: 20000.0,
    quantity: 2,
    status: 1, // checked
  },
];

class OrderService {
  // Get All Orders
  async get(rowsPerPage = 0, page = 0, filters = {}) {
    stores.dispatch.uiStore.showLoading(true);
    let result = [];

    const postdata = {
      basicFilters: {
        status: 'pending',
      },
      filters,
      credit_limit: true,
      limit: rowsPerPage,
      skip: page,
      sorting: [
        {
          ordered_at: 'date',
        },
      ],
    };

    await PtAPIv2.admin
      .post(`functions/get-orders`, postdata)
      .then((responseData) => {
        // handle success
        result = [];
        responseData.result.map((item) => {
          return result.push({
            _id: item.id,
            pharmacy_name: item.pharmacy.name,
            distributor: item.distributor.name,
            net_amount: item.net_amount,
            vat: item.tax_rate,
            status: 1, // in progress (use item.status)
            ordered_on: new Date(item.ordered_at),
            invoice_date: new Date(),
            delivery_date: new Date(),
            due_date: new Date(),
            payment_date: new Date(),
            items: defaultOrderItems,
            invoice_no: item.po_number,
            invoiced_amount: item.net_amount,
          });
        });
        // console.log("ORDER LIST API RESPONSE", response, postdata, {
        //   count: 0,
        //   pages: 0,
        //   page: page,
        //   rowPerPage: rowsPerPage,
        //   result: result
        // });
      })
      .catch((error) => {
        // handle error
        console.error(error);
      })
      .finally(() => {
        // always executed
        stores.dispatch.uiStore.showLoading(false);
      });

    return {
      count: 0,
      pages: 0,
      page,
      rowPerPage: rowsPerPage,
      result,
    };
  }

  // Get One Order
  async getOne({ id = 0 }) {
    stores.dispatch.uiStore.showLoading(true);
    let result = {};
    const postdata = {
      purchase_order_id: id,
    };

    await PtAPIv2.admin
      .post(`functions/get-order`, postdata)
      .then((responseData) => {
        // handle success
        result = {
          _id: responseData.result.id,
          pharmacy_name: responseData.result.pharmacy.name,
          distributor: responseData.result.distributor.name,
          net_amount: responseData.result.net_amount,
          vat: responseData.result.tax_rate,
          status: 1, // in progress (use responseData.result.status)
          ordered_on: new Date(responseData.result.ordered_at),
          invoice_date: new Date(),
          delivery_date: new Date(),
          due_date: new Date(),
          payment_date: new Date(),
          items: defaultOrderItems,
          invoice_no: responseData.result.po_number,
          invoiced_amount: responseData.result.net_amount,
        };
      })
      .catch((error) => {
        // handle error
        console.error(error);
      })
      .finally(() => {
        // always executed
        stores.dispatch.uiStore.showLoading(false);
      });
    console.log(result);
    return result;
  }
}

export default new OrderService();
