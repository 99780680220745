import styled from 'styled-components';

const Form = styled.form`
  && {
    height: 40vh;
  }
`;

export const S = {
  Form,
};
