import React, { useDispatch, useGlobal } from 'reactn';

import Tooltip from '@material-ui/core/Tooltip';

import { Button, Dialog } from 'common/components';
import { ColorType } from 'types';

import { S } from './MergeOrderModal.styles';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const MergeOrderConfirmationModal: React.FC<Props> = ({
  open,
  onClose,
}) => {
  const [{ merge, loading }] = useGlobal('transactions');
  const mergeOrders = useDispatch('mergeOrders');

  const { poNumbers } = merge;

  const handleClose = (): void => {
    onClose();
  };

  const handleSubmit = async (): Promise<void> => {
    await mergeOrders({ poNumbers });

    onClose();
  };

  const renderContent = (): JSX.Element => {
    const firstPoNumber = poNumbers[0];
    const otherPoNumbers = [...poNumbers].splice(1);

    return (
      <>
        <S.ConfirmationMessage>
          Are you sure you want to merge
          <br />
          order <S.ListBody>{firstPoNumber}</S.ListBody> and{' '}
          {poNumbers.length === 2 ? (
            <>
              <S.ListBody>{poNumbers[1]}</S.ListBody>?
            </>
          ) : (
            <Tooltip
              title={otherPoNumbers.map((number) => (
                <>
                  {number}
                  <br />
                </>
              ))}
              placement="bottom"
              interactive
            >
              <div>{otherPoNumbers.length} others?</div>
            </Tooltip>
          )}
          <br />
          <br />
          Please note that a new order will be created
          <br /> after confirmation.
        </S.ConfirmationMessage>
      </>
    );
  };

  const renderActions = (): JSX.Element => (
    <>
      <Button
        isSecondary
        color={ColorType.DANGER}
        disabled={loading}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        color={ColorType.PRIMARY}
        disabled={loading}
        loading={loading}
        onClick={handleSubmit}
      >
        Yes, I confirm
      </Button>
    </>
  );

  return (
    <Dialog
      open={open}
      primary
      title="Confirmation"
      onClose={handleClose}
      renderActions={renderActions}
      renderContent={renderContent}
      showCloseButton={false}
    />
  );
};
