import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

interface WrapperProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  disabled?: boolean;
}

const activeWrapperStyles = css`
  && {
    background: #f2fcfa;
    border: 1px solid #40ae96;
    box-shadow: 0px 1px 1px rgba(47, 89, 85, 0.29);
    color: #40ae96;
    font-weight: bold;
  }
`;

const getActiveWrapperStyles = ({
  active,
}: WrapperProps): FlattenSimpleInterpolation | undefined =>
  active ? activeWrapperStyles : undefined;

const getWrapperBackground = ({ disabled }: WrapperProps): string =>
  disabled ? '#fafafa' : '#fff';

const ButtonWrapper = styled.button`
  && {
    background: ${getWrapperBackground};
    border: 1px solid #b2b2b2;
    border-radius: 5px;
    cursor: pointer;
    color: #969696;
    display: inline-block;
    padding: 8px 10px;

    &:focus {
      outline: none;
    }

    ${getActiveWrapperStyles}
  }
`;

const Wrapper = styled.span`
  && {
    padding: 0px;
    margin-left: 4px;
    margin-right: 4px;
    position: relative;
    background-color: orange;
  }
`;

const Notif = styled.p`
  && {
    font-size: 12px;
    position: absolute;
    top: -26px;
    right: -8px;
    padding: 1.5px 5px;
    background-color: red;
    color: white;
    border-radius: 15px;
    z-index: 10;
  }
`;

export const S = {
  Wrapper,
  ButtonWrapper,
  Notif
};
