import React, { useGlobal, useState } from 'reactn';

import { Button, Dialog } from 'common/components';
import { ColorType } from 'types';
import { pluralize } from 'common/utils';

import { SplitOrderItemModalStyles as S } from '../SplitOrderItemModal';
import { useOrderItemReassignForm } from './use-order-item-reassign-form';
import { OrderItemReassignConfirmationModal } from './order-item-reassign-confirmation-modal.component';

export const OrderItemReassignReviewModal: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);

  const [{ orderItemReassign }] = useGlobal('transactions');

  const { resetOptions } = useOrderItemReassignForm();

  const {
    availableDistributors,
    poNumber,
    orderItems,
    selectedDistributorId,
  } = orderItemReassign;

  const handleClose = (): void => {
    resetOptions();

    setSubmitted(false);
  };

  const handleSubmit = (): void => {
    setSubmitted(true);
  };

  const renderContent = (): JSX.Element => {
    const distributor = availableDistributors.find(
      ({ value }) => value === selectedDistributorId,
    );

    return (
      <>
        <S.Description>
          You are about to reassign the following
          <br />
          {pluralize('item', orderItems.length)} of order{' '}
          <strong>{poNumber}</strong> to
          <br />
          <strong>{distributor?.label}</strong>.
          <br />
          <br />
          Please review before submitting.
        </S.Description>
        <S.ListWrapper hideScroll={orderItems.length < 4}>
          {orderItems.map(({ product }) => (
            <S.ListItem>
              <S.ListHeader>{product.name}</S.ListHeader>
              <S.ListBody>{product.package}</S.ListBody>
            </S.ListItem>
          ))}
        </S.ListWrapper>
      </>
    );
  };

  const renderActions = (): JSX.Element => (
    <Button color={ColorType.PRIMARY} onClick={handleSubmit}>
      Submit
    </Button>
  );

  return (
    <>
      <Dialog
        open={!!selectedDistributorId && !submitted}
        primary
        title="Reassign Order Items"
        onClose={handleClose}
        renderActions={renderActions}
        renderContent={renderContent}
      />
      <OrderItemReassignConfirmationModal
        open={submitted}
        onClose={handleClose}
      />
    </>
  );
};
