/* eslint-disable no-param-reassign */

import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { PbfAssignmentList, PBFAssignmentUpload } from './interfaces';
import { PBFFileUpload } from 'pages/pbf-assignment/constants';

export class SwipeRxPtPBFAssignments {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.PBF_ASSIGNMENTS;
  }

  async fetchPbfAssignments(params: any): Promise<PbfAssignmentList> {
    const result = await this.base.get(`${this.resource}/`, params);
    return result;
  }

  async fetchPbfAbbreviations(): Promise<{ data: string[] }> {
    const result = await this.base.get(`${this.resource}/get-available-abbreviations`);
    return result;
  }

  async bulkUpload(
    file: File,
    type: string = PBFFileUpload.PBFAssignment,
  ): Promise<{
    data: any;
  }> {
    const form = new FormData();
    form.append('file', new Blob([await file.arrayBuffer()], { type: 'text/csv' }), file.name);

    return this.base.post(`${this.resource}/bulk-upload/${type}`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async getUploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`${this.resource}/bulk-upload`, {
      page,
      page_size,
    });
  }

  async publish(payload: PBFAssignmentUpload): Promise<void> {
    return this.base.post(`${this.resource}`, payload);
  }
}
