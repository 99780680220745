import { CSSProperties } from '@material-ui/styles';
import { OrganizationStatus } from '.';
import { defaultStyle } from '..';

const optionByOrganizationStatus = (provided: React.CSSProperties, state: any): CSSProperties => ({
  ...provided,
  padding: '12px 20px',
  fontSize: 14,
  fontFamily: 'Nunito Sans, sans-serif',
  fontWeight: 400,
  borderRadius: 0,
  '&:not(:last-child)': {
    borderBottom: '1px solid #ececec',
  },
  backgroundColor: state.data.status === OrganizationStatus.DISABLED ? '#ececec' : provided.backgroundColor
});

const primary = {
  ...defaultStyle,
  option: optionByOrganizationStatus,
  control: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 20,
    padding: '5px 5px',
    cursor: 'text',
    border: '2px solid transparent',
    borderRadius: 10,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ececec',
      transition: 'ease-in 0.3s',
    },
    '&:focus-within': {
      border: '2px solid #269781',
    },
  }),
  input: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 18,
    fontFamily: 'Nunito Sans, sans-serif',
  }),
  placeholder: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 20,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
    color: '#333333',
  }),
};

const secondary = {
  ...defaultStyle,
  option: (provided: React.CSSProperties) => ({
    ...provided,
    padding: '12px 20px',
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
    '&:not(:last-child)': {
      borderBottom: '1px solid #ececec',
    },
  }),
  control: (provided: React.CSSProperties) => ({
    ...provided,
    padding: '5px 5px',
    cursor: 'text',
    border: '2px solid #269781',
    borderRadius: 10,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ececec',
      transition: 'ease-in 0.3s',
    },
  }),
  input: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 16,
    fontFamily: 'Nunito Sans, sans-serif',
  }),
  placeholder: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 16,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 300,
    color: '#333333',
  }),
};

const basic = {
  ...defaultStyle,
  option: optionByOrganizationStatus
};

export const styles = { primary, secondary, basic };
