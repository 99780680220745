import styled from 'styled-components';

const Description = styled.div`
  color: #717786;
  font-size: 16px;
  line-height: 20px;
`;

const ConfirmationMessage = styled.div`
  text-align: center;
`;

export const S = {
  Description,
  ConfirmationMessage,
};
