import qs from 'query-string';

export function removeTrailingSlash(url: string): string {
  return url.replace(/\/$/, '');
}

export function appendParamsToPath(
  path: string,
  params: Record<string, any>,
): string {
  return `${path}?${qs.stringify(params, {
    arrayFormat: 'bracket',
    skipNull: true,
  })}`;
}
