import React from 'react';

import { S } from './ButtonToggle.styles';

interface Props {
  disabled?: boolean;
  name?: string;
  radioMode?: boolean;
  text: string;
  value?: boolean;
  onChange: (value: boolean, name?: string) => void;
  notif?: string | null;
  style?: React.CSSProperties | undefined
}

export const ButtonToggle: React.FC<Props> = ({
  disabled,
  name,
  onChange,
  radioMode,
  text,
  value,
  notif,
  style
}) => {
  const handleClick = (): void => {
    if (radioMode && value) {
      return;
    }

    onChange(!value, name);
  };

  return (
    <S.Wrapper>
      <S.ButtonWrapper
        className="button-toggle"
        active={!!value}
        disabled={disabled}
        type="button"
        onClick={handleClick}
        data-testid={`${text}-btn`}
        style={style}
      >
        {text}
      </S.ButtonWrapper>
      {notif && !value ? <S.Notif>{notif}</S.Notif> : null}
    </S.Wrapper>
  );
};
