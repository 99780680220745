import creditsService from '../services/apis/v2/services/credits.service';

export default {
  state: {
    credits: {
      result: [],
      page: 0,
      itemsPerPage: 0,
      total: 0,
      query: '',
    },
    bulk_upgrade: {
      result: [],
      page: 0,
      itemsPerPage: 0,
      total: 0,
      query: '',
    },
  },
  reducers: {
    setCredits(state, payload) {
      return { ...state, credits: payload };
    },
    setBulkUpgrades(state, payload) {
      return { ...state, bulk_upgrade: payload };
    },
    setCredit(state, payload) {
      const { credits } = state;
      const index = credits.result.findIndex((credit) => credit.id === payload.id);

      credits.result[index] = payload;

      return { ...state, credits };
    },
    setBulkUpgradeFile(state, payload) {
      const { bulk_upgrade } = state;
      bulk_upgrade.result.unshift(payload);

      if (bulk_upgrade.result.length > bulk_upgrade.itemsPerPage) {
        bulk_upgrade.result.pop();
      }

      return { ...state, bulk_upgrade };
    },
  },
  effects: (dispatch) => ({
    async getCreditList({ limit = 10, page = 1, query = '', order_by = 'pharmacy_name', sort_by = 'asc' }) {
      const totalList = await creditsService.getList(limit, page, query, order_by, sort_by);

      dispatch.creditsStore.setCredits({
        result: totalList.result,
        page,
        itemsPerPage: limit,
        total: totalList.total,
        query,
      });
    },

    async getBulkUpgradeFiles({ limit = 10, page = 1, query = '', order_by = 'created_at', sort_by = 'desc' }) {
      const totalList = await creditsService.getBulkUpgradeFiles(limit, page, query, order_by, sort_by);

      dispatch.creditsStore.setBulkUpgrades({
        result: totalList.result,
        page,
        itemsPerPage: limit,
        total: totalList.total,
        query,
      });
    },
    async saveCreditInfo(creditInfo) {
      const savedCreditInfo = await creditsService.saveCreditInfo(creditInfo);

      dispatch.creditsStore.setCredit({
        ...savedCreditInfo,
        total_unpaid: creditInfo.total_unpaid,
      });

      return !!savedCreditInfo;
    },
    async bulkUpgradeFile(file) {
      const savedBulkUpgradeFile = await creditsService.bulkUpgradeFile(file);

      if (savedBulkUpgradeFile.result) {
        dispatch.creditsStore.setBulkUpgradeFile(savedBulkUpgradeFile.result);
      }

      return savedBulkUpgradeFile;
    },
  }),
};
