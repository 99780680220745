/* eslint-disable class-methods-use-this */
import stores from '../../../../models/index.model';
import { PtAPIv2 } from '../utils';

class CreditsService {
  async getList(limit = 10, page = 1, query = '', order_by, sort_by) {
    const queryParams = query
      ? `page_size=${limit}&page=${page}&query=${query}&order_by=${order_by}&sort_by=${sort_by}`
      : `page_size=${limit}&page=${page}&order_by=${order_by}&sort_by=${sort_by}`;

    stores.dispatch.uiStore.showLoading(true);

    const returnData = {
      result: [],
      page,
      itemsPerPage: limit,
      total: 0,
    };

    await PtAPIv2.dashboard
      .get(`credit?${queryParams}`)
      .then((successData) => {
        // handle success
        returnData.result = successData.result.credits;
        returnData.total = successData.result.meta.total_count;
      })
      .catch((error) => {
        // handle error
        console.error('Error on getting Credits: ', error);
      })
      .finally(() => {
        // always executed
        stores.dispatch.uiStore.showLoading(false);
      });
    return returnData;
  }

  async getBulkUpgradeFiles(limit = 10, page = 1, query = '', order_by, sort_by) {
    const queryParams = query
      ? `page_size=${limit}&page=${page}&query=${query}&order_by=${order_by}&sort_by=${sort_by}`
      : `page_size=${limit}&page=${page}&order_by=${order_by}&sort_by=${sort_by}`;

    stores.dispatch.uiStore.showLoading(true);

    const returnData = {
      result: [],
      page,
      itemsPerPage: limit,
      total: 0,
    };

    await PtAPIv2.dashboard
      .get(`credit/bulk-upgrade-files?${queryParams}`)
      .then((successData) => {
        // handle success
        returnData.result = successData.result.bulk_upgrade_files;
        returnData.total = successData.result.meta.total_count;
      })
      .catch((error) => {
        // handle error
        console.error('Error on getting bulk upgrade files: ', error);
      })
      .finally(() => {
        // always executed
        stores.dispatch.uiStore.showLoading(false);
      });
    return returnData;
  }

  async saveCreditInfo(creditInfo) {
    stores.dispatch.uiStore.showLoading(true);

    let returnData = {};

    await PtAPIv2.dashboard
      .post(`credit/upgrade/${creditInfo.pharmacy.id}`, creditInfo)
      .then((successData) => {
        // handle success
        returnData = successData.result;
      })
      .catch((error) => {
        // handle error
        throw new Error(error.message);
      })
      .finally(() => {
        // always executed
        stores.dispatch.uiStore.showLoading(false);
      });
    return returnData;
  }

  async bulkUpgradeFile(file) {
    stores.dispatch.uiStore.showLoading(true);

    const returnData = {
      result: null,
      error: null,
    };

    const formData = new FormData();
    formData.append('file', file);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    await PtAPIv2.dashboard
      .post(`credit/bulk-upgrade`, formData, config)
      .then((successData) => {
        returnData.result = successData.result;
      })
      .catch((error) => {
        returnData.error = error.message || 'Failed to process, Please check your file..';
        // handle error
        console.error('Error on bulk upgrading credits: ', error.message);
      })
      .finally(() => {
        // always executed
        stores.dispatch.uiStore.showLoading(false);
      });

    return returnData;
  }
}

export default new CreditsService();
