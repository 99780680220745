import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';

type Props = {
  open: boolean;
};

const SidebarDrawer = styled(Drawer)`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

export const S = {
  SidebarDrawer,
};
