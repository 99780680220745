import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { FieldInput, FieldInputProps } from '../field-input';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: number | undefined } }) => void;
  name: string;
}

const NumberFormatCustom: React.FC<NumberFormatCustomProps> = ({ onChange, ...props }) => {
  const handleValueChange = ({ floatValue }: NumberFormatValues): void => {
    onChange({ target: { name: props.name, value: floatValue } });
  };

  return <NumberFormat {...props} thousandSeparator decimalScale={2} onValueChange={handleValueChange} />;
};

export const FieldNumber: React.FC<FieldInputProps> = (props) => (
  <FieldInput
    {...props}
    InputProps={{
      inputComponent: NumberFormatCustom as any,
    }}
  />
);
