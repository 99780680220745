import keyBy from 'lodash/keyBy';
import { DataTable } from 'common/reducers';

export const getSelectedInvoicesByIds = (
  ids: number[],
  dataTables: Record<'finance', DataTable>,
): any[] => {
  const { finance } = dataTables;

  const indexedInvoices = keyBy(finance.rows, 'id');
  const selectedInvoices: any[] = [];

  ids.forEach((id) => selectedInvoices.push(indexedInvoices[id]));

  return selectedInvoices;
};
