import { Product } from 'common/interfaces';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';

export class SwipeRxPtSimilarProductsInternal {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.SIMILAR_PRODUCTS;
  }

  async postReport(
    marketId: string,
    skuCodes: string[],
    availableDistributorAbbrs: string[],
    emails: string[],
  ): Promise<Product | {}> {
    const { data } = await this.base.postV2(`${this.resource}/report`, {
      marketId,
      skuCodes,
      availableDistributorAbbrs,
      emails,
    });
    return data;
  }
}
