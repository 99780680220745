import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { COLORS } from 'common/constants';
import { ColorType } from 'types';

type WrapperProps = Omit<ButtonProps, 'color'> & {
  color: ColorType;
  isSecondary?: boolean;
};

const getWrapperBackground = ({ color: colorType, disabled, isSecondary }: WrapperProps): string =>
  disabled ? '#D7D7D7' : isSecondary ? COLORS.secondary : COLORS[colorType];

const getWrapperHoverBackground = ({ color: colorType, isSecondary }: WrapperProps): string =>
  darken(0.05, isSecondary ? '#F6F7F7' : COLORS[colorType]);

const getWrapperColor = ({ color: colorType, disabled, isSecondary }: WrapperProps): string =>
  disabled ? '#9F9F9F' : isSecondary ? COLORS[colorType] : '#fff';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Wrapper = styled(({ color, isSecondary, ...props }) => <Button data-testid="action-btn" {...props} />)`
  && {
    background: ${getWrapperBackground};
    color: ${getWrapperColor};
    font-weight: bold;
    text-transform: none;
    padding: 5px 24px;

    &:hover {
      background: ${getWrapperHoverBackground};
    }
  }
`;

const Loader = styled(CircularProgress)`
  && {
    position: absolute;
  }
`;

const RemoveButton = styled.button`
  && {
    display: flex;
    align-items: center;
    margin-left: 6px;
    cursor: pointer;
    background: #fff;
    border: 0;
    padding: 0;

    &:focus {
      outline: none;
    }
  }
`;

export const S = {
  Wrapper: Wrapper as React.FC<WrapperProps>,
  RemoveButton,
  Loader,
};
