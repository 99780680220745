import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const BaseText = css`
  font-size: 12px;
  font-weight: 400;
`;

const Container = styled(Grid)`
  && {
    padding: 15px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }
`;

const Title = styled(Typography)`
  && {
    font-size: 11px;
    font-weight: 600;
    display: inline-block;
    color: #3500ec;
    padding: 3px 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    background-color: #f1e7ff;
  }
`;

const SubTitle = styled(Typography)`
  && {
    ${BaseText}
    color: #717786;
  }
`;

const Text = styled(Typography)`
  && {
    ${BaseText}
    font-size: 14px;
    color: #181d29;
  }
`;

export const S = { Title, SubTitle, Text, Container };
