/* eslint-disable import/no-cycle */
import { addReducer } from 'reactn';

import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';
import { recordException } from 'utils/Reporting/Sentry';

import { parseStatus } from '../../utils';

export const ORDERS_INITIAL_STATE = {
  orderHashMap: {},
};

addReducer('cancelRequestOrders', (_1, _2, isCancelRequest) => {
  if (isCancelRequest) {
    SwipeRxPt.instance.orders.cancelRequest();
  }
});

addReducer('fetchOrderDataTableRows', async (_, dispatch, { params, cancelPendingRequest }) => {
  try {
    dispatch.cancelRequestOrders(cancelPendingRequest as boolean);
    const { status: initialStatusParam, expand: paramExpand } = params || {};

    const { status, fulfillmentStatus, isReturned, isDelayed } = parseStatus(initialStatusParam);

    const defaultExpand = [
      'invoice',
      'distributor',
      'pharmacy',
      'payment_direct',
      'requirements',
      'logistics_po_delivery',
      'activities',
      'precursor_status',
    ];
    const expand = paramExpand ? [...defaultExpand, ...paramExpand] : defaultExpand;

    /* if (fulfillmentStatus && ['delivered', 'dispatched', 'fulfilled'].includes(fulfillmentStatus)) {
      expand.push('logistics_delivery_status', 'logistics_delivery_status.logistics_delivery_partner');
    } */
    const { data, meta } = await SwipeRxPt.instance.orders.list({
      ...params,
      status,
      fulfillment_status: fulfillmentStatus,
      is_returned: isReturned,
      is_delayed: isDelayed,
      expand,
      no_count: true,
    });

    dispatch.setDataTableRows({
      name: 'orders',
      rows: data,
      params: {
        ...params,
        page_count: meta.page_count,
        total_count: meta.total_count,
      },
    });
  } catch (e) {
    recordException(e, 'fetchOrderDataTableRows', { params });
    dispatch.setDataTableError({ name: 'orders', error: e.message });
  }
});

addReducer('fetchOrderById', async (state, _, orderId) => {
  const { orders } = state;
  const { orderHashMap } = orders;

  try {
    const data = await SwipeRxPt.instance.orders.retrieve(orderId, {
      expand: ['pharmacy', 'items', 'items.product', 'invoice', 'distributor', 'logistics_po_delivery'],
    });

    orderHashMap[data.id] = data;

    return {
      ...state,
      orders: {
        ...orders,
        orderHashMap: {
          ...orderHashMap,
        },
      },
    };
  } catch (error) {
    recordException(error, 'fetchOrderById', { orderId });
    return {
      ...state,
      orders: {
        ...orders,
        orderHashMap: {
          ...orderHashMap,
        },
        error: error.message,
      },
    };
  }
});
