/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDispatch, useEffect, useGlobal } from 'reactn';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

export interface OrderDeliverFormState {
  date: string;
}

const formState: OrderDeliverFormState = {
  date: moment().format('YYYY-MM-DD'),
};

export const useOrderDeliverForm = () => {
  const [{ deliver }] = useGlobal('transactions');
  const deliverOrders = useDispatch('deliverOrders');
  const setOrderIdsForDeliver = useDispatch('setOrderIdsForDeliver');

  const clearOrderIds = (): void => {
    setOrderIdsForDeliver({ orderIds: [] });
  };

  const form = useFormik({
    initialValues: {
      ...formState,
    },
    validationSchema: Yup.object({
      date: Yup.date().required('Date field is required.'),
    }),
    onSubmit: ({ date }, { setSubmitting }) => {
      const newDate = moment(date).format('YYYY-MM-DD');
      deliverOrders({ date: newDate });
      setSubmitting(false);
      clearOrderIds();
    },
  });

  useEffect(() => {
    form.setValues({ ...formState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliver.orderIds]);

  return { form, deliver, clearOrderIds };
};
