import styled from 'styled-components';

interface SinglePageProp {
  pageSize?: number;
  marginBottom?: number;
}
const getSinglePagePageSize = ({ pageSize = 1064 }: SinglePageProp): string =>
  `${pageSize}px`;

const getSinglePageMarginBottom = ({
  marginBottom = 68,
}: SinglePageProp): string => `${marginBottom}px`;

const PrimaryDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  .po-qrcode {
    margin-top: 10px;
  }
`;

const Address = styled.div`
  flex: 0 0 50%;
  margin-bottom: 30px;
`;

const OrderDetails = styled.div`
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;

  .order-meta-data {
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      justify-content: flex-end;

      strong {
        margin-right: 6px;
      }
    }
  }

  .order-totals-container {
    margin: 20px 0;
  }
`;

const SinglePage = styled.div<SinglePageProp>`
  background-color: #fff;
  min-height: ${getSinglePagePageSize};
  position: relative;
  margin-bottom: ${getSinglePageMarginBottom};
`;

const MainTable = styled.table`
  border-bottom: 1px solid #ccc;
  width: 100%;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  table-layout: fixed;

  & th {
    text-transform: uppercase;
    background: #ccc;
    border-radius: 0;
    color: #333;
    font-weight: bold;
    padding: 3px;
    border: 1px solid #fff;
    font-size: 10px;
  }

  & tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 10px;

    &:last-child {
      border-bottom: 0;
    }
  }

  & td {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
    padding: 8px 10px;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 10px;

  }

  & td.package,
  & td.product {
    font-size: 8px;
  }
  & td.center {
    text-align: center;
  }
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
`;

const HorizontalRule = styled.hr`
  border-width: 2.5px;
  border-style: solid;
  border-color: rgba(9, 30, 66, 0.08);
  border-image: initial;
  border-radius: 1px;
  width: 100%;
  position: absolute;
`;

const Pagination = styled.div`
  width: 100%;
  text-align: end;
  position: absolute;
  padding-top: 26px;
`;

export const S = {
  PrimaryDataContainer,
  Address,
  OrderDetails,
  SinglePage,
  MainTable,
  TotalRow,
  Footer,
  Pagination,
  HorizontalRule,
};
