/* eslint-disable no-restricted-globals */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Dialog } from 'common/components/Dialog';
import './RejectReasonModal.css';
import { useDispatch, useGlobal } from 'reactn';
import { useUpdateQueryParams } from 'hooks';
import { OrderDetailsResponse, PrecursorRejectReason, PrecursorRejectReasonObject } from './PrecursorDetail.type';
import { PrecursorReasonCheckbox } from './PrecursorDetailPage.style';
import { PrecursorRejectReasonEnglishText, PrecursorRejectReasonFreeText } from './PrecursorDetail.constant';

type Props = {
  open: boolean;
  orderDetails: OrderDetailsResponse;
  onClose: () => void;
};

const RejectReasonModal: React.FC<Props> = (props: Props) => {
  const FREE_TEXT_CHAR_LIMIT = 140;
  const { open, orderDetails, onClose } = props;
  const [{ loading }] = useGlobal('precursor');
  const precursorRejectReasonDefaultValuesToFalse = Object.assign(
    {},
    ...Object.values(PrecursorRejectReason).map((r) => ({ [r]: false })),
  );
  const precursorRejectReasonDefaultFreeText = Object.assign(
    {},
    ...Object.values(PrecursorRejectReason).map((r) => ({ [r]: null })),
  )

  const [checked, setChecked] = useState<Record<PrecursorRejectReason, boolean>>(
    precursorRejectReasonDefaultValuesToFalse,
  );
  const { queryParams } = useUpdateQueryParams();
  const { id } = queryParams;
  const [checkedOthers, setCheckedOthers] = useState(false);
  const [description, setDescription] = useState('');
  const [rejectButtonDisabled, setRejectButtonDisabled] = useState(true);
  const [rejectFreeText, setRejectFreeText] = useState<Record<PrecursorRejectReason, string|null>>(
    precursorRejectReasonDefaultFreeText,
  );
  const rejectPrecursor: any = useDispatch('rejectPrecursor');

  const handleChangeReason = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setDescription(value);
  };

  const handleChangeReasonFreeText = (event: React.ChangeEvent<HTMLInputElement>, rejectReason: PrecursorRejectReason): void => {
    const { value } = event.target;
    setRejectFreeText({
      ...rejectFreeText,
      [rejectReason]: value || null,
    });
  };


  const uncheckRejectionChecklist = useCallback(() => {
    setChecked(precursorRejectReasonDefaultValuesToFalse);
    setRejectFreeText(precursorRejectReasonDefaultFreeText);
  }, [checkedOthers]);

  const resetForm = (): void => {
    setChecked(precursorRejectReasonDefaultValuesToFalse);
    setCheckedOthers(false);
    setDescription('');
  };
  const handleClose = (): void => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (checkedOthers) uncheckRejectionChecklist();

    const payload = Object.keys(checked).filter((check) => checked[check as PrecursorRejectReason]);

    if (payload.length === 0 && !checkedOthers && !description) setRejectButtonDisabled(true);
    else if (payload.length === 0 && checkedOthers && !description) setRejectButtonDisabled(true);
    else setRejectButtonDisabled(false);
  }, [checkedOthers, checked, description]);

  const handleRejection = (): void => {
    const payload = Object.keys(checked)
      .filter((check) => checked[check as PrecursorRejectReason])
      .map((value) => ({ name: value, text: (rejectFreeText[value as PrecursorRejectReason])}));
    // send to API
    rejectPrecursor({
      id: orderDetails.data?.precursor_status?.id,
      payload: { reason: payload, description },
    });
  };
  const content = (): JSX.Element => {
    return (
      <DialogContent>
        <FormLabel style={{ fontWeight: 'bold' }} required>
          Reason
        </FormLabel>
        <FormControl style={{ position: 'relative', width: '100%', padding: '20px 0px 20px 0px' }} component="fieldset">
          <Grid container direction="column">
            {Object.values(PrecursorRejectReason).map((value: PrecursorRejectReason, index: number) => {
              return (
                <>
                <Grid item>
                  <FormControlLabel
                    // eslint-disable-next-line react/no-array-index-key
                    key={`precursor-rejection-${index}`}
                    value={`${index}`}
                    control={<PrecursorReasonCheckbox size="small" checked={checked[value]} />}
                    label={
                      <Typography style={{ whiteSpace: 'nowrap', fontSize: '12px !important' }}>
                        {PrecursorRejectReasonEnglishText[value]}
                      </Typography>
                    }
                    style={{ marginTop: -5, marginBottom: -5 }}
                    onClick={() => {
                      setCheckedOthers(false);
                      setDescription('');
                      setChecked({
                        ...checked,
                        [value]: !checked[value],
                      });
                      setRejectFreeText({
                        ...rejectFreeText,
                        [value]: !checked[value] ? null : rejectFreeText[value],
                      })
                    }}
                  />
                </Grid>
                {/* Row Free Text Implementation */}
                <Grid item style={{ paddingBottom: '10px' }}>
                  {(checked[value] && PrecursorRejectReasonFreeText.includes(value)) && (
                    <FormControl fullWidth>
                      <TextField
                        id={`reject-reason-${value}`}
                        multiline
                        rows={2}
                        value={rejectFreeText[value]}
                        variant="outlined"
                        placeholder="Type additional reason here ..."
                        inputProps={{ maxLength: FREE_TEXT_CHAR_LIMIT }}
                        helperText={`${rejectFreeText[value]?.length || 0}/${FREE_TEXT_CHAR_LIMIT}`}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeReasonFreeText(event, value)}
                      />
                    </FormControl>
                  )}
                </Grid>
                </>
              );
            })}
            <Grid item>
              <FormControlLabel
                key="precursor-rejection-others"
                value="others"
                control={<PrecursorReasonCheckbox size="small" checked={checkedOthers} />}
                label={
                  <Typography style={{ whiteSpace: 'nowrap', fontSize: '12px !important' }}>Others ...</Typography>
                }
                style={{ marginTop: -5, marginBottom: -5 }}
                onClick={() => {
                  setCheckedOthers(!checkedOthers);
                }}
              />
            </Grid>
            <Grid item style={{ paddingBottom: '10px' }}>
              {checkedOthers && (
                <FormControl fullWidth>
                  <FormLabel component="legend">Reason</FormLabel>
                  <TextField
                    id="reject-reason"
                    multiline
                    rows={2}
                    value={description}
                    variant="outlined"
                    placeholder="Type reason here ..."
                    onChange={handleChangeReason}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item>
              <Button
                disabled={loading || rejectButtonDisabled}
                onClick={handleRejection}
                style={{ float: 'right' }}
                variant="contained"
                color="primary"
              >
                {loading ? 'Loading...' : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>
    );
  };
  return (
    <>
      <Dialog
        fullWidth
        title="Rejection Reason"
        renderContent={content}
        primary
        onClose={handleClose}
        open={open}
        disableBackdropClick
      />
    </>
  );
};

export default RejectReasonModal;
