/* eslint-disable no-param-reassign */

import { IUserModel, ICreateUserInput, IUpdateUserStatus, ICreatePTUserInput } from 'common/models/user.model';
import { CSVUploadResult } from 'common/components/CsvUpload';
import { ISearchable, ListResponse, ISearchParam } from '../../interfaces';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { IMutable } from '../../interfaces/IMutable.interface';

export class SwipeRxPtUsers implements ISearchable<IUserModel>, IMutable<ICreateUserInput> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.USERS;
  }

  async createOrUpdate(data: ICreateUserInput): Promise<any> {
    return this.base.postV2(`${this.resource}/create-pharmacy`, data);
  }

  async activatePharmacyUser(data: IUpdateUserStatus): Promise<any> {
    return this.base.patch(`${this.resource}/${data.id}/activate`, { organization_id: data.organization_id });
  }

  async deactivatePharmacyUser(data: IUpdateUserStatus): Promise<any> {
    return this.base.patch(`${this.resource}/${data.id}/deactivate`, data);
  }

  async search(params: ISearchParam): Promise<ListResponse<IUserModel>> {
    return this.base.get(this.resource, { ...params });
  }

  async getRole(userId: number): Promise<any> {
    return this.base.get(`${this.resource}/${userId}/role`);
  }

  async getUserLink(userId: number, urlToAppend?: string): Promise<any> {
    const result = urlToAppend
      ? this.base.get(`${this.resource}/${userId}/user-link?urlToAppend=${urlToAppend}`)
      : this.base.get(`${this.resource}/${userId}/user-link`);
    return result;
  }

  async uploadCsv(
    file: File,
  ): Promise<{
    data: CSVUploadResult;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );
    return this.base.post(`${this.resource}/bulk-create?async=true`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async uploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`${this.resource}/upload/history`, {
      page,
      page_size,
    });
  }

  // This function is call for registration of pt user with existing account from community
  async createPTUser(data: ICreatePTUserInput): Promise<any> {
    return this.base.postV2(`${this.resource}/create-pharmacy-pt`, data);
  }
}
