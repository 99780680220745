import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        font-family: Arial !important;
        box-sizing: border-box;
        font-size: 12px;
    }

    body {
        background: #fff;
    }

    html, body, .container {
        width: 595pt;
        line-height: 1.5;
        font-size: 14px;
        color: rgba(0,0,0,.65);
    }

    nav {
        display: none !important 
    }

    @media print {
        .btn-save {
            display: none !important;
        }
    }

    @page {
        size: A4; /* auto is the initial value */
        margin: 0; /* this affects the margin in the printer settings */
    }
`;

const Container = styled.div`
  padding: 0 10px;
  width: 100%;
  background: #fff;
`;

const Content = styled.div`
  padding-top: 20px;
`;

const PrintButton = styled.button`
  float: right;
  position: relative;
  left: 6pt;
  background: #26a69a;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 15px;
  font-weight: bold;
  cursor: pointer;

  &:active {
    outline: none;
  }
`;

const DocBody = styled.div`
  display: inline-block;
  padding: 48px 48px 0px;
  width: 100%;

  &.print-delivery-proof,
  &.print-delivery-docs,
  &.print-invoice-docs,
  &.bulk-print {
    padding: 0 48px 48px;
  }
`;

export const S = {
  GlobalStyle,
  Container,
  Content,
  PrintButton,
  DocBody,
};
