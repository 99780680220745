import { useGetAuthenticatedUser } from 'hooks';

import { UserRoles } from 'common/constants';
import { getAllowedRolesWoForbidden } from 'utils/get-allowed-roles';
import { getIsActionAllowed } from 'utils/get-is-action-allowed';

interface UseIsRoleAllowedParam {
  allowedRoles?: UserRoles[];
  forbiddenRoles?: UserRoles[];
  action?: string;
}

export const useIsRoleAllowed = ({ allowedRoles, forbiddenRoles, action }: UseIsRoleAllowedParam): boolean => {
  const { userType: userRole } = useGetAuthenticatedUser();

  if (action) {
    return getIsActionAllowed(action, userRole)
  }

  const allowedRoleList =
    (forbiddenRoles ? getAllowedRolesWoForbidden(forbiddenRoles, allowedRoles) : allowedRoles) ||
    Object.values(UserRoles);

  const isRoleAllowed = allowedRoleList.includes(userRole);

  return isRoleAllowed;
};
