import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';

interface Props {
  active: boolean;
}

export const FieldFileUploadIcon: React.FC<Props> = ({ active }) => (
  <>
    {active ? (
      <CloudDoneIcon color="primary" style={{ paddingRight: '8px' }} />
    ) : (
      <CloudUploadIcon style={{ paddingRight: '8px' }} />
    )}
  </>
);
