import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import numeral from 'numeral';
import { FieldInput, FieldInputProps, OptionalProps } from '../field-input';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: number | undefined } }) => void;
  name: string;
}

const NumberFormatCustom: React.FC<NumberFormatCustomProps> = ({ onChange, ...props }) => {
  const handleValueChange = ({ floatValue }: NumberFormatValues): void => {
    onChange({ target: { name: props.name, value: floatValue } });
  };

  return (
    <NumberFormat
      {...props}
      thousandSeparator
      prefix={`${numeral.localeData().currency.symbol} `}
      onValueChange={handleValueChange}
    />
  );
};

const RoundedNumberFormatCustom: React.FC<NumberFormatCustomProps> = ({ onChange, ...props }) => {
  const handleValueChange = ({ floatValue }: NumberFormatValues): void => {
    if (typeof floatValue === 'number')
      onChange({ target: { name: props.name, value: Math.round(floatValue as number) } });
  };

  return (
    <NumberFormat
      {...props}
      thousandSeparator
      prefix={`${numeral.localeData().currency.symbol} `}
      onValueChange={handleValueChange}
    />
  );
};

export const FieldCurrency: React.FC<FieldInputProps & OptionalProps> = (props) => (
  <FieldInput
    data-testid="field-currency-input"
    {...props}
    InputProps={{
      inputComponent: NumberFormatCustom as any,
    }}
  />
);

export const RoundedFieldCurrency: React.FC<FieldInputProps> = (props) => (
  <FieldInput
    data-testid="rounded-field-currency-input"
    {...props}
    InputProps={{
      inputComponent: RoundedNumberFormatCustom as any,
    }}
  />
);
