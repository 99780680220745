export const defaultStyle = {
  menuPortal: (provided: React.CSSProperties) => ({
    ...provided,
    zIndex: 9999,
  }),
  option: (provided: React.CSSProperties) => ({
    ...provided,
    padding: '12px 20px',
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
    borderRadius: 0,
    '&:not(:last-child)': {
      borderBottom: '1px solid #ececec',
    },
  }),
  control: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 14,
    padding: '13px 10px',
    cursor: 'text',
    border: '1px solid #C6C6C6',
    borderRadius: 4,
    boxShadow: 'none',
    '&:focus-within': {
      border: '1px solid #C6C6C6',
    },
  }),
  input: (provided: React.CSSProperties) => ({
    ...provided,
    borderRadius: 0,
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
  }),
  placeholder: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
    color: '#a4a4a4',
  }),
  noOptionsMessage: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};
