import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  root: {
    padding: '10mm',
    paddingBottom: '15mm',
    paddingHorizontal: '10mm',
  },
  deliveryInformationContainer: {
    width: '190mm',
    maxWidth: '190mm',
    fontFamily: 'Sarabun',
    flexDirection: 'row',
    marginTop: '2mm',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginHorizontal: '0pt',
  },
  mClinicaAddress: {
    fontSize: '3mm',
    fontWeight: 'thin',
    flexWrap: 'wrap',
    maxWidth: '65mm',
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.35,
  },
  addressesContainer: {
    width: '190mm',
    maxWidth: '190mm',
    fontFamily: 'Sarabun',
    flexDirection: 'row',
    marginTop: '5mm',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  address: {
    fontSize: '3mm',
    fontWeight: 'thin',
    flexWrap: 'wrap',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '10%',
  },
  formsContainer: {
    width: '190mm',
    maxWidth: '190mm',
    fontFamily: 'Sarabun',
    flexDirection: 'column',
    marginTop: '8mm',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formTitle: {
    fontSize: '4mm',
    fontWeight: 'bold',
    flexWrap: 'wrap',
    display: 'flex',
  },
  signatureBlank: {
    width: '40mm',
    minWidth: '40mm',
    borderBottom: '1pt solid #666666',
    marginBottom: '4mm',
  },
  signageContainer: {
    width: '190mm',
    maxWidth: '190mm',
    fontFamily: 'Sarabun',
    flexDirection: 'row',
    marginTop: '8mm',
    justifyContent: 'center',
    alignItems: 'center',
  },
  signature: {
    flexWrap: 'wrap',
    display: 'flex',
    width: '95mm',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  signatureLabel: {
    fontSize: '3mm',
    fontWeight: 'bold',
    marginBottom: '6mm',
  },
  formSeparator: {
    width: '190mm',
    borderBottom: '1pt dotted #bbb',
    marginBottom: '6mm',
    marginTop: '6mm',
  },
  notesContainer: {
    width: '190mm',
    maxWidth: '190mm',
    fontFamily: 'Sarabun',
    flexDirection: 'row',
    marginTop: '4mm',
    marginBottom: '8mm',
    justifyContent: 'flex-start',
    fontSize: '3mm',
  },
  qrcodeContainer: {
    alignItems: 'flex-start',
    flexGrow: 1,
    maxWidth: '70mm',
    width: '70mm',
  },
  qrcode: {
    width: '25mm',
    height: '25mm',
  },
  pageNumber: {
    position: 'absolute',
    bottom: '12mm',
    right: '12mm',
    fontFamily: 'Sarabun',
    fontSize: '3mm',
    fontWeight: 'thin',
  },
});
