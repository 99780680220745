import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { darken } from 'polished';

import { COLORS } from 'common/constants';

interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {
  basic?: boolean;
  disabled?: boolean;
  scrollable?: boolean;
}

const tableBasicStyles = css`
  thead tr {
    background: #fafafa;
  }

  tbody {
    box-shadow: none;

    &.disabled {
      background: rgba(228, 228, 228, 0.5);
      color: ${COLORS.gray};
    }

    &:nth-child(odd) {
      background: #fafafa;
    }

    &:first-of-type,
    &:last-of-type {
      tr td:first-child,
      tr td:last-child {
        border-radius: 0;
      }
    }
  }

  tr {
    border: 1px solid ${darken(0.15, '#fafafa')};

    td {
      border-bottom: 0;
      vertical-align: top;
    }
  }
`;

const getTablePointerEvents = ({ disabled }: TableProps): string | undefined =>
  disabled ? 'none' : undefined;

const getTableBasicStyles = ({
  basic,
}: TableProps): FlattenSimpleInterpolation | undefined =>
  basic ? tableBasicStyles : undefined;

const getTableMargin = ({ scrollable }: TableProps): string | undefined =>
  !scrollable ? '20px 0' : undefined;

const Table = styled.table<TableProps>`
  && {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
    pointer-events: ${getTablePointerEvents};
    width: 100%;
    margin: ${getTableMargin};

    ${getTableBasicStyles}
  }
`;

const HeaderWrapper = styled.div`
  margin-top: 20px;
`;

const BodyWrapper = styled.div`
  margin-bottom: 20px;
  overflow-y: auto;
`;

export const S = {
  Table,
  HeaderWrapper,
  BodyWrapper,
};
