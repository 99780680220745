/* eslint-disable  no-useless-escape */

const URL_PATTERN = /^(https?):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/i;

const NUMBER_PATTERN = /(^\+?(\(628\)|62 ?8))([\d -]+)$/;

const NUMBER_ONLY = /^\d+$/;

export const REGEX = {
  URL_PATTERN,
  NUMBER_ONLY,
  NUMBER_PATTERN,
};
