import { RoundedFieldCurrency, FieldInput } from 'common/components';

import { OrderInvoiceFormState } from './use-order-invoice-form';

type OrderInvoiceFormKeys = keyof OrderInvoiceFormState;

interface FieldOptions {
  name: OrderInvoiceFormKeys;
  component: React.FC<any>;
  label?: string;
  placeholder?: string;
  type?: string;
}

export const fieldOptions: FieldOptions[] = [
  {
    name: 'invoice_number',
    component: FieldInput,
    label: 'Invoice Number',
    placeholder: 'Enter invoice number',
  },
  {
    name: 'invoiced_amount',
    component: RoundedFieldCurrency,
    label: 'Invoice Amount',
    placeholder: 'Enter invoice amount',
  },
  {
    name: 'invoiced_at',
    component: FieldInput,
    placeholder: 'Enter invoice date',
    label: 'Date',
    type: 'date',
  },
];
