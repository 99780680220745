import React, { useState } from 'react';

import { FormControlLabel, Switch, SwitchProps } from '@material-ui/core';

import { ColorType } from 'types';

interface Props {
  onChange: any;
  name: string;
  values: boolean;
  label?: string;
  labelPlacement?: 'top' | 'end' | 'start' | 'bottom';
  style?: React.CSSProperties;
}

export const FieldSwitch: React.FC<Props & SwitchProps> = ({
  onChange,
  name,
  values,
  label,
  disabled,
  labelPlacement = 'end',
  style,
}) => {
  const [checked, setChecked] = useState(values);
  const toggleChecked = (e: any): void => {
    const newValue = !checked;
    onChange(e);
    setChecked(newValue);
  };
  return (
    <FormControlLabel
      style={style}
      control={
        <Switch
          name={name}
          size="medium"
          checked={checked}
          onChange={(e) => toggleChecked(e)}
          color={ColorType.PRIMARY}
          disabled={disabled}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};
