import React from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      paddingRight: theme.spacing(1),
      fontWeight: 300,
    },
    closeButton: {
      color: '#000000',
      fontWeight: 400,
    },
  });
});

export interface IModalTitleProps {
  id: string;
  title: string;
  isCloseDisabled?: boolean;
  onClose?: () => void;
  style?: React.CSSProperties;
}

const ModalTitle: React.FC<IModalTitleProps> = (props) => {
  const classes = useStyles();

  const { title = '', isCloseDisabled = false, onClose, style } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} style={style}>
      <Typography className={classes.title} variant="h5">
        {title}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          disabled={isCloseDisabled}
          data-testid="close-btn"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default ModalTitle;
