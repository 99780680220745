import { useLayoutEffect, useState } from 'react';

export function useWindowSize(): number[] {
  // solution from https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize(): void {
      setSize([window.innerWidth, window.innerHeight - 5]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

// Solution to long unbreakable word https://stackoverflow.com/a/14487422/7288471
function testWhite(x: string): boolean {
  const white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
}

export const textWrapper = (s: string, maxWidth = 32): string => {
  const newLineStr = '\n';
  let res = '';
  let str = s;
  while (str && str.length > maxWidth) {
    let found = false;
    // Inserts new line at first whitespace of the line
    // eslint-disable-next-line no-plusplus
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (testWhite(str.charAt(i))) {
        res += [str.slice(0, i), newLineStr].join('');
        str = str.slice(i + 1);
        found = true;
        break;
      }
    }
    // Inserts new line at maxWidth position, the word is too long to wrap
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join('');
      str = str.slice(maxWidth);
    }
  }

  return res + str;
};
