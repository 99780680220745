import React, { useState } from 'reactn';
import { Button, Typography, FormControlLabel, Checkbox, FormGroup } from '@material-ui/core';
import { Dialog } from '..';

export interface PagesCheckboxModalOptions {
  showCloseButton?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  accept?: string;
  cancel?: string;
  variant?: 'primary' | 'danger';
}

export interface PagesCheckboxModalProps {
  title: string;
  totalPages: number;
  message?: string | JSX.Element;
  options?: PagesCheckboxModalOptions;

  onCancel: () => void;
  onAccept: () => void;
  setSelectedPages: React.Dispatch<React.SetStateAction<number[]>>;
}

export const PagesCheckboxModal: React.FC<PagesCheckboxModalProps> = ({
  title,
  totalPages,
  message,
  options,

  onCancel,
  onAccept,
  setSelectedPages,
}) => {
  const { showCloseButton = true, maxWidth = 'xs', accept = 'Download Pages', cancel = 'Cancel', variant = 'primary' } =
    options || {};
  const [checkedBoxes, setCheckedBoxes] = useState<boolean[]>(new Array(totalPages).fill(false));

  const handleCheckboxChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCheckedBoxes = [...checkedBoxes];
    updatedCheckedBoxes[index] = event.target.checked;
    setCheckedBoxes(updatedCheckedBoxes);

    const indices: number[] = [];

    updatedCheckedBoxes.forEach((checkBox, index) => {
      if (checkBox === true) {
        indices.push(index + 1);
      }
    });
    setSelectedPages(indices);
  };

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Typography style={{ paddingLeft: 8 }}>{message}</Typography>
        <div style={{ height: '150px', margin: '20px', overflow: 'auto' }}>
          <FormGroup>
            {Array.from({ length: totalPages }).map((_, index) => (
              <FormControlLabel
                key={`Page-${index + 1}`}
                control={
                  <Checkbox color="default" checked={checkedBoxes[index]} onChange={handleCheckboxChange(index)} />
                }
                label={`Page ${index + 1}`}
              />
            ))}
          </FormGroup>
        </div>
      </>
    );
  };

  const renderActions = (): JSX.Element => {
    const acceptProps: any = {
      color: 'primary',
      variant: 'contained',
      onClick: onAccept,
    };
    return (
      <>
        <Button data-testid="reset-cancel-btn" fullWidth variant="outlined" color="primary" onClick={onCancel}>
          {cancel}
        </Button>
        {variant === 'primary' && (
          <Button fullWidth {...acceptProps}>
            {accept}
          </Button>
        )}
      </>
    );
  };

  return (
    <Dialog
      open
      primary
      fullWidth
      showCloseButton={showCloseButton}
      maxWidth={maxWidth}
      title={title}
      onClose={onCancel}
      renderContent={renderContent}
      renderActions={renderActions}
    />
  );
};
