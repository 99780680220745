import React from 'react';
import styled from 'styled-components';

import { ReactComponent as SortIconComponent } from 'common/images/icon_sort.svg';

interface WrapperProps
  extends React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
    HTMLTableHeaderCellElement
  > {
  active?: boolean;
  sort?: boolean;
}

const getWrapperCursor = ({ sort }: WrapperProps): string =>
  sort ? 'pointer' : 'default';

const getWrapperFontWeight = ({ active }: WrapperProps): string =>
  active ? 'bold' : 'normal';

const Wrapper = styled.th`
  && {
    cursor: ${getWrapperCursor};
    color: rgba(0, 0, 0, 0.7);
    font-size: 13px;
    font-weight: ${getWrapperFontWeight};
    padding: 15px 10px;
    text-align: left;

    && {
      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }
  }
`;

const SortIcon = styled(SortIconComponent)`
  margin-left: 6px;
`;

export const S = {
  Wrapper,
  SortIcon,
};
