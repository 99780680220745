export enum InvoiceStatus {
  PENDING = 'pending',
  INVOICED = 'invoiced',
  DELIVERED = 'delivered',
  PARTIAL = 'partial',
  PAID = 'paid',
  CLAIMED = 'claimed',
  QUEUED = 'queued',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
}
