import { TRANSACTION_ACCESS_ROLES, UserRoles } from 'common/constants';
import { IModuleConfig } from './interfaces';

/*
Feature Configs was added to enable and disable features via market config
We can disable/enable the feature in future by just changing market_config instead of release
*/
export const updateConfiguredModulesByFeatureConfigs = (
  modules: IModuleConfig,
  featureConfigs?: any,
): IModuleConfig => {
  if (!featureConfigs) return modules;

  const { distributorStaffAccess } = featureConfigs;
  if (distributorStaffAccess === 'disabled') {
    return {
      ...modules,
      transactions: {
        'view-module': TRANSACTION_ACCESS_ROLES.filter((role) => role !== UserRoles.DISTRIBUTOR_STAFF),
      },
    };
  }
  return modules;
};
