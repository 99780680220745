/* eslint-disable no-param-reassign */

import { CSVUploadResult } from 'common/components/CsvUpload/interfaces/csv-upload.interface';
import { Product, ProductBigQueryProps } from 'common/interfaces';
import { ProductFileUpload } from 'pages/ProductPage/components/UploadPage/csv-upload-constants';
import { ListMeta } from '../..';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';

import { ProductDistributorListParams, ProductDistributorList } from './interfaces';
import { ProductCreate, ProductPatch } from './types';
import { IProductPricegroupMappingList } from '../price-group/interface/ProductPriceGroup.interface';

export class SwipeRxPtProducts {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.PRODUCTS;
  }

  async retrieve(id: number): Promise<Product> {
    const { data } = await this.base.get(`${this.resource}/${id}`);

    return data;
  }

  async createProduct(product: ProductCreate): Promise<Product> {
    const blob = new Blob([JSON.stringify(product)], {
      type: 'application/json',
    });

    const form = new FormData();
    form.append('file', blob);

    const { data } = await this.base.post(`${this.resource}`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
    return data;
  }

  async patchProduct(id: number, product: ProductPatch): Promise<Product | {}> {
    const blob = new Blob([JSON.stringify(product)], {
      type: 'application/json',
    });

    const form = new FormData();
    form.append('file', blob);

    const { data } = await this.base.patch(`${this.resource}/${id}`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
    return data;
  }

  async search(query: {
    term?: string;
    fields?: string[];
    page: number;
    page_size: number;
  }): Promise<{
    data: Product[];
    meta: ListMeta;
  }> {
    return this.base.get(SwipeRxPtV3Resources.PRODUCTS, {
      ...query,
    });
  }

  async bulkUploadImage(
    files: File[],
  ): Promise<{
    data: Product[];
    meta: ListMeta;
  }> {
    const form = new FormData();

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      form.append('files[]', file, file.name);
    }

    return this.base.post(`${this.resource}/images`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async bulkUpsertProducts(
    file: File,
    type: string = ProductFileUpload.Products,
  ): Promise<{
    data: CSVUploadResult;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );
    return this.base.post(`${this.resource}/bulk-upload/${type}`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async getUploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`${SwipeRxPtV3Resources.PRODUCTS}/bulk-upload`, {
      page,
      page_size,
    });
  }

  async listProductDistributors(id: number, params?: ProductDistributorListParams): Promise<ProductDistributorList> {
    const result = await this.base.get(`${this.resource}/${id}/distributor-mapping`, params);

    return result;
  }

  async listProductProductPriceGroup(
    id: number,
    params?: ProductDistributorListParams,
  ): Promise<IProductPricegroupMappingList> {
    const result = await this.base.get(`${this.resource}/${id}/price-group-mapping`, params);

    return result;
  }

  async validateSku(params: { sku_code: string; product_id?: number }): Promise<any> {
    return this.base.get(`${this.resource}/validate-sku`, params);
  }

  async getProductBigQueryByID(id: number): Promise<{ data: Partial<ProductBigQueryProps>; status: number }> {
    return this.base.get(`${this.resource}/${id}/bigquery`);
  }
}
