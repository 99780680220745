export type DayIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const days: Record<DayIndex, string> = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

export const DateFormat = 'D MMM YYYY';
