import React from 'reactn';

import { Route, useRouteMatch } from 'react-router-dom';

import { ShowIf } from 'common/components';

import { TemplatesContainer } from './Templates.container';
import { S } from './PrintPage.styles';
import { LoadingComponent } from './components';

export const PrintPage: React.FC = () => {
  const match = useRouteMatch<{ template: string }>();
  const [isLoadingPage, setIsLoadingPage] = React.useState(true);
  const {
    params: { template },
  } = match;

  const handlePdfPrint = (): void => {
    window.print();
  };

  const loadingProps = { isLoadingPage, setIsLoadingPage };

  const ConditionalWrapper = ({ condition, wrapper, children }: any): JSX.Element =>
    condition ? wrapper(children) : children;

  return (
    <>
      <ShowIf condition={isLoadingPage}>
        <LoadingComponent />
      </ShowIf>

      {!isLoadingPage && <S.GlobalStyle />}
      <ConditionalWrapper condition={!isLoadingPage} wrapper={(children: any) => <S.Container>{children}</S.Container>}>
        <S.Content>
          {!isLoadingPage && (
            <S.PrintButton className="btn-save" onClick={handlePdfPrint}>
              Print Document
            </S.PrintButton>
          )}
          <ConditionalWrapper
            condition={!isLoadingPage}
            wrapper={(children: any) => <S.DocBody className={`${template} doc-body`}>{children}</S.DocBody>}
          >
            <Route
              path={`${match.path}/:id`}
              render={(props) => <TemplatesContainer loadingProps={loadingProps} {...props} />}
            />
          </ConditionalWrapper>
        </S.Content>
      </ConditionalWrapper>
    </>
  );
};
