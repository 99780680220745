import { Order } from 'common/services/apis/v3/swipe-rx-pt/resources/orders/interfaces/Order.interface';

export enum PrecursorRejectReason {
  PHOTO_UNCLEAR = 'photo_unclear',
  PRECURSOR_FORMAT_INVALID = 'precursor_format_invalid',
  ADDRESS_PBF_INVALID = 'address_pbf_invalid',
  PHARMACY_DATA_INCOMPLETE = 'pharmacy_data_incomplete',
  NO_SIPNAP = 'no_sipnap',
  SIPNAP_NOT_MATCH = 'sipnap_not_match',
  SPECIMEN_NOT_UPDATED = 'specimen_not_updated',
  PHARMACY_DATA_INVALID = 'pharmacy_data_invalid',
  APJ_DATA_INVALID = 'apj_data_invalid',
  SIGNATURE_BLANK = 'signature_blank',
  SIGNATURE_NOT_MATCH = 'signature_not_match',
  SIGNATURE_DRY = 'signature_dry',
  STAMP_BLANK = 'stamp_blank',
  STAMP_NOT_MATCH = 'stamp_not_match',
  STAMP_DRY = 'stamp_dry',
  WRONG_CORE_MOLECULES = 'wrong_core_molecules',
  WRONG_PRODUCT_NAME = 'wrong_product_name',
  WRONG_QUANTITY = 'wrong_quantity',
}
export enum PrecursorApproveReason {
  DOCUMENT_CLEAR = 'document_clear',
  SIGNATURE_MATCH = 'signature_match',
  CORRECT_CORE_MOLECULES = 'correct_core_molecules',
}
export enum PrecursorCancelReason {
  AUTO_CANCEL_AFTER_HOURS = 'auto_cancel_after_hours',
}

export enum PrecursorDetailTabTypes {
  DOCUMENT_INFO = 'document-info',
  DOCUMENT_HISTORY_LOG = 'document-history-log',
}

export interface OrderDetailsResponse {
  data: Order | null;
  error?: string | null | undefined;
}

export interface PrecursorRejectReasonObject {
  name: string;
  text: string | null;
}
