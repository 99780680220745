import React, { useDispatch, useGlobal, useState } from 'reactn';

import { Button, Dialog } from 'common/components';
import { ColorType } from 'types';

import { S } from './AcceptOrderModal.styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { formatToCurrency } from 'common/utils';
import { OrderAmountDiff } from 'common/services/apis/v3/swipe-rx-pt/resources/orders/types/OrderAmoutDiff.type';

export const AcceptOrderModal: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);

  const [{ accept, loading }] = useGlobal('transactions');
  const [{ market }] = useGlobal('config');
  const resetOrderAcceptConfirmation = useDispatch('resetOrderAcceptConfirmation');
  const acceptOrders = useDispatch('acceptOrders');

  const { orderIds, amountDiffs } = accept;

  const handleClose = (): void => {
    setSubmitted(false);
    resetOrderAcceptConfirmation({});
  };

  const handleSubmit = async (): Promise<void> => {
    setSubmitted(true);
    await acceptOrders({ ids: orderIds });
    setSubmitted(false);
  };

  const totalCreditAfterAccept = (diff: OrderAmountDiff): number => {
    // credit calculation already done on rollbacked invoice
    if (diff.invoiced_amount && diff.is_rollbacked) {
      const totalDiff = Math.abs(diff.invoiced_amount - diff.updated_amount);
      const invoiceThresholdAmount = market.transactional?.invoiceThreshold || 0;

      if (totalDiff <= invoiceThresholdAmount) {
        return diff.available_credit;
      }
    }

    return diff.available_credit - (diff.updated_amount - diff.prev_amount);
  };

  const renderContent = (): JSX.Element => (
    <>
      <S.Description>
        You are about to accept the following orders which its item has changed and can create negative credit limit.
        <br />
        <br />
        Please review before submitting.
        <br />
      </S.Description>

      <TableContainer component={Paper}>
        <Table aria-label="order diff table">
          <TableHead>
            <TableRow>
              <TableCell>PO Number</TableCell>
              <TableCell align="right">Prev Amount</TableCell>
              <TableCell align="right">Updated Amount</TableCell>
              <TableCell align="right">Credit After Accept</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {amountDiffs.map((row) => (
              <TableRow key={row.order_id}>
                <TableCell component="th" scope="row">
                  {row.po_number}
                </TableCell>
                <TableCell align="right">{formatToCurrency(row.prev_amount)}</TableCell>
                <TableCell align="right">{formatToCurrency(row.invoiced_amount || row.updated_amount)}</TableCell>
                <TableCell align="right">{formatToCurrency(totalCreditAfterAccept(row))}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const renderActions = (): JSX.Element => (
    <>
      <Button isSecondary color={ColorType.DANGER} onClick={handleClose} disabled={loading}>
        Cancel
      </Button>

      <Button color={ColorType.PRIMARY} onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );

  return (
    <>
      <Dialog
        open={!!orderIds.length && !submitted}
        primary
        title="Accept Orders"
        onClose={handleClose}
        renderActions={renderActions}
        renderContent={renderContent}
      />
    </>
  );
};
