import { CSVUploadResult } from 'common/components/CsvUpload';
import { PBFFileUpload } from 'pages/pbf-assignment/constants';
import { DistributorFileImportType } from 'pages/distributor/components/file-import-tab/types';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';

import {
  IPharmacyPriceGroup,
  IPriceGroupBase,
  IPriceGroupProps,
  IPriceGroupTierPricingBase,
  IPriceGroupTierPricingProps,
} from './interface';
import { SuccessResponse } from '../../interfaces';

interface IScvUploadResultExtended {
  id: number;
  summary?: any;
}
export class SwipeRxPtPriceGroup {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.PRICE_GROUP;
  }

  async bulkUpload(
    distributor_id: number,
    file: File,
    type = DistributorFileImportType.ProductPriceGroup,
    datetime?: string,
  ): Promise<{
    data: CSVUploadResult & IScvUploadResultExtended;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );

    if (datetime) {
      form.append('datetime', datetime);
    }

    return this.base.post(`${this.resource}/${distributor_id}/bulk-upload/${type}`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async bulkUploadDPGAssignment(
    file: File,
    type: string = PBFFileUpload.DPGAssignment,
  ): Promise<{
    data: CSVUploadResult & IScvUploadResultExtended;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );

    return this.base.post(`${this.resource}/bulk-upload/${type}`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async cancelUpload(type: DistributorFileImportType, fileUploadId: number): Promise<any> {
    return this.base.delete(`${this.resource}/bulk-upload/confirm/${type}/${fileUploadId}`);
  }
  
  async confirmUpload(type: DistributorFileImportType, fileUploadId: number): Promise<any> {
    return this.base.post(`${this.resource}/bulk-upload/confirm/${type}/${fileUploadId}`, null);
  }

  async getPriceGroupTierPricingConfirmationSummary(fileUploadId: number): Promise<any> {
    return this.base.get(`${this.resource}/bulk-upload/summary/${fileUploadId}`);
  }

  async getPriceGroupProducts(priceGroupId: number, params: any): Promise<any> {
    return this.base.get(`${this.resource}/${priceGroupId}/products`, params);
  }

  async updatePriceGroupProductPrices(
    productPriceGroupId: number,
    payload: { selling_price: number; discount_rate: number; pre_negotiated_price: number, quantity_threshold: number },
  ): Promise<any> {
    return this.base.patch(`${this.resource}/product/${productPriceGroupId}`, payload);
  }

  async updatePriceGroupProductVisbility(productPriceGroupId: number, payload: { visibility: boolean }): Promise<any> {
    return this.base.patch(`${this.resource}/product/visibility/${productPriceGroupId}`, payload);
  }

  async fetchProductListing(id: number, params: any): Promise<any> {
    const result = await this.base.get(`${this.resource}/${id}/product-listing`, params);

    return result;
  }

  async updateProductsInListing(id: number, params: any): Promise<any> {
    const result = await this.base.post(`${this.resource}/${id}/product-listing/update-products-in-listing`, params);

    return result;
  }

  async createTierPricing(
    params: Omit<IPriceGroupTierPricingBase, 'market_id'>,
  ): Promise<SuccessResponse<IPriceGroupTierPricingProps>> {
    const result = await this.base.post(`${this.resource}/tier-pricing`, params);
    return result;
  }

  async updateTierPricing(
    id: number,
    params: Partial<IPriceGroupTierPricingBase>,
  ): Promise<SuccessResponse<IPriceGroupTierPricingProps>> {
    const result = await this.base.patch(`${this.resource}/tier-pricing/${id}`, params);

    return result;
  }

  async getPriceGroups(params: any): Promise<any> {
    const result = await this.base.get(`${this.resource}`, params);

    return result;
  }

  async updatePriceGroup(id: number, params: Partial<IPriceGroupProps>): Promise<any> {
    const result = await this.base.patch(`${this.resource}/${id}`, params);

    return result;
  }

  async createPriceGroup(params: Omit<IPriceGroupBase, 'market_id'>): Promise<SuccessResponse<IPriceGroupProps>> {
    const result = await this.base.post(`${this.resource}`, params);
    return result;
  }

  async deleteTierPricing(id: number): Promise<SuccessResponse<IPriceGroupTierPricingProps>> {
    const result = await this.base.delete(`${this.resource}/tier-pricing/${id}`);
    return result;
  }

  async getAssignedPharmaciesInPriceGroup(id: number, params: any): Promise<any> {
    const result = await this.base.get(`${this.resource}/${id}/pharmacy`, params);

    return result;
  }

  async getAssignablePharmaciesInPriceGroup(id: number, params: any): Promise<any> {
    const result = await this.base.get(`${this.resource}/${id}/pharmacy/assignable`, params);

    return result;
  }

  async assignPharmacyInPriceGroup(
    price_group_id: number,
    pharmacy_ids: number[],
  ): Promise<SuccessResponse<IPharmacyPriceGroup>> {
    const result = await this.base.post(`${this.resource}/${price_group_id}/pharmacy`, { pharmacy_ids: pharmacy_ids });

    return result;
  }

  async unassignPharmacyInPriceGroup(
    price_group_id: number,
    pharmacy_id: number,
  ): Promise<SuccessResponse<IPharmacyPriceGroup>> {
    const result = await this.base.delete(`${this.resource}/${price_group_id}/pharmacy/${pharmacy_id}`);

    return result;
  }
}
