import stores from '../../../../models/index.model';
import { PtAPIv2 } from '../utils';
import { QueryParams } from '../../../../models/product.model';
import { TIMEOUT_50_SECS } from '../utils/api.constants';

class ProductService {
  // eslint-disable-next-line class-methods-use-this
  async fetchAllProducts(
    data: QueryParams,
  ): Promise<{ products: any; meta: any } | undefined> {
    stores.dispatch.uiStore.showLoading(true);
    try {
      const queryParams = `?page=${data.page}&page_size=${data.page_size}&sort_by=${data.sort_by}&order_by=${data.order_by}&query=${data.query}`;
      const payload: any = await PtAPIv2.admin.get(
        `products${queryParams}`,
        undefined,
        TIMEOUT_50_SECS,
      );

      stores.dispatch.uiStore.showLoading(false);
      return { products: payload.result, meta: payload.meta };
    } catch (e) {
      // console.log('error ::', e);
      return undefined;
    } finally {
      stores.dispatch.uiStore.showLoading(false);
    }
  }
}

export default new ProductService();
