import styled from 'styled-components';
import RadioBase from '@material-ui/core/Radio';
import PaperBase from '@material-ui/core/Paper';
import AutocompleteBase from '@material-ui/lab/Autocomplete';
import { Colors } from 'common/themes/colors';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

export const Autocomplete = styled(AutocompleteBase)`
  && {
    margin-top: 10px;
  }
`;

export const Radio = withStyles({
  checked: {
    color: `${Colors.primary} !important`,
  },
})(RadioBase);

export const HeaderLabel = styled(Typography)`
  && {
    font-weight: bold;
    margin-top: 20px;
    font-size: 16px;
    color: ${Colors.textNormal};
  }
`;

export const Paper = styled(PaperBase).attrs({
  variant: 'outlined',
})`
  && {
    padding: 15px;
    border-radius: 10px;
    margin-top: 10px;
    border-color: ${Colors.primary};
  }
`;
