import { StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { COLORS } from 'common/constants';

interface StyleProps {
  rawFont?: boolean;
}

export const generateStyles = ({ rawFont }: StyleProps): { [key: string]: Style } =>
  StyleSheet.create({
    tableContainer: {},
    tableHeaderContainer: {
      flexDirection: 'row',
      marginHorizontal: '0pt',
      marginTop: '5mm',
    },
    tableHeader: {
      flexGrow: 1,
      height: '7mm',
      fontFamily: rawFont ? undefined : 'Sarabun',
      fontSize: '7pt',
      fontWeight: 'bold',
      backgroundColor: COLORS.GREY_200,
      paddingHorizontal: '2mm',
      paddingVertical: '5pt',
      borderBottom: `1pt solid ${COLORS.GREY_700}`,
    },
    tableBody: {},
    tableRowContainer: {
      flexDirection: 'row',
      marginHorizontal: '0pt',
    },
    tableRow: {
      flexGrow: 1,
      fontFamily: rawFont ? undefined : 'Sarabun',
      fontSize: '7pt',
      paddingHorizontal: '2mm',
      paddingVertical: '1mm',
    },
  });
