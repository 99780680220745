/* eslint-disable no-param-reassign */
import { IMarketingRewardData } from "pages/marketing-rewards";
import { ISearchable, ListMeta } from "../../interfaces";
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { Pharmacy } from "../organizations/interfaces";

export class SwipeRxPtMarketingRewards implements ISearchable<IMarketingRewardData> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.MARKETING_REWARDS;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async search(query: any) {
    return this.base.get(this.resource, {
      ...query
    });
  }

  createOrUpdate(data: any): Promise<never> {
    return this.base.patch(`${this.resource}/edit/update-reward`, data);
  }

  async searchPharmacies(query: {
    term?: string;
    fields?: string[];
    sort_by: string;
    order_by?: string;
    page: number;
    page_size: number;
  }): Promise<{
    data: Partial<Pharmacy>[];
    meta: ListMeta;
  }> {
    return this.base.get(`${this.resource}/search-pharmacies`, {
      ...query
    });
  }

  cancelRequest(): void {
    this.base.cancelRequest();
  }
}
