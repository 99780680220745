import React from 'reactn';

import {
  Button,
  Dialog,
  FieldWrapper,
  BulkActionItemCardMapper,
} from 'common/components';
import { ColorType } from 'types';

import { useOrderDelayForm } from './use-order-delay-form';
import { fieldOptions } from './order-delay-form.config';

const formId = 'order-delay-form';

export const OrderDelayModal: React.FC = () => {
  const {
    form,
    delay: { orderDetails, orderIds },
    clearOrderIds,
    selectedReason,
    setSelectedReason,
  } = useOrderDelayForm();

  const renderContent = (): JSX.Element => (
    <>
      <BulkActionItemCardMapper data={orderDetails} />
      <form id={formId} onSubmit={form.handleSubmit} style={{ height: '20vh' }}>
        {fieldOptions.map(({ name, component: Field, label, options }) => {
          const handleChange = (option: {
            value: string;
            label: string;
          }): void => {
            form.setFieldValue(name, option.value);
            setSelectedReason(option);
          };

          const handleInputChange = (inputValue: string): void => {
            if (!inputValue) return;
            form.setFieldValue(name, inputValue);
          };

          return (
            <FieldWrapper
              key={name}
              touched={form.touched[name]}
              error={form.errors[name]}
              label={label}
            >
              <Field
                name={name}
                value={selectedReason}
                onChange={handleChange}
                options={options}
                onInputChange={handleInputChange}
                onBlur={form.handleBlur}
              />
            </FieldWrapper>
          );
        })}
      </form>
    </>
  );
  const renderActions = (): JSX.Element => (
    <Button
      color={ColorType.PRIMARY}
      disabled={!form.dirty || form.isSubmitting}
      loading={form.isSubmitting}
      form={formId}
      type="submit"
    >
      Submit
    </Button>
  );

  return (
    <Dialog
      open={!!orderIds.length}
      primary
      title={orderIds.length > 1 ? 'Delay Orders' : 'Delay Order'}
      onClose={clearOrderIds}
      renderActions={renderActions}
      renderContent={renderContent}
      subTitle={orderIds.length > 1 ? 'Selected' : 'Order ID'}
      value={
        orderIds.length > 1
          ? `${orderIds.length} Orders to delay`
          : orderDetails.length === 1
          ? orderDetails[0].po_number
          : ''
      }
    />
  );
};
