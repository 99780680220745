import styled from 'styled-components';

import { BadgeStyles } from 'common/components/Badge';

const Wrapper = styled.tr`
  && {
    td {
      padding: 12px;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;

      button {
        margin-left: 12px;
      }
    }
  }
`;

const PrimaryLabel = styled(BadgeStyles.Primary)`
  && {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const S = {
  Wrapper,
  PrimaryLabel,
};
