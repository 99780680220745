import React, { useState, useEffect } from 'reactn';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { getMarketPath, IMarketParams } from '../../utils/get-router-path';

import { SideBarMenu } from './sidebar-menu.component';
import { useSidebarMenuStyles } from './use-sidebar-menu-styles';
import { useSideBarMenuReducer } from './reducer';

export const SideBarMenuContainer: React.FC = () => {
  const history = useHistory();
  const { params } = useRouteMatch<IMarketParams>();
  const location = useLocation();
  const classes = useSidebarMenuStyles();
  const {
    paths: { paths },
  } = useSideBarMenuReducer();
  const [activeMenu, setActiveMenu] = useState('');
  const { pathname } = location;

  useEffect(() => {
    if (paths) {
      const active = Array.from(paths)
        .reverse()
        .find((menu) => {
          return pathname.startsWith(menu.path);
        });
      setActiveMenu(active ? active.path : getMarketPath(params.marketId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, paths]);

  const isMenuActive = (menuPath: string): boolean => {
    return activeMenu === menuPath;
  };

  const isMenuIconActive = (menuPath: string): { root: string } => {
    const styleIcon = activeMenu === menuPath ? { root: classes.iconSelected } : { root: classes.icon };
    return styleIcon;
  };

  const handleItemClick = (path: string): void => {
    history.push(`${path}`);
  };

  return (
    <SideBarMenu
      paths={paths}
      classes={classes}
      onItemClick={handleItemClick}
      isMenuActive={isMenuActive}
      isMenuIconActive={isMenuIconActive}
    />
  );
};
