import React from 'reactn';
import moment from 'moment';

import {
  Button,
  Dialog,
  FieldWrapper,
  CreatableAutoComplete,
  FieldDateInput,
} from 'common/components';
import { OrderReturnType } from 'common/services/apis/v3/swipe-rx-pt';
import { ColorType } from 'types';

import { useOrderModalReason } from 'pages/transactions/hooks/use-order-modal-reason.hook';
import { OrderReturnModalProps } from './interfaces';
import { fieldOptions } from './order-return-form.config';

const formId = 'order-return-form';

export const OrderReturnModal: React.FC<OrderReturnModalProps> = ({
  form,
  data: { orderDetails },
  steps: { step, clearSteps, nextStep },
  returnType: { type: orderReturnType },
}) => {
  const isOpen =
    (orderReturnType === OrderReturnType.FULL && step === 2) ||
    (orderReturnType === OrderReturnType.PARTIAL && step === 3);

  const { selectedReason, setSelectedReason, clearReason} = useOrderModalReason();

  const handleCloseClick = ():void => {
    clearSteps();
    clearReason();
  }

  const renderContent = (): JSX.Element => (
    <>
      <form id={formId} onSubmit={form.handleSubmit} style={{ height: '22vh' }}>
        {fieldOptions.map(({ name, type, options, label }) => {
          if (type === 'select') {
            const handleChange = (option: {
              value: string;
              label: string;
            }): void => {
              form.setFieldValue(name, option.value);
              setSelectedReason(option);
            };

            const handleInputChange = (inputValue: string): void => {
              if (!inputValue) return;
              form.setFieldValue(name, inputValue);
            };

            return (
              <FieldWrapper
                key={name}
                touched={form.touched[name]}
                error={form.errors[name]}
                label={label}
              >
                <CreatableAutoComplete
                  name={name}
                  value={selectedReason}
                  onChange={handleChange}
                  options={options}
                  onInputChange={handleInputChange}
                  onBlur={form.handleBlur}
                />
              </FieldWrapper>
            );
          }
          if (orderReturnType === OrderReturnType.PARTIAL && type === 'date') {
            return (
              <FieldWrapper
                key={name}
                touched={form.touched[name]}
                error={form.errors[name]}
                label={label}
              >
                <FieldDateInput
                  value={form.values[name]}
                  onChange={(date) => {
                    form.setFieldValue(name, date);
                  }}
                />
              </FieldWrapper>
            );
          }

          return null;
        })}
      </form>
    </>
  );
  const renderActions = (): JSX.Element => (
    <Button
      color={ColorType.PRIMARY}
      onClick={nextStep}
      disabled={!form.dirty || form.isSubmitting || !moment(form.values[fieldOptions[1].name]).isValid()}
      type="button"
    >
      Submit
    </Button>
  );

  return (
    <Dialog
      open={isOpen}
      primary
      title="Return Order"
      onClose={handleCloseClick}
      renderActions={renderActions}
      renderContent={renderContent}
      subTitle="Order ID"
      value={orderDetails.length === 1 ? orderDetails[0].po_number : ''}
    />
  );
};
