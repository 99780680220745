import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const LoadingText = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  text-align: center;
  margin: 22px 0 0;
`;

export const S = { Container, LoadingText };
