import styled from 'styled-components';

const Wrapper = styled.tbody`
  && {
    tr td {
      color: #5f5f5f;
      font-weight: bold;
      height: 100px;
      text-align: center;
    }
  }
`;

export const S = {
  Wrapper,
};
