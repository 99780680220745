import React from 'reactn';
import moment from 'moment';

import {
  Button,
  Dialog,
  FieldWrapper,
  BulkActionItemCardMapper,
  FieldDateInput,
} from 'common/components';
import { ColorType } from 'types';

import { fieldOptions } from './order-deliver-form.config';
import { useOrderDeliverForm } from './use-order-deliver-form';

const formId = 'order-cancel-form';

export const OrderDeliverModal: React.FC = () => {
  const {
    form,
    deliver: { orderIds, orderDetails },
    clearOrderIds,
  } = useOrderDeliverForm();

  const renderContent = (): JSX.Element => (
    <>
      <BulkActionItemCardMapper data={orderDetails} />
      <form id={formId} onSubmit={form.handleSubmit} style={{ height: '20vh' }}>
        {fieldOptions.map(({ name, type, label }) => {
          if (type === 'date') {
            return (
              <FieldWrapper
                key={name}
                touched={form.touched[name]}
                error={form.errors[name]}
                label={label}
              >
                <FieldDateInput
                  value={form.values[name]}
                  onChange={(date) => {
                    form.setFieldValue(name, date);
                  }}
                />
              </FieldWrapper>
            );
          }

          return null;
        })}
      </form>
    </>
  );
  const renderActions = (): JSX.Element => (
    <Button
      color={ColorType.PRIMARY}
      disabled={form.isSubmitting || !moment(form.values[fieldOptions[0].name]).isValid()}
      loading={form.isSubmitting}
      form={formId}
      type="submit"
    >
      Submit
    </Button>
  );

  return (
    <Dialog
      open={!!orderIds.length}
      primary
      title={orderIds.length > 1 ? 'Deliver Orders' : 'Deliver Order'}
      onClose={clearOrderIds}
      renderActions={renderActions}
      renderContent={renderContent}
      subTitle={orderIds.length > 1 ? 'Selected' : 'Order ID'}
      value={
        orderIds.length > 1
          ? `${orderIds.length} Orders to deliver`
          : orderDetails.length === 1
          ? orderDetails[0].po_number
          : ''
      }
    />
  );
};
