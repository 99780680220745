import { Button, Typography } from '@material-ui/core';
import React from 'reactn';
import { Dialog } from '..';
import { DangerButton } from './ConfirmationDialog.style';

export interface ConfirmationDialogOptions {
  showCloseButton?: boolean;
  maxWidth?:  'xs' | 'sm' | 'md' | 'lg' | 'xl'
  accept?: string;
  cancel?: string;
  footer?: string;
  variant?: 'primary' | 'danger';
}

export interface ConfirmationDialogProps {
  title: string;
  message: string | JSX.Element;
  options?: ConfirmationDialogOptions;

  onCancel: () => void;
  onAccept: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  message,
  options,

  onCancel,
  onAccept,
}) => {
  const {
    showCloseButton = true,
    maxWidth = 'xs',
    accept = 'Yes',
    cancel = 'No',
    variant = 'primary',
    footer,
  } = options || {}

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Typography style={{paddingLeft: 8}}>{message}</Typography>
        {footer && <Typography style={{paddingLeft: 8, fontWeight: 'bold'}}>{footer}</Typography>}
      </>
    )
  }

  const renderActions = (): JSX.Element => {
    const acceptProps: any = {
      color: 'primary',
      variant: 'contained',
      onClick: onAccept,
    }
    return (
      <>
        <Button data-testid="reset-cancel-btn" fullWidth variant="outlined" color="primary" onClick={onCancel}>{cancel}</Button>
        {variant === 'primary' && <Button fullWidth {...acceptProps}>{accept}</Button>}
        {variant === 'danger' && <DangerButton fullWidth {...acceptProps}>{accept}</DangerButton>}
      </>
    )
  }

  return (
    <Dialog
      open
      primary
      fullWidth
      showCloseButton={showCloseButton}
      maxWidth={maxWidth}
      title={title}
      onClose={onCancel}
      renderContent={renderContent}
      renderActions={renderActions}
    />
  )
}