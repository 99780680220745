/* eslint-disable */
import stores from '../models/index.model';

// Mock API client
class ApiClient {
  async get(path, qs) {
    stores.dispatch.uiStore.showLoading(true);

    await timeout(1000);

    let result = null;
    const splitPath = path.split('/');

    switch (splitPath[2]) {
      case 'orders':
        if (splitPath[3]) {
          result = ordersData.result.filter(
            (item) => item._id.toString() === splitPath[3].toString(),
          )[0];
        } else {
          result = ordersData;
        }
        break;
      case 'invoices':
        if (splitPath[3]) {
          result = invoicesData.result.filter(
            (item) => item._id.toString() === splitPath[3].toString(),
          )[0];
        } else {
          result = invoicesData;
        }
        break;
      case 'distributors':
        if (splitPath[3]) {
          result = distributorsData.result[splitPath[3] - 1];
          if (splitPath[4]) {
            switch (splitPath[4]) {
              case 'areas':
                result = areasData;
                break;
              case 'invoices':
                result = invoicesData;
                break;
              case 'users':
                result = usersData;
                break;
              default:
            }
          }
        } else {
          result = distributorsData;
        }
        break;
      case 'pharmacies':
        if (splitPath[3]) {
          result = pharmaciesData.result[splitPath[3] - 1];
          if (splitPath[4]) {
            switch (splitPath[4]) {
              case 'credit':
                result = creditsData;
                break;
              case 'orders':
                result = ordersData;
                break;
              case 'users':
                result = usersData;
                break;
              default:
            }
          }
        } else {
          result = pharmaciesData;
        }
        break;
      case 'users':
        if (splitPath[3]) {
          result = usersData.result[splitPath[3] - 1];
        } else {
          result = usersData;
        }
        break;
      case 'products':
        if (splitPath[3]) {
          result = productsData.result[splitPath[3] - 1];
        } else {
          result = productsData;
        }
        break;
      default:
        throw new Error('Error!');
    }

    stores.dispatch.uiStore.showLoading(false);
    return result;
  }

  async post(path, body, options = {}) {
    stores.dispatch.uiStore.showLoading(true);

    await timeout(1000);
    let response = 'Success!';

    const splitPath = path.split('/');

    switch (splitPath[2]) {
      case 'distributors':
        if (splitPath[3]) {
          if (splitPath[4]) {
            let target = stores.getState().distributorStore;
            switch (splitPath[4]) {
              case 'areas':
                target = target.distributor.areas;
                if (splitPath[6] === 'available') {
                  target = target.result.filter(
                    (area) => area._id === splitPath[5],
                  )[0];
                  target.available = !target.available;
                } else {
                  target.result.push({
                    _id: target.result.length + 1,
                    name: body.name,
                    zipcode: body.zipcode,
                    available: true,
                  });
                  target.count = target.result.length;
                }
                break;
              case 'invoices':
                break;
              case 'users':
                target = target.distributor.users;
                if (splitPath[6] === 'remove') {
                  target.result = target.result.filter(
                    (user) => user._id !== splitPath[5],
                  );
                  target.count = target.result.length;
                } else {
                  target.result.push({
                    _id: target.result.length + 1,
                    name: body.name,
                    email: body.email,
                  });
                  target.count = target.result.length;
                }
                break;
              default:
            }
          }
        }
        break;
      case 'pharmacies':
        if (splitPath[3]) {
          if (splitPath[4]) {
            let target = stores.getState().pharmacyStore;
            switch (splitPath[4]) {
              case 'activate':
                target = target.pharmacy;
                target.activated = !target.activated;
                break;
              case 'suspend':
                target = target.pharmacy;
                target.suspend_tansactions = !target.suspend_tansactions;
                break;
              case 'credits':
                target = target.pharmacy.credits;
                target.result.push({
                  _id: target.result.length + 1,
                  description: body.description,
                  amount: body.amount,
                });
                target.count = target.result.length;
                break;
              case 'users':
                target = target.pharmacy.users;
                if (splitPath[6] === 'remove') {
                  target.result = target.result.filter(
                    (user) => user._id !== splitPath[5],
                  );
                  target.count = target.result.length;
                } else {
                  target.result.push({
                    _id: target.result.length + 1,
                    name: body.name,
                    email: body.email,
                  });
                  target.count = target.result.length;
                }
                break;
              default:
            }
          }
        }
        break;
      case 'users':
        if (splitPath[3]) {
          let target = stores.getState().userStore.user;
          target.activated = !target.activated;
        }
        break;
      case 'products':
        if (splitPath[3]) {
          let target = stores.getState().productStore.product;
          target.promoted = !target.promoted;
        }
        break;
      default:
        throw new Error('Error!');
    }

    stores.dispatch.uiStore.showLoading(false);
    return response;
  }
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const pharmaciesData = {
  result: [
    {
      _id: 1,
      name: 'Pharmacy 1',
      status: 2,
      credit: 1000000.0,
      created_at: new Date(),
      legal_name: 'Pharmacy 1 Co',
      organization_id: 123,
      address: '3rd Street',
      city: 'Jakarta',
      country: 'Indonesia',
      zipcode: '11440',
      area: 'Tomang',
      email: 'pharmaciauno@email.com',
      bank_name: 'Sinamras',
      bank_code: 'S2354',
      bank_account_name: 'Jayho Klamnan',
      bank_account_number: '3415461215',
      bank_description: 'Et cetera',
      bank_account_status: 0,
      activated: true,
      suspend_tansactions: false,
    },
    {
      _id: 2,
      name: 'Pharmacy Two',
      status: 3,
      credit: 200000.0,
      created_at: new Date(),
      legal_name: 'Pharmacy 2 Co',
      organization_id: 123,
      address: '3rd Street',
      city: 'Jakarta',
      country: 'Indonesia',
      zipcode: '11440',
      area: 'Tomang',
      email: 'pharmaciados@email.com',
      bank_name: 'Sinamras',
      bank_code: 'S2354',
      bank_account_name: 'Jayho Klamnan',
      bank_account_number: '3415461215',
      bank_description: 'Et cetera',
      bank_account_status: 0,
      activated: true,
      suspend_tansactions: false,
    },
    {
      _id: 3,
      name: 'Pharmacy Tres',
      status: 1,
      credit: 300000.0,
      created_at: new Date(),
      legal_name: 'Pharmacy Tres Co',
      organization_id: 123,
      address: '3rd Street',
      city: 'Jakarta',
      country: 'Indonesia',
      zipcode: '11440',
      area: 'Tomang',
      email: 'pharmacia3@email.com',
      bank_name: 'Sinamras',
      bank_code: 'S2354',
      bank_account_name: 'Jayho Klamnan',
      bank_account_number: '3415461215',
      bank_description: 'Et cetera',
      bank_account_status: 0,
      activated: true,
      suspend_tansactions: false,
    },
    {
      _id: 4,
      name: 'Pharmacy 4',
      status: 1,
      credit: 10000.5,
      created_at: new Date(),
      legal_name: 'Pharmacy 2 Co',
      organization_id: 123,
      address: '3rd Street',
      city: 'Jakarta',
      country: 'Indonesia',
      zipcode: '11440',
      area: 'Tomang',
      email: 'pharmaciados@email.com',
      bank_name: 'Sinamras',
      bank_code: 'S2354',
      bank_account_name: 'Jayho Klamnan',
      bank_account_number: '3415461215',
      bank_description: 'Et cetera',
      bank_account_status: 0,
      activated: true,
      suspend_tansactions: false,
    },
  ],
  count: 4,
  pages: 1,
};

const creditsData = {
  result: [
    {
      _id: 1,
      description: 'Paid Rp 1.5m last week',
      amount: 1500000.0,
    },
    {
      _id: 2,
      description: 'Paid Rp 1.7m last week',
      amount: 1700000.0,
    },
    {
      _id: 3,
      description: 'Paid Rp 2m last week',
      amount: 2000000.0,
    },
    {
      _id: 4,
      description: 'Paid Rp 3.5m last week',
      amount: 3500000.0,
    },
  ],
  count: 4,
  pages: 1,
};

const defaultOrderItems = [
  {
    name: 'Abisoprolol 5 mg Dexa Medica',
    packaging: 'Dus, 3 Blister @ 10 Tablet Salut Selaput',
    original_price: 25000.0,
    discount: 15,
    net_price: 20000.0,
    quantity: 5,
    status: 1, // checked
  },
  {
    name: 'Abisoprolol 3 mg Dexa Medica',
    packaging: 'Dus, 3 Blister @ 10 Tablet Salut Selaput',
    original_price: 25000.0,
    discount: 15,
    net_price: 20000.0,
    quantity: 3,
    status: 1, // checked
  },
  {
    name: 'Abisoprolol 2 mg Dexa Medica',
    packaging: 'Dus, 3 Blister @ 10 Tablet Salut Selaput',
    original_price: 25000.0,
    discount: 15,
    net_price: 20000.0,
    quantity: 2,
    status: 1, // checked
  },
];

const ordersData = {
  result: [
    {
      _id: 1,
      pharmacy_name: 'Apotek K-25',
      distributor: 'PBF Vasil',
      net_amount: 80000.1,
      vat: 8000.0,
      status: 1, // in progress
      ordered_on: new Date(),
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      items: defaultOrderItems,
      invoice_no: 'RJ3-532513',
      invoiced_amount: 88000.0,
    },
    {
      _id: 2,
      pharmacy_name: 'Apotek K-26',
      distributor: 'PBF Vasil',
      net_amount: 90000.99,
      vat: 9000.0,
      status: 1, // in progress
      ordered_on: new Date(),
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      items: defaultOrderItems,
    },
    {
      _id: 3,
      pharmacy_name: 'Apotek K-300',
      invoice_date: new Date(),
      delivery_date: new Date(),
      payment_date: new Date(),
      items: defaultOrderItems,
      invoice_no: 'RJ3-532513',
      invoiced_amount: 110000.0,
    },
    {
      _id: 4,
      pharmacy_name: 'Ampotek K-225',
      distributor: 'PBF Vasil',
      net_amount: 80000.0,
      vat: 8000.0,
      status: 1, // in progress
      ordered_on: new Date(),
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      items: defaultOrderItems,
    },
    {
      _id: 6,
      pharmacy_name: 'Apotek K-2Six',
      distributor: 'PBF Vasil',
      net_amount: 50000.0,
      vat: 5000.0,
      status: 1, // in progress
      ordered_on: new Date(),
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      items: defaultOrderItems,
      invoice_no: 'RJ3-532513',
      invoiced_amount: 55000.0,
    },
    {
      _id: 7,
      pharmacy_name: 'Apotek K-Seven',
      distributor: 'PBF Vasil',
      net_amount: 90000.0,
      vat: 9000.0,
      status: 1, // in progress
      ordered_on: new Date(),
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      items: defaultOrderItems,
      invoice_no: 'RJ3-532513',
      invoiced_amount: 99000.0,
    },
    {
      _id: 8,
      pharmacy_name: 'Ampotek K-Eight',
      distributor: 'PBF Vasil',
      net_amount: 60000.0,
      vat: 6000.0,
      status: 1, // in progress
      ordered_on: new Date(),
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      items: defaultOrderItems,
      invoice_no: 'RJ3-532513',
      invoiced_amount: 66000.0,
    },
    {
      _id: 9,
      pharmacy_name: 'Apotek K-Nein',
      distributor: 'PBF Vasil',
      net_amount: 50000.0,
      vat: 5000.0,
      status: 1, // in progress
      ordered_on: new Date(),
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      items: defaultOrderItems,
      invoice_no: 'RJ3-532513',
      invoiced_amount: 55000.0,
    },
    {
      _id: 10,
      pharmacy_name: 'Apotek K-Ten',
      distributor: 'PBF Vasil',
      net_amount: 30000.0,
      vat: 3000.0,
      status: 1, // in progress
      ordered_on: new Date(),
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      items: defaultOrderItems,
      invoice_no: 'RJ3-532513',
      invoiced_amount: 33000.0,
    },
    {
      _id: 11,
      pharmacy_name: 'Apotek K-Eleven',
      distributor: 'PBF Vasil',
      net_amount: 40000.0,
      vat: 4000.0,
      status: 1, // in progress
      ordered_on: new Date(),
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      items: defaultOrderItems,
      invoice_no: 'RJ3-532513',
      invoiced_amount: 44000.0,
    },
    {
      _id: 12,
      pharmacy_name: 'Ampotek K-12',
      distributor: 'PBF Vasil',
      net_amount: 50000.0,
      vat: 5000.0,
      status: 1, // in progress
      ordered_on: new Date(),
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      items: defaultOrderItems,
      invoice_no: 'RJ3-532513',
      invoiced_amount: 55000.0,
    },
  ],
  count: 12,
  pages: 2,
};

const invoicesData = {
  result: [
    {
      _id: 1,
      invoice_no: 'HNF34-1345',
      pharmacy: 'Apotek K-25',
      distributor: 'PBF Vasil',
      invoiced_amount: 88000.0,
      status: 1,
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      invoice_proof: 'Link to proof?',
      delivery_proof: null,
      tax_invoice: null,
      order_no: 1,
      order_amount: 88000.0,
    },
    {
      _id: 2,
      invoice_no: 'HNF34-1346',
      pharmacy: 'Apotek K-25',
      distributor: 'PBF Vasil',
      invoiced_amount: 80000.0,
      status: 1, // Claimed
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      invoice_proof: null,
      delivery_proof: null,
      tax_invoice: null,
      order_no: 1,
      order_amount: 80000.0,
    },
    {
      _id: 3,
      invoice_no: 'HNF34-1347',
      pharmacy: 'Apotek K-25',
      distributor: 'PBF Vasil',
      invoiced_amount: 100000.5,
      status: 0, // In progress
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      invoice_proof: 'Link to proof?',
      delivery_proof: null,
      tax_invoice: null,
      order_no: 1,
      order_amount: 100000.5,
    },
    {
      _id: 4,
      invoice_no: 'HNF34-1348',
      pharmacy: 'Apotek K-25',
      distributor: 'PBF Vasil',
      invoiced_amount: 110000.5,
      status: 0, // In progress
      invoice_date: new Date(),
      delivery_date: new Date(),
      due_date: new Date(),
      payment_date: new Date(),
      invoice_proof: null,
      delivery_proof: null,
      tax_invoice: null,
      order_no: 1,
      order_amount: 110000.5,
    },
  ],
  count: 4,
  pages: 1,
};

const distributorsData = {
  result: [
    {
      _id: 1,
      distributor: 'PBF Vasil',
      legal_name: 'PBF Vasi Co.',
      organization_id: '256',
      address: '3rd Street',
      city: 'Jakarta',
      country: 'Indonesia',
      zipcode: '11440',
      area: 'Tomang',
      email: 'info@pbfvasil.com',
      added_on: new Date(),
      status: 1,
    },
    {
      _id: 2,
      distributor: 'PBF Mani',
      legal_name: 'PBF Mani Co.',
      organization_id: '257',
      address: '4th Street',
      city: 'Jakarta',
      country: 'Indonesia',
      zipcode: '11440',
      area: 'Tomang',
      email: 'info@pbfmani.com',
      added_on: new Date(),
      status: 0,
    },
    {
      _id: 3,
      distributor: 'PBF Hopya',
      legal_name: 'PBF Hopya Co.',
      organization_id: '258',
      address: '1st Street',
      city: 'Jakarta',
      country: 'Indonesia',
      zipcode: '11440',
      area: 'Tomang',
      email: 'contact@pbfhopya.com',
      added_on: new Date(),
      status: 1,
    },
    {
      _id: 4,
      distributor: 'PBF Pop',
      legal_name: 'PBF Pop Co.',
      organization_id: '260',
      address: '1st Road',
      city: 'Jakarta',
      country: 'Indonesia',
      zipcode: '11440',
      area: 'Tomang',
      email: 'contact@pbfpop.com',
      added_on: new Date(),
      status: 0,
    },
  ],
  count: 4,
  pages: 1,
};
const areasData = {
  result: [
    {
      _id: 1,
      name: 'Tomang',
      zipcode: 141531,
      available: true,
    },
    {
      _id: 2,
      name: 'Tomang To',
      zipcode: 141532,
      available: false,
    },
    {
      _id: 3,
      name: 'Tomang Tres',
      zipcode: 141533,
      available: false,
    },
    {
      _id: 4,
      name: 'Tomang Por',
      zipcode: 141534,
      available: true,
    },
  ],
  count: 4,
  pages: 1,
};

const usersData = {
  result: [
    {
      _id: 1,
      name: 'John Smith',
      email: 'jsmith@gmail.com',
      status: 1, // active
      swiperx_user_id: 176542,
      last_logged_in: new Date(),
      activated: true,
      added_on: new Date(),
    },
    {
      _id: 2,
      name: 'Will Smith',
      email: 'will@gmail.com',
      status: 0, // new
      swiperx_user_id: 176543,
      last_logged_in: new Date(),
      activated: false,
      added_on: new Date(),
    },
    {
      _id: 3,
      name: 'Jayden Smith',
      email: 'jade@gmail.com',
      status: 2, // inactive
      swiperx_user_id: 176544,
      last_logged_in: new Date(),
      activated: false,
      added_on: new Date(),
    },
    {
      _id: 4,
      name: 'Jayda Smith',
      email: 'jay@gmail.com',
      status: 1, // active
      swiperx_user_id: 176545,
      last_logged_in: new Date(),
      activated: true,
      added_on: new Date(),
    },
  ],
  count: 4,
  pages: 1,
};

const productsData = {
  result: [
    {
      _id: 1,
      name: 'Bisoprolol 5 mg Dexa Medica',
      packaging: 'Dus, 3 Blister @ 10 Tablet Salut',
      sku_code: 'SK1-241',
      molecule: 'Bisoprolol',
      manufacturer: 'Company X',
      market_price: 17800.0,
      promoted: true,
    },
    {
      _id: 2,
      name: 'Bisoprolol 2 mg Dexa Medica',
      packaging: 'Dus, 3 Blister @ 20 Tablet Salut',
      sku_code: 'SK1-241',
      molecule: 'Bisoprolol',
      manufacturer: 'Company X',
      market_price: 25800.0,
      promoted: false,
    },
    {
      _id: 3,
      name: 'Bisoprolol 3 mg Dexa Medica',
      packaging: 'Dus, 3 Blister @ 30 Tablet Salut',
      sku_code: 'SK1-241',
      molecule: 'Bisoprolol',
      manufacturer: 'Company X',
      market_price: 35800.0,
      promoted: true,
    },
    {
      _id: 4,
      name: 'Bisoprolol 2 mg Dexa Medica',
      packaging: 'Dus, 3 Blister @ 50 Tablet Salut',
      sku_code: 'SK1-241',
      molecule: 'Bisoprolol',
      manufacturer: 'Company X',
      market_price: 50800.99,
      promoted: false,
    },
  ],
  count: 4,
  pages: 1,
};

export default new ApiClient();
