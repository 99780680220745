import { ColorType } from 'types';

export const COLORS: Record<ColorType, string> = {
  primary: '#40AE96',
  secondary: '#F6F7F7',
  success: 'green',
  danger: '#FF5050',
  info: '#6469DF',
  warning: '#F2994A',
  gray: '#8B8C9D',
  white: '#ffffff',
  black: '#000000',
  pink: '#cc5bc3',

  // 10 shades of grey
  GREY_50: '#fafafa',
  GREY_100: '#f5f5f5',
  GREY_200: '#eeeeee',
  GREY_300: '#e0e0e0',
  GREY_400: '#bdbdbd',
  GREY_500: '#9e9e9e',
  GREY_600: '#757575',
  GREY_700: '#616161',
  GREY_800: '#424242',
  GREY_900: '#212121',

  // Custom
  GREEN_YELLOW: '#96d35f',
};
