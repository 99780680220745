import { ReactNode, useMemo } from 'react';
import { useGlobal } from 'reactn';

import uniq from 'lodash/uniq';

import { CardTableRowFooterAction, CardTableRowFooterLabel } from 'common/components/CardTable/interfaces';
import { CardTableRowFooter } from 'common/components/CardTable/types';
import {
  DistributorProductFlag,
  RequirementTypeEnum,
  TRANSACTION_STATUS_LIST_ALLOWED_FOR_INVOICING,
  UserRoles,
  PaymentMethod,
} from 'common/constants';
import { OrderFulfillmentStatus, OrderStatus, OrderReturnType } from 'common/services/apis/v3/swipe-rx-pt';

import { getOrderElapsedTimeElement, getTransactionActionItems, getTransactionStatus, pluralize } from 'common/utils';
import { useGetAuthenticatedUser, useUpdateQueryParams } from 'hooks';
import { ColorType, DataObject, PurchaseOrderRequirement } from 'types';

import { useRouteMatch } from 'react-router-dom';
import { IMarketParams } from 'utils/get-router-path';

import { useTransactionRowAction } from './use-transaction-row-action.hook';
import { PrekursorLabel } from '../components';
import { OrderPrecursorStatus } from '../types';

export const useTransactionDataTableRowFooter = (): CardTableRowFooter => {
  const {
    params: { marketId },
  } = useRouteMatch<IMarketParams>();

  const [config] = useGlobal('config');
  const { market: marketConfig } = config;
  const isMarketPrekursorConfigured = marketConfig.requirements.product.includes(DistributorProductFlag.PREKURSOR);

  const user = useGetAuthenticatedUser();
  const { queryParams } = useUpdateQueryParams();
  const processTransactionRowAction = useTransactionRowAction();

  const { status: statusFromUrl } = queryParams;

  const handleRowActionClick = (name: string, data: DataObject): void => {
    processTransactionRowAction(name, data);
  };

  const unallowedInvoicingRoles = [UserRoles.CUSTOMER_SUPPORT, UserRoles.FINANCE, UserRoles.DISPATCHER];
  const isAllowedForInvoicing =
    !unallowedInvoicingRoles.includes(user.userType) &&
    TRANSACTION_STATUS_LIST_ALLOWED_FOR_INVOICING.includes(statusFromUrl as OrderStatus | OrderFulfillmentStatus);

  const actionItems = useMemo(() => getTransactionActionItems(user.userType, statusFromUrl as string), [
    user.userType,
    statusFromUrl,
  ]);

  const rowFooter: CardTableRowFooter = ({
    flagged_item_counts,
    fulfillment_status,
    invoice,
    is_undelivered,
    cancellation_reason,
    delay_reason,
    rollback_reason,
    ordered_at,
    is_delayed,
    is_delivery_doc_printed,
    is_returned,
    is_quantity_void,
    return_type,
    status_history,
    status,
    payment_method,
    payment_direct,
    payment_status,
    activities,
    precursor_status,
  }: DataObject) => {
    const labels: CardTableRowFooterLabel[] = [];

    let returnType = '';
    if (!return_type) returnType = '';
    else if (return_type === OrderReturnType.FULL) returnType = 'Full Returned';
    else returnType = `Partial Returned${is_quantity_void ? ' (Quantity Void)' : ''}`;

    let is_prekursor_required = false;
    let is_prekursor_approved = false;

    if (precursor_status) {
      is_prekursor_required = true;
      is_prekursor_approved = precursor_status.status === OrderPrecursorStatus.DOCUMENT_APPROVED;
    }

    const { limited_stock, price_changed, discount_rate_changed, stock_out } = flagged_item_counts || {};

    if (stock_out) {
      labels.push({
        id: 'stock_out',
        type: ColorType.DANGER,
        node: `${stock_out} ${pluralize('Item', stock_out)} Out of Stock`,
      });
    }

    if (price_changed) {
      labels.push({
        id: 'price_changed',
        type: ColorType.INFO,
        node: `${price_changed} ${pluralize('Item', price_changed)} Price Changed`,
      });
    }

    if (limited_stock) {
      labels.push({
        id: 'limited_stock',
        type: ColorType.INFO,
        node: `${limited_stock} ${pluralize('Item', limited_stock)} Limited Stock`,
      });
    }

    if (discount_rate_changed) {
      labels.push({
        id: 'discount_rate_changed',
        type: ColorType.INFO,
        node: `${discount_rate_changed} ${pluralize('Item', discount_rate_changed)} Discount Rate Changed`,
      });
    }

    if (is_undelivered) {
      labels.push({
        id: 'undelivered',
        type: ColorType.WARNING,
        node: 'Undelivered',
      });
    }

    if (rollback_reason) {
      labels.push({
        id: 'rollbacked',
        type: ColorType.DANGER,
        node: 'Rollbacked',
      });
    }

    if (status === OrderStatus.CANCELLED && !is_returned) {
      labels.push({
        id: 'cancelled',
        type: ColorType.GRAY,
        node: `Cancelled - ${cancellation_reason}`,
      });
    }

    if (is_delayed) {
      labels.push({
        id: 'delayed',
        type: ColorType.GRAY,
        node: `Delayed - ${delay_reason}`,
      });
    }

    if (is_delivery_doc_printed) {
      labels.push({
        id: 'delivery_doc_printed',
        type: ColorType.GRAY,
        node: 'Delivery Docs Printed',
      });
    }

    if (fulfillment_status === OrderFulfillmentStatus.DELIVERED && is_returned) {
      labels.push({
        id: 'delivered-partial-returned',
        type: ColorType.GRAY,
        node: `Delivered - ${returnType}`,
      });
    }

    if (status === OrderStatus.CANCELLED && is_returned) {
      labels.push({
        id: 'cancelled-full-return',
        type: ColorType.GRAY,
        node: `Cancelled - ${returnType}`,
      });
    }

    if (fulfillment_status === OrderFulfillmentStatus.FULFILLED && is_returned) {
      labels.push({
        id: 'fulfilled-full-return',
        type: ColorType.GRAY,
        node: `Fulfilled - ${returnType}`,
      });
    }

    let finalActionItems = [...actionItems];

    if (isAllowedForInvoicing) {
      const invoicingVersions = {
        v0: {
          isSecondary: true,
          color: ColorType.PRIMARY,
          name: 'update_invoice_v0',
          text: `${invoice ? 'Update' : 'Create'} Invoice`,
        },
        v1: {
          isSecondary: true,
          color: ColorType.PRIMARY,
          name: 'update_invoice_v1',
          text: `${invoice ? 'Update' : 'Create'} Invoice`,
        },
      };

      let marketInvoicing;
      if (marketId === 'th') {
        marketInvoicing = invoicingVersions.v1;
      } else {
        marketInvoicing = invoicingVersions.v0;
      }

      finalActionItems = [marketInvoicing, ...actionItems];

      if (
        [OrderFulfillmentStatus.DISPATCHED, OrderFulfillmentStatus.DELIVERED].includes(
          statusFromUrl as OrderFulfillmentStatus,
        ) &&
        user.userType !== UserRoles.TRANSACTION_MANAGER
      ) {
        finalActionItems.shift();
      }
    }

    if (status === 'completed') {
      const index = finalActionItems.findIndex((item) => item.name === 'update_delivery_docs');

      if (index >= 0) {
        finalActionItems.splice(index, 1);
      }
    }

    if (status === 'pending') {
      if (payment_method === PaymentMethod.DIRECT_PAYMENT && (!invoice || invoice?.payment_status !== 'paid')) {
        const index = finalActionItems.findIndex((item) => item.name === 'print');

        if (index >= 0) {
          finalActionItems.splice(index, 1);

          // eslint-disable-next-line no-restricted-globals
          const cancelIndex = finalActionItems[0]?.items?.findIndex((item) => item?.name === 'cancel') ?? -1;

          if (
            invoice?.payment_status !== 'paid' &&
            finalActionItems[0]?.items &&
            cancelIndex >= 0 &&
            marketId === 'id'
          ) {
            finalActionItems[0].items = finalActionItems[0]?.items.filter((item: unknown) => item);
            finalActionItems[0].items.splice(cancelIndex, 1);
          }
        }
      } else if (isMarketPrekursorConfigured && is_prekursor_required && !is_prekursor_approved) {
        const index = finalActionItems.findIndex((item) => item.name === 'print');
        if (index >= 0) finalActionItems.splice(index, 1);
      } else {
        const index = finalActionItems.findIndex((item) => item.name === 'disabled_print');
        if (index >= 0) finalActionItems.splice(index, 1);
      }
    }

    if (status === 'processing') {
      if (payment_method === PaymentMethod.DIRECT_PAYMENT && !invoice) {
        const index = finalActionItems.findIndex((item) => item.name === 'accept');

        if (index >= 0) {
          finalActionItems.splice(index, 1);
        }
      } else {
        const index = finalActionItems.findIndex((item) => item.name === 'disabled_accept');

        if (index >= 0) {
          finalActionItems.splice(index, 1);
        }
      }
    }

    if (status === 'accepted') {
      if (payment_method === PaymentMethod.DIRECT_PAYMENT) {
        let index;

        // Remove update invoice v0 button
        index = finalActionItems.findIndex((item) => item.name === 'update_invoice_v0');

        if (index >= 0) {
          finalActionItems.splice(index, 1);
        }

        // Remove update invoice v1 button
        index = finalActionItems.findIndex((item) => item.name === 'update_invoice_v1');

        if (index >= 0) {
          finalActionItems.splice(index, 1);
        }
      }
    }

    if (payment_direct) {
      labels.push({
        id: 'bill_refference',
        type: ColorType.INFO,
        node: `Bill Reference: ${payment_direct.bill_reference || '-'}`,
      });
    }

    // Remove possible duplicate actions
    finalActionItems = uniq(finalActionItems.map((item) => JSON.stringify(item))).map((item) => JSON.parse(item));

    const { labelElement: primaryLabelElement, labelType: primaryLabelType } = getOrderElapsedTimeElement(
      statusFromUrl === 'all' ? getTransactionStatus(status, fulfillment_status) : (statusFromUrl as string),
      status_history,
      ordered_at,
      payment_method,
      invoice,
      payment_status,
    );

    return {
      labels,
      actions: finalActionItems,
      onActionClick: handleRowActionClick,
      primaryLabel: {
        id: 'elapsed_time',
        custom: true,
        type: primaryLabelType,
        node: primaryLabelElement,
      },
    };
  };

  return rowFooter;
};
