import React from 'reactn';

import { PDFTable, PDFTableCol } from 'common/components';
import { DataObject } from 'types';
import { toCurrency as toCurrencyBase, toTruncatedCurrencyWithoutLabel } from 'utils/toCurrency';
import { View, Text } from '@react-pdf/renderer';
import numeral from 'numeral';
import { InvoiceProps } from './invoice';
import { styles } from './styles';

interface ContentProps extends InvoiceProps {}

const cols: PDFTableCol[] = [
  {
    name: 'Product Name',
    key: 'productName',
    width: '70mm',
  },
  {
    name: 'Packaging',
    key: 'packaging',
    width: '32mm',
  },
  {
    name: 'Quantity',
    key: 'quantity',
    align: 'right',
    width: '15mm',
  },
  {
    name: 'Unit Price',
    key: 'unitPrice',
    align: 'right',
    width: '15mm',
  },
  {
    name: 'Discount',
    key: 'discount',
    align: 'right',
    width: '15mm',
  },
  {
    name: 'Net Price',
    key: 'netPrice',
    align: 'right',
    width: '18mm',
  },
  {
    name: 'Subtotal',
    key: 'subtotal',
    align: 'right',
    width: '25mm',
  },
];

export const Content: React.FC<ContentProps> = (props) => {
  const { order, match } = props;
  const {
    params: { marketId },
  } = match;

  const toCurrency = (value: number): string => {
    let alternativeSymbol: string | undefined | null;
    if (marketId === 'th') alternativeSymbol = '฿';

    return alternativeSymbol ? `${alternativeSymbol}${toTruncatedCurrencyWithoutLabel(value)}` : toCurrencyBase(value);
  };

  const transformRows = (): any[] => {
    if (!order) return [];
    return order.items.map((item: DataObject) => ({
      productName: item.product.name,
      packaging: item.product.package,
      quantity: item.quantity,
      unitPrice: toCurrency(item.selling_price),
      discount: item.discount_rate ? `${(item.discount_rate * 100).toFixed(2)}%` : '-',
      netPrice: toCurrency(item.net_price),
      subtotal: toCurrency(item.net_price * item.quantity),
    }));
  };

  const countTotal = (): string => {
    return toCurrency(order.items.reduce((acc: number, B: DataObject) => acc + B.net_price * B.quantity, 0));
  };

  return (
    <View style={{ marginTop: '4mm' }}>
      <PDFTable
        fixedHeader
        cols={cols}
        rows={transformRows()}
        width="190mm"
        footer={
          <View style={styles.tableFooter}>
            <Text style={{ fontSize: '3.5mm' }}>Total({numeral.localeData().currency.symbol}) </Text>
            <Text style={{ marginLeft: '3mm' }}>{countTotal()}</Text>
          </View>
        }
      />
    </View>
  );
};
