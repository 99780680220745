/* eslint-disable consistent-return */
import { addReducer } from "reactn"
import { RootState } from "..";


addReducer('setMarketingSegmentLoading', (state, _, loading: boolean): RootState => {
  const { marketingSegments } = state;

  return {
    ...state,
    marketingSegments: {
      ...marketingSegments,
      loading,
    }
  }
});
