/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Container, Grid, IconButton, Typography } from '@material-ui/core';

import { Button, PageWrapper, Snackbar } from 'common/components';

import { TabFilter } from './TabFilter.component';
import { ArrowBack } from '@material-ui/icons';
import { Colors } from 'common/themes/colors';
import { useDispatch, useGlobal } from 'reactn';
import { PrecursorDetailTabTypes } from './PrecursorDetail.type';
import RejectReasonModal from './RejectReasonModal.component';
import { ColorType } from 'types';
import { useUpdateQueryParams } from 'hooks';
import { OrderPrecursorStatus } from 'pages/transactions/types';
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router-dom';

interface Props extends RouteComponentProps<any> {
  previousPath?: string;
}
export const PrecursorDetail: React.FC<Props> = (props: Props) => {
  const { queryParams, updateQueryParams } = useUpdateQueryParams();
  const { previousPath } = props;
  const { params } = useRouteMatch();
  const { id } = params;
  const [rejectButtonDisabled, setRejectButtonDisabled] = useState(true);
  const [approveButtonDisabled, setApproveButtonDisabled] = useState(true);
  const [openRejectReasonModal, setOpenRejectReasonModal] = useState(false);
  const fetchTransactionOrderDetailsDispatch: any = useDispatch('fetchTransactionOrderDetails');
  const setPrecursorError: any = useDispatch('setPrecursorError');
  const setPrecursorSuccess: any = useDispatch('setPrecursorSuccess');
  const approvePrecursor: any = useDispatch('approvePrecursor');
  const setTransactionOrderDetails: any = useDispatch('setTransactionOrderDetails');
  const [{ orderDetails }] = useGlobal('transactions');
  const [{ loading, error, message, precursorStatusHistory }] = useGlobal('precursor');
  const history = useHistory();
  const fetchPrecursorStatusHistory: any = useDispatch('fetchPrecursorStatusHistory');

  useEffect(() => {
    if (orderDetails && orderDetails.data && orderDetails.data.precursor_status)
      fetchPrecursorStatusHistory({
        id: orderDetails.data?.precursor_status.id,
        params: { sort_by: 'DESC', expand: ['user'] },
      });
  }, [orderDetails]);

  const fetchTransactionOrderDetails = () =>
    fetchTransactionOrderDetailsDispatch({ id, params: { expand: ['precursor_status', 'pharmacy.crm'] } });
  useEffect(() => {
    fetchTransactionOrderDetails();
    return () => setTransactionOrderDetails(null);
  }, []);

  const handleTabChange = (value: PrecursorDetailTabTypes): void => {
    updateQueryParams(
      {
        precursor_tab: value,
      },
      {
        previousPath,
      },
    );
  };

  const onApprove = (): void => {
    approvePrecursor({ id: orderDetails.data?.precursor_status?.id });
  };

  const goBack = (previousPath?: string): void => {
    history.push(previousPath);
  };

  const handleCloseSnackbar = (): void => {
    setPrecursorError({ error: null });
    setPrecursorSuccess({ message: null });
  };

  useEffect(() => {
    if (!!message) {
      fetchTransactionOrderDetails();
      setOpenRejectReasonModal(false);
    }
  }, [message]);

  return (
    <Grid>
      <div style={{ display: 'inline', marginTop: 20, marginBottom: 20 }}>
        <IconButton
          style={{ display: 'inline' }}
          onClick={() => goBack(props.previousPath)}
          data-testid="precursor-detail-back-btn"
        >
          <ArrowBack style={{ fontSize: '16px', fontWeight: 'bold', padding: 10 }} />
        </IconButton>
        <Typography style={{ fontSize: '14px', display: 'inline' }}>PRECURSOR APPROVAL</Typography>
      </div>

      <PageWrapper
        transparent
        title={
          <Typography style={{ color: Colors.textLight, fontSize: 14, fontWeight: 800, textTransform: 'uppercase' }}>
            {orderDetails.data?.po_number}
          </Typography>
        }
        name={
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            {orderDetails.data?.pharmacy?.name}
          </Typography>
        }
      >
        <Container>
          <Grid container direction="row" style={{ width: '18%', right: '0px', top: '22px', position: 'absolute' }}>
            <Grid item style={{ paddingRight: '5px' }}>
              <Button
                onClick={() => setOpenRejectReasonModal(true)}
                variant="contained"
                color={ColorType.DANGER}
                disabled={rejectButtonDisabled}
              >
                REJECT
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                disabled={loading || approveButtonDisabled}
                color={ColorType.PRIMARY}
                onClick={onApprove}
              >
                {loading ? 'LOADING...' : 'APPROVE'}
              </Button>
            </Grid>
          </Grid>
          <RejectReasonModal
            orderDetails={orderDetails}
            onClose={() => setOpenRejectReasonModal(false)}
            open={openRejectReasonModal}
          />
        </Container>
        <TabFilter
          setApproveButtonDisabled={setApproveButtonDisabled}
          setRejectButtonDisabled={setRejectButtonDisabled}
          value={queryParams.precursor_tab}
          orderDetails={orderDetails}
          precursorStatusHistory={precursorStatusHistory}
          onChange={handleTabChange}
        />
      </PageWrapper>
      <Snackbar
        autoHideDuration={5000}
        open={!!message}
        color={ColorType.PRIMARY}
        message={message}
        onClose={handleCloseSnackbar}
      />
      <Snackbar
        autoHideDuration={5000}
        open={!!error}
        color={ColorType.DANGER}
        message={error}
        onClose={handleCloseSnackbar}
      />
    </Grid>
  );
};
