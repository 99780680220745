export enum PaymentProvider {
  DOKU = 'DOKU',
  DIGIO = 'DIGIO',
  XENDIT = 'XENDIT',
}

export enum NewPaymentProviders {
  DOKU = 'doku',
  XENDIT = 'xendit',
}

export enum PaymentProvidersID {
  DOKU = 1,
  XENDIT = 2,
}

export const PAYMENT_PROVIDER_DOKU = PaymentProvider.DOKU;
export const PAYMENT_PROVIDER_DIGIO = PaymentProvider.DIGIO;
export const PAYMENT_PROVIDER_XENDIT = PaymentProvider.XENDIT;
