import { Checkbox, Container, StyleRules } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import { COLORS } from 'common/constants';
import { Colors } from 'common/themes/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const styles = (): StyleRules => ({
  list: {
    backgroundColor: 'white',
  },
  details: {
    backgroundColor: grey[200],
    position: 'relative',
  },
  mainContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: '100%',
    position: 'relative',
  },
  content: {
    flex: '1 0',
    backgroundColor: 'white',
    position: 'relative',
    overflow: 'hidden',
  },
});

export const BackButton = styled(Link)`
  && {
    color: ${COLORS.GREY_600};
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    text-decoration: none;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
  }
`;

export const PrecursorReasonCheckbox = withStyles({
  checked: {
    color: `${Colors.primary} !important`,
  },
})(Checkbox);

export const PrecursorReasonContainer = styled(Container)`
  box-sizing: border-box;

  position: relative;
  width: 100%;
  height: 150px;
  background: #fcfcfc;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
`;

export const PrecursorDetailTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: normal;
  margin-bottom: 10px;

  & tbody {
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.23);
    border-radius: 7px;

    & tr {
      border-bottom: 1px solid #e9eef4;
    }
  }

  & th {
    font-size: 14px;
    font-weight: bold;
  }

  & th.sortable {
    cursor: pointer;
  }
  & td > p {
    font-size: 14px;
  }

  & th,
  & td {
    width: 8%;
    text-align: left;
    padding: 0 16px;
    height: 64px;
  }

  & tr.order-items {
    background: rgba(196, 196, 196, 0.37);
  }
  & td.succeeded_issue_date,
  & th.succeeded_issue_date {
    min-width: 110px;
  }
  & td.succeeded_expiry_date,
  & th.succeeded_expiry_date {
    min-width: 110px;
  }
  & td.succeeded_uploaded_at,
  & th.succeeded_uploaded_at {
    min-width: 120px;
  }

  & th.failed_filename {
    width: 20%;
  }
  & th.failed_failed_reason {
    width: 70%;
  }
  & th.failed_created_at {
    width: 10%;
  }
`;
