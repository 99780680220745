import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#269781',
      dark: '#246F61',
      light: '#67D1BD',
    },
    secondary: {
      main: '#ffcc66',
    },
  },
  typography: {
    fontFamily: 'Nunito Sans, sans-serif',
  },
  status: {
    danger: 'orange',
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 6px 8px rgba(0, 0, 0, 0.13), 0px -1px 1px rgba(0, 0, 0, 0.04);',
        borderRadius: 5,
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          '0px 1px 1px rgba(0, 0, 0, 0.07), 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 6px 8px rgba(0, 0, 0, 0.13), 0px -1px 1px rgba(0, 0, 0, 0.04);',
        borderRadius: 5,
        marginTop: 5,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 13,
      },
    },
  },
});

export default theme;
