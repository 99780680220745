import React from 'react';

import Grid from '@material-ui/core/Grid';

import { CardTablePageChangeHandler } from '../../../types';

import { S } from './PageNumber.styles';

interface Props {
  current: number;
  disabled?: boolean;
  total: number;
  onChange?: CardTablePageChangeHandler;
}

export const CardTablePageNumber: React.FC<Props> = ({
  current,
  disabled,
  total,
  onChange,
}) => {
  const isOnFirstPage = current === 1;
  const isOnLastPage = current === total;

  const handlePreviousClick = (): void => {
    if (onChange && !isOnFirstPage) {
      onChange(current - 1);
    }
  };

  const handleNextClick = (): void => {
    if (onChange && !isOnLastPage) {
      onChange(current + 1);
    }
  };

  return (
    <S.Wrapper>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <S.Previous
            disabled={!!disabled || isOnFirstPage}
            onClick={handlePreviousClick}
            data-testid="previous-btn"
          />
        </Grid>
        <Grid item>
          <S.Text disabled={disabled}>
            {current} <S.Inactive>of {total}</S.Inactive>
          </S.Text>
        </Grid>
        <Grid item>
          <S.Next
            disabled={!!disabled || isOnLastPage}
            onClick={handleNextClick}
            data-testid="next-btn"
          />
        </Grid>
      </Grid>
    </S.Wrapper>
  );
};
