import React from 'reactn';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { OrderReturnType } from 'common/services/apis/v3/swipe-rx-pt';
import { Dialog, Button } from 'common/components';
import { ColorType } from 'types';
import { OrderReturnTypeModalProps } from './interfaces';
export const OrderReturnTypeModal: React.FC<OrderReturnTypeModalProps> = ({
  returnType: { handleReturnTypeChange, type },
  steps: { step, nextStep, clearSteps },
  data: { orderId, orderDetails },
}) => {
  const renderContent = (): JSX.Element => {
    return (
      <FormControl
        component="fieldset"
        style={{ width: '100%', minWidth: 300 }}
      >
        <FormLabel
          style={{ paddingBottom: 10, fontSize: 14 }}
          component="legend"
        >
          Return Types
        </FormLabel>
        <RadioGroup
          aria-label="return-type"
          name="return-type"
          value={type}
          onChange={handleReturnTypeChange}
        >
          <FormControlLabel
            value={OrderReturnType.PARTIAL}
            control={<Radio color="primary" />}
            label="Partial return"
          />
          <FormControlLabel
            value={OrderReturnType.FULL}
            control={<Radio color="primary" />}
            label="Full return"
          />
        </RadioGroup>
      </FormControl>
    );
  };

  const renderActions = (): JSX.Element => (
    <Button
      color={ColorType.PRIMARY}
      disabled={!type}
      type="button"
      onClick={nextStep}
    >
      Next
    </Button>
  );

  return (
    <Dialog
      open={!!orderId && step === 1}
      primary
      title="Return Order"
      onClose={clearSteps}
      renderActions={renderActions}
      renderContent={renderContent}
      subTitle="Order ID"
      value={orderDetails.length === 1 ? orderDetails[0].po_number : ''}
    />
  );
};
