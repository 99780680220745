/* eslint-disable no-plusplus */
interface Ib64toBlob {
  base64Data: string;
  contentType?: string;
  sliceSize?: number;
}

export const b64toBlob = (params: Ib64toBlob): Blob => {
  const { base64Data, contentType='', sliceSize=512 } = params
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
};
