import React from 'reactn';
import { View, Image, Text } from '@react-pdf/renderer';

import { styles } from './styles';

interface HeaderProps {
  subtitle: string;
  slaTag?: string | null;
}

export const Header: React.FC<HeaderProps> = ({ subtitle, slaTag }) => {
  return (
    <View
      fixed
      style={{
        width: '190mm',
        maxWidth: '190mm',
        fontFamily: 'Sarabun',
        fontSize: '4mm',
        fontWeight: 'bold',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginHorizontal: '0pt',
      }}
    >
      <Image
        src={`${process.env.PUBLIC_URL}/mClinica-logo.png`}
        style={{ width: '24mm', height: '6mm', marginTop: '2mm' }}
      />
      <View style={{ display: 'flex', flexDirection: 'row', textAlign: 'right' }}>
        <Text
          style={!slaTag ? { fontSize: '4mm', width: '70mm' } : { fontSize: '4mm', width: '70mm', marginRight: '2mm' }}
        >
          {subtitle}
        </Text>
        {slaTag && <Text style={styles.sla}>{slaTag}</Text>}
      </View>
    </View>
  );
};
