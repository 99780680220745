/* eslint-disable no-restricted-syntax */
import React, { useDispatch, useGlobal } from 'reactn';

import { Button, Dialog } from 'common/components';
import { DataObject, ColorType } from 'types';

import { useTransactionBulkAction } from '../../hooks';
import { OrderPrecursorStatus } from 'pages/transactions/types';

export const BulkActionConfirmationModal: React.FC = () => {
  const [{ bulkActionConfirmation, loading }] = useGlobal('transactions');
  const [dataTables] = useGlobal('dataTables');

  const setTransactionBulkActionConfirmation = useDispatch('setTransactionBulkActionConfirmation');

  const processTransactionBulkAction = useTransactionBulkAction();

  if (!bulkActionConfirmation) {
    return null;
  }

  const { action, orderIds } = bulkActionConfirmation;

  const selectedItem = dataTables.orders.rows.filter((item) => orderIds.includes(item.id));

  const checkItemRestriction = (items: DataObject[]): [boolean, string] => {
    // Check prekursor item requirement if the action is print
    if (action === 'print') {
      for (const item of items) {
        const { precursor_status } = item;
        if (precursor_status && precursor_status.status !== OrderPrecursorStatus.DOCUMENT_APPROVED) {
          return [
            false,
            "There are order items whose prekursor requirement hasn't been approved. Printing PO is not allowed",
          ];
        }
      }
    }

    return [true, ''];
  };

  const [allowSubmit, restrictionReason] = checkItemRestriction(selectedItem);

  const handleClose = (): void => {
    setTransactionBulkActionConfirmation(null);
  };

  const handleSubmit = async (): Promise<void> => {
    processTransactionBulkAction(action, orderIds, true);

    handleClose();
  };

  const renderContent = (): JSX.Element => (
    <div style={{ textAlign: 'center' }}>
      {bulkActionConfirmation.message.split('\n').map((text) => (
        <>
          <br />
          {text}
        </>
      ))}
      <br />
      <br />
      {!allowSubmit && <p style={{ color: '#ff0000' }}>{restrictionReason}</p>}
      Do you wish to proceed?
    </div>
  );

  const renderActions = (): JSX.Element => (
    <>
      <Button isSecondary color={ColorType.DANGER} disabled={loading} onClick={handleClose}>
        No
      </Button>
      <Button color={ColorType.PRIMARY} disabled={loading || !allowSubmit} loading={loading} onClick={handleSubmit}>
        Yes
      </Button>
    </>
  );

  return (
    <Dialog
      open
      primary
      title="Confirmation"
      onClose={handleClose}
      renderActions={renderActions}
      renderContent={renderContent}
      showCloseButton={false}
    />
  );
};
