import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';

export class SwipeRxPtDistributorProduct {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.DISTRIBUTOR_PRODUCT;
  }

  async bulkUpload(
    option: string,
    distributorId: number,
    file: File,
    additionalOptions?: Record<string, string>,
  ): Promise<{
    data: any;
  }> {
    const form = new FormData();
    form.append('option', option);
    form.append('file', new Blob([await file.arrayBuffer()], { type: 'text/csv' }), file.name);
    if (additionalOptions) {
      Object.keys(additionalOptions).forEach((key) => {
        form.append(key, additionalOptions[key]);
      });
    }

    return this.base.post(`${this.resource}/${distributorId}/bulk-upload/distributor_product_pharmacy_max_qty`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }
}
