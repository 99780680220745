import { RoundedFieldCurrency, FieldInput } from 'common/components';

import { OrderInvoiceFormState } from './use-order-invoice-form';

type OrderInvoiceFormKeys =
  | keyof OrderInvoiceFormState
  | 'pharmacy_name'
  | 'pharmacy_address';

interface FieldOptions {
  name: OrderInvoiceFormKeys;
  component: React.FC<any>;
  label?: string;
  placeholder?: string;
  type?: string;
}

export const fieldOptions = [
  {
    name: 'invoice_number',
    component: FieldInput,
    label: 'Invoice Number',
    placeholder: 'Enter invoice number',
  },
  {
    name: 'invoiced_amount',
    component: RoundedFieldCurrency,
    label: 'Invoice Amount',
    placeholder: 'Enter invoice amount',
  },
  {
    name: 'invoiced_at',
    component: FieldInput,
    placeholder: 'Enter invoice date',
    label: 'Date',
    type: 'date',
  },
  {
    name: 'pharmacy_address',
    placeholder: 'Enter pharmacy address',
    label: 'Ship to',
  },
  {
    name: 'pharmacy_name',
    placeholder: 'Enter pharmacy name',
    label: 'Bill to',
  },
];

export const getFieldOption = (name: OrderInvoiceFormKeys): FieldOptions =>
  fieldOptions.find((item) => item.name === name) as FieldOptions;
