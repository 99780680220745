import React, { useState } from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';

import SideBarUser from '../SideBarUser';
import { SideBarMenuContainer } from '../../../customer-support-user/sidebar-menu';
import { S } from './Sidebar.styles';

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    overflow: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(11) + 1,
    },
  },
  iconOpen: {
    position: 'absolute',
    bottom: '5rem',
    left: '130px',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  iconClose: {
    position: 'absolute',
    bottom: '5rem',
    left: '30px',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

/* TODO: GET CORRECT ROLES AND BIND THEM HERE */
const SideBarMenu = () => {
  return <SideBarMenuContainer />;
};

const SideBar = ({ username, role }) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const handleToggle = () => setOpen(!open);

  return (
    <S.SidebarDrawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <SideBarUser role={role} username={username} open={open} />
      <SideBarMenu role={role} />
      <IconButton
        data-testid="side-bar-toggle-icon"
        onClick={handleToggle}
        className={clsx({
          [classes.iconOpen]: open,
          [classes.iconClose]: !open,
        })}
        style={{ background: 'white' }}
        size="small"
      >
        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </S.SidebarDrawer>
  );
};

export default SideBar;
