import { createModel } from '@rematch/core';
import { DownloadAPI } from '../services/apis/v2';

interface TableState {
  downloading?: boolean;
  error?: string;
  done?: boolean;
}

interface DownloadState {
  [key: string]: TableState;
}

interface DownloadPayload {
  query: Partial<{
    order: string;
    page_size: number;
    page: number;
    pharmacy_id: string;
    sort: string;
  }>;
  tableName: string;
}

const updateTable = (
  state: DownloadState,
  tableName: string,
  update: TableState,
): DownloadState => {
  const tableState = state[tableName];

  return {
    ...state,
    [tableName]: {
      ...tableState,
      ...update,
    },
  };
};

export const csvDownload = createModel<DownloadState>({
  state: {},
  reducers: {
    setError: (state, tableName: string, error: string): DownloadState =>
      updateTable(state, tableName, { error, downloading: false }),

    startDownload: (state, tableName: string): DownloadState =>
      updateTable(state, tableName, {
        downloading: true,
      }),

    downloadDone: (state, tableName: string): DownloadState =>
      updateTable(state, tableName, {
        done: true,
      }),

    finishDownload: (state, tableName: string): DownloadState =>
      updateTable(state, tableName, {
        downloading: false,
        done: false,
        error: undefined,
      }),
  },
  effects: {
    async download(payload: DownloadPayload) {
      const { tableName, query } = payload;

      try {
        this.startDownload(tableName);

        await new DownloadAPI().download(tableName, query);
      } catch ({ message }) {
        this.setError(tableName, 'Something went wrong with the request');
        console.error('@model.csvDownloads::download', message);
      }

      this.downloadDone(tableName);
    },
  },
});
