import React, { useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';

import { SortOrder } from 'types';
import { ConditionalRender } from 'common/components';

import { CardTableHeaderCell } from '../../interfaces';
import { CardTableSortHandler } from '../../types';

import { HeaderItem } from '../HeaderItem';

import { S } from './CardTableHeader.styles';

interface CardTableHeaderProps {
  hasSelection?: boolean;
  initialParams?: {
    sortKey: string;
    sortOrder: SortOrder | null;
  };
  items: CardTableHeaderCell[];
  selectable?: boolean;
  selectedAll?: boolean;
  onSelectAll?: () => void;
  onSort?: CardTableSortHandler;
}

export const CardTableHeader: React.FC<CardTableHeaderProps> = ({
  hasSelection,
  initialParams,
  items,
  onSelectAll,
  onSort,
  selectable,
  selectedAll = false,
}) => {
  const [sortKey, setSortKey] = useState(
    initialParams && initialParams.sortKey,
  );

  const handleSort: CardTableSortHandler = (key, order) => {
    if (onSort) {
      onSort(key, order);
    }

    setSortKey(key);
  };

  const renderItems = (): JSX.Element[] =>
    items.map((item) => {
      const { id, path, sort, text } = item;
      const key = path || id || text;

      return (
        <HeaderItem
          key={key}
          initialSortOrder={initialParams && initialParams.sortOrder}
          path={path}
          sort={sort}
          sortKey={sortKey}
          text={text}
          onSort={handleSort}
        />
      );
    });

  return (
    <thead>
      <tr>
        <ConditionalRender condition={!!selectable}>
          <S.CheckboxCell>
            <Checkbox
              checked={hasSelection || selectedAll}
              color="primary"
              indeterminate={hasSelection && !selectedAll}
              onClick={onSelectAll}
              data-testid="select-all-checkbox"
            />
          </S.CheckboxCell>
        </ConditionalRender>
        {renderItems()}
      </tr>
    </thead>
  );
};
