import React from 'react';

import { FormikTouched, FormikValues, FormikErrors } from 'formik';
import { S } from './field-wrapper.styles';
import { FieldErrorMessage } from '../field-error-message';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  touched?: FormikTouched<FormikValues> | boolean;
  error?: FormikErrors<FormikValues> | string;
  label?: string;
  isLabelBold?: boolean;
  hasAsterisk?: boolean;
  minWidth?: number | string;
  labelextend?: string;
}

export const FieldWrapper: React.FC<Props> = ({
  children,
  touched,
  error,
  label,
  isLabelBold,
  hasAsterisk,
  labelStyle,
  labelextend,
  ...props
}) => {
  const asterisk = <S.Asterisk>*</S.Asterisk>;
  return (
    <S.Wrapper {...props}>
      {!label && !labelextend ? null : (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <S.Label style={{ fontWeight: isLabelBold ? 'bold' : undefined, ...labelStyle }}>{label}</S.Label>
          <S.LabelExtend style={{ fontWeight: isLabelBold ? 'bold' : undefined, ...labelStyle }}>{labelextend}</S.LabelExtend>
          {hasAsterisk && asterisk}
        </div>
      )}
      {children}
      <FieldErrorMessage touched={touched} error={error} />
    </S.Wrapper>
  );
};
