import { CardTableColumn } from '../interfaces';

export const executeColumnResizeInterval = ({
  key,
  columns,
  selectable,
}: {
  key?: string;
  columns: CardTableColumn[];
  selectable?: boolean;
}): NodeJS.Timeout =>
  setInterval(() => {
    columns.forEach(({ width }, i) => {
      if (!width) {
        return;
      }

      const tableClassName = key ? `.${key}` : '';
      const childIndex = i + (selectable ? 2 : 1);

      const headerCellWidth = document.querySelector(
        `${tableClassName}.card-table-header thead tr th:nth-child(${childIndex})`,
      )?.clientWidth;

      const bodyCellWidth = document.querySelector(
        `${tableClassName}.card-table-body tbody tr td:nth-child(${childIndex})`,
      )?.clientWidth;

      if (!bodyCellWidth) {
        return;
      }

      if (!headerCellWidth) {
        return;
      }

      const headerCellCol = document.querySelector<HTMLElement>(
        `${tableClassName}.card-table-header colgroup col:nth-child(${childIndex})`,
      );

      if (headerCellCol && headerCellWidth !== bodyCellWidth && bodyCellWidth / headerCellWidth < 3) {
        headerCellCol.style.width = `${bodyCellWidth}px`;
        headerCellCol.style.minWidth = `${bodyCellWidth}px`;
      }
    });
  }, 300);
