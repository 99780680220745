/* eslint-disable no-restricted-globals */
import { addReducer } from 'reactn';

import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';
import { recordException } from 'utils/Reporting/Sentry';

import { AreaState } from './interfaces/AreaState.interface';

export const AREAS_INITIAL_STATE: AreaState = {
  areaList: {
    data: null,
  },
  loading: false,
};
addReducer('fetchAreas', async (state, dispatch, payload) => {
  const { areas } = state;

  try {
    const { data } = await SwipeRxPt.instance.areas.fetchAreas(payload);
    return {
      ...state,
      areas: {
        ...areas,
        areaList: {
          data,
        },
      },
    };
  } catch (e) {
    recordException(e, 'fetchAreas', { areas });
    dispatch.setAreaError({ error: e.message });
    return state;
  }
});


addReducer('setAreaError', (state, _, payload) => {
  const { areas } = state;

  return {
    ...state,
    areas: {
      ...areas,
      error: payload,
    },
  };
});
