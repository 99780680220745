import { TRANSACTION_BULK_ACTION_ITEM_MAP_BY_STATUS, UserRoles } from 'common/constants';
import { TransactionActionName, TransactionAction } from 'types';

import { isAllowed } from './get-transaction-action-items.util';

type CustomConditions = Record<TransactionActionName, boolean>;

export const getTransactionBulkActionItems = (
  userRole: UserRoles,
  status: string,
  page?: string | string[] | null | undefined,
  customConditions?: CustomConditions,
): Array<TransactionAction | undefined> => {
  const actions = (TRANSACTION_BULK_ACTION_ITEM_MAP_BY_STATUS[status] || []).filter((item) => {
    if (!item) {
      return true;
    }

    const { actionItem, allowedRoles } = item;

    if (customConditions) {
      return customConditions[actionItem.name];
    }

    return isAllowed(userRole, allowedRoles);
  });

  if (!actions[actions.length - 1]) {
    const newActions = [...actions];

    newActions.pop();

    return newActions;
  }

  if (page === 'pending') {
    const bulkPending = TRANSACTION_BULK_ACTION_ITEM_MAP_BY_STATUS.pendingAll || [];
    return bulkPending;
  }

  return actions;
};
