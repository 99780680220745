import styled, { css } from 'styled-components';

const tableStyle = css`
  display: table;
  border: 0;
  border-bottom: 1px solid #ccc;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-size: 12px;

  & th {
    text-transform: uppercase;
    background: #ccc;
    border-radius: 0;
    color: #333;
    font-weight: 700;
    padding: 3px;
    border: 1px solid #fff;
  }
`;

const DeliveryWrapper = styled.div`
  ${({ className }) =>
    className === 'deliveryDocsWrapper' &&
    css`
      &:not(:first-child) {
        margin-top: 68px;
      }
    `}

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const PrimaryDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Brand = styled.div`
  font-size: 22px;
  flex: 0 0 50%;

  & strong {
    font-size: 20px;
  }
`;

const OrganizationData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0 0 50%;
  text-align: right;
`;

const Title = styled.div`
  text-align: center;
  height: 17px;
`;

const InvoiceTable = styled.table`
  ${tableStyle}

  & td {
    padding: 8px 15px;
    font-weight: 400;
  }
`;

const SignatureContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
`;

const SignatureContent = styled.div`
  flex: 0 0 50%;
  position: relative;
`;

const SignatureSlot = styled.div`
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  position: relative;
  bottom: 0;
  width: 60%;
  left: 20%;
  height: 20px;
`;

const SignatureAdditionalInfo = styled.div`
  display: flex;
  height: 26px;
  padding: 0 26px;

  & p:first-child {
    width: 150px;
    text-align: left;
  }
`;

const ReturnFormTable = styled.table`
  ${tableStyle}

  & tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  & td {
    height: 16px;
  }
`;

const AddendumContainer = styled.div`
  display: flex;

  & .note {
    flex: 1 0 62%;
    font-size: 14px;

    & .qr-code {
      margin-top: 6px;
    }
  }

  & .virtual-accounts {
    padding-left: 20px;
  }
`;

const Divider = styled.div`
  width: 100%;
  border: 2px dashed rgba(0, 0, 0, 0.2);
  margin: 4px 0;
`;

const BlankPageWithQr = styled.div`
  position: relative;

  ${({ className }) =>
    className === 'invoiceDocsWrapper'
      ? css`
           {
            height: 1083px;
          }
        `
      : css`
           {
            height: 1092px;
          }
        `}

  & .qrcode-container {
    position: absolute;
    bottom: 20px;
    display: flex;

    & .qrcode-label {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-left: 16px;
      padding-bottom: 10px;
    }
  }
`;

export const S = {
  DeliveryWrapper,
  PrimaryDataContainer,
  Brand,
  OrganizationData,
  Title,
  InvoiceTable,
  SignatureContainer,
  SignatureContent,
  SignatureSlot,
  SignatureAdditionalInfo,
  ReturnFormTable,
  AddendumContainer,
  Divider,
  BlankPageWithQr,
};
