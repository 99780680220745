import { FC } from 'react';
import styled, { css } from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { GridProps } from '@material-ui/core/Grid';

import { Colors } from '../../themes/colors';

interface WrapperProps extends GridProps {
  transparent?: number;
}

export const titleCss = css`
  && {
    color: ${Colors.textNormal};
    display: inline;
    font-size: 28px;
    font-weight: 300;
    line-height: 32px;
  }
`;

const getWrapperBackground = ({ transparent }: WrapperProps): string => (transparent ? '#fafafa' : '#fff');

const Wrapper = styled<FC<WrapperProps>>(Grid)`
  && {
    background: ${getWrapperBackground};
    padding: 20px;
    position: relative;
    flex: 1 0;
  }
`;

const Title = styled(Typography)`
  ${titleCss}
`;

export const S = {
  Wrapper,
  Title,
};
