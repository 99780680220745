import React, { useDispatch, useGlobal } from 'reactn';

import { Button, Dialog } from 'common/components';
import { ColorType } from 'types';

import { S } from './SplitOrderItemModal.styles';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const SplitOrderItemConfirmationModal: React.FC<Props> = ({
  open,
  onClose,
}) => {
  const [{ split, loading }] = useGlobal('transactions');
  const splitOrderItems = useDispatch('splitOrderItems');

  const { poNumber } = split;

  const handleClose = (): void => {
    onClose();
  };

  const handleSubmit = async (): Promise<void> => {
    await splitOrderItems({});

    onClose();
  };

  const renderContent = (): JSX.Element => (
    <S.ConfirmationMessage>
      Are you sure you want to split the items from
      <br />
      order <S.PoNumber>{poNumber}</S.PoNumber>?
      <br />
      <br />
      Please note that a new order will be created
      <br /> after confirmation.
    </S.ConfirmationMessage>
  );

  const renderActions = (): JSX.Element => (
    <>
      <Button
        isSecondary
        color={ColorType.DANGER}
        disabled={loading}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button
        color={ColorType.PRIMARY}
        disabled={loading}
        loading={loading}
        onClick={handleSubmit}
      >
        Yes, I confirm
      </Button>
    </>
  );

  return (
    <Dialog
      open={open}
      primary
      title="Confirmation"
      onClose={handleClose}
      renderActions={renderActions}
      renderContent={renderContent}
      showCloseButton={false}
    />
  );
};
