import { useGlobal, useDispatch, useState } from 'reactn';

import { IDeliveryPartner } from 'common/services/apis/v3/swipe-rx-pt';

import { OrderDeliveryPartnerAutoCompleteOption } from './order-delivery-partner-autocomplete.component'
import { useEffect } from 'react';

type UseOrderDeliveryPartner = {
  collection: IDeliveryPartner[];
  options: OrderDeliveryPartnerAutoCompleteOption[];
  selected: IDeliveryPartner | undefined;
  onSearch: (term?: string) => void;
  onSearchResultSelected: (id: string) => void;
};

export const useOrderGetDeliveryPartner = (): UseOrderDeliveryPartner => {
  const search = useDispatch('searchDeliveryPartner');
  const select = useDispatch('searchDeliveryPartnerSelected');

  const [options, setOptions] = useState<OrderDeliveryPartnerAutoCompleteOption[]>([]);

  const [{ 
    orderDeliveryPartner: {
      data: collection, 
      selected
    }
  }] = useGlobal('transactions');

  const onSearch = (term?: string): void => {
    search({ term });
  };

  const onSearchResultSelected = (partnerId: string): void => {
    const selectedDistributor = collection.find(({ id }) => id === +partnerId);
    select({ selected: selectedDistributor || undefined });
  };

  useEffect(() => {
    setOptions(collection.map((item => ({
      value: item.id.toString(),
      label: item.name
    }))))
  }, [collection])

  return {
    options,
    collection,
    selected,
    onSearch,
    onSearchResultSelected
  }
};
