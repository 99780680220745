import styled from 'styled-components';

const Wrapper = styled.input`
  background: #fff;
  border: 1px solid #009688;
  border-radius: 3px;
  outline: none;
  padding: 4px 8px;

  &:disabled {
    background: #fafafa;
    border-color: #d8d8d8;
  }
`;

export const S = {
  Wrapper,
};
