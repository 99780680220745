import React from 'react';
import { Typography, Grid, Box, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import DashboardCard from 'common/components/DashboardCard';

import { getMarketPath } from 'utils/get-router-path';
import history from '../../history';

const styles = (theme) => ({
  box: {
    padding: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(3),
    fontWeight: 300,
  },
});

class Dashboard extends React.Component {
  state = {
    activeMenu: 0,
  };

  componentDidMount() {
    const backlogArray = ['overdue', 'pending', 'unmatched', 'mismatched'];

    const selectedArray = backlogArray.indexOf(history.location.pathname.split('/')[1]);
    const path = selectedArray === -1 ? 0 : selectedArray;

    this.setState({
      activeMenu: path,
    });
    this._initialCall();
  }

  // Initial call to backlog services to populate dashboard cards and page 1s
  _initialCall = async () => {
    const {
      getOverdueInvoices,
      getPendingDeposits,
    } = this.props;

    const overdueInvoices = getOverdueInvoices({
      due_at: moment.utc().format('DD/MM/YYYY').toString(),
      page: 1,
      page_size: 50,
      sort_by: 'asc',
      order_by: 'invoiced_at',
    });

    const pendingDeposits = getPendingDeposits({
      status: 'uploaded',
      page: 1,
      page_size: 50,
      order_by: 'uploaded_at',
      sort_by: 'asc',
    });

    await Promise.all([
      overdueInvoices,
      pendingDeposits,
    ]);
  };

  _isActiveMenu = (menuNum) => {
    const { activeMenu } = this.state;
    const isActiveMenu = activeMenu === menuNum;
    return isActiveMenu;
  };

  _goToRoute = (route, active) => {
    history.push(`${route}`);
    this.setState({ activeMenu: active });
  };

  render() {
    const {
      classes,
      backlogStore,
      match: {
        params: { marketId },
      },
    } = this.props;
    const prefix = getMarketPath(marketId);
    return (
      <Box classes={{ root: classes.box }}>
        <Typography variant="h5" classes={{ root: classes.header }}>
          Backlog
        </Typography>
        <Grid item container spacing={3} xs={12} md={6} lg={10} xl={8}>
          <Grid item sm={12} md={4} lg={3}>
            <DashboardCard
              selected={this._isActiveMenu(0)}
              label="overdue invoices"
              value={backlogStore.overdue_invoices ? backlogStore.overdue_invoices.total : 0}
              onClick={() => this._goToRoute(`${prefix}/overdue/invoices`, 0)}
            />
          </Grid>
          <Grid item sm={12} md={4} lg={3}>
            <DashboardCard
              selected={this._isActiveMenu(1)}
              label="pending deposits"
              value={backlogStore.pending_deposits ? backlogStore.pending_deposits.total : 0}
              onClick={() => this._goToRoute(`${prefix}/pending/deposits`, 1)}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  authStore: state.authStore,
  backlogStore: state.backlogStore,
});

const mapDispatchToProps = ({ authStore, backlogStore }) => {
  return {
    ...authStore,
    ...backlogStore,
  };
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));
