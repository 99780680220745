import styled from 'styled-components';

const Wrapper = styled.div`
  && {
    span {
      font-size: 15px;
      margin-right: 5px;
    }
  }
`;

export const S = {
  Wrapper,
};
