import React from 'react';

import MuiSnackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { ColorType } from 'types';

import { S } from './Snackbar.styles';

interface Props extends SnackbarProps {
  color: ColorType;
  onClose?: () => void;
  contentStyle?: React.CSSProperties;
}

export const Snackbar: React.FC<Props> = ({ color, message, onClose, contentStyle, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onCloseSnackbar = () => {
    if(onClose) onClose();
  };
  return (
  <MuiSnackbar onClose={onCloseSnackbar} {...props}>
    <S.Content
      data-testid="snackbar-content"
      color={color}
      message={message}
      style={contentStyle}
      action={[
        <IconButton data-testid="snackbar-close" key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>,
      ]}
    />
  </MuiSnackbar>
)};
