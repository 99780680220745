import React, { useState } from 'react';

import ChipInput, { Props as FieldMultiInputProps } from 'material-ui-chip-input';

import { uniq } from 'lodash';

interface OptionalProps {
  name: string;
  values: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  onChange?: (value: any) => void;
  regexDelimiter?: string | RegExp;
}

export const FieldMultiInput: React.FC<FieldMultiInputProps & OptionalProps> = ({
  values,
  setFieldValue,
  onChange,
  name,
  onBlur,
  regexDelimiter = '\n',
  ...props
}) => {
  const getChips = (value: string): string[] => value.split(',').filter((val) => val !== '');

  const [chips, setChips] = useState(getChips(values));

  const handleChangeChip = (newValue: string): void => {
    if (setFieldValue) {
      setFieldValue(name, newValue);
    } else if (onChange) {
      onChange(newValue);
    }
  };

  const handleAdd = (value: string): void => {
    setChips((state) => uniq([...state, ...getChips(value)]));

    const newValue = chips.concat(value).join(',');
    handleChangeChip(newValue);
  };

  const handleDelete = (deletedChip: string): void => {
    const newValue = chips.filter((chip) => chip !== deletedChip).join(',');
    setChips(getChips(newValue));
    handleChangeChip(newValue);
  };

  return (
    <ChipInput
      data-testid="chip-input"
      value={chips}
      onAdd={handleAdd}
      onDelete={handleDelete}
      newChipKeys={[',']}
      onBlur={(e: any) => {
        if (onBlur) {
          onBlur(e);
        }
        handleAdd(e.target.value);
      }}
      fullWidth
      onPaste={(event) => {
        event.preventDefault(); // prevent default behavior
        const clipboard = event.clipboardData.getData('Text');
        const addedValue = uniq(clipboard.split(regexDelimiter)).join(',');
        handleAdd(addedValue);
      }}
      {...props}
    />
  );
};

FieldMultiInput.defaultProps = {
  variant: 'outlined',
};
