/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDispatch } from 'reactn';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { InvoiceCreate } from 'common/services/apis/v3/swipe-rx-pt';
import { useCrypto } from 'hooks/useCrypto';
import { formatToDatetime, parseDateString } from 'common/utils';

export interface OrderInvoiceFormState {
  id?: string;
  order_id: number;
  invoice_number: string;
  invoiced_amount: number;
  invoiced_at: string;
  ordered_at: string;
}

export const useOrderInvoiceForm = (initialValues: Partial<OrderInvoiceFormState>) => {
  const createInvoice = useDispatch('createInvoice');
  const updateInvoice = useDispatch('updateInvoice');
  const { sha256 } = useCrypto();
  const orderedAt = parseDateString(initialValues?.ordered_at);

  const form = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      invoice_number: Yup.string().required('Invoice Number is required.'),
      invoiced_amount: Yup.string().required('Invoice Amount is required.'),
      invoiced_at: Yup.date().transform(parseDateString).min(orderedAt).required('Invoice Date is required.'),
    }),
    onSubmit: async ({ id, order_id, invoice_number, invoiced_amount, invoiced_at }, { setSubmitting }) => {
      if (!order_id) {
        return;
      }

      const payload = {
        invoice_number,
        invoiced_amount,
        // ensures invoiced_at is already in UTC before sending to backend
        invoiced_at: formatToDatetime(invoiced_at!, 'YYYY-MM-DD'),
      };

      if (id) {
        await updateInvoice({ orderId: order_id, invoice: payload });
      } else {
        let idempotence_key = '';

        try {
          idempotence_key = await sha256(
            JSON.stringify({
              order_id,
              invoice_number,
              invoiced_amount,
              invoiced_at,
            }),
          );
        } catch (e) {
          // do nothing
        }

        await createInvoice({
          orderId: order_id,
          invoice: {
            ...(payload as InvoiceCreate),
            idempotence_key,
          },
        });
      }
      setSubmitting(false);
    },
  });

  return form;
};
