import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface Props {
  title: string;
  style?: React.CSSProperties;
}

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: 14,
      fontWeight: 300,
      color: '#246F61',
    },
  }),
);

export const TitleDetail: React.FC<Props> = ({ title, style }) => {
  const classes = useStyles();
  return (
    <Typography classes={{ root: classes.title }} variant="subtitle2" color="primary" style={style}>
      {title}
    </Typography>
  );
};
export default TitleDetail;
