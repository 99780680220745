/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDispatch, useGlobal } from 'reactn';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ValueType, OptionTypeBase } from 'react-select';

export interface OrderItemReassignFormState {
  distributor: number;
}

const formState: OrderItemReassignFormState = {
  distributor: 0,
};

export const useOrderItemReassignForm = () => {
  const [{ orderItemReassign }] = useGlobal('transactions');
  // const reassignOrders = useDispatch('reassignOrders');
  const setOrderItemReassignDetails = useDispatch('setOrderItemReassignDetails');

  const [selectedDistributor, setSelectedDistributor] = useState<ValueType<OptionTypeBase> | null>(null);

  const resetOptions = (): void => {
    setOrderItemReassignDetails({
      availableDistributors: [],
      orderItems: [],
      poNumber: '',
    });

    setSelectedDistributor(null);
  };

  const form = useFormik({
    initialValues: {
      ...formState,
    },
    validationSchema: Yup.object({
      distributor: Yup.string().required('Please select a distributor.'),
    }),
    onSubmit: ({ distributor }, { setSubmitting }) => {
      setOrderItemReassignDetails({
        ...orderItemReassign,
        selectedDistributorId: distributor,
      });
      setSubmitting(false);
    },
  });

  return {
    form,
    orderItemReassign,
    resetOptions,
    selectedDistributor,
    setSelectedDistributor,
  };
};
