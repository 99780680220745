import {  useDispatch } from 'reactn';
import { ColorType } from 'types';

interface ISnackBar {
  prompt: (message: string, variant?: ColorType, autoHideDuration?: number) => void;
};


export const useSnackBar = (): ISnackBar => {
  const setSnackbar = useDispatch('setUtilsSnackbar');
  const closeSnackbar = useDispatch('closeSnackbar');

  const prompt = (message: string, variant = ColorType.PRIMARY, autoHideDuration = 8000): void => {
    setSnackbar({
      open: true,
      message,
      variant,
      onCloseSnackbar: () => closeSnackbar({}),
      autoHideDuration,
    });
  };

  return { prompt };
};
