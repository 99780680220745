export enum FormStateType {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
}

export enum ColumnType {
  TEXT = 'text',
  SELECT = 'select',
  ARRAY = 'array',
  ROWS = 'rows',
  SWITCH = 'switch',
  FILEUPLOAD = 'fileUpload',
  LOCALE_DATE = 'locale_date',
  MASK = 'mask',
  LINK = 'link',
  NUMBER = 'number',
  BUTTON = 'button',
  MARKDOWN = 'markdown',
}
