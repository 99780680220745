/* eslint-disable no-restricted-globals */
import { Criteria, CriteriaOption } from "common/components";

interface SearchCriteriaToQueryConfig {
  criteriaOptions: CriteriaOption[];
  multipleSelectCriteriaField: string;
  output?: string;
}

export const searchCriteriaToQuery = (searchCriterias: Criteria[], config?: SearchCriteriaToQueryConfig ): Record<string, string> => {
  return searchCriterias?.reduce((acc: any, criteria) => {
    const { id, value } = criteria;
    const isCriteriaMultipleSelect = id === config?.multipleSelectCriteriaField;
    if(isCriteriaMultipleSelect) {
      const multipleSelectCriteria = config?.criteriaOptions.find(searchCriteria => searchCriteria.id === id);
      const field = multipleSelectCriteria?.parameterMapping ?  multipleSelectCriteria.parameterMapping : id;
      if(config?.output === 'array') {
        acc[field] = acc[field] ? acc[field].concat([value]) : [value];
      } else {
        acc[field] = acc[field] ? `${acc[field]},${value}` : value;
      }
    } else {
      acc[id] = value;
    }
    return acc;
  }, {});
}