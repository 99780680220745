/* eslint-disable no-param-reassign */

import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { MarketingList, Marketing } from './interfaces';
import { MarketingParams } from './interfaces/MarketingParams.interface';

export class SwipeRxPtMarketings {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.MARKETING;
  }

  async retrieve(id: number, params?: MarketingParams): Promise<Marketing> {
    const { data } = await this.base.get(`${this.resource}/${id}`, params);

    return data;
  }

  async fetchMarketings(params: MarketingParams): Promise<MarketingList> {
    const result = await this.base.get(`${this.resource}/`, params);

    return result;
  }

  async fetchFilterOptions(): Promise<any> {
    const result = await this.base.get(`${this.resource}/filters`);

    return result;
  }

  async fetchProductListing(id: number, params: any): Promise<any> {
    const result = await this.base.get(`${this.resource}/${id}/product-listing`, params);

    return result;
  }

  async getAllPositions(): Promise<MarketingList> {
    return this.base.get(`${this.resource}?&is_published=true&page=1&page_size=1000&fields=id,position`);
  }

  async updateProductsInListing(id: number, params: any): Promise<any> {
    const result = await this.base.post(`${this.resource}/${id}/product-listing/update-products-in-listing`, params);

    return result;
  }

  async updateConfigFilter(id: number, params: any): Promise<any> {
    const result = await this.base.post(`${this.resource}/${id}/product-listing/update-config-filter`, params);

    return result;
  }

  async create(marketing: any): Promise<Marketing> {
    const { data } = await this.base.post(`${this.resource}`, marketing);
    return data;
  }

  async update(id: number, marketing: Partial<Marketing>): Promise<any> {
    const { data } = await this.base.patch(`${this.resource}/${id}`, marketing);
    return data;
  }

  async delete(id: number): Promise<void> {
    return this.base.delete(`${this.resource}/${id}`);
  }

  async pin(marketing_id: number, product_id: number): Promise<any> {
    const result = await this.base.patch(`${this.resource}/${marketing_id}/pin/${product_id}`, undefined);
    return result
  }

  async unpin(marketing_id: number, product_id: number): Promise<any> {

    const result = await this.base.patch(`${this.resource}/${marketing_id}/unpin/${product_id}`, undefined);
    return result
  }
}
