/**
 * Reference: https://github.com/noahjohn9259/react-clear-cache/blob/master/src/index.tsx#L60
 */

export const emptyCacheStorage = async (reload = false): Promise<void> => {
  // eslint-disable-next-line no-console
  console.log('Emptying cache and reloading page');
  if ('caches' in window) {
    // Service worker cache should be cleared with caches.delete()
    const cacheKeys = await window.caches.keys();
    await Promise.all(
      cacheKeys.map(async (key: string) => {
        await window.caches.delete(key);
      }),
    );
  }

  if (reload) {
    window.location.replace(window.location.href);
  }
};
