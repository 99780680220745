import { ILogisticsDistributor, IMutableLogisticsDistributor } from 'common/interfaces/Logistics.interface';
import { ISearchable, ListResponse, ISearchParam } from '../../interfaces';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { IMutable } from '../../interfaces/IMutable.interface';

export class SwipeRxPtLogisticsDistributor
  implements ISearchable<ILogisticsDistributor>, IMutable<IMutableLogisticsDistributor> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.LOGISTICS_DISTRIBUTOR;
  }

  async createOrUpdate(data: IMutableLogisticsDistributor): Promise<any> {
    return this.base.post(`${this.resource}`, data);
  }

  async putByDistributor(distributor_id: number, data: Partial<IMutableLogisticsDistributor>): Promise<any> {
    return this.base.patch(`${this.resource}/organization/${distributor_id}`, data);
  }

  async search(params: ISearchParam, resourceOverride?: string): Promise<ListResponse<ILogisticsDistributor>> {
    return this.base.get(resourceOverride || this.resource, { ...params });
  }

  async add(path: string, distributor_ids: number[]): Promise<ILogisticsDistributor> {
    return this.base.post(path, { distributor_ids });
  }

  async delete(path: string): Promise<ILogisticsDistributor> {
    return this.base.delete(path);
  }

  async getOne(distributor_id: number): Promise<{ data: ILogisticsDistributor }> {
    return this.base.get(`${this.resource}`, { ids: `${distributor_id}`, include: 'logisticsArea' });
  }
}
