import { SwipeRxPt, SwipeRxPtV3Resources } from '../..';
import { PaginationParams } from '../../interfaces';

import { IDepositTransaction, IDepositTransactionList } from './interface';

export class SwipeRxPtDepositTransaction {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.DEPOSIT_TRANSACTION;
  }

  async list(params: PaginationParams & Partial<IDepositTransaction>): Promise<IDepositTransactionList> {
    return this.base.get(this.resource, params);
  }

  async fetchLatest(depositRequestId: number): Promise<IDepositTransaction | null> {
    const { data } = await this.base.get(`${this.resource}/latest/${depositRequestId}`);
    return data;
  }
}
