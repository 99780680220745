import React from 'reactn';

import { Button, Dialog, FieldWrapper } from 'common/components';
import { pluralize } from 'common/utils';
import { ColorType } from 'types';

import { useOrderItemReassignForm } from './use-order-item-reassign-form';
import { fieldOptions } from './order-item-reassign-form.config';
import { OrderItemReassignReviewModal } from './order-item-reassign-review-modal.component';

const formId = 'order-reassign-form';

export const OrderItemReassignModal: React.FC = () => {
  const {
    form,
    orderItemReassign: { orderItems, availableDistributors, selectedDistributorId },
    resetOptions,
    selectedDistributor,
    setSelectedDistributor,
  } = useOrderItemReassignForm();

  const renderContent = (): JSX.Element => (
    <>
      <form id={formId} onSubmit={form.handleSubmit} style={{ height: '20vh' }}>
        {fieldOptions.map(({ name, component: Field, label }) => {
          const handleChange = (option: {
            value: string;
            label: string;
          }): void => {
            form.setFieldValue(name, option.value);
            setSelectedDistributor(option);
          };

          return (
            <FieldWrapper
              key={name}
              touched={form.touched[name]}
              error={form.errors[name]}
              label={label}
            >
              <Field
                name={name}
                value={selectedDistributor}
                onChange={handleChange}
                options={availableDistributors}
                onBlur={form.handleBlur}
              />
            </FieldWrapper>
          );
        })}
      </form>
    </>
  );
  const renderActions = (): JSX.Element => (
    <Button
      color={ColorType.PRIMARY}
      disabled={!form.dirty || form.isSubmitting}
      loading={form.isSubmitting}
      form={formId}
      type="submit"
    >
      Submit
    </Button>
  );

  const numberOfSelectedItems = orderItems.length;

  return (
    <>
      <Dialog
        open={!!numberOfSelectedItems && !selectedDistributorId}
        primary
        title={pluralize('Reassign Item', numberOfSelectedItems)}
        onClose={resetOptions}
        renderActions={renderActions}
        renderContent={renderContent}
      />
      <OrderItemReassignReviewModal />
    </>
  );
};
