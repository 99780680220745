import { TransactionBulkActionItemMapByStatus } from 'types';

import {
  UserRoles,
  TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
  TRANSACTION_LOGISTIC_DELIVERY_ROLES,
  TRANSACTION_MEMBER_ROLES,
} from './roles.constants';
import { TRANSACTION_ACTIONS } from './transaction-actions.constant';

export const TRANSACTION_BULK_ACTION_ITEM_MAP_BY_STATUS: TransactionBulkActionItemMapByStatus = {
  all: [
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
  ],
  accepted: [
    {
      actionItem: TRANSACTION_ACTIONS.SEND_TO_DISPATCHER,
      allowedRoles: [
        UserRoles.VENDOR_SPECIALIST,
        UserRoles.FULFILLMENT_ADMIN,
        UserRoles.SENIOR_VENDOR_SPECIALIST,
        UserRoles.DISTRIBUTOR_STAFF,
        UserRoles.DISPATCHER,
      ],
    },
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT,
      allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT_TO_DELIVERY_PARTNER,
      allowedRoles: TRANSACTION_LOGISTIC_DELIVERY_ROLES,
    },
    {
      actionItem: TRANSACTION_ACTIONS.REPRINT,
      allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.PRINT_DELIVERY_PROOF,
      allowedRoles: [...TRANSACTION_MEMBER_ROLES, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.PRINT_DELIVERY_DOCS,
      allowedRoles: [...TRANSACTION_MEMBER_ROLES, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.PRINT_INVOICE_DOCS,
    },
    {
      actionItem: TRANSACTION_ACTIONS.UPDATE_INVOICE_DATES,
    },
    {
      actionItem: TRANSACTION_ACTIONS.REQUEUE_FOR_SHIPMENT,
      allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.MANUAL_CREATE_AWB,
      allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.MERGE_SHIPMENT,
      allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.DELETE_SHIPMENT,
      allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.DELAY,
    },
    undefined,
    {
      actionItem: TRANSACTION_ACTIONS.ROLLBACK,
      allowedRoles: [UserRoles.TRANSACTION_MANAGER, UserRoles.DISTRIBUTOR_STAFF],
    },
    {
      actionItem: TRANSACTION_ACTIONS.CANCEL,
      allowedRoles: [UserRoles.TRANSACTION_MANAGER],
    },
  ],
  returned: [
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
  ],
  delayed: [
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
  ],
  cancelled: [
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
    {
      actionItem: TRANSACTION_ACTIONS.RESTORE,
      allowedRoles: [UserRoles.TRANSACTION_MANAGER],
    },
  ],
  delivered: [
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT,
      allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
    },
  ],
  dispatched: [
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT,
      allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.MARK_AS_UNDELIVERED,
      allowedRoles: [
        UserRoles.TRANSACTION_MANAGER,
        UserRoles.VENDOR_SPECIALIST,
        UserRoles.FULFILLMENT_ADMIN,
        UserRoles.SENIOR_VENDOR_SPECIALIST,
        UserRoles.DISPATCHER,
      ],
    },
    {
      actionItem: TRANSACTION_ACTIONS.DELIVER,
      allowedRoles: [
        UserRoles.TRANSACTION_MANAGER,
        UserRoles.VENDOR_SPECIALIST,
        UserRoles.FULFILLMENT_ADMIN,
        UserRoles.SENIOR_VENDOR_SPECIALIST,
        UserRoles.DISPATCHER,
      ],
    },
    {
      actionItem: TRANSACTION_ACTIONS.REPRINT_DELIVERY_PROOF,
      allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.REPRINT_DELIVERY_DOCS,
      allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.REPRINT_INVOICE_DOCS,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
    {
      actionItem: TRANSACTION_ACTIONS.REQUEUE_FOR_SHIPMENT,
      allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.UPDATE_INVOICE_DATES,
    },
    undefined,
    {
      actionItem: TRANSACTION_ACTIONS.ROLLBACK,
      allowedRoles: [UserRoles.TRANSACTION_MANAGER, UserRoles.DISTRIBUTOR_STAFF],
    },
    {
      actionItem: TRANSACTION_ACTIONS.CANCEL,
      allowedRoles: [UserRoles.TRANSACTION_MANAGER],
    },
  ],
  fulfilled: [
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT,
    },
  ],
  pendingAll: [
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT_FULL_ORDER_DETAILS,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
  ],
  pending: [
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT_FULL_ORDER_DETAILS,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
    {
      actionItem: TRANSACTION_ACTIONS.PRINT,
      allowedRoles: [
        UserRoles.VENDOR_SPECIALIST,
        UserRoles.FULFILLMENT_ADMIN,
        UserRoles.SENIOR_VENDOR_SPECIALIST,
        UserRoles.DISTRIBUTOR_STAFF,
        UserRoles.ADMIN,
      ],
    },
    {
      actionItem: TRANSACTION_ACTIONS.MERGE,
      allowedRoles: [
        UserRoles.SENIOR_VENDOR_SPECIALIST,
        UserRoles.TRANSACTION_MANAGER,
        UserRoles.DISTRIBUTOR_STAFF,
        UserRoles.ADMIN,
      ],
    },
    {
      actionItem: TRANSACTION_ACTIONS.REASSIGN,
      allowedRoles: [UserRoles.TRANSACTION_MANAGER, UserRoles.ADMIN],
    },
    {
      actionItem: TRANSACTION_ACTIONS.DELAY,
    },
    undefined,
    {
      actionItem: TRANSACTION_ACTIONS.CANCEL,
      allowedRoles: [UserRoles.TRANSACTION_MANAGER, UserRoles.ADMIN],
    },
  ],
  processing: [
    {
      actionItem: TRANSACTION_ACTIONS.ACCEPT,
      allowedRoles: [
        UserRoles.VENDOR_SPECIALIST,
        UserRoles.FULFILLMENT_ADMIN,
        UserRoles.SENIOR_VENDOR_SPECIALIST,
        UserRoles.DISTRIBUTOR_STAFF,
      ],
    },
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
    {
      actionItem: TRANSACTION_ACTIONS.REPRINT,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
    {
      actionItem: TRANSACTION_ACTIONS.MERGE,
      allowedRoles: [UserRoles.SENIOR_VENDOR_SPECIALIST, UserRoles.TRANSACTION_MANAGER, UserRoles.DISTRIBUTOR_STAFF],
    },
    {
      actionItem: TRANSACTION_ACTIONS.REASSIGN,
      allowedRoles: [UserRoles.TRANSACTION_MANAGER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.REQUEUE_FOR_SHIPMENT,
      allowedRoles: [...TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS, UserRoles.DISPATCHER],
    },
    {
      actionItem: TRANSACTION_ACTIONS.DELAY,
    },
    undefined,
    {
      actionItem: TRANSACTION_ACTIONS.CANCEL,
      allowedRoles: [UserRoles.TRANSACTION_MANAGER],
    },
  ],
  precursor: [
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
    {
      actionItem: TRANSACTION_ACTIONS.EXPORT_FULL_ORDER_DETAILS,
      allowedRoles: TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS,
    },
  ],
};
