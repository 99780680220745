/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDispatch, useGlobal, useEffect } from 'reactn';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useOrderModalReason } from 'pages/transactions/hooks/use-order-modal-reason.hook';

export interface OrderDelayFormState {
  reason: string;
}

const formState: OrderDelayFormState = {
  reason: '',
};

export const useOrderDelayForm = () => {
  const [{ delay }] = useGlobal('transactions');
  const delayOrders = useDispatch('delayOrders');
  const setOrderIdsForDelay = useDispatch('setOrderIdsForDelay');

  const { selectedReason, setSelectedReason, clearReason } = useOrderModalReason();

  const clearOrderIds = (): void => {
    setOrderIdsForDelay({ orderIds: [] });
  };

  const form = useFormik({
    initialValues: {
      ...formState,
    },
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason field is required.'),
    }),
    onSubmit: ({ reason }, { setSubmitting }) => {
      delayOrders({ reason });
      setSubmitting(false);
      clearOrderIds();
    },
  });

  useEffect(() => {
    form.setValues({ ...formState });
    Object.keys(formState).map((key: string): void => {
      form.setTouched({ [key]: false });
    });

    return () => clearReason();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay.orderIds]);

  return { form, delay, clearOrderIds, setSelectedReason, selectedReason };
};
