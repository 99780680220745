export enum Action {
  SyncCRM = 'Sync CRM',
  EditCRM = 'Edit CRM',
  UploadCRM = 'Upload CRM',
  // SPT-20453: Disable the get pharmacy link and change password link (20240222)
  // SPT-20574: Enable pharmacy link access for specific user list (20240306)
  GetPharmacyLink = 'Get Pharmacy Link',
  ForgotPassLink = 'Forgot Password Link',
  EditPharmacyDetail = 'Edit Pharmacy Detail',
  MessagePharmacy = 'Message Pharmacy',
  AddPayment = 'Add Payment',
  ResolveDoku = 'Resolve Doku',
}
