export enum Colors {
  transparent = 'rgba(0,0,0,0)',
  textLight2 = '#999999',
  textLight = 'rgba(0,0,0,0.3)',
  textNormal = '#333333',
  lightGray = '#d9d9d9',
  bgLightGray = '#f2f2f2',
  white = '#ffffff',
  primary = '#40AE96',
  darkenPrimary = '#206e5d',
  primaryBlue = '#1633e8', // give better name
  red = '#ff0000',
  warning= 'rgb(242, 153, 74)',
}
