export enum DistributorProductFlag {
  PREKURSOR = 'P',
  COLDCHAIN = 'C',
}

export enum RequirementTypeEnum {
  PREKURSOR = 'prekursor_document',
  COLDCHAIN = 'coldchain_document',
}

export enum DocumentTypeEnum {
  ELECTRONIC = 'Electronic SP',
  MANUAL = 'Manual',
}

