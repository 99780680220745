import { useState } from 'react';
import { useEffect } from 'reactn';

interface IUseModalProps {
  isOpen?: boolean;
  onChangeState?: (isOpen: boolean)=> void;
}

export interface UseModal {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const useModal = (props: IUseModalProps): UseModal => {
  const [isOpen, setIsOpen] = useState<boolean>(props.isOpen || false);

  const openModal = (): void => {
    setIsOpen(true);
  };

  const closeModal = (): void => {
    setIsOpen(false);
  };

  useEffect(()=>{
    const { onChangeState } = props;
    if(onChangeState) {
      onChangeState(isOpen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return {
    isOpen,

    openModal,
    closeModal,
  };
};
