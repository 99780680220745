import moment from 'moment-timezone';

export const getMarketIdTimezone = (market: string): string => {
  let timezone: string;
  switch (market) {
    case 'id':
      timezone = 'Asia/Jakarta';
      break;
    case 'th':
      timezone = 'Asia/Bangkok';
      break;
    default:
      timezone = moment.tz.guess();
  }
  return timezone;
};
