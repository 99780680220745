import { FieldInput } from 'common/components';

import { UpdateTrackingNumberFormState } from './use-update-tracking-number-form';
import { OrderDeliveryPartnerAutoComplete } from '../order-export-to-delivery-partner-modal/order-delivery-partner-autocomplete.component';

type UpdateTrackingNumberFormStateKeys = keyof UpdateTrackingNumberFormState;
export interface DeliveryPartnerOptions {
  value: number,
  label: string,
}

export interface FieldOptions {
  name: UpdateTrackingNumberFormStateKeys;
  component?: React.FC<any>;
  label?: string;
  placeholder?: string;
}

export interface FieldOptionsSelect {
  name: UpdateTrackingNumberFormStateKeys;
  component?: React.FC<any>;
  label?: string;
  placeholder?: string;
  options: any[];
}

interface DeliveryStatusOptions {
  value: string | undefined;
  label: string;
}

export const fieldOptionsInput: FieldOptions[] = [
  {
    name: 'tracking_number',
    label: 'Tracking number',
    component: FieldInput,
    placeholder: 'Enter tracking number',
  },
  {
    name: 'point_id',
    label: 'Point id',
    component: FieldInput,
    placeholder: 'Enter point id',
  }
];

export const fieldOptionsSelectDeliveryPartner: FieldOptions[] = [
  {
    name: 'logistics_delivery_partner_id',
    label: 'Delivery partner',
    component: OrderDeliveryPartnerAutoComplete,
  },
];



