/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useGlobal, useState, useEffect } from 'reactn';
import { useRouteMatch } from 'react-router-dom';

import { UserRoles } from 'common/constants';
import { IModuleConfig } from 'common/reducers';

import { updateConfiguredModulesByFeatureConfigs } from 'common/reducers/market/config.utils';
import { useGetAuthenticatedUser } from 'hooks';
import { getMarketPath, IMarketParams } from '../../../utils/get-router-path';
import { sideBarMenuConfig } from '../sidebar-menu.config';

import { SidebarMenuState } from './sidebar-menu.reducer';
import { SidebarMenuConfigOptions } from '../sidebar-menu.interface';

export const useSideBarMenuReducer = () => {
  const { params } = useRouteMatch<IMarketParams>();
  const [{ market, paymentMethod }] = useGlobal('config');
  const { role } = useGetAuthenticatedUser();

  const prefix = `${getMarketPath(params.marketId)}`;

  let configuredModules: IModuleConfig = {
    ...market.modules,
    voucher: {
      'view-module': (market.modules.voucher['view-module'] as Function)(paymentMethod.voucher.enabled),
    },
  };
  configuredModules = updateConfiguredModulesByFeatureConfigs(configuredModules, market.featureConfigs);

  const [paths, setPaths] = useState<SidebarMenuConfigOptions[]>([]);

  useEffect(() => {
    if (role) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      renderSidebarMenuByUserRole(role);
    } else {
      const sidebarMenuPaths: SidebarMenuState = {
        paths: sideBarMenuConfig(prefix, configuredModules, market.activations),
      };

      setPaths(sidebarMenuPaths.paths);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market, role]);

  const renderSidebarMenuByUserRole = (userRoles: UserRoles) => {
    const sidebarMenuPaths: SidebarMenuState = {
      paths: sideBarMenuConfig(prefix, configuredModules, market.activations),
    };

    const currentPaths = sidebarMenuPaths.paths;

    switch (userRoles) {
      case UserRoles.ADMIN:
      case UserRoles.SUPER_ADMIN:
        setPaths(currentPaths);
        break;
      default:
        setPaths(currentPaths.filter((path) => path.roleAccess && path.roleAccess.includes(userRoles)));
        break;
    }
  };

  return {
    paths: { paths },
    renderSidebarMenuByUserRole,
  };
};
