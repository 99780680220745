import React from 'react';
import { DeleteIcon, ZoomInIcon } from 'common/components/Icon';
import { Grid } from '@material-ui/core';

interface Props {
  active: boolean;
  onMagnify: () => void;
  onRemove?: () => void;
}

export const ImageControls: React.FC<Props> = ({ active, onRemove, onMagnify }) => (
  <Grid container direction="column" alignContent="flex-end" alignItems="flex-end">
    {active ? (
      <Grid container direction="row" alignItems="flex-end" justify="flex-end">
        {onRemove && (
          <DeleteIcon
            style={{
              paddingRight: 20,
              paddingBottom: 20,
              cursor: 'pointer',
            }}
            onClick={onRemove}
          />
        )}
        <ZoomInIcon
          style={{
            paddingRight: 20,
            paddingBottom: 20,
            cursor: 'pointer',
          }}
          onClick={onMagnify}
        />
      </Grid>
    ) : null}
  </Grid>
);
