import styled from 'styled-components';

interface ListWrapperProps {
  hideScroll: boolean;
}

const Description = styled.div`
  color: #717786;
  font-size: 14px;
  line-height: 20px;
`;

const ConfirmationMessage = styled.div`
  text-align: center;
`;

const getListWrapperOverflowY = ({
  hideScroll,
}: ListWrapperProps): string | undefined => (hideScroll ? undefined : 'scroll');

const ListWrapper = styled.ul<ListWrapperProps>`
  list-style-type: none;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: ${getListWrapperOverflowY};
  margin-bottom: 0;
`;

const ListItem = styled.li`
  background: #fbfbfb;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.57);
  padding: 16px;
`;

const ListHeader = styled.span`
  color: #717786;
  display: block;
  font-size: 12px;
`;

const ListBody = styled.span`
  color: #007f60;
  font-size: 16px;
  font-weight: 600;
`;

export const S = {
  Description,
  ConfirmationMessage,
  ListWrapper,
  ListItem,
  ListHeader,
  ListBody,
};
