/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'reactn';

export interface OrderFulfillmentFormState {
  invoice: string;
  ttf: string;
}

const formState: OrderFulfillmentFormState = {
  invoice: '',
  ttf: '',
};

interface FulfillmentHooksConfig {
  isUpdate?: boolean;
}

export const useOrderFulfillmentForm = (config : FulfillmentHooksConfig | null) => {

  const setOrderIdsForFulfillment = useDispatch('setOrderIdsForFulfillment');
  const fulfillOrder = useDispatch('fulfillOrder');
  const updateDocuments = useDispatch('updateDocuments');
  const isUpdate = config && config.isUpdate;

  const form = useFormik({
    initialValues: {
      ...formState,
    },
    validationSchema: Yup.object().shape({
      invoice: Yup.string().when('ttf', {
        is: (value) => {
          return (isUpdate && value === undefined) || (!isUpdate)
        },
        then: Yup.string().required('Please upload invoice document.'),
        otherwise: Yup.string()
      }),
      ttf: Yup.string().when('invoice', {
        is: (value) => (isUpdate && value === undefined) || (!isUpdate),
        then: Yup.string().required('Please upload ttf document.'),
        otherwise: Yup.string()
      }),
    },[ ['ttf', 'invoice'] ]),
    onSubmit: ({ invoice, ttf }, { setSubmitting, resetForm }) => {
      if (isUpdate) {
        updateDocuments({ invoice, ttf });
      } else {
        fulfillOrder({invoice, ttf});
      }
      setOrderIdsForFulfillment({ orderIds: [] });
      setSubmitting(false);
      resetForm();
    },
  });

  return form;
};
