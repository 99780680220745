import React from 'react';
import { Typography } from '@material-ui/core';

import { ConditionalRender } from 'common/components';

import { Colors } from '../../themes/colors';

import { S } from './PageWrapper.styles';

interface Props {
  name?: string | React.ReactNode;
  title?: string | JSX.Element;
  transparent?: boolean;
  style?: any;
}

export const PageWrapper: React.FC<Props> = ({ children, name, title, transparent = true, style }) => (
  <S.Wrapper
    container
    transparent={transparent ? 1 : 0}
    direction="column"
    justify="flex-start"
    alignItems="stretch"
    style={style}
  >
    <ConditionalRender condition={!!name}>
      {name ? (
        typeof name === 'string' ? (
          <Typography
            data-testid={`label-${name}`}
            style={{
              color: Colors.textLight,
              fontSize: 14,
              fontWeight: 800,
              textTransform: 'uppercase',
            }}
          >
            {name}
          </Typography>
        ) : (
          <>{name}</>
        )
      ) : null}
    </ConditionalRender>
    <ConditionalRender condition={!!title}>
      {React.isValidElement(title) ? title : <S.Title>{title}</S.Title>}
    </ConditionalRender>
    {children}
  </S.Wrapper>
);
