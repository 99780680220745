import {
  Organization as IOrganization,
  OrganizationParams,
  Pharmacy as IPharmacy,
  IPharmacyMembership,
  PharmacyRestriction,
} from './interfaces';
import { VirtualAccount } from '../../../../../../reducers/virtual-accounts';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { ISearchable, ListResponse } from '../../interfaces';
import { CSVUploadResult } from 'common/components/CsvUpload';

export interface UpdateOrganizationPaymentMethodRequest {
  new_payment_method: string;
  options: {
    initial_credit_limit: number;
  };
}
export class SwipeRxPtOrganizations implements ISearchable<IOrganization> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.ORGANIZATIONS;
  }

  async search(params: OrganizationParams): Promise<ListResponse<IOrganization>> {
    return this.base.get(this.resource, { ...params });
  }

  async retrieve(id: number, params?: Omit<OrganizationParams, 'page' | 'page_size'>): Promise<IOrganization> {
    const { data } = await this.base.get(`${this.resource}/${id}`, params);

    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  async fetchVirtualAccountsById(id: number): Promise<VirtualAccount[]> {
    return this.base.get(`${this.resource}/${id}/virtual-accounts`);
  }

  /**
   * @deprecated
   */
  async activateModalku(orgId: number): Promise<void> {
    const payload = await this.base.post(`${SwipeRxPtV3Resources.CREDIT}/queue-modalku-activation/${orgId}`, null);

    return payload;
  }

  async fetchCreditHistory(data: any): Promise<any> {
    const payload = await this.base.get(`${SwipeRxPtV3Resources.CREDIT}`, { ...data });

    return payload;
  }

  async createPharmacy(data: IPharmacy): Promise<{ data: IPharmacy; status: number }> {
    return this.base.post(`/pharmacy/create`, data);
  }

  async updatePharmacy(pharmacyId: number, data: IPharmacy): Promise<void> {
    return this.base.patch(`/${this.resource}/${pharmacyId}`, data);
  }

  async updateRestriction(pharmacyId: number, data: PharmacyRestriction): Promise<void> {
    return this.base.patch(`/${this.resource}/${pharmacyId}/restriction`, data);
  }

  async enrollOrgMembership(pharmacyId: number, data: IPharmacyMembership): Promise<void> {
    return this.base.patch(`/${this.resource}/${pharmacyId}/account-status`, data);
  }

  async updatePaymentMethod(organizationId: number, options: UpdateOrganizationPaymentMethodRequest): Promise<void> {
    const response = await this.base.patch(`/payment-method/convert`, { organization_id: organizationId, ...options });

    return response;
  }

  async uploadCsv(
    file: File,
  ): Promise<{
    data: CSVUploadResult;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );
    return this.base.post(`/pharmacy/bulk-create`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async uploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`/pharmacy/upload/history`, {
      page,
      page_size,
    });
  }
}
