import { ILogisticAreaModel, ICreateLogisticAreaInput } from 'common/models/logistic-area.model';
import { ILogisticsAreaLogisticsDeliveryPartnerModel } from 'common/models/logistics-area-logistics-delivery-partner.model';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../..';
import { ISearchable, ListResponse, ISearchParam } from '../../interfaces';
import { CSVUploadResult } from 'common/components/CsvUpload';

export class SwipeRxPtLogisticsArea implements ISearchable<ILogisticAreaModel> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.LOGISTICS_AREA;
  }

  async create(data: ICreateLogisticAreaInput): Promise<any> {
    return this.base.post(`${this.resource}`, data);
  }

  async update(id: number, data: ICreateLogisticAreaInput): Promise<any> {
    return this.base.patch(`${this.resource}/${id}`, data);
  }

  async search(params: ISearchParam): Promise<ListResponse<ILogisticAreaModel>> {
    return this.base.get(this.resource, { ...params });
  }

  async get(path: string): Promise<{ data: ILogisticAreaModel }> {
    return this.base.get(path);
  }

  async getAll(name?: string): Promise<ILogisticAreaModel[]> {
    const params: Record<string, string> = {};

    if (name) params.name = name;
    
    const { data } = await this.base.get(this.resource, params);

    return data;
  }

  async patch(path: string, data: Partial<ICreateLogisticAreaInput>): Promise<any> {
    return this.base.patch(path, data);
  }

  async getDeliveryPartnerById(areaId: number): Promise<ILogisticsAreaLogisticsDeliveryPartnerModel[]> {
    const { data } = await this.base.get(`${this.resource}/${areaId}/delivery-partner`);
    return data;
  }
  
  async uploadCsv(
    file: File,
  ): Promise<{
    data: CSVUploadResult;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );
    return this.base.post(`${this.resource}/3pl/upload`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async uploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`${this.resource}/3pl/upload/history`, {
      page,
      page_size,
    });
  }
}
