import React, { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

import { AsyncAutoComplete } from 'common/components/async-auto-complete';
import { styles } from 'common/components/async-auto-complete/organization-auto-complete/organization-auto-complete.styles';

import { useOrderGetDeliveryPartner } from './use-order-get-delivery-partner';

export type OrderDeliveryPartnerAutoCompleteOption = {
  value: string;
  label: string;
};

export type OrderDeliveryPartnerAutoCompleteProps = {
  selectInput: (selected: OrderDeliveryPartnerAutoCompleteOption) => void;
  defaultValue?: any;
};

export const OrderDeliveryPartnerAutoComplete: React.FC<OrderDeliveryPartnerAutoCompleteProps> = ({
  selectInput,
  defaultValue,
  ...props
}) => {
  const { options, onSearch, onSearchResultSelected } = useOrderGetDeliveryPartner();

  const debounceSearch = useCallback(debounce(onSearch, 400, { leading: true }), []);

  const handleChange = (option: OrderDeliveryPartnerAutoCompleteOption): void => {
    if (option) {
      selectInput(option);
      onSearchResultSelected(option.value);
    }
  };

  useEffect(() => {
    onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AsyncAutoComplete
      styles={styles.basic}
      placeholder="Type delivery partner"
      datasource={options}
      fetchData={debounceSearch}
      handleChange={handleChange}
      isClearable
      defaultValue={defaultValue}
      {...props}
    />
  );
};
