/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState, useEffect } from 'reactn';
import { useOrderModalReason } from 'pages/transactions/hooks/use-order-modal-reason.hook';
import { PartialOrderReturnFormState } from './interfaces';

export const usePartialOrderReturnForm = (order: {
  orderId: number;
  orderDetails: any[];
  orderItems: { [key: string]: any };
}) => {
  const { orderItems, orderId } = order;

  const [itemsToReturn, setItemstoReturn] = useState<PartialOrderReturnFormState>({});

  const { selectedReason, setSelectedReason, clearReason } = useOrderModalReason();

  useEffect(() => {
    const formState = Object.keys(orderItems).reduce((itemMap, id) => {
      itemMap[id] = {
        selected: false,
        quantity: 0,
        reason: '',
      };

      return itemMap;
    }, {} as PartialOrderReturnFormState);
    setItemstoReturn(formState);

    return () => clearReason();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const handleCheckBoxChange = (id: number) => {
    setItemstoReturn({
      ...itemsToReturn,
      [id]: {
        ...itemsToReturn[id],
        selected: !itemsToReturn[id].selected,
      },
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const eventCount = Number(e.target.value);
    if (eventCount < 0) {
      return;
    }
    if (eventCount > orderItems[id].quantity) {
      return;
    }
    setItemstoReturn({
      ...itemsToReturn,
      [id]: {
        ...itemsToReturn[id],
        quantity: eventCount,
      },
    });
  };

  const handleReasonChange = (reason: string = '', id: number) => {
    setItemstoReturn({
      ...itemsToReturn,
      [id]: {
        ...itemsToReturn[id],
        reason,
      },
    });
  };

  return {
    itemsToReturn,
    handleInputChange,
    handleCheckBoxChange,
    handleReasonChange,
    setSelectedReason,
    selectedReason,
  };
};
