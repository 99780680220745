import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { PtApiConfig } from './ptApi.config';

export type Query = Record<string, string | number | undefined>;
export type QsStringifyParam = { [key: string]: any };

export class PtAPIv2 {
  private client: AxiosInstance;

  private static _dashboard: PtAPIv2;

  private static _distributor: PtAPIv2;

  private static _pharmacy: PtAPIv2;

  private static _admin: PtAPIv2;

  private static _base: PtAPIv2;

  constructor(domain?: string) {
    this.client = PtApiConfig.instance.create(domain, undefined); // v2 - no v2 prefix
  }

  public static get base(): PtAPIv2 {
    if (!PtAPIv2._base) {
      PtAPIv2._base = new PtAPIv2();
    }
    return PtAPIv2._base;
  }

  public static get admin(): PtAPIv2 {
    if (!PtAPIv2._admin) {
      PtAPIv2._admin = new PtAPIv2('admin');
    }
    return PtAPIv2._admin;
  }

  public static get dashboard(): PtAPIv2 {
    if (!PtAPIv2._dashboard) {
      PtAPIv2._dashboard = new PtAPIv2('dashboard');
    }
    return PtAPIv2._dashboard;
  }

  public static get distributor(): PtAPIv2 {
    if (!PtAPIv2._distributor) {
      PtAPIv2._distributor = new PtAPIv2('distributor');
    }
    return PtAPIv2._distributor;
  }

  public static get pharmacy(): PtAPIv2 {
    if (!PtAPIv2._pharmacy) {
      PtAPIv2._pharmacy = new PtAPIv2('pharmacy');
    }
    return PtAPIv2._pharmacy;
  }

  private static throwError(e: any): void {
    if (e.response) {
      const { data } = e.response;

      throw new Error(data.error || data.message);
    }

    throw new Error(e.message);
  }

  public async get<T = any>(
    path: string,
    query?: Query | (QsStringifyParam & { params?: never }),
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>['data']> {
    try {
      const { client } = this;

      const { data } = await client.get<T>(path, {
        ...config,
        params: query,
      });

      return data;
    } catch (e) {
      console.error('@APIService::get', e.message);
      throw PtAPIv2.throwError(e);
    }
  }

  public async post<T = any>(
    path: string,
    payload: any,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<T>['data']> {
    try {
      const { client } = this;

      const { data } = await client.post<T>(path, payload, config);

      return data;
    } catch (e) {
      console.error('@APIService::post', e.message);
      throw PtAPIv2.throwError(e);
    }
  }

  public async patch<T = any>(
    path: string,
    payload: any,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<T>['data']> {
    try {
      const { data } = await this.client.patch(path, payload, config);

      return data;
    } catch (e) {
      console.error('@APIService::patch', e.message);
      throw PtAPIv2.throwError(e);
    }
  }

  public async delete<T = any>(
    path: string,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<T>['data']> {
    try {
      const { data } = await this.client.delete(path, config);

      return data;
    } catch (e) {
      throw PtAPIv2.throwError(e);
    }
  }
}
