export default {
  state: {
    isLoading: false,
  },
  reducers: {
    setLoading(state: any, payload: boolean) {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
  effects: (dispatch: any) => ({
    showLoading(payload: boolean) {
      dispatch.uiStore.setLoading(payload);
    },
  }),
};
