import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Route, Link } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';

const styles = (theme) => ({
  grid: {
    backgroundColor: theme.palette.primary.secondary,
    height: '100vh',
  },
});

const ErrorContainer = ({ classes }) => {
  console.error('This is an error page');
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      classes={{ root: classes.grid }}
    >
      <Grid item>
        <Typography variant="h1">404</Typography>
        <Typography>
          Uh oh, looks like you stumbled on a page that doesn&lsquot exist yet.
        </Typography>
        <Typography>
          Click <Link to="/">here</Link> to get back to the dashboard.
        </Typography>
        <Route exact path="/" component={Dashboard} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ErrorContainer);
