import { OrderDeliverFormState } from './use-order-deliver-form';

type OrderDeliverFormKeys = keyof OrderDeliverFormState;

interface FieldOptions {
  name: OrderDeliverFormKeys;
  type: 'select' | 'date';
  label?: string;
}

export const fieldOptions: FieldOptions[] = [
  {
    name: 'date',
    type: 'date',
    label: 'Delivery Date',
  },
];
