import { DataTableParams } from '../interfaces';

import { getPage } from './get-page.util';

export const assemblePaginationParams = (
  oldParams: DataTableParams = {},
  newParams: DataTableParams = {},
): DataTableParams => {
  const {
    page: currentPage,
    page_size: currentPageSize,
    order_by: currentOrderBy,
    sort_by: currentSortBy,
    page_count: currentPageCount,
    total_count: currentTotalCount,
    ...restOldParams
  } = oldParams;

  const page = getPage({
    newPage: newParams.page as number,
    newPageSize: newParams.page_size as number,
    oldPage: currentPage as number,
    oldPageCount: currentPageCount as number,
    oldTotalCount: currentTotalCount as number,
  });

  const params = {
    page_size: currentPageSize,
    order_by: currentOrderBy,
    sort_by: currentSortBy,
    ...restOldParams,
    ...newParams,
    page,
  };

  return params;
};
