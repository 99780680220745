const PRINT_ORDER = 'print-order';
const PRINT_DELIVERY_DOCS = 'print-delivery-docs';
const PRINT_INVOICE_DOCS = 'print-invoice-docs';
const PRINT_DELIVERY_PROOF = 'print-delivery-proof';

export const TemplateOptions = {
  PRINT_ORDER,
  PRINT_DELIVERY_DOCS,
  PRINT_INVOICE_DOCS,
  PRINT_DELIVERY_PROOF,
};
