import styled from 'styled-components';
import MuiSelect from '@material-ui/core/Select';

const Select = styled(MuiSelect)`
  && {
    width: 100%;
    min-width: ${(props: any) => props.minWidth || '300px'};
    font-size: 14px;
  }
`;

export const S = { Select };
