import { SwipeRxPt, SwipeRxPtV3Resources } from '../..';
import { SuccessResponse } from '../..';

import { IDepositRequest } from './interfaces';

export class SwipeRxPtDepositRequest {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.DEPOSIT_REQUEST;
  }

  async fetchDepositRequest(pharmacyId: number): Promise<IDepositRequest | null> {
    const { data } = await this.base.get<SuccessResponse<IDepositRequest | null>>(
      `${this.resource}/active?pharmacy_id=${pharmacyId}`,
    );

    return data;
  }
}
