import React from 'reactn';

import { Box, LinearProgress } from '@material-ui/core';
import { S } from './loading.styles';

export const LoadingComponent: React.FC = () => {
  return (
    <S.Container>
      <img src="/new-logo.svg" alt="Loading" style={{ marginBottom: '78px' }} />
      <Box style={{ width: '403px' }}>
        <LinearProgress value={70} />
        <S.LoadingText>Loading ...</S.LoadingText>
      </Box>
    </S.Container>
  );
};
