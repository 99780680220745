import styled from 'styled-components';
import { KeyboardTimePicker } from '@material-ui/pickers';

const TimePicker = styled(KeyboardTimePicker)`
  && {
    width: 100%;

    & .MuiFormHelperText-root: {
      margin: 0;
      padding: 10px 0;
    }
  }
`;

export const S = { TimePicker };
