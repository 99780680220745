import React from 'react';
import styled from 'styled-components';

import Select, { SelectProps } from '@material-ui/core/Select';

import { wrapperStyle } from '../Pagination.styles';

interface WrapperProps extends SelectProps {
  disabled?: boolean;
}

interface ArrowProps {
  disabled?: boolean;
}

const getWrapperPointerEvents = ({
  disabled,
}: WrapperProps): string | undefined => (disabled ? 'none' : undefined);

const getArrowColor = ({ disabled }: ArrowProps): string =>
  disabled ? '#8D959C' : '#000';

const Wrapper = styled(Select)`
  ${wrapperStyle}

  && {
    padding-left: 8px;

    div {
      background: #fff;
      pointer-events: ${getWrapperPointerEvents};
      padding-right: 40px;
    }

    svg {
      border-left: 2px solid #fafafa;
      color: ${getArrowColor};
      font-size: 1.3rem;
      margin-top: 2px;
      margin-right: 2px;
    }

    &::before,
    &::after,
    &:hover:before,
    &:active:before {
      border: 0;
    }
  }
`;

export const S = {
  Wrapper: Wrapper as React.ComponentType<WrapperProps>,
};
