import React, { useEffect, useState } from 'react';
import { Grid, DialogContent, Dialog } from '@material-ui/core';

import { useModal } from 'hooks/useModal';
import ModalTitle from 'common/components/Modal/ModalHeader';
import { Image } from '@material-ui/icons';
import { Colors } from 'common/themes/colors';
import { ObjectFitProperty } from 'csstype';
import { ImageControls } from './image-controls.component';
import { ConditionalRender } from '../ConditionalRender';

interface Props {
  imageSource: string;
  handleRemove?: () => void;
  isActive?: boolean;
  getRootProps?: any;
  imageHeight?: string | number;
  imageWidth?: string | number;
  objectFitMode?: ObjectFitProperty;
}

export const ImagePreview: React.FC<Props> = ({
  getRootProps,
  imageSource,
  isActive = true,
  handleRemove,
  imageHeight = 260,
  imageWidth,
  objectFitMode = 'unset',
}) => {
  const [hasError, setHasError] = useState(false);

  const modal = useModal({});

  const handleError = (): void => setHasError(true);

  const errorImage = (
    <div style={{ color: Colors.lightGray as string, paddingBottom: '5%' }}>
      <Grid item xs={12}>
        <Image style={{ height: 260, margin: 'auto', fontSize: '20.3em' }} />
      </Grid>
      <Grid item xs={12}>
        Image Not Found
      </Grid>
    </div>
  );

  useEffect(() => {
    setHasError(false);
  }, [imageSource]);

  return (
    <>
      <Grid
        container
        direction="row"
        style={{
          height: '100%',
          width: '100%',
          border: '0.5px solid lightgray',
          borderRadius: '8px',
          display: 'flex',
        }}
      >
        <Grid
          container
          item
          xs={12}
          {...(getRootProps ? { ...getRootProps() } : {})}
          alignItems="center"
          justify="center"
          style={{ height: '90%', textAlign: 'center' }}
        >
          <ConditionalRender condition={!hasError}>
            <img
              src={imageSource}
              alt=""
              onError={handleError}
              style={{
                height: imageHeight,
                width: imageWidth,
                objectFit: objectFitMode,
                margin: 'auto',
              }}
            />
          </ConditionalRender>
          <ConditionalRender condition={hasError}> {errorImage} </ConditionalRender>
        </Grid>
        <Grid item xs={12} style={{ height: '10%' }}>
          <ConditionalRender condition={!hasError}>
            <ImageControls active={isActive} onRemove={handleRemove} onMagnify={modal.openModal} />
          </ConditionalRender>
        </Grid>
      </Grid>
      <Dialog open={modal.isOpen} onClose={modal.closeModal} scroll="body" disableBackdropClick={false}>
        <ModalTitle
          id="preview-image-dialog"
          title="PREVIEW IMAGE"
          onClose={modal.closeModal}
          isCloseDisabled={false}
        />

        <DialogContent>
          <img src={imageSource} alt="" style={{ height: 500, margin: 'auto' }} />
        </DialogContent>
      </Dialog>
    </>
  );
};
