import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectProps } from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { S } from './field-select.styles';

export interface SelectOptions {
  value: string;
  label: string;
}

interface Props extends SelectProps {
  variant?: 'standard' | 'outlined' | 'filled';
  items: SelectOptions[];
  label?: string;
  minWidth?: string | number;
}

export const FieldSelect: React.FC<Props> = ({ variant, items, label, ...props }) => (
  <FormControl variant={variant} style={{ width: '100%' }}>
    {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
    <S.Select {...props}>
      {items.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </S.Select>
  </FormControl>
);

FieldSelect.defaultProps = {
  variant: 'outlined',
};
