import { Meta } from 'types';
import distributorService from '../services/apis/v2/services/distributor.service';
import { OrganizationArea } from './organization-area.model';

export interface QueryParams {
  distributor_id: string;
  page_size: number;
  page: number;
  sort_by: string;
  order_by: string;
  query: string;
}

export interface DistributorProduct {
  [key: string]: any;
  id: number;
  name: string;
  manufacturer: string;
  molecule: string;
  package: string;
  promoted: number;
  selling_price: number;
  sku_code: string;
  display_photo: string | null;
  thumbnail_photo: string | null;
  created_at: Date;
  updated_at: Date;
  base_price: {
    available: number;
    discount_rate: number;
    distributor_product_id: number;
    pre_negotiated_price: number;
    selling_price: number;
  };
  DistributorProduct: {
    ProductTierPricing: any[];
    available: number;
    discount_rate: number;
    id: number;
    pre_negotiated_price: number;
    selling_price: number;
  }[];
}

export interface Distributor {
  id: number;
  payment_account_number?: string;
  name?: string;
  abbreviation?: string;
  legal_name?: string;
  universal_id?: number;
  type?: string;
  country?: string;
  region?: string;
  city?: string;
  address?: string;
  phone?: string;
  zipcode?: string;
  email?: string;
  notification_list?: string;
  rating?: string;
  minimum_invoice_amount?: number;
  status?: string;
  created_at?: Date;
  updated_at?: Date;
  default_virtual_account_id?: number;
  organization_area?: OrganizationArea[]
}

export interface DistributorStore {
  distributor: Distributor;
  products: DistributorProduct[];
  meta: Meta;
}

export type GetAllProducts = (x: QueryParams) => Promise<void>;
export type GetDistributor = (x: { id: number }) => Promise<void>;

export default {
  state: {
    distributors: {},
    products: [],
    meta: {
      count: 0,
      page: 0,
      limit: 0,
    },
  },
  reducers: {
    setDistributor(state: DistributorStore, payload: Distributor) {
      return { ...state, distributors: payload };
    },
    setProducts(state: DistributorStore, payload: DistributorProduct) {
      return { ...state, products: payload };
    },
    setMeta(state: DistributorStore, payload: Meta) {
      return { ...state, meta: payload };
    },
    clearDistributor() {
      return {
        distributors: {},
        products: [],
        meta: {
          count: 0,
          page: 0,
          limit: 0,
        },
      };
    },
  },
  effects: (dispatch: any) => ({
    async getAllProducts(params: QueryParams) {
      const {
        distributor_id,
        page,
        page_size,
        sort_by,
        order_by,
        query = '',
      } = params;
      const payload: any = await distributorService.fetchAllProducts({
        distributor_id,
        page: page + 1,
        page_size,
        sort_by,
        order_by,
        query,
      });

      const products = payload && payload.products ? payload.products : [];
      const meta = payload && payload.meta ? payload.meta : {};

      dispatch.distributorStore.setProducts(products);
      dispatch.distributorStore.setMeta(meta);
    },

    async getDistributor(x: { id: number }) {
      const distributor: Distributor = await distributorService.getOne(x.id);
      dispatch.distributorStore.setDistributor(distributor);
    },

    clearDistributorStore() {
      dispatch.distributorStore.clearDistributor();
    },
  }),
};
