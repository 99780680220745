import React from 'react';

import { S } from './bulk-action-item-card.styles';

interface Props {
  title: string;
  subTitle: string;
  value: string;
}

export const BulkActionItemCard: React.FC<Props> = ({
  title,
  subTitle,
  value,
}) => (
  <S.Container>
    <S.Title>{title}</S.Title>
    <S.SubTitle>{subTitle}</S.SubTitle>
    <S.Text>{value}</S.Text>
  </S.Container>
);
