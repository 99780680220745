/* eslint-disable no-restricted-globals */
import React from 'react';

import { View, Text } from '@react-pdf/renderer';
import moment from 'moment-timezone';
import { DataObject } from 'types';

import { IConfig, Order } from 'common/reducers';
import { PaymentMethod } from 'common/constants';

import { textWrapper } from '../../../helper';

import { translations, ITranslation } from '../lang';

import { styles } from './styles';

interface DeliveryAddressesProps {
  config: IConfig;
  order: Order;
  marketId: string;
}

function getShipToDisplayAddress(invoice: DataObject<any>, pharmacy: DataObject<any>): React.ReactNode {
  return invoice?.custom_information?.pharmacy?.address || pharmacy?.address;
}

function getPharmacyDisplayName(invoice: DataObject<any>, pharmacy: DataObject<any>): React.ReactNode {
  return invoice?.custom_information?.pharmacy?.name || pharmacy?.name;
}

function getContactNumber(pharmacy: DataObject<any>): React.ReactNode {
  const contactInfos = pharmacy?.contacts?.value;
  let contactNumber = '-';

  if (contactInfos) {
    const priorityNumber = contactInfos?.owner_mobile || contactInfos?.manager_mobile || contactInfos?.payments_mobile;

    if (priorityNumber) {
      contactNumber = priorityNumber.replace("'", '');
    }
  }

  return contactNumber;
}

export const DeliveryAddresses: React.FC<DeliveryAddressesProps> = ({ config, order, marketId }) => {
  const {
    market: { regionalOffice },
  } = config;

  const t: ITranslation = translations[marketId];

  const { invoice = {}, pharmacy = {}, distributor = {} } = order;

  return (
    <View fixed>
      <View style={styles.deliveryInformationContainer}>
        <Text style={styles.mClinicaAddress}>
          {regionalOffice?.localName}
          {'\n'}
          {regionalOffice?.address},{'\n'}
          {regionalOffice?.building},{'\n'}
          {regionalOffice?.region}, {regionalOffice?.city} {regionalOffice?.province} {regionalOffice?.postalCode}{' '}
          {regionalOffice?.country}
        </Text>
        <Text style={styles.wholesalerAddress}>
          <Text style={{ fontWeight: 'bold' }}>Wholesaler</Text>
          {'\n'}
          {distributor?.name} {distributor?.legal_name ? `(${distributor?.legal_name})` : null}
          {'\n'}
          {distributor?.address}
          {'\n'}
          {distributor?.area} {distributor?.region}
          {'\n'}
          {distributor?.city} {distributor?.zipcode} {distributor?.country}
        </Text>
        <View style={{ width: '65mm', maxWidth: '65mm' }}>
          <View style={styles.informationContainer}>
            <Text style={styles.informationLabel}>Invoice No.</Text>
            <Text style={styles.informationValue}>{textWrapper(invoice?.invoice_number, 23)}</Text>
          </View>
          <View style={styles.informationContainer}>
            <Text style={styles.informationLabel}>Date</Text>
            <Text style={styles.informationValue}>{moment(invoice?.invoiced_at).format('DD/MMM/YYYY')}</Text>
          </View>
          <View style={styles.informationContainer}>
            <Text style={styles.informationLabel}>Purchase Order No.</Text>
            <Text style={styles.informationValue}> {textWrapper(order?.po_number, 23)}</Text>
          </View>
          <View style={styles.informationContainer}>
            <Text style={styles.informationLabel}>Payment Method</Text>
            <Text style={styles.informationValue}>
              {order?.payment_method === PaymentMethod.DIRECT_PAYMENT ? 'Direct Payment' : 'Credit Line'}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.addressesContainer}>
        <Text style={styles.billToAddress}>
          <Text style={{ fontWeight: 'bold' }}>Bill to</Text>
          {'\n'}
          {getPharmacyDisplayName(invoice, pharmacy)}
          {'\n'}
          {pharmacy?.address}
          {'\n'}
          {pharmacy?.area} {pharmacy?.region}
          {'\n'}
          {pharmacy?.city} {pharmacy?.zipcode} {pharmacy?.country}
        </Text>
        <Text style={styles.shipToAddress}>
          <Text style={{ fontWeight: 'bold' }}>Ship to</Text>
          {'\n'}
          {getPharmacyDisplayName(invoice, pharmacy)}
          {'\n'}
          {getShipToDisplayAddress(invoice, pharmacy)}
          {'\n'}
          {t.contactNumber}: {getContactNumber(pharmacy)}
          {'\n'}
          {pharmacy?.area} {pharmacy?.region}
          {'\n'}
          {pharmacy?.city} {pharmacy?.zipcode} {pharmacy?.country}
        </Text>
      </View>
    </View>
  );
};
