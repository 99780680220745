import { DataObject } from 'types';
import { FinanceUpdateInvoicePayload } from 'common/reducers/finance/types';
import { FinanceUpdateDisbursementDatePayload } from 'common/reducers/finance/types/finance-update-disbursement-date-payload.type';
import { FinanceUpdateReimbursementDatePayload } from 'common/reducers/finance/types/finance-update-reimbursement-date-payload.type';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { Invoice } from '../orders/interfaces';
import { FinanceLatestStatusOnReimbursementDate } from '../orders/interfaces/FinanceLatestStatusOnReimbursementDate.interface';
import { FinanceLatestStatusOnDisbursementDate } from '../orders/interfaces/FinanceLatestStatusOnDisbursementDate.interface';


export class SwipeRxPtFinance {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.FINANCE;
  }

  cancelRequest(): void {
    this.base.cancelRequest();
  }

  // eslint-disable-next-line class-methods-use-this
  private parseQueryParams(params: any): DataObject {
    const { transaction_status, status, ...options } = params;
    if (status !== 'all') {
      options.finance_status = status;
    }

    if (transaction_status) {
      options.due_status = transaction_status.replace('due_for_', '');
    }
    return options;
  }

  async list(params: any): Promise<any> {
    const options = this.parseQueryParams(params);
    return this.base.get(this.resource, options);
  }

  async approveInvoice(invoiceIds: number[]): Promise<Invoice[]> {
    const { data } = await this.base.post(`${this.resource}/approve`, {
      invoice_ids: invoiceIds,
    });

    if (data.error) throw new Error(data.error);
    return data;
  }

  async reimburseInvoice(invoiceIds: number[]): Promise<any> {
    const { data } = await this.base.post(`${this.resource}/reimburse`, {
      invoice_ids: invoiceIds,
    });
    return data;
  }

  async reimburseInvoiceByNumber(invoiceNumbers: string[]): Promise<any> {
    const { data } = await this.base.post(
      `${this.resource}/reimburse-by-number`,
      {
        invoice_numbers: invoiceNumbers,
      },
    );
    return data;
  }

  async reimburseInvoiceCsv(file: File, params?: any): Promise<any> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );

    const { data } = await this.base.post(
      `${this.resource}/reimburse-csv`,
      form,
      {
        headers: {
          'content-type': 'multipart/form-data;',
        },
        params: this.parseQueryParams(params)
      }
    );
    return data;
  }

  async disburseInvoiceByNumber(invoiceNumbers: string[]): Promise<any> {
    const { data } = await this.base.post(
      `${this.resource}/disburse-by-number`,
      {
        invoice_numbers: invoiceNumbers,
      },
    );
    return data;
  }

  async disburseInvoice(invoiceIds: number[]): Promise<{ 
    failed: Invoice[], 
    updated: Invoice[] 
  }> {
    const { data } = await this.base.patch(`${this.resource}/disburse`, {
      invoice_ids: invoiceIds,
    });
    return data;
  }

  async createOrUpdatePaymentPreference(
    id: number,
    payload: {
      days_reimbursement: number;
      days_disbursement: number;
    },
  ): Promise<any> {
    const { data } = await this.base.post(
      `${this.resource}/distributor-payment-preference/${id}`,
      payload,
    );
    return data;
  }

  async getPaymentPreference(id: number): Promise<any> {
    const { data } = await this.base.get(
      `${this.resource}/distributor-payment-preference/${id}`,
    );
    return data;
  }

  async exportInvoice(payload: DataObject, all: boolean): Promise<any> {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
      page,
      page_size,
      ...queryParams
    } = payload.params || {}

    const body = {
      all,
      invoice_ids: all ? [] : payload.invoiceIds,
      params: all ? this.parseQueryParams(queryParams) : null,
    };

    const { data } = await this.base.post(
      `${this.resource}/export`,
      body
    );
    return data;
  }

  async updateInvoice(
    invoiceId: number,
    payload: FinanceUpdateInvoicePayload
  ): Promise<Invoice> {
    const { data } = await this.base.patch(
      `${this.resource}/${invoiceId}/invoice`,
      payload,
    );

    return data;
  }

  async updateDisbursementDateInfo(
    payload: FinanceUpdateDisbursementDatePayload,
  ): Promise<FinanceLatestStatusOnDisbursementDate> {
    const { id, disbursement_date } = payload;
    const { data } = await this.base.patch(
      `${this.resource}/single-disbursement-date`,
      { id, disbursement_date }
    );

    return data;
  }

  async updateReimbursementDateInfo(
    payload: FinanceUpdateReimbursementDatePayload,
  ): Promise<FinanceLatestStatusOnReimbursementDate> {
    const { id, reimbursement_date } = payload;
    const { data } = await this.base.patch(
      `${this.resource}/single-reimbursement-date`,
      { id, reimbursement_date }
    );

    return data;
  }

  async holdToApproveInvoice(invoiceIds: number[], reason?: string): Promise<{ 
    failed: Invoice[], 
    updated: Invoice[] 
  }> {
    const { data } = await this.base.post(`${this.resource}/hold-to-approve`, {
      invoice_ids: invoiceIds,
      reason
    });
    return data;
  }

  async holdToDisburseInvoice(invoiceIds: number[], reason?: string): Promise<{ 
    failed: Invoice[], 
    updated: Invoice[] 
  }> {
    const { data } = await this.base.post(`${this.resource}/hold-to-disburse`, {
      invoice_ids: invoiceIds,
      reason
    });
    return data;
  }

  async getTotalRowCount(params: any): Promise<any> {
    const options = this.parseQueryParams(params);
    const { data } = await this.base.get(`${this.resource}/total-row-count`, options);
    return data;
  }
}
