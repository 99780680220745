/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

import React from 'reactn';
import { Grid } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { FormikValues } from 'formik';
import { FieldWrapper, ConditionalRender, ImagePreview } from 'common/components';

import { UserRoles } from 'common/constants';
import { AdvanceFormField } from './AdvanceFormField.component';
import { getViewValue } from './AdvanceFormColumn.util';

import { LayoutColumn, FormikAccumulator, GridXs, AcceptableImageSize } from '../AdvanceForm.interface';
import { ColumnType, FormStateType } from '../AdvanceForm.types';

interface Props {
  currentUserRole: UserRoles;
  subColumn: LayoutColumn;
  formik: FormikValues;
  initialValues: FormikAccumulator;
  formState: FormStateType;
  setFocusedField?: (name: string) => void;
  acceptableImageSize?: AcceptableImageSize;
  isSubmitting?: boolean;
  customComponent?: (data: any) => JSX.Element;
  columnStyle?: React.CSSProperties;
}

export const AdvanceFormColumn: React.FC<Props> = ({
  currentUserRole,
  subColumn,
  formik,
  initialValues,
  formState,
  setFocusedField,
  isSubmitting,
  columnStyle,
}): JSX.Element => {
  const {
    isNotVisibleInEdit,
    type,
    name,
    helperText: initialHelperText,
    gridSize,
    label,
    isLabelHidden,
    placeholder,
    config,
    blacklist,
    hidden = false,
    isDisabled,
    customComponent,
    buttonColor,
    buttonOnClick,
    referenceValue,
    useReferenceValidation,
  } = subColumn;

  const [helperText, setHelperText] = React.useState<string>(initialHelperText || '');

  const isViewBlacklisted =
    blacklist?.view && (blacklist.view === true || (blacklist.view as UserRoles[]).includes(currentUserRole));
  const isEditBlacklisted =
    blacklist?.edit &&
    (blacklist.edit === true || (blacklist.edit as UserRoles[]).includes(currentUserRole)) &&
    formState === FormStateType.EDIT;

  if (isViewBlacklisted || (isNotVisibleInEdit && formState === FormStateType.EDIT)) return <></>;

  if (type === ColumnType.ROWS) {
    const subColumnValue = subColumn.value as LayoutColumn[];
    return (
      <>
        <Grid container>
          {subColumnValue.map(
            (column: LayoutColumn): JSX.Element => {
              return (
                <AdvanceFormColumn
                  currentUserRole={currentUserRole}
                  subColumn={column}
                  formik={formik}
                  initialValues={initialValues}
                  formState={formState}
                  isSubmitting={isSubmitting}
                />
              );
            },
          )}
        </Grid>
      </>
    );
  }
  const formikValue = formik.values[name as keyof typeof initialValues];
  const viewValue = getViewValue(type, formikValue, config);
  const refInputValue = referenceValue ? formik.values[referenceValue as keyof typeof initialValues] : '';

  const handleButtonOnclick = async (): Promise<void> => {
    if (!buttonOnClick) return;
    if (!refInputValue) return;

    const newHelper = await buttonOnClick(formik.values);

    formik.setFieldValue(name, newHelper.message);
    if (newHelper.additionalInfo !== undefined) {
      setHelperText(newHelper.additionalInfo);
    }
    formik.setErrors(name, newHelper.message);
    formik.setFieldTouched(name, true, false);
  };

  let oldValue: any = '';
  if (subColumn.name === name) {
    oldValue = subColumn.value;
  }

  const hasError = !!formik.errors[name];
  const buttonDisabled = useReferenceValidation && !!referenceValue && !!formik.errors[referenceValue];

  return (
    <ConditionalRender condition={!hidden}>
      <Grid item xs={gridSize as GridXs} key={JSON.stringify(subColumn)}>
        <FieldWrapper
          key={name}
          label={!isLabelHidden ? name : undefined}
          isLabelBold
          style={{
            paddingBottom: 15,
            paddingRight: '10%',
            ...columnStyle,
          }}
          hasAsterisk={!!formik.errors[name] && formState !== FormStateType.VIEW}
          minWidth={100}
          error={formik.errors[name]}
          touched={formik.touched[name]}
        >
          <ConditionalRender condition={formState !== FormStateType.VIEW}>
            <AdvanceFormField
              type={type}
              name={name}
              helperText={helperText}
              label={label}
              values={formikValue}
              placeholder={placeholder}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              setFieldValue={formik.setFieldValue}
              config={config}
              isRequired={!!formik.errors[name]}
              disabled={isDisabled || isEditBlacklisted || isSubmitting}
              setFocusedField={setFocusedField}
              data-testid={`select-${name}`}
              initialValue={oldValue}
              hasError={hasError}
              buttonColor={buttonColor}
              buttonOnClick={handleButtonOnclick}
              buttonDisabled={buttonDisabled || !refInputValue}
            />
          </ConditionalRender>
          <ConditionalRender
            condition={
              formState === FormStateType.VIEW &&
              ![ColumnType.FILEUPLOAD, ColumnType.LINK, ColumnType.MARKDOWN].includes(type)
            }
          >
            <div style={{ overflowWrap: 'break-word' }}>{viewValue}</div>
          </ConditionalRender>
          <ConditionalRender condition={formState === FormStateType.VIEW && [ColumnType.FILEUPLOAD].includes(type)}>
            <ImagePreview imageSource={viewValue} />
          </ConditionalRender>
          <ConditionalRender condition={formState === FormStateType.VIEW && [ColumnType.LINK].includes(type)}>
            <Link href={viewValue}>view list</Link>
          </ConditionalRender>
          <ConditionalRender condition={formState === FormStateType.VIEW && [ColumnType.MARKDOWN].includes(type)}>
            <div style={{ overflowWrap: 'break-word', margin: 0 }} dangerouslySetInnerHTML={{ __html: viewValue }} />
          </ConditionalRender>
          <ConditionalRender condition={formState === FormStateType.VIEW}>
            {customComponent && !!viewValue && customComponent(viewValue)}
          </ConditionalRender>
        </FieldWrapper>
      </Grid>
    </ConditionalRender>
  );
};
