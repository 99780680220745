import React from 'reactn';

import {
  Button,
  Dialog,
  FieldWrapper,
  BulkActionItemCardMapper,
  CreatableAutoComplete,
  FieldDateInput,
} from 'common/components';
import { ColorType } from 'types';

import { fieldOptions } from './order-cancel-form.config';
import { useOrderCancelForm } from './use-order-cancel-form';

const formId = 'order-cancel-form';

export const OrderCancelModal: React.FC = () => {
  const {
    form,
    cancel: { orderIds, orderDetails },
    selectedReason,
    setSelectedReason,
    clearOrderIds,
  } = useOrderCancelForm();

  const renderContent = (): JSX.Element => (
    <>
      <BulkActionItemCardMapper data={orderDetails} />
      <form id={formId} onSubmit={form.handleSubmit} style={{ height: '20vh' }}>
        {fieldOptions.map(({ name, type, options, label }) => {
          if (type === 'select') {
            const handleChange = (option: {
              value: string;
              label: string;
            }): void => {
              form.setFieldValue(name, option.value);
              setSelectedReason(option);
            };

            const handleInputChange = (inputValue: string): void => {
              if (!inputValue) return;
              form.setFieldValue(name, inputValue);
            };

            return (
              <FieldWrapper
                key={name}
                touched={form.touched[name]}
                error={form.errors[name]}
                label={label}
              >
                <CreatableAutoComplete
                  name={name}
                  value={selectedReason}
                  onChange={handleChange}
                  options={options}
                  onInputChange={handleInputChange}
                  onBlur={form.handleBlur}
                />
              </FieldWrapper>
            );
          }
          if (type === 'date') {
            return (
              <FieldWrapper
                key={name}
                touched={form.touched[name]}
                error={form.errors[name]}
                label={label}
              >
                <FieldDateInput
                  value={form.values[name]}
                  onChange={(date) => {
                    form.setFieldValue(name, date);
                  }}
                />
              </FieldWrapper>
            );
          }

          return null;
        })}
      </form>
    </>
  );
  const renderActions = (): JSX.Element => (
    <Button
      color={ColorType.PRIMARY}
      disabled={!form.dirty || form.isSubmitting}
      loading={form.isSubmitting}
      form={formId}
      type="submit"
    >
      Submit
    </Button>
  );

  return (
    <Dialog
      open={!!orderIds.length}
      primary
      title={orderIds.length > 1 ? 'Cancel Orders' : 'Cancel Order'}
      onClose={clearOrderIds}
      renderActions={renderActions}
      renderContent={renderContent}
      subTitle={orderIds.length > 1 ? 'Selected' : 'Order ID'}
      value={
        orderIds.length > 1
          ? `${orderIds.length} Orders to cancel`
          : orderDetails.length === 1
          ? orderDetails[0].po_number
          : ''
      }
    />
  );
};
