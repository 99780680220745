import { useDispatch, useGlobal } from 'reactn';
import { TransactionActionName, DataObject } from 'types';
import { useRouteMatch } from 'react-router-dom';

import { getMarketPath, IMarketParams } from 'utils/get-router-path';
import { DataTableName, DataTable } from 'common/reducers';

type ProcessTransactionBulkAction = (name: string, orderIds: number[], confirmed?: boolean) => void;

export const useTransactionBulkAction = (): ProcessTransactionBulkAction => {
  const {
    params: { marketId },
  } = useRouteMatch<IMarketParams>();
  const initializeOrderCancel = useDispatch('initializeOrderCancel');
  const exportTransactions = useDispatch('exportTransactions');
  const exportTransactionsFullOrderDetails = useDispatch('exportTransactionsFullOrderDetails');
  const initializeOrderMerge = useDispatch('initializeOrderMerge');
  const initializeOrderDeliver = useDispatch('initializeOrderDeliver');
  const acceptOrders = useDispatch('acceptOrders');
  const initializeOrderReassign = useDispatch('initializeOrderReassign');
  const initializeOrderDelay = useDispatch('initializeOrderDelay');
  const initializeOrderRollback = useDispatch('initializeOrderRollback');
  const initializeUpdateInvoiceDates = useDispatch('initializeUpdateInvoiceDates');
  const processOrders = useDispatch('processOrders');
  const sendToDispatcher = useDispatch('sendToDispatcher');
  const markOrdersAsUndelivered = useDispatch('markOrdersAsUndelivered');
  const restoreOrders = useDispatch('restoreOrders');
  const initializeOrderExportToDeliveryPartner = useDispatch('initializeOrderExportToDeliveryPartner');
  const setTransactionError = useDispatch('setTransactionError');
  const setTransactionBulkActionConfirmation = useDispatch('setTransactionBulkActionConfirmation');
  const requeueForShipment = useDispatch('requeueShipment');
  const mergeShipment = useDispatch('mergeShipment');
  const manualCreateAWB = useDispatch('manualCreateAWB');
  const deleteShipment = useDispatch('deleteShipment');
  const [dataTables] = useGlobal('dataTables');
  const [{ market }] = useGlobal('config');

  const getUnpaidDirectPaymentOrders = (data: Record<DataTableName, DataTable>): DataObject[] =>
    data?.orders?.rows
      ?.filter((item) => item.status === 'pending' && item.payment_method === 'DIRECT_PAYMENT')
      .filter((item) => !item.invoice || (item.invoice && item.invoice.payment_status === 'pending'));

  const filterPrintOrder = (
    data: Record<DataTableName, DataTable>,
    orderIds: number[],
  ): { message: string; fitleredOrderIds: number[] } => {
    let message = `Printing PO in bulk will also update the\nstatus of the orders to In Progress.`;
    let fitleredOrderIds = orderIds;

    try {
      let additionalMessage;

      // Obtain filtered orders
      const unpaidOrders = getUnpaidDirectPaymentOrders(data);
      const filteredUnpaidOrder = unpaidOrders.filter((item) => orderIds.includes(item.id));
      const filteredUnpaidOrderIds = filteredUnpaidOrder.map((item) => item.id);

      // Obtain allowed orders to be printed
      if (filteredUnpaidOrder.length > 0) {
        fitleredOrderIds = orderIds.filter((item) => !filteredUnpaidOrderIds.includes(item));
      }

      // Prepare warning message
      if (filteredUnpaidOrder.length > 0) {
        additionalMessage = filteredUnpaidOrder.map((item) => item.po_number).join(', ');
      }

      if (additionalMessage) {
        message += `\n\nWARNING!!`;
        message += `\nThese orders with PO number listed can't be printed due to unpaid status:\n`;
        message += additionalMessage;
      }
    } catch (e) {
      alert('Invalid dataTables');
    }
    return { message, fitleredOrderIds };
  };

  const validateInvoiceAmount = (orderIds: number[]): [boolean, number] => {
    const thresholdAmount = market.transactional?.invoiceThreshold || 0;
    const selectedItems = dataTables.orders.rows.filter((item) => orderIds.includes(item.id));

    const invalidItems = selectedItems.filter((order) => {
      if (thresholdAmount === -1) return false;

      if (!order.invoice) return true;

      const diff = Math.abs(order.invoice.invoiced_amount - order.total_amount_with_tax);

      return diff > thresholdAmount;
    });

    return [invalidItems.length === 0, invalidItems.length];
  };

  const processTransactionBulkAction: ProcessTransactionBulkAction = (
    name: string,
    orderIds: number[],
    confirmed?: boolean,
  ): void => {
    switch (name as TransactionActionName) {
      case 'accept': {
        if (!confirmed) {
          const [itemsValid, totalInvalidItems] = validateInvoiceAmount(orderIds);

          if (itemsValid) {
            setTransactionBulkActionConfirmation({
              orderIds,
              action: 'accept',
              message: 'This will update the status of the orders to Accepted.',
            });
          } else {
            setTransactionError({
              error: `${totalInvalidItems} Orders has invalid Invoice amount or not within tolerated threshold`,
            });
          }

          break;
        }

        acceptOrders({ ids: orderIds });
        break;
      }

      case 'cancel':
        initializeOrderCancel({
          ids: orderIds,
        });
        break;

      case 'delay':
        initializeOrderDelay({
          ids: orderIds,
        });
        break;

      case 'rollback':
        initializeOrderRollback({
          ids: orderIds,
        });
        break;

      case 'export':
        exportTransactions({ ids: orderIds });
        break;

      case 'export_full_order_details':
        exportTransactionsFullOrderDetails({ ids: orderIds });
        break;

      case 'deliver':
        initializeOrderDeliver({
          ids: orderIds,
        });
        break;

      case 'mark_as_undelivered': {
        if (!confirmed) {
          setTransactionBulkActionConfirmation({
            orderIds,
            action: 'mark_as_undelivered',
            message: 'This will mark the orders as undelivered.',
          });
          break;
        }
        markOrdersAsUndelivered({ ids: orderIds });
        break;
      }

      case 'merge':
        initializeOrderMerge({ ids: orderIds });
        break;

      case 'print': {
        const { message, fitleredOrderIds } = filterPrintOrder(dataTables, orderIds);
        if (!confirmed) {
          setTransactionBulkActionConfirmation({
            orderIds: fitleredOrderIds,
            action: 'print',
            message,
          });
          break;
        }
        processOrders({ ids: fitleredOrderIds });
        window.open(`${getMarketPath(marketId)}/print-page/print-order/${fitleredOrderIds.join(',')}`);
        break;
      }

      case 'print_delivery_docs':
      case 'reprint_delivery_docs':
        window.open(`${getMarketPath(marketId)}/print-page/print-delivery-docs/${orderIds.join(',')}`);
        break;

      case 'reassign':
        initializeOrderReassign({ ids: orderIds });
        break;

      case 'reprint':
        window.open(`${getMarketPath(marketId)}/print-page/print-order/${orderIds.join(',')}`);
        break;

      case 'send_to_dispatcher':
        if (!confirmed) {
          setTransactionBulkActionConfirmation({
            orderIds,
            action: 'send_to_dispatcher',
            message: 'Send to dispatcher the selected order data',
          });
          break;
        }
        sendToDispatcher({ ids: orderIds });
        break;

      case 'restore': {
        if (!confirmed) {
          setTransactionBulkActionConfirmation({
            orderIds,
            action: 'restore',
            message: 'This will update the status of the orders to Pending.',
          });
          break;
        }
        restoreOrders({ ids: orderIds });
        break;
      }

      case 'export_to_delivery_partner': {
        initializeOrderExportToDeliveryPartner({ ids: orderIds });
        break;
      }

      case 'print_invoice_docs':
      case 'reprint_invoice_docs':
        window.open(`${getMarketPath(marketId)}/print-page/print-invoice-docs/${orderIds.join(',')}`);
        break;

      case 'print_delivery_proof':
      case 'reprint_delivery_proof':
        window.open(`${getMarketPath(marketId)}/print-page/print-delivery-proof/${orderIds.join(',')}`);
        break;

      case 'update_invoice_dates':
        initializeUpdateInvoiceDates({
          ids: orderIds,
        });
        break;
      case 'requeue_for_shipment':
        requeueForShipment({ order_ids: orderIds });
        break;
      case 'manual_create_awb':
        manualCreateAWB({ order_ids: orderIds });
        break;
      case 'merge_shipment':
        mergeShipment({ order_ids: orderIds });
        break;
      case 'delete_shipment':
        deleteShipment({ order_ids: orderIds });
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Not yet implemented');
        break;
    }
  };

  return processTransactionBulkAction;
};
