import React from 'reactn';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

import { Colors } from 'common/themes/colors';

const Text = styled(Typography)`
  && {
    font-size: 15px;
    font-weight: bold;
    color: ${Colors.textNormal};
  }
`;

export const MissingOptionsMessage: React.FC<{ message: string; gridStyle?: React.CSSProperties }> = ({
  message,
  gridStyle,
}) => (
  <Grid container justify="center" style={gridStyle}>
    <Text>{message}</Text>
  </Grid>
);
