/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import moment from 'moment';

import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';
import stores from '../../../../models/index.model';
import { TIMEOUT_50_SECS } from '../utils/api.constants';
import { IParamsCommon } from '../../../../models/backlog.model';
import { IDepositList } from '../../v3/swipe-rx-pt/resources/deposit/interfaces/deposit-list.interface';
import { IDeposit } from '../../v3/swipe-rx-pt/resources/deposit/interfaces/deposit.interfaces';

interface IPendingDeposit extends IParamsCommon {
  status: string;
}

interface IOverdueInvoices extends IParamsCommon {
  due_at?: string;
}

const parsePayloadPendingDeposits = (results: IDepositList): any => {
  const payload = results.data.map((item: IDeposit) => {
    const {
      id,
      uploaded_by,
      uploaded_at,
      status,
      proof_url,
      pharmacy_name,
      deposited_amount,
      deposited_at,
      pharmacy_id,
    } = item;
    const timePastSinceUpload = `${moment().diff(uploaded_at, 'days')} day(s)`;

    return {
      id,
      uploaded_by,
      uploaded_at,
      proof_deposit: status,
      proof_url,
      pharmacy: pharmacy_name,
      deposit_amount: deposited_amount,
      time_past_upload: timePastSinceUpload,
      deposited_at,
      pharmacy_id,
    };
  });
  return {
    deposit: !results ? [] : payload,
    meta: !results
      ? {
          page: 0,
          page_size: 0,
          total_count: 0,
        }
      : results.meta,
  };
};

class BacklogServices {
  async fetchPendingDeposits(data: IPendingDeposit) {
    stores.dispatch.uiStore.showLoading(true);
    try {
      const results = await SwipeRxPt.instance.deposit.list({
        page: data.page,
        page_size: data.page_size,
        order_by: data.order_by,
        sort_by: data.sort_by,
        status: data.status,
      });

      const payload: any = results.meta.total_count > 0
        ? parsePayloadPendingDeposits(results)
        : {};
      stores.dispatch.uiStore.showLoading(false);
      return { result: payload };
    } catch (e) {
      console.log('error ::', e);
    }
  }

  async fetchOverdueInvoices(data: IOverdueInvoices) {
    const { due_at, page, page_size, sort_by, order_by } = data;
    stores.dispatch.uiStore.showLoading(true);

    try {

      const { data: { invoices }, meta } = await SwipeRxPt.instance.invoice.getOverdue({
        page,
        page_size,
        order_by,
        sort_by,
        due_at,
      }, TIMEOUT_50_SECS);

      const payload = {
        result: invoices,
        page: meta.page,
        page_size: meta.page_size,
        total: meta.total_count,
      };
      stores.dispatch.uiStore.showLoading(false);
      return payload;
    } catch (e) {
      // console.log('e ::', e);
    }
  }
}

export default new BacklogServices();
