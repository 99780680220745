/* eslint-disable no-param-reassign */

import React, { useState } from 'react';
import Select from 'react-select';

import { Grid } from '@material-ui/core';

import { FieldInput} from 'common/components';
import { OptionType } from 'common/interfaces/OptionType.interface';

import { S } from './field-date-selection.styles'
import { DateSelection } from './field-date-selection.interface';

interface Props {
  defaultTime: string;
  defaultUnit: string;
  onChange: (value: DateSelection)=>void;
  unitOptions: OptionType[];
  componentKey?: string;
}

export const FieldDateSelection: React.FC<Props> = ({
  defaultTime,
  defaultUnit,
  onChange,
  unitOptions,
  componentKey
}) => {
  unitOptions = unitOptions.map(unitOption => {
    unitOption.label = `${unitOption.value} ago`;
    return unitOption
  })
  const [time, setTime] = useState(defaultTime)
  const [unit, setUnit] = useState(defaultUnit)

  const handleUnitChange = (selected: any): void => {
    const newUnit = selected.value
    if(newUnit && time ) {
      onChange({
        unit: newUnit,
        time
      })
    }    
    setUnit(newUnit)
  }

  const handleTimeChange = (e: any): void => {
    const newTime = e.target.value;
    if(unit && newTime) {
      onChange({
          unit,
          time: newTime
      })
    }
    setTime(newTime)
  }

  const unitLabel = unit ? `${unit} ago`: unit;

  return (
    <Grid container>
      <Grid item xs={6}>
        <FieldInput
          key={`${componentKey || ''}-input`}
          placeholder="value"
          value={time}
          onChange={handleTimeChange}
          inputProps={{
              style: {
                height: '15px'
              },
          }}                
          minWidth='98%'
        />              
      </Grid>
      <Grid item xs={6}>
        <Select
          key={`${componentKey || ''}-select`}              
          defaultValue={{ label: unitLabel, value: unit }}
          options={unitOptions}
          onChange={handleUnitChange}
          styles={S.UnitOptionStyles}                
        />
      </Grid>
    </Grid>
  );
};
