import React from 'react';

import S, { Paper } from './DataGrid.styles';
import { DataGridProps } from './DataGrid.types';

export const DataGrid: React.FC<DataGridProps> = ({
  rows,
  columns,
  headerHeight,
  rowHeight,
  hideHeader,
  fontSize,
}) => {
  return (
    <S.TableContainer component={Paper}>
      <S.Table>
        <S.TableHead hide={hideHeader}>
          <S.TableRow>
            {columns.map((item) => {
              return (
                <S.TableCell
                  align={item.align ? item.align : 'left'}
                  style={{ width: item.width, height: headerHeight }}
                  fontSize={fontSize}
                >
                  {item.headerName}
                </S.TableCell>
              );
            })}
          </S.TableRow>
        </S.TableHead>
        <S.TableBody>
          {rows.map((row, index) => (
            <S.TableRow key={row.id} index={index}>
              {columns.map((item) => {
                return (
                  <S.TableCell
                    align={item.align}
                    height={rowHeight}
                    width={item.width}
                    fontSize={fontSize || item.fontSize}
                  >
                    {row[item.field]}
                  </S.TableCell>
                );
              })}
            </S.TableRow>
          ))}
        </S.TableBody>
      </S.Table>
    </S.TableContainer>
  );
};
