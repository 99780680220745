import { useDispatch } from 'reactn';
import { useUpdateQueryParams } from 'hooks';
import { PaymentMethod } from 'common/constants';

type SearchFilterFields = Partial<
  Record<
    | 'po_numbers'
    | 'invoice_number'
    | 'pharmacy_id'
    | 'pharmacy_name'
    | 'ordered_at'
    | 'flag_reason'
    | 'bill_reference'
    | 'bill_code'
    | 'distributor_id'
    | 'payment_method',
    string | null
  >
>;

type UpdateSearchFilter = (fields: SearchFilterFields, onFirstLoad?: boolean) => void;

type UseTransactionSearchFilter = () => {
  updateSearchFilter: UpdateSearchFilter;
};

export const useTransactionSearchFilter: UseTransactionSearchFilter = () => {
  const fetchDataTableRows = useDispatch('fetchDataTableRows');
  const { queryParams } = useUpdateQueryParams();
  const { status, page_size, only_document_pending, precursor_status } = queryParams;

  const updateSearchFilter: UpdateSearchFilter = (
    {
      po_numbers,
      invoice_number,
      pharmacy_id,
      pharmacy_name,
      ordered_at,
      flag_reason,
      bill_reference,
      bill_code,
      distributor_id,
      payment_method,
    },
    onFirstLoad?: boolean,
  ): void => {
    const pageSize = page_size || 50;
    fetchDataTableRows({
      name: 'orders',
      params: {
        distributor_id,
        payment_method,
        status,
        po_numbers,
        invoice_number,
        pharmacy_id,
        pharmacy_name,
        ordered_at,
        flag_reason,
        bill_reference,
        bill_code,
        only_document_pending,
        precursor_status,
        page: 1,
        page_size: +pageSize,
      },
      // TODO: hotfix to be fix at SPT-10798
      cancelPendingRequest: onFirstLoad && payment_method !== PaymentMethod.DIRECT_PAYMENT,
    });
  };

  return { updateSearchFilter };
};
