import React from 'reactn';

import {
  Button,
  Dialog,
  FieldWrapper,
  BulkActionItemCardMapper,
} from 'common/components';
import { ColorType } from 'types';

import { useOrderReassignForm } from './use-order-reassign-form';
import { fieldOptions } from './order-reassign-form.config';

const formId = 'order-reassign-form';

export const OrderReassignModal: React.FC = () => {
  const {
    form,
    reassign: { orderIds, options, orderDetails },
    clearOrderIds,
    selectedDistributor,
    setSelectedDistributor,
  } = useOrderReassignForm();

  const renderContent = (): JSX.Element => (
    <>
      <BulkActionItemCardMapper data={orderDetails} />
      <form id={formId} onSubmit={form.handleSubmit} style={{ height: '20vh' }}>
        {fieldOptions.map(({ name, component: Field, label }) => {
          const handleChange = (option: {
            value: string;
            label: string;
          }): void => {
            form.setFieldValue(name, option.value);
            setSelectedDistributor(option);
          };

          return (
            <FieldWrapper
              key={name}
              touched={form.touched[name]}
              error={form.errors[name]}
              label={label}
            >
              <Field
                name={name}
                value={selectedDistributor}
                onChange={handleChange}
                options={options}
                onBlur={form.handleBlur}
              />
            </FieldWrapper>
          );
        })}
      </form>
    </>
  );
  const renderActions = (): JSX.Element => (
    <Button
      color={ColorType.PRIMARY}
      disabled={!form.dirty || form.isSubmitting}
      loading={form.isSubmitting}
      form={formId}
      type="submit"
    >
      Submit
    </Button>
  );

  return (
    <Dialog
      open={!!orderIds.length}
      primary
      title={orderIds.length > 1 ? 'Reassign Orders' : 'Reassign Order'}
      onClose={clearOrderIds}
      renderActions={renderActions}
      renderContent={renderContent}
      subTitle={orderIds.length > 1 ? 'Selected' : 'Order ID'}
      value={
        orderIds.length > 1
          ? `${orderIds.length} Orders to reassign`
          : orderDetails.length === 1
          ? orderDetails[0].po_number
          : ''
      }
    />
  );
};
