import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';
import { addReducer } from 'reactn';
import { recordException } from 'utils/Reporting/Sentry';
import { PrecursorState } from './interfaces/PrecursorState.interface';

export const PRECURSOR_INITIAL_STATE: PrecursorState = {
  precursorStatusHistory: [],
  error: '',
  message: '',
  loading: false,
};
addReducer('fetchPrecursorStatusHistory', async (_, dispatch, { id, params }) => {
  try {
    const { data } = await SwipeRxPt.instance.precursor.getHistory(id, params);

    dispatch.setPrecursorHistory(data);
  } catch (e) {
    if (e instanceof Error) {
      recordException(e, 'fetchPrecursorStatusHistory', { id });
      dispatch.setPrecursorError({ error: e.message });
    }
  }
});

addReducer('rejectPrecursor', async (_, dispatch, { id, payload }) => {
  try {
    dispatch.setLoading({ loading: true });
    await SwipeRxPt.instance.precursor.reject(id, payload);
    dispatch.setPrecursorSuccess({ message: 'Precursor Order Rejected' });
  } catch (e) {
    if (e instanceof Error) {
      recordException(e, 'rejectPrecursor', { id });
      dispatch.setPrecursorError({ error: e.message });
    }
  }
});

addReducer('approvePrecursor', async (_, dispatch, { id }) => {
  try {
    dispatch.setLoading({ loading: true });
    await SwipeRxPt.instance.precursor.approve(id);
    dispatch.setPrecursorSuccess({ message: 'Precursor Order Approved' });
  } catch (e) {
    if (e instanceof Error) {
      recordException(e, 'approvePrecursor', { id });
      dispatch.setPrecursorError({ error: e.message });
    }
  }
});

addReducer('setPrecursorHistory', async (state, _, precursor) => {
  const { precursor: precursorState } = state;
  return {
    ...state,
    precursor: {
      ...precursorState,
      precursorStatusHistory: precursor,
    },
  };
});

addReducer('setPrecursorSuccess', async (state, _, payload) => {
  const { precursor: precursorState } = state;
  return {
    ...state,
    precursor: {
      ...precursorState,
      message: payload.message,
      loading: false,
    },
  };
});

addReducer('setPrecursorError', async (state, _, payload) => {
  const { precursor: precursorState } = state;
  return {
    ...state,
    precursor: {
      ...precursorState,
      error: payload.error,
      loading: false,
    },
  };
});

addReducer('setLoading', async (state, _, payload) => {
  const { precursor: precursorState } = state;
  return {
    ...state,
    precursor: {
      ...precursorState,
      loading: payload.loading,
    },
  };
});
