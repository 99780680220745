import { DocumentManagementPharmacyUploadData, DocumentManagementPharmacyUpdateData } from 'common/reducers/document-management-pharmacy/types';
import { IDocumentManagementPharmacyHistory } from "../../../../../../reducers/document-management-pharmacy/interfaces/document-management-pharmacy-history.interface";
import { IDocumentType, IPharmacy } from '../..';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';

export class SwipeRxPtDocumentManagementPharmacy {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.DOCUMENT_MANAGEMENT_PHARMACY;
  }

  async scanDocument(payload: DocumentManagementPharmacyUploadData): Promise<any> {
    return this.base.post(`${this.resource}/scan-document`, payload);
  }

  async fetchSignedUrl(payload: { id: number, minutes?: number }): Promise<any> {
    return this.base.post(`${this.resource}/get-signed-url`, payload);
  }

  async fetchSignedUrlByPharmacyIdAndShort(payload: { pharmacy_id: number, document_management_type_short: string, minutes?: number }): Promise<any> {
    return this.base.post(`${this.resource}/get-signed-url-by-pharmacy-and-short`, payload);
  }

  async fetchSignedUrlByPharmacyIdAndShorts(payload: { pharmacy_id: number, document_management_type_short: string[], minutes?: number }): Promise<any> {
    return this.base.post(`${this.resource}/get-signed-url-by-pharmacy-and-shorts`, payload);
  }

  async fetchSignedUrlByDocumentHistoryId(payload: { id: number,  minutes?: number }): Promise<any> {
    return this.base.post(`${this.resource}/get-signed-url-by-document-history-id`, payload);
  }

  async list(params: any): Promise<any> {
    return this.base.get(`${this.resource}`, params);
  }

  async getAllhistory(params: any): Promise<{ data: IDocumentManagementPharmacyHistory[], meta: any}> {
    const { data, meta } = await this.base.get(`${this.resource}/history`, params);
    return { data, meta };
  }

  async uploadStatus() : Promise<any> {
    return this.base.get(`${this.resource}/upload-status`);
  }

  async uploadDone() : Promise<any> {
    return this.base.post(`${this.resource}/upload-done`, {});
  }

  async updateDocument(payload: DocumentManagementPharmacyUpdateData) : Promise<any> {
    return this.base.patch(`${this.resource}/${payload.id}`, 
    {
      issue_date: payload.issueDate,
      expiry_date: payload.expiryDate,
      document_management_type_id: payload.documentManagementTypeId,
      pharmacy_id: payload.pharmacyId,
    });
  }  

  async getDocumentTypes(params: {
    term?: string;
    page_size?: number;
    order_by?: string;
    sort_by?: string;
  }): Promise<IDocumentType[]> {
    const { data } = await this.base.get('document-type/types', {
      page_size: 5,
      order_by: 'type',
      sort_by: 'asc',
      ...params,
    });
    return data;
  }

  async getPharmacys(params: {
    term?: string;
    page_size?: number;
    order_by?: string;
    sort_by?: string;
  }): Promise<IPharmacy[]> {
    const { data } = await this.base.get('pharmacys/pharmacys', {
      order_by: 'name',
      sort_by: 'asc',
      ...params,
    });
    return data;
  }

  async deleteById(id: number): Promise<void> {
    return this.base.delete(`${this.resource}/${id}`);
  }
}
