/* eslint-disable no-param-reassign */
import React, { useDispatch, useEffect, useGlobal } from 'reactn';

import { useUpdateQueryParams } from 'hooks';
import { SortOrder } from 'types';
import { DataTableName } from 'common/reducers';
import { CardTable } from 'common/components/CardTable';
import { CardTableColumn } from 'common/components/CardTable/interfaces';
import {
  CardTableActionSelectHandler,
  CardTablePageChangeHandler,
  CardTablePageSizeChangeHandler,
  CardTableRowFooter,
  CardTableSortHandler,
  CardTableOnAllPagesSelect,
} from 'common/components/CardTable/types';
import { ActionMenuItems } from 'common/components/ActionMenu/types';

interface Props {
  config: {
    actions?: {
      items: ActionMenuItems;
      onSelect?: CardTableActionSelectHandler;
    };
    /* buttons?: {
      items: TransactionActionButtonItems;
      onClick: TransactionActionButtonHandler;
    }; */
    columns: CardTableColumn[];
    key?: string;
    rowFooter?: CardTableRowFooter;
    selectable?: boolean;
    isAllSelectable?: boolean;
    isNoCount?: boolean;
    isNoRow?: boolean;
  };
  name: DataTableName;
  initialParams?: Record<string, any>;
  isActionLoading?: boolean;
  defaultActionItems: ActionMenuItems;
}

export const DataTable: React.FC<Props> = ({
  config: { actions, columns, key, rowFooter, selectable, isAllSelectable, isNoCount, isNoRow },
  name,
  isActionLoading,
  defaultActionItems,
  initialParams,
}) => {
  const fetchDataTableRows = useDispatch('fetchDataTableRows');
  const resetDataTable = useDispatch('resetDataTable');
  const setDataTableSelectAllStatus = useDispatch('setDataTableSelectAllStatus');
  const fetchTransactionRowCount = useDispatch('fetchTransactionRowCount');

  const [dataTables] = useGlobal('dataTables');
  const [transactions] = useGlobal('transactions');

  const { queryParams, updateQueryParams } = useUpdateQueryParams();

  const { error = '', loading, rows = [], params = {}, isAllSelected = false } = dataTables[name] || {};
  if (actions) {
    actions.items = isAllSelected ? defaultActionItems : actions?.items;
  }

  const defaultPageSize = 50;
  const defaultPage = 1;

  useEffect(() => {
    return () => {
      resetDataTable({ name });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort: CardTableSortHandler = (orderBy, sortBy): void => {
    const paramUpdates = {
      order_by: sortBy ? orderBy : undefined,
      sort_by: sortBy as string,
    };
    fetchDataTableRows({
      name,
      params: paramUpdates,
      // cancelPendingRequest: true,
    });
    updateQueryParams(paramUpdates as Record<string, any>);
  };

  const handlePageSizeChange: CardTablePageSizeChangeHandler = (pageSize) => {
    fetchDataTableRows({
      name,
      params: {
        page_size: pageSize,
      },
      // cancelPendingRequest: true,
    });
    updateQueryParams({
      page_size: pageSize.toString(),
    });
  };

  const handlePageChange: CardTablePageChangeHandler = (page) => {
    fetchDataTableRows({
      name,
      params: {
        page,
      },
    });
    updateQueryParams({
      page: page.toString(),
    });
  };

  const handleAllPagesSelect: CardTableOnAllPagesSelect = (selectAll): void => {
    setDataTableSelectAllStatus({ name, selectAll });
  };

  const handleFetchTotalRowCount = (): void => {
    fetchTransactionRowCount(initialParams);
  };

  const getCurrentPageParams = (): {
    page: number;
    pageCount: number;
    pageSize: number;
    orderBy: string;
    sortBy: SortOrder;
  } => {
    const { page, page_size, order_by, sort_by } = queryParams as Record<string, string>;

    return {
      page: parseInt(page, 10) || defaultPage,
      pageCount: params.page_count || defaultPage,
      pageSize: parseInt(page_size, 10) || defaultPageSize,
      orderBy: order_by,
      sortBy: sort_by as SortOrder,
    };
  };

  const {
    page: currentPage,
    pageCount: currentPageCount,
    pageSize: currentPageSize,
    orderBy: currentOrderBy,
    sortBy: currentSortBy,
  } = getCurrentPageParams();

  let totalRowCount = 0;
  if (params?.total_count) {
    totalRowCount = params.total_count;
  } else if (transactions?.totalRowCount) {
    totalRowCount = transactions.totalRowCount;
  }

  return (
    <CardTable
      config={{
        actions,
        // buttons,
        columns,
        key,
        rowFooter,
        selectable,
        isAllSelectable,
        customPagination: true,
        pageSize: {
          defaultValue: defaultPageSize,
          items: [defaultPageSize, 60, 80, 100],
        },
        scroll: 500,
        isNoCount,
        isNoRow,
      }}
      currentPage={currentPage}
      currentPageCount={currentPageCount}
      currentPageSize={currentPageSize}
      currentOrderBy={currentOrderBy}
      currentSortBy={currentSortBy}
      data={rows}
      error={error}
      isActionLoading={isActionLoading}
      loading={loading}
      totalRowCount={totalRowCount}
      onSort={handleSort}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      onAllPagesSelect={handleAllPagesSelect}
      onFetchTotalRowCount={handleFetchTotalRowCount}
      name={name}
    />
  );
};
