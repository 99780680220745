import styled from 'styled-components';

const CriteriaContainer = styled.div`
    background: #FFFFFF;
    border: 1px solid #40AE96;
    border-radius: 50px;
    padding: 4px 10px;
    margin: 1.5px;
    display: flex;
    align-items: center;

    & .criteria-value {
        max-width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & .remove-icon {
        display: flex;
        align-items: center;
        margin-left: 6px;
        cursor: pointer;
        background: #fff;
        border: 0;
        padding: 0;

        &:focus {
            outline: none;
        }
    }
`

export const S = {
    CriteriaContainer
}