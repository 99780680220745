import { ParseStatusResult } from './interfaces';
import {
  OrderStatus,
  OrderFulfillmentStatus,
  OrderStatusFulfillmentMap,
} from '../services/apis/v3';

export const getTransactionStatus = (
  status: string,
  fulfillmentStatus: string,
): string =>
  ['dispatched', 'delivered', 'fulfilled'].includes(fulfillmentStatus)
    ? fulfillmentStatus
    : status;

export const parseStatus = (value: string): ParseStatusResult => {
  let status: OrderStatus | undefined;
  let fulfillmentStatus: OrderFulfillmentStatus | undefined;
  let isReturned: boolean | undefined;
  let isDelayed: boolean | undefined;
  let finalValue = value === 'all' ? undefined : value;
  let isFulfillmentStatus = [
    OrderFulfillmentStatus.DISPATCHED,
    OrderFulfillmentStatus.DELIVERED,
    OrderFulfillmentStatus.FULFILLED,
  ].includes(value as OrderFulfillmentStatus);

  if (OrderStatusFulfillmentMap[value as OrderStatus]) {
    finalValue = OrderStatusFulfillmentMap[value as OrderStatus];
    isFulfillmentStatus = true;
  }

  if (isFulfillmentStatus) {
    fulfillmentStatus = finalValue as OrderFulfillmentStatus;
  } else if (value === 'returned') {
    isReturned = true;
  } else if (value === 'delayed') {
    isDelayed = true;
  } else {
    status = finalValue as OrderStatus;
  }

  return { status, fulfillmentStatus, isReturned, isDelayed };
};
