import { ColDef } from 'common/components/DataGrid';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { Order } from 'common/services/apis/v3/swipe-rx-pt';
import moment from 'moment';

export interface OrderDetails {
  data: Order | null;
  error?: string | null;
}

export interface LabelProps {
  shade?: string;
  weight?: string;
  marginTop?: number;
  marginBottom?: number;
}

export interface TextProps {
  shade?: string;
  weight?: string;
}

export interface ButtonProps {
  width?: number;
  danger?: boolean;
}

export interface DialogActionsProps {
  form: any;
  formId: string;
  order: any;
  marketId: string;
  onClose: any;
  onConfirmation: any;
  dialogVisibility: boolean;
}

export interface DialogProps {
  invisible?: boolean;
}

export interface ContentProps {
  formId: string;
  form: any;
  ordered_at: any;
  order: any;
  orderDetails: OrderDetails;
  config: any;
  marketId: string;
  dialogVisibility: boolean;
}

export interface CompactOrderItem {
  id?: number | null;
  sku_code?: string | null;
  name?: string | null;
  quantity?: number | null;
  selling_price?: number | null;
}

export interface DataGridPaginationProps {
  totalCount: number;
  onNext?: any;
  onPrev?: any;
  page: number;
  pageSize: number;
}

export type DateTime = string | moment.Moment | MaterialUiPickersDate;

export const DataGridColumns: ColDef[] = [
  { field: 'sku_code', headerName: 'SKU ID', width: 130, align: 'left' },
  {
    field: 'name',
    headerName: 'Product Name',
    width: 300,
    align: 'left',
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    type: 'number',
    width: 100,
    align: 'center',
  },
  {
    field: 'net_price',
    headerName: 'Price (VAT may included)',
    type: 'number',
    width: 270,
    align: 'center',
  },
];

export const MAX_ADDRESS_LENGTH = 100;
export const MAX_NAME_LENGTH = 30;
