/* eslint-disable class-methods-use-this */

import stores from '../../../../models/index.model';
import { QueryParams } from '../../../../models/distributor.model';
import { PtAPIv2 } from '../utils';
import { TIMEOUT_50_SECS } from '../utils/api.constants';

class DistributorService {
  async fetchAllProducts(
    data: QueryParams,
  ): Promise<{ products: any; meta: any } | undefined> {
    stores.dispatch.uiStore.showLoading(true);
    try {
      const queryParams = `?page=${data.page}&page_size=${data.page_size}&sort_by=${data.sort_by}&order_by=${data.order_by}&query=${data.query}`;
      const payload: any = await PtAPIv2.dashboard.get(
        `distributors/${data.distributor_id}/products${queryParams}`,
        undefined,
        TIMEOUT_50_SECS,
      );
      stores.dispatch.uiStore.showLoading(false);
      return { products: payload.result, meta: payload.meta };
    } catch (e) {
      // console.log('error ::', e);
      return undefined;
    } finally {
      // always executed
      stores.dispatch.uiStore.showLoading(false);
    }
  }

  async getOne(id: number): Promise<any> {
    stores.dispatch.uiStore.showLoading(true);
    try {
      const responseData = await PtAPIv2.dashboard.get(
        `organizations/${id}`,
        undefined,
        TIMEOUT_50_SECS,
      );
      const { organization } = responseData.result;
      return organization;
    } catch (e) {
      // console.log(e);
      return undefined;
    } finally {
      // always executed
      stores.dispatch.uiStore.showLoading(false);
    }
  }
}

export default new DistributorService();
