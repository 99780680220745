import React from 'react';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { DEFAULT_MARKET_ID } from '../common/services/constants';
import { getMarketPath, IMarketParams } from '../utils/get-router-path';

export const RedirectToPath: React.FC<{}> = () => {
  const location = useLocation();
  const { params } = useRouteMatch<IMarketParams>();
  const { marketId } = params;
  if (!marketId) {
    let redirectTo = `${getMarketPath(DEFAULT_MARKET_ID)}`;
    if (!localStorage.getItem('PT-ROLE-DATA')) {
      redirectTo = `${redirectTo}/login`;
    }
    return <Redirect to={redirectTo} />;
  } else {
    return <Redirect to={`${(location as any).match.path}`} />;
  }
};
