/* eslint-disable no-restricted-globals */
import { addReducer } from 'reactn';

import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';

import { recordException } from 'utils/Reporting/Sentry';

import { ProductState } from './interfaces/ProductState.interface';
import { RootState } from '..';

export const PRODUCTS_INITIAL_STATE: ProductState = {
  productList: {
    data: null,
  },
  productDetails: {
    data: null,
  },
  productDistributorDetails: {
    data: null,
  },
  productCategoriesDetails: {
    data: null,
  },
  productTypeList: [],
  productCcpList: [],
  groupIndicationList: [],
  subIndicationList: [],
  productCcpLabelValueMap: new Map<string, string>(),
  loading: false,
};

addReducer('fetchProducts', async (state, dispatch, payload) => {
  const { products } = state;

  try {
    const { data } = await SwipeRxPt.instance.products.search(payload);
    return {
      ...state,
      products: {
        ...products,
        productList: {
          data,
        },
      },
    };
  } catch (e) {
    recordException(e, 'fetchProducts', { payload });
    dispatch.setProductError({ error: e.message });
    return state;
  }
});

addReducer('patchDistributorProductAndRefreshThePage', async (_, dispatch, payload) => {
  try {
    const { fetchParams, ...params } = payload;
    await SwipeRxPt.instance.distributors.updateDistributorProduct(params.distributor_id, params.id, params.payload);
    dispatch.fetchProductDistributorDetails({ id: params.product_id, params: fetchParams });
  } catch (e) {
    recordException(e, 'patchDistributorProductAndRefreshThePage', payload);
    dispatch.setProductError({ error: e.message });
  }
});

addReducer('fetchProductDetails', async (_, dispatch, { id }) => {
  try {
    const product = await SwipeRxPt.instance.products.retrieve(id);
    dispatch.setProductDetails(product);
  } catch (e) {
    recordException(e, 'fetchProductDetails', { id });
    dispatch.setProductError({ error: e.message });
  }
});

addReducer('fetchProductDistributorDetails', async (_, dispatch, { id, params }) => {
  try {
    const productDistributor = await SwipeRxPt.instance.products.listProductDistributors(id, params);
    dispatch.setProductDistributorDetails(productDistributor);
  } catch (e) {
    recordException(e, 'fetchProductDistributorDetails', { id, params });
    dispatch.setProductError({ error: e.message });
  }
});

addReducer('fetchProductCategories', async (state, dispatch, params) => {
  const { products } = state;

  try {
    const response = await SwipeRxPt.instance.productCategories.fetchProductCategories(params || { name: '' });
    return {
      ...state,
      products: {
        ...products,
        productCategoriesDetails: {
          data: response.data,
        },
      },
    };
  } catch (e) {
    recordException(e, 'fetchProductCategories', { params });
    dispatch.setProductError({ error: e.message });
    return state;
  }
});

addReducer('fetchProductPageLists', async (state) => {
  try {
    const { products } = state;
    const data = await SwipeRxPt.instance.masterList.fetchListsByDomain('product');

    const labelValueMap = new Map<string, string>();
    data.ccp_flag.forEach((masterList) => {
      labelValueMap.set(masterList.label || masterList.value, masterList.value);
      labelValueMap.set(masterList.value, masterList.label || masterList.value);
    });

    return {
      ...state,
      products: {
        ...products,
        productTypeList: data?.product_type?.map((v) => {
          return {
            label: v.label || v.value,
            value: v.value,
          };
        }) || [],
        productCcpList: data?.ccp_flag?.map((v) => {
          return {
            label: v.label || v.value,
            value: v.value,
          };
        }) || [],
        groupIndicationList: data?.group_indication?.map((v) => {
          return {
            label: v.label || v.value,
            value: v.value,
          };
        }) || [],
        subIndicationList: data?.sub_indication?.map((v) => {
          return {
            label: v.label || v.value,
            value: v.value,
          };
        }) || [],
        productCcpLabelValueMap: labelValueMap,
      },
    };
  } catch (e) {
    recordException(e, 'fetchProductTypeList');
    return state;
  }
});

addReducer('createProduct', async (_, dispatch, product) => {
  try {
    const { id } = await SwipeRxPt.instance.products.createProduct(product);
    dispatch.fetchProductDetails({ id });
  } catch (e) {
    recordException(e, 'createProduct', { product });
    dispatch.setProductError({ error: e.message });
  }
});

addReducer('patchProduct', async (_, dispatch, { id, product }) => {
  try {
    await SwipeRxPt.instance.products.patchProduct(id, product);
    dispatch.fetchProductDetails({ id });
  } catch (e) {
    recordException(e, 'patchProduct', { id, product });
    dispatch.setProductError({ error: e.message });
  }
});

addReducer('setProductDetails', (state, _, payload) => {
  const { products } = state;

  return {
    ...state,
    products: {
      ...products,
      productDetails: {
        data: payload,
        error: null,
      },
    },
  };
});

addReducer('setProductDistributorDetails', (state, _, payload) => {
  const { products } = state;

  return {
    ...state,
    products: {
      ...products,
      productDistributorDetails: {
        ...payload,
      },
    },
  };
});

addReducer('setProductError', (state, _, payload) => {
  const { products } = state;

  return {
    ...state,
    products: {
      ...products,
      error: payload.error,
    },
  };
});

addReducer(
  'setProductLoading',
  (state, _, loading): RootState => {
    const { products } = state;
    return {
      ...state,
      products: {
        ...products,
        loading,
      },
    };
  },
);
