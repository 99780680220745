import React, { useGlobal, useDispatch, useEffect, useState } from 'reactn';

import { Order } from 'common/reducers';

import { SingleOrderData, DeliveryProofV0, DeliveryProofV2 } from './templates';
import { TemplateOptions } from './Templates.constants';
import { PAPERSIZE } from './constants';

export const TemplatesContainer: React.FC<any> = (props) => {
  const { match } = props;
  const {
    params: { marketId },
  } = match;

  const { isLoadingPage, setIsLoadingPage } = props?.loadingProps;

  const fetchDataTableRows = useDispatch('fetchDataTableRows');
  const fetchMarketConfig = useDispatch('fetchMarketConfig');
  const markOrderDeliveryDocsAsPrinted = useDispatch('markOrderDeliveryDocsAsPrinted');
  const [{ orders }] = useGlobal('dataTables');
  const [config] = useGlobal('config');
  const [ordersToPrint, setOrdersToPrint] = useState<any[]>([]);

  useEffect(() => {
    const { id, template } = match.params;
    const arrayIds = (id as string).split(',').map((item) => parseInt(item, 10));

    fetchDataTableRows({
      name: 'orders',
      params: {
        order_ids: arrayIds,
        expand: [
          'pharmacy',
          'items',
          'items.product',
          'items.product.distributor_products',
          'invoice',
          'distributor',
          'pharmacy.virtual_accounts',
          'pharmacy.contacts',
        ],
        page: 1,
        page_size: arrayIds.length,
        order_by: 'pharmacy.name',
        sort_by: 'ASC',
      },
    });

    fetchMarketConfig({});

    window.onafterprint = () => {
      if (template === TemplateOptions.PRINT_DELIVERY_DOCS) {
        markOrderDeliveryDocsAsPrinted({ ids: arrayIds });
      }
    };

    return () => {
      window.onafterprint = null;
    };
  }, [fetchDataTableRows, fetchMarketConfig, markOrderDeliveryDocsAsPrinted, match.params]);

  useEffect(() => {
    if (orders && orders.rows) {
      setIsLoadingPage(false);
      setOrdersToPrint(orders.rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  const renderTemplate = (): JSX.Element[] | null => {
    const { template } = match.params;

    if (!ordersToPrint.length) return null;

    switch (template) {
      case TemplateOptions.PRINT_ORDER:
        return ordersToPrint.map((order, index) => (
          <SingleOrderData
            key={order.id}
            order={order as Order}
            paperSize={PAPERSIZE.A4}
            isLastOrder={index + 1 === ordersToPrint.length}
          />
        ));
      case TemplateOptions.PRINT_DELIVERY_DOCS:
      case TemplateOptions.PRINT_INVOICE_DOCS:
      case TemplateOptions.PRINT_DELIVERY_PROOF:
        if (marketId === 'th') {
          return [<DeliveryProofV2 orders={ordersToPrint as Order[]} config={config} {...props} />];
        }
        return ordersToPrint.map((order) => {
          return <DeliveryProofV0 key={order.id} order={order as Order} config={config} {...props} />;
        });
      default:
        return null;
    }
  };

  return <>{!isLoadingPage && renderTemplate()}</>;
};
