/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Button, Container, FormControl, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { HeaderLabel } from 'pages/distributor/components/summary-tab/logistics-sla-config.style';
import { BadgeStyles, DocumentViewer } from 'common/components';
import { useDispatch, useGlobal } from 'reactn';
import { formatToDatetime, getOrderElapsedTimeElement } from 'common/utils';
import { Scrollbars } from 'react-custom-scrollbars';
import { OrderPrecursorStatus } from 'pages/transactions/types';
import { IPrecursorStatusHistory } from 'common/reducers/precursor/interfaces/PrecursorState.interface';
import { UnavailableViewer } from 'common/components/DocumentViewer/UnavailableViewer';
import { PrecursorDetailTable, PrecursorReasonCheckbox, PrecursorReasonContainer } from './PrecursorDetailPage.style';
import {
  OrderDetailsResponse,
  PrecursorApproveReason,
  PrecursorCancelReason,
  PrecursorRejectReason,
  PrecursorRejectReasonObject,
} from './PrecursorDetail.type';
import { ImagePreview } from './ImagePreview.component';
import { PrecursorCancelReasonEnglishText, PrecursorRejectReasonEnglishText } from './PrecursorDetail.constant';

const PrecursorApproveReasonDefaultValuesToTrue = Object.assign(
  {},
  ...Object.values(PrecursorApproveReason).map((r) => ({ [r]: true })),
);

const PrecursorApproveReasonDefaultValuesToFalse = Object.assign(
  {},
  ...Object.values(PrecursorApproveReason).map((r) => ({ [r]: false })),
);
export const PrecursorApproveReasonEnglishText = {
  [PrecursorApproveReason.DOCUMENT_CLEAR]: 'APJ Pharmacy Data, Customer Data, PBF Address Filled Correctly',
  [PrecursorApproveReason.CORRECT_CORE_MOLECULES]: 'Item Names, Core Molecules, Dosage Form, QTY, UOM Filled Correctly',
  [PrecursorApproveReason.SIGNATURE_MATCH]: "Signatures & Pharmacy's Stamp Match, SP Filled Correctly",
};
interface Props {
  setApproveButtonDisabled: (check: boolean) => void;
  setRejectButtonDisabled: (check: boolean) => void;
  orderDetails?: OrderDetailsResponse;
  precursorStatusHistory?: IPrecursorStatusHistory[];
}

const DocumentInformationPage: React.FC<Props> = (props: Props) => {
  const { orderDetails, setApproveButtonDisabled, setRejectButtonDisabled, precursorStatusHistory } = props;
  const fetchSignedUrlByPharmacyIdAndShorts: any = useDispatch('fetchSignedUrlByPharmacyIdAndShorts');
  const setSignedUrls: any = useDispatch('setSignedUrls');
  const [{ signedUrls }] = useGlobal('utils');
  const { labelElement, labelType } = getOrderElapsedTimeElement(
    orderDetails?.data?.status,
    orderDetails?.data?.status_history ?? {},
    orderDetails?.data?.ordered_at ?? '',
    orderDetails?.data?.payment_method ?? null,
  );
  useEffect(() => {
    if (orderDetails && orderDetails.data)
      fetchSignedUrlByPharmacyIdAndShorts({
        pharmacy_id: orderDetails.data.pharmacy_id,
        document_management_type_short: ['APJ', 'SPC'],
      });
  }, [fetchSignedUrlByPharmacyIdAndShorts, orderDetails]);

  useEffect(() => {
    return (): any => setSignedUrls({ data: {} });
  }, [setSignedUrls]);
  const [checked, setChecked] = useState<Record<PrecursorApproveReason, boolean>>(
    PrecursorApproveReasonDefaultValuesToFalse,
  );

  const [checkListDisabled, setCheckListDisabled] = useState<Record<PrecursorApproveReason, boolean>>(
    PrecursorApproveReasonDefaultValuesToFalse,
  );

  useEffect(() => {
    if (!orderDetails?.data?.precursor_status) return;
    const precursorStatus = orderDetails.data.precursor_status;
    const { status } = precursorStatus;
    const approveButtonDisabled = Object.values(checked).includes(false);
    const waitingApproval = [
      OrderPrecursorStatus.DOCUMENT_REQUIRED,
      OrderPrecursorStatus.DOCUMENT_WAITING_APPROVAL,
      OrderPrecursorStatus.DOCUMENT_RESUBMITTED,
    ];

    if (waitingApproval.includes(status)) {
      if (!approveButtonDisabled) {
        setApproveButtonDisabled(false);
        setRejectButtonDisabled(true);
      } else {
        setApproveButtonDisabled(true);
        setRejectButtonDisabled(false);
      }
    } else {
      setApproveButtonDisabled(true);
      setRejectButtonDisabled(true);
    }

    if (precursorStatus.status === OrderPrecursorStatus.DOCUMENT_APPROVED) {
      setChecked(PrecursorApproveReasonDefaultValuesToTrue);
      setCheckListDisabled(PrecursorApproveReasonDefaultValuesToTrue);
    } else if (precursorStatus.status === OrderPrecursorStatus.DOCUMENT_REJECTED || !precursorStatus.document_url) {
      setChecked(PrecursorApproveReasonDefaultValuesToFalse);
      setCheckListDisabled(PrecursorApproveReasonDefaultValuesToTrue);
    }
  }, [checked, orderDetails, setApproveButtonDisabled, setRejectButtonDisabled]);

  const latestSubmittedDocumentrl =
    precursorStatusHistory && precursorStatusHistory.length > 0
      ? precursorStatusHistory.find((value) => value.status === OrderPrecursorStatus.DOCUMENT_REJECTED)?.document_url
      : '';
  return (
    <Container maxWidth="xl">
      <PrecursorReasonContainer maxWidth={false}>
        <HeaderLabel>Checklist</HeaderLabel>
        <FormControl style={{ position: 'relative', height: '110px', width: '100%' }} component="fieldset">
          <Grid container>
            {Object.values(PrecursorApproveReason).map((value: PrecursorApproveReason, index: number) => {
              return (
                <Grid item style={{}} xs={12}>
                  <FormControlLabel
                    // eslint-disable-next-line react/no-array-index-key
                    key={`precursor-detail-${index}`}
                    value={`${index}`}
                    control={
                      <PrecursorReasonCheckbox
                        size="small"
                        disabled={checkListDisabled[value]}
                        checked={checked[value]}
                      />
                    }
                    label={
                      <Typography style={{ whiteSpace: 'nowrap', fontSize: '12px !important' }}>
                        {PrecursorApproveReasonEnglishText[value]}
                      </Typography>
                    }
                    style={{ marginTop: -5, marginBottom: -5 }}
                    onClick={() => {
                      setChecked({
                        ...checked,
                        [value]: !checked[value],
                      });
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </FormControl>
      </PrecursorReasonContainer>
      <Grid spacing={2} style={{ padding: '20px', borderBottom: '1px solid grey' }} container direction="row">
        <Grid item>
          <Grid container direction="column">
            <Grid item style={{ fontWeight: 'bold' }}>
              PO Number
            </Grid>
            <Grid item>{orderDetails?.data?.po_number}</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item style={{ fontWeight: 'bold' }}>
              Order Date
            </Grid>
            <Grid item>
              {orderDetails && orderDetails.data?.ordered_at ? formatToDatetime(orderDetails.data?.ordered_at) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item style={{ fontWeight: 'bold' }}>
              SIPA Number
            </Grid>
            <Grid item style={{ whiteSpace: 'normal', maxWidth: '250px', wordWrap: 'break-word' }}>
              {orderDetails?.data?.pharmacy.crm?.values?.swiperx_pt_id_account?.SIPA_Number}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item style={{ fontWeight: 'bold' }}>
              SIA Code
            </Grid>
            <Grid style={{ maxWidth: '250px', whiteSpace: 'normal', wordWrap: 'break-word' }} item>
              {orderDetails?.data?.pharmacy.crm?.values?.swiperx_pt_id_account?.SIA_Number}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column">
            <Grid item style={{ fontWeight: 'bold' }}>
              Rejection Reason
            </Grid>
            <Grid style={{ maxWidth: '250px', whiteSpace: 'normal', wordWrap: 'break-word' }} item>
              {orderDetails?.data?.precursor_status?.reason && orderDetails?.data?.precursor_status?.reason.length > 0
                ? orderDetails?.data?.precursor_status?.reason
                    ?.map((reason: PrecursorRejectReasonObject) => {
                      if (Object.values(PrecursorCancelReason).includes(reason.name as PrecursorCancelReason))
                        return PrecursorCancelReasonEnglishText[reason.name as PrecursorCancelReason];

                      const additionalText = reason.text ? ` - ${reason.text}` : '';
                      return PrecursorRejectReasonEnglishText[reason.name as PrecursorRejectReason] + additionalText;
                    })
                    .join(', ')
                : orderDetails?.data?.precursor_status?.description}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container style={{ textAlign: 'right', fontSize: '14px' }} direction="column">
            <Grid item>
              <BadgeStyles.Primary color={labelType} style={{ marginRight: 0 }}>
                {labelElement}
              </BadgeStyles.Primary>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" style={{ padding: '20px', minHeight: '400px' }}>
        <Grid item xs={6} style={{ padding: '20px', borderRight: '1px solid grey' }}>
          <Grid item>
            <Typography style={{ paddingBottom: '20px' }}>Pharmacy Precursor</Typography>
            {orderDetails && orderDetails.data?.precursor_status?.document_url ? (
              <>
                <Button
                  style={{ marginLeft: 'auto', marginRight: '0', float: 'right', width: '150px' }}
                  onClick={() => {
                    if (orderDetails.data?.precursor_status?.status !== OrderPrecursorStatus.DOCUMENT_APPROVED) {
                      return;
                    }
                    const anchor = document.createElement('a');
                    anchor.href = orderDetails.data?.precursor_status?.document_url || '';
                    anchor.download = `SP_${orderDetails.data?.pharmacy_id}_${orderDetails.data?.po_number}.pdf`;
                    anchor.target = '_blank';
                    anchor.click();
                  }}
                  disabled={orderDetails.data?.precursor_status?.status !== OrderPrecursorStatus.DOCUMENT_APPROVED}
                >
                  <div
                    style={{
                      backgroundColor:
                        orderDetails.data?.precursor_status?.status !== OrderPrecursorStatus.DOCUMENT_APPROVED
                          ? '#D7D7D7'
                          : '#ffcc66',
                      borderRadius: '5px',
                      width: '100%',
                      height: '100%',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    Download
                  </div>
                </Button>
                <ImagePreview
                  imageHeight="100%"
                  imageWidth="100%"
                  imageSource={orderDetails.data?.precursor_status?.document_url}
                  isPdf={orderDetails.data?.precursor_status?.document_url.endsWith('.pdf')}
                />
              </>
            ) : (
              <UnavailableViewer />
            )}
            <Container style={{ paddingTop: '20px' }}>
              {latestSubmittedDocumentrl &&
              latestSubmittedDocumentrl !== orderDetails?.data?.precursor_status?.document_url ? (
                <DocumentViewer height="100%" url={latestSubmittedDocumentrl} />
              ) : (
                <></>
              )}
            </Container>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ padding: '20px' }}>
          <Scrollbars>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography style={{ paddingBottom: '20px' }}>Pharmacy APJ Signature and Stamp</Typography>
                {signedUrls && signedUrls.SPC ? (
                  <ImagePreview imageHeight="100%" imageWidth="100%" imageSource={signedUrls.SPC} />
                ) : (
                  <UnavailableViewer />
                )}
                <Typography style={{ padding: '20px 0px 20px 0px' }}>Pharmacy KTP</Typography>
                {signedUrls && signedUrls.APJ ? (
                  <ImagePreview imageHeight="100%" imageWidth="100%" imageSource={signedUrls.APJ} />
                ) : (
                  <UnavailableViewer />
                )}
                <Typography style={{ padding: '20px 0px 20px 0px' }}>Order Details</Typography>
                <PrecursorDetailTable>
                  <thead>
                    <tr className="order-items">
                      <th>SKU ID</th>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>UOM</th>
                      <th>Active Molecule</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDetails &&
                      orderDetails.data &&
                      orderDetails.data.items.map((data, index) => {
                        return (
                          <tr>
                            <td>{data.product?.sku_code}</td>
                            <td>{data.product?.name}</td>
                            <td>{orderDetails.data?.items[index].quantity}</td>
                            <td>{data.product?.package}</td>
                            <td>{data.product?.core_molecule}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </PrecursorDetailTable>
              </Grid>
            </Grid>
          </Scrollbars>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DocumentInformationPage;
