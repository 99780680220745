import { addReducer } from 'reactn';
import { recordException } from 'utils/Reporting/Sentry';

import { assemblePaginationParams } from './utils';

export const DATA_TABLES_INITIAL_STATE = {};

addReducer('toggleDataTableLoadStatus', (state, _, { name }) => {
  const { dataTables } = state;
  const dataTable = dataTables[name] || {};

  return {
    ...state,
    dataTables: {
      ...dataTables,
      [name]: {
        ...dataTable,
        loading: !dataTable.loading,
      },
    },
  };
});

addReducer('setDataTableSelectAllStatus', (state, _, { name, selectAll }) => {
  const { dataTables, transactions } = state;
  const dataTable = dataTables[name] || {};
  return {
    ...state,
    dataTables: {
      ...dataTables,
      [name]: {
        ...dataTable,
        isAllSelected: selectAll,
        params: { ...dataTable.params, total_count: transactions.totalRowCount },
      },
    },
  };
});

addReducer('setDataTableRows', (state, _, { name, params, rows }) => {
  const { dataTables } = state;
  const dataTable = dataTables[name] || {};

  return {
    ...state,
    dataTables: {
      ...dataTables,
      [name]: {
        ...dataTable,
        params: {
          ...dataTable.params,
          ...params,
        },
        rows,
        error: null,
        loading: false,
      },
    },
  };
});

addReducer('setDataTableError', (state, _, { name, error }) => {
  const { dataTables } = state;
  const dataTable = dataTables[name] || {};

  return {
    ...state,
    dataTables: {
      ...dataTables,
      [name]: {
        ...dataTable,
        error,
        loading: false,
      },
    },
  };
});

addReducer('resetDataTable', (state, _, { name }) => {
  const { dataTables } = state;

  return {
    ...state,
    dataTables: {
      ...dataTables,
      [name]: undefined,
    },
  };
});

addReducer('fetchDataTableRows', async ({ dataTables }, dispatch, { name, params, cancelPendingRequest }) => {
  try {
    dispatch.toggleDataTableLoadStatus({ name });

    const { params: currentParams = {} } = dataTables[name] || {};

    const finalParams = assemblePaginationParams(currentParams, params);

    switch (name) {
      case 'orders':
        dispatch.fetchOrderDataTableRows({ params: finalParams, cancelPendingRequest });
        return;
      case 'document-management':
        dispatch.fetchDocumentManagementDatatable({ params: finalParams });
        return;
      case 'document-management-pharmacy':
        dispatch.fetchDocumentManagementPharmacyDatatable({ params: finalParams });
        return;
      case 'document-management-pharmacy-history':
        dispatch.fetchDocumentManagementPharmacyHistoryDatatable({ params: finalParams });
        return;
      case 'finance':
        dispatch.fetchFinanceDatatable({ params: finalParams });
        return;
      case 'logistics-sla-calendar':
        dispatch.fetchCalendarDatatable({ params: finalParams });
        return;
      case 'voucher-type':
        dispatch.fetchVoucherTypeDatatable({ params: finalParams });
        return;
      case 'voucher':
        dispatch.fetchVoucherDatatable({ params: finalParams });
        return;
      case 'voucher-redemption-history':
        dispatch.fetchRedemptionHistory({ params: finalParams });
        return;
      case 'deposit-transactions':
        dispatch.fetchDepositTransactionDatatable({ params: finalParams });
        return;
      case 'credit-limit-requests':
        dispatch.fetchCreditLimitRequestDatatable({ params: finalParams });
        return;
      case 'return-management':
        dispatch.fetchReturnDataTable({ params: finalParams });
        return;
      default:
        throw new Error(`Data table "${name} is not supported yet.`);
    }
  } catch (e) {
    if (e instanceof Error) {
      recordException(e, 'fetchDataTableRows', { name, params });
      dispatch.setDataTableError({ name, error: e.message });
    } else {
      throw e;
    }
  }
});
