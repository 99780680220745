import { css } from 'styled-components';

export const wrapperStyle = css`
  background: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.18), 0px 0px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 3px;
  height: 28px;
  font-size: 13px;
  font-weight: bold;
  padding-left: 2px;
  padding-right: 2px;
`;
