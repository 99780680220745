import pharmaciesService from '../services/apis/v2/services/pharmacies.service';

export default {
  state: {
    pharmacies: {
      result: [],
      page: 0,
      itemsPerPage: 0,
      total: 0,
    },
    pharmacy: {},
    organization: {},
    summary: {},
    history: {
      result: [],
      page: 0,
      itemsPerPage: 0,
      total: 0,
    },
    invoices: {
      result: [],
      page: 0,
      itemsPerPage: 0,
      total: 0,
      sort: 'invoiced_at',
      order: 'asc',
      search: {},
    },
    virtualAccounts: [],
    selectedInvoices: [],
    message: 'No invoices selected.',
  },
  reducers: {
    setPharmacies(state, payload) {
      return { ...state, pharmacies: payload };
    },
    setPharmacy(state, payload) {
      return { ...state, pharmacy: payload };
    },
    setSummary(state, payload) {
      return { ...state, summary: payload };
    },
    setHistory(state, payload) {
      return { ...state, history: payload };
    },
    setInvoices(state, payload) {
      return { ...state, invoices: payload };
    },
    setSelectedInvoices(state, payload) {
      return { ...state, selectedInvoices: payload };
    },
    setInvoicesMessage(state, payload) {
      return { ...state, message: payload };
    },
    setVirtualAccounts(state, payload) {
      return { ...state, virtualAccounts: payload };
    },
  },
  effects: (dispatch) => ({
    async getPharmacyList({
      type = 'pharmacy',
      limit = 10,
      page = 1,
      sort_by = 'asc',
      order_by = 'created_at',
      query = '',
    }) {
      const totalList = await pharmaciesService.getList(type, limit, page, sort_by, order_by, query);
      dispatch.pharmacyStore.setPharmacies({
        result: totalList.result,
        page,
        itemsPerPage: limit,
        total: totalList.total,
      });
    },

    async getPharmacy(id) {
      if (id === null) {
        dispatch.pharmacyStore.setPharmacy({});
      } else {
        const [pharmacy, organization] = await Promise.all([
          pharmaciesService.getOne(id),
          pharmaciesService.getOrganizationByID(id),
        ]);

        if (pharmacy) {
          const { logisticsAreaPharmacy, ...restPharmacy } = pharmacy;

          dispatch.pharmacyStore.setPharmacy({ ...restPharmacy, logisticsAreaPharmacy, organization : {
            ...organization,
            digital_signature_disabled: organization?.digital_signature_disabled ?? true,
            digital_signature_disabled_reason: organization?.digital_signature_disabled_reason ?? ' ',
          }
           });
        } else {
          dispatch.pharmacyStore.setPharmacy({});
        }

        // Reset selected invoices on pharmacy change
        dispatch.pharmacyStore.setSelectedInvoices([]);
      }
    },

    async getCreditSummary(id) {
      if (!id) {
        dispatch.pharmacyStore.setSummary({});
      } else {
        const payload = await pharmaciesService.getCreditSummaryById(id);
        const data = !payload ? {} : payload;
        dispatch.pharmacyStore.setSummary(data);
      }
    },

    async getCreditHistory({ id, limit, page, sort_by, order_by }) {
      if (!id) {
        dispatch.pharmacyStore.setHistory({
          result: [],
          page: 0,
          itemsPerPage: 0,
          total: 0,
        });
      } else {
        const payload = await pharmaciesService.getCreditHistoryById(id, limit, page, sort_by, order_by);
        const setData = {
          result: payload.result,
          page: payload.total && payload.total.page ? payload.total.page : 0,
          itemsPerPage: limit,
          total: payload.total && payload.total.total_count ? payload.total.total_count : 0,
        };

        dispatch.pharmacyStore.setHistory(setData);
      }
    },

    async getVirtualAccounts(id) {
      if(!id || Number.isNaN(id)){
        return;
      }

      const payload = await pharmaciesService.getVirtualAccountsByPharmacyId(id);
      dispatch.pharmacyStore.setVirtualAccounts(payload);
    },

    async getInvoicesById({ id, limit, page, sort_by, order_by, search }) {
      if (!id) {
        dispatch.pharmacyStore.setInvoices({
          result: [],
          page: 0,
          itemsPerPage: 0,
          total: 0,
          order_by: 'invoiced_at',
          sort_by: 'asc',
          search: {},
        });
      } else {
        const payload = await pharmaciesService.getInvoicesById(
          id,
          limit,
          page,
          order_by === 'due_date' ? 'invoiced_at' : order_by,
          sort_by,
          search,
        );
        const setData = {
          result: payload.result,
          page: payload.page ? payload.page : 0,
          itemsPerPage: limit,
          total: payload.total ? payload.total : 0,
          order_by,
          sort_by,
          search,
        };
        dispatch.pharmacyStore.setInvoices(setData);
      }
    },

    async getInvoicesMessage(payload, rootState) {
      if (!rootState.pharmacyStore.pharmacy.id) {
        dispatch.pharmacyStore.setInvoicesMessage({
          result: 'Invoice Message 404',
        });
      } else {
        const invoiceIds = rootState.pharmacyStore.selectedInvoices.map((invoice) => invoice.id);
        // eslint-disable-next-line no-param-reassign
        payload = await pharmaciesService.getInvoicesMessagesByInvoiceIds(
          rootState.pharmacyStore.pharmacy.id,
          invoiceIds,
        );
        const setData = payload.result.message;
        dispatch.pharmacyStore.setInvoicesMessage(setData);
      }
    },
    async syncCRMData(organizationId) {
      await pharmaciesService.syncCRMData(organizationId);
    },
  }),
};
