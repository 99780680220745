/* eslint-disable no-param-reassign */

import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { ProductCategoryList } from './interfaces/ProductCategoryList.interface';

export class SwipeRxPtProductCategories {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.PRODUCTS_CATEGORIES;
  }

  async fetchProductCategories(params: any): Promise<ProductCategoryList> {
    const result = await this.base.get(`${this.resource}/`, params);

    return result;
  }
}
