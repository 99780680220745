import { DocumentManagementUploadData } from 'common/reducers/document-management/types';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';

export class SwipeRxPtDocumentManagement {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.DOCUMENT_MANAGEMENT;
  }

  async scanDocument(payload: DocumentManagementUploadData): Promise<any> {
    return this.base.post(`${this.resource}/scan-document`, payload);
  }

  async uploadDocument(payload: DocumentManagementUploadData): Promise<any> {
    return this.base.post(`${this.resource}/upload-document`, payload);
  }

  async list(params: any): Promise<any> {
    return this.base.get(`${this.resource}`, params);
  }

  async deleteById(id: number): Promise<any> {
    return this.base.delete(`${this.resource}/${id}`);
  }

  async uploadStatus() : Promise<any> {
    return this.base.get(`${this.resource}/upload-status`);
  }

  async uploadDone() : Promise<any> {
    return this.base.post(`${this.resource}/upload-done`, {});
  }

  async replaceDoment(
    id: number, 
    payload: { file: string, filename: string }
  ): Promise<any> {
    return this.base.patch(`${this.resource}/${id}/replace-document`, payload)
  }

  async reassignDocument(
      id: number, 
      payload: { po_number: string }
  ): Promise<any> {
    return this.base.patch(`${this.resource}/${id}/reassign-document`, payload)
  }

  async assignDocument(
      id: number, 
      payload: { 
          distributor_id: number,
          invoice_number: string,
          document_type: string,
      }
  ): Promise<any> {
    return this.base.patch(`${this.resource}/${id}/assign-document`, payload)
  }

  async getUnassignedDocuments(
      params: {
          distributor_id: number | null,
      }
  ): Promise<any> {
    return this.base.get(`${this.resource}/unassigned`, params);
  }

  async getDistributorByInvoiceId(invoiceNumber: string): Promise<any> {
    return this.base.get(`${this.resource}/distributor?invoice_number=${invoiceNumber}`);
  }
}
