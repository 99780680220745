import numeral from 'numeral';

export const toCurrency = (value: number, format='$ (0,0.00)'): string => {
  return numeral(value).format(format);
};

export const toTruncatedCurrency = (value: number): string => {
  // Truncate if the value is greater than a trillion
  if (value > 1000000000000) return numeral(value).format('$ 0,0.[0a]');
  return numeral(value).format('($ 0,0.00)');
};

export const toTruncatedCurrencyWithoutLabel = (value: number): string => {
  // Truncate if the value is greater than a trillion
  if (value > 1000000000000) return numeral(value).format('0,0.[0a]');
  return numeral(value).format('(0,0.00)');
};

export default toCurrency;
