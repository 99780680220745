import axios, { AxiosInstance } from 'axios';
import { removeTrailingSlash } from '../../../../../utils/url.util';

const { REACT_APP_API_KEY } = process.env;

export type PtAPIVersions = 'v3';

export interface ProcurementAxiosParams {
  baseUrl: string;
  warpSessionToken?: string;
  marketId: string;
  version: PtAPIVersions;
}

export class ProcurementAxios {
  private readonly apiServerAxios: AxiosInstance;

  private baseUrl: string;

  private marketId: string;

  private version: PtAPIVersions;

  private warpSessionToken?: string;

  constructor(params: ProcurementAxiosParams) {
    this.baseUrl = params.baseUrl;
    this.marketId = params.marketId;
    this.version = params.version;
    this.warpSessionToken = params.warpSessionToken;

    this.apiServerAxios = axios.create({
      baseURL: this.getUrl(),
    });

    this.apiServerAxios.interceptors.request.use(
      (config) => {
        const token =
          this.warpSessionToken || ProcurementAxios.getSessionToken();
        const apiKey = REACT_APP_API_KEY;

        // eslint-disable-next-line no-param-reassign
        config.headers['x-warp-session-token'] = token;

        // eslint-disable-next-line no-param-reassign
        config.headers['x-warp-api-key'] = apiKey;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    this.apiServerAxios.interceptors.response = axios.interceptors.response;
  }

  private getUrl(): string {
    let url = removeTrailingSlash(this.baseUrl);

    if (this.version) {
      url = `${url}/${this.version}`;
    }

    url = `${url}/${this.marketId}`;

    return url;
  }

  static getSessionToken(): string {
    if (localStorage.getItem('PT-ROLE-DATA')) {
      const { session_token } = JSON.parse(
        localStorage.getItem('PT-ROLE-DATA') as string,
      );
      return session_token;
    }
    return '';
  }

  get apiServer(): AxiosInstance {
    return this.apiServerAxios;
  }
}
