import { addReducer } from 'reactn';
import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';

import { DEFAULT_LOCALE, DEFAULT_LANGUAGE } from 'utils/locale';
import { recordException } from 'utils/Reporting/Sentry';
import { UserRoles, VOUCHER_VIEWER_ROLES, TRANSACTION_ACCESS_ROLES } from 'common/constants';
import { ConfigState } from './interfaces';

export const CONFIG_INITIAL_STATE: ConfigState = {
  marketId: null,
  market: {
    language: DEFAULT_LANGUAGE,
    locale: DEFAULT_LOCALE,
    activations: [],
    contactNumber: null,
    regionalOffice: null,
    templates: {
      distributor_product: {
        headers: [],
        samples: [],
      },
      distributor_product_availability: {
        headers: [],
        samples: [],
      },
      tier_pricing: {
        headers: [],
        samples: [],
      },
      pbf_assignments: {
        headers: [],
        samples: [],
      },
      pharmacies: {
        headers: [],
        samples: [],
      },
    },
    modules: {
      'pbf-assignment': {
        'view-module': [UserRoles.SUPER_ADMIN, UserRoles.SALES_SUPPORT, UserRoles.PHARMACIST],
        'view-overview': [UserRoles.SUPER_ADMIN, UserRoles.SALES_SUPPORT, UserRoles.PHARMACIST],
        'view-file-import': [UserRoles.SUPER_ADMIN, UserRoles.SALES_SUPPORT, UserRoles.PHARMACIST],
      },
      'pharmacy-users': {
        'view-module': [
          UserRoles.SUPER_ADMIN,
          UserRoles.SALES_SUPPORT,
          UserRoles.CUSTOMER_SUPPORT,
          UserRoles.PHARMACIST,
          UserRoles.SALES_SUPERVISOR,
        ],
      },
      pharmacies: {
        'view-module': [
          UserRoles.SUPER_ADMIN,
          UserRoles.SALES_SUPPORT,
          UserRoles.CUSTOMER_SUPPORT,
          UserRoles.PHARMACIST,
          UserRoles.SALES_SUPERVISOR,
        ],
      },
      voucher: {
        'view-module': (enabled) => (enabled ? VOUCHER_VIEWER_ROLES : [UserRoles.SUPER_ADMIN, UserRoles.PHARMACIST]),
      },
      transactions: {
        'view-module': TRANSACTION_ACCESS_ROLES,
      },
    },
    requirements: {
      product: [],
    },
    documentPharmacyUpload: {
      maxFiles: 50,
      maxSizeInMB: 1,
    },
    documentManagement: {
      defaultLimitDays: 30,
    },
  },
  paymentMethod: {
    directPayment: {
      active: false,
      provider: null,
      billExpiration: null,
      tz: null,
    },
    credit: {
      active: false,
      hideMinMaxBill: null,
      internal: { min: 0, max: 0, active: false },
      provider: null,
    },
    monetary: {
      taxRate: null,
    },
    voucher: {
      enabled: false,
    },
  },
};

addReducer('fetchMarketConfig', async (state) => {
  try {
    const { data } = await SwipeRxPt.instance.market.getConfig();

    return {
      ...state,
      config: {
        ...state.config,
        ...data,
        market: {
          ...state.config.market,
          ...data.market,
        },
      },
    };
  } catch (error) {
    recordException(error, 'fetchMarketConfig');
    return {
      ...state,
      error: error.message,
    };
  }
});
