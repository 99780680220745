import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const setSentryUser = (): void => {
  try {
    const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
    if (!SENTRY_DSN || SENTRY_DSN === 'disabled') return;

    const data = localStorage.getItem('PT-ROLE-DATA');
    if (!data) return;

    const user = JSON.parse(data);
    if (!user) return;

    const { id, username } = user;
    Sentry.setUser({ id, email: username, username });
  } catch (e) {
    /* eslint-disable no-console */
    console.error('Failed to set sentry user', e);
  }
};

export const initialiseReporting = (): void => {
  try {
    const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
    if (!SENTRY_DSN || SENTRY_DSN === 'disabled') return;

    const appVersion = process.env.REACT_APP_VERSION;
    /* eslint-disable no-restricted-globals */
    const environment = location.hostname;
    const traceRate = process.env.REACT_APP_SENTRY_TRACE_RATE as string;

    Sentry.init({
      dsn: SENTRY_DSN,
      release: `${appVersion}`,
      integrations: [new Integrations.BrowserTracing()],
      environment,
      tracesSampleRate: parseFloat(traceRate),
      normalizeDepth: 3, // redux state depth
    });

    setSentryUser();
  } catch (error) {
    /* eslint-disable no-console */
    console.error('Failed to initialise sentry', error);
  }
};

export const recordException = (error: Error, method: string, extra?: Record<string, any>): void => {
  try {
    const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
    if (!SENTRY_DSN || SENTRY_DSN === 'disabled') return;
    Sentry.captureException(error, { tags: { method }, extra });
  } catch (e) {
    /* eslint-disable no-console */
    console.error('Failed to send exception to sentry', e);
  }
  
};
