const defaultStyle = {
  option: (provided: React.CSSProperties) => ({
    ...provided,
    padding: '12px 20px',
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
    borderRadius: 0,
    '&:not(:last-child)': {
      borderBottom: '1px solid #ececec',
    },
  }),
  control: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 14,
    padding: '13px 10px',
    cursor: 'text',
    border: '1px solid #C6C6C6',
    borderRadius: 4,
    boxShadow: 'none',
    '&:focus-within': {
      border: '1px solid #C6C6C6',
    },
  }),
  input: (provided: React.CSSProperties) => ({
    ...provided,
    borderRadius: 0,
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
  }),
  placeholder: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
    color: 'rgb(179, 179, 179)',
  }),
  noOptionsMessage: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  loadingIndicator: () => ({
    display: 'none',
  }),
};

export const multiSelectStyle = {
  ...defaultStyle,
  option: (provided: React.CSSProperties) => ({
    ...provided,
    padding: '12px 20px',
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
    '&:not(:last-child)': {
      borderBottom: '1px solid #ececec',
    },
  }),
  control: (provided: React.CSSProperties, props: any) => ({
      ...provided,
      padding: '5px 5px',
      cursor: 'text',
      borderRadius: 4,
      boxShadow: 'none',
      border: props.selectProps.border || '1px solid hsl(0,0%,80%)',
      '&:hover': {
        backgroundColor: '#ececec',
        transition: 'ease-in 0.3s',
        border: '1px solid #269781',
      },
    }),
  input: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 16,
    fontFamily: 'Nunito Sans, sans-serif',
  }),
  placeholder: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 16,
    fontFamily: 'Nunito Sans, sans-serif',
    color: '#c1c1c1',
  }),
  dropdownIndicator: () => ({
    display: 'inline-block',
    color: 'lightgray',
    padding: '5px 8px 2px 8px',
  }),
  multiValue: (provided: React.CSSProperties) => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.26)',
    borderRadius: '16px',
  })
}