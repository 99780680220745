import { CSVUploadResult } from 'common/components/CsvUpload';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';

export class SwipeRxPtCrm {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.CRM;
  }

  cancelRequest(): void {
    this.base.cancelRequest();
  }

  async cancel(payload: { order_ids: number[]; reason: string; date: string }): Promise<void> {
    await this.base.post(`${this.resource}/cancel`, payload);
  }

  async getInternalCrm(orgId: number, params: { build_calculations: boolean}): Promise<any> {
    const { data } = await this.base.get(`${this.resource}/internal/${orgId}`, params);
    return data;
  }

  async patchInternalCrm(orgId: number, internalCrmReq: any): Promise<any> {
    const { data } = await this.base.patch(`${this.resource}/internal/${orgId}/update`, { ...internalCrmReq });
    return data;
  }

  async crmSyncData(orgId: number): Promise<void> {
    const payload = await this.base.post(`${this.resource}/external/${orgId}`, null);

    return payload;
  }

  async getCrmConfig(): Promise<any> {
    const payload = await this.base.get(`${this.resource}/internal/config`);

    return payload;
  }

  async uploadCsv(
    file: File,
  ): Promise<{
    data: CSVUploadResult;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );
    return this.base.post(`${this.resource}/internal/upload`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }
  
  async uploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`${this.resource}/internal/upload/history`, {
      page,
      page_size,
    });
  }  
}
