/* eslint-disable */
import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  InputAdornment,
  withStyles,
  IconButton,
  Divider,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { connect } from 'react-redux';

import StatusCard from '../../components/StatusCard';
import LoadOverlay from '../../components/LoaderOverlay';
import history from '../../history';
import { getMarketPath } from '../../../utils/get-router-path';
import { DEFAULT_MARKET_ID } from '../../services/constants';

const styles = (theme: {
  palette: { primary: { main: any } };
  spacing: (arg0: number) => any;
}) => ({
  grid: {
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
  },
  cardContent: {
    padding: theme.spacing(3.5),
    marginBottom: theme.spacing(3.5),
  },
  cardActionsSpacing: {
    padding: theme.spacing(3.5),
    backgroundColor: grey[200],
  },
});

const logo = {
  src: '/logo.png',
  alt: 'Logo',
};

const logoStyle = {
  width: '100%',
  marginBottom: 32,
};

type Props = any;
type State = {
  email: string;
  password: string;
  showPassword: boolean;
  error: { message?: string; password?: string; email?: string };
  loggedIn: boolean;
};

class LoginPage extends React.Component<Props, State> {
  state: Readonly<State> = {
    email: '',
    password: '',
    showPassword: false,
    error: {},
    loggedIn: false,
  };

  componentDidMount() {
    const {
      authStore,
      match: {
        params: { marketId },
      },
    } = this.props;
    if (authStore.data.session_token) {
      history.push(getMarketPath(marketId));
    }
  }

  _onInputChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value } as State);
  };

  _onSubmit = async (e: { preventDefault: () => void }) => {
    const { authenticate, location, match } = this.props;
    e.preventDefault();
    const { email, password } = this.state;
    // BASIC VALIDATION
    if (email.length === 0) {
      this.setState({ error: { email: 'Please enter your email address' } });
    } else if (password.length === 0) {
      this.setState({ error: { password: 'Please enter your password' } });
    } else {
      const response = await authenticate({
        username: email,
        password,
      });

      if (response.id === -1) {
        if (response.error) {
          this.setState({ error: { message: response.error } });
        } else {
          this.setState({ error: { message: 'Login failed.' } });
        }
        // LOGIN FAILED
      } else {
        // LOGIN SUCCESS
        console.log('login success');
        const { marketId } = match.params;
        const prefix = getMarketPath(marketId);
        let pushHist = `${prefix}/login`;

        if (location.state) {
          if (
            location.state.referrer === `${prefix}/login` ||
            location.state.referrer === `/`
          ) {
            pushHist = `${prefix}`;
          } else {
            pushHist = location.state.referrer;
          }
        }
        this.setState({ error: {}, loggedIn: true });
        history.push(pushHist);
      }
    }
  };

  _handleChange = (prop: any) => (event: { target: { value: any } }) => {
    this.setState({ [prop]: event.target.value } as State);
  };

  _handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  _loginAs = (role: any) => {
    const { changeRole, location } = this.props;
    changeRole(role);
    history.push(
      location.state
        ? location.state.referrer === '/login'
          ? '/'
          : location.state.referrer
        : '/',
    );
  };

  render() {
    const { email, password, showPassword, error, loggedIn } = this.state;
    const { classes, uiStore, match } = this.props;

    if (loggedIn) {
      const { marketId } = match.params;
      const prefix = getMarketPath(marketId || DEFAULT_MARKET_ID);
      return <Redirect to={prefix} />;
    }

    return (
      <Grid
        container
        classes={{ root: classes.grid }}
        alignItems="center"
        justify="center"
      >
        {uiStore.isLoading ? <LoadOverlay /> : null}
        <Grid item xs={4} xl={3}>
          <img src={logo.src} alt={logo.alt} style={logoStyle} />
          {error.message ? (
            <StatusCard variant="error">{error.message}</StatusCard>
          ) : null}
          <Card>
            <form onSubmit={this._onSubmit}>
              <CardContent classes={{ root: classes.cardContent }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      type="email"
                      value={email}
                      onChange={this._onInputChange}
                      label="Email *"
                      fullWidth
                      error={!!error.email}
                      helperText={error.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      error={!!error.password}
                      helperText={error.password}
                      id="adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      label="Password *"
                      onChange={this._handleChange('password')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton data-testid="login-icon-show-password" onClick={this._handleClickShowPassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions
                classes={{
                  spacing: classes.cardActionsSpacing,
                }}
              >
                <Grid container justify="flex-end">
                  <Button
                    data-testid="login-button"
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Log In
                  </Button>
                </Grid>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state: { authStore: any; uiStore: any }) => ({
  authStore: state.authStore,
  uiStore: state.uiStore,
});

const mapDispatchToProps = ({ authStore, uiStore }: any) => {
  return {
    ...authStore,
    ...uiStore,
  };
};

const StyledLogin = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage),
);

export default withRouter(StyledLogin as any);
