import React, { useDispatch, useGlobal, useState } from 'reactn';

import { Button, Dialog } from 'common/components';
import { ColorType } from 'types';

import { S } from './RestoreOrderModal.styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { formatToCurrency } from 'common/utils';
import { OrderAmountDiff } from 'common/services/apis/v3/swipe-rx-pt/resources/orders/types/OrderAmoutDiff.type';

export const RestoreOrderModal: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);

  const [{ restore, loading }] = useGlobal('transactions');

  const resetOrderRestoreConfirmation = useDispatch('resetOrderRestoreConfirmation');
  const restoreOrders = useDispatch('restoreOrders');

  const { orderIds, amountDiffs } = restore;

  const handleClose = (): void => {
    setSubmitted(false);
    resetOrderRestoreConfirmation({});
  };

  const handleSubmit = async (): Promise<void> => {
    setSubmitted(true);
    await restoreOrders({ ids: orderIds });
    setSubmitted(false);
  };

  const totalCreditAfterAccept = (diff: OrderAmountDiff): number => {
    return diff.available_credit - diff.updated_amount;
  };

  const renderContent = (): JSX.Element => (
    <>
      <S.Description>
        You are about to restore the following orders which can create negative credit limit.
        <br />
        <br />
        Please review before submitting.
        <br />
      </S.Description>

      <TableContainer component={Paper}>
        <Table aria-label="order diff table">
          <TableHead>
            <TableRow>
              <TableCell>PO Number</TableCell>
              <TableCell align="right">Total Amount</TableCell>
              <TableCell align="right">Credit After Restore</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {amountDiffs.map((row) => (
              <TableRow key={row.order_id}>
                <TableCell component="th" scope="row">
                  {row.po_number}
                </TableCell>
                <TableCell align="right">{formatToCurrency(row.invoiced_amount || row.updated_amount)}</TableCell>
                <TableCell align="right">{formatToCurrency(totalCreditAfterAccept(row))}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const renderActions = (): JSX.Element => (
    <>
      <Button isSecondary color={ColorType.DANGER} onClick={handleClose} disabled={loading}>
        Cancel
      </Button>

      <Button color={ColorType.PRIMARY} onClick={handleSubmit}>
        Submit
      </Button>
    </>
  );

  return (
    <>
      <Dialog
        open={!!orderIds.length && !submitted}
        primary
        title="Restore Orders"
        onClose={handleClose}
        renderActions={renderActions}
        renderContent={renderContent}
      />
    </>
  );
};
