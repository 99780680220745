import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { GridProps } from '@material-ui/core';

import { S } from './field-file-upload.styles';
import { FieldFileUploadControls } from './field-file-upload-controls.component';
import { FieldFileUploadIcon } from './field-file-upload-icon.component';

interface Props {
  onUpload: (base64: any, filename: string, file?: File) => void;
  type: string;
  accept: string;
  label?: string;
  defaultText: string;
  removeCallback?: () => void;
  overrideProps?: Record<string, any>;
  controlOverrideProps?: GridProps;
}

export const FieldFileUpload: React.FC<Props> = ({
  onUpload,
  type,
  accept,
  label,
  defaultText,
  removeCallback,
  overrideProps = {},
  controlOverrideProps = {},
}) => {
  const [fileName, setFileName] = useState(defaultText);
  const hasUploadedFile = fileName !== defaultText;

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          if (event.target) {
            onUpload(event.target.result, file.name, file);
          }
        };

        reader.readAsDataURL(file);

        setFileName(file.name);
      });
    },
    [onUpload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  const handleRemove = (): void => {
    onUpload('', '');
    setFileName(defaultText);
    if (removeCallback) {
      removeCallback();
    }
  };

  return (
    <>
      <FieldFileUploadControls
        label={label}
        active={hasUploadedFile}
        onRemove={handleRemove}
        overrideProps={controlOverrideProps}
      />
      <S.DropzoneContainer
        {...getRootProps()}
        active={hasUploadedFile}
        style={{ ...(getRootProps().style ?? {}), ...(overrideProps?.style ?? {}) }}
      >
        <input data-testid={`upload-${label}`} {...getInputProps()} />
        {isDragActive ? (
          <p>{`Drop the ${type} file here ...`}</p>
        ) : (
          <S.UploadArea>
            <FieldFileUploadIcon active={hasUploadedFile} />
            <span>{fileName}</span>
          </S.UploadArea>
        )}
      </S.DropzoneContainer>
    </>
  );
};
