import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { S } from './field-file-upload.styles';
import { FieldFileUploadControls } from './field-file-upload-controls.component';
import { FieldFileUploadIcon } from './field-file-upload-icon.component';

interface Props {
  onUpload: (base64: any) => void;
  type: string;
  accept: string;
}

export const FieldFileUpload: React.FC<Props> = ({ onUpload, type, accept }) => {
  const defaultText = `file_name_${type}`;

  const [fileName, setFileName] = useState(defaultText);
  const hasUploadedFile = fileName !== defaultText;

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          if (event.target) {
            onUpload(event.target.result);
          }
        };

        reader.readAsDataURL(file);

        setFileName(file.name);
      });
    },
    [onUpload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  const handleRemove = (): void => {
    onUpload('');
    setFileName(defaultText);
  };

  return (
    <>
      <FieldFileUploadControls label={type} active={hasUploadedFile} onRemove={handleRemove} />
      <S.DropzoneContainer {...getRootProps()} active={hasUploadedFile}>
        <input {...getInputProps()} data-testid={`upload-${type}`} />
        {isDragActive ? (
          <p>{`Drop the ${type} file here ...`}</p>
        ) : (
          <S.UploadArea>
            <FieldFileUploadIcon active={hasUploadedFile} />
            <span>{fileName}</span>
          </S.UploadArea>
        )}
      </S.DropzoneContainer>
    </>
  );
};
