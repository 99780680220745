/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useDispatch } from 'reactn';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { OrderReturnType } from 'common/services/apis/v3/swipe-rx-pt';
import { OrderItemUpdate } from 'common/interfaces';
import { FullOrderReturnFormState } from './interfaces';

const formState: FullOrderReturnFormState = {
  reason: '',
  date: moment().format('YYYY-MM-DD'),
};

export const useFullOrderReturnForm = (payload: {
  return_type: string;
  order_id: number;
  clearSteps: () => void;
  item_updates: OrderItemUpdate[];
}) => {
  const { return_type, order_id, clearSteps, item_updates } = payload;
  const returnOrder = useDispatch('returnOrder');

  const form = useFormik({
    initialValues: {
      ...formState,
    },
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason field is required.'),
      date: Yup.date().required('Date field is required.'),
    }),
    onSubmit: ({ reason, date }, { setSubmitting }) => {
      const deliveryDate =
        return_type === OrderReturnType.PARTIAL ? date : undefined;
      returnOrder({
        reason,
        return_type,
        item_updates,
        clearSteps,
        date: deliveryDate,
      });
      setSubmitting(false);
    },
  });

  useEffect(() => {
    form.setValues({ ...formState });
    Object.keys(formState).map((key: string): void => {
      form.setTouched({ [key]: false });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_id]);

  return form;
};
