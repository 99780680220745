/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SwipeRxPt } from 'common/services/apis/v3';
import { useSelector, shallowEqual } from 'react-redux';
import { useEffect, useState } from 'reactn';
import stores from 'common/models/index.model'
import { AuthenticatedUser } from './use-get-authenticated-user';

export const useValidateUserRole = () => {
  const {
    authStore: { data: user },
  } = useSelector((state: any) => state, shallowEqual);
  const initialUserType = (user as AuthenticatedUser).userType;
  const [isValid,setIsValid] = useState(true);
  const [role,setRole] = useState(initialUserType);

  const validateUserRole = () =>{
    if(user.id > 0)
    SwipeRxPt.instance.users.getRole(user.id).then((response)=>{
      const correctRole = response.data;
      const isUserRoleValid = initialUserType === correctRole
      stores.dispatch.authStore.setRole({
          role: correctRole,
          isUserRoleValid
        } as any);
      if(!isUserRoleValid) {
        setIsValid(isUserRoleValid);
        setRole(correctRole);
      }
    })    
  }

  useEffect(validateUserRole, [])

  return {
    isValid,
    validateUserRole,
    role
  };
};
