import React, { useGlobal } from 'reactn';
import moment from 'moment-timezone';

import Typography from '@material-ui/core/Typography';

import { ColorType, DataObject } from 'types';
import { PaymentMethod, PaymentProvider } from 'common/constants';
import { ConfigState } from 'common/reducers';
import { ShowIf } from 'common/components';

const { DIRECT_PAYMENT } = PaymentMethod;

const getBillExpiration = (config: ConfigState['paymentMethod']): number => {
  return config?.directPayment?.billExpiration ? config.directPayment.billExpiration : 14400;
};

function getDurationWords(time: moment.Duration): string[] {
  const durations = [];
  if (time.months() > 0) {
    durations.push(`${time.months()} ${time.months() > 1 ? 'months' : 'month'}`);
  }
  durations.push(`${time.days()} ${time.days() > 1 ? 'days' : 'day'}`);
  durations.push(`${time.hours()} ${time.hours() > 1 ? 'hours' : 'hour'}`);
  durations.push(`${time.minutes()} ${time.minutes() > 1 ? 'minutes' : 'minute'}`);
  durations.push(`${time.seconds()} ${time.seconds() > 1 ? 'seconds' : 'second'}`);
  return durations;
}

function getElapsedDuration(
  now: moment.Moment,
  dateBefore: moment.Moment,
): { totalNumOfHours: number; totalNumOfSeconds: number; formattedElapsedTime: string } {
  const elapsedTime = moment.duration(now.diff(dateBefore));
  const durations = getDurationWords(elapsedTime);

  const formattedElapsedTime = durations.join(' ');
  const totalNumOfHours = elapsedTime.asHours();
  const totalNumOfSeconds = elapsedTime.asSeconds();
  return { totalNumOfHours, totalNumOfSeconds, formattedElapsedTime };
}

function getRemainingDuration(
  dateBefore: moment.Moment,
  now: moment.Moment,
): { totalNumOfSeconds: number; formattedRemainingTime: string } {
  const remainingTime = moment.duration(moment(dateBefore).diff(now));

  const durations = getDurationWords(remainingTime);

  const formattedRemainingTime = durations.join(' ');
  const totalNumOfSeconds = remainingTime.asSeconds();
  return { totalNumOfSeconds, formattedRemainingTime };
}

const getDateBefore = (
  status: string | undefined,
  orderedAt: string,
  config: ConfigState['paymentMethod'],
  timezone: string,
  lastStatusUpdate: string,
  paymentMethod: PaymentMethod | null,
): moment.Moment => {
  let dateBefore = moment.tz(lastStatusUpdate || orderedAt, 'UTC').tz(timezone);

  if ((status === 'pending' || !status) && paymentMethod === DIRECT_PAYMENT) {
    dateBefore = moment.tz(orderedAt, 'UTC').tz(timezone).add(getBillExpiration(config), 'seconds');
  }

  return dateBefore;
};

const toUtcTz = (at: string, timezone: string): moment.Moment => {
  return moment.tz(at, 'UTC').tz(timezone);
};

const getOrderLabel = (
  status: string | undefined,
  lastStatusUpdate: string,
  orderedAt: string,
  config: ConfigState['paymentMethod'],
  paymentMethod: PaymentMethod | null,
  timezone: string,
  dateBefore: moment.Moment,
  invoice?: DataObject | null,
  paymentStatus?: string | null,
): any => {
  let labelType;
  let labelElement;

  const now = moment.tz(timezone);

  let invoicePaymentStatus = paymentStatus;

  // Determining payment status
  if (!paymentStatus && paymentMethod === DIRECT_PAYMENT && (status === 'pending' || !status)) {
    if (invoice && invoice.payment_status === 'paid') {
      invoicePaymentStatus = 'paid';
    } else {
      invoicePaymentStatus = 'unpaid';
    }
  }

  if (invoicePaymentStatus === 'paid') {
    let label = '';

    if (invoice) {
      const invPaidAt = moment.tz(invoice.paid_at, 'UTC').tz(timezone);
      const orderedAtConverted = toUtcTz(orderedAt, timezone);
      const { totalNumOfSeconds, formattedElapsedTime } = getElapsedDuration(invPaidAt, orderedAtConverted);
      label = `Bill was paid ${formattedElapsedTime} after checkout`;

      if (totalNumOfSeconds <= getBillExpiration(config)) {
        labelType = ColorType.SUCCESS;
      } else {
        labelType = ColorType.DANGER;
      }
    } else {
      label = 'Bill is paid';
      labelType = ColorType.SUCCESS;
    }

    labelElement = (
      <>
        <strong>{label}</strong>
      </>
    );
  } else if (invoicePaymentStatus === 'unpaid') {
    const { totalNumOfSeconds, formattedRemainingTime } = getRemainingDuration(dateBefore, now);

    // Determine direct payment label element and type
    if (totalNumOfSeconds > 0 && totalNumOfSeconds <= getBillExpiration(config)) {
      labelType = ColorType.DANGER;
      labelElement = (
        <>
          <ShowIf condition={config.directPayment.provider === PaymentProvider.DIGIO}>
            <strong>{formattedRemainingTime}</strong> left for payment
            <br />
          </ShowIf>
          <Typography variant="caption">Unpaid</Typography>
        </>
      );
    } else if (totalNumOfSeconds < 0) {
      labelElement = (
        <>
          <ShowIf condition={config.directPayment.provider === PaymentProvider.DIGIO}>
            <strong>Bill is expired</strong>
            <br />
          </ShowIf>
          <Typography variant="caption">Unpaid</Typography>
        </>
      );
    }
  } else {
    const { totalNumOfHours, formattedElapsedTime } = getElapsedDuration(now, dateBefore);

    // Determine credit line label type
    if (!lastStatusUpdate) {
      labelType = ColorType.GRAY;
    } else if (totalNumOfHours < 5) {
      labelType = ColorType.GRAY;
    } else {
      labelType = ColorType.DANGER;
    }

    // Determine credit line label element
    if (lastStatusUpdate) {
      labelElement = (
        <>
          <strong>{formattedElapsedTime}</strong> {status}
        </>
      );
    }
  }

  if (!labelElement) {
    labelElement = (
      <strong>
        <em>Elapsed Time Not Available</em>
      </strong>
    );
  }

  if (!labelType) {
    labelType = ColorType.GRAY;
  }

  return { labelType, labelElement };
};

export const getOrderElapsedTimeElement = (
  status: string | undefined,
  statusHistory: DataObject,
  orderedAt: string,
  paymentMethod: PaymentMethod | null,
  invoice?: DataObject | null,
  paymentStatus?: string | null,
): {
  labelElement: JSX.Element;
  labelType: ColorType;
} => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [{ paymentMethod: paymentMethodConfig }] = useGlobal('config');

  const lastStatusUpdate = statusHistory && status ? statusHistory[status]?.date : '';
  const timezone = moment.tz.guess();
  const dateBefore = getDateBefore(status, orderedAt, paymentMethodConfig, timezone, lastStatusUpdate, paymentMethod);

  const { labelType, labelElement } = getOrderLabel(
    status,
    lastStatusUpdate,
    orderedAt,
    paymentMethodConfig,
    paymentMethod,
    timezone,
    dateBefore,
    invoice,
    paymentStatus,
  );

  return {
    labelType,
    labelElement,
  };
};
