/* eslint-disable no-restricted-globals */
import { addReducer } from 'reactn';

import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';
import { recordException } from 'utils/Reporting/Sentry';

import { LogisticCalendarState } from './interfaces/LogisticCalendarState.interface';

export const LOGISTIC_CALENDAR_INITIAL_STATE: LogisticCalendarState = {
  logisticCalendarList: {
    data: [],
    meta: {
      page: 1,
      page_count: 1,
      page_size: 10,
      total_count: 1,
    }
  },
  loading: false,
};

addReducer('fetchCalendarDatatable', async (state, dispatch, { params} ) => {
  dispatch.setCalendarLoading(true);

  try {
    const { meta, data } = await SwipeRxPt.instance.logisticsCalendar.list(params);

    dispatch.setCalendarDataTable({ data, meta});
  } catch (error) {
    recordException(error, 'fetchCalendarDatatable', { params });

    dispatch.setCalendarError(error);
  }
});

addReducer('deleteCalendarEvent', async (state, dispatch, eventId ) => {
  try {
    await SwipeRxPt.instance.logisticsCalendar.delete(eventId);
  } catch (error) {
    recordException(error, 'deleteCalendarEvent', { eventId });

    dispatch.setCalendarError(error);
  }
});

addReducer('setCalendarDataTable', (state, _, { data, meta}) => {
  const logisticCalendar = state.logisticCalendar;

  return {
    ...state,
    logisticCalendar: {
      ...logisticCalendar,
      loading: false,
      logisticCalendarList: {
        data,
        meta,
      }
    }
  }
});

addReducer('clearCalendarDataTable', (state) => {
  const logisticCalendar = state.logisticCalendar;

  return {
    ...state,
    logisticCalendar: {
      ...logisticCalendar,
      loading: false,
      logisticCalendarList: {
        data: [],
        meta:  {
          page: 1,
          page_count: 1,
          page_size: 10,
          total_count: 1,
        },
      }
    }
  }
});

addReducer('setCalendarError', (state, _, error) => {
  const logisticCalendar = state.logisticCalendar;

  return {
    ...state,
    logisticCalendar: {
      ...logisticCalendar,
      error: error.message,
    }
  }
});

addReducer('setCalendarLoading', (state, _, isLoading) => {
  const logisticCalendar = state.logisticCalendar;

  return {
    ...state,
    logisticCalendar: {
      ...logisticCalendar,
      loading: isLoading,
    }
  }
});
