import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';

import { titleCss } from 'common/components/PageWrapper';

const Title = styled(Typography)`
  ${titleCss}

  && {
    cursor: pointer;
  }
`;

export const Style = {
  Title,
};
