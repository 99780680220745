export const getCurrencyByMarketId = (marketId: string): string => {
  switch (marketId) {
    case 'th':
      return 'THB';
    case 'id':
      return 'IDR';
    default:
      return '';
  }
};
