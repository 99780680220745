import React from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CloudDownload } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);

interface Props extends ButtonProps {
  downloading?: boolean;
}

export const DownloadButton: React.FC<Props> = ({
  disabled,
  downloading,
  onClick,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button
      color="default"
      disabled={disabled || downloading}
      onClick={onClick}
      size="small"
      {...props}
    >
      {downloading ? (
        <CircularProgress size={20} className={classes.leftIcon} />
      ) : (
        <CloudDownload className={classes.leftIcon} fontSize="small" />
      )}
      Download
    </Button>
  );
};

DownloadButton.defaultProps = {
  downloading: false,
};
