import React from 'react';
import clsx from 'clsx';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { UserRoles } from '../constants'

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  name: {
    color: 'white',
    fontSize: '12px',
    paddingTop: '5px',
    fontWeight: 300,
    wordBreak: 'break-all',
  },
  icon: {
    color: 'white',
  },
  role: {
    opacity: 0.6,
    color: 'white',
    lineHeight: 0,
  },
  logoutButton: {
    padding: 0,
  },
  userDetailsOpen: {
    transition: theme.transitions.create('display', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.complex,
    }),
    display: 'block',
  },
  userDetailsClose: {
    transition: theme.transitions.create('display', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.complex,
    }),
    display: 'none',
  },
});

class SideBarUser extends React.Component {
  _logout = async () => {
    const { logout } = this.props;
    await logout();
    window.location.reload()
  };

  render() {
    const { classes, role, username, open } = this.props;
    const userRoleName = Object.keys(UserRoles)[(Object.values(UserRoles)).indexOf(role)] || '';
    return (
      <Grid container classes={{ root: classes.root }}>
        <Grid
          item
          xs={10}
          className={clsx({
            [classes.userDetailsOpen]: open,
            [classes.userDetailsClose]: !open,
          })}
        >
          <Typography variant="overline" classes={{ root: classes.role }} data-testid="user-type-label">
            {userRoleName}
          </Typography>
          <Typography variant="body2" classes={{ root: classes.name }}>
            {username}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <SideBarUserMenu logout={this._logout} classes={classes} />
        </Grid>
      </Grid>
    );
  }
}

const SideBarUserMenu = ({ classes, logout }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function onLogout() {
    handleClose();
    logout();
  }

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        data-testid="menu-button-logout"
      >
        <ExitToAppIcon classes={{ root: classes.icon }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem id="logout-menu-item" onClick={onLogout} data-testid="menu-item-logout">
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({
  authStore: state.authStore,
});

const mapDispatchToProps = ({ authStore }) => {
  return {
    ...authStore,
  };
};

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SideBarUser)),
);
