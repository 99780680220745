import React, { useState } from 'react';

import { Grid } from '@material-ui/core';

import { OptionType } from 'common/interfaces/OptionType.interface';

import { FieldDateSelection, DateSelection } from '../field-date-selection';
import { DateInBetween } from './field-date-in-between.interface';

interface Props {
  defaultDateA: DateSelection;
  defaultDateB: DateSelection;
  onChange: (value: DateInBetween)=>void;
  unitOptions: OptionType[];
  componentKey?: string;
}

export const FieldDateInBetween: React.FC<Props> = ({
  defaultDateA,
  defaultDateB,
  onChange,
  unitOptions,
  componentKey
}) => {
  const defaultDate:DateSelection = {
    unit: '',
    time: ''
  }
  const [dateA, setDateA] = useState<DateSelection>(defaultDateA || defaultDate)
  const [dateB, setDateB] = useState<DateSelection>(defaultDateB || defaultDate)

  const isDateDefined = (date: DateSelection): boolean => date.time !== '' || date.unit !== ''

  const handleDateAChange = (newDateA: any): void => {
    if(newDateA && isDateDefined(dateB) ) {
      onChange({
        dateA: newDateA,
        dateB
      })
    }    
    setDateA(newDateA)
  }

  const handleDateBChange = (newDateB: any): void => {
    if(isDateDefined(dateA) && newDateB) {
      onChange(
        ({
          dateA,
          dateB: newDateB
        })
      )
    }
    setDateB(newDateB)
  }


  return (
    <Grid container>
      <Grid item xs={5}>
        <FieldDateSelection 
          defaultTime={dateA.time}
          defaultUnit={dateA.unit}
          onChange={handleDateAChange}
          unitOptions={unitOptions}
          componentKey={`${componentKey}A`}
        />            
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          alignSelf: 'center',
          textAlign: 'center',              
        }}
      >
        and
      </Grid>
      <Grid item xs={5}>
        <FieldDateSelection 
          defaultTime={dateB?.time || ''}
          defaultUnit={dateB?.unit || ''}
          onChange={handleDateBChange}
          unitOptions={unitOptions}
          componentKey={`${componentKey}B`}
        />       
      </Grid>
    </Grid> 
  );
};
