export const getCountryByMarketId = (marketId: string): string => {
  switch (marketId) {
    case 'th':
      return 'Thailand';
    case 'id':
      return 'Indonesia';
    default:
      return '';
  }
};

export const getMarketIdByCountry = (country: string): string => {
  switch (country.toLowerCase()) {
    case 'thailand':
      return 'th';
    case 'indonesia':
      return 'id';
    default:
      return 'id';
  }
};
