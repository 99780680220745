/* eslint-disable no-param-reassign */

import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { Area } from './interfaces';
import { AreaList } from './interfaces/AreaList.interface';
import { FetchCityParams, FetchCountryParams, FetchKecamatanParams, FetchKelurahanParams, FetchProvinceParams, FetchZipcodeParams } from './interfaces/AreaParams.interface';

export class SwipeRxPtAreas {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.AREAS;
  }

  async fetchAreas(params: any): Promise<AreaList> {
    const result = await this.base.get(`${this.resource}/`, params);

    return result;
  }

  async fetchCountry(params: FetchCountryParams): Promise<Area[]> {
    const result = await this.base.get(`${this.resource}/country`, params);

    return result.data;
  }

  async fetchProvince(params: FetchProvinceParams): Promise<Area[]> {
    const result = await this.base.get(`${this.resource}/province`, params);

    return result.data;
  }

  async fetchCity(params: FetchCityParams): Promise<Area[]> {
    const result = await this.base.get(`${this.resource}/city`, params);

    return result.data;
  }

  async fetchKecamatan(params: FetchKecamatanParams): Promise<Area[]> {
    const result = await this.base.get(`${this.resource}/kecamatan`, params);

    return result.data;
  }

  async fetchKelurahan(params: FetchKelurahanParams): Promise<Area[]> {
    const result = await this.base.get(`${this.resource}/kelurahan`, params);

    return result.data;
  }

  async fetchZipcode(params: FetchZipcodeParams): Promise<Area[]> {
    const result = await this.base.get(`${this.resource}/zipcode`, params);

    return result.data;
  }
}
