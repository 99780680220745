import styled from 'styled-components';

const Wrapper = styled.span`
  && {
    cursor: pointer;
    color: #40ae96;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const S = {
  Wrapper,
};
