/* eslint-disable no-restricted-globals */
import React, { useGlobal } from 'reactn';
import capitalize from 'lodash/capitalize';
import moment from 'moment-timezone';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import { CardTableDetailButton } from 'common/components/CardTable';
import { CardTableColumn } from 'common/components/CardTable/interfaces';
import { CardTableCellType } from 'common/components/CardTable/types';
import { getTransactionStatus } from 'common/utils';
import { Colors } from 'common/themes/colors';
import { useUpdateQueryParams } from 'hooks';
import { InvoiceStatus } from 'common/services/apis/v3';
import { PaymentMethod } from 'common/constants';

import { renderDocumentType } from '../utils/column-render.util';

export const useTransactionDataTableColumns = (): CardTableColumn[] => {
  const {
    queryParams: { status: statusFromUrl, payment_method: paymentMethod },
    updateQueryParams,
  } = useUpdateQueryParams();

  let dataTestId;

  const [{ distributor }] = useGlobal('transactions');

  const columns: CardTableColumn[] = [
    {
      path: 'pharmacy.name',
      text: 'Pharmacy',
      sort: true,
      width: 220,
    },
    {
      path: 'po_number',
      text: 'PO Number',
      sort: true,
      width: 150,
    },
    {
      path: 'ordered_at',
      text: 'Order Date',
      type: CardTableCellType.DATETIME,
      sort: true,
      width: 260,
    },
    {
      text: 'Expect Dispatch Date',
      width: 270,
      render: (_, data) => {
        const ExpectDispatchDate = styled(Typography).attrs({ noWrap: true })`
          font-size: 12px;
          color: ${Colors.darkenPrimary};
          min-width: 60px;
          text-align: center;
        `;
        if (!data.logistics_po_delivery?.expected_dispatch) return <ExpectDispatchDate>-</ExpectDispatchDate>;
        return moment(data.logistics_po_delivery?.expected_dispatch).utcOffset(7).format('MMM DD, YYYY hh:mm A');
      },
    },
    {
      text: 'Expect Delivery Date',
      width: 270,
      render: (_, data) => {
        const ExpectDeliveryText = styled(Typography).attrs({ noWrap: true })`
          font-size: 12px;
          color: ${Colors.darkenPrimary};
          min-width: 60px;
          text-align: center;
        `;

        if (!data.logistics_po_delivery?.expected_delivery) return <ExpectDeliveryText>-</ExpectDeliveryText>;

        return moment(data.logistics_po_delivery?.expected_delivery).utcOffset(7).startOf('day').format('DD MMM YYYY');
      },
    },
    {
      id: 'logistics_delivery_partner',
      path: 'logistics_po_delivery.logistics_delivery_partner.name',
      text: '3PL Name',
      width: 300,
    },
    {
      id: 'document_type',
      text: 'Surat Pesanan',
      width: 270,
      render: renderDocumentType,
    },
    /* {
      id: 'logistics_delivery_status',
      path: 'logistics_delivery_status.status',
      text: 'Delivery API Status',
      sort: true,
      width: 300,
      render: (_, data) => {
        const BaseDeliveryText = styled(Typography).attrs({ noWrap: true })`
          font-size: 12px;
          color: ${Colors.darkenPrimary};
          min-width: 60px;
          text-align: center;
        `;

        if (!data.logistics_delivery_status) return <BaseDeliveryText>-</BaseDeliveryText>;

        const {
          status,
          status_detail,
          logistics_delivery_partner,
          delivery_tracking_code,
          point_id,
        } = data.logistics_delivery_status;

        const DeliveryNameText = styled(BaseDeliveryText)`
          font-size: 14px;
          color: ${Colors.textNormal};
        `;
        const DeliveryTrackingText = styled(BaseDeliveryText)``;
        const DeliveryStatusText = styled(BaseDeliveryText)`
          text-transform: uppercase;
        `;

        return (
          <>
            <DeliveryNameText>{logistics_delivery_partner?.name}</DeliveryNameText>
            {delivery_tracking_code && (
              <Grid container direction="row">
                <DeliveryTrackingText>{delivery_tracking_code}</DeliveryTrackingText>
                {point_id && <DeliveryTrackingText>/{point_id}</DeliveryTrackingText>}
              </Grid>
            )}
            <DeliveryStatusText>{status}</DeliveryStatusText>
            <DeliveryStatusText>{status_detail}</DeliveryStatusText>
          </>
        );
      },
    }, */
    {
      id: 'delivered_on',
      text: 'Delivered on',
      width: 300,
      render: (_, data) => {
        const { delivered_at, logistics_po_delivery } = data;

        if (!delivered_at) return '-';

        const { expected_delivery } = logistics_po_delivery || {};
        const shouldShowPunctuality = !!expected_delivery;
        const isPunctual = moment(delivered_at)
          .utcOffset(7)
          .startOf('day')
          .isSameOrBefore(moment(expected_delivery).utcOffset(7).startOf('day'), 'day');

        return (
          <>
            {moment(delivered_at).utcOffset(7).startOf('day').format('DD MMM YYYY')}
            {!shouldShowPunctuality ? null : isPunctual ? (
              <p style={{ color: Colors.primary }}>On time</p>
            ) : (
              <p style={{ color: Colors.red }}>Not on time</p>
            )}
          </>
        );
      },
    },
    {
      path: 'net_amount',
      text: 'Total Amount',
      type: CardTableCellType.CURRENCY,
      sort: true,
      width: 150,
    },
    {
      path: 'total_amount_with_tax',
      text: 'Total Amount After Tax',
      type: CardTableCellType.CURRENCY,
      width: 160,
    },
    {
      id: 'detail_button',
      render: (_, data) => {
        dataTestId = data.id;
        const handleClick = (): void => {
          updateQueryParams({
            detail: 'order',
            order_id: data.id,
          });
        };

        return <CardTableDetailButton dataId={dataTestId} onClick={handleClick} />;
      },
    },
  ];

  const showInvoiceColumns = !['pending', 'cancelled'].includes(statusFromUrl as string);
  const showDeliveryDate = ['delivered', 'fulfilled'].includes(statusFromUrl as string);
  // const showDeliveryStatus = ['dispatched', 'delivered', 'fulfilled'].includes(statusFromUrl as string);
  const showInvoiceStatus = false;

  if (showInvoiceColumns) {
    const totalAmountAfterTaxColumnIndex = 6;

    columns.splice(
      totalAmountAfterTaxColumnIndex,
      0,
      {
        path: 'invoice.invoice_number',
        text: 'Invoice Number',
        sort: true,
        width: 150,
      },
      {
        path: 'invoice.invoiced_amount',
        text: 'Invoice Amount',
        type: CardTableCellType.CURRENCY,
        sort: true,
        width: 150,
      },
      {
        path: 'invoice.invoiced_at',
        text: 'Invoice Date',
        type: CardTableCellType.DATE,
        sort: true,
        width: 170,
      },
    );

    if (showInvoiceStatus && paymentMethod === PaymentMethod.CREDIT_LINE) {
      const invoiceAmountIndex = 7;

      columns.splice(invoiceAmountIndex, 0, {
        path: 'invoice.status',
        text: 'Invoice Status',
        type: CardTableCellType.PERCENTAGE,
        sort: false,
        width: 150,
        render: (_, data) => {
          let color = Colors.warning;

          if (data.invoice?.status === InvoiceStatus.CLAIMED) {
            color = Colors.primary;
          }

          if ([InvoiceStatus.CANCELLED, InvoiceStatus.FAILED].includes(data.invoice?.status)) {
            color = Colors.red;
          }

          const InvoiceStatusText = styled(Typography)`
            font-size: 12px;
            font-weight: 600;
            color: ${color};
            text-transform: capitalize;
          `;

          return <InvoiceStatusText>{data.invoice?.status || '-'}</InvoiceStatusText>;
        },
      });
    }
  }

  if (statusFromUrl && statusFromUrl === 'all') {
    const poNumberColumnIndex = 2;

    columns.splice(poNumberColumnIndex, 0, {
      text: 'Status',
      width: 100,
      render: (_, { is_returned, status, fulfillment_status }) => {
        if (status === 'completed' && fulfillment_status === 'fulfilled') {
          return 'Completed';
        }

        const transactionStatus = getTransactionStatus(status, fulfillment_status);

        if (transactionStatus === 'pending') {
          return 'New Order';
        }

        if (transactionStatus === 'processing') {
          return 'In Progress';
        }

        if (is_returned) {
          return 'Returned';
        }

        return capitalize(transactionStatus);
      },
    });
  }

  if (!distributor.data) {
    columns.unshift({
      path: 'distributor.name',
      text: 'Distributor',
      sort: true,
      width: 220,
      render: (_, data) => {
        const DistributorNameText = styled(Typography)`
          font-size: 14px;
          min-width: 110px;
          text-align: left;
        `;

        return <DistributorNameText>{data.distributor?.name || 'N/A'}</DistributorNameText>;
      },
    });
  }

  if (!showDeliveryDate) {
    const deliveryDateColumnIndex = columns.findIndex((column) => column.id === 'delivered_on');
    columns.splice(deliveryDateColumnIndex, 1);
  }
  // if (!showDeliveryStatus) {
  //   const deliveryLogisticColumnIndex = columns.findIndex((column) => column.id === 'logistics_delivery_status');
  //   columns.splice(deliveryLogisticColumnIndex, 1);
  // }

  return columns;
};
