/* eslint-disable class-methods-use-this */
import stores from '../../../../models/index.model';
import { PtAPIv2 } from '../utils';
import { SwipeRxPt } from '../../v3/swipe-rx-pt';

class PharmaciesServices {
  // Get All pharmacies v2
  async getList(type = 'pharmacy', limit = 10, page = 1, sort_by = 'name', order_by = 'asc', query = '') {
    const queryParams = query
      ? `type=${type}&page_size=${limit}&page=${page}&sort_by=${sort_by}&order_by=${order_by}&query=${query}`
      : `type=${type}&page_size=${limit}&page=${page}&sort_by=${sort_by}&order_by=${order_by}`;
    // console.log('queryParams ::::', queryParams);

    stores.dispatch.uiStore.showLoading(true);

    const returnData = {
      result: [],
      page,
      itemsPerPage: limit,
      total: 0,
    };

    await PtAPIv2.dashboard
      .get(`organizations?${queryParams}`)
      .then((successData) => {
        // handle success
        returnData.result = successData.result.organizations;
        returnData.total = successData.result.meta.total_count;
      })
      .catch((error) => {
        // handle error
        console.error('Error on getting Pharmacies: ', error);
      })
      .finally(() => {
        // always executed
        stores.dispatch.uiStore.showLoading(false);
      });
    return returnData;
  }

  async getOne(id) {
    stores.dispatch.uiStore.showLoading(true);
    // eslint-disable-next-line
    var result = {};
    await PtAPIv2.dashboard
      .get(`organizations/${id}`)
      .then((successData) => {
        result = successData.result;
      })
      .catch((error) => {
        console.error(error);
        result = {};
      })
      .finally(() => {
        // always executed
        stores.dispatch.uiStore.showLoading(false);
      });

    return result.organization;
  }

  // Fetch credit summary of credit information
  async getCreditSummaryById(id, showLoading = true) {
    if (showLoading) stores.dispatch.uiStore.showLoading(true);
    let result = {};
    await PtAPIv2.dashboard
      .get(`credit/summary/${id}`)
      .then((successData) => {
        result = successData.result;
      })
      .catch((error) => {
        console.error(error);
        result = {};
      })
      .finally(() => {
        if (showLoading) stores.dispatch.uiStore.showLoading(false);
      });

    return result.credit_summary;
  }

  // Fetch credit history
  async getCreditHistoryById(id, limit, page, sort_by, order_by) {
    stores.dispatch.uiStore.showLoading(true);
    const returnData = {};

    await SwipeRxPt.instance.organizations
      .fetchCreditHistory({
        organization_id: id,
        page_size: limit,
        page,
        sort_by,
        order_by,
      })
      .then((response) => {
        // handle success
        returnData.result = response.data;
        returnData.total = response.meta;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        stores.dispatch.uiStore.showLoading(false);
      });
    return returnData;
  }

  // Fetch invoices of a pharmacy
  async getInvoicesById(id, limit, page, orderBy, sortBy, searchObject) {
    stores.dispatch.uiStore.showLoading(true);
    let path = `invoice/${id}?page_size=${limit}&page=${page}&order_by=${orderBy}&sort_by=${sortBy}`;

    if (searchObject) {
      Object.keys(searchObject).forEach((key) => {
        switch (key) {
          case 'invoiced_at':
            path += `&invoiced_date=${searchObject[key]}`;
            break;
          case 'order_date':
            path += `&order_date=${searchObject[key]}`;
            break;
          case 'tax_invoice':
            path += `&tax_invoice_uploaded=${searchObject[key].toLowerCase() === 'uploaded' ? 0 : 1}`;
            break;
          default:
            path += `&${key}=${searchObject[key].toLowerCase()}`;
            break;
        }
      });
    }
    const returnData = {
      result: [],
      page,
      itemsPerPage: limit,
      total: 0,
    };
    await PtAPIv2.dashboard
      .get(path)
      .then((successData) => {
        // handle success
        // // console.log(successResponse.result.invoices[0]);
        returnData.result = successData.result.invoices;
        returnData.total = successData.result.meta.total_count;
      })
      .catch((error) => {
        // handle error
        console.error('Error on getting Pharmacies: ', error);
      })
      .finally(() => {
        // always executed
        stores.dispatch.uiStore.showLoading(false);
      });
    return returnData;
  }

  async getVirtualAccountsByPharmacyId(id) {
    stores.dispatch.uiStore.showLoading(true);
    try {
      const { data } = await SwipeRxPt.instance.organizations.fetchVirtualAccountsById(id);

      return data;
    } catch (e) {
      // console.log(e);
    } finally {
      // always executed
      stores.dispatch.uiStore.showLoading(false);
    }
    return undefined;
  }

  async getInvoicesMessagesByInvoiceIds(organizationId, invoiceIds) {
    stores.dispatch.uiStore.showLoading('true');
    const returnData = {
      result: '',
    };
    await PtAPIv2.dashboard
      .get(`invoice/message/${organizationId}?invoices_id=${invoiceIds.join()}`)
      .then((successData) => {
        returnData.result = successData.result;
      })
      .catch((error) => {
        console.error('Error on getting Invoices Message: ', error);
      })
      .finally(() => {
        stores.dispatch.uiStore.showLoading(false);
      });
    return returnData;
  }

  async getInvoiceSummaryByPharmacy(organizationId) {
    stores.dispatch.uiStore.showLoading('true');
    const returnData = {
      result: '',
    };
    await PtAPIv2.dashboard
      .get(`invoice/summary/${organizationId}`)
      .then((successData) => {
        returnData.result = successData.result;
      })
      .catch((error) => {
        console.error('Error on getting Invoices Summary: ', error);
      })
      .finally(() => {
        stores.dispatch.uiStore.showLoading(false);
      });
    return returnData;
  }

  async downloadPaymentHistory(organizationId) {
    stores.dispatch.uiStore.showLoading('true');
    let returnMessage;

    await PtAPIv2.dashboard
      .get(`payment-history/${organizationId}/download`)
      .then((successData) => {
        alert(successData.message);
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally(() => {
        stores.dispatch.uiStore.showLoading(false);
      });

    return returnMessage;
  }

  async syncCRMData(organizationId) {
    stores.dispatch.uiStore.showLoading('true');

    await SwipeRxPt.instance.crm
      .crmSyncData(organizationId)
      .then((successData) => {
        stores.dispatch.uiStore.showLoading(false);

        const message = `Accounts result: ${successData.data.accounts?.[0]?.message}\n\n
        Contacts result: ${successData.data.contacts?.[0]?.message}\n\n
        Desk result: ${JSON.stringify(successData.data.desk)}`;
        alert(message);

        const { invalids } = successData?.data || {};
        if (invalids) {
          const invalidsMessage = invalids.reduce((previousValue, invalid) => {
            return `${previousValue}${invalid.field}: ${invalid.message}\n`;
          }, 'Invalid Fields:\n');
          alert(invalidsMessage);
        }
      })
      .catch((error) => {
        console.error(error);
        alert(error.message);
      })
      .finally(() => {
        stores.dispatch.uiStore.showLoading(false);
      });
  }

  async getOrganizationByID(id) {
    stores.dispatch.uiStore.showLoading(true);
    // eslint-disable-next-line
    var result = {};
    await PtAPIv2.admin
      .post(`functions/get-organization`, { id })
      .then((successData) => {
        result = successData.result;
      })
      .catch((error) => {
        console.error(error);
        result = {};
      })
      .finally(() => {
        // always executed
        stores.dispatch.uiStore.showLoading(false);
      });

    return result;
  }
}

export default new PharmaciesServices();
