import React, { useDispatch, useGlobal } from 'reactn';

import { Button, Dialog, FieldWrapper, FieldDateInput } from 'common/components';
import { formatToDatetime } from 'common/utils/formatters';
import { ColorType } from 'types';

import { PaymentMethod } from 'common/constants';
import { fieldOptions } from './order-invoice-form.config';
import { useOrderInvoiceForm } from './use-order-invoice-form';

const formId = 'order-invoice-form';

export const OrderInvoiceModalV0: React.FC = () => {
  const [{ invoicing, loading }] = useGlobal('transactions');
  const setOrderForInvoicing = useDispatch('setOrderForInvoicing');

  const { order } = invoicing || {};
  const { id, invoice_number, invoiced_amount, invoiced_at } = order?.invoice || {};
  const { ordered_at } = order || {};
  const isDisabled = order?.payment_method === PaymentMethod.DIRECT_PAYMENT;

  const initialValues = {
    id,
    invoice_number,
    invoiced_amount,
    order_id: order?.id,
    invoiced_at: formatToDatetime(invoiced_at || new Date(), 'YYYY-MM-DD'),
    ordered_at: formatToDatetime(ordered_at || new Date(), 'YYYY-MM-DD'),
  };

  const form = useOrderInvoiceForm(initialValues);

  const handleClose = (): void => {
    setOrderForInvoicing(null);
  };

  const renderContent = (): JSX.Element => (
    <form id={formId} onSubmit={form.handleSubmit}>
      {fieldOptions.map(({ name, type, label, component: Field, ...fieldProps }) => {
        if (type === 'date') {
          return (
            <FieldWrapper key={name} touched={form.touched[name]} error={form.errors[name]} label={label}>
              <FieldDateInput
                minDate={ordered_at}
                value={form.values[name]}
                onChange={(date) => {
                  form.setFieldValue(name, date);
                }}
                disabled={isDisabled}
              />
            </FieldWrapper>
          );
        }

        return (
          <FieldWrapper key={name} touched={form.touched[name]} error={form.errors[name]}>
            <Field
              name={name}
              type={type}
              label={label}
              {...fieldProps}
              {...form.getFieldProps(name)}
              disabled={isDisabled}
            />
          </FieldWrapper>
        );
      })}
    </form>
  );

  const renderActions = (): JSX.Element => (
    <Button
      color={ColorType.PRIMARY}
      disabled={!form.dirty || !form.isValid || form.isSubmitting || isDisabled}
      loading={form.isSubmitting}
      form={formId}
      type="submit"
    >
      Submit
    </Button>
  );

  return (
    <Dialog
      open={!!invoicing && invoicing.version === 'v0'}
      primary
      loading={loading}
      title={`${order?.invoice ? 'Update' : 'Create'} Invoice`}
      onClose={handleClose}
      renderActions={renderActions}
      renderContent={renderContent}
      subTitle="Order ID"
      value={order?.po_number}
    />
  );
};
