import { InputBase } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import styled from 'styled-components';

import { wrapperStyle } from '../Pagination.styles';

interface Props {
  disabled?: boolean;
}

const getColor = ({ disabled }: Props): string => (disabled ? '#8D959C' : '#000');

const Wrapper = styled.div`
  ${wrapperStyle}
  && {
    display: flex;
  }
`;

const Text = styled.span`
  && {
    color: ${getColor};
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const InputPage = styled(InputBase)`
  && {
    width: 3em;
    color: ${getColor};
  }
`;

const GoButton = styled(KeyboardArrowRight)`
  && {
    font-size: 1.3rem;
    margin-bottom: -2px;
    border: 2px solid #fafafa;
    color: ${getColor};
  }
`;

export const S = {
  Wrapper,
  Text,
  InputPage,
  GoButton,
};
