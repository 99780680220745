import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  root: {
    padding: '10mm',
    paddingBottom: '15mm',
    paddingHorizontal: '10mm',
  },
  informationContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '65mm',
    marginBottom: '0mm',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  informationLabel: {
    fontFamily: 'Sarabun',
    fontSize: '3mm',
    fontWeight: 'bold',
    width: '30mm',
    textAlign: 'left',
  },
  informationValue: {
    fontFamily: 'Sarabun',
    width: '35mm',
    fontSize: '3mm',
    textAlign: 'right',
    flex: 1,
  },
  sla: {
    fontFamily: 'Sarabun',
    width: '9mm',
    fontSize: '4mm',
    border: '1pt solid #000000',
    borderRadius: 4,
    textAlign: 'center',
  },
  tableFooter: {
    width: '190mm',
    maxWidth: '190mm',
    fontFamily: 'Sarabun',
    fontSize: '9pt',
    fontWeight: 'bold',
    flexDirection: 'row',
    marginTop: '2mm',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginHorizontal: '0pt',
  },
  deliveryInformationContainer: {
    width: '190mm',
    maxWidth: '190mm',
    fontFamily: 'Sarabun',
    flexDirection: 'row',
    marginTop: '2mm',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginHorizontal: '0pt',
  },
  mClinicaAddress: {
    fontSize: '3mm',
    fontWeight: 'thin',
    flexWrap: 'wrap',
    width: '65mm',
    maxWidth: '65mm',
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.35,
  },
  addressesContainer: {
    width: '190mm',
    maxWidth: '190mm',
    fontFamily: 'Sarabun',
    flexDirection: 'row',
    marginTop: '5mm',
    alignItems: 'flex-start',
  },
  wholesalerAddress: {
    fontSize: '3mm',
    fontWeight: 'thin',
    width: '48mm',
    maxWidth: '48mm',
  },
  billToAddress: {
    fontSize: '3mm',
    fontWeight: 'thin',
    flexWrap: 'wrap',
    width: '60mm',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '11mm',
  },
  shipToAddress: {
    fontSize: '3mm',
    fontWeight: 'thin',
    flexWrap: 'wrap',
    width: '60mm',
    display: 'flex',
    flexDirection: 'column',
  },
  footerContainer: {
    marginHorizontal: '0pt',
    fontFamily: 'Sarabun',
    fontSize: '7pt',
    marginTop: '5mm',
  },
  noteTitle: {
    fontWeight: 'bold',
    marginBottom: '3mm',
  },
  noteContent: {
    fontWeight: 'thin',
    maxWidth: '100mm',
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '7mm',
  },
  qrcodeContainer: {
    alignItems: 'flex-start',
    flexGrow: 1,
    maxWidth: '70mm',
    width: '70mm',
  },
  qrcode: {
    width: '25mm',
    height: '25mm',
  },
  signatureContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '70mm',
    marginRight: '0mm',
  },
  signatureHead: {
    fontWeight: 'bold',
    textAlign: 'right',
    minWidth: '30mm',
    marginBottom: '3mm',
  },
  signatureTitle: {
    fontWeight: 'bold',
    textAlign: 'right',
    minWidth: '30mm',
    marginBottom: '5mm',
    marginRight: '21mm',
    marginTop: '2mm',
  },
  signatureField: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  signatureBlank: {
    width: '40mm',
    minWidth: '40mm',
    borderBottom: '1pt solid #666666',
    marginBottom: '4mm',
  },
  pageNumber: {
    position: 'absolute',
    bottom: '5mm',
    right: '10mm',
    fontFamily: 'Sarabun',
    fontSize: '3mm',
    fontWeight: 'thin',
  },
});
