import { IInvoiceAllocation } from './interfaces/invoice-allocation.interface';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../..';
import { ISearchable, ListResponse, ISearchParam } from '../../interfaces';

export class SwipeRxPtInvoiceAllocation implements ISearchable<IInvoiceAllocation> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.INVOICE_ALLOCATION;
  }

  async list(params: any): Promise<any> {
    return this.base.get(this.resource, params);
  }

  async search(params: ISearchParam): Promise<ListResponse<IInvoiceAllocation>> {
    return this.base.get(this.resource, { ...params });
  }
}
