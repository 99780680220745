import apiClient from '../services/apiClient.service';

export default {
  state: {
    users: {
      result: [{}],
      count: 0,
      pages: 1,
    },
    user: {},
  },
  reducers: {
    setUsers(state, payload) {
      return { ...state, users: payload };
    },
    setUser(state, payload) {
      return { ...state, user: payload };
    },
  },
  effects: (dispatch) => ({
    async getUserList({ page = 1, query = '' }, rootState) {
      try {
        const result = await apiClient.get(`/admin/users`, {
          page,
          query,
        });
        dispatch.userStore.setUsers(result);
      } catch (e) {
        console.error(e);
        alert(e.message);
      }
    },

    async getUserSpecific({ id }, rootState) {
      try {
        const result = await apiClient.get(`/admin/users/${id}`);
        dispatch.userStore.setUser(result);
      } catch (e) {
        console.error(e);
        alert(e.message);
      }
    },

    async activateUser({ id }, rootState) {
      try {
        await apiClient.post(`/admin/users/${id}/activate`);
      } catch (e) {
        console.error(e);
        alert(e.message);
      }
    },
  }),
};
