import { addReducer } from 'reactn';
import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt/SwipeRxPt.class';
import { recordException } from 'utils/Reporting/Sentry';

export const PAYMENT_METHOD_INITIAL_STATE = {
  loading: false,
};

addReducer('switchToCreditLine', async (_state, _, payload) => {
  const { organizationId, options } = payload;

  try {
    await SwipeRxPt.instance.organizations.updatePaymentMethod(organizationId, {
      new_payment_method: 'CREDIT_LINE',
      options,
    });
  } catch (error) {
    recordException(error, 'switchToCreditLine', { payload });

    throw error;
  }
});
