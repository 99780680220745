import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';

import {
  CreditParams,
  LatestCredit,
  CreditSource,
  CreditBulkUpgradeFilesData,
  ILatestCreditUpgraded,
} from './interfaces';

export class SwipeRxPtCredit {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.CREDIT;
  }

  async fetchCreditList(params: CreditParams): Promise<LatestCredit> {
    return this.base.get<LatestCredit>(`${this.resource}/get-all-pharmacy`, params);
  }

  async upgrade(pharmacyId: number, params: { amount?: number; source: CreditSource }): Promise<ILatestCreditUpgraded> {
    const { data } = await this.base.patch(`${this.resource}/upgrade/${pharmacyId}`, params);

    return data;
  }

  async getBulkUpgradeFiles(params: CreditParams): Promise<CreditBulkUpgradeFilesData> {
    return this.base.get(`${this.resource}/bulk-upgrade-files`, params);
  }

  async uploadBulkUpgradeFile(file: File): Promise<CreditBulkUpgradeFilesData> {
    const formData = new FormData();
    formData.append('file', file);

    return this.base.post(`${this.resource}/bulk-upgrade-files`, formData);
  }
}
