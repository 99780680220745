import React from 'react';

import { ColorType } from 'types';

import { S } from './Badge.styles';

interface Props {
  color: ColorType;
  light?: boolean;
  bg?: string;
}

export const Badge: React.FC<Props> = ({ color, children, light, bg }) => (
  <S.Wrapper color={color} light={light} bg={bg}>
    {children}
  </S.Wrapper>
);
