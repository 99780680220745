import React from 'react';
import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { COLORS } from 'common/constants';

interface TitleProps extends DialogTitleProps {
  primary?: boolean;
}

interface TextProps extends TypographyProps {
  strong?: boolean;
}

const getTitleBackground = ({ primary }: TitleProps): string =>
  primary ? COLORS.primary : COLORS.secondary;

const getTitleColor = ({ primary }: TitleProps): string =>
  primary ? '#fff' : '#000';

const Wrapper = styled(Dialog)`
  && {
    .MuiPaper-root {
      border-radius: 13px;
    }

    .MuiDialogContent-root,
    .MuiDialogActions-root {
      padding: 16px;
    }
  }
`;

const Title = styled(Typography)`
  && {
    font-size: 15px;
  }
`;

const SubTitle = styled(Typography)`
  && {
    display: block;
    padding-top: 10px;
    font-size: 14px;
    font-weight: bold;
  }
`;

const Text = styled<React.ComponentType<TextProps>>(Typography)`
  && {
    font-size: 12px;
    font-weight: ${(props) => (props.strong ? 600 : 400)};
  }
`;

const TitleContainer = styled<React.ComponentType<TitleProps>>(DialogTitle)`
  && {
    background: ${getTitleBackground};
    color: ${getTitleColor};
    font-size: 18px;
    font-weight: 600;
  }
`;

const CloseButtonWrapper = styled.div`
  && {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;

    & > span {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-weight: normal;
      margin-right: 8px;
    }
  }
`;

const CloseButton = styled(IconButton)`
  && {
    background: rgba(0, 0, 0, 0.14);
    color: rgba(0, 0, 0, 0.5);

    svg {
      font-size: 20px;
    }
  }
`;

const LoaderWrapper = styled.div`
  && {
    overflow: hidden;
    padding: 20px 50px;
  }
`;

export const S = {
  Wrapper,
  TitleContainer,
  Title,
  SubTitle,
  Text,
  CloseButtonWrapper,
  CloseButton,

  LoaderWrapper,
};
