import styled from 'styled-components';
import { lighten } from 'polished';

import { ColorType } from 'types';
import { COLORS } from 'common/constants';

interface WrapperProps extends React.HTMLAttributes<HTMLSpanElement> {
  color: ColorType;
  light?: boolean;
  bg?: string;
}

const getWrapperBackground = ({
  color: colorType,
  light,
  bg
}: WrapperProps): string => {
  const color = COLORS[colorType];

  if (bg) {
    return bg;
  }

  if (light) {
    return lighten(0.3, color);
  }

  return color;
};

const getWrapperColor = ({ color: colorType, light }: WrapperProps): string => {
  const color = COLORS[colorType];

  if (light) {
    return color;
  }

  return '#fff';
};

const Wrapper = styled.span`
  && {
    color: ${getWrapperColor};
    background: ${getWrapperBackground};
    border-radius: 24px;
    display: inline-block;
    font-size: 12px;
    padding: 3px 16px;
    margin: 4px;
  }
`;

const Primary = styled(Wrapper)`
  && {
    padding-top: 6px;
    padding-bottom: 6px;
    margin-left: -12px;
    margin-right: 16px;
    font-size: 14px;
  }
`;

export const S = {
  Wrapper,
  Primary,
};
