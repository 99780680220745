import { ButtonToggleFilterData } from 'common/components/ButtonToggleFilter';
import { useUpdateQueryParams } from 'hooks';

type UpdateStatusFilter = (status?: string) => void;

type UseTransactionPrekursorApprovalStatusFilter = () => {
  statusFilterItems: ButtonToggleFilterData[];
  updateStatusFilter: UpdateStatusFilter;
};

export const useTransactionPrekursorApprovalStatusFilter: UseTransactionPrekursorApprovalStatusFilter = () => {
  const statusFilterItems: ButtonToggleFilterData[] = [
    {
      text: 'All Orders',
      value: 'all',
    },
    {
      text: 'Document Required',
      value: 'required',
    },
    {
      text: 'Waiting Approval',
      value: 'submitted,resubmitted',
    },
    {
      text: 'Approved',
      value: 'approved',
    },
    {
      text: 'Rejected',
      value: 'rejected',
    },
    {
      text: 'Cancelled',
      value: 'cancelled',
    },
  ];

  const { updateQueryParams, queryParams } = useUpdateQueryParams();
  const { page_size } = queryParams;
  const defaultPageSize = page_size ? +page_size : 50;
  const defaultPage = 1;

  const updateStatusFilter: UpdateStatusFilter = (status?: string): void => {
    updateQueryParams({
      ...queryParams,
      page: defaultPage,
      precursor_status: status,
      page_size: defaultPageSize,
      only_document_pending: 'true',
    } as Record<string, any>);
  };

  return {
    statusFilterItems,
    updateStatusFilter,
  };
};
