import React from 'react';
import styled from 'styled-components';

interface Props {
  url: string;
  width?: string;
  height?: string;
};

const PdfFrame = styled.iframe`
  min-height: 400px;
`;

export const PdfViewer: React.FC<Props> = ({ url, width, height }) => {
  return (
    <PdfFrame
      title="tax-photo"
      src={`${url}#toolbar=0&view=FitH`}
      height={height || "100%"}
      width={width || "100%"}
    />
  );
}
