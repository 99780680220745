import React, { useDispatch, useEffect, useGlobal, useState } from 'reactn';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useUpdateQueryParams, useGetAuthenticatedUser } from 'hooks';
import {
  OrganizationAutoComplete,
  OrganizationType,
} from 'common/components/async-auto-complete/organization-auto-complete';

import {
  DistributorTitlePage,
  DistributorTitleSource,
  SetDistributorDispatch,
  FetchDistributorDispatch,
  DistributorDataTable,
  VIEW_ALL_DISTRIBUTOR,
  PARTIAL_ACCESS_ROLES,
  VIEW_ALL_STATUS_DISTRIBUTOR,
} from './DistributorTitle.constant';
import { Style } from './DistributorTitle.style';

interface DistributorTitleProps {
  page: DistributorTitlePage;
}

interface TitleProps {
  text: string;
  onClick: () => void;
}

const Title: React.FC<TitleProps> = ({ text, onClick }) => (
  <div>
    <Style.Title onClick={onClick}>{text}</Style.Title>
  </div>
);

export const DistributorTitle: React.FC<DistributorTitleProps> = ({ page }) => {
  const {
    queryParams: { distributor_id },
    updateQueryParams,
  } = useUpdateQueryParams();

  const user = useGetAuthenticatedUser();

  const [showTextField, setShowTextField] = useState(false);

  const [{ distributor }] = useGlobal(DistributorTitleSource[page]);

  const fetchDataTableRows = useDispatch('fetchDataTableRows');
  const resetTransactionRowCount = useDispatch('resetTransactionRowCount');

  const fetchDistributor = useDispatch(FetchDistributorDispatch[page]);
  const setDistributor = useDispatch(SetDistributorDispatch[page]);

  const userHasPartialAccess = page === 'FINANCE' ? false : PARTIAL_ACCESS_ROLES[page].includes(user.role as any);

  useEffect(() => {
    if (userHasPartialAccess) {
      if (user.organization_id && Number(distributor_id) !== user.organization_id) {
        fetchDistributor({
          id: parseInt(user.organization_id.toString(), 10),
        });
        return () => null;
      }
    }

    if (distributor_id) {
      fetchDistributor({
        id: parseInt(distributor_id as string, 10),
      });
    }

    return () => {
      setDistributor(null);
    };
  }, [setDistributor, distributor_id, fetchDistributor, user.organization_id, userHasPartialAccess]);

  const handleBlur = (): void => {
    setShowTextField(false);
  };

  const handleChange = ({ id, name }: { id: number; name: string }): void => {
    const selectedId = id.toString();
    resetTransactionRowCount({});
    setShowTextField(false);
    updateQueryParams({ distributor_id: selectedId });
    setDistributor({ id, name });
    fetchDataTableRows({
      name: DistributorDataTable[page],
      params: { distributor_id: id },
      // cancelPendingRequest: true,
    });
  };

  const handleTitleClick = (): void => {
    if (userHasPartialAccess) return;
    setShowTextField(true);
  };

  const handleClearClick = (): void => {
    resetTransactionRowCount({});
    setShowTextField(false);
    updateQueryParams({ distributor_id: null });
    setDistributor(null);
    fetchDataTableRows({
      name: DistributorDataTable[page],
      params: { distributor_id: null },
      // cancelPendingRequest: true,
    });
  };

  const renderTitle = (title: string): JSX.Element => <Title text={title} onClick={handleTitleClick} />;

  const isOnInputMode = showTextField || distributor.error;

  if (isOnInputMode) {
    return (
      <div style={{ width: 330, paddingBottom: 15 }}>
        <OrganizationAutoComplete
          variant="primary"
          autoFocus
          type={OrganizationType.DISTRIBUTOR}
          selectInput={handleChange}
          onBlur={handleBlur}
          allStatus={VIEW_ALL_STATUS_DISTRIBUTOR[page]}
        />
      </div>
    );
  }

  const isViewingAllDistributorsAllowed =
    page === 'FINANCE' ? true : VIEW_ALL_DISTRIBUTOR[page].includes(user.userType);

  if (!distributor.data) {
    if (isViewingAllDistributorsAllowed) return renderTitle('All Distributors');
    return null;
  }

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
      <Grid item>{renderTitle(distributor.data.name)}</Grid>
      {!isViewingAllDistributorsAllowed ? null : (
        <Grid item>
          <IconButton size="small" onClick={handleClearClick}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
