import React, { useGlobal, useDispatch, Suspense, useEffect } from 'reactn';
import { Router, Route, Switch, useRouteMatch } from 'react-router-dom';

import { connect } from 'react-redux';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ColorType } from 'types';

import { MainRouteContainer } from 'routes/MainRoute.container';
import { useValidateUserRole } from 'hooks';
import SideBar from '../components/SideBar/SideBar.component';
import ErrorContainer from './Error/Error.container';
import LoadOverlay from '../components/LoaderOverlay';
import '../../App.scss';
import { removeTrailingSlash } from '../utils/url.util';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    overflowX: 'hidden',
  },
}));

const Main = (props) => {
  const [{ snackbar }] = useGlobal('utils');

  const { authStore, history, uiStore, match } = props;
  const { params } = useRouteMatch();
  const classes = useStyles();
  const fetchMarketConfig = useDispatch('fetchMarketConfig');

  const { role, isValid } = useValidateUserRole();

  useEffect(() => {
    fetchMarketConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSnackbar = useDispatch('setUtilsSnackbar');
  const closeSnackbar = useDispatch('closeSnackbar');

  useEffect(() => {
    if (!isValid) {
      setSnackbar({
        open: true,
        variant: ColorType.DANGER,
        message: `Incorrect ${authStore.data.userType} role, returning back to ${role} role`,
        onCloseSnackbar: () => {
          closeSnackbar({});
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, authStore.data.userType, role]);


  // TEMPORARY: This will be needed until the PageWrapper component is used
  // in all page components.
  const background = history.location.pathname === '/transactions' ? '#fafafa' : '#fff';

  if (!params.marketId) {
    return null;
  }

  return (
    <>
      {uiStore.isLoading ? <LoadOverlay /> : null}
      <div className={classes.root} style={{ background }}>
        <SideBar username={authStore.data.username} role={authStore.data.userType} />
        <main className={classes.content}>
          <Router history={history}>
            <Switch>
              <Suspense fallback={<LoadOverlay />}>
                <MainRouteContainer prefix={removeTrailingSlash(match.path)} />
              </Suspense>
              <Route component={ErrorContainer} />
            </Switch>
          </Router>
        </main>
        <Snackbar
          data-testid="snackbar-content"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={snackbar.open}
          message={snackbar.message}
          onClose={snackbar.onCloseSnackbar}
          autoHideDuration={snackbar.autoHideDuration}
        >
          <SnackbarContent
            style={snackbar.contentProps}
            message={
              <span id="client-snackbar" data-testid="snackbar-message">
                {snackbar.message}
              </span>
            }
          />
        </Snackbar>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  authStore: state.authStore,
  uiStore: state.uiStore,
});

const mapDispatchToProps = ({ authStore, uiStore }) => {
  return {
    ...authStore,
    ...uiStore,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
