import React, { useEffect, useState } from 'react';
import { Grid, DialogContent, Dialog, DialogTitle } from '@material-ui/core';

import { useModal } from 'hooks/useModal';
import { Image, RotateRight } from '@material-ui/icons';
import { Colors } from 'common/themes/colors';
import { ObjectFitProperty } from 'csstype';
import { ZoomInIcon } from 'common/components/Icon';
import { ConditionalRender, DocumentViewer } from 'common/components';

interface Props {
  imageSource: string;
  imageHeight?: string | number;
  imageWidth?: string | number;
  objectFitMode?: ObjectFitProperty;
  isPdf?: boolean;
}

export const ImagePreview: React.FC<Props> = ({
  imageSource,
  imageHeight = 260,
  imageWidth,
  objectFitMode = 'unset',
  isPdf = false,
}) => {
  const [hasError, setHasError] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [translate, setTranslate] = useState('');
  const modal = useModal({});

  const handleError = (): void => setHasError(true);

  const errorImage = (
    <div style={{ color: Colors.lightGray as string, paddingBottom: '5%' }}>
      <Grid item xs={12}>
        <Image style={{ height: 260, margin: 'auto', fontSize: '20.3em' }} />
      </Grid>
      <Grid item xs={12}>
        Image Not Found
      </Grid>
    </div>
  );

  useEffect(() => {
    setHasError(false);
  }, [imageSource]);

  return (
    <>
      <Grid
        container
        direction="row"
        style={{
          height: '100%',
          width: '100%',
          border: '0.5px solid lightgray',
          borderRadius: '8px',
          display: 'flex',
        }}
      >
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justify="center"
          style={{ height: '90%', textAlign: 'center', position: 'relative' }}
        >
          {isPdf ? (
            <>
              <div style={{ height: 40 }} />
              <DocumentViewer height="100%" url={imageSource} />
            </>
          ) : (
            <img
              src={imageSource}
              alt=""
              onError={handleError}
              style={{
                height: imageHeight,
                width: imageWidth,
                objectFit: objectFitMode,
                margin: 'auto',
              }}
            />
          )}
          <ZoomInIcon
            style={{
              paddingRight: 20,
              paddingBottom: 20,
              cursor: 'pointer',
              position: 'absolute',
              right: 0,
              top: 10,
            }}
            onClick={modal.openModal}
          />
        </Grid>
      </Grid>
      <Dialog
        maxWidth="xl"
        open={modal.isOpen}
        onClose={() => {
          modal.closeModal();
          setRotate(0);
          setTranslate('');
        }}
        disableBackdropClick={false}
      >
        <DialogTitle>
          <ConditionalRender condition={!isPdf}>
            <RotateRight
              style={{
                paddingRight: 20,
                paddingBottom: 20,
                cursor: 'pointer',
                position: 'relative',
                right: 0,
                top: 10,
              }}
              onClick={() => {
                const newDegree = rotate + 90 === 360 ? 0 : rotate + 90;
                const translate: any = {
                  0: '',
                  90: 'translateY(-100%)',
                  180: 'translateX(100%) translateY(-100%)',
                  270: 'translateX(150%) translateY(-20%)',
                };
                setRotate(newDegree);
                setTranslate(translate[newDegree]);
              }}
            />
          </ConditionalRender>
        </DialogTitle>
        <DialogContent
          style={{
            position: 'relative',
            overflow: 'auto',
            height: '1000px',
            width: '1000px',
          }}
        >
          {isPdf ? (
            <DocumentViewer height="100%" url={imageSource} />
          ) : (
            <img
              src={imageSource}
              alt=""
              style={{
                width: '110%',
                height: 'auto',
                margin: 'auto',
                transformOrigin: 'left bottom',
                transform: `${translate} rotate(${rotate}deg)`,
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
