/* eslint-disable no-param-reassign */

import { FORM_DATA_HEADERS_CONFIG } from 'config/headers';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import {
  Homesquare,
  HomesquareList,
  HomesquareParams,
  HomesquareSubmissionForm,
  HomesquareVisibilityForm,
} from './interfaces';

export class SwipeRxPtHomesquare {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.HOMESQUARE;
  }

  async fetchHomesquares(params?: HomesquareParams): Promise<HomesquareList> {
    return this.base.get<HomesquareList>(`${this.resource}`, params);
  }

  async upsertHomesquare(params: HomesquareSubmissionForm): Promise<Homesquare> {
    const { file, ...restParams } = params;
    const form = new FormData();
    form.append('homesquare', JSON.stringify(restParams));
    form.append('file', file);

    const { data } = restParams?.id
      ? await this.base.patch(`${this.resource}/${restParams?.id}`, form, FORM_DATA_HEADERS_CONFIG)
      : await this.base.post(`${this.resource}`, form, FORM_DATA_HEADERS_CONFIG);
    return data;
  }

  async updateHomesquareVisibility(params: HomesquareVisibilityForm): Promise<Homesquare> {
    const form = new FormData();
    form.append('homesquare', JSON.stringify({ visibility: params.visibility, deactivate_at: params.deactivate_at }));

    const { data } = await this.base.patch(`${this.resource}/${params.id}`, form);
    return data;
  }

  async delete(id: number): Promise<void> {
    return this.base.delete(`${this.resource}/${id}`);
  }
}
