/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDispatch, useGlobal } from 'reactn';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export interface OrderExportToDeliveryPartnerFormState {
  logistics_delivery_partner_id: number;
}

const formState: OrderExportToDeliveryPartnerFormState = {
  logistics_delivery_partner_id: -1
};

export const useOrderExportToDeliveryPartnerForm = () => {
  const [{ exportToDeliveryPartner }] = useGlobal('transactions');
  const setOrderIdsForExportToDeliveryPartner = useDispatch('setOrderIdsForExportToDeliveryPartner');
  const sendToDispatcherDeliveryPartner = useDispatch('sendToDispatcherDeliveryPartner');
  
  const clearOrderIds = (): void => {
    setOrderIdsForExportToDeliveryPartner({ orderIds: [] });
  };

  const form = useFormik({
    initialValues: {
      ...formState,
    },
    validationSchema: Yup.object({
      logistics_delivery_partner_id: Yup.number().required('Delivery Partner field is required.'),
    }),
    onSubmit: async ({ logistics_delivery_partner_id }, { setSubmitting }) => {
      await sendToDispatcherDeliveryPartner({ logistics_delivery_partner_id });
      setSubmitting(false);
      clearOrderIds();
    },
  });

  return { 
    form, 
    exportToDeliveryPartner, 
    clearOrderIds, 
  };
};
