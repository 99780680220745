import React from 'react';
import { Button, Dialog } from 'common/components';
import { OrderReturnType } from 'common/services/apis/v3/swipe-rx-pt';
import { ColorType } from 'types';
import { OrderReturnConfirmationModalProps } from './interfaces';

export const OrderReturnConfirmationModal: React.FC<OrderReturnConfirmationModalProps> = ({
  form,
  items,
  data: { orderDetails },
  type,
  steps: { step, clearSteps },
}) => {
  const isOpen =
    (type === OrderReturnType.FULL && step === 3) ||
    (type === OrderReturnType.PARTIAL && step === 4);

  const noOfItemsToReturn = Object.keys(items).reduce((acc, id) => {
    if (items[id].quantity && items[id].selected) {
      acc.push(id);
    }
    return acc;
  }, [] as string[]);

  const renderContent = (): JSX.Element => (
    <div style={{ textAlign: 'center', width: 350 }}>
      {type === OrderReturnType.FULL
        ? `Are you sure you want to mark the order as returned for PO Number ${orderDetails[0].po_number}?`
        : `Are you sure you want to mark the order as returned for ${
            noOfItemsToReturn.length > 1
              ? `${noOfItemsToReturn.length} items`
              : `${noOfItemsToReturn.length} item`
          } PO Number ${
            orderDetails.length === 1 ? orderDetails[0].po_number : ''
          }?`}
    </div>
  );

  const renderActions = (): JSX.Element => (
    <>
      <Button isSecondary color={ColorType.DANGER} onClick={clearSteps}>
        Cancel
      </Button>
      <Button
        color={ColorType.PRIMARY}
        onClick={form.handleSubmit}
        form="order-return-form"
      >
        Confirm
      </Button>
    </>
  );

  return (
    <Dialog
      open={isOpen}
      primary
      title="Confirmation"
      onClose={clearSteps}
      renderActions={renderActions}
      renderContent={renderContent}
      showCloseButton={false}
    />
  );
};
