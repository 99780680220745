import React from 'react';

import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';

import { ColorType } from 'types';

import { S } from './Button.styles';
import { ConditionalRender } from '../ConditionalRender';

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  color: ColorType;
  isSecondary?: boolean;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  ...restProps
}) => (
  <S.Wrapper {...restProps}>
    <ConditionalRender condition={!!loading}>
      <S.Loader size={16} />
    </ConditionalRender>
    {children}
  </S.Wrapper>
);
