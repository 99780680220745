import React from 'react';

import Grid from '@material-ui/core/Grid';

import { CardTablePageChangeHandler } from '../../../types';

import { S } from './PageNumber.styles';

interface Props {
  current: number;
  pageSize: number;
  disabled?: boolean;
  dataLength?: number;
  onChange?: CardTablePageChangeHandler;
  isNoRow?: boolean;
}

export const CardTablePageNumberNoCount: React.FC<Props> = ({
  current,
  disabled,
  pageSize,
  onChange,
  dataLength,
  isNoRow,
}) => {
  const isOnFirstPage = current <= 1;
  const isOnLastPage = dataLength !== undefined && dataLength < pageSize;
  const handlePreviousClick = (): void => {
    if (onChange && !isOnFirstPage && !disabled) {
      if (isNoRow) {
        onChange(current > 1 ? current - 1 : 1);
      } else {
        onChange(current - pageSize >= 0 ? current - pageSize : 0);
      }
    }
  };

  const handleNextClick = (): void => {
    if (onChange && !isOnLastPage && !disabled) {
      if (isNoRow) {
        onChange(current + 1);
      } else {
        onChange(current + pageSize);
      }
    }
  };

  return (
    <S.Wrapper>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <S.Previous disabled={!!disabled || isOnFirstPage} onClick={handlePreviousClick} data-testid="previous-btn" />
          <S.Text disabled={!!disabled || isOnFirstPage}>
            <S.Inactive>Prev</S.Inactive>
          </S.Text>
        </Grid>
        <Grid item>
          <S.Text disabled={!!disabled || isOnLastPage}>
            <S.Inactive>Next</S.Inactive>
          </S.Text>
          <S.Next disabled={!!disabled || isOnLastPage} onClick={handleNextClick} data-testid="next-btn" />
        </Grid>
      </Grid>
    </S.Wrapper>
  );
};
