export enum ColorType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  GRAY = 'gray',
  WHITE = 'white',
  BLACK = 'black',
  PINK = 'pink',

  // 10 shades of grey
  GREY_50 = 'GREY_50',
  GREY_100 = 'GREY_100',
  GREY_200 = 'GREY_200',
  GREY_300 = 'GREY_300',
  GREY_400 = 'GREY_400',
  GREY_500 = 'GREY_500',
  GREY_600 = 'GREY_600',
  GREY_700 = 'GREY_700',
  GREY_800 = 'GREY_800',
  GREY_900 = 'GREY_900',

  // Custom color
  GREEN_YELLOW = 'GREEN_YELLOW',
}
