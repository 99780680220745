import React from 'react';
import { Button, Typography, Grid, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const styles = (theme) => ({
  selectedButton: {
    padding: theme.spacing(2),
  },
  button: {
    padding: theme.spacing(2),
    borderColor: grey[600],
    color: grey[600],
  },
  label: {
    textTransform: 'lowercase',
    textAlign: 'left',
    fontWeight: 500,
  },
});

const DashboardCard = ({
  classes,
  value = 0,
  selected = false,
  label = 'unknown',
  onClick = () => { },
}) => {
  return (
    <Button
      variant="outlined"
      color={selected ? 'primary' : 'inherit'}
      classes={{
        root: classes.selectedButton,
        colorInherit: classes.button,
      }}
      fullWidth
      onClick={onClick}
      data-testid="dashboard-card"
    >
      <Grid container>
        <Grid item xs={7} container alignItems="center" justify="center">
          <Typography variant="h4">{value}</Typography>
        </Grid>
        <Grid item xs={5} container alignItems="center" justify="flex-end">
          <Typography variant="body2" classes={{ root: classes.label }}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

export default withStyles(styles)(DashboardCard);
