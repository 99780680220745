import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { COLORS } from 'common/constants';
import { ColorType } from 'types';

interface PrimaryRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  hasFooter?: boolean;
  highlight?: ColorType;
}

const cellPadding = '24px 10px';

const getHighlight = ({ highlight }: PrimaryRowProps): string | undefined =>
  !highlight
    ? undefined
    : `inset 4px 0px 0px 0px rgba(247,77,77,1);
box-shadow: inset 4px 0px 0px 0px ${COLORS[highlight]}`;

const wrapperStyleWithNoFooter = css`
  &:first-of-type {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    tr {
      td:first-child {
        border-top-left-radius: 7px;
      }

      td:last-child {
        border-top-right-radius: 7px;
      }
    }
  }

  &:last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;

    tr {
      td:first-child {
        border-bottom-left-radius: 7px;
      }

      td:last-child {
        border-bottom-right-radius: 7px;
      }
    }
  }
`;

const getWrapperStyle = ({
  hasFooter,
}: PrimaryRowProps): FlattenSimpleInterpolation | null =>
  !hasFooter ? wrapperStyleWithNoFooter : null;

const getWrapperBorderRadius = ({
  hasFooter,
}: PrimaryRowProps): string | undefined => (hasFooter ? '7px' : undefined);

const getPrimaryRowTdLeftRadius = ({
  hasFooter,
}: PrimaryRowProps): FlattenSimpleInterpolation | null =>
  !hasFooter
    ? null
    : css`
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      `;

const getPrimaryRowTdRightRadius = ({
  hasFooter,
}: PrimaryRowProps): FlattenSimpleInterpolation | null =>
  !hasFooter
    ? null
    : css`
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
      `;

const Wrapper = styled.tbody`
  && {
    background: #fff;
    border-radius: ${getWrapperBorderRadius};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.23);

    ${getWrapperStyle}
  }
`;

const PrimaryRow = styled.tr<PrimaryRowProps>`
  && {
    td {
      padding: ${cellPadding};
    }

    td {
      border-bottom: 1px solid rgba(221, 221, 221, 0.39);
    }

    td:first-child {
      ${getPrimaryRowTdLeftRadius}
      box-shadow: ${getHighlight};
      padding-left: 16px;
    }

    td:last-child {
      ${getPrimaryRowTdRightRadius}
      padding-right: 16px;
    }
  }
`;

const RowSeparator = styled.tbody`
  && {
    tr td {
      padding: 12px 0;
    }
  }
`;

const CheckboxCell = styled.td`
  &&&& {
    padding: ${cellPadding};
    text-align: center;
  }
`;

export const S = {
  Wrapper,
  PrimaryRow,
  RowSeparator,
  CheckboxCell,
};
