import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { S } from './field-file-upload.styles';

interface Props {
  label: string;
  active: boolean;
  onRemove: () => void;
}

export const FieldFileUploadControls: React.FC<Props> = ({
  label,
  active,
  onRemove,
}) => (
  <Grid
    container
    justify="space-between"
    alignContent="center"
    alignItems="center"
  >
    <S.FormTitle>{label}</S.FormTitle>
    {active ? (
      <Button onClick={onRemove}>
        <span style={{ color: 'red' }}>remove</span>
      </Button>
    ) : null}
  </Grid>
);
