import React from 'react';
import { useGlobal } from 'reactn';

import { useGetAuthenticatedUser } from 'hooks';
import { updateConfiguredModulesByFeatureConfigs } from 'common/reducers/market/config.utils';
import { IModuleConfig } from 'common/reducers';

import { Routes } from './routes.component';
import { routeConfig } from './routes.config';

export const MainRouteContainer: React.FC<{ prefix: string }> = ({ prefix }) => {
  const user = useGetAuthenticatedUser();
  const authenticated = !!user.session_token;
  const [
    {
      market: { modules, featureConfigs },
      paymentMethod: { voucher },
    },
  ] = useGlobal('config');

  let configuredModules: IModuleConfig = {
    ...modules,
    voucher: {
      'view-module': (modules.voucher['view-module'] as Function)(voucher.enabled),
    },
  };

  configuredModules = updateConfiguredModulesByFeatureConfigs(configuredModules, featureConfigs)

  return <Routes authenticated={authenticated} routes={routeConfig(prefix, configuredModules)} userRole={user.role} />;
};
