import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = () => ({
  root: {
    backgroundColor: 'rgba(50,50,50,0.2)',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1600,
  },
});

const LoaderOverlay = ({ classes }) => {
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      classes={{ root: classes.root }}
    >
      <CircularProgress size={120} thickness={3} />
    </Grid>
  );
};

export default withStyles(styles)(LoaderOverlay);
