import React from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  withStyles,
} from '@material-ui/core';
import { yellow, green, red } from '@material-ui/core/colors';

const styles = (theme) => ({
  info: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  success: {
    backgroundColor: green[500],
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: 'white',
  },
  warn: {
    backgroundColor: yellow[700],
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  error: {
    backgroundColor: red[600],
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: 'white',
  },
  cardContent: {
    '&:last-child': {
      padding: theme.spacing(2),
    },
  },
});

const StatusCard = ({
  children = '',
  actions = null,
  variant = 'info',
  classes,
}) => {
  const cardStyles = (variant) => {
    switch (variant) {
      case 'info':
        return { root: classes.info };
      case 'success':
        return { root: classes.success };
      case 'warn':
        return { root: classes.warn };
      case 'error':
        return { root: classes.error };
      default:
        return { root: classes.info };
    }
  };
  return (
    <Card classes={cardStyles(variant)}>
      <CardContent classes={{ root: classes.cardContent }}>
        <Typography variant="body1">{children}</Typography>
      </CardContent>
      {actions ? <CardActions>{actions}</CardActions> : null}
    </Card>
  );
};

export default withStyles(styles)(StatusCard);
