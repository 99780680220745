import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { S } from './TabFilter.style';
import { OrderDetailsResponse, PrecursorDetailTabTypes } from './PrecursorDetail.type';
import DocumentInformationPage from './DocumentInformationPage.component';
import TabPanel from '@material-ui/lab/TabPanel';
import DocumentHistoryLogPage from './DocumentHistoryLog.component';
import { TabContext } from '@material-ui/lab';
import { IPrecursorStatusHistory } from 'common/reducers/precursor/interfaces/PrecursorState.interface';

type Props = {
  value?: any;
  enableCreditFilter?: boolean;
  enableReturnFilter?: boolean;
  onChange: (method: PrecursorDetailTabTypes) => void;
  setApproveButtonDisabled: (disable: boolean) => void;
  setRejectButtonDisabled: (disable: boolean) => void;
  orderDetails?: OrderDetailsResponse;
  precursorStatusHistory?: IPrecursorStatusHistory[];
};

export const TabFilter: React.FC<Props> = (props) => {
  const {
    value = 'document-info',
    onChange,
    setApproveButtonDisabled,
    setRejectButtonDisabled,
    orderDetails,
    precursorStatusHistory,
  } = props;
  const handleChange = (event: React.ChangeEvent<{}>, selected: PrecursorDetailTabTypes): void => {
    event.preventDefault();
    onChange(selected);
  };
  return (
    <S.Wrapper>
      <TabContext value={value}>
        <Tabs indicatorColor="primary" value={value} onChange={handleChange} aria-label="Precursor Detail">
          <Tab label="Document Info" value="document-info" data-testid="document-info-tab" />
          <Tab label="Document History Log" value="document-history-log" data-testid="document-history-log-tab" />
        </Tabs>
        <TabPanel style={{ padding: '20px 0px 20px 0px' }} value="document-info">
          <DocumentInformationPage
            orderDetails={orderDetails}
            precursorStatusHistory={precursorStatusHistory}
            setApproveButtonDisabled={(disable) => setApproveButtonDisabled(disable)}
            setRejectButtonDisabled={(disable) => setRejectButtonDisabled(disable)}
          />
        </TabPanel>
        <TabPanel style={{ padding: '20px 0px 20px 0px' }} value="document-history-log">
          <DocumentHistoryLogPage precursorStatusHistory={precursorStatusHistory} orderDetails={orderDetails} />
        </TabPanel>
      </TabContext>
    </S.Wrapper>
  );
};
