import moment from 'moment-timezone';
import { parse, isDate } from 'date-fns';
import { getMarketIdTimezone } from './get-market-timezone.util';

export const formatToDatetime = (value: Date | string, format?: string): string => {
  const timezone = moment.tz.guess();
  const result = moment
    .tz(value, 'UTC')
    .tz(timezone)
    .format(format || 'MMM DD, YYYY hh:mm A');

  return result;
};

export const setToMarketTimezone = (market: string, value: string | Date | moment.Moment): moment.Moment | null => {
  if (!value) {
    return null;
  }

  const timezone = getMarketIdTimezone(market);

  const result = moment(value).tz(timezone, true);
  return result;
};

export const stringToMarketMoment = (market: string, value: string | Date): moment.Moment => {
  const timezone = getMarketIdTimezone(market);

  const result = moment.tz(value, 'UTC').tz(timezone);
  return result;
};

export const stringToMarketTime = (market: string, value: Date | string, format?: string): string => {
  const result = stringToMarketMoment(market, value);

  const stringFormatted = result.format(format || 'MMM DD, YYYY hh:mm A');

  return stringFormatted;
};

export const parseDateString = (originalValue: string | undefined): string | Date => {
  return originalValue ? (isDate(originalValue) ? originalValue : parse(originalValue, 'yyyy-MM-dd', new Date())) : '';
};

export const getTimezone = (): string => {
  const timezone = moment.tz.guess();
  switch (timezone) {
    case 'Asia/Jakarta':
      return 'jkt';
    case 'Asia/Manila':
      return 'mnl';
    default:
      return (timezone.split('/').pop() as string).slice(0, 3);
  }
};
