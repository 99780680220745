import { IVoucherType, IMutableVoucherType } from 'common/interfaces/VoucherType.interface';
import { CSVUploadResult } from 'common/components/CsvUpload/interfaces/csv-upload.interface';
import { IVoucherUsage } from 'common/interfaces/VoucherUsage.interface';
import { IVocherRedemptionSummary } from 'common/interfaces/VocherRedemptionSummary.interface';
import { VOUCHER_PRICE_TYPE, VOUCHER_VALUE_TYPE } from 'common/constants';
import { IVoucher } from 'common/interfaces/Voucher.interface';
import { ISearchable, ListResponse, ISearchParam, SuccessResponse } from '../../interfaces';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { IMutable } from '../../interfaces/IMutable.interface';
import { VoucherTypeListExportParams } from './interfaces/VoucherTypeListExportParams.interface';

export class SwipeRxPtVoucherType implements ISearchable<IVoucherType>, IMutable<IMutableVoucherType> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.VOUCHER_TYPE;
  }

  async list(params: any): Promise<ListResponse<IVoucherType>> {
    return this.base.get(this.resource, { ...params, include_summary: true, expand: ['voucher'] });
  }

  async detail(id: number): Promise<IVoucherType> {
    const response = await this.base.get(`${this.resource}/${id}`);

    return response.data;
  }

  async createVoucher(voucherType: IVoucherType | null): Promise<{ vouchers: IVoucher[]; voucherType: IVoucherType }> {
    if (!voucherType) throw new Error('Empty payload');

    const payload: Record<string, any> = {
      title: voucherType.title,
      description: voucherType.description,
      priceType: voucherType.price_type || VOUCHER_PRICE_TYPE.CASH,
      price: typeof voucherType.price === 'string' ? parseInt(voucherType.price, 10) : voucherType.price || 0,
      valueType: voucherType.value_type || VOUCHER_VALUE_TYPE.FIXED_AMOUNT,
      value: typeof voucherType.value === 'string' ? parseFloat(voucherType.value) : voucherType.value || 0,
      activeStartDate: voucherType.active_start_date,
      activeEndDate: voucherType.active_end_date,
      rules: voucherType.rules,
      appliedTo: voucherType.applied_to,
      voucher: voucherType.voucher,
      isVisible: voucherType.is_visible,
      visibleStartDate: voucherType.visible_start_date,
      visibleEndDate: voucherType.visible_end_date,
    };

    const { data } = await this.base.post(this.resource, payload);
    return data;
  }

  async updateVoucher(voucherType: IVoucherType | null): Promise<{ vouchers: IVoucher[]; voucherType: IVoucherType }> {
    if (!voucherType) throw new Error('Empty payload');

    const payload: Record<string, any> = {
      title: voucherType.title,
      description: voucherType.description,
      priceType: voucherType.price_type || VOUCHER_PRICE_TYPE.CASH,
      price: typeof voucherType.price === 'string' ? parseInt(voucherType.price, 10) : voucherType.price || 0,
      valueType: voucherType.value_type || VOUCHER_VALUE_TYPE.FIXED_AMOUNT,
      value: typeof voucherType.value === 'string' ? parseFloat(voucherType.value) : voucherType.value || 0,
      activeStartDate: voucherType.active_start_date,
      activeEndDate: voucherType.active_end_date,
      isVisible: voucherType.is_visible,
      visibleStartDate: voucherType.visible_start_date,
      visibleEndDate: voucherType.visible_end_date,
      rules: voucherType.rules,
      appliedTo: voucherType.applied_to,
      voucher: voucherType.voucher,
    };

    const { data } = await this.base.patch(`${this.resource}/${voucherType.id}`, payload);
    return data;
  }

  async activate(id: number): Promise<SuccessResponse<IVoucherType>> {
    return this.base.patch(`${this.resource}/${id}/activate`, {});
  }

  async deactivate(id: number): Promise<SuccessResponse<IVoucherType>> {
    return this.base.patch(`${this.resource}/${id}/deactivate`, {});
  }

  async redemptionHistory(id: number, params: any): Promise<ListResponse<IVoucherUsage>> {
    return this.base.get(`${this.resource}/${id}/redemption`, params);
  }

  async redemptionSummary(id: number): Promise<IVocherRedemptionSummary> {
    const response = await this.base.get(`${this.resource}/${id}/redemption-summary`, {});

    return response.data;
  }

  async createOrUpdate(data: IMutableVoucherType): Promise<any> {
    return this.base.post(`${this.resource}`, data);
  }

  async search(params: ISearchParam, resourceOverride?: string): Promise<ListResponse<IVoucherType>> {
    return this.base.get(resourceOverride || this.resource, { ...params });
  }

  async export(params?: VoucherTypeListExportParams): Promise<void> {
    const { voucher_type_ids } = params || {};

    await this.base.get(`${this.resource}/export`, {
      ...params,
      voucher_type_ids: voucher_type_ids && voucher_type_ids.join(','),
    });
  }

  async uploadCsv(
    file: File,
  ): Promise<{
    data: CSVUploadResult;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );
    return this.base.post(`${this.resource}/upload`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async uploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`${this.resource}/upload/history`, {
      page,
      page_size,
    });
  }

  async delete(id: number): Promise<any> {
    return this.base.delete(`${this.resource}/${id}`);
  }
}
