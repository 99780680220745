import React from 'react';
import Select from 'react-select';

import { defaultStyle } from './sync-auto-complete.styles';
import { SyncAutoCompleteProps } from './sync-auto-complete.interface';

export const SyncAutoComplete: React.FC<SyncAutoCompleteProps> = ({
  styles,
  options,
  name,
  isDisabled,
  isClearable,
  isSearchable,
  isLoading,
  noOptionsMessage,
  height,
  value,
  autoFocus,
  onBlur,
  onChange,
}) => (
  <Select
    name={name}
    value={value ? value : {}}
    autoFocus={autoFocus}
    onBlur={onBlur}
    styles={styles || defaultStyle}
    isDisabled={isDisabled}
    isLoading={isLoading}
    maxMenuHeight={height || 140}
    noOptionsMessage={noOptionsMessage}
    isClearable={isClearable}
    isSearchable={isSearchable}
    onChange={onChange}
    options={options}
  />
);
