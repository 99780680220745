/* eslint-disable no-param-reassign */
import React from 'reactn';

import {
  Button,
  Dialog,
  FieldWrapper,
} from 'common/components';
import { ColorType } from 'types';

import { fieldOptions } from './order-export-to-delivery-partner-form.config';
import { useOrderExportToDeliveryPartnerForm } from './use-order-export-to-delivery-partner-form';
import { OrderDeliveryPartnerAutoCompleteOption } from './order-delivery-partner-autocomplete.component';

const formId = 'order-export-to-delivery-partner-form';

export const OrderExportToDeliveryPartnerModal: React.FC = () => {
  const {
    form,
    exportToDeliveryPartner: { orderDetails, orderIds },
    clearOrderIds,
  } = useOrderExportToDeliveryPartnerForm();

  const renderContent = (): JSX.Element => (
    <>
      <form id={formId} onSubmit={form.handleSubmit} style={{ minHeight: '40vh' }}>
        {fieldOptions.map(({ name, component: Field, label }) => {
          const handleChange = (option: OrderDeliveryPartnerAutoCompleteOption): void => {
            form.setFieldValue(name, option.value);
          };

          return (
            <FieldWrapper
              key={name}
              touched={form.touched[name]}
              error={form.errors[name]}
              label={label}
            >
              <Field
                selectInput={handleChange}
              />
            </FieldWrapper>
          );
        })}
      </form>
    </>
  );
  const renderActions = (): JSX.Element => (
    <Button
      color={ColorType.PRIMARY}
      disabled={!form.dirty || form.isSubmitting}
      loading={form.isSubmitting}
      form={formId}
      type="submit"
    >
      Submit
    </Button>
  );

  return (
    <Dialog
      open={!!orderIds.length}
      primary
      title="Export for Delivery Partner"
      onClose={clearOrderIds}
      renderActions={renderActions}
      renderContent={renderContent}
      subTitle={orderIds.length > 1 ? 'Selected' : 'Order ID'}
      value={
        orderIds.length > 1
          ? `${orderIds.length} Orders`
          : orderDetails.length === 1
          ? orderDetails[0].po_number
          : ''
      }
    />
  );
};
