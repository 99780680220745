import React from 'react';

import { S } from './OrderDetailsModal.styles';

interface Props {
  name: string;
  description: string;
}

export const BasicProductDetails: React.FC<Props> = ({ name, description }) => (
  <S.Product>
    {name}
    <S.ItemDescription>{description}</S.ItemDescription>
  </S.Product>
);
