import styled from 'styled-components';

interface HeaderProps {
  isButtonUpperRightSide?: boolean;
}

const HeaderForm = styled.div<HeaderProps>`
  display: flex;
  justify-content: ${(props) => (props?.isButtonUpperRightSide ? 'space-between' : 'flex-start')} !important;
  align-items: center;
`;

export const S = { HeaderForm };
