import React, { EffectCallback, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import {
  MaintenanceStateContext,
  MaintenanceDispatchContext,
  MaintenanceActions,
  maintenanceReducer,
  initialState,
} from './context';
import { getMaintenanceConfigDB } from './firebase';
import { emptyCacheStorage } from './utils';

interface WithMaintenanceProps {
  maintenancePath: string;
  history: any;
}

const WithMaintenanceMode: React.FC<WithMaintenanceProps> = (props) => {
  const { children } = props;

  const location = useLocation();
  const [state, dispatch] = useReducer(maintenanceReducer, initialState);

  const ref = getMaintenanceConfigDB();

  // listen firebase maintenance config data
  useEffect((): ReturnType<EffectCallback> => {
    if (ref) {
      ref.on('value', (snapshot) => {
        const config = snapshot.val();
        dispatch({
          type: MaintenanceActions.CONFIG_LOAD,
          config,
        });
      });

      // unsubscribe firebase listener
      return (): void => {
        ref.off('value');
      };
    }

    return undefined;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMaintenanceMode = (): boolean => state.config?.enabled || false;
  const isMaintenancePage = (): boolean => location.pathname.includes(props.maintenancePath);

  if (isMaintenancePage() && !isMaintenanceMode()) {
    Promise.resolve(emptyCacheStorage()).finally(() => {
      props.history.push({ pathname: '/' });
    });
  }

  if (!isMaintenancePage() && isMaintenanceMode()) {
    props.history.push({ pathname: props.maintenancePath });
  }

  return (
    <MaintenanceStateContext.Provider value={state}>
      <MaintenanceDispatchContext.Provider value={dispatch}>{children}</MaintenanceDispatchContext.Provider>
    </MaintenanceStateContext.Provider>
  );
};

export default WithMaintenanceMode;
