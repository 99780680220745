import backlogService from '../services/apis/v2/services/backlog.service';

export interface IParamsCommon {
  page_size?: number;
  page?: number;
  sort_by?: string;
  order_by?: string;
}

interface IParamsPendingDeposits extends IParamsCommon {
  status: string;
}

interface IParamsOverdueInvoices extends IParamsCommon {
  due_at: string;
}

export default {
  state: {
    pending_deposits: {
      result: [],
      page: 0,
      page_size: 0,
      total: 0,
      sort_by: '',
      order_by: '',
    },
    overdue_invoices: {
      result: [],
      page: 0,
      page_size: 0,
      total: 0,
      sort_by: '',
      order_by: '',
    },
  },
  reducers: {
    setPendingDeposits(state: any, payload: any) {
      return { ...state, pending_deposits: payload };
    },
    setOverdueInvoices(state: any, payload: any) {
      return { ...state, overdue_invoices: payload };
    },
  },
  effects: (dispatch: any) => ({
    async getPendingDeposits(params: IParamsPendingDeposits) {
      // console.log('rootState ::', rootState.backlogStore.pending_deposits);
      const payload = await backlogService.fetchPendingDeposits({
        status: params.status,
        page_size: params.page_size,
        page: params.page,
        sort_by: params.sort_by,
        order_by: params.order_by,
      });

      const setData = {
        result: payload && payload.result.deposit ? payload.result.deposit : [],
        page: payload && payload.result.meta ? payload.result.meta.page : 0,
        page_size:
          payload && payload.result.meta ? payload.result.meta.page_size : 0,
        total:
          payload && payload.result.meta ? payload.result.meta.total_count : 0,
        sort_by: params.sort_by,
        order_by: params.order_by,
      };

      dispatch.backlogStore.setPendingDeposits(setData);
    },

    async getOverdueInvoices(params: IParamsOverdueInvoices) {
      const { due_at, page_size, page, sort_by, order_by } = params;

      const payload = await backlogService.fetchOverdueInvoices({
        due_at,
        page_size,
        page,
        sort_by,
        order_by,
      });

      dispatch.backlogStore.setOverdueInvoices({
        ...payload,
        order_by,
        sort_by,
      });
    },
  }),
};
