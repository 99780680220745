/* eslint-disable class-methods-use-this */

import stores from '../../../../models/index.model';

import { PtAPIv2 } from '../utils';
import { TIMEOUT_50_SECS } from '../utils/api.constants';

class AdminService {
  async recalculatePayments(
    pharmacyId: number,
    pharmacyIds: number[] = [],
    callbacks: { successCallback?: () => void; errorCallback?: (message: string) => void },
  ): Promise<void> {
    const { successCallback, errorCallback } = callbacks;

    stores.dispatch.uiStore.showLoading(true);
    try {
      const result: any = await PtAPIv2.dashboard.post(
        `payment/recalculate`,
        { pharmacyId, pharmacyIds },
        TIMEOUT_50_SECS,
      );

      const resetNotAllowed = (result.resetNotAllowed || []).filter((item: any) => item.pharmacy_id === pharmacyId);
      if (!!resetNotAllowed[0]) {
        throw Error(`${resetNotAllowed[0].reason}`);
      }
      stores.dispatch.uiStore.showLoading(false);

      if (successCallback) successCallback();
    } catch (e) {
      if (errorCallback) errorCallback((e as Error).message);
    } finally {
      // always executed
      stores.dispatch.uiStore.showLoading(false);
    }
  }
}

export default new AdminService();
