/* eslint-disable no-param-reassign */

import { FORM_DATA_HEADERS_CONFIG } from 'config/headers';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import {
  Searchsquare,
  SearchsquareList,
  SearchsquareParams,
  SearchsquareSubmissionForm,
  SearchsquareVisibilityForm,
} from './interfaces';

export class SwipeRxPtSearchsquare {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.SEARCHSQUARE;
  }

  async fetchSearchsquares(params?: SearchsquareParams): Promise<SearchsquareList> {
    return this.base.get<SearchsquareList>(`${this.resource}`, params);
  }

  async upsertSearchsquare(params: SearchsquareSubmissionForm): Promise<Searchsquare> {
    const { file, ...restParams } = params;
    const form = new FormData();
    form.append('searchsquare', JSON.stringify(restParams));
    form.append('file', file);

    const { data } = restParams?.id
      ? await this.base.patch(`${this.resource}/${restParams?.id}`, form, FORM_DATA_HEADERS_CONFIG)
      : await this.base.post(`${this.resource}`, form, FORM_DATA_HEADERS_CONFIG);
    return data;
  }

  async updateSearchsquareVisibility(params: SearchsquareVisibilityForm): Promise<Searchsquare> {
    const form = new FormData();
    form.append(
      'searchsquare',
      JSON.stringify({
        visibility: params.visibility,
        marketing_id: params.marketing_id,
        deactivate_at: params.deactivate_at,
      }),
    );

    const { data } = await this.base.patch(`${this.resource}/${params.id}`, form);
    return data;
  }

  async delete(id: number): Promise<void> {
    return this.base.delete(`${this.resource}/${id}`);
  }
}
