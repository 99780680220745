/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-globals */
import React, { useGlobal } from 'reactn';

import chunk from 'lodash/chunk';
import moment from 'moment-timezone';
import QRCode from 'qrcode.react';
import numeral from 'numeral';

import { toCurrency } from 'utils';
import { DataObject } from 'types';

import { Order } from 'common/reducers';
import { ConditionalRender } from 'common/components';
import { formatToPercentage } from 'common/utils/formatters';
import { PaymentMethod, PaymentProvider } from 'common/constants';

import { PaperTypeInfo } from '../../interfaces';
import { PAPERSIZE } from '../../constants';

import { S } from './SingleOrderData.styles';

export const SingleOrderData: React.FC<{
  order: Order;
  paperSize: PaperTypeInfo;
  isLastOrder: boolean;
}> = ({ order, isLastOrder = true, paperSize = PAPERSIZE.A4 }) => {
  const [config] = useGlobal('config');
  const { marketId, paymentMethod } = config;

  const taxRate = order.tax_rate;
  const useRounding = paymentMethod?.directPayment.provider === PaymentProvider.DOKU;

  const taxRatePercentage = taxRate * 100;

  const items = order.items || [];
  const getChunkLimit = (item: DataObject): number => {
    const defaultChunkLimit = 8;
    let productNames = '';

    for (let i = 0; i < defaultChunkLimit && i < item.length; i += 1) {
      productNames += item[i].product?.name;
    }
    return productNames.length < 320 ? defaultChunkLimit : defaultChunkLimit - 1;
  };

  const chunkedItems: any = chunk(Array.from(items as any), getChunkLimit(items));

  const getComputedSinglePageStyle = (page: number): { pageSize: number; marginBottom: number } => {
    const defaultMarginPercentage = 0.0606;
    const isLastPage = isLastOrder && page + 1 === chunkedItems.length;
    const marginPerPagePx = paperSize.pixel * defaultMarginPercentage;
    const pageSize = paperSize.pixel - marginPerPagePx;
    return {
      pageSize,
      marginBottom: !isLastPage ? marginPerPagePx : 0,
    };
  };

  const toMarketPaymentWiseAmount = (amount: number, roundingRule: 'ceil' | 'round' | 'floor' = 'ceil'): number => {
    const orderPaymentMethod = order.payment_method;

    let finalAmount = amount;

    if (orderPaymentMethod === PaymentMethod.DIRECT_PAYMENT && useRounding) {
      if (roundingRule === 'ceil') finalAmount = Math.ceil(amount);
      if (roundingRule === 'round') finalAmount = Math.round(amount);
      if (roundingRule === 'floor') finalAmount = Math.floor(amount);
    }

    return finalAmount;
  };

  const calculateAmountBeforeTax = (net_amount: number, tax_rate: number): number => {
    const total = toMarketPaymentWiseAmount(net_amount * (1 + tax_rate));
    return toMarketPaymentWiseAmount(total / (1 + tax_rate), 'floor');
  };

  const calculateTax = (net_amount: number, tax_rate: number): number => {
    const total = toMarketPaymentWiseAmount(net_amount * (1 + tax_rate));
    return toMarketPaymentWiseAmount((total * tax_rate) / (1 + tax_rate), 'ceil');
  };

  const calculateAmountAfterTax = (net_amount: number, tax_rate: number): number => {
    const total = toMarketPaymentWiseAmount(net_amount * (1 + tax_rate));
    return total;
  };

  const grandTotal = toMarketPaymentWiseAmount(order.net_amount || 0);
  const pharmacy = order.pharmacy || {};

  const isShowExternalID = marketId === 'th';

  const renderPrimaryData = (): JSX.Element | null => {
    if (Object.keys(pharmacy).length) {
      return (
        <S.PrimaryDataContainer>
          <S.Address>
            <div>
              <strong>
                {pharmacy.id} - {pharmacy.name}
              </strong>
              <p className="address">{pharmacy.address}</p>
            </div>
            <div className="po-qrcode">
              <QRCode value={`PT-PO-${order.po_number}`} size={125} />
            </div>
          </S.Address>
          <S.OrderDetails>
            <div className="order-meta-data">
              <div className="row">
                <strong>Order ID: </strong>
                <span>{order.po_number}</span>
              </div>
              <div className="row">
                <strong>Order Date: </strong>
                <span>{moment(order.ordered_at).format('YYYY-MM-DD hh:mm:ss')}</span>
              </div>
            </div>
            <div className="order-totals-container">
              <S.TotalRow>
                <p className="label">Grand Total</p>
                <p className="value">
                  <strong>{toCurrency(calculateAmountBeforeTax(grandTotal, taxRate))}</strong>
                </p>
              </S.TotalRow>
              <ConditionalRender condition={taxRate > 0}>
                <S.TotalRow>
                  <p className="label">VAT ({taxRatePercentage.toFixed(0)}%)</p>
                  <p className="value">{toCurrency(calculateTax(grandTotal, taxRate))}</p>
                </S.TotalRow>
                <S.TotalRow>
                  <p className="label">Total After VAT ({numeral.localeData().currency.symbol})</p>
                  <p className="value">{toCurrency(calculateAmountAfterTax(grandTotal, taxRate))}</p>
                </S.TotalRow>
              </ConditionalRender>
            </div>
          </S.OrderDetails>
        </S.PrimaryDataContainer>
      );
    }

    return null;
  };

  return (
    <>
      {chunkedItems.map((chunkedItem: any, page: number) => (
        <S.SinglePage key={`${chunkedItem[0].id}_container`} {...getComputedSinglePageStyle(page)}>
          {renderPrimaryData()}
          <S.MainTable>
            <tbody>
              <tr>
                <th>Flag</th>
                <th>SKU Code</th>
                <ConditionalRender condition={isShowExternalID}>
                  <th>Dist. SKU</th>
                </ConditionalRender>
                <th>Package</th>
                <th>Product</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Discount</th>
                <th>Net Price</th>
                <th>Subtotal</th>
                <th>Price min qty</th>
              </tr>
            </tbody>
            <tbody>
              {chunkedItem.map((item: any) => {
                const [dp] = item.product?.distributor_products;
                const flag = dp?.flag || '';

                return (
                  <tr key={item.id}>
                    <td className="center">{flag}</td>
                    <td>{item.product?.sku_code}</td>
                    <ConditionalRender condition={isShowExternalID}>
                      <td>{dp?.external_id || '-'}</td>
                    </ConditionalRender>
                    <td className="package">{item.product?.package}</td>
                    <td className="product">{item.product?.name}</td>
                    <td className="center">{item.quantity}</td>
                    <td>{toCurrency(item.selling_price)}</td>
                    <td>{formatToPercentage(item.discount_rate)}</td>
                    <td>{toCurrency(item.net_price)}</td>
                    <td>{toCurrency(item.net_price * item.quantity)}</td>
                    <td className="center">{item.min_promo}</td>
                  </tr>
                );
              })}
            </tbody>
          </S.MainTable>
          <S.Footer>
            <S.HorizontalRule />
            <S.Pagination>{`${order.po_number} (${page + 1}/${chunkedItems.length})`}</S.Pagination>
          </S.Footer>
        </S.SinglePage>
      ))}
    </>
  );
};
