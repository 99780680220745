import React from 'react';

import { S } from './TableFilterCard.styles';

export const TableFilterCard: React.FC = ({ children }) => (
  <S.Wrapper>
    {React.Children.map(children, (child) => (
      <S.ItemWrapper>{child}</S.ItemWrapper>
    ))}
  </S.Wrapper>
);
