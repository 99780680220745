/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useSidebarMenuStyles = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      list: {
        backgroundColor: theme.palette.primary.main,
        flexGrow: 1,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        overflow: 'auto',
      },
      listItem: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(0.75),
        marginBottom: theme.spacing(0.75),
        color: theme.palette.primary.light,
        borderRadius: theme.spacing(1),
      },
      listItemText: {
        fontSize: 14,
      },
      listItemSelected: {
        color: theme.palette.primary.contrastText,
      },
      icon: {
        color: theme.palette.primary.light,
      },
      iconSelected: {
        color: theme.palette.primary.contrastText,
      },
    }),
  );

  const classes = useStyles();

  return classes;
};
