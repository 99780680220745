/* eslint-disable consistent-return */
import { addReducer } from "reactn"
import { RootState } from "..";


addReducer('setLoyaltyLoading', (state, _, loading: boolean): RootState => {
  const { loyalty } = state;

  return {
    ...state,
    loyalty: {
      ...loyalty,
      loading,
    }
  }
});

addReducer('setLoyaltyImageIcon', (state, _, image: string | undefined): RootState => {
  const { loyalty } = state;
  const imageIcon = image;

  return {
    ...state,
    loyalty: {
      ...loyalty,
      imageIcon,
    }
  }
});

addReducer('setAddLoyaltyMission', (state, _, params): RootState => {
  const { loyalty } = state;
  const addLoyaltyMission = params;
  return {
    ...state,
    loyalty: {
      ...loyalty,
      addLoyaltyMission,
    },
  };
});

addReducer('setLoyaltyMissionEditMode', (state, _, isEditMode): RootState => {
  const { loyalty } = state;
  return {
    ...state,
    loyalty: {
      ...loyalty,
      isEditMode,
    },
  };
});

addReducer('setLoyaltyMissionSummary', (state, _, loyaltyMissionSummary): RootState => {
  const { loyalty } = state;
  return {
    ...state,
    loyalty: {
      ...loyalty,
      loyaltyMissionSummary,
    },
  };
});
