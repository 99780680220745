import keyBy from 'lodash/keyBy';
import { DataTable } from 'common/reducers';

export const getSelectedDocumentsByIds = (
  ids: number[],
  dataTables: Record<string, DataTable>,
): any[] => {
  const indexedOrders = keyBy(dataTables['document-management'].rows, 'id');
  const selectedOrders: any[] = [];

  ids.forEach((id) => selectedOrders.push(indexedOrders[id]));

  return selectedOrders;
};
