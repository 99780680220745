/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback } from 'reactn';

import moment from 'moment';
import numeral from 'numeral';
import get from 'lodash/get';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CalendarToday from '@material-ui/icons/CalendarToday';

import { ColorType } from 'types';
import { toCurrency } from 'utils';

import { getFieldOption } from '../order-invoice-form.config';

import S from './OrderInvoiceDialog.styles';

import {
  ContentProps,
  DataGridPaginationProps,
  DialogActionsProps,
  DateTime,
  CompactOrderItem,
  DataGridColumns,
  MAX_ADDRESS_LENGTH,
  MAX_NAME_LENGTH,
} from './OrderInvoiceDialog.types';

import { calculatePageModel, calculateTotalPrice, transformRows, updatePolicy } from './OrderInvoiceDialog.utils';

export const DataGridPagination: React.FC<DataGridPaginationProps> = ({
  totalCount,
  page,
  pageSize,
  onNext,
  onPrev,
}) => {
  const { startIndex, lastIndex, totalPages, pageIndex } = calculatePageModel(totalCount, page, pageSize);

  return (
    <S.PaginationGroup>
      <S.PaginationItemNumber>
        {startIndex} - {lastIndex} of {totalCount}
      </S.PaginationItemNumber>
      <S.ChangePageButton onClick={onPrev}>
        <ChevronLeft />
      </S.ChangePageButton>
      <S.PaginationPageNumber>
        <span className="current-page">{pageIndex}</span>
        <span>&nbsp;of {totalPages}</span>
      </S.PaginationPageNumber>
      <S.ChangePageButton onClick={onNext}>
        <ChevronRight />
      </S.ChangePageButton>
    </S.PaginationGroup>
  );
};

export const DialogActions: React.FC<DialogActionsProps> = ({
  form,
  formId,
  order,
  marketId,
  onConfirmation,
  onClose,
  dialogVisibility,
}) => {
  const renderFormAction = (): JSX.Element => {
    const truthyValidation = (): boolean =>
      Object.keys(form.values)
        .filter((item) => item !== 'id')
        .map((key) => !!form.values[key])
        .every((item) => item);

    return (
      <Grid container alignItems="flex-start" justify="flex-end" spacing={3}>
        <Grid item>
          <S.TotalPrice>
            Total ({numeral.localeData().currency.symbol})
            <span className="total-price-currency ">&nbsp;{toCurrency(form.values.invoiced_amount, '(0,0.00)')}</span>
          </S.TotalPrice>
        </Grid>
        <Grid item>
          <Tooltip
            title={updatePolicy(order, marketId) ? 'save invoice' : 'Invoice is no longer editable'}
            aria-label="save-or-update-invoice"
          >
            <div>
              <S.Button
                color={ColorType.PRIMARY}
                disabled={
                  !form.dirty ||
                  form.isSubmitting ||
                  Object.keys(form.errors).length > 0 ||
                  !truthyValidation() ||
                  !updatePolicy(order, marketId)
                }
                onClick={onConfirmation}
              >
                Save
              </S.Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const renderConfirmationAction = (): JSX.Element => {
    return (
      <Grid container alignItems="flex-start" justify="flex-end" spacing={3}>
        <Grid item>
          <S.Button color={ColorType.DANGER} variant="text" onClick={onClose} width={56} danger>
            Cancel
          </S.Button>
        </Grid>
        <Grid item>
          <S.Button width={56} color={ColorType.PRIMARY} loading={form.isSubmitting} form={formId} type="submit">
            Confirm
          </S.Button>
        </Grid>
      </Grid>
    );
  };

  return <>{dialogVisibility ? renderFormAction() : renderConfirmationAction()}</>;
};

export const Content: React.FC<ContentProps> = ({ formId, form, order, orderDetails, marketId, dialogVisibility }) => {
  const [datePickerVisibility, setDatePickerVisibility] = useState(false);

  // Pagination state
  const [rows, setRows] = useState<CompactOrderItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);

  const openDatePicker = (): void => {
    setDatePickerVisibility(true);
  };
  const closeDatePicker = (): void => {
    setDatePickerVisibility(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setDate = (dateTime: DateTime, shouldValidate = true): void => {
    form.setFieldValue('invoiced_at', moment.tz(dateTime, 'UTC').toISOString(), shouldValidate);
  };

  const getDateValue = (format?: string): moment.Moment | string => {
    const localTimezone: string = moment.tz.guess();

    const dateTime = moment.tz(form.values.invoiced_at, 'UTC').tz(localTimezone);
    return format ? dateTime.format(format) : dateTime;
  };

  const setField = (name: string, shouldValidate = true): React.ChangeEventHandler<HTMLInputElement> => (e) => {
    form.setFieldValue(name, e.target.value, shouldValidate);
  };

  const setPaginationState = useCallback(
    (selectedPage: number): void => {
      const { startIndex, lastIndex, pageIndex } = calculatePageModel(
        orderDetails?.data?.items?.length as number,
        selectedPage,
        pageSize,
      );

      setPage(pageIndex);

      if (orderDetails?.data?.items) {
        const currentRows = transformRows(orderDetails?.data?.items?.slice(startIndex - 1, lastIndex));

        setRows([...currentRows]);
      }
    },
    [orderDetails, pageSize],
  );

  const onPreviousPage = (): void => {
    const selectedPage = page - 1;
    setPaginationState(selectedPage);
  };

  const onNextPage = (): void => {
    const selectedPage = page + 1;
    setPaginationState(selectedPage);
  };

  useEffect(() => {
    // Set invoice date
    if (order?.invoice?.invoiced_at) {
      setDate(order.invoice.invoiced_at, false);
    } else {
      const localTimezone: string = moment.tz.guess();
      const ordered_at = moment(order?.ordered_at)
        .subtract(moment().tz(localTimezone).utcOffset(), 'minutes')
        .toISOString();

      setDate(ordered_at, false);
    }

    // Set pharmacy name
    if (order?.invoice?.custom_information?.pharmacy?.name)
      form.setFieldValue('pharmacy_name', order.invoice.custom_information.pharmacy.name, false);
    else {
      form.setFieldValue('pharmacy_name', order?.pharmacy?.legal_name || order?.pharmacy?.name, false);
    }

    // Set pharmacy address
    if (order?.invoice?.custom_information?.pharmacy?.address)
      form.setFieldValue('pharmacy_address', order.invoice.custom_information.pharmacy.address, false);
    else {
      form.setFieldValue('pharmacy_address', order?.pharmacy?.address, false);
    }

    // Set invoiced amount
    if (orderDetails?.data?.items) {
      const invoiced_amount = calculateTotalPrice(orderDetails);
      form.setFieldValue('invoiced_amount', invoiced_amount, false);
    }

    // Set pagination state
    setPaginationState(page);
  }, [order, orderDetails]);

  const renderInvoiceNumberField = (): JSX.Element => {
    return (
      <Grid container alignItems="center">
        <Grid item direction="row">
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            Invoice No. #
          </Typography>
        </Grid>
        <Grid item>
          <S.TitleField
            name={getFieldOption('invoice_number').name}
            placeholder={getFieldOption('invoice_number').placeholder}
            value={form.values.invoice_number}
            onChange={setField('invoice_number')}
            error={!!form.errors.invoice_number}
            helperText={form.errors.invoice_number}
            disabled={!updatePolicy(order, marketId)}
          />
        </Grid>
      </Grid>
    );
  };

  const renderWholesalerField = (): JSX.Element => {
    return (
      <S.UneditableWrapper>
        <S.Label>Wholesaler</S.Label>
        <S.OutlinedInput disabled value={order?.distributor?.name} />
      </S.UneditableWrapper>
    );
  };

  const renderBillToField = (): JSX.Element => {
    return (
      <Grid item xs={6}>
        <S.EditableWrapper>
          <S.Label>{getFieldOption('pharmacy_name').label}</S.Label>
          <S.OutlinedInput
            name={getFieldOption('pharmacy_name').name}
            placeholder={getFieldOption('pharmacy_name').placeholder}
            value={get(form.values, 'pharmacy_name')}
            onChange={setField('pharmacy_name')}
            disabled={!updatePolicy(order, marketId)}
            inputProps={{ maxLength: MAX_NAME_LENGTH }}
          />
          <S.MaxCharLabel>
            {get(form.values, 'pharmacy_name')?.length}/{MAX_NAME_LENGTH}
          </S.MaxCharLabel>
        </S.EditableWrapper>
      </Grid>
    );
  };

  const renderShipToField = (): JSX.Element => {
    return (
      <Grid item xs={6}>
        <S.EditableWrapper>
          <S.Label>{getFieldOption('pharmacy_address').label}</S.Label>
          <S.OutlinedInput
            multiline
            rowsMax={4}
            name={getFieldOption('pharmacy_address').name}
            placeholder={getFieldOption('pharmacy_address').placeholder}
            value={get(form.values, 'pharmacy_address')}
            onChange={setField('pharmacy_address')}
            disabled={!updatePolicy(order, marketId)}
            inputProps={{ maxLength: MAX_ADDRESS_LENGTH }}
          />
          <S.MaxCharLabel>
            {get(form.values, 'pharmacy_address')?.length}/{MAX_ADDRESS_LENGTH}
          </S.MaxCharLabel>
        </S.EditableWrapper>
      </Grid>
    );
  };

  const renderDateField = (): JSX.Element => {
    return (
      <Grid container direction="row" alignItems="center">
        <Grid item xs={2}>
          <S.Text weight="normal" shade="gray">
            Date:
          </S.Text>
        </Grid>
        <Grid item xs={9}>
          <S.Text weight="normal">{getDateValue('MMM DD YYYY HH:mm z')}</S.Text>
        </Grid>
        <Grid item xs={1}>
          <S.IconButton onClick={openDatePicker} disabled={!updatePolicy(order, marketId)}>
            <CalendarToday style={{ fontSize: 12 }} />
          </S.IconButton>
          <S.DateTimePicker
            margin="normal"
            id="date-time-picker-dialog"
            label="Date time picker dialog"
            minDate={order?.ordered_at}
            value={getDateValue}
            open={datePickerVisibility}
            onChange={setDate}
            onClose={closeDatePicker}
            disabled={!updatePolicy(order, marketId)}
          />
        </Grid>
      </Grid>
    );
  };

  const renderPurchaseOrderNumberField = (): JSX.Element => {
    return (
      <div style={{ marginTop: 15 }}>
        <S.Label>Purchase Order No.</S.Label>
        <S.OutlinedInput disabled value={order?.po_number} />
      </div>
    );
  };

  const renderPaymentMethodField = (): JSX.Element => {
    return (
      <div style={{ marginTop: 15 }}>
        <S.Label>Payment Terms</S.Label>
        <S.OutlinedInput disabled value={order?.payment_method} />
      </div>
    );
  };

  const renderTableHeader = (): JSX.Element => {
    return (
      <Grid container direction="row" alignItems="center" style={{ marginTop: 10 }}>
        <Grid item xs>
          <S.Label>Order Details</S.Label>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <DataGridPagination
            totalCount={orderDetails?.data?.items?.length as number}
            page={page}
            pageSize={pageSize}
            onPrev={onPreviousPage}
            onNext={onNextPage}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <form id={formId} onSubmit={form.handleSubmit} style={{ display: dialogVisibility ? 'block' : 'none' }}>
        <Grid container direction="row">
          <Grid item xs={8}>
            {renderInvoiceNumberField()}
            {renderWholesalerField()}

            <Grid container spacing={0}>
              {renderBillToField()}
              {renderShipToField()}
            </Grid>
          </Grid>
          <S.RightSection>
            {renderDateField()}
            {renderPurchaseOrderNumberField()}
            {renderPaymentMethodField()}
          </S.RightSection>
        </Grid>

        {renderTableHeader()}

        <S.DataGridWrapper>
          <S.DataGrid rows={rows} columns={DataGridColumns} />
        </S.DataGridWrapper>
      </form>
      {!dialogVisibility && (
        <>
          <S.Text weight="normal" style={{ fontSize: 18, textAlign: 'center' }}>
            Are you sure to update invoice <br />
            number <span style={{ color: '#40AE96' }}>{form.values.invoice_number}</span>?
          </S.Text>
        </>
      )}
    </>
  );
};
