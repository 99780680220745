import styled, { css } from 'styled-components';
import { COLORS } from 'common/constants';

const labelStyle = css`
  display: block;
  color: #828282;
  font-size: 14px;
  padding: 5px 0;
`;

const Wrapper = styled.div`
  table {
    margin: 0;

    td {
      min-height: 145px;
    }
  }

  .button-toggle {
    display: block;
    margin: 0 0 8px 0;
    padding: 5px 0;
    width: 150px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const BasicDetailsWrapper = styled.div`
  margin-bottom: 10px;
  padding: 10px;
`;

const Label = styled.span`
  ${labelStyle}
`;

const Product = styled.div`
  max-width: 250px;
  text-transform: uppercase;
`;

const ItemDescription = styled.span`
  ${labelStyle}
  margin-bottom: 20px;
`;

const DiffCellWrapper = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PreviousValueHeader = styled.span`
  color: ${COLORS.primary};
  display: block;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const PreviousValue = styled.span`
  color: #9399a8;
  display: block;
  font-size: 14px;
  text-decoration-line: line-through;
`;

const Stockout = styled.span`
  cursor: default;
  display: block;
  border: 3px solid #ff5050;
  color: #ff5050;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 800;
  padding: 8px;
  width: 120px;
`;

export const S = {
  Wrapper,
  BasicDetailsWrapper,
  Label,
  Product,
  ItemDescription,
  DiffCellWrapper,
  PreviousValueHeader,
  PreviousValue,
  Stockout,
};
