/* eslint-disable global-require */
import { init, RematchRootState } from '@rematch/core';
import logger from 'redux-logger';

const models = {
  uiStore: require('./ui.model').default,
  orderStore: require('./order.model').default,
  invoiceStore: require('./invoice.model').default,
  pharmacyStore: require('./pharmacy.model').default,
  distributorStore: require('./distributor.model').default,
  userStore: require('./user.model').default,
  productStore: require('./product.model').default,
  authStore: require('./auth.model').default,
  backlogStore: require('./backlog.model').default,
  csvDownloadStore: require('./csv-download.model').csvDownload,
  creditsStore: require('./credits.model').default,
};

const middlewares: any = [];

if (process.env.NODE_ENV === 'development' && process.env.REDUX_LOGGER_OFF === '1') {
  middlewares.push(logger);
}

const store = init({
  models,
  redux: { middlewares },
});

export type Store = typeof store;
export type Dispatch = typeof store.dispatch;
export type RootState = RematchRootState<typeof models>;

export default store;
