import React from 'react';
import { Button } from 'common/components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ColorType } from 'types';

interface ButtonLabelProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string;
  color: ColorType;
  style?: any;
  disabled?: boolean;
  isLoading?: boolean;
}

export const ButtonLabel: React.FC<ButtonLabelProps> = ({
  color,
  label,
  onClick,
  disabled,
  isLoading,
}) => {
  return (
    <Button
      color={color}
      onClick={onClick}
      disabled={disabled || isLoading}
      style={{ minWidth: 100, minHeight: 32 }}
    >
      {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : label}
    </Button>
  );
};
