// eslint-disable-next-line no-console
import React, { useEffect, useState } from 'react';
import chunk from 'lodash/chunk';
import intersection from 'lodash/intersection';
import { CircularProgress, Grid } from '@material-ui/core';

import { DataObject, SortOrder } from 'types';
import { ConditionalRender, ActionMenu } from 'common/components';
import { ActionMenuItems, ActionMenuSelectHander } from 'common/components/ActionMenu/types';

import * as C from './components';
import { CardTableColumn } from './interfaces';
import {
  CardTableActionSelectHandler,
  CardTableGetRowHighlight,
  CardTableIsRowDisabled,
  CardTablePageChangeHandler,
  CardTablePageSizeChangeHandler,
  CardTableRowFooter,
  CardTableSelectHandler,
  CardTableSelectionMap,
  CardTableSortHandler,
  CardTableOnAllPagesSelect,
} from './types';
import { executeColumnResizeInterval } from './utils';

import { S } from './CardTable.styles';

interface Props {
  config: {
    actions?: {
      items: ActionMenuItems;
      onSelect?: CardTableActionSelectHandler;
    };
    /* buttons?: {
      items: TransactionActionButtonItems;
      onClick: TransactionActionButtonHandler;
    }; */
    basic?: boolean;
    columns: CardTableColumn[];
    customPagination?: boolean;
    isPaginated?: boolean;
    key?: string;
    pageSize?: {
      defaultValue: number;
      items: number[];
    };
    rowFooter?: CardTableRowFooter;
    scroll?: number;
    selectable?: boolean;
    isAllSelectable?: boolean;
    isNoCount?: boolean;
    isNoRow?: boolean;
  };
  currentPage?: number;
  currentPageCount?: number;
  currentPageSize?: number;
  currentOrderBy?: string;
  currentSortBy?: SortOrder;
  data: DataObject[];
  error?: string | null;
  loading?: boolean;
  getRowHighlight?: CardTableGetRowHighlight;
  isActionLoading?: boolean;
  isRowDisabled?: CardTableIsRowDisabled;
  totalRowCount?: number;
  onSort?: CardTableSortHandler;
  onPageChange?: CardTablePageChangeHandler;
  onPageSizeChange?: CardTablePageSizeChangeHandler;
  onAllPagesSelect?: CardTableOnAllPagesSelect;
  onFetchTotalRowCount?: () => void;
  name?: string;
}

export const CardTable: React.FC<Props> = ({
  config: {
    basic,
    columns,
    customPagination,
    key,
    rowFooter,
    scroll,
    selectable,
    actions = {
      items: [],
    },
    // buttons,
    pageSize: pageSizeConfig = {
      defaultValue: 10,
      items: [5, 10, 15],
    },
    isPaginated = true,
    isAllSelectable,
    isNoCount,
    isNoRow,
  },
  totalRowCount = 0,
  currentPage,
  currentPageCount,
  currentPageSize,
  currentOrderBy,
  currentSortBy,
  error,
  getRowHighlight,
  isActionLoading,
  isRowDisabled,
  loading,
  onSort,
  onPageChange,
  onPageSizeChange,
  onAllPagesSelect,
  onFetchTotalRowCount,
  name,
  data = [],
}) => {
  const [originalSelectionMap, setOriginalSelectionMap] = useState<CardTableSelectionMap>({});
  const [selectionMap, setSelectionMap] = useState<CardTableSelectionMap>({});
  const [selectedAll, setSelectedAll] = useState(false);
  const [hasSelection, setHasSelection] = useState(false);
  const [areAllPagesSelected, setAllPagesSelectionStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const firstRowId = (data[0] || {}).id;
  const lastRowId = (data[data.length - 1] || {}).id;
  const numOfSelectedRows = areAllPagesSelected ? totalRowCount : Object.keys(selectionMap).length;

  useEffect(() => {
    setSelectionMap({});
    setOriginalSelectionMap({});
  }, [key]);

  useEffect(() => {
    const numOfSelectedItems = Object.keys(selectionMap).reduce((currentNum, rowId) => {
      const selected = selectionMap[rowId];

      return selected ? currentNum + 1 : currentNum;
    }, 0);
    const isWholePageSelected = !!data.length && numOfSelectedItems === data.length;
    setSelectedAll(isWholePageSelected);
    if (!isWholePageSelected) {
      setAllPagesSelectionStatus(isWholePageSelected);
      if (onAllPagesSelect) {
        onAllPagesSelect(isWholePageSelected);
      }
    }
    setHasSelection(!!numOfSelectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRowId, lastRowId, data.length, selectionMap]);

  useEffect(() => {
    const fullSelectionMap = { ...originalSelectionMap, ...selectionMap };
    const selectedIds = Object.keys(fullSelectionMap).filter((id) => fullSelectionMap[id]);
    const currentIds = data.map(({ id }) => String(id));
    const commonIds = intersection(currentIds, selectedIds);

    const newSelectionMap = commonIds.reduce(
      (currentMap, id) => ({ ...currentMap, [id]: true }),
      {} as CardTableSelectionMap,
    );

    setOriginalSelectionMap(fullSelectionMap);
    setSelectionMap(newSelectionMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRowId, lastRowId, data.length]);

  const handleSelect: CardTableSelectHandler = (id) => {
    setSelectionMap((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSelectAll = (): void => {
    const newSelectionMap = data.reduce(
      (currentMap, { id }) => ({ ...currentMap, [id]: !selectedAll }),
      {} as CardTableSelectionMap,
    );

    if (!selectedAll && totalRowCount === 0 && onFetchTotalRowCount) onFetchTotalRowCount();
    setSelectionMap(newSelectionMap);
    setSelectedAll((prev) => !prev);
  };

  const handleActionSelect: ActionMenuSelectHander = (name) => {
    const { onSelect } = actions;

    if (!onSelect) {
      return;
    }

    const indices: number[] = [];

    const selectedItems = Object.keys(selectionMap).filter((id) => {
      const selected = selectionMap[id];

      const index = data.findIndex((item) => {
        const idToCompare = typeof item.id === 'number' ? parseInt(id, 10) : id;

        return item.id === idToCompare;
      });

      if (selected) {
        indices.push(index);
      }

      return selected;
    });

    onSelect(name, selectedItems, indices);
  };

  const handlePageChange: CardTablePageChangeHandler = (newPage): void => {
    if (customPagination && onPageChange) {
      onPageChange(newPage);
    }

    setPage(newPage);
  };

  const handlePageSizeChange: CardTablePageSizeChangeHandler = (newPageSize): void => {
    if (customPagination && onPageSizeChange) {
      onPageSizeChange(newPageSize);
    }

    setPageSize(newPageSize);
  };

  const handleAllPagesSelect = (value: boolean): void => {
    if (onAllPagesSelect) {
      onAllPagesSelect(value);
    }
    if (!value) {
      handleSelectAll();
    }
    setAllPagesSelectionStatus(value);
  };

  const headerItems = columns.map(({ id, path, sort, text, width }) => ({
    id,
    path,
    sort,
    text,
    width,
  }));

  useEffect(() => {
    if (!scroll) {
      return;
    }

    const columnResizeInterval = executeColumnResizeInterval({
      columns,
      key,
      selectable,
    });

    // eslint-disable-next-line consistent-return
    return () => clearInterval(columnResizeInterval);
  });

  const fields = columns.map(({ key: fieldKey, id, path, render, type, width }) => ({
    key: fieldKey,
    id,
    path,
    render,
    type,
    width,
  }));

  const defaultPaginatedData = chunk(data, pageSize);

  const pageCount = customPagination ? (currentPageCount as number) : defaultPaginatedData.length;

  if (page > pageCount) {
    setPage(pageCount);
  }

  const finalData = !isPaginated || customPagination ? data : defaultPaginatedData[page - 1] || defaultPaginatedData[0];

  const hasData = !!finalData.length;
  const hasActions = !!actions.items.length;
  // eslint-disable-next-line no-restricted-globals
  // const hasButtons = !!buttons?.items?.length;

  const initialHeaderParams =
    currentOrderBy && currentSortBy
      ? {
          sortKey: currentOrderBy,
          sortOrder: currentSortBy,
        }
      : undefined;

  const renderControls = (): JSX.Element => (
    <ConditionalRender condition={(hasActions || isPaginated) && hasData && !error}>
      <Grid
        container
        direction="row"
        justify={hasActions ? 'space-between' : 'flex-end'}
        alignItems="center"
        spacing={2}
      >
        <ConditionalRender condition={hasActions}>
          <Grid item xs={7}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <ActionMenu
                  data-testid="card-table-action-menu"
                  disabled={!hasSelection}
                  items={actions.items}
                  onSelect={handleActionSelect}
                />
              </Grid>
              <ConditionalRender condition={!!onAllPagesSelect}>
                <Grid item>
                  <C.AllPagesButton
                    selectedAll={selectedAll}
                    areAllPagesSelected={areAllPagesSelected}
                    numOfSelectedRows={numOfSelectedRows}
                    onAllPagesSelect={handleAllPagesSelect}
                    totalRowCount={totalRowCount}
                    name={name}
                    isAllSelectable={isAllSelectable}
                  />
                </Grid>
              </ConditionalRender>
              {/* <ConditionalRender condition={hasButtons}>
                {buttons?.items?.map((button, index) => (
                  <Grid key={`action-button-${index}`} item>
                    <Button color={button.color} onClick={() => buttons?.onClick(button.name)} type="button">
                      {button.text}
                    </Button>
                  </Grid>
                ))}
              </ConditionalRender> */}
            </Grid>
          </Grid>
        </ConditionalRender>
        <ConditionalRender condition={isPaginated}>
          <Grid item xs={5}>
            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
              <ConditionalRender condition={!!loading || !!isActionLoading}>
                <Grid item>
                  <CircularProgress disableShrink size={20} />
                </Grid>
              </ConditionalRender>

              <Grid item>
                <C.PageStat
                  page={currentPage || page}
                  pageSize={currentPageSize || pageSize}
                  totalCount={totalRowCount}
                  literal={isNoCount}
                  onClick={onFetchTotalRowCount}
                  isNoRow={isNoRow}
                  dataLength={data.length}
                />
              </Grid>
              <Grid item>
                <C.PageSize
                  defaultValue={pageSizeConfig.defaultValue}
                  disabled={loading}
                  items={pageSizeConfig.items}
                  onChange={handlePageSizeChange}
                  value={currentPageSize || pageSize}
                />
              </Grid>
              {isNoCount && (
                <Grid item>
                  <C.PageJump currentPage={currentPage || page} onChange={handlePageChange} />
                </Grid>
              )}
              <Grid item>
                {isNoCount ? (
                  <C.PageNumberNoCount
                    current={currentPage || page}
                    onChange={handlePageChange}
                    pageSize={currentPageSize || pageSize}
                    dataLength={data ? data.length : undefined}
                    isNoRow
                  />
                ) : (
                  <C.PageNumber
                    current={currentPage || page}
                    disabled={loading}
                    onChange={handlePageChange}
                    total={pageCount}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </ConditionalRender>
      </Grid>
    </ConditionalRender>
  );

  const colGroup = (
    <colgroup>
      <ConditionalRender condition={!!selectable}>
        <col style={{ width: 62 }} />
      </ConditionalRender>
      {headerItems.map(({ width }) => (
        <col style={{ width }} />
      ))}
    </colgroup>
  );

  const header = (
    <C.Header
      hasSelection={hasSelection}
      initialParams={initialHeaderParams}
      items={headerItems}
      selectable={selectable && hasData}
      selectedAll={selectedAll}
      onSelectAll={handleSelectAll}
      onSort={onSort}
    />
  );

  const body = (
    <C.Body
      data={finalData}
      error={error}
      fields={fields}
      loading={loading}
      getRowHighlight={getRowHighlight}
      isRowDisabled={isRowDisabled}
      rowFooter={rowFooter}
      selectable={selectable}
      selectionMap={selectionMap}
      onSelect={handleSelect}
    />
  );

  let headerClassName = 'card-table-header';
  let bodyClassName = 'card-table-body';

  if (key) {
    headerClassName = `${key} ${headerClassName}`;
    bodyClassName = `${key} ${bodyClassName}`;
  }

  return (
    <>
      {renderControls()}
      {!scroll ? (
        <S.Table id={key} basic={basic} className={headerClassName} disabled={loading && !error} scrollable={!!scroll}>
          {header}
          {body}
        </S.Table>
      ) : (
        <>
          <S.HeaderWrapper style={{ padding: scroll ? '0 5px' : undefined }}>
            <S.Table
              id={key}
              basic={basic}
              className={headerClassName}
              disabled={loading && !error}
              scrollable={!!scroll}
            >
              {colGroup}
              {header}
            </S.Table>
          </S.HeaderWrapper>
          <S.BodyWrapper
            style={{
              maxHeight: scroll,
              padding: scroll ? 5 : undefined,
            }}
            data-testid="order-row"
          >
            <S.Table
              id={key}
              basic={basic}
              className={bodyClassName}
              disabled={loading && !error}
              scrollable={!!scroll}
            >
              {colGroup}
              {body}
            </S.Table>
          </S.BodyWrapper>
        </>
      )}
      {!loading ? renderControls() : null}
    </>
  );
};
