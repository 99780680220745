export * from './ColorType.type';
export * from './DataObject.type';
export * from './SortOrder.type';
export * from './TransactionAction.type';
export * from './TransactionActionItemMapByStatus.type';
export * from './TransactionActionName.type';
export * from './TransactionBulkActionItemMapByStatus.type';
export * from './FinanceAction.type';
export * from './FinanceActionGroup.type';
export * from './FinanceActionName.type';
export * from './FinanceBulkActionItemMapByStatus.type';
export * from './FinanceActionButton.type';
export * from './FinanceActionButtons.type';
export * from './FinanceActionButtonName.type';
export * from './FinanceBulkActionButtonMapByStatus.type';
export * from './FinanceActionButtonHandler.type';
export * from './ProductListActionItemMap';
export * from './ProductListActionName.type';
export * from './ProductListAction.type';
export * from './PurchaseOrderRequirement.type';
export * from './TransactionActionButton.type';
export * from './LogicError.type';
export * from './AllError.type';

export interface Meta {
  count: number;
  limit: number;
  page: number;
}

export type Sort = 'asc' | 'desc';
