/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useSelector, shallowEqual } from 'react-redux';
import { UserRoles } from 'common/constants';

export interface AuthenticatedUser {
  id: number;
  username: string;
  userType: UserRoles;
  organization_id: number | undefined;
  session_token: string;
  role: UserRoles;
  isUserRoleValid: boolean;
}

export const useGetAuthenticatedUser = () => {
  const {
    authStore: { data: user, role, isUserRoleValid },
  } = useSelector((state: any) => state, shallowEqual);

  return {
    ...user,
    role: role ?? user.userType,
    isUserRoleValid
  } as AuthenticatedUser;
};
