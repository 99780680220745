/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import { useUpdateQueryParams } from 'hooks';
import { DataObject } from 'types';
import { ConditionalRender } from 'common/components';
import { ReactComponent as MagnifyingGlass } from 'common/images/magnifying-glass.svg';
import { S } from './SearchWithCriteria.styles';
import { AddedCriteria } from '.';

import { CriteriaOption, Criteria, SearchWithCriteriaProps } from './SearchWithCriteria.interface';

export interface SearchwithCriiteriaRef {
  clearState: () => void;
}

export const SearchWithCriteria = forwardRef<SearchwithCriiteriaRef, SearchWithCriteriaProps>((props, ref) => {
  const {
    // TODO: Pass by reference, Investigate incorrect update of values
    searchCriteriaOptions,
    onChangeCriteria,
    multipleSelectCriteria,
    multipleSelectPrefix,
    placeholder = 'Search...',
    multipleSelectSeparator,
    onSearch,
    onClearAll,
    disabled = false,
    clearOnInit = true,
    enableQueryParamHandling = true,
  } = props || {};

  const [searchCriterias, setSearchCriteria] = useState<Criteria[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchInputHasFocus, setSearchInputFocus] = useState(false);
  const [criteriaOptions, setCriteriaOptions] = useState<CriteriaOption[]>([]);
  const { queryParams, updateQueryParams } = useUpdateQueryParams();

  useEffect(() => {
    if (disabled) return;
    const newCriteriaOptions = searchCriteriaOptions.map((criteriaOption) => {
      return {
        ...criteriaOption,
        isActive: !!queryParams[criteriaOption.id],
        isFocused: false,
      };
    });

    newCriteriaOptions[0].isFocused = true;
    setCriteriaOptions([...newCriteriaOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteriaOptions]);

  const getCriteriaArrayEquivalence = (id: string, name: string, value: string): Criteria[] =>
    value.split(',').reduce<Criteria[]>(
      (acc: Criteria[], val) =>
        val === ''
          ? acc
          : acc.concat({
              id,
              name,
              value: val,
            }),
      [],
    );

  const getSearchCriteriaEquivalent = (_searchCriterias: Criteria[], key: string): Criteria[] => {
    const findSearchCriteriaOptionByType = (type: keyof CriteriaOption): CriteriaOption | undefined =>
      searchCriteriaOptions.find((option: CriteriaOption) => option[type] === key);
    const isDefaultCriteriaOption = findSearchCriteriaOptionByType('id');
    const isParameterMappedCriteriaOption = findSearchCriteriaOptionByType('parameterMapping');
    const criteriaOption: CriteriaOption | undefined = isDefaultCriteriaOption || isParameterMappedCriteriaOption;
    if (!criteriaOption) return _searchCriterias;

    const value = queryParams[key] as string;
    const { id, name, isArray } = criteriaOption;

    const newSearchCriterias: Criteria[] = isArray
      ? getCriteriaArrayEquivalence(id, name, value)
      : [
          {
            id,
            name,
            value,
          },
        ];
    return _searchCriterias.concat(newSearchCriterias);
  };

  useEffect(() => {
    if (disabled || !enableQueryParamHandling) return;
    let _searchCriterias: Criteria[] = [];
    Object.keys(queryParams).forEach((key) => {
      _searchCriterias = getSearchCriteriaEquivalent(_searchCriterias, key);
    });

    setSearchCriteria([..._searchCriterias]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteriaOptions]);

  const handleOnChangeCriteria = (newCriterias: Criteria[]): void => {
    const criteriaObject = newCriterias.reduce((acc: any, { id, value }) => {
      const optionWithMapping = searchCriteriaOptions.find(
        (criteriaOpt: any) => criteriaOpt.id === id && criteriaOpt.parameterMapping,
      );
      const key = optionWithMapping ? (optionWithMapping.parameterMapping as string) : id;
      const isArray = optionWithMapping && optionWithMapping.isArray;
      const finalValue = isArray && acc[key] ? `${value},${acc[key]}` : value;
      acc[key] = finalValue;
      return acc;
    }, {});

    const criteriaResetObject = searchCriteriaOptions.reduce((acc: any, { id, parameterMapping }) => {
      const key = parameterMapping || id;
      acc[key] = null;
      return acc;
    }, {});

    if (enableQueryParamHandling) {
      updateQueryParams({ ...criteriaResetObject, ...criteriaObject });
    }
    if (typeof onChangeCriteria === 'function') {
      return onChangeCriteria(newCriterias);
    }
  };

  const handleOnFocusSearch = (): void => setSearchInputFocus(true);
  const handleonBlurSearch = (): void => setSearchInputFocus(false);
  const handleSetSearchValue = (e: React.ChangeEvent<HTMLInputElement>): void => setSearchValue(e.target.value);

  const handleOnSearch = (): void => {
    if (searchCriterias?.length > 0 && typeof onSearch === 'function') {
      setSearchInputFocus(false);
      return onSearch(searchCriterias);
    }
  };

  const matchMultipleSelectCriteria = (option: DataObject): boolean => {
    if (!multipleSelectCriteria) return false;
    return multipleSelectCriteria.split(',').includes(option.id);
  };

  const selectOption = (): void => {
    if (searchValue?.length === 0) return;
    let optionIndex = criteriaOptions.findIndex((option) => !!option.isFocused);
    let searchTxt = searchValue;
    const multiOptionIndex = criteriaOptions.findIndex((option) => option.id === multipleSelectCriteria);

    if (multipleSelectCriteria) {
      if (criteriaOptions[optionIndex].isActive && !matchMultipleSelectCriteria(criteriaOptions[optionIndex])) {
        criteriaOptions[optionIndex].isFocused = false;
        criteriaOptions[multiOptionIndex].isFocused = true;
        criteriaOptions[optionIndex].isActive = true;
      }

      if (multipleSelectPrefix) {
        const prefixIndex = multipleSelectPrefix?.findIndex((prefix) => {
          return searchValue.startsWith(prefix);
        });

        if (prefixIndex !== -1) {
          optionIndex = multiOptionIndex;
          searchTxt = searchValue.replace(multipleSelectPrefix[prefixIndex], '');
        }

        setCriteriaOptions([...criteriaOptions]);
      }
    }

    if (
      multipleSelectSeparator &&
      criteriaOptions[optionIndex].id === multipleSelectCriteria &&
      searchTxt.split(multipleSelectSeparator).length > 1
    ) {
      const searchValueArr = searchTxt.split(multipleSelectSeparator);

      if (searchValueArr?.length >= 1) {
        optionIndex = multiOptionIndex;
        setCriteriaOptions([...criteriaOptions]);

        // only max 50 po allow to search
        searchValueArr.slice(0, 50).forEach((val) => {
          searchCriterias.push({
            id: criteriaOptions[optionIndex].id,
            name: criteriaOptions[optionIndex].name,
            value: val,
          });
        });

        setSearchValue('');
        searchTxt = '';
        setCriteriaOptions([...criteriaOptions]);
        setSearchCriteria([...searchCriterias]);
        handleOnChangeCriteria(searchCriterias);
      }
    }

    if (optionIndex > -1 && !criteriaOptions[optionIndex].isActive && searchTxt) {
      searchCriterias.push({
        id: criteriaOptions[optionIndex].id,
        name: criteriaOptions[optionIndex].name,
        value: searchTxt,
      });

      if (!multipleSelectCriteria || !matchMultipleSelectCriteria(criteriaOptions[optionIndex])) {
        criteriaOptions[optionIndex].isActive = true;
      }

      setCriteriaOptions([...criteriaOptions]);
      setSearchCriteria([...searchCriterias]);
      setSearchValue('');
      handleOnChangeCriteria(searchCriterias);
    }
  };

  const handleClearAll = (): void => {
    setSearchValue('');
    const zeroArr: Criteria[] | null = [];
    setSearchCriteria([...zeroArr]);

    for (const item of criteriaOptions) {
      item.isActive = false;
    }

    handleOnChangeCriteria([...zeroArr]);

    setCriteriaOptions([...criteriaOptions]);

    if (typeof onClearAll === 'function') {
      return onClearAll();
    }
  };

  const handleOnKeyDownSearch = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      selectOption();
    }

    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      let successorIndex = 0;
      const optionIndex = criteriaOptions.findIndex((option) => !!option.isFocused);

      if (e.key === 'ArrowDown') {
        successorIndex = optionIndex < criteriaOptions.length - 1 ? optionIndex + 1 : 0;
      } else {
        successorIndex = optionIndex === 0 ? criteriaOptions.length - 1 : optionIndex - 1;
      }

      criteriaOptions[optionIndex].isFocused = false;
      criteriaOptions[successorIndex].isFocused = true;

      setCriteriaOptions([...criteriaOptions]);
    }

    if (multipleSelectCriteria && e.ctrlKey && e.key === 's') {
      handleOnSearch();
    }

    // shortcut crtl+x for clear
    if (multipleSelectCriteria && e.ctrlKey && e.key === 'x') {
      handleClearAll();
    }
  };

  const handleRemoveSearchCriteria = (criteria: Criteria): void => {
    const filteredCriterias = searchCriterias.filter((item) => item !== criteria);
    const deletedCriteriaOption = criteriaOptions.filter((item) => item.id === criteria.id);
    deletedCriteriaOption[0].isActive = false;
    setSearchCriteria([...filteredCriterias]);
    setCriteriaOptions([...criteriaOptions]);
    handleOnChangeCriteria(filteredCriterias);
  };

  const handleMouseOver = (selectedId: string): void => {
    // set focus selected Option
    criteriaOptions.filter((option) => option.id === selectedId).map((option) => (option.isFocused = true));

    // set focus:false others
    criteriaOptions.filter((option) => option.id !== selectedId).map((option) => (option.isFocused = false));

    setCriteriaOptions([...criteriaOptions]);
  };

  const handleMouseUp = (): void => {
    selectOption();
  };

  useImperativeHandle(
    ref,
    () => ({
      clearState() {
        handleClearAll();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [],
  );

  useEffect(() => {
    if (clearOnInit) {
      handleClearAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.SearchContainer>
      <S.SearchGroup>
        <div className="magnifying-glass">
          <MagnifyingGlass />
        </div>
        <div className="controls-container">
          <AddedCriteria criterias={searchCriterias} onRemoveCriteria={handleRemoveSearchCriteria} />
          <S.MainInputContainer>
            <input
              data-testid="search-input"
              type="text"
              autoFocus={searchInputHasFocus}
              onFocus={handleOnFocusSearch}
              onBlur={handleonBlurSearch}
              onKeyDown={handleOnKeyDownSearch}
              className="search-input"
              placeholder={placeholder}
              value={searchValue}
              onChange={handleSetSearchValue}
            />
            <ConditionalRender condition={searchInputHasFocus}>
              <div className="criteria-suggestions">
                {criteriaOptions.map((option) => (
                  <div
                    key={option.id}
                    className={`suggestion ${option.isFocused ? 'selected' : ''}`}
                    onMouseOver={() => handleMouseOver(option.id)}
                    onFocus={() => handleMouseOver(option.id)}
                    onMouseDownCapture={handleMouseUp}
                  >
                    {option.isActive ? (
                      <p>
                        {option.name} <strong>(already active)</strong>
                      </p>
                    ) : (
                      <p>
                        {option.name}
                        {option?.name.includes('not ') ? ' ' : ' has '}
                        <strong>{searchValue}</strong>
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </ConditionalRender>
          </S.MainInputContainer>
        </div>
      </S.SearchGroup>
    </S.SearchContainer>
  );
});
