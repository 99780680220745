import styled from 'styled-components';
import { darken } from 'polished';

import { COLORS } from 'common/constants';
import { ColorType } from 'types';

interface WrapperProps {
  color: ColorType;
}

const getButtonBorderColor = ({ color: colorType }: WrapperProps): string =>
  darken(0.05, COLORS[colorType]);

const Wrapper = styled.div<WrapperProps>`
  && {
    display: inline-block;

    .MuiButtonGroup-root {
      button {
        border: none;
      }

      button:first-child {
        border-right: 2px solid ${getButtonBorderColor};
      }

      button:nth-child(2) {
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`;

export const S = {
  Wrapper,
};
