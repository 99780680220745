import React from 'react';
import { FieldInput } from 'common/components';
import { REGEX } from 'common/constants';

// TODO: FIX INTERFACE OR TYPE HERE
// type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

// export type TProps = Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> & {
//   mask: string;
//   value: string;
//   maskSymbol?: any;
//   onChange: (value: string) => void;
// };

export function maskedText(val: string, mask: string): string | undefined {
  let i = 0;
  let lastReplacedIndex = -1;
  const filledMask = mask?.replace(/#/g, (_, j) => {
    if (i >= val.length) {
      return '#';
    }
    lastReplacedIndex = j;
    // eslint-disable-next-line no-plusplus
    return val[i++];
  });

  return filledMask?.substring(0, lastReplacedIndex + 1);
}

export const cleanUpNonDigitValue = (maskValue: any): string => {
  return maskValue.replace(/[^\d]/g, '');
};

export const MaskedInput: React.FC<any> = ({ value, mask, onChange, initialValue, ...rest }): JSX.Element => {
  const maxLength = mask?.['length'];
  const cleanValue = value.replace(/[^\d]/g, '');

  const isValueValid = REGEX.NUMBER_ONLY.test(cleanValue);
  const isInitialValid = REGEX.NUMBER_ONLY.test(value);
  let shouldFormatted = true;

  if (initialValue && !isInitialValid && !isValueValid) {
    shouldFormatted = false;
  }

  return (
    <FieldInput
      value={shouldFormatted ? maskedText(cleanValue, mask) : value}
      onChange={onChange}
      maxLength={maxLength}
      {...rest}
    />
  );
};
