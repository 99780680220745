import React from 'react';

import { View, Text } from '@react-pdf/renderer';
import { COLORS } from 'common/constants';

import { PDFTableProps } from './types';
import { generateStyles } from './styles';

export const PDFTable: React.FC<PDFTableProps> = (props) => {
  const { cols, width, rows, footer, fixedHeader, rawFont, wrapHeader } = props;
  const { tableStyles, tableHeaderContainerStyles, tableHeaderStyles } = props;
  const styles = generateStyles({ rawFont });

  return (
    <View wrap style={{ ...styles.tableContainer, width, ...(tableStyles || {}) }}>
      {/* Table Header */}
      <View
        fixed={fixedHeader}
        style={{ ...styles.tableHeaderContainer, width, ...(tableHeaderContainerStyles || {}) }}
      >
        {cols.map((col) => {
          const style: Record<string, any> = {
            ...styles.tableHeader,
            ...(tableHeaderStyles || {}),
            ...(col.headerStyles || {}),
          };
          if (col.width) style.width = col.width;
          if (col.align) style.textAlign = col.align;

          return (
            <Text style={style} wrap={wrapHeader}>
              {col.name}
            </Text>
          );
        })}
      </View>

      {/* Table Body */}
      <View style={{ ...styles.tableBody, width }}>
        {rows.map((row, index, { length }) => {
          return (
            <View
              style={{
                ...styles.tableRowContainer,
                width,
              }}
            >
              {cols.map((col) => {
                const style: Record<string, any> = { ...styles.tableRow };
                if (col.width) style.width = col.width;
                if (col.align) style.textAlign = col.align;

                if (index % 2 === 0) {
                  style.backgroundColor = COLORS.white;
                  style.borderColor = COLORS.white;
                } else {
                  style.backgroundColor = COLORS.GREY_100;
                  style.borderColor = COLORS.GREY_100;
                }

                if (index + 1 === length) style.borderBottom = `1pt solid ${COLORS.GREY_700}`;

                if (col.raw) {
                  return (
                    <View style={{ ...style, ...(col.cellStyles || {}) }} wrap>
                      {row[col.key]}
                    </View>
                  );
                } else {
                  return (
                    <Text style={{ ...style, ...(col.cellStyles || {}) }} wrap>
                      {row[col.key]}
                    </Text>
                  );
                }
              })}
            </View>
          );
        })}
      </View>

      {/* Table Footer */}
      {footer || null}
    </View>
  );
};
