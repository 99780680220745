import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';

import { COLORS } from 'common/constants';

const Button = styled(ButtonBase)`
  && {
    cursor: pointer;
    background-color: ${COLORS.primary};
    padding: 10px 15px;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
  }
`;

export const S = { Button };
