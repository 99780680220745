import React, { useGlobal, useDispatch, useEffect, useState } from 'reactn';
import {
  Button,
  Dialog,
  BulkActionItemCardMapper,
  FieldWrapper,
} from 'common/components';
import { ColorType } from 'types';
import { useOrderFulfillmentForm } from './use-order-fulfillment-form';
import { fieldOptions } from './order-fulfillment-form.config';
import { FieldFileUpload } from './component/field-file-upload';

const formId = 'order-fulfillment-form';

export const OrderFulfillmentModal: React.FC = () => {
  const [{ fulfill }] = useGlobal('transactions');
  const setOrderIdsForFulfillment = useDispatch('setOrderIdsForFulfillment');
  const { orderIds, orderDetails } = fulfill;
  const [isOrderFulfilled, setIsOrderFulfilled] = useState(false);  
  
  const form = useOrderFulfillmentForm({
    isUpdate: !!isOrderFulfilled
  });

  const handleClose = (): void => {
    setOrderIdsForFulfillment({ orderIds: [] });
  };

  useEffect(() => {
    const orderDetail = orderDetails[0];

    if (
      orderDetail && 
      orderDetail.fulfillment_status === 'fulfilled' && 
      orderDetails.length === 1
      ) {
      setIsOrderFulfilled(true);
    } else {
      setIsOrderFulfilled(false);
    }

  }, [orderIds, orderDetails]);

  const renderContent = (): JSX.Element => (
    <>
      <BulkActionItemCardMapper data={orderDetails} />
      <form
        id={formId}
        onSubmit={form.handleSubmit}
        style={{ height: '26.5vh' }}
      >
        <span style={{ fontSize: '13px' }}>Upload Document</span>
        {fieldOptions.map(({ name, type }) => {
          const handleDrop = (base64: string): Promise<any> =>
            form.setFieldValue(name, base64);

          if (type === 'file') {
            return (
              <FieldWrapper
                key={name}
                touched={form.touched[name]}
                error={form.errors[name]}
              >
                <FieldFileUpload
                  onUpload={handleDrop}
                  type={name}
                  accept="image/*"
                />
              </FieldWrapper>
            );
          }

          return null;
        })}
      </form>
    </>
  );
  const renderActions = (): JSX.Element => (
    <Button
      color={ColorType.PRIMARY}
      disabled={!form.dirty || form.isSubmitting}
      loading={form.isSubmitting}
      form={formId}
      type="submit"
    >
      Submit
    </Button>
  );

  return (
    <Dialog
      open={!!orderIds.length}
      primary
      title="Upload Delivery Document"
      onClose={handleClose}
      renderActions={renderActions}
      renderContent={renderContent}
      subTitle="Order ID"
      value={orderIds.length === 1 ? orderDetails[0].po_number : ''}
    />
  );
};
