/* eslint-disable no-shadow */
import React, { useRef, useState } from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

import { ColorType } from 'types';
import {
  ActionMenuItems,
  ActionMenuSelectHander,
} from 'common/components/ActionMenu/types';
import { Button } from 'common/components/Button';

import { S } from './SplitButton.styles';
import * as C from './components';

export interface SplitButtonProps {
  color: ColorType;
  items: ActionMenuItems;
  text: string;
  onClick?: () => void;
  onSelect?: ActionMenuSelectHander;
  disabled?: boolean;
}

export const SplitButton: React.FC<SplitButtonProps> = ({
  color,
  items,
  text,
  onClick,
  onSelect,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleItemClick = (name: string): void => {
    if (!onSelect) {
      return;
    }

    onSelect(name);
    setOpen(false);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const renderItems = (): JSX.Element[] =>
    items.map((item) => {
      if (!item) {
        return <Divider />;
      }

      const { color, name, text, disabled: itemDisabled } = item;

      return (
        <C.Item
          key={name}
          color={color as ColorType}
          text={text}
          name={name}
          disabled={itemDisabled !== undefined ? itemDisabled : disabled}
          onClick={handleItemClick}
        />
      );
    });

  return (
    <S.Wrapper color={color}>
      <ButtonGroup ref={anchorRef}>
        <Button color={color} onClick={onClick} disabled={disabled}>
          {text}
        </Button>
        <Button color={color} onClick={handleOpen}>
          <ArrowDropDownIcon data-testid="dropdown-btn" />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        style={{ zIndex: 1 }}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>{renderItems()}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </S.Wrapper>
  );
};
