import { SwipeRxPt, SwipeRxPtV3Resources } from '../..';

export class SwipeRxPtDoku {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.DOKU;
  }

  async resolve(transactionIds: string[]): Promise<void> {
    await this.base.postV2(`${this.resource}/resolve-unprocessed-transactions`, { transaction_ids: transactionIds });
  }
}
