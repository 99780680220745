import { addReducer } from 'reactn';

import { DistributorCalendarsState } from './interfaces/DistributorCalendarsState.interface';
import { RootState } from '..';

export const DISTRIBUTOR_CALENDARS_INITIAL_STATE: DistributorCalendarsState = {
  loading: false,
  error: undefined,
  successMessage: undefined,

  // FIXME:  CLEAN THIS LATER
  // productList: {
  //   data: null,
  // },
  // productDetails: {
  //   data: null,
  // },
  // productDistributorDetails: {
  //   data: null,
  // },
  // productCategoriesDetails: {
  //   data: null,
  // },
};

addReducer(
  'setDistributorCalendarLoading',
  (state, _, loading): RootState => {
    const { distributorCalendars } = state;
    return {
      ...state,
      distributorCalendars: {
        ...distributorCalendars,
        loading,
      },
    };
  },
);

// FIXME:  CLEAN THIS LATER
// FIXME:  CLEAN THIS LATER
// addReducer('fetchProducts', async (state, dispatch, payload) => {
//   const { products } = state;

//   try {
//     const { data } = await SwipeRxPt.instance.products.search(payload);
//     return {
//       ...state,
//       products: {
//         ...products,
//         productList: {
//           data,
//         },
//       },
//     };
//   } catch (e) {
//     recordException(e, 'fetchProducts', { payload });
//     dispatch.setProductError({ error: e.message });
//     return state;
//   }
// });

// addReducer('fetchProductDetails', async (_, dispatch, { id }) => {
//   try {
//     const product = await SwipeRxPt.instance.products.retrieve(id);
//     dispatch.setProductDetails(product);
//   } catch (e) {
//     recordException(e, 'fetchProductDetails', { id });
//     dispatch.setProductError({ error: e.message });
//   }
// });

// addReducer('fetchProductDistributorDetails', async (_, dispatch, { id, params }) => {
//   try {
//     const productDistributor = await SwipeRxPt.instance.products.listProductDistributors(
//       id,
//       params,
//     );
//     dispatch.setProductDistributorDetails(productDistributor);
//   } catch (e) {
//     recordException(e, 'fetchProductDistributorDetails', { id, params });
//     dispatch.setProductError({ error: e.message });
//   }
// });

// addReducer('fetchProductCategories', async (state, dispatch, params) => {
//   const { products } = state;

//   try {
//     const response = await SwipeRxPt.instance.productCategories.fetchProductCategories(params || {name:''});
//     return {
//       ...state,
//       products: {
//         ...products,
//         productCategoriesDetails: {
//           data: response.data,
//         },
//       },
//     };
//   } catch (e) {
//     recordException(e, 'fetchProductCategories', { params });
//     dispatch.setProductError({ error: e.message });
//     return state;
//   }
// });

// addReducer('createProduct', async (_, dispatch, product) => {
//   try {
//     const { id } = await SwipeRxPt.instance.products.createProduct(product);
//     dispatch.fetchProductDetails({ id });
//   } catch (e) {
//     recordException(e, 'createProduct', { product });
//     dispatch.setProductError({ error: e.message });
//   }
// });

// addReducer('patchProduct', async (_, dispatch, { id, product }) => {
//   try {
//     await SwipeRxPt.instance.products.patchProduct(id, product);
//     dispatch.fetchProductDetails({ id });
//   } catch (e) {
//     recordException(e, 'patchProduct', { id, product });
//     dispatch.setProductError({ error: e.message });
//   }
// });

// addReducer('setProductDetails', (state, _, payload) => {
//   const { products } = state;

//   return {
//     ...state,
//     products: {
//       ...products,
//       productDetails: {
//         data: payload,
//         error: null,
//       },
//     },
//   };
// });

// addReducer('setProductDistributorDetails', (state, _, payload) => {
//   const { products } = state;

//   return {
//     ...state,
//     products: {
//       ...products,
//       productDistributorDetails: {
//         ...payload
//       },
//     },
//   };
// });

// addReducer('setProductError', (state, _, payload) => {
//   const { products } = state;

//   return {
//     ...state,
//     products: {
//       ...products,
//       error: payload.error,
//     },
//   };
// });
