import React from 'react';

import { ButtonToggle } from 'common/components/ButtonToggle';

import { ButtonToggleFilterData } from './interfaces';

import { S } from './ButtonToggleFilter.styles';

interface Props {
  data: ButtonToggleFilterData[];
  defaultValue?: string;
  label?: string;
  onChange: (value?: string) => void;
  value: string;
  radioMode?: boolean;
  style?: { [any: string]: string }
}

export const ButtonToggleFilter: React.FC<Props> = ({ data, label, onChange, value, radioMode = true, style }) => {
  const handleChange = (_: boolean, name?: string): void => onChange(name);

  const renderItems = (): JSX.Element[] =>
    data.map((item) => (
      <ButtonToggle
        radioMode={radioMode}
        key={item.value}
        value={item.value === value}
        onChange={handleChange}
        text={item.text}
        name={item.value}
        notif={item.notif}
      />
    ));

  return (
    <S.Wrapper style={style}>
      {!!label && <span>{label}</span>} {renderItems()}
    </S.Wrapper>
  );
};
