import { ILogisticsPharmacyArea, IMutableLogisticsPharmacyArea } from 'common/interfaces/Logistics.interface';
import { CSVUploadResult } from 'common/components/CsvUpload';
import { ISearchable, ListResponse, ISearchParam } from '../../interfaces';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { IMutable } from '../../interfaces/IMutable.interface';

export class SwipeRxPtLogisticsPharmacyArea
  implements ISearchable<ILogisticsPharmacyArea>, IMutable<IMutableLogisticsPharmacyArea> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.LOGISTICS_PHARMACY_AREA;
  }

  async createOrUpdate(data: IMutableLogisticsPharmacyArea): Promise<any> {
    return this.base.post(`${this.resource}`, data);
  }

  async search(params: ISearchParam, resourceOverride?: string): Promise<ListResponse<ILogisticsPharmacyArea>> {
    return this.base.get(resourceOverride || this.resource, { ...params });
  }

  async add(path: string, pharmacy_ids: number[]): Promise<ILogisticsPharmacyArea> {
    return this.base.post(path, { pharmacy_ids });
  }

  async delete(path: string): Promise<ILogisticsPharmacyArea> {
    return this.base.delete(path);
  }

  async getOne(pharmacy_id: number): Promise<{ data: ILogisticsPharmacyArea }> {
    return this.base.get(`${this.resource}`, { ids: `${pharmacy_id}`, include: 'logisticsArea' });
  }
  
  async uploadCsv(
    file: File,
  ): Promise<{
    data: CSVUploadResult;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );
    return this.base.post(`${this.resource}/upload`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async uploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`${this.resource}/upload/history`, {
      page,
      page_size,
    });
  }
}
