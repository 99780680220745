import { MarketingType } from '../types';

export const CONFIGURABLE_MARKETING_TYPES = [
  MarketingType.PRODUCT_LISTING,
  MarketingType.BANNER_LISTING,
  MarketingType.LOYALTY_BANNER_LISTING,
  MarketingType.SIMILAR_PRODUCT_LISTING,
  MarketingType.HOME_SQUARES,
  MarketingType.RECENTLY_VIEWED_LISTING,
];

export const LISTING_MARKETING_TYPE = [
  MarketingType.PRODUCT_LISTING,
  MarketingType.SIMILAR_PRODUCT_LISTING,
  MarketingType.RECENTLY_VIEWED_LISTING,
  MarketingType.REPURCHASE_LISTING,
  MarketingType.AUTO_PLAYLIST_LISTING,
];
export const BANNER_MAREKTING_TYPE = [MarketingType.LOYALTY_BANNER_LISTING, MarketingType.BANNER_LISTING];
export const HOMESQUARE_MARKETING_TYPE = [MarketingType.HOME_SQUARES];
export const CAMPAIGN_SEARCH_MARKETING_TYPE = [MarketingType.CAMPAIGN_SEARCH];

export enum TabsMarketingType {
  LISTING = 'listing',
  BANNER = 'banner',
  HOMESQUARE = 'home square',
  CAMPAIGN_SEARCH = 'search campaign',
}
