import { setGlobal } from 'reactn';

import { DATA_TABLES_INITIAL_STATE, DataTableReducers, DataTableState } from './data-tables';
import { TRANSACTIONS_INITIAL_STATE, TransactionReducers, TransactionState } from './transactions';

import { OrderReducers, OrderState, ORDERS_INITIAL_STATE } from './order';
import {
  DocumentManagementReducers,
  DocumentManagementState,
  DOCUMENT_MANAGEMENT_INITIAL_STATE,
} from './document-management';

import {
  DocumentManagementPharmacyReducers,
  DocumentManagementPharmacyState,
  DOCUMENT_MANAGEMENT_PHARMACY_INITIAL_STATE,
} from './document-management-pharmacy';

import { VirtualAccountsReducer, VirutalAccountsState, VIRTUAL_ACCOUNTS_INITIAL_STATE } from './virtual-accounts';

import { ConfigReducers, ConfigState, CONFIG_INITIAL_STATE } from './market';
import { FinanceReducers, FinanceState, FINANCE_INITIAL_STATE } from './finance';

import { MARKETING_SEGMENTS_INITIAL_STATE } from './marketing-segments';
import { MarketingSegmentsReducers, MarketingSegmentsState } from './marketing-segments/interfaces';

import { LOYALTY_INITIAL_STATE } from './loyalty';
import { LoyaltyReducers, LoyaltyState } from './loyalty/interfaces';

import { ProductState, ProductReducers, PRODUCTS_INITIAL_STATE } from './products';

import { DISTRIBUTOR_CALENDARS_INITIAL_STATE, DistributorCalendarsState } from './distributor-calendars';

import { DistributorReducers, DistributorState } from './distributors/interfaces';
import { DISTRIBUTORS_INITIAL_STATE } from './distributors';

import { AreaReducers, AreaState, AREAS_INITIAL_STATE } from './areas';

import { LogisticAreaReducers, LogisticAreaState, LOGISTIC_AREA_INITIAL_STATE } from './logistic-area';

import { LogistiCalendarReducers, LogisticCalendarState, LOGISTIC_CALENDAR_INITIAL_STATE } from './logistic-calendar';

import { VoucherTypeReducers, VoucherTypeState, VOUCHER_TYPE_INITIAL_STATE } from './voucher-type';
import { VoucherReducers, VoucherState } from './voucher';

import { UtilsState, UtilsReducers, UTILS_INITIAL_STATE } from './utils';

import { MarketingReducers, MarketingState } from './marketing/interfaces';
import { MARKETING_INITIAL_STATE } from './marketing';

import { PBFAssignmentsReducers, PBFAssignmentState } from './pbf-assignments/interfaces';
import { PBF_INITIAL_STATE } from './pbf-assignments';

import { PaymentMethodReducer } from './payment-method/payment-method-reducer.interface';
import { PAYMENT_METHOD_INITIAL_STATE } from './payment-method/payment-method.reducer';

import { DepositTransactionReducers } from './deposit-transactions';
import { DepositTransactionState } from './deposit-transactions/interfaces/DepositTransactionState.interface';
import { DEPOSIT_TRANSACTION_INITIAL_STATE } from './deposit-transactions/deposit-transactions.reducer';
import {
  CreditLimitRequestState,
  CreditLimitRequestReducers,
  CREDIT_LIMIT_REQUESTS_INITIAL_STATE,
} from './credit-limit-request';
import { CrmState, CRM_INITIAL_STATE } from './crm';
import { CrmReducers } from './crm/interfaces/crm-reducers.interface';
import { MARKETING_REWARDS_INITIAL_STATE, MarketingRewardsReducers, MarketingRewardsState } from "./marketing-rewards";
import { ReturnState, ReturnReducers, RETURN_INITIAL_STATE, ReturnDetailState, RETURN_DETAIL_INITIAL_STATE } from './return';
import { PrecursorState } from './precursor/interfaces/PrecursorState.interface';
import { PrecursorReducers } from './precursor/interfaces/PrecursorReducers.interface';
import { PRECURSOR_INITIAL_STATE } from './precursor/precursor.reducer';

export type Reducer<TDispatch, TPayload = {}, TReturn = void> = (
  global: RootState,
  dispatch: TDispatch,
  payload: TPayload,
) => TReturn;

export interface RootState {
  dataTables: DataTableState;
  transactions: TransactionState;
  products: ProductState;
  distributorCalendars: DistributorCalendarsState;
  virtualAccounts: VirutalAccountsState;
  orders: OrderState;
  documentManagement: DocumentManagementState;
  documentManagementPharmacy: DocumentManagementPharmacyState;
  config: ConfigState;
  finance: FinanceState;
  distributors: DistributorState;
  areas: AreaState;
  utils: UtilsState;
  marketing: MarketingState;
  pbfAssignments: PBFAssignmentState;
  marketingRewards: MarketingRewardsState;
  marketingSegments: MarketingSegmentsState;
  loyalty: LoyaltyState;
  logisticAreas: LogisticAreaState;
  logisticCalendar: LogisticCalendarState;
  voucherType: VoucherTypeState;
  voucher: VoucherState;
  depositTransaction: DepositTransactionState;
  creditLimitRequest: CreditLimitRequestState;
  crm: CrmState;
  returnManagement: ReturnState;
  returnManagementDetail: ReturnDetailState;
  precursor: PrecursorState;
}

export interface AllReducers<T>
  extends DataTableReducers<T>,
    OrderReducers<T>,
    TransactionReducers<T>,
    ProductReducers<T>,
    VirtualAccountsReducer<T>,
    DocumentManagementReducers<T>,
    DocumentManagementPharmacyReducers<T>,
    ConfigReducers<T>,
    FinanceReducers<T>,
    DistributorReducers<T>,
    AreaReducers<T>,
    UtilsReducers<T>,
    MarketingReducers<T>,
    PBFAssignmentsReducers<T>,
    MarketingRewardsReducers<T>,
    MarketingSegmentsReducers<T>,
    LoyaltyReducers<T>,
    LogisticAreaReducers<T>,
    LogistiCalendarReducers<T>,
    DepositTransactionReducers<T>,
    VoucherTypeReducers<T>,
    VoucherReducers<T>,
    PaymentMethodReducer<T>,
    CreditLimitRequestReducers<T>,
    CrmReducers<T>,
    ReturnReducers<T>,
    PrecursorReducers<T> {}

const globalInitialState = {
  dataTables: DATA_TABLES_INITIAL_STATE,
  transactions: TRANSACTIONS_INITIAL_STATE,
  products: PRODUCTS_INITIAL_STATE,
  distributorCalendars: DISTRIBUTOR_CALENDARS_INITIAL_STATE,
  virtualAccounts: VIRTUAL_ACCOUNTS_INITIAL_STATE,
  orders: ORDERS_INITIAL_STATE,
  documentManagement: DOCUMENT_MANAGEMENT_INITIAL_STATE,
  documentManagementPharmacy: DOCUMENT_MANAGEMENT_PHARMACY_INITIAL_STATE,
  documentManagementPharmacyHistory: DOCUMENT_MANAGEMENT_PHARMACY_INITIAL_STATE,
  config: CONFIG_INITIAL_STATE,
  finance: FINANCE_INITIAL_STATE,
  distributors: DISTRIBUTORS_INITIAL_STATE,
  areas: AREAS_INITIAL_STATE,
  utils: UTILS_INITIAL_STATE,
  marketing: MARKETING_INITIAL_STATE,
  pbfAssignments: PBF_INITIAL_STATE,
  marketingRewards: MARKETING_REWARDS_INITIAL_STATE,
  marketingSegments: MARKETING_SEGMENTS_INITIAL_STATE,
  loyalty: LOYALTY_INITIAL_STATE,
  logisticAreas: LOGISTIC_AREA_INITIAL_STATE,
  logisticCalendar: LOGISTIC_CALENDAR_INITIAL_STATE,
  voucherType: VOUCHER_TYPE_INITIAL_STATE,
  paymentMethod: PAYMENT_METHOD_INITIAL_STATE,
  depositTransaction: DEPOSIT_TRANSACTION_INITIAL_STATE,
  creditLimitRequest: CREDIT_LIMIT_REQUESTS_INITIAL_STATE,
  crm: CRM_INITIAL_STATE,
  returnManagement: RETURN_INITIAL_STATE,
  returnManagementDetail: RETURN_DETAIL_INITIAL_STATE,
  precursor: PRECURSOR_INITIAL_STATE,
};

setGlobal(globalInitialState);
