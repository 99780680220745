import { ColorType, FinanceActionButton } from 'types';

type ActionButton = FinanceActionButton['actionButton'];

const MARK_AS_REIMBURSED: ActionButton = {
  name: 'bulk_reimburse',
  text: 'Bulk Reimburse',
  color: ColorType.PRIMARY
};

const MARK_AS_DISBURSED: ActionButton = {
  name: 'bulk_disburse',
  text: 'Bulk Disburse',
  color: ColorType.PRIMARY
};

export const FINANCE_BUTTONS = {
  MARK_AS_REIMBURSED,
  MARK_AS_DISBURSED,
};
