import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const ProductName = styled(Typography)`
  && {
    font-size: 12px;
    text-transform: uppercase;
  }
`;

const SubTitle = styled(Typography)`
  && {
    font-size: 12px;
    color: #9399a8;
  }
`;

const Text = styled(Typography)`
  && {
    font-size: 12px;
  }
`;

const Label = styled(Typography)`
  && {
    display: block;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 11px;
    text-transform: uppercase;
  }
`;

const NumberInput = styled.input`
  padding: 10px;
  width: 50px;
  border-radius: 3px;
  border: 1px solid #c6c6c6;
`;

const Container = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ececec;
    padding: 15px;
  }
`;

export const S = {
  ProductName,
  SubTitle,
  Text,
  Label,
  NumberInput,
  Container,
};
