import React from 'react';
import { KeyboardDatePickerProps } from '@material-ui/pickers';

import { S } from './field-date-input.styles';

type Props = KeyboardDatePickerProps;

export const FieldDateInput: React.FC<Props> = ({
  inputVariant,
  format,
  ...props
}) => (
  <S.DatePicker
    autoOk
    inputVariant={inputVariant}
    format={format}
    InputAdornmentProps={{ position: 'start' }}
    {...props}
  />
);

FieldDateInput.defaultProps = {
  inputVariant: 'outlined',
  format: 'DD/MM/YYYY',
};
