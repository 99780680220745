import React from 'react';
import {
  HomeOutlined,
  Receipt,
  InsertDriveFile,
  StoreMallDirectory,
  LocalShippingOutlined,
  People,
  StoreOutlined,
  CreditCard,
  SyncAlt,
  FindInPage,
  Delete,
  ZoomIn,
  Event,
  Explore,
  Stars,
  Description,
} from '@material-ui/icons';
import { SvgIcon } from '@material-ui/core';

const HomeIcon = (props) => {
  return <HomeOutlined {...props} />;
};

const OrdersIcon = (props) => {
  return <Receipt {...props} />;
};

const InvoicesIcon = (props) => {
  return <InsertDriveFile {...props} />;
};

const PharmaciesIcon = (props) => {
  return <StoreMallDirectory {...props} />;
};

const DistributorsIcon = (props) => {
  return <LocalShippingOutlined {...props} />;
};

const UsersIcon = (props) => {
  return <People {...props} />;
};

const RewardsIcon = (props) => {
  return <Stars {...props} />;
};

const ProductsIcon = (props) => {
  return <StoreOutlined {...props} />;
};

const CreditIcon = (props) => {
  return <CreditCard {...props} />;
};

const ScanDocumentIcon = (props) => {
  return <FindInPage {...props} />;
};

const DocumentPharmacyIcon = (props) => {
  return <Description {...props} />;
};

const DeleteIcon = (props) => {
  return <Delete {...props} />;
};

const ZoomInIcon = (props) => {
  return <ZoomIn {...props} />;
};

const EventIcon = (props) => {
  return <Event {...props} />;
};

export const TransactionIcon = (props) => <SyncAlt {...props} />;

const FinanceIcon = (props) => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 7L12 2L21.5 7V9H2.5V7ZM12 4.26L17.21 7H6.79L12 4.26ZM5 11H7V18H5V11ZM13 11V18H11V11H13ZM2.5 20V22H21.5V20H2.5ZM17 11H19V18H17V11Z" />
  </SvgIcon>
);

const ProductIcon = (props) => {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 4H14V2C14 0.9 13.1 0 12 0H8C6.9 0 6 0.9 6 2V4H2C0.9 4 0 4.9 0 6V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V6C20 4.9 19.1 4 18 4ZM8 2H12V4H8V2ZM14 13H11V16H9V13H6V11H9V8H11V11H14V13Z" />
    </SvgIcon>
  );
};

const MarketingIcon = (props) => {
  return (
    <SvgIcon
      width="20"
      height="16"
      viewBox="0
      0
      20
      16"
      fill="#D7EEE9"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 7V9H20V7H16ZM14 13.61C14.96 14.32 16.21 15.26 17.2 16C17.6 15.47 18 14.93 18.4 14.4C17.41 13.66 16.16 12.72 15.2 12C14.8 12.54 14.4 13.08 14 13.61ZM18.4 1.6C18 1.07 17.6 0.53 17.2 0C16.21 0.74 14.96 1.68 14 2.4C14.4 2.93 14.8 3.47 15.2 4C16.16 3.28 17.41 2.35 18.4 1.6ZM2 5C0.9 5 0 5.9 0 7V9C0 10.1 0.9 11 2 11H3V15H5V11H6L11 14V2L6 5H2ZM13.5 8C13.5 6.67 12.92 5.47 12 4.65V11.34C12.92 10.53 13.5 9.33 13.5 8Z"
        {...props}
      />
    </SvgIcon>
  );
};

const DownloadIcon = (props) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.56 6.92444L8.88889 8.59556V0.888889C8.88889 0.4 8.48889 0 8 0C7.51111 0 7.11111 0.4 7.11111 0.888889V8.59556L5.43111 6.92444C5.08445 6.57778 4.52445 6.57778 4.17778 6.92444C4.01133 7.09052 3.91779 7.31598 3.91779 7.55111C3.91779 7.78624 4.01133 8.01171 4.17778 8.17778L7.36889 11.3689C7.71556 11.7156 8.27556 11.7156 8.62222 11.3689L11.8133 8.17778C12.16 7.83111 12.16 7.27111 11.8133 6.92444C11.4667 6.57778 10.9067 6.57778 10.56 6.92444ZM14.2222 13.3333V8.88889C14.2222 8.4 14.6222 8 15.1111 8C15.6 8 16 8.4 16 8.88889V14.2222C16 15.2 15.2 16 14.2222 16H1.77778C0.8 16 0 15.2 0 14.2222V8.88889C0 8.4 0.4 8 0.888889 8C1.37778 8 1.77778 8.4 1.77778 8.88889V13.3333C1.77778 13.8222 2.17778 14.2222 2.66667 14.2222H13.3333C13.8222 14.2222 14.2222 13.8222 14.2222 13.3333Z"
        fill="#009688"
      />
    </SvgIcon>
  );
};

const MarketingSegmentsIcon = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0
      0
      24
      24"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 11C13.3261 11 14.5979 11.5268 15.5355 12.4645C16.4732 13.4021 17 14.6739 17 16V22H7V16C7 14.6739 7.52678 13.4021 8.46447 12.4645C9.40215 11.5268 10.6739 11 12 11ZM5.288 14.006C5.12886 14.5428 5.03485 15.0968 5.008 15.656L5 16V22H2V17.5C1.9998 16.6376 2.31803 15.8054 2.89363 15.1632C3.46924 14.521 4.2617 14.1139 5.119 14.02L5.289 14.006H5.288ZM18.712 14.006C19.6019 14.0602 20.4376 14.452 21.0486 15.1012C21.6596 15.7505 21.9999 16.6084 22 17.5V22H19V16C19 15.307 18.9 14.638 18.712 14.006ZM5.5 8C6.16304 8 6.79893 8.26339 7.26777 8.73223C7.73661 9.20107 8 9.83696 8 10.5C8 11.163 7.73661 11.7989 7.26777 12.2678C6.79893 12.7366 6.16304 13 5.5 13C4.83696 13 4.20107 12.7366 3.73223 12.2678C3.26339 11.7989 3 11.163 3 10.5C3 9.83696 3.26339 9.20107 3.73223 8.73223C4.20107 8.26339 4.83696 8 5.5 8ZM18.5 8C19.163 8 19.7989 8.26339 20.2678 8.73223C20.7366 9.20107 21 9.83696 21 10.5C21 11.163 20.7366 11.7989 20.2678 12.2678C19.7989 12.7366 19.163 13 18.5 13C17.837 13 17.2011 12.7366 16.7322 12.2678C16.2634 11.7989 16 11.163 16 10.5C16 9.83696 16.2634 9.20107 16.7322 8.73223C17.2011 8.26339 17.837 8 18.5 8ZM12 2C13.0609 2 14.0783 2.42143 14.8284 3.17157C15.5786 3.92172 16 4.93913 16 6C16 7.06087 15.5786 8.07828 14.8284 8.82843C14.0783 9.57857 13.0609 10 12 10C10.9391 10 9.92172 9.57857 9.17157 8.82843C8.42143 8.07828 8 7.06087 8 6C8 4.93913 8.42143 3.92172 9.17157 3.17157C9.92172 2.42143 10.9391 2 12 2Z"
        {...props}
      />
    </SvgIcon>
  );
};

const LoyaltyIcon = (props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0
      0
      24
      24"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.32997 15.9294C5.10983 14.9686 4.99912 13.986 4.99997 13.0004C4.99997 7.91236 7.90297 3.56436 12 1.81836C16.097 3.56436 19 7.91236 19 13.0004C19 14.0104 18.886 14.9914 18.67 15.9294L20.69 17.7254C20.7769 17.8027 20.8342 17.9079 20.8519 18.0229C20.8696 18.1379 20.8466 18.2555 20.787 18.3554L18.329 22.4514C18.2898 22.5164 18.2364 22.5718 18.1727 22.6131C18.109 22.6545 18.0367 22.6808 17.9614 22.69C17.886 22.6993 17.8095 22.6912 17.7377 22.6665C17.6659 22.6418 17.6007 22.601 17.547 22.5474L15.293 20.2934C15.1055 20.1058 14.8512 20.0004 14.586 20.0004H9.41397C9.14877 20.0004 8.89446 20.1058 8.70697 20.2934L6.45297 22.5474C6.39926 22.601 6.33403 22.6418 6.26223 22.6665C6.19042 22.6912 6.11394 22.6993 6.03856 22.69C5.96319 22.6808 5.89092 22.6545 5.82722 22.6131C5.76353 22.5718 5.71009 22.5164 5.67097 22.4514L3.21297 18.3564C3.15304 18.2564 3.12994 18.1386 3.14765 18.0234C3.16536 17.9081 3.22278 17.8027 3.30997 17.7254L5.32997 15.9294ZM12 13.0004C12.5304 13.0004 13.0391 12.7896 13.4142 12.4146C13.7893 12.0395 14 11.5308 14 11.0004C14 10.4699 13.7893 9.96122 13.4142 9.58615C13.0391 9.21107 12.5304 9.00036 12 9.00036C11.4695 9.00036 10.9608 9.21107 10.5858 9.58615C10.2107 9.96122 9.99997 10.4699 9.99997 11.0004C9.99997 11.5308 10.2107 12.0395 10.5858 12.4146C10.9608 12.7896 11.4695 13.0004 12 13.0004Z"
        {...props}
      />
    </SvgIcon>
  );
};

const LogisticIcon = (props) => {
  return <Explore {...props} />;
};

const VoucherIcon = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="#D7EEE9" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.4925 18.0278H6.87373V16.1945C6.87373 15.8568 6.60944 15.5834 6.28291 15.5834C5.95638 15.5834 5.69209 15.8568 5.69209 16.1945V18.0278H1.50751C1.18098 18.0278 0.916687 17.7545 0.916687 17.4167V13.7501C0.916687 13.4123 1.18098 13.1389 1.50751 13.1389C2.48472 13.1389 3.27997 12.3164 3.27997 11.3056C3.27997 10.2948 2.48472 9.47228 1.50751 9.47228C1.18098 9.47228 0.916687 9.19891 0.916687 8.86117V5.1945C0.916687 4.85676 1.18098 4.58339 1.50751 4.58339H5.64325V6.41672C5.64325 6.75446 5.90754 7.02783 6.23407 7.02783C6.5606 7.02783 6.82489 6.75446 6.82489 6.41672V4.58339H20.4925C20.8191 4.58339 21.0834 4.85676 21.0834 5.1945V17.4167C21.0834 17.7545 20.8191 18.0278 20.4925 18.0278ZM6.82489 8.86117C6.82489 8.52343 6.5606 8.25006 6.23407 8.25006C5.90754 8.25006 5.64325 8.52343 5.64325 8.86117V10.0834C5.64325 10.4211 5.90754 10.6945 6.23407 10.6945C6.5606 10.6945 6.82489 10.4211 6.82489 10.0834V8.86117ZM6.82489 12.5278C6.82489 12.1901 6.5606 11.9167 6.23407 11.9167C5.90754 11.9167 5.64325 12.1901 5.64325 12.5278V13.7501C5.64325 14.0878 5.90754 14.3612 6.23407 14.3612C6.5606 14.3612 6.82489 14.0878 6.82489 13.7501V12.5278Z"
        {...props}
      />
    </SvgIcon>
  );
};

const BannerIcon = (props) => {
  return (
    <SvgIcon width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.843 2H13.2638L11.928 0.59C11.5679 0.21 11.0848 0 10.5826 0H5.68457C4.64243 0 3.79924 0.9 3.79924 2L3.78976 14C3.78976 15.1 4.64243 16 5.68457 16H20.843C21.8852 16 22.7378 15.1 22.7378 14V4C22.7378 2.9 21.8852 2 20.843 2ZM0.947403 4C0.426331 4 0 4.45 0 5V9H0.00947403L0 18C0 19.1 0.852663 20 1.89481 20H18.0007C18.5217 20 18.9481 19.55 18.9481 19C18.9481 18.45 18.5217 18 18.0007 18H2.84221C2.32114 18 1.89481 17.55 1.89481 17V5C1.89481 4.45 1.46847 4 0.947403 4ZM10.5256 7.5199L7.20025 12.1999C6.9634 12.5299 7.19077 12.9999 7.57921 12.9899H18.9386C19.327 12.9899 19.5544 12.5099 19.3081 12.1799L16.949 8.9799C16.7595 8.7299 16.3995 8.7199 16.2101 8.9699L14.2205 11.4999L11.2836 7.5099C11.0846 7.2499 10.7151 7.2599 10.5256 7.5199Z"
        fill="#009688"
        {...props}
      />
    </SvgIcon>
  );
};

const ProductListingIcon = (props) => {
  return (
    <SvgIcon width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1285 0C10.6877 0 11.2258 0.21 11.6267 0.59L16.7121 5.42C17.113 5.79 17.3346 6.3 17.3346 6.83V18C17.3346 19.1 16.3851 20 15.2245 20H2.55319C1.39262 20 0.453613 19.1 0.453613 18V2C0.453613 0.9 1.40317 0 2.56374 0H10.1285ZM5.7289 16H12.0593C12.6396 16 13.1143 15.55 13.1143 15C13.1143 14.45 12.6396 14 12.0593 14H5.7289C5.14862 14 4.67384 14.45 4.67384 15C4.67384 15.55 5.14862 16 5.7289 16ZM12.0593 12H5.7289C5.14862 12 4.67384 11.55 4.67384 11C4.67384 10.45 5.14862 10 5.7289 10H12.0593C12.6396 10 13.1143 10.45 13.1143 11C13.1143 11.55 12.6396 12 12.0593 12ZM9.94923 1.5V6C9.94923 6.55 10.424 7 11.0043 7H15.7521L9.94923 1.5Z"
        fill="#009688"
        {...props}
      />
    </SvgIcon>
  );
};

const HomesquareIcon = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.65 2.93996L6.93 9.01996C6.52 9.67996 7 10.54 7.78 10.54H15.21C15.99 10.54 16.47 9.67996 16.06 9.01996L12.35 2.93996C11.96 2.29996 11.04 2.29996 10.65 2.93996ZM17 12.54C15.3923 12.54 13.9067 13.3977 13.1029 14.79C12.299 16.1823 12.299 17.8977 13.1029 19.29C13.9067 20.6823 15.3923 21.54 17 21.54C18.6077 21.54 20.0933 20.6823 20.8971 19.29C21.701 17.8977 21.701 16.1823 20.8971 14.79C20.0933 13.3977 18.6077 12.54 17 12.54ZM9.5 21.04H3.5C2.95 21.04 2.5 20.59 2.5 20.04V14.04C2.5 13.49 2.95 13.04 3.5 13.04H9.5C10.05 13.04 10.5 13.49 10.5 14.04V20.04C10.5 20.59 10.05 21.04 9.5 21.04Z"
        fill="#009688"
        {...props}
      />
    </SvgIcon>
  );
};

export {
  HomeIcon,
  OrdersIcon,
  InvoicesIcon,
  PharmaciesIcon,
  DistributorsIcon,
  UsersIcon,
  ProductsIcon,
  CreditIcon,
  ScanDocumentIcon,
  FinanceIcon,
  ProductIcon,
  MarketingIcon,
  DeleteIcon,
  ZoomInIcon,
  DownloadIcon,
  MarketingSegmentsIcon,
  LoyaltyIcon,
  EventIcon,
  LogisticIcon,
  VoucherIcon,
  DocumentPharmacyIcon,
  RewardsIcon,
  BannerIcon,
  ProductListingIcon,
  HomesquareIcon,
};
