/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useDispatch, useEffect, useGlobal, useState } from 'reactn';

import { formatToDatetime } from 'common/utils/formatters';

import { useRouteMatch } from 'react-router-dom';
import { IMarketParams } from 'utils/get-router-path';
import { Content, DialogActions, Dialog } from './OrderInvoiceDialog';
import { useOrderInvoiceForm } from './use-order-invoice-form';

const formId = 'order-invoice-form';

export const OrderInvoiceModalV1: React.FC = () => {
  const {
    params: { marketId },
  } = useRouteMatch<IMarketParams>();

  const [{ invoicing, loading, orderDetails }] = useGlobal('transactions');
  const [config] = useGlobal('config');
  const setOrderForInvoicing = useDispatch('setOrderForInvoicing');
  const fetchTransactionOrderDetails = useDispatch(
    'fetchTransactionOrderDetails',
  );

  const [dialogVisibility, setDialogVisibility] = useState<boolean>(true);

  const { order } = invoicing || {};

  useEffect(() => {
    if (order?.id) {
      fetchTransactionOrderDetails({
        id: order?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const { id, invoice_number, invoiced_amount, invoiced_at } =
    order?.invoice || {};
  const { ordered_at } = order || {};

  const initialValues = {
    id,
    invoice_number,
    invoiced_amount,
    order_id: order?.id,
    invoiced_at: formatToDatetime(invoiced_at || new Date(), 'YYYY-MM-DD'),
  };

  const handleClose = (): void => {
    setOrderForInvoicing(null);
    setDialogVisibility(true);
  };

  const handleConfirmation = (): void => {
    setDialogVisibility(false);
  };

  const form = useOrderInvoiceForm(initialValues, handleClose);

  const renderContent = (): JSX.Element => (
    <Content
      formId={formId}
      form={form}
      ordered_at={ordered_at}
      order={order}
      orderDetails={orderDetails}
      marketId={marketId}
      config={config}
      dialogVisibility={dialogVisibility}
    />
  );

  const renderActions = (): JSX.Element => (
    <DialogActions
      formId={formId}
      form={form}
      order={order}
      marketId={marketId}
      onConfirmation={handleConfirmation}
      onClose={handleClose}
      dialogVisibility={dialogVisibility}
    />
  );

  return (
    <Dialog
      open={!!invoicing && invoicing.version === 'v1'}
      primary
      loading={loading}
      title={`${order?.invoice ? 'Update' : 'Create'} Invoice`}
      onClose={handleClose}
      renderActions={renderActions}
      renderContent={renderContent}
      showCloseText={false}
      invisible={!dialogVisibility}
    />
  );
};
