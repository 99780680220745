/* eslint-disable no-restricted-globals */
import React from 'reactn';
import { Page, Text, View, Image } from '@react-pdf/renderer';

import { PDFTable } from 'common/components';
import { Order } from 'common/reducers';

import { styles } from './styles';
import { Header } from '../header';
import { DeliveryProofV2Props } from '../../types';
import { acceptanceFormCols, returnFormCols, transfromAcceptanceFormData, returnFormData } from './helper';

interface DeliveryInformationProps extends DeliveryProofV2Props {
  order: Order;
}

export const DeliveryInformation: React.FC<DeliveryInformationProps> = (props) => {
  const { order, config } = props;
  const {
    market: { regionalOffice, contactNumber },
  } = config;

  const { invoice = {}, pharmacy = {}, distributor = {} } = order;

  const acceptanceFormData = transfromAcceptanceFormData(invoice);

  const qrId = `qrcode-delivery-${order.id}`;
  const qrcodeRef = document.getElementById(qrId)
    ? (document.getElementById(qrId) as any).toDataURL('image/png', 1)
    : null;

  const AddressSection = (): JSX.Element => (
    <View style={styles.addressesContainer}>
      <Text style={styles.address}>
        <Text style={{ fontWeight: 'bold' }}>Wholesaler</Text>
        {'\n'}
        {distributor?.name} {distributor?.legal_name ? `(${distributor?.legal_name})` : null}
        {'\n'}
        {distributor?.address}
        {'\n'}
        {distributor?.area} {distributor?.region}
        {'\n'}
        {distributor?.city} {distributor?.zipcode} {distributor?.country}
      </Text>

      <Text style={styles.address}>
        <Text style={{ fontWeight: 'bold' }}>Ship to</Text>
        {'\n'}
        {invoice?.custom_information?.pharmacy?.name ? invoice?.custom_information?.pharmacy?.name : pharmacy?.name}
        {'\n'}
        {invoice?.custom_information?.pharmacy?.address
          ? invoice?.custom_information?.pharmacy?.address
          : pharmacy?.address}
        {'\n'}
        {pharmacy?.area} {pharmacy?.region}
        {'\n'}
        {pharmacy?.city} {pharmacy?.zipcode} {pharmacy?.country}
      </Text>
    </View>
  );

  const AcceptanceForm = (): JSX.Element => (
    <>
      <Text style={styles.formTitle}>Acceptance Form</Text>

      <PDFTable cols={acceptanceFormCols} rows={acceptanceFormData} width="190mm" />

      <View style={styles.signageContainer}>
        <View style={styles.signature}>
          <Text style={styles.signatureLabel}>Delivered by:</Text>
          <View style={styles.signatureBlank} />
        </View>

        <View style={styles.signature}>
          <Text style={styles.signatureLabel}>Received by:</Text>
          <View style={styles.signatureBlank} />
        </View>
      </View>
    </>
  );

  const ReturnForm = (): JSX.Element => (
    <>
      <Text style={styles.formTitle}>Return Form</Text>

      <PDFTable cols={returnFormCols} rows={returnFormData} width="190mm" />

      <View style={styles.signageContainer}>
        <View style={styles.signature}>
          <Text style={styles.signatureLabel}>Delivered by:</Text>
          <View style={styles.signatureBlank} />
        </View>

        <View style={styles.signature}>
          <Text style={styles.signatureLabel}>Returned by:</Text>
          <View style={styles.signatureBlank} />
        </View>
      </View>
    </>
  );

  return (
    <Page size="A4" style={styles.root}>
      <Header subtitle="Delivery Information" />
      <View>
        <View style={styles.deliveryInformationContainer}>
          <Text style={styles.mClinicaAddress}>
            {regionalOffice?.localName}
            {'\n'}
            {regionalOffice?.address},{'\n'}
            {regionalOffice?.building},{'\n'}
            {regionalOffice?.region}, {regionalOffice?.city} {regionalOffice?.province} {regionalOffice?.postalCode}{' '}
            {regionalOffice?.country}
          </Text>
        </View>
        <AddressSection />
        <View style={styles.formsContainer}>
          <AcceptanceForm />
          <View style={styles.formSeparator} />
          <ReturnForm />
        </View>
        <View style={styles.notesContainer}>
          <Text>Note :</Text>
          <Text style={{ marginLeft: '115mm' }}>
            {'\n'}
            {'\n'}
            For more information please contact customer
            {'\n'}
            service at: {contactNumber}
          </Text>
        </View>
        <View style={styles.qrcodeContainer}>
          <Image source={qrcodeRef} style={styles.qrcode} />
        </View>
      </View>
    </Page>
  );
};
