import { AxiosRequestConfig } from 'axios';
import { SwipeRxPt, SwipeRxPtV3Resources, Query } from '../../SwipeRxPt.class';
import { SuccessResponse } from '../../interfaces';
import { InvoiceSummary, InvoiceSummaryParams } from './interfaces';

export interface InvoiceSummaryMessageParams {
  organization_id: number;
  invoice_ids: number[]
}

export class SwipeRxPtInvoice {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.INVOICE;
  }

  async fetchInvoiceSummary(params: InvoiceSummaryParams): Promise<InvoiceSummary> {
    const { data } = await this.base.get<SuccessResponse<InvoiceSummary>>(
      `${this.resource}/summary/${params.organization_id}`,
      { invoice_ids: params.invoice_ids },
    );

    return data;
  }

  async downloadOverdue(params: Query): Promise<any> {
    return this.base.get(`${this.resource}/overdue/download`, params);
  }

  async getOverdue(params: Query, config?: AxiosRequestConfig): Promise<any> {
    return this.base.get(`${this.resource}/overdue`, params, config);
  }

  async generateInvoiceSummaryMessage(params: InvoiceSummaryMessageParams): Promise<string> {
    const { organization_id, invoice_ids } = params;
    const { data } = await this.base.get<SuccessResponse<string>>(`${this.resource}/message/${organization_id}`, { invoice_ids });
    return data;
  }
}
