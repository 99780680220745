/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/extensions */

import stores from 'common/models/index.model';
import { PtAPIv2 } from '../utils';

class AuthService {
  // Get All Orders
  async login(username = '', password = '') {
    stores.dispatch.uiStore.showLoading(true);
    // eslint-disable-next-line
    var returnData = {};
    await PtAPIv2.admin
      .post(`functions/login`, {
        username,
        password,
      })
      .then((successData) => {
        returnData = successData.result;
      })
      .catch((error) => {
        returnData = {
          id: -1,
          session_token: '',
          userType: 'anonymous',
          username,
        };

        if (error.message) {
          returnData.error = error.message;
        }
      })
      .finally(() => {
        stores.dispatch.uiStore.showLoading(false);
      });

    return returnData;
  }
  // eslint-disable-next-line lines-between-class-members
  async logout() {
    stores.dispatch.uiStore.showLoading(true);
    // eslint-disable-next-line
    var returnData = {};

    await PtAPIv2.admin
      .post('functions/logout', null)
      .then((successData) => {
        returnData = successData.result;
      })
      .catch((error) => {
        console.error('Error on logout', error);
      })
      .finally(() => {
        stores.dispatch.uiStore.showLoading(false);
      });

    return returnData;
  }
}

export default new AuthService();
