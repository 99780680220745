import qs from 'query-string';
import { CSVUploadResult } from 'common/components/CsvUpload/interfaces/csv-upload.interface';

import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
// import { MarketingList, Marketing } from './interfaces';
// import { MarketingParams } from './interfaces/MarketingParams.interface';

// TODO: update return types

export class SwipeRxPtMarketingSegments {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.MARKETING_SEGMENTS;
  }

  async getMarketingSegmentList(page: number): Promise<any> {
    return this.base.get(`${this.resource}?page=${page}`);
  }

  async getMarketingSegmentInfo(marketingSegmentId: number): Promise<any> {
    return this.base.get(`${this.resource}/${marketingSegmentId}`);
  }

  async createMarketingSegment(name: string): Promise<any> {
    return this.base.post(`${this.resource}`, { name });
  }

  async deleteMarketingSegment(marketingSegmentId: number): Promise<any> {
    return this.base.delete(`${this.resource}/${marketingSegmentId}`);
  }

  async getMarketingSegmentPharmacyList(
    marketingSegmentId: number,
    meta: any,
    criterias: {
      ids: string | null,
      names: string | null,
    }): Promise<any> {
    const { page, pageSize, sortBy, orderBy } = meta;
    const params = {
      page,
      page_size: pageSize,
      order_by: orderBy,
      sort_by: sortBy,
      ...criterias,
    };
    const query = qs.stringify(params, { skipNull: true });
    return this.base.get(`${this.resource}/${marketingSegmentId}/pharmacies?${query}`);
  }

  async deleteMarketingSegmentPharmacy(marketingSegmentId: number, marketingSegmentPharmacyId: number): Promise<any> {
    return this.base.delete(`${this.resource}/${marketingSegmentId}/pharmacies/${marketingSegmentPharmacyId}`);
  }

  async getPharmaciesNotInMarketingSegment(
    marketingSegmentId: number,
    params: { sort_by: string, order_by: string, ids: string | null, names: string | null},
  ): Promise<any> {
    const query = qs.stringify(params, { skipNull: true });
    return this.base.get(`${this.resource}/${marketingSegmentId}/pharmacy-search?${query}`)
  }

  async addPharmaciesInMarketingSegment(marketingSegmentId: number, pharmacyIds: number[]): Promise<any> {
    return this.base.post(`${this.resource}/${marketingSegmentId}/pharmacies`, { pharmacy_ids: pharmacyIds });
  }

  async updateMarketingSegmentStatus(marketingSegmentId: number, isActive: boolean): Promise<any> {
    return this.base.patch(`${this.resource}/${marketingSegmentId}`, { is_active: isActive });
  }

  async exportPharmacyInMarketingSegment(marketingSegmentId: number): Promise<void> {
      await this.base.get(`${this.resource}/${marketingSegmentId}/export`, {
            marketingSegmentId
      });
  }

  async uploadCsv(
    file: File,
  ): Promise<{
    data: CSVUploadResult;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );
    return this.base.post(`${this.resource}/upload`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async uploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`${this.resource}/upload/history`, {
      page,
      page_size,
    });
  }

  async getUploadSegment(
    fileImportId: number,
  ): Promise<any> {
    return this.base.get(`${this.resource}/upload/file-import/${fileImportId}`)
  }

}
