import { SyncAutoComplete, SyncAutoCompleteProps } from 'common/components';
import { OrderItemReassignFormState } from './use-order-item-reassign-form';

type OrderItemReassignFormKeys = keyof OrderItemReassignFormState;

interface FieldOptions {
  name: OrderItemReassignFormKeys;
  component: React.FC<SyncAutoCompleteProps>;
  label?: string;
}

export const fieldOptions: FieldOptions[] = [
  {
    name: 'distributor',
    component: SyncAutoComplete,
    label: 'Reassign to',
  },
];
