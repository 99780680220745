/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'reactn';
import { CSVUploadStatus } from 'common/services/apis/v3';
import { PBFFileUpload } from 'pages/pbf-assignment/constants';
import { DistributorFileImportType } from 'pages/distributor/components/file-import-tab/types';
import { IFileImport } from 'common/interfaces';

interface FileImportHistoryHookOptions {
  refresh: boolean;
  fetchHistory: (page: number, pageSize: number) => Promise<any>;
}

interface FileImportHistoryHook {
  history: IFileImport[] | never[];
  totalRows: number;
  pagination: {
    page: number;
    pageSize: number;
  };
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
}

export const useFileImportHistory = ({
  refresh = true,
  fetchHistory,
}: FileImportHistoryHookOptions): FileImportHistoryHook => {
  const [totalRows, setTotalRows] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
  });
  const [history, setHistory] = useState<any[] | never[]>([]);

  useEffect(() => {
    if (!refresh) return;

    const { page, pageSize } = pagination;
    fetchHistory(page, pageSize).then(({ data, meta }) => {
      setHistory(data);
      setTotalRows(meta.total_count);
    });
  }, [refresh, pagination]);

  useEffect(() => {
    if (history.length > 0) {
      const acceptedFileTypes = [
        PBFFileUpload.DPGAssignment,
        PBFFileUpload.PGAssignment,
        DistributorFileImportType.DistributorProductPharmacyMaxQuantity,
      ];
      const isFileProcessing = history.some(
        (historyObj) =>
          // eslint-disable-next-line no-restricted-globals
          historyObj?.status === CSVUploadStatus.Processing && acceptedFileTypes.includes(historyObj?.type),
      );
      if (isFileProcessing) {
        const intervalId = setInterval(() => {
          const { page, pageSize } = pagination;
          fetchHistory(page, pageSize).then(({ data, meta }) => {
            setHistory(data);
            setTotalRows(meta.total_count);
          });
        }, 30000);

        return () => clearInterval(intervalId);
      }
    }
    return undefined;
  }, [history]);

  const handlePageChange = (page: number): void =>
    setPagination((state) => ({
      ...state,
      page,
    }));

  const handlePageSizeChange = (pageSize: number): void =>
    setPagination({
      page: 1,
      pageSize,
    });

  return {
    history,
    totalRows,
    pagination,

    handlePageChange,
    handlePageSizeChange,
  };
};
