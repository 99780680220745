/* eslint-disable no-restricted-globals */
import { addReducer } from 'reactn';

import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';
import { recordException } from 'utils/Reporting/Sentry';

import { LogisticAreaState } from './interfaces/LogisticAreaState.interface';

export const LOGISTIC_AREA_INITIAL_STATE: LogisticAreaState = {
  logisticAreaList: {
    data: [],
  },
  loading: false,
};

addReducer('createLogisticArea', async (state, dispatch, payload) => {
  try {
    dispatch.setLogisticAreaLoading(true);

    await SwipeRxPt.instance.logisticsArea.create(payload);
  } catch (e) {
    recordException(e, 'createLogisticArea', { payload });
  } finally {
    dispatch.setLogisticAreaLoading(false);
  }
});

addReducer('updateLogisticArea', async (state, dispatch, {id, ...payload}) => {
  try {
    dispatch.setLogisticAreaLoading(true);

    await SwipeRxPt.instance.logisticsArea.update(id, payload);
  } catch (e) {
    recordException(e, 'updateLogisticArea', { payload });
  } finally {
    dispatch.setLogisticAreaLoading(false);
  }
});

addReducer('setLogisticAreaLoading', (state, _, payload) => {
  return {
    ...state,
    loading: payload
  };
});
