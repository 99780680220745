/* eslint-disable consistent-return */
import { addReducer } from "reactn"
import { MarketingState } from "./interfaces";
import { RootState } from "..";

export const MARKETING_INITIAL_STATE: MarketingState = {
  global: {
    loading: false,
  }
}

addReducer('setMarketingLoading', (state, _, loading): RootState => {
  const { marketing } = state;
  const { global } = marketing;

  return {
    ...state,
    marketing: {
      ...marketing,
      global: {
        ...global,
        loading,
      }
    }
  }
});
