import { ILogisticsPoDelivery, IMutableLogisticsPoDelivery } from 'common/interfaces/Logistics.interface';
import { ISearchable, ListResponse, ISearchParam } from '../../interfaces';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { IMutable } from '../../interfaces/IMutable.interface';

export class SwipeRxPtLogisticsPoDelivery
  implements ISearchable<ILogisticsPoDelivery>, IMutable<IMutableLogisticsPoDelivery> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.LOGISTICS_DISTRIBUTOR_CALENDAR;
  }

  async createOrUpdate(data: IMutableLogisticsPoDelivery): Promise<any> {
    return this.base.post(`${this.resource}`, data);
  }

  async search(params: ISearchParam, resourceOverride?: string): Promise<ListResponse<ILogisticsPoDelivery>> {
    return this.base.get(resourceOverride || this.resource, { ...params });
  }
}
