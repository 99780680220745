import moment from 'moment-timezone';

import { Order } from 'common/reducers';

interface SLA {
  SLATag: string;
  SLADay: number;
}

export const calculateSLA = (order: Order): SLA | null => {
  const { ordered_at, logistics_po_delivery } = order;

  const expected_delivery = logistics_po_delivery ? logistics_po_delivery.expected_delivery : null;

  if (!ordered_at || !expected_delivery) return null;

  try {
    const orderedAt = moment(ordered_at).utcOffset(7).startOf('day');
    const expectedAt = moment(expected_delivery).utcOffset(7).startOf('day');

    const expectationDay = expectedAt.diff(orderedAt, 'day');

    return {
      SLATag: expectationDay === 0 ? 'SD' : expectationDay === 1 ? 'ND' : `${expectationDay}D`,
      SLADay: expectationDay,
    };
  } catch {
    return null;
  }
};
