import styled from 'styled-components';

import { COLORS } from 'common/constants';

const ItemWrapper = styled.div`
  border: 1px solid #b3b8c8;
  border-radius: 5px;
  margin-bottom: 12px;
  min-width: 650px;
  padding: 10px;

  svg {
    color: ${COLORS.gray};
    margin-top: 5px;
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Message = styled.span`
  color: ${COLORS.gray};
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
`;

const BeforeDiffHeader = styled.span`
  color: #b3b8c8;
  display: block;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
`;

const AfterDiffValue = styled.span`
  color: #6469df;
  font-weight: bold;
`;

const DiffValueCell = styled.td`
  min-width: 130px;
  padding: 5px 0;
`;

export const S = {
  ItemWrapper,
  Message,
  BeforeDiffHeader,
  AfterDiffValue,
  DiffValueCell,
};
