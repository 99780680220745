import './App.scss';
import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import MomentUtils from '@date-io/moment';
import 'typeface-roboto';

import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import stores from 'common/models/index.model';

import theme from './common/themes/theme';
import history from './common/history';

import './index.style.css';
import 'config/http';
import { AppRouteContainer } from './routes/AppRouter.container';
import { RedirectToPath } from './routes/RedirectToPath';
import MaintenancePage from './pages/maintenance';
import WithMaintenanceMode from './pages/maintenance/WithMaintenanceMode.component';

const App: React.FC = () => {
  return (
    <Provider store={stores}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <WithMaintenanceMode history={history} maintenancePath="/maintenance">
              <Switch>
                <Route path="/maintenance" component={MaintenancePage} />
                <Route path="/market/:marketId" render={({ match: { path } }) => <AppRouteContainer path={path} />} />
                <Route path="*" component={RedirectToPath} />
              </Switch>
            </WithMaintenanceMode>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  );
};

/*
  This is used for Hot-Module Reloading.
  We only have to do it for the root component.

  - Immanuel
*/
const hotApp = hot(App);

export default hotApp;
