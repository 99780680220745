import styled, { css } from 'styled-components';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import { wrapperStyle } from '../Pagination.styles';

interface Props {
  disabled?: boolean;
}

const arrowStyle = css`
  && {
    font-size: 1.3rem;
    margin-bottom: -2px;
  }
`;

const getColor = ({ disabled }: Props): string =>
  disabled ? '#8D959C' : '#000';

const Wrapper = styled.div`
  ${wrapperStyle}

  && {
    display: flex;
  }
`;

const Text = styled.span`
  && {
    color: ${getColor};
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const Inactive = styled.span`
  && {
    color: #8d959c;
  }
`;

const Previous = styled(KeyboardArrowLeft)`
  ${arrowStyle}

  && {
    border-right: 2px solid #fafafa;
    color: ${getColor};
  }
`;

const Next = styled(KeyboardArrowRight)`
  ${arrowStyle}

  && {
    border-left: 2px solid #fafafa;
    color: ${getColor};
  }
`;

export const S = {
  Wrapper,
  Text,
  Inactive,
  Previous,
  Next,
};
