import React from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import Checkbox from '@material-ui/core/Checkbox';

import { formatToCurrency, formatToDatetime, formatToPercentage } from 'common/utils/formatters';
import { ColorType, DataObject } from 'types';
import { ConditionalRender } from 'common/components/ConditionalRender';

import { CardTableBodyCell } from '../../interfaces';
import { CardTableCellType, CardTableRowFooter, CardTableSelectHandler } from '../../types';

import { RowFooter } from '../RowFooter';

import { S } from './CardTableRow.styles';

interface Props {
  data: DataObject;
  disabled?: boolean;
  fields: CardTableBodyCell[];
  footer?: CardTableRowFooter;
  highlight?: ColorType;
  index: number;
  isLast?: boolean;
  selectable?: boolean;
  selected?: boolean;
  onSelect?: CardTableSelectHandler;
}

const RowSeparator: React.FC = () => (
  <S.RowSeparator>
    <tr>
      <td />
    </tr>
  </S.RowSeparator>
);

const CardTableRowBase: React.FC<Props> = ({
  data,
  disabled,
  fields,
  footer,
  highlight,
  index,
  isLast,
  selectable,
  selected,
  onSelect,
}) => {
  const handleCheckboxClick = (): void => {
    if (onSelect) {
      onSelect(data.id);
    }
  };

  const transformValueByType = (value: any, type?: CardTableCellType): any => {
    switch (type) {
      case CardTableCellType.DATETIME:
        return formatToDatetime(value);
      case CardTableCellType.DATE:
        return formatToDatetime(value, 'MMM DD, YYYY');
      case CardTableCellType.CURRENCY:
        return formatToCurrency(value);
      case CardTableCellType.PERCENTAGE:
        return formatToPercentage(value);
      default:
        return value;
    }
  };

  const renderColumns = (): JSX.Element[] =>
    fields.map((field) => {
      const { id, path, render, type } = field;
      const key = path || id;

      let finalNode: React.ReactNode = null;

      if (!path && render) {
        finalNode = render(null, data, index);
      }

      if (path) {
        const value = get(data, path as string);
        const transformedValue = !['null', 'undefined'].includes(typeof value)
          ? transformValueByType(value, type)
          : '-';

        finalNode = render ? render(transformedValue, data, index) : transformedValue;
      }

      return <td key={key}>{finalNode}</td>;
    });

  return (
    <>
      <S.Wrapper className={disabled ? 'disabled' : ''} hasFooter={!!footer}>
        <S.PrimaryRow hasFooter={!!footer} highlight={highlight}>
          <ConditionalRender condition={!!selectable}>
            <S.CheckboxCell>
              <Checkbox
                checked={selected === undefined ? false : selected}
                color="primary"
                onClick={handleCheckboxClick}
              />
            </S.CheckboxCell>
          </ConditionalRender>
          {renderColumns()}
        </S.PrimaryRow>
        <ConditionalRender condition={!!footer}>
          <RowFooter colSpan={fields.length + 1} data={data} footer={footer as CardTableRowFooter} />
        </ConditionalRender>
      </S.Wrapper>
      <ConditionalRender condition={!!footer && !isLast}>
        <RowSeparator />
      </ConditionalRender>
    </>
  );
};

const propsAreEqual = (prevProps: Props, nextProps: Props): boolean => {
  const {
    data: prevData,
    disabled: prevDisabled,
    fields: prevFields,
    selected: prevSelected,
    highlight: prevHighlight,
  } = prevProps;

  const {
    data: nextData,
    disabled: nextDisabled,
    fields: nextFields,
    selected: nextSelected,
    highlight: nextHighlight,
  } = nextProps;

  const prevFieldKeys = prevFields.map(({ key }) => key);
  const nextFieldKeys = nextFields.map(({ key }) => key);

  return (
    isEqual(prevData, nextData) &&
    isEqual(prevFieldKeys, nextFieldKeys) &&
    prevDisabled === nextDisabled &&
    prevHighlight === nextHighlight &&
    prevSelected === nextSelected
  );
};

export const CardTableRow = React.memo(CardTableRowBase, propsAreEqual);
