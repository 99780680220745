import { MARKETING_ROLES } from "common/constants";
import { TabsMarketingType } from "common/services/apis/v3/swipe-rx-pt/resources/marketing/constants/marketing.constants";

export enum MarketingAction {
    Listing = TabsMarketingType.LISTING,
    Banner = TabsMarketingType.BANNER,
    Homesquare = TabsMarketingType.HOMESQUARE,
    SearchCampaign = TabsMarketingType.CAMPAIGN_SEARCH,
}
  
export const MarketingActionPermissions = [
    {
      action: MarketingAction.Listing,
      roleAccess: MARKETING_ROLES,
    },
    {
      action: MarketingAction.Banner,
      roleAccess: MARKETING_ROLES,
    },
    {
      action: MarketingAction.Homesquare,
      roleAccess: MARKETING_ROLES,
    },
    {
      action: MarketingAction.SearchCampaign,
      roleAccess: MARKETING_ROLES,
    },
  ];
  