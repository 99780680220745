import React from 'react';
import { Grid } from '@material-ui/core';
import { S } from './CardTableAllPagesSelectionButton.styles';
import { CardTableOnAllPagesSelect } from '../../types';

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
interface Props {
  onClick: CardTableOnAllPagesSelect;
  actionInformation: string;
  selectionValue: boolean;
  children: React.ReactNode;
}

export const CardTableAllPagesSelectionButton: React.FC<Props> = ({
  onClick,
  actionInformation,
  selectionValue,
  children,
}) => (
    <S.InformationContainer>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <S.Information>{children}</S.Information>
          </Grid>
          <Grid item data-testid="select-all-po">
            <S.ActionInformation>
              <div onClick={() => onClick(selectionValue)}>
                {actionInformation}
              </div>
            </S.ActionInformation>
          </Grid>
        </Grid>
      </Grid>
    </S.InformationContainer>
  );
