import React from 'react';

import { DataObject } from 'types';
import { Button, SplitButton } from 'common/components';
import { CardTableRowFooterAction } from '../../interfaces';

type Props = Omit<CardTableRowFooterAction, 'id'> & {
  data: DataObject;
  onClick?: (name: string, data: DataObject) => void;
  disabled?: boolean;
};

export const CardTableRowFooterButton: React.FC<Props> = ({
  color,
  data,
  isSecondary,
  items,
  name,
  text,
  onClick,
  disabled = false,
}) => {
  const handleClick = (): void => {
    if (onClick) {
      onClick(name, data);
    }
  };

  // eslint-disable-next-line no-restricted-globals
  if (items?.length) {
    const handleSelect = (actionName: string): void => {
      if (onClick) {
        onClick(actionName, data);
      }
    };

    return (
      <SplitButton
        color={color}
        text={text}
        items={items}
        onClick={handleClick}
        onSelect={handleSelect}
        disabled={disabled}
      />
    );
  }

  return (
    <Button onClick={handleClick} isSecondary={isSecondary} color={color} disabled={disabled}>
      {text}
    </Button>
  );
};
