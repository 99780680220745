import { Order } from 'common/services/apis/v3';
import { PrecursorRejectReasonObject } from '../components/PrecursorDetail/PrecursorDetail.type';

export enum OrderPrecursorStatus {
  DOCUMENT_REQUIRED = 'required',
  DOCUMENT_WAITING_APPROVAL = 'submitted',
  DOCUMENT_RESUBMITTED = 'resubmitted',
  DOCUMENT_REJECTED = 'rejected',
  DOCUMENT_CANCELLED = 'cancelled',
  DOCUMENT_APPROVED = 'approved',
}

export enum OrderPrecursorDocumentType {
  ELECTRONIC = 'Electronic SP',
  MANUAL = 'Manual',
}

export interface PrecursorStatus {
  id: number;
  purchase_order_id: number;
  purchase_order_number: string;
  document_url?: string;
  document_type?: OrderPrecursorDocumentType;
  status: OrderPrecursorStatus;
  reason?: PrecursorRejectReasonObject[] | null;
  description?: string | null;
  user_id: number;
  event_time: Date;
  purchase_order?: Order;
}
