import moment from 'moment-timezone';
import { getMarketIdTimezone } from './get-market-timezone.util';

export class GlobalMoment {
  public static instance: GlobalMoment = new GlobalMoment();

  public momentL: moment.MomentTimezone = moment.tz.setDefault(moment.tz.guess());

  initialize(marketId = 'id'): void {
    const timezone = getMarketIdTimezone(marketId);
    this.momentL = moment.tz.setDefault(timezone);
  }
}
