import React, { useDispatch, useGlobal, useState } from 'reactn';

import { Button, Dialog } from 'common/components';
import { ColorType } from 'types';

import { MergeOrderConfirmationModal } from './MergeOrderConfirmationModal.component';
import { S } from './MergeOrderModal.styles';

export const MergeOrderModal: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);

  const [{ merge }] = useGlobal('transactions');
  const setPoNumbersForMerge = useDispatch('setPoNumbersForMerge');

  const { poNumbers } = merge;

  const handleClose = (): void => {
    setPoNumbersForMerge({ poNumbers: [] });
    setSubmitted(false);
  };

  const handleSubmit = (): void => {
    setSubmitted(true);
  };

  const renderContent = (): JSX.Element => (
    <>
      <S.Description>
        You are about to merge the following orders.
        <br />
        Please review before submitting.
      </S.Description>
      <S.ListWrapper hideScroll={poNumbers.length < 4}>
        {poNumbers.map((poNumber) => (
          <S.ListItem>
            <S.ListHeader>Order ID</S.ListHeader>
            <S.ListBody>{poNumber}</S.ListBody>
          </S.ListItem>
        ))}
      </S.ListWrapper>
    </>
  );

  const renderActions = (): JSX.Element => (
    <Button color={ColorType.PRIMARY} onClick={handleSubmit}>
      Submit
    </Button>
  );

  return (
    <>
      <Dialog
        open={!!poNumbers.length && !submitted}
        primary
        title="Merge Orders"
        onClose={handleClose}
        renderActions={renderActions}
        renderContent={renderContent}
      />
      <MergeOrderConfirmationModal open={submitted} onClose={handleClose} />
    </>
  );
};
