import { OrderFulfillmentFormState } from "./use-order-fulfillment-form";

type OrderFulfillmentFormKeys = keyof OrderFulfillmentFormState;

interface FieldOptions {
  name: OrderFulfillmentFormKeys;
  type: 'file';
  label?: string;
}

export const fieldOptions: FieldOptions[] = [
  {
    name: 'invoice',
    type: 'file',
    label: 'Invoice'
  },
  {
    name: 'ttf',
    type: 'file',
    label: 'TTF'
  }
];