/* eslint-disable consistent-return */
import { OrganizationType } from './organization-auto-complete.constant';
import { Organization, Options } from './organization-auto-complete.interface';
import { PtAPIv2 } from '../../../services/apis/v2/utils';

const apiPath = `organizations`;

export const fetchOrganizations = async (
  input: string,
  type: OrganizationType,
  allStatus?: boolean,
): Promise<Options[] | undefined> => {
  let params: { [key: string]: any } = {
    type,
    page_size: 10,
    page: 1,
    sort_by: 'asc',
    order_by: 'id',
  };

  if (!input) return;

  const isMatch = input.match(/^\d+$/);

  if (isMatch) {
    params = { ...params, id: isMatch.pop() };
  } else {
    params = { ...params, query: input };
  }

  if (allStatus !== undefined) {
    params.all_status = Number(allStatus);
  }

  const responseData = await PtAPIv2.dashboard.get(apiPath, params);

  const { organizations } = responseData.result;

  const data: Options[] = organizations.map(({ id, name, status, OrganizationPaymentMethod }: Organization) => ({
    name,
    label: `${name} (${id})`,
    value: id,
    status,
    paymentMethod: OrganizationPaymentMethod?.payment_method,
  }));

  return data;
};
