import React from 'react';

import {
  HomeIcon,
  PharmaciesIcon,
  CreditIcon,
  TransactionIcon,
  ScanDocumentIcon,
  FinanceIcon,
  ProductIcon,
  DistributorsIcon,
  MarketingIcon,
  MarketingSegmentsIcon,
  LoyaltyIcon,
  EventIcon,
  LogisticIcon,
  VoucherIcon,
  RewardsIcon,
  DocumentPharmacyIcon,
} from 'common/components/Icon';
import {
  DISTRIBUTOR_MEMBER_ROLES,
  PRODUCT_MEMBER_ROLES,
  DISTRIBUTOR_CALENDAR_MANAGER_ROLES,
  UserRoles,
  TRANSACTION_ACCESS_ROLES,
  LOYALTY_ROLES,
} from 'common/constants';

import { IModuleConfig } from 'common/reducers';
import { LocationOn, Store } from '@material-ui/icons';
import { PaymentActivation } from 'utils/locale';
import { TabsMarketingType } from 'common/services/apis/v3/swipe-rx-pt/resources/marketing/constants/marketing.constants';
import { SidebarMenuConfigOptions } from './sidebar-menu.interface';

export const sideBarMenuConfig: (
  prefix: string,
  modules: IModuleConfig,
  activations: PaymentActivation[],
) => SidebarMenuConfigOptions[] = (prefix, modules, activations) => {
  const menu = [
    {
      path: `${prefix}`,
      label: 'Home',
      icon: <HomeIcon />,
      roleAccess: [UserRoles.CUSTOMER_SUPPORT, UserRoles.FINANCE],
    },
    {
      path: `${prefix}/pharmacies`,
      label: 'Pharmacies',
      icon: <PharmaciesIcon />,
      roleAccess: [
        UserRoles.CUSTOMER_SUPPORT,
        UserRoles.FINANCE,
        UserRoles.TRANSACTION_MANAGER,
        UserRoles.SALES_SUPPORT,
        UserRoles.PHARMACIST,
        UserRoles.MARKETING,
        UserRoles.SALES_SUPERVISOR,
      ],
    },
    {
      path: `${prefix}/pharmacy-users`,
      label: 'Pharmacy User',
      icon: <Store />,
      roleAccess: modules['pharmacy-users']['view-module'],
    },
  ];

  if (activations.includes('modalku')) {
    menu.push({
      path: `${prefix}/credits`,
      label: 'Credits',
      icon: <CreditIcon />,
      roleAccess: [UserRoles.CUSTOMER_SUPPORT],
    });
  }

  return menu.concat([
    {
      path: `${prefix}/transactions`,
      label: 'Transactions',
      icon: <TransactionIcon />,
      roleAccess: modules.transactions['view-module'] || TRANSACTION_ACCESS_ROLES,
    },
    {
      path: `${prefix}/document-management/finance`,
      label: 'Document Finance',
      icon: <ScanDocumentIcon />,
      roleAccess: [
        UserRoles.VENDOR_SPECIALIST,
        UserRoles.FULFILLMENT_ADMIN,
        UserRoles.TRANSACTION_MANAGER,
        UserRoles.SENIOR_VENDOR_SPECIALIST,
        UserRoles.FINANCE,
      ],
    },
    {
      path: `${prefix}/document-management/pharmacy`,
      label: 'Document Pharmacy',
      icon: <DocumentPharmacyIcon />,
      roleAccess: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.SALES_SUPPORT, UserRoles.CUSTOMER_SUPPORT],
    },
    {
      path: `${prefix}/finance`,
      label: 'Finance',
      icon: <FinanceIcon />,
      roleAccess: [UserRoles.FINANCE],
    },
    {
      path: `${prefix}/product`,
      label: 'Product',
      icon: <ProductIcon />,
      roleAccess: PRODUCT_MEMBER_ROLES,
    },
    {
      path: `${prefix}/distributor`,
      label: 'Distributor',
      icon: <DistributorsIcon />,
      roleAccess: DISTRIBUTOR_MEMBER_ROLES,
    },
    {
      path: `${prefix}/marketing/${TabsMarketingType.LISTING}`,
      label: 'Marketing',
      icon: <MarketingIcon />,
      roleAccess: [UserRoles.MARKETING],
    },
    {
      path: `${prefix}/marketing-rewards`,
      label: 'Marketing Rewards',
      icon: <RewardsIcon />,
      roleAccess: LOYALTY_ROLES,
    },
    {
      path: `${prefix}/pbf-assignment`,
      label: 'Area',
      icon: <LocationOn />,
      roleAccess: modules['pbf-assignment']['view-module'],
    },
    {
      path: `${prefix}/loyalty`,
      label: 'Loyalty',
      icon: <LoyaltyIcon />,
      roleAccess: [UserRoles.MARKETING, UserRoles.SALES_SUPPORT],
    },
    {
      path: `${prefix}/marketing-segments`,
      label: 'Marketing Segments',
      icon: <MarketingSegmentsIcon />,
      roleAccess: [UserRoles.MARKETING],
    },
    {
      path: `${prefix}/distributor-calendar`,
      label: 'Distributor Calendar Events',
      icon: <EventIcon />,
      roleAccess: DISTRIBUTOR_CALENDAR_MANAGER_ROLES,
    },
    {
      path: `${prefix}/logistics-area`,
      label: 'Logistic Management',
      icon: <LogisticIcon />,
      roleAccess: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.TRANSACTION_MANAGER],
    },
    {
      path: `${prefix}/vouchers`,
      label: 'Voucher Management',
      icon: <VoucherIcon />,
      roleAccess: modules.voucher['view-module'] as UserRoles[],
    },
  ]);
};
