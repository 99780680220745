import React from 'react';

import truncate from 'lodash/truncate';
import Async from 'react-select/async';

import { PaymentMethod } from 'common/constants';

import { AsyncAutoComplete } from '..';
import { Options } from './organization-auto-complete.interface';
import { OrganizationType } from './organization-auto-complete.constant';
import { styles } from './organization-auto-complete.styles';
import { AsyncAutoCompleteProps } from '../async-auto-complete.interface';
import { fetchOrganizations } from './fetch-organizations';

interface Props extends Omit<AsyncAutoCompleteProps, 'fetchData' | 'styles' | 'handleChange'> {
  title?: string;
  type: OrganizationType;
  variant?: 'primary' | 'secondary' | 'basic';
  selectInput: (selected: { id: number; name: string; paymentMethod?: PaymentMethod }) => void;
  selectRef?: React.RefObject<Async<HTMLInputElement | any>>;
  inputValue?: string;
  isClearable?: boolean;
  allStatus?: boolean;
}

export const OrganizationAutoComplete: React.FC<Props> = ({
  type,
  variant,
  selectInput,
  title = '',
  placeholder = `Enter ${type} name`,
  allStatus,
  ...props
}) => {
  const titleDisplay = truncate(title, {
    length: 27,
  });

  const fetchData = async (input: string, callback: Function): Promise<void> => {
    const data = await fetchOrganizations(input, type, allStatus);
    callback(data);
  };

  const handleChange = (options: Options): void => {
    if (options) {
      selectInput({ id: options.value, name: options.name, paymentMethod: options.paymentMethod });
    }
  };

  return (
    <AsyncAutoComplete
      data-testid="auto-complete-search-box"
      styles={variant ? styles[variant] : false}
      placeholder={!title ? placeholder : titleDisplay}
      fetchData={fetchData}
      handleChange={handleChange}
      {...props}
    />
  );
};
