/* eslint-disable no-bitwise */
import numeral from 'numeral';

import { setNumeralLocale, DEFAULT_LOCALE } from './locale';

// Formatting for id numbers
numeral.register('locale', 'id', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(num: number) {
    const b = num % 10;
    return ~~((num % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
  },
  currency: {
    symbol: 'Rp.',
  },
});

// Formatting for th numbers
numeral.register('locale', 'th', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(num: number) {
    const b = num % 10;
    return ~~((num % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
  },
  currency: {
    symbol: 'THB',
  },
});

setNumeralLocale(DEFAULT_LOCALE);
