/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDispatch, useEffect, useGlobal, useState } from 'reactn';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ValueType, OptionTypeBase } from 'react-select';

export interface OrderReassignFormState {
  distributor: string;
}

const formState: OrderReassignFormState = {
  distributor: '',
};

export const useOrderReassignForm = () => {
  const [{ reassign }] = useGlobal('transactions');
  const reassignOrders = useDispatch('reassignOrders');
  const setOrderIdsForReassign = useDispatch('setOrderIdsForReassign');
  const [selectedDistributor, setSelectedDistributor] = useState<ValueType<OptionTypeBase> | null>(null);

  const clearOrderIds = (): void => {
    setOrderIdsForReassign({ orderIds: [] });
  };

  const form = useFormik({
    initialValues: {
      ...formState,
    },
    validationSchema: Yup.object({
      distributor: Yup.string().required('Please select a distributor.'),
    }),
    onSubmit: ({ distributor }, { setSubmitting }) => {
      reassignOrders({ distributorId: Number(distributor) });
      setSubmitting(false);
      clearOrderIds();
    },
  });

  useEffect(() => {
    form.setValues({ ...formState });
    Object.keys(formState).map((key: string): void => {
      form.setTouched({ [key]: false });
    });

    return () => setSelectedDistributor(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reassign.orderIds]);

  return {
    form,
    reassign,
    clearOrderIds,
    selectedDistributor,
    setSelectedDistributor,
  };
};
