/* eslint-disable no-restricted-globals */
import { SwipeRxPt } from 'common/services/apis/v3';
import { addReducer } from 'reactn';
import { recordException } from 'utils/Reporting/Sentry';

import { ReturnDetailState, ReturnState } from './interfaces/ReturnState.interface';

export const RETURN_INITIAL_STATE: ReturnState = {
  returnList: {
    data: [],
    meta: {
      page: 1,
      page_count: 1,
      page_size: 10,
      total_count: 1,
    },
  },
  loading: false,
};

export const RETURN_DETAIL_INITIAL_STATE: ReturnDetailState = {
  loading: false,
  error: null,
  returnDetail: {
    id: 0,
    created_at: '',
    total_amount: 0,
    credit_memo: {
      id: 0,
      credit_memo_no: '',
      uploaded_at: new Date(),
    },
    invoice: {
      invoice_number: '',
      tax_invoice_no: '',
      tax_invoice_date: '',
      purchase_order: {
        tax_rate: 0,
        pharmacy: {
          name: '',
          address: '',
          tax_number: '',
        },
        distributor: {
          name: '',
          address: '',
          tax_number: '',
        },
      },
    },

    returned_items: [],
  },
};

addReducer('fetchReturnDataById', async (state, dispatch, { id }) => {
  dispatch.setReturnLoading(true);

  try {
    const { data } = await SwipeRxPt.instance.returnManagement.getByID(id);
    const returnDetailState: ReturnDetailState = RETURN_DETAIL_INITIAL_STATE;
    returnDetailState.returnDetail.id = data.id;
    returnDetailState.returnDetail.credit_memo = data.credit_memo;
    returnDetailState.returnDetail.total_amount = data.total_amount;
    returnDetailState.returnDetail.invoice = {
      invoice_number: data.invoice.invoice_number,
      tax_invoice_no: data.invoice.tax_invoice_no || '',
      tax_invoice_date: data.invoice.tax_invoice_date || '',
      purchase_order: {
        tax_rate: data.invoice.purchase_order.tax_rate,
        pharmacy: {
          name: data.invoice.purchase_order.pharmacy.name,
          address: data.invoice.purchase_order.pharmacy.address,
          tax_number: data.invoice.purchase_order.pharmacy?.crm?.values?.swiperx_pt_id_account?.NPWP_Number,
        },
        distributor: {
          name: data.invoice.purchase_order.distributor.name,
          address: data.invoice.purchase_order.distributor.address,
          tax_number: '-', // not available at the moment
        },
      },
    };
    returnDetailState.returnDetail.created_at = data.created_at;
    returnDetailState.returnDetail.returned_items = data.return_items.map((item: any) => {
      return {
        item,
        product: {
          name: item.purchase_order_item.product.name,
          package: item.purchase_order_item.product.package,
          sku_code: item.purchase_order_item.product.sku_code,
        },
      };
    });

    dispatch.setReturnDataDetail({ returnDetailState });
  } catch (error) {
    recordException(error, 'fetchReturnDataTable', { id });
    dispatch.setReturnDataDetailError(error);
  } finally {
    dispatch.setReturnLoading(false);
  }
});

addReducer('updateInvoiceTaxInformation', async (state, dispatch, { id, payload }) => {
  try {
    const { returnManagementDetail } = state;
    await SwipeRxPt.instance.returnManagement.updateTaxInformation(id, payload);

    dispatch.setReturnDataDetail({ returnManagementDetail });
  } catch (error) {
    recordException(error, 'updateInvoiceTaxInformation', { id, payload });
    dispatch.setReturnDataDetailError(error);
  }
});

addReducer('updateCreditMemo', async (state, dispatch, { id, creditMemoPayload }) => {
  try {
    const { returnManagementDetail } = state;
    await SwipeRxPt.instance.returnManagement.updateCreditMemo(id, creditMemoPayload);
    dispatch.setReturnDataDetail({ returnManagementDetail });
  } catch (error) {
    recordException(error, 'updateInvoiceTaxInformation', { id, creditMemoPayload });
    dispatch.setReturnDataDetailError(error);
  }
});

addReducer('setReturnDataDetail', (state) => {
  const { returnManagementDetail } = state;

  return {
    ...state,
    returnManagementDetail,
  };
});

addReducer('setReturnDataDetailError', (state, _, error) => {
  const { returnManagementDetail } = state;

  return {
    ...state,
    returnManagementDetail: {
      ...returnManagementDetail,
      error: error.message,
    },
  };
});

addReducer('resetReturnDataDetailError', (state) => {
  const { returnManagementDetail } = state;

  return {
    ...state,
    returnManagementDetail: {
      ...returnManagementDetail,
      error: null,
    },
  };
});

addReducer('fetchReturnDataTable', async (state, dispatch, { params }) => {
  dispatch.setReturnLoading(true);

  try {
    const { data, meta } = await SwipeRxPt.instance.returnManagement.list(params);

    dispatch.setReturnDataTable({ data, meta });
  } catch (error) {
    recordException(error, 'fetchReturnDataTable', { params });
    dispatch.setReturnError(error);
  } finally {
    dispatch.setReturnLoading(false);
  }
});

addReducer('setReturnDataTable', (state, _, { data, meta }) => {
  const { returnManagement } = state;

  return {
    ...state,
    returnManagement: {
      ...returnManagement,
      returnList: {
        data,
        meta,
      },
    },
  };
});

addReducer('deleteReturn', async (_state, dispatch, _returnId) => {
  dispatch.setReturnLoading(true);
});

addReducer('clearReturnDataTable', (state) => {
  const { returnManagement } = state;

  return {
    ...state,
    returnManagement: {
      ...returnManagement,
      loading: false,
      returnList: {
        data: [],
        meta: {
          page: 1,
          page_count: 1,
          page_size: 10,
          total_count: 1,
        },
      },
    },
  };
});

addReducer('setReturnError', (state, _, error) => {
  const { returnManagement } = state;

  return {
    ...state,
    returnManagement: {
      ...returnManagement,
      error: error.message,
    },
  };
});

addReducer('setSuccessMessage', (state, _, message) => {
  const { returnManagement } = state;

  return {
    ...state,
    returnManagement: {
      ...returnManagement,
      successMessage: message,
    },
  };
});

addReducer('resetReturnError', (state) => {
  const { returnManagement } = state;

  return {
    ...state,
    returnManagement: {
      ...returnManagement,
      error: null,
    },
  };
});

addReducer('resetSuccessMessage', (state) => {
  const { returnManagement } = state;

  return {
    ...state,
    returnManagement: {
      ...returnManagement,
      successMessage: null,
    },
  };
});

addReducer('setReturnLoading', (state, _, isLoading) => {
  const { returnManagement } = state;

  return {
    ...state,
    returnManagement: {
      ...returnManagement,
      loading: isLoading,
    },
  };
});

addReducer('removeFromReturnDataTable', async (state, _, removedIds: number[]) => {
  const { returnManagement } = state;
  const { data, meta } = returnManagement.returnList;

  return {
    ...state,
    returnManagement: {
      ...returnManagement,
      returnList: {
        data: data.filter((row) => !removedIds.includes(row.id)),
        meta,
      },
    },
  };
});
