/* eslint-disable no-restricted-globals */
import React, { useCallback, useEffect, useDispatch, useGlobal } from 'reactn';
import { useFormik } from 'formik';
import { v4 as uuid } from 'uuid';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { UserRoles } from 'common/constants';
import { useGetAuthenticatedUser, useUpdateQueryParams } from 'hooks';
import { ColorType, DataObject } from 'types';
import { OrderStatus, OrderFulfillmentStatus, OrderReturnType, Order } from 'common/services/apis/v3/swipe-rx-pt';
import { calculateNetPrice, getOrderElapsedTimeElement, pluralize } from 'common/utils';
import { formatToCurrency, formatToPercentage } from 'common/utils/formatters';
import { Badge, BadgeStyles, Button, CardTable, Dialog } from 'common/components';
import { CardTableCellType } from 'common/components/CardTable/types';
import { CardTableColumn } from 'common/components/CardTable/interfaces';

import { SplitOrderItemModal } from '../SplitOrderItemModal';
import { OrderItemFlagConfirmationModal } from '../OrderItemFlagConfirmationModal';
import { OrderItemFlagInputs } from '../OrderItemFlagInputs';
import { OrderItemReassignModal } from '../order-item-reassign-modal';

import { BasicOrderDetails } from './BasicOrderDetails.component';
import { S } from './OrderDetailsModal.styles';
import { InvoicePhotos } from './InvoicePhotos.component';

export const OrderDetailsModal: React.FC = () => {
  const {
    queryParams: { order_id, status: statusFromUrl },
    updateQueryParams,
  } = useUpdateQueryParams();
  const user = useGetAuthenticatedUser();

  const [{ orderDetails, orderItemFlagSummary, split, orderItemReassign }] = useGlobal('transactions');

  const fetchTransactionOrderDetails = useDispatch('fetchTransactionOrderDetails');
  const setTransactionOrderDetails = useDispatch('setTransactionOrderDetails');
  const initializeOrderItemFlagSummary = useDispatch('initializeOrderItemFlagSummary');
  const initializeOrderItemSplit = useDispatch('initializeOrderItemSplit');
  const initializeOrderItemReassign = useDispatch('initializeOrderItemReassign');

  const clearOrganizationDetails = useCallback(() => {
    updateQueryParams({
      detail: null,
      order_id: null,
    });
    setTransactionOrderDetails(null);
  }, [setTransactionOrderDetails, updateQueryParams]);

  useEffect(() => {
    fetchTransactionOrderDetails({
      id: parseInt(order_id as string, 10),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderDetails.error) {
      clearOrganizationDetails();
    }
  }, [clearOrganizationDetails, orderDetails.error]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      items:
        orderDetails.data?.items?.map(
          ({
            id,
            is_out_of_stock,
            discount_rate,
            selling_price,
            quantity,
            previous_selling_price,
            previous_quantity,
            previous_discount_rate,
          }) => ({
            id,
            is_out_of_stock: is_out_of_stock === null ? false : is_out_of_stock,
            stock_limit: previous_quantity ? quantity.toString() : null,
            price_change: previous_selling_price ? selling_price.toString() : null,
            discount_rate_change: previous_discount_rate !== null ? (discount_rate * 100).toFixed(2) : null,
          }),
        ) || [],
    },
    onSubmit: (values) => {
      if (!orderDetails.data) {
        return;
      }

      const newItems = values.items.map((item) => {
        const { stock_limit, price_change, discount_rate_change } = item;

        return {
          ...item,
          stock_limit: Number(stock_limit),
          price_change: Number(price_change),
          discount_rate_change: discount_rate_change === null ? null : Number(discount_rate_change),
        };
      });

      initializeOrderItemFlagSummary({
        newItems,
        order: orderDetails.data,
        initialItems: orderDetails.data.items,
      });
    },
  });

  const handleClose = (): void => {
    clearOrganizationDetails();
  };

  const handleSubmit = async (): Promise<void> => {
    form.submitForm();
  };

  const handleBulkActionSelect = (name: string, ids: string[], indices: number[]): void => {
    const orderItemIds = ids.map((id) => Number(id));

    switch (name) {
      case 'stockout': {
        const shouldEnableAll = indices.some((index) => {
          const { is_out_of_stock, stock_limit, price_change, discount_rate_change } = form.values.items[index];

          const isAllowedToEnable = !stock_limit && !price_change && !discount_rate_change;

          if (!isAllowedToEnable && !is_out_of_stock) {
            return false;
          }

          return !is_out_of_stock;
        });

        indices.forEach((index) => {
          const { is_out_of_stock, stock_limit, price_change, discount_rate_change } = form.values.items[index];

          const isAllowedToEnable = !stock_limit && !price_change && !discount_rate_change;

          const shouldEnable = !is_out_of_stock ? isAllowedToEnable : shouldEnableAll;

          form.setFieldValue(`items[${index}].is_out_of_stock`, shouldEnable);
        });

        break;
      }
      case 'split': {
        if (!orderDetails.data) {
          break;
        }

        const { id: orderId, po_number } = orderDetails.data;

        initializeOrderItemSplit({
          ids: orderItemIds,
          poNumber: po_number,
          orderId,
        });

        break;
      }
      case 'reassign_order_item': {
        initializeOrderItemReassign({
          orderItemIds,
        });

        break;
      }
      default:
        break;
    }
  };

  const bulkActionItems = [
    {
      name: 'stockout',
      text: 'Out of Stock',
    },
  ];

  const isSplitAllowed = [
    UserRoles.SENIOR_VENDOR_SPECIALIST,
    UserRoles.TRANSACTION_MANAGER,
    UserRoles.SUPER_ADMIN,
    UserRoles.DISTRIBUTOR_STAFF,
    UserRoles.PHARMACIST,
  ].includes(user.userType);

  if (isSplitAllowed) {
    bulkActionItems.push({
      name: 'split',
      text: 'Split',
    });
  }

  const isOrderItemReassignAllowed = [
    UserRoles.TRANSACTION_MANAGER,
    UserRoles.SUPER_ADMIN,
    UserRoles.PHARMACIST,
  ].includes(user.userType);

  if (isOrderItemReassignAllowed) {
    bulkActionItems.push({
      name: 'reassign_order_item',
      text: 'Reassign',
    });
  }

  const notCustomerSupport = user.userType !== UserRoles.CUSTOMER_SUPPORT;

  const isFlaggingAllowed =
    (notCustomerSupport &&
      [OrderStatus.PENDING, OrderStatus.PROCESSING].includes(orderDetails?.data?.status as OrderStatus)) ||
    ((user.userType === UserRoles.SUPER_ADMIN 
        || user.userType === UserRoles.FINANCE
        || user.userType === UserRoles.PHARMACIST
      ) &&
      orderDetails?.data?.status === OrderStatus.FULFILLED);

  const orderItemTableColumns: CardTableColumn[] = [
    {
      path: 'sku_id',
      text: 'SKU ID',
      width: 100,
      render: (_, { product: { sku_code } }) => {
        return <>{sku_code ?? '-'}</>;
      },
    },
    {
      id: 'product_detail',
      text: 'Product',
      width: 250,
      render: (_, { product, product_id, is_out_of_stock, return_reason }) => {
        const productElement = (
          <S.Product>
            {product.name}
            <S.ItemDescription>{product.package}</S.ItemDescription>
            {is_out_of_stock ? <S.Stockout>Out of Stock</S.Stockout> : null}
          </S.Product>
        );

        const productWithTooltip = return_reason ? (
          <Tooltip title={return_reason} placement="bottom" interactive>
            {productElement}
          </Tooltip>
        ) : (
          productElement
        );

        return !product && product_id ? <em>Not available. Data might have been deleted.</em> : productWithTooltip;
      },
    },
    {
      path: 'selling_price',
      text: 'Unit Price',
      type: CardTableCellType.CURRENCY,
      width: 150,
      render: (value, { previous_selling_price }) => (
        <S.DiffCellWrapper>
          {value}
          {!previous_selling_price ? null : (
            <div>
              <S.PreviousValueHeader>
                Previous
                <br />
                Price
              </S.PreviousValueHeader>
              <S.PreviousValue>{formatToCurrency(previous_selling_price)}</S.PreviousValue>
            </div>
          )}
        </S.DiffCellWrapper>
      ),
    },
    {
      path: 'discount_rate',
      text: 'Discount',
      type: CardTableCellType.PERCENTAGE,
      width: 70,
      render: (value, { previous_discount_rate }) => (
        <S.DiffCellWrapper>
          {value}
          {previous_discount_rate === null ? null : (
            <div>
              <S.PreviousValueHeader>
                Previous
                <br />
                Discount Rate
              </S.PreviousValueHeader>
              <S.PreviousValue>{formatToPercentage(previous_discount_rate)}</S.PreviousValue>
            </div>
          )}
        </S.DiffCellWrapper>
      ),
    },
    {
      path: 'net_price',
      text: 'Price After Discount',
      type: CardTableCellType.CURRENCY,
      width: 150,
      render: (value, { discount_rate, selling_price, previous_discount_rate, previous_selling_price }) => (
        <S.DiffCellWrapper>
          {value}
          {!previous_selling_price && !previous_discount_rate ? null : (
            <div>
              <S.PreviousValue>
                {formatToCurrency(
                  calculateNetPrice(previous_selling_price || selling_price, previous_discount_rate || discount_rate),
                )}
              </S.PreviousValue>
            </div>
          )}
        </S.DiffCellWrapper>
      ),
    },
    {
      path: 'quantity',
      text: 'Quantity',
      width: 70,
      render: (value, { previous_quantity }) => (
        <S.DiffCellWrapper>
          {value}
          {!previous_quantity ? null : (
            <div>
              <S.PreviousValueHeader>
                Previous
                <br />
                Quantity
              </S.PreviousValueHeader>
              <S.PreviousValue>{previous_quantity}</S.PreviousValue>
            </div>
          )}
        </S.DiffCellWrapper>
      ),
    },
    {
      id: 'total_value',
      text: 'Total Value',
      width: 150,
      render: (
        _,
        {
          selling_price,
          discount_rate,
          net_price,
          quantity,
          previous_selling_price,
          previous_quantity,
          previous_discount_rate,
        },
      ) => {
        const currentTotalValue = net_price * quantity;
        let originalTotalValue = currentTotalValue;

        const hasChange = previous_quantity || previous_selling_price || previous_discount_rate !== null;

        if (hasChange) {
          originalTotalValue =
            calculateNetPrice(previous_selling_price || selling_price, previous_discount_rate ?? discount_rate) *
            (previous_quantity || quantity);
        }

        return (
          <S.DiffCellWrapper>
            {formatToCurrency(currentTotalValue)}
            {!hasChange ? null : (
              <div>
                <S.PreviousValue>{formatToCurrency(originalTotalValue)}</S.PreviousValue>
              </div>
            )}
          </S.DiffCellWrapper>
        );
      },
    },
    {
      path: 'min_promo',
      text: 'Min Quantity',
      width: isFlaggingAllowed ? 100 : undefined,
    },
  ];

  if (isFlaggingAllowed) {
    const actionColumnKey = `actions-${uuid()}`;

    orderItemTableColumns.push({
      id: 'actions',
      key: actionColumnKey,
      text: 'Actions',
      render: (_, __, i) => {
        const { is_out_of_stock, stock_limit, price_change, discount_rate_change } = form.values.items[i] || {};

        return (
          <OrderItemFlagInputs
            index={i}
            values={{
              is_out_of_stock: is_out_of_stock ?? !!orderDetails.data?.items[i]?.is_out_of_stock,
              stock_limit: stock_limit || null,
              price_change: price_change || null,
              discount_rate_change: discount_rate_change || null,
            }}
            onChange={(index, name, value) => {
              form.setFieldValue(`items[${index}][${name}]`, value);
            }}
          />
        );
      },
    });
  }

  const getRowHighlight = (data: DataObject): ColorType | undefined => {
    const { is_out_of_stock, previous_selling_price, previous_quantity, previous_discount_rate } = data;

    if (is_out_of_stock) {
      return ColorType.DANGER;
    }

    if (previous_selling_price || previous_quantity || previous_discount_rate !== null) {
      return ColorType.INFO;
    }

    return undefined;
  };

  const isRowDisabled = (data: DataObject): boolean => data.is_out_of_stock && !isFlaggingAllowed;

  const renderActions = (): JSX.Element => (
    <Button color={ColorType.PRIMARY} disabled={!form.dirty} onClick={handleSubmit}>
      Submit Changes
    </Button>
  );

  const renderFlags = (order: Order): JSX.Element => {
    const {
      flagged_item_counts,
      cancellation_reason,
      is_delayed,
      is_delivery_doc_printed,
      is_undelivered,
      fulfillment_status,
      delay_reason,
      is_returned,
      return_type,
      is_quantity_void,
      status,
    } = order;
    const { limited_stock, price_changed, discount_rate_changed, stock_out } = flagged_item_counts || {};

    const returnType = !return_type
      ? ''
      : return_type === OrderReturnType.FULL
      ? 'Full Returned'
      : `Partial Returned${is_quantity_void ? ' (Quantity Void)' : ''}`;

    return (
      <Grid container direction="row" justify="flex-end" alignItems="flex-end">
        {!stock_out ? null : (
          <Badge light color={ColorType.DANGER}>
            {`${stock_out} ${pluralize('Item', stock_out)} Out of Stock`}
          </Badge>
        )}
        {!price_changed ? null : (
          <Badge light color={ColorType.INFO}>
            {`${price_changed} ${pluralize('Item', price_changed)} Price Changed`}
          </Badge>
        )}
        {!limited_stock ? null : (
          <Badge light color={ColorType.INFO}>
            {`${limited_stock} ${pluralize('Item', limited_stock)} Limited Stock`}
          </Badge>
        )}
        {!discount_rate_changed ? null : (
          <Badge light color={ColorType.INFO}>
            {`${discount_rate_changed} ${pluralize('Item', discount_rate_changed)} Discount Rate Changed`}
          </Badge>
        )}
        {!is_undelivered ? null : (
          <Badge light color={ColorType.WARNING}>
            Undelivered
          </Badge>
        )}
        {status === OrderStatus.CANCELLED && !is_returned ? (
          <Badge light color={ColorType.GRAY}>
            {`Cancelled - ${cancellation_reason}`}
          </Badge>
        ) : null}
        {!is_delayed ? null : (
          <Badge light color={ColorType.GRAY}>
            {`Delayed - ${delay_reason}`}
          </Badge>
        )}
        {!is_delivery_doc_printed ? null : (
          <Badge light color={ColorType.GRAY}>
            Delivery Doc Printed
          </Badge>
        )}
        {status === OrderStatus.CANCELLED && is_returned ? (
          <Badge light color={ColorType.GRAY}>
            {`Cancelled - ${returnType}`}
          </Badge>
        ) : null}
        {fulfillment_status === OrderFulfillmentStatus.DELIVERED && is_returned ? (
          <Badge light color={ColorType.GRAY}>
            {`Delivered - ${returnType}`}
          </Badge>
        ) : null}
        {fulfillment_status === OrderFulfillmentStatus.FULFILLED && is_returned ? (
          <Badge light color={ColorType.GRAY}>
            {`Fulfilled - ${returnType}`}
          </Badge>
        ) : null}
      </Grid>
    );
  };

  const orderDateEditable =
    [UserRoles.TRANSACTION_MANAGER].includes(user.userType) &&
    [OrderStatus.PENDING, OrderStatus.PROCESSING].includes(orderDetails.data?.status as OrderStatus);

  const renderContent = (): JSX.Element | null => {
    if (!orderDetails.data) {
      return null;
    }

    const { data } = orderDetails;
    const {
      po_number,
      ordered_at,
      status_history,
      invoice, 
      payment_method, 
      logistics_delivery_status,
    } = data;

    const { labelElement, labelType } = getOrderElapsedTimeElement(
      statusFromUrl as string,
      status_history,
      ordered_at,
      payment_method,
      invoice,
    );

    const actions = !isFlaggingAllowed
      ? undefined
      : {
          items: bulkActionItems,
          onSelect: handleBulkActionSelect,
        };

    const proofs = [invoice?.invoice_photo, invoice?.proof_photo, invoice?.tax_photo];

    return (
      <S.Wrapper>
        <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
          <Grid item xs={6}>
            <BasicOrderDetails poNumber={po_number} orderDate={ordered_at} isOrderDateEditable={orderDateEditable} awbNumber={logistics_delivery_status?.delivery_tracking_code} />
            <InvoicePhotos proofs={proofs} />
          </Grid>
          <Grid item>
            <Grid container direction="column" justify="center" alignItems="flex-end" spacing={1}>
              <Grid item>
                <BadgeStyles.Primary color={labelType} style={{ marginRight: 0 }}>
                  {labelElement}
                </BadgeStyles.Primary>
              </Grid>
              <Grid item>{renderFlags(data)}</Grid>
            </Grid>
          </Grid>
        </Grid>
        <CardTable
          config={{
            actions,
            basic: true,
            isPaginated: false,
            columns: orderItemTableColumns,
            key: 'order-details',
            scroll: 500,
            selectable: isFlaggingAllowed,
          }}
          data={orderDetails.data.items}
          getRowHighlight={getRowHighlight}
          isRowDisabled={isRowDisabled}
        />
      </S.Wrapper>
    );
  };

  const title = !orderDetails.data ? '' : orderDetails.data.pharmacy.name;

  return (
    <>
      <Dialog
        fullWidth
        open={!orderItemFlagSummary && !split.poNumber && !orderItemReassign.selectedDistributorId}
        maxWidth="lg"
        loading={!orderDetails.data}
        onClose={handleClose}
        renderActions={isFlaggingAllowed ? renderActions : undefined}
        renderContent={renderContent}
        style={isFlaggingAllowed ? { maxWidth: 1480 } : undefined}
        title={title}
      />
      <OrderItemFlagConfirmationModal />
      <SplitOrderItemModal />
      <OrderItemReassignModal />
    </>
  );
};
