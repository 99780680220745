import { PaymentMethod } from 'common/constants';

export enum OrganizationStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export interface Organization {
  id: number;
  name: string;
  created_at: Date;
  status?: OrganizationStatus;
  OrganizationPaymentMethod?: {
    id: number;
    payment_method: PaymentMethod;
  };
}

export interface Options {
  label: string;
  name: string;
  value: number;
  status?: OrganizationStatus;
  paymentMethod?: PaymentMethod;
}
