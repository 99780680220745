import { CSVUploadResult } from 'common/components/CsvUpload/interfaces/csv-upload.interface';
import { IReturn } from 'common/interfaces';

import { ISearchable, ListResponse, ISearchParam } from '../../interfaces';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';

export class SwipeRxPtReturnManagement implements ISearchable<IReturn> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.RETURN_MANAGEMENT;
  }

  async getByID(id: number): Promise<{ data: any }> {
    return this.base.get(`${this.resource}/${id}`);
  }

  async getByInvoiceID(invoiceID: number): Promise<{ data: IReturn[] }> {
    return this.base.get(`${this.resource}/invoice/${invoiceID}`);
  }

  async updateTaxInformation(
    id: number,
    payload: { tax_invoice_no: string; tax_invoice_date: string },
  ): Promise<{ data: any }> {
    return this.base.patch(`${this.resource}/${id}/invoice`, payload);
  }

  async updateCreditMemo(id: number, creditMemoPayload: { credit_memo_no: string }): Promise<{ data: any }> {
    const creditMemoNumber = creditMemoPayload.credit_memo_no;
    const payload = !creditMemoNumber || creditMemoNumber.trim() === '' ? { credit_memo_no: null } : creditMemoPayload;
    return this.base.patch(`${this.resource}/${id}/credit-memo`, payload);
  }

  async removeById(id: number): Promise<void> {
    this.base.delete(`${this.resource}/${id}`);
  }

  async removeByIds(ids: number[]): Promise<void> {
    this.base.patch(`${this.resource}/bulk-delete`, { return_ids: ids });
  }

  async list(params: any): Promise<ListResponse<IReturn>> {
    return this.base.get(this.resource, { ...params });
  }

  async search(params: ISearchParam, resourceOverride?: string): Promise<ListResponse<IReturn>> {
    return this.base.get(resourceOverride || this.resource, { ...params });
  }

  async uploadCsv(
    file: File,
  ): Promise<{
    data: CSVUploadResult;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );
    return this.base.post(`/credit-memo/upload`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async uploadAttachments(files: Array<File>): Promise<any> {
    const form = new FormData();

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      form.append('files[]', file, file.name);
    }

    return this.base.post(`/credit-memo/attachment/upload`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async checkDuplicates(filenames: Array<string>): Promise<any> {
    return this.base.post(`/credit-memo/attachment/checkDuplicates`, { filenames });
  }

  async uploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`/credit-memo/upload/history`, {
      page,
      page_size,
    });
  }

  async attachmentUploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`/credit-memo/attachment/history`, {
      page,
      page_size,
    });
  }
}
