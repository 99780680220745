import { ButtonToggleFilterData } from 'common/components/ButtonToggleFilter';
import { useUpdateQueryParams } from 'hooks';

type UpdateStatusFilter = (status?: string) => void;
type UpdatePrekursorFilter = (isPrekursor: PrekursorFilterValues) => void;

type UseTransactionStatusFilter = () => {
  statusFilterItems: ButtonToggleFilterData[];
  prekursorFilterItems: ButtonToggleFilterData[];
  selectedPrekursorFilter: string;
  updateStatusFilter: UpdateStatusFilter;
  updatePrekursorFilter: UpdatePrekursorFilter;
};

export enum PrekursorFilterValues {
  DOCUMENT_ALL = 'all',
  DOCUMENT_PREKURSOR = 'prekursor',
  PREKURSOR_PAGE = 'prekursor-page',
}

export const useTransactionStatusFilter: UseTransactionStatusFilter = () => {
  const statusFilterItems: ButtonToggleFilterData[] = [
    {
      text: 'All Orders',
      value: 'all',
    },
    {
      text: 'New Orders',
      value: 'pending',
    },
    {
      text: 'In Progress',
      value: 'processing',
    },
    {
      text: 'Accepted',
      value: 'accepted',
    },
    {
      text: 'Dispatched',
      value: 'dispatched',
    },
    {
      text: 'Delivered',
      value: 'delivered',
    },
    {
      text: 'Fulfilled',
      value: 'fulfilled',
    },
    {
      text: 'Returned',
      value: 'returned',
    },
    {
      text: 'Delayed',
      value: 'delayed',
    },
    {
      text: 'Cancelled',
      value: 'cancelled',
    },
  ];

  const prekursorFilterItems: ButtonToggleFilterData[] = [
    {
      text: 'All Orders',
      value: 'all',
    },
    {
      text: 'Prekursor Orders',
      value: 'prekursor',
    },
  ];

  const { updateQueryParams, queryParams } = useUpdateQueryParams();
  const { page_size } = queryParams;
  const defaultPageSize = page_size ? +page_size : 50;
  const defaultPage = 1;
  const selectedPrekursorFilter = queryParams.only_document_pending
  ? PrekursorFilterValues.DOCUMENT_PREKURSOR
  : PrekursorFilterValues.DOCUMENT_ALL;

  const updateStatusFilter: UpdateStatusFilter = (status?: string): void => {
    updateQueryParams({ page: defaultPage, status, page_size: defaultPageSize } as Record<string, any>);
  };

  const updatePrekursorFilter: UpdatePrekursorFilter = (prekursorValue: PrekursorFilterValues): void => {
    if (prekursorValue === PrekursorFilterValues.DOCUMENT_PREKURSOR) {
      delete queryParams.precursor_page;
      updateQueryParams({ ...queryParams, only_document_pending: 'true', precursor_status: 'all' });
    } else if (prekursorValue === PrekursorFilterValues.DOCUMENT_ALL) {
      delete queryParams.only_document_pending;
      delete queryParams.precursor_status;
      updateQueryParams(queryParams);
    } else if (prekursorValue === PrekursorFilterValues.PREKURSOR_PAGE) {
      delete queryParams.only_document_pending;
      delete queryParams.precursor_status;
      delete queryParams.payment_method;
      delete queryParams.status;
      updateQueryParams({ ...queryParams, precursor_page: PrekursorFilterValues.PREKURSOR_PAGE })
    }
  }

  return {
    statusFilterItems,
    prekursorFilterItems,
    selectedPrekursorFilter,
    updateStatusFilter,
    updatePrekursorFilter,
  };
};
