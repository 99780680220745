import { FinanceAction } from 'types';

type ActionItem = FinanceAction['actionItem'];

const EXPORT: ActionItem = {
  name: 'export',
  text: 'Export'
};

const APPROVE: ActionItem = {
  name: 'approve',
  text: 'Approve'
};

const MARK_AS_REIMBURSED: ActionItem = {
  name: 'mark_as_reimbursed',
  text: 'Mark as Reimbursed'
};

const MARK_AS_DISBURSED: ActionItem = {
  name: 'mark_as_disbursed',
  text: 'Mark as Disbursed'
};

const HOLD_TO_APPROVE: ActionItem = {
  name: 'hold_to_approve',
  text: 'Hold to Approve'
};

const HOLD_TO_DISBURSE: ActionItem = {
  name: 'hold_to_disburse',
  text: 'Hold to Disburse'
};

export const FINANCE_ACTIONS = {
  EXPORT,
  APPROVE,
  MARK_AS_REIMBURSED,
  MARK_AS_DISBURSED,
  HOLD_TO_APPROVE,
  HOLD_TO_DISBURSE
}