import React from 'react';
import { lighten } from 'polished';
import { S } from './Button.styles';
interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  color: string;
  backgroundColor: string;
  style?: any;
}

export const CloseButton: React.FC<Props> = ({
  onClick,
  onKeyDown,
  color,
  backgroundColor,
  style,
}) => (
  <S.RemoveButton
    type="button"
    className="remove-icon"
    onClick={onClick}
    onKeyDown={onKeyDown}
    style={style}
  >
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: lighten(0.3, backgroundColor) }}
    >
      {' '}
      <circle cx="9" cy="9" r="9" fill={backgroundColor} />
      <path
        d="M12.9375 5.85562L12.1444 5.0625L9 8.20688L5.85563 5.0625L5.0625 5.85562L8.20688 9L5.0625 12.1444L5.85563 12.9375L9 9.79313L12.1444 12.9375L12.9375 12.1444L9.79313 9L12.9375 5.85562Z"
        fill={color}
      />
    </svg>
  </S.RemoveButton>
);
