import { LoyaltyMissionSummary, LoyaltyState } from "./interfaces";

export const blankLoyaltyMissionSummary: LoyaltyMissionSummary = {
  id: 0,
  name: '',
  code: '',
  description: '',
  termsAndCondition: '',
  status: 'inactive',
  startDate: null,
  endDate: null,
  excludeMarketingIds: [],
};



export const LOYALTY_INITIAL_STATE: LoyaltyState = {
  loading: false,
  addLoyaltyMission: null,
  isEditMode: false,
  imageIcon: undefined,
  loyaltyMissionSummary: blankLoyaltyMissionSummary,
  loyaltyConfig:{loyaltyMissionMaxBackDate:''},
};
