export const isStatusAllNotFiltered = (params: Record<string, any>): boolean => {
  const isSearchFiltered =
    !!params.distributor_names ||
    !!params.pharmacy_names ||
    !!params.po_numbers ||
    !!params.invoice_numbers ||
    !!params.exclude_distributor_names ||
    !!params.exclude_pharmacy_names ||
    !!params.exclude_po_numbers ||
    !!params.exclude_invoice_numbers;

  const isFiltered =
    !!params.transaction_date_from ||
    !!params.transaction_date_to ||
    !!params.approval_date_from ||
    !!params.approval_date_to ||
    !!params.transaction_status;

  return params.status === 'all' && !isFiltered && !isSearchFiltered;
};
