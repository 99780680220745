import styled from 'styled-components';

interface Props {
  active: boolean;
}

const getBackgroundColor = (props: Props): string => {
  if (props.active) {
    return '#FFFFFF';
  }
  return '#F8F5F5';
};

const getBorder = (props: Props): string => {
  if (props.active) {
    return 'none';
  }
  return '1px dashed #A8a8a8';
};

const getFontColor = (props: Props): string => {
  if (props.active) {
    return 'black';
  }
  return 'gray';
};

const DropzoneContainer = styled.div<Props>`
  height: 50px;
  display: flex;
  padding-left: 10px;
  background: ${getBackgroundColor};
  border: ${getBorder};
  box-sizing: border-box;
  border-radius: 3px;
  color: ${getFontColor};

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const FormTitle = styled.span`
  && {
    padding-top: 12px;
    padding-bottom: 9px;
    color: gray;
    font-size: 14px;
    color: #000000
  }
`;

const UploadArea = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

export const S = {
  DropzoneContainer,
  FormTitle,
  UploadArea
};
