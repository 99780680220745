import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Redirect } from 'react-router';
import history from './common/history';
import { getMarketPath, IMarketParams } from './utils/get-router-path';
import { DEFAULT_MARKET_ID } from './common/services/constants';

type UnauthorizedRedirectProps = {};

const UnauthorizedRedirect: React.FC<UnauthorizedRedirectProps> = () => {
  const { params } = useRouteMatch<IMarketParams>();
  if (!params.marketId || localStorage.getItem('PT-ROLE-DATA')) {
    return null;
  }

  const prefix = getMarketPath(params.marketId || DEFAULT_MARKET_ID);
  return (
    <Redirect
      to={{
        pathname: `${prefix}/login`,
        state: { referrer: history.location.pathname },
      }}
    />
  );
};

export default UnauthorizedRedirect;
