import {
  ILogisticsDistributorCalendar as ILogisticsCalendar,
  IMutableLogisticsDistributorCalendar as IMutableLogisticsCalendar,
} from 'common/interfaces/Logistics.interface';
import { CSVUploadResult } from 'common/components/CsvUpload/interfaces/csv-upload.interface';
import { ISearchable, ListResponse, ISearchParam } from '../../interfaces';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { IMutable } from '../../interfaces/IMutable.interface';

export class SwipeRxPtLogisticsCalendar
  implements ISearchable<ILogisticsCalendar>, IMutable<IMutableLogisticsCalendar> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.LOGISTICS_DISTRIBUTOR_CALENDAR;
  }

  async list(params: any): Promise<ListResponse<ILogisticsCalendar>> {
    return this.base.get(this.resource, { ...params });
  }

  async createOrUpdate(data: IMutableLogisticsCalendar): Promise<any> {
    return this.base.post(`${this.resource}`, data);
  }

  async search(params: ISearchParam, resourceOverride?: string): Promise<ListResponse<ILogisticsCalendar>> {
    return this.base.get(resourceOverride || this.resource, { ...params });
  }

  async uploadCsv(
    file: File,
  ): Promise<{
    data: CSVUploadResult;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );
    return this.base.post(`${this.resource}/events/upload`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async uploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`${this.resource}/events/upload/history`, {
      page,
      page_size,
    });
  }

  async delete(id: number): Promise<any> {
    return this.base.delete(`${this.resource}/${id}`);
  }

  // FIXME: clean later

  // async retrieve(id: number): Promise<Product> {
  //   const { data } = await this.base.get(`${this.resource}/${id}`);

  //   return data;
  // }

  // async createProduct(product: ProductCreate): Promise<Product> {
  //   const blob = new Blob([JSON.stringify(product)], {
  //     type: 'application/json',
  //   });

  //   const form = new FormData();
  //   form.append('file', blob);

  //   const { data } = await this.base.post(`${this.resource}`, form, {
  //     headers: {
  //       'content-type': 'multipart/form-data;',
  //     },
  //   });
  //   return data;
  // }

  // async patchProduct(id: number, product: ProductPatch): Promise<Product | {}> {
  //   const blob = new Blob([JSON.stringify(product)], {
  //     type: 'application/json',
  //   });

  //   const form = new FormData();
  //   form.append('file', blob);

  //   const { data } = await this.base.patch(`${this.resource}/${id}`, form, {
  //     headers: {
  //       'content-type': 'multipart/form-data;',
  //     },
  //   });
  //   return data;
  // }

  // async search(query: {
  //   term?: string;
  //   fields?: string[];
  //   page: number;
  //   page_size: number;
  // }): Promise<{
  //   data: Product[];
  //   meta: ListMeta;
  // }> {
  //   return this.base.get(SwipeRxPtV3Resources.PRODUCTS, {
  //     ...query,
  //   });
  // }

  // async bulkUploadImage(
  //   files: File[],
  // ): Promise<{
  //   data: Product[];
  //   meta: ListMeta;
  // }> {
  //   const form = new FormData();

  //   for (let i = 0; i < files.length; i += 1) {
  //     const file = files[i];
  //     form.append('files[]', file, file.name);
  //   }

  //   return this.base.post(`${this.resource}/images`, form, {
  //     headers: {
  //       'content-type': 'multipart/form-data;',
  //     },
  //   });
  // }

  // async listProductDistributors(id: number, params?: ProductDistributorListParams): Promise<ProductDistributorList> {
  //   const result = await this.base.get(`${this.resource}/${id}/distributor-mapping`, params);

  //   return result;
  // }

  // async validateSku(params: { sku_code: string; product_id?: number }): Promise<any> {
  //   return this.base.get(`${this.resource}/validate-sku`, params);
  // }
}
