import { useState } from 'react';

import { ValueType, OptionTypeBase } from 'react-select';

interface UseOrderModalReason {
  selectedReason: ValueType<OptionTypeBase> | null;
  setSelectedReason: (reason: ValueType<OptionTypeBase> | null) => void;
  clearReason: () => void;
}

export const useOrderModalReason = (): UseOrderModalReason => {
  const [selectedReason, setSelectedReason] = useState<ValueType<OptionTypeBase> | null>(null);

  const clearReason = (): void => setSelectedReason(null);

  return {
    selectedReason,
    setSelectedReason,
    clearReason,
  };
};
