import { GetDataTablePageParams } from '../interfaces';

export const getPage = ({
  newPage,
  newPageSize,
  oldPage,
  oldPageCount,
  oldTotalCount,
}: GetDataTablePageParams): number => {
  const pageCount = !newPageSize ? oldPageCount : Math.ceil(oldTotalCount / newPageSize);

  const selectedPage = newPage || oldPage;

  const page = pageCount && pageCount < selectedPage ? pageCount : selectedPage;

  return page;
};
