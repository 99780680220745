import React from 'reactn';

import { Page } from '@react-pdf/renderer';

import { calculateSLA } from 'pages/PrintPage/utils/calculateSLA';
import { Order } from 'common/reducers';

import { Header } from './header';
import { Content } from './content';
import { Footer } from './footer';
import { DeliveryProofV2Props } from '../types';
import { DeliveryAddresses } from './delivery-addresses';
import { styles } from './styles';

export interface InvoiceProps extends DeliveryProofV2Props {
  subtitle: string;
  order: Order;
}

export const Invoice: React.FC<InvoiceProps> = (props) => {
  const { subtitle, order, config, match } = props;
  const {
    params: { marketId },
  } = match;

  const SLA = calculateSLA(order);

  return (
    <Page size="A4" style={styles.root}>
      <Header subtitle={subtitle} slaTag={marketId === 'th' ? null : SLA?.SLATag} />
      <DeliveryAddresses config={config} order={order} marketId={marketId} />
      <Content {...props} />
      <Footer {...props} />
      {/* <Text
        render={({ pageNumber, totalPages }) => {
          if (totalPages > 3) {
            const pagePerSection = (totalPages - 1) / 2;
            const currentPage = pageNumber % pagePerSection === 0 ? pagePerSection : pageNumber % pagePerSection;

            return `${currentPage} / ${pagePerSection}`;
          }
          return null;
        }}
        fixed
        style={styles.pageNumber}
      /> */}
    </Page>
  );
};
