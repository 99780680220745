import qs from 'query-string';
import { b64toBlob } from 'common/utils/converters';
import { CSVUploadResult } from 'common/components/CsvUpload/interfaces/csv-upload.interface';

import { LoyaltySpecialCashbackUploadType } from 'pages/Loyalty/constants/loyalty-special-cashback-csv-upload-constants';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';

import { IAddLoyaltyMissionLevel, IUpdateLoyaltyMissionLevel, IUpdateLoyaltyMissionSegment, LoyaltyMissionData } from './interfaces';

export class SwipeRxPtLoyalty {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.LOYALTY;
  }

  async getLoyaltyMissions(page: number): Promise<any> {
    return this.base.get(`${this.resource}?page=${page}`);
  }

  async getLoyaltyMissionConfig( key: string,): Promise<any> {
    const params = {
      key,
    };
    const query = qs.stringify(params, { skipNull: true });
    return this.base.get(`loyalty/loyalty_config/${key}`);
  }

  async getLoyaltyMissionPharmacyList(
    missionId: number,
    meta: any,
  ): Promise<any> {
    const { page, pageSize, sortBy, orderBy } = meta;
    const params = {
      page,
      page_size: pageSize,
      order_by: orderBy,
      sort_by: sortBy,
    };
    const query = qs.stringify(params, { skipNull: true });
    return this.base.get(`${this.resource}/${missionId}/pharmacies?${query}`);
  }

  async getLoyaltyMissionSummary(loyaltyMissionId: number): Promise<{ data: LoyaltyMissionData, error: string }> {
    return this.base.get(`${this.resource}/${loyaltyMissionId}`);
  }

  async createLoyaltyMission(params: {
    code: string;
    name: string;
    description: string;
    start_date?: Date | string;
    end_date?: Date | string;
    relative_start_days?: number;
    relative_start_days_basis?: string;
    relative_end_days?: number;
    relative_end_days_basis?: string;
    status: string;
    tnc: string;
    segmentsIds: number[];
  }): Promise<any> {
    return this.base.post(`${this.resource}`, params);
  }

  async updateLoyaltyMission(
    missionId: number,
    params: {
      code?: string;
      name?: string;
      description?: string;
      start_date?: string;
      end_date?: string;
      relative_start_days?: number;
      relative_start_days_basis?: string;
      relative_end_days?: number;
      relative_end_days_basis?: string;
      status?: string;
      tnc?: string;
      is_show_homepage?: boolean;
  }): Promise<any> {
    return this.base.patch(`${this.resource}/${missionId}`, params);
  }

  async activateLoyaltyMission(missionId: number): Promise<any> {
    return this.base.patch(`${this.resource}/${missionId}/activate`, {});
  }

  async deleteLoyaltyMission(loyaltyMissionId: number): Promise<any> {
    return this.base.delete(`${this.resource}/${loyaltyMissionId}`);
  }

  async cloneLoyaltyMission(loyaltyMissionId: number): Promise<any> {
    return this.base.postV2(`${this.resource}/${loyaltyMissionId}/clone`, '');
  }

  async uploadLoyaltyMissionIcon(
    loyaltyMissionId: number,
    base64Image: string,
    filename: string,
    isBanner: boolean,
  ): Promise<any> {
    const contentType = base64Image.split(';')[0].split(':')[1];
    const base64 = base64Image.split(',')[1];
    const form = new FormData();
    form.append('file', b64toBlob({base64Data: base64, contentType }), filename);
    const config = {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    }

    const query = isBanner ? '?image_type=image_banner' : '';
    return this.base.post(`${this.resource}/${loyaltyMissionId}/image${query}`, form, config);
  }

  async deleteLoyaltyMissionIcon(loyaltyMissionId: number): Promise<any> {
    return this.base.delete(`${this.resource}/${loyaltyMissionId}/image`);
  }

  async updateLoyaltyMissionSegment(loyaltyMissionId: number, params: IUpdateLoyaltyMissionSegment): Promise<any> {
    return this.base.post(`${this.resource}/${loyaltyMissionId}/segments`, params);
  }

  async getLoyaltyMissionLevels(loyaltyMissionId: number): Promise<any> {
    return this.base.get(`${this.resource}/${loyaltyMissionId}/levels`);
  }

  async getLoyaltyMissionLevel(loyaltyMissionId: number, levelId: number): Promise<any> {
    return this.base.get(`${this.resource}/${loyaltyMissionId}/levels/${levelId}`);
  }

  async getLoyaltyMissionActivityTypes(): Promise<any> {
    return this.base.get(`${this.resource}/activity-types`);
  }

  async validateLoyaltyMissionReward(campaignId: string): Promise<any> {
    return this.base.get(`${this.resource}/rewards/${campaignId}`);
  }

  async addLoyaltyMissionLevel(params: IAddLoyaltyMissionLevel): Promise<any> {
    return this.base.post(`${this.resource}/levels`, params);
  }

  async updateLoyaltyMissionLevel(levelId: number, params: IUpdateLoyaltyMissionLevel): Promise<any> {
    return this.base.patch(`${this.resource}/levels/${levelId}`, params);
  }

  async uploadCsv(
    file: File,
  ): Promise<{
    data: CSVUploadResult;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );
    return this.base.post(`${this.resource}/upload`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async uploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get(`${this.resource}/upload/history`, {
      page,
      page_size,
    });
  }

  async addLoyaltyMissionExcludeMarketing(missionId: number, marketingId: number): Promise<LoyaltyMissionData> {
    return this.base.post(`${this.resource}/${missionId}/exclude-marketing`, { marketing_ids: [marketingId] });
  }

  async deleteLoyaltyMissionExcludeMarketing(missionId: number, marketingId: number): Promise<LoyaltyMissionData> {
    return this.base.delete(`${this.resource}/${missionId}/exclude-marketing/${marketingId}`);
  }

  async loyaltySpecialCashbackUploadHistory(page: number, page_size: number): Promise<any> {
    return this.base.get('loyalty/special-tier-upload', { page, page_size });
  }

  async loyaltySpecialCashbackUploadCsv(file: File, uploadType: LoyaltySpecialCashbackUploadType): Promise<{
    data: CSVUploadResult;
  }> {
    const form = new FormData();
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],

        { type: 'text/csv' },
      ),
      file.name,
    );
    return this.base.post(`loyalty/special-tier-upload/${uploadType}`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }
}
