import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import { ActionMenuItems, ActionMenuSelectHander } from './types';
import { S } from './ActionMenu.styles';

type Props = Readonly<{
  disabled?: boolean;
  items: ActionMenuItems;
  onSelect?: ActionMenuSelectHander;
}>;

export const ActionMenu: React.FC<Props> = ({
  disabled,
  items,
  onSelect,
  ...props
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    if (onSelect) {
      onSelect(event.target.value as string);
    }
  };

  const renderItems = (): JSX.Element[] =>
    items.map((item) => {
      if (!item) {
        return <Divider />;
      }

      const { color, name, text } = item;

      return (
        <MenuItem key={name} value={name}>
          <S.ItemText color={color}>{text}</S.ItemText>
        </MenuItem>
      );
    });

  return (
    <S.Wrapper
      {...props}
      displayEmpty
      fullWidth
      disabled={disabled}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        getContentAnchorEl: null,
      }}
      renderValue={() => 'Select an Action'}
      variant="outlined"
      value=""
      onChange={handleChange}
    >
      {renderItems()}
    </S.Wrapper>
  );
};
