import React from 'react';

import { DataObject } from 'types';

import { CardTableBodyCell } from '../../interfaces';
import {
  CardTableGetRowHighlight,
  CardTableIsRowDisabled,
  CardTableRowFooter,
  CardTableSelectHandler,
  CardTableSelectionMap,
} from '../../types';
import { CardTableMessage } from '../Message';
import { CardTableRow } from '../Row';

interface Props {
  data: DataObject[];
  error?: string | null;
  fields: CardTableBodyCell[];
  getRowHighlight?: CardTableGetRowHighlight;
  isRowDisabled?: CardTableIsRowDisabled;
  loading?: boolean;
  rowFooter?: CardTableRowFooter;
  selectable?: boolean;
  selectionMap?: CardTableSelectionMap;
  onSelect?: CardTableSelectHandler;
}

export const CardTableBody: React.FC<Props> = ({
  data,
  error,
  fields,
  getRowHighlight,
  isRowDisabled,
  loading,
  rowFooter,
  selectable,
  onSelect,
  selectionMap = {},
}) => {
  const isEmpty = !data.length;

  if (loading) {
    return <CardTableMessage text="Loading..." />;
  }

  if (error) {
    return <CardTableMessage altText={error} text="Something went wrong while fetching the list." />;
  }

  if (isEmpty) {
    return <CardTableMessage text="The list is empty." />;
  }

  return (
    <>
      {data.map((item, i) => (
        <CardTableRow
          data-testid={`CardTableRow-index-${i}`}
          key={item.id}
          index={i}
          data={item}
          disabled={isRowDisabled ? isRowDisabled(item) : false}
          fields={fields}
          footer={rowFooter}
          highlight={getRowHighlight ? getRowHighlight(item) : undefined}
          isLast={i === data.length - 1}
          selectable={selectable}
          selected={selectionMap[item.id]}
          onSelect={onSelect}
        />
      ))}
    </>
  );
};
