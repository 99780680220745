import React from 'react';
import { Button, Grid, GridProps } from '@material-ui/core';
import { S } from './field-file-upload.styles';

interface Props {
  label?: string;
  active: boolean;
  onRemove: () => void;
  overrideProps?: GridProps;
}

export const FieldFileUploadControls: React.FC<Props> = ({ label, active, onRemove, overrideProps = {} }) => (
  <Grid container justify="space-between" alignContent="center" alignItems="center" {...overrideProps}>
    {label && <S.FormTitle>{label}</S.FormTitle>}
    {active ? (
      <Button onClick={onRemove} data-testid="remove-btn">
        <span style={{ color: 'red' }}>remove</span>
      </Button>
    ) : null}
  </Grid>
);
