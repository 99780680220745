import styled from 'styled-components';

import { DataGrid as BaseDataGrid } from 'common/components/DataGrid';
import TextField from '@material-ui/core/TextField';
import BaseIconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { DateTimePicker as BaseDateTimePicker } from '@material-ui/pickers';

import { Dialog as BaseDialog, Button as BaseButton } from 'common/components';

import {
  TextProps,
  LabelProps,
  DialogProps,
  ButtonProps,
} from './OrderInvoiceDialog.types';

export const Button = styled(BaseButton)<ButtonProps>`
  min-width: ${(props) => (props && props.width ? props.width : 150)}px;
  ${(props) =>
    props && props.danger && props.variant === 'text'
      ? 'color: #FF5050 !important; background: rgba(0,0,0,0.05) !important;'
      : null}
`;

export const Text = styled(Typography).attrs({ variant: 'body2' })`
  color: ${(props: TextProps) => (props && props.shade ? props.shade : '#333')};
  font-weight: ${(props: TextProps) =>
    props && props.weight ? props.weight : 'bolder'};
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Label = styled(Typography).attrs({ variant: 'body2' })`
  color: ${(props: LabelProps) =>
    props && props.shade ? props.shade : '#333'};
  font-weight: ${(props: LabelProps) =>
    props && props.weight ? props.weight : 'bolder'};
  margin-top: ${(props: LabelProps) =>
    props && props.marginTop ? props.marginTop : 10}px;
  margin-bottom: ${(props: LabelProps) =>
    props && props.marginBottom ? props.marginBottom : 0}px;
`;

export const MaxCharLabel = styled(Typography).attrs({ variant: 'caption' })`
  display: block;
  color: ${(props: LabelProps) =>
    props && props.shade ? props.shade : '#777'};
  margin-top: ${(props: LabelProps) =>
    props && props.marginTop ? props.marginTop : 5}px;
  margin-bottom: ${(props: LabelProps) =>
    props && props.marginBottom ? props.marginBottom : 0}px;
  font-size: 8pt;
`;

export const Dialog = styled(BaseDialog)<DialogProps>`
  && {
    .MuiDialog-paperWidthSm {
      width: 837px;
      max-width: 100vw;
      ${(props) =>
        props && props.invisible
          ? 'width: 418px !important; max-width: 50vw !important;'
          : null}
    }

    #dialog-title-container {
    }

    #dialog-title {
      font-size: 16px !important;
    }

    #dialog-close-button {
      background: rgba(0, 0, 0, 0);
      color: rgb(255, 255, 255);
      transform: translateY(-5px);
    }
  }
`;

export const IconButton = styled(BaseIconButton)`
  height: 12px;
  width: 12px;
`;

export const DataGridWrapper = styled.div`
  height: 400px;
  width: 100%;
  margin-top: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const OutlinedInput = styled(TextField).attrs({ variant: 'outlined' })`
  width: 100%;
  max-width: 230px;
  margin-top: 5px;

  && {
    .MuiOutlinedInput-input {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 0.875rem;
      color: #333;
    }

    .MuiInputBase-root {
      border-radius: 8px;
      background: ${(props) =>
        props.disabled ? '#DADADA' : 'rgb(0, 0, 0, 0)'};
    }

    .MuiOutlinedInput-multiline {
      padding: 0px;
    }
  }
`;

export const DataGrid = styled(BaseDataGrid).attrs({
  columnBuffer: 0,
  pagepageSize: 10,
  hideFooter: true,
  disableExtendRowFullWidth: true,
  rowHeight: 45,
})`
  margin-top: 10;
  border: 0px;

  && {
    .MuiDataGrid-columnSeparator {
      display: none;
    }

    .MuiDataGrid-colCellTitle {
      font-weight: bolder;
    }

    .MuiDataGrid-columnsContainer {
      border-bottom: 1px solid #828282;
    }

    .MuiDataGrid-withBorder {
      border-right: 0px solid rgba(0, 0, 0, 0);
    }
  }
`;

export const TitleField = styled(TextField).attrs({})`
  && {
    .MuiInputBase-input {
      font-size: 24px;
      color: #000;
      font-weight: bold;
    }
    .MuiInput-underline.Mui-disabled:before {
      border-bottom-style: hidden !important;
    }
  }
`;

export const DateTimePicker = styled(BaseDateTimePicker).attrs({
  autoOk: true,
  ampm: false,
})`
  display: none;
`;

export const PaginationGroup = styled(ButtonGroup).attrs({ color: 'primary' })`
  margin-bottom: 0;
  margin-top: 10px;
`;

export const PaginationItemNumber = styled(Typography)`
  font-size: 12px;
  margin-top: 5px;
  color: #717786;
`;

export const PaginationPageNumber = styled.div`
  font-size: 12px;
  margin-top: 5px;
  font-family: Nunito Sans, sans-serif;
  text-align: center;

  && {
    .current-page {
      font-weight: bold;
    }
  }
`;

export const TotalPrice = styled(Typography)`
  font-size: 20px;
  font-weight: 600;

  && {
    .total-price-currency {
      color: #40ae96;
    }
  }
`;

export const ChangePageButton = styled(BaseIconButton).attrs({
  disableRipple: true,
  disableFocusRipple: true,
})`
  color: gray;
  margin-top: -12px;
`;

export const UneditableWrapper = styled.div`
  margin-top: 8px;
  width: 50%;
`;

export const EditableWrapper = styled.div`
  margin-top: 8px;
  width: 100%;
`;

export const RightSection = styled(Grid).attrs({
  item: true,
  xs: 4,
  spacing: 0,
})`
  padding-left: 10px;
  padding-right: 40px;
  margin-top: 10px;
`;

export default {
  Button,
  DataGrid,
  DataGridWrapper,
  Label,
  MaxCharLabel,
  OutlinedInput,
  Text,
  IconButton,
  TitleField,
  DateTimePicker,
  PaginationGroup,
  PaginationPageNumber,
  PaginationItemNumber,
  TotalPrice,
  ChangePageButton,
  UneditableWrapper,
  EditableWrapper,
  RightSection,
};
