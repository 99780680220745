import { ColorType, TransactionActionButton } from 'types';

type ActionButton = TransactionActionButton['actionButton'];

const UPLOAD_TRACKING_NUMBERS: ActionButton = {
  name: 'upload_delivery_tracking_number',
  text: 'Upload Tracking Numbers',
  color: ColorType.PRIMARY
};

export const TRANSACTION_BUTTONS = {
  UPLOAD_TRACKING_NUMBERS,
};
