/* eslint-disable no-param-reassign */

import { Distributor } from 'common/models/distributor.model';
import { ListMeta } from '../..';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { OrganizationParams } from '../organizations/interfaces';

export class SwipeRxPtDistributors {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.DISTRIBUTORS;
  }

  async retrieve(id: number, params?: Omit<OrganizationParams, 'page' | 'page_size'>): Promise<Distributor> {
    const { data } = await this.base.get(`${SwipeRxPtV3Resources.DISTRIBUTORS}/${id}`, params);

    return data;
  }

  async createDistributor(distributorCreateReq: any
    ): Promise<Distributor> {

    const { data } = await this.base.post(SwipeRxPtV3Resources.DISTRIBUTORS, {
      ...distributorCreateReq,
      type: 'distributor',
    });
    return data;
  }

  async patchDistributor(id: number, distributorPatchReq: any
    ): Promise<Distributor> {

    const { data } = await this.base.patch(`${SwipeRxPtV3Resources.DISTRIBUTORS}/${id}`, {
      ...distributorPatchReq,
      type: 'distributor',
    });
    return data;
  }

  async validateAbbreviation( params: { abbreviation: string, distributor_id?: number}): Promise<any> {
    return this.base.get(
      `${this.resource}/validate-abbreviation`,
      params,
    );
  }

  async getDistributorProduct(
    distributorId: number,
    distributorProductId: number,
    expand: string[],
  ): Promise<any> {
    const { data } = await this.base.get(`${this.resource}/${distributorId}/products/${distributorProductId}`, {
      expand,
    });
    return data;
  }

  async getDistributorProducts(
    distributorId: number,
    params: any
  ): Promise<any> {
    const response = await this.base.get(`${this.resource}/${distributorId}/products`, params);
    return response;
  }

  // eslint-disable-next-line class-methods-use-this
  async search(query: {
    term?: string;
    fields?: string[];
    page: number;
    page_size: number;
  }): Promise<{
    data: Partial<Distributor>[];
    meta: ListMeta;
  }> {
    return this.base.get(SwipeRxPtV3Resources.DISTRIBUTORS, {
      ...query,
    });
  }

  async getUploadHistory(distributorId: number, page: number, page_size: number): Promise<any> {
    return this.base.get(`${this.resource}/${distributorId}/bulk-upload`, {
      page,
      page_size,
    });
  }

  async bulkUpload(
    option: string,
    distributorId: number,
    file: File,
    additionalOptions?: Record<string, string>
  ): Promise<{
    data: any;
  }> {
    const form = new FormData();
    form.append('option', option);
    form.append(
      'file',
      new Blob(
        [await file.arrayBuffer()],
        { type: 'text/csv' },
      ),
      file.name,
    );
    if(additionalOptions) {
      Object.keys(additionalOptions).forEach((key) => {
        form.append(key, additionalOptions[key]); 
      });
    }

    return this.base.post(`${this.resource}/${distributorId}/bulk-upload`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

  async getTierPricingConfirmationSummary(distributorId: number, fileUploadId: number): Promise<any> {
    return this.base.get(`${this.resource}/${distributorId}/bulk-upload/${fileUploadId}`)
  }

  async cancelTierPricingUpload(distributorId: number, fileUploadId: number): Promise<any> {
    return this.base.delete(`${this.resource}/${distributorId}/bulk-upload/${fileUploadId}`)
  }

  async confirmTierPricingUpload(distributorId: number, fileUploadId: number): Promise<any> {
    return this.base.post(`${this.resource}/${distributorId}/bulk-upload/${fileUploadId}`, null)
  }

  async updateDistributorProduct(
    distributorId: number,
    distributorProductId: number,
    payload: any,
  ): Promise<any> {
    const { data } = await this.base.patch(
      `${this.resource}/${distributorId}/products/${distributorProductId}`,
      payload,
    );

    return data;
  }

  async addProductToDistributor(
    distributorId: number,
    payload: any,
  ): Promise<any> {
    const { data } = await this.base.post(
      `${this.resource}/${distributorId}/products`,
      payload,
    );

    return data;
  }
}
