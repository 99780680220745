/* eslint-disable no-param-reassign */

import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { MasterListData, MasterListDataPerDomain } from './interfaces';

/**
 * This class is used for fetching dropdown options to API.
 */
export class SwipeRxPtMasterList {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.MASTER_LIST;
  }

  async fetchLists(params: { domain: string; type: string }): Promise<MasterListData[]> {
    const result = await this.base.get(`${this.resource}/domain/${params.domain}/type/${params.type}`, params);
    return result.data || [];
  }

  async fetchListsByDomain(domain: string): Promise<MasterListDataPerDomain> {
    const result = await this.base.get(`${this.resource}/domain/${domain}`);
    return result.data || {};
  }

  async fetchListsByParentId(parentId: string): Promise<MasterListData[]> {
    const result = await this.base.get(`${this.resource}/parent/${parentId}`);
    return result.data || [];
  }
}
