import React, { useState } from 'react';
import { Button, MenuItem, Menu, PaperProps } from '@material-ui/core';

import { ArrowDropDown } from '@material-ui/icons';

interface ButtonMenuOptions {
  key: string;
  caption: string;
  onClick: () => void;
  disabled?: boolean;
}
export interface ButtonMenuProps {
  menuOptions: ButtonMenuOptions[];
  title: string;
  disabled?: boolean;
  menuStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  paperProps?: PaperProps;
}
export const ButtonMenu: React.FC<ButtonMenuProps> = (props) => {
  const { menuOptions, title, disabled, menuStyle = {}, buttonStyle = {}, paperProps } = props || {};
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <Button
        id={`${title}-button-menu`}
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        disabled={disabled}
        color="primary"
        variant="contained"
        style={{
          textTransform: 'none',
          ...buttonStyle,
        }}
      >
        {title}
      </Button>
      <Menu
        id={`${title}-customized-menu`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        PaperProps={paperProps}
        onClose={handleClose}
        style={{
          minWidth: '180px',
          marginTop: '38px',
          ...menuStyle,
        }}
      >
        {menuOptions.map((option) => (
          <MenuItem
            key={option.key}
            onClick={() => {
              option.onClick();
              handleClose();
            }}
            disabled={option.disabled}
          >
            {option.caption}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
