import React from 'reactn';
import QRCode from 'qrcode.react';
import moment from 'moment-timezone';
import { RouteComponentProps } from 'react-router';
import { DataObject } from 'types';

import { Order } from 'common/reducers';

import { toCurrency } from 'utils';
import { ConditionalRender } from 'common/components/ConditionalRender';
import { VirtualAccount } from 'common/reducers/virtual-accounts';
import { TemplateOptions } from 'pages/PrintPage/Templates.constants';
import { calculateSLA } from 'pages/PrintPage/utils/calculateSLA';
import { translations, ITranslation } from './lang';

import { S } from './DeliveryProof.styles';

interface MatchParams {
  template: string;
  marketId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  order: Order;
  config: DataObject;
  key?: string;
}

export const DeliveryProofV0: React.FC<Props> = ({ order, match, config }) => {
  const {
    params: { marketId },
  } = match!;
  const { template } = match.params;

  const invoice = order.invoice || {};
  const pharmacy = order.pharmacy || {};
  const distributor = order.distributor || {};
  const virtualAccounts = order.pharmacy.virtual_accounts || {};
  const targetVirtualAccounts = [] as string[];

  const isDeliveryProof = template === TemplateOptions.PRINT_DELIVERY_PROOF;
  const isDeliveryDocs = template === TemplateOptions.PRINT_DELIVERY_DOCS;
  const isInvoiceDocs = template === TemplateOptions.PRINT_INVOICE_DOCS;

  // TODO : VAs only apply in Indonesia market, need to accomodate other market on API side
  if (marketId === 'id') {
    targetVirtualAccounts.push(...['BCA', 'MANDIRI']);
  }
  const t: ITranslation = translations[marketId];

  const SLA = calculateSLA(order);

  const renderVirtualAccounts = (): JSX.Element | null => {
    if (virtualAccounts.length) {
      return (
        <div className="virtual-accounts">
          {virtualAccounts
            .filter((account: VirtualAccount) => targetVirtualAccounts.includes(account.bank))
            .map((account: VirtualAccount) => (
              <p key={account.id}>{`${account.bank} : ${account.number}`}</p>
            ))}
        </div>
      );
    }

    return null;
  };

  const generateSignature = (withAdditionalInfo = false): JSX.Element => (
    <S.SignatureContainer>
      <S.SignatureContent>
        <p>{t.deliverer},</p>
        <ConditionalRender condition={withAdditionalInfo}>
          <S.SignatureAdditionalInfo />
        </ConditionalRender>
        <S.SignatureSlot />
      </S.SignatureContent>
      <S.SignatureContent>
        <p>{t.recipient},</p>
        <ConditionalRender condition={withAdditionalInfo}>
          <S.SignatureAdditionalInfo>
            <p>{t.date}: </p>
            <p>{t.time}: </p>
          </S.SignatureAdditionalInfo>
        </ConditionalRender>
        <S.SignatureSlot />
      </S.SignatureContent>
    </S.SignatureContainer>
  );

  const renderOneCopy = (): JSX.Element => (
    <>
      <S.PrimaryDataContainer>
        <S.Brand>
          <strong>{t.SwipeRx}</strong>
        </S.Brand>
        <S.OrganizationData>
          <div className="pharmacy-name">
            <strong>{`${pharmacy.name}[${pharmacy.id}]`}</strong>
          </div>
          <div className="distributor-name">
            <strong>{distributor.name}</strong>
          </div>
        </S.OrganizationData>
      </S.PrimaryDataContainer>
      <S.Title>
        <strong>{t.invoiceReceipt}</strong>
      </S.Title>
      <div>
        <S.InvoiceTable>
          <tbody>
            <tr>
              <th>{t.invoiceNo}</th>
              <th>{t.invoiceNumber}</th>
              <th>{t.invoiceDate}</th>
              <th>{t.invoiceTotalAmount}</th>
              <th>{t.invoiceDueDate}</th>
              <th>{t.invoiceNote}</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>1</td>
              <td>{invoice.invoice_number}</td>
              <td>{moment(invoice.invoiced_at).format('DD/MM/YYYY')}</td>
              <td>{toCurrency(invoice.invoiced_amount)}</td>
              <td>{moment(invoice.invoiced_at).add(30, 'days').format('DD/MM/YYYY')}</td>
              <td />
            </tr>
          </tbody>
        </S.InvoiceTable>
      </div>
      {generateSignature(true)}
      <S.Title>
        <strong>{t.retourForm}</strong>
      </S.Title>
      <div>
        <S.ReturnFormTable>
          <tbody>
            <tr>
              <th>{t.retourNo}</th>
              <th>{t.retourItem}</th>
              <th>{t.retoruQuantity}</th>
              <th>{t.retourReason}</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colSpan={4} />
            </tr>
            <tr>
              <td colSpan={4} />
            </tr>
            <tr>
              <td colSpan={4} />
            </tr>
          </tbody>
        </S.ReturnFormTable>
      </div>
      {generateSignature()}
      <S.AddendumContainer>
        <div className="note">
          <p>{t.footerNoteTitle}: </p>
          {t.footerNotes.map((item, index) => {
            return (
              <p>
                {index + 1}&nbsp;{item}
              </p>
            );
          })}
          <QRCode className="qr-code" value={`PT-DLV-${order.po_number}`} size={125} />
        </div>
        <div className="payment-information">
          <p>{t.footerPaymentMethod}:</p>
          {renderVirtualAccounts()}
          <p>{t.footerCustomerService.replace('#CONTACT_NUMBER#', config.market.contactNumber)}</p>
          {SLA && (
            <>
              <div
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.4)',
                  borderRadius: 8,
                  marginTop: 16,
                  width: 'fit-content',
                  padding: 4,
                }}
              >
                {SLA.SLATag}
              </div>
            </>
          )}
        </div>
      </S.AddendumContainer>
    </>
  );
  return (
    <S.DeliveryWrapper className={isDeliveryDocs ? 'deliveryDocsWrapper' : ''}>
      <>
        {(isDeliveryDocs || isDeliveryProof) && (
          <>
            {renderOneCopy()}
            <S.Divider />
            {renderOneCopy()}
          </>
        )}

        {!isDeliveryProof && (
          <S.BlankPageWithQr className={isInvoiceDocs ? 'invoiceDocsWrapper' : ''}>
            <div className="qrcode-container">
              <QRCode value={`PT-INV-${order.po_number}`} />
              <div className="qrcode-label">
                <div className="full-width">
                  <strong>{`Pharmacy: ${pharmacy.name}`}</strong>
                  <br />
                  <strong>{`Distributor: ${distributor.name}`}</strong>
                  <br />
                  <strong>{`Order Number: ${order.po_number}`}</strong>
                  <br />
                  <strong>{`Invoice Number: ${invoice?.invoice_number || 'Pending invoice number'}`}</strong>
                </div>
              </div>
            </div>
          </S.BlankPageWithQr>
        )}
      </>
    </S.DeliveryWrapper>
  );
};
