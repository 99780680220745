import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { PaymentMethodType } from 'types/PaymentMethod.type';
import { PaymentMethod, FinanceManagement } from 'common/constants';
import { PrekursorFilterValues } from 'pages/transactions/hooks';
import { S } from './TabFilterPayment.styles';

type Props = {
  value?: PaymentMethodType | FinanceManagement | PrekursorFilterValues;
  enableCreditFilter?: boolean;
  enableReturnFilter?: boolean;
  enablePrekursorFilter?: boolean;
  onChange: (method: PaymentMethodType) => void;
};

export const TabFilterPayment: React.FC<Props> = (props) => {
  const {
    value = PaymentMethod.CREDIT_LINE,
    enableCreditFilter = true,
    enableReturnFilter = false,
    enablePrekursorFilter = false,
    onChange
  } = props;

  const handleChange = (event: React.ChangeEvent<{}>, selected: PaymentMethodType): void => {
    event.preventDefault();

    onChange(selected);
  };

  return (
    <S.Wrapper>
      <Tabs indicatorColor="primary" value={value} onChange={handleChange} aria-label="Filter Payment Method">
        <Tab label="Credit" value={PaymentMethod.CREDIT_LINE} data-testid="credit-tab" />
        { enableCreditFilter &&  <Tab label="Direct Payment" value={PaymentMethod.DIRECT_PAYMENT} data-testid="directPayment-tab" /> }
        { enableReturnFilter && <Tab label="Returns Management" value={FinanceManagement.RETURN} data-testid="returnManagement-tab" />}
        { enablePrekursorFilter && <Tab label="Prekursor Approval" value={PrekursorFilterValues.PREKURSOR_PAGE} data-testid="prekursorapproval-tab" />}
      </Tabs>
    </S.Wrapper>
  );
};
