/* eslint-disable no-restricted-globals */
import { addReducer } from 'reactn';
import findIndex from 'lodash/findIndex';

import { getSelectedInvoicesByIds, isStatusAllNotFiltered, pluralize } from 'common/utils';
import { SwipeRxPt, Invoice } from 'common/services/apis/v3/swipe-rx-pt';

import { recordException } from 'utils/Reporting/Sentry';

import { DataObject } from 'types';
import { FinanceState } from './interfaces';
import { DataTable, DataTableParams } from '../data-tables';

export const FINANCE_INITIAL_STATE: FinanceState = {
  isBlank: true,
  loading: false,
  distributor: {
    data: null,
  },
  invoiceViewInfo: {
    id: null,
  },
  isBulkReimburseOpen: false,
  isBulkDisburseOpen: false,
  invoiceUpdateInfo: null,
  disbursementDateUpdateInfo: null,
  reimbursementDateUpdateInfo: null,
  invoiceApprove: {
    selected: [],
    failed: [],
  },
  invoiceReimbursement: {
    selected: [],
    failed: [],
  },
  invoiceDisbursement: {
    selected: [],
    failed: [],
  },
  invoiceHoldToApprove: {
    ids: [],
    details: [],
    failed: [],
    success: [],
  },
  invoiceHoldToDisburse: {
    ids: [],
    details: [],
    failed: [],
    success: [],
  },
};

const getInvoiceNumberTexts = (invoices: Invoice[] | string[]): string => {
  const getInvoiceNumber = (invoice: string | Invoice): string =>
    typeof invoice === 'string' ? invoice : invoice.invoice_number;

  const [firstInvoice] = invoices;
  const firstInvoiceNumber = getInvoiceNumber(firstInvoice);

  if (invoices.length > 2) {
    const numOfOtherInvoices = invoices.length - 1;
    return `${firstInvoiceNumber} and ${numOfOtherInvoices} ${pluralize('other', numOfOtherInvoices)}`;
  } else if (invoices.length === 2) {
    return `${firstInvoiceNumber} and ${getInvoiceNumber(invoices[1])}`;
  }
  return firstInvoiceNumber;
};

const financeStatusUpdateMessage = (updateList: Invoice[] | string[], message: string): string => {
  return `Invoice ${getInvoiceNumberTexts(updateList)} ${pluralize('has', updateList.length, 'have')} ${message}`;
};

const buildExpandQueryParams = (params: any): string[] => {
  const expand = [
    'purchase_order.distributor',
    'purchase_order.pharmacy',
    'finance_latest_status.approval_actor',
    'finance_latest_status.claim_actor',
    'finance_latest_status.reimbursement_actor',
    'finance_latest_status.disbursement_actor',
  ];
  const hasLastCredit =
    (params.claim_status === 'failed' && params.transaction_status === 'due_for_disbursement') ||
    params.transaction_status === 'previously_failed';
  if (hasLastCredit) {
    expand.push('purchase_order.pharmacy.latest_credit');
  }
  return expand;
};

addReducer('setFinanceBlank', (state, _, isBlank) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      isBlank,
    },
  };
});

addReducer('fetchFinanceRowCount', async (state, dispatch, { params }) => {
  const { finance } = state;
  try {
    const isBlank = params?.status === undefined && params?.claim_status === undefined;
    if (!isBlank) {
      const queryParams = {
        ...params,
        expand: buildExpandQueryParams(params),
      };
      const { total_count } = await SwipeRxPt.instance.finance.getTotalRowCount(queryParams);
      const totalCount = total_count as number;
      return {
        ...state,
        finance: {
          ...finance,
          totalRowCount: totalCount,
        },
      };
    }
  } catch (err) {
    recordException(err, 'fetchFinanceRowCount', { params });
  }
  return {
    ...state,
    finance: {
      ...finance,
      totalRowCount: undefined,
    },
  };
});

addReducer('resetFinanceRowCount', (state) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      totalRowCount: undefined,
    },
  };
});

addReducer('fetchFinanceDatatable', async (_, dispatch, { params, cancelPendingRequest }) => {
  try {
    dispatch.clearApprove({});
    dispatch.clearHoldToApprove({});
    dispatch.clearReimbursement({});
    dispatch.clearDisbursement({});
    dispatch.clearHoldToDisburse({});

    let rows: any = [];
    let dataTableParams: DataTableParams = {
      ...params,
      page_count: 0,
      total_count: 0,
    };

    const isBlank =
      (params?.status === undefined && params?.claim_status === undefined) || isStatusAllNotFiltered(params);
    dispatch.setFinanceBlank(isBlank);
    if (!isBlank) {
      dispatch.cancelRequestFinance(cancelPendingRequest as boolean);
      dispatch.setFinanceLoading(true);

      const queryParams = {
        ...params,
        expand: buildExpandQueryParams(params),
      };
      const { data, meta } = await SwipeRxPt.instance.finance.list(queryParams);

      rows = data;
      dataTableParams.page_count = meta.page_count;
      dataTableParams.total_count = meta.total_count;
    }

    dispatch.setDataTableRows({
      rows,
      name: 'finance',
      params: dataTableParams,
    });
  } catch (error) {
    recordException(error, 'fetchFinanceDatatable', { params, cancelPendingRequest });
    dispatch.setFinanceError({ error: error.message });
  } finally {
    dispatch.setFinanceLoading(false);
  }
});

addReducer('setFinanceLoading', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      loading: payload,
    },
  };
});

addReducer('setFinanceError', (state, _, payload) => {
  const { finance } = state;
  return {
    ...state,
    finance: {
      ...finance,
      error: payload.error,
    },
  };
});

addReducer('setFinanceSuccessMessage', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      successMessage: payload.message,
    },
  };
});

addReducer('setFinanceWarningMessage', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      warningMessage: payload.message,
    },
  };
});

addReducer('fetchFinanceDistributor', async (_, dispatch, { id }) => {
  try {
    const { name } = await SwipeRxPt.instance.organizations.retrieve(id, {
      type: 'distributor',
    });

    dispatch.setFinanceDistributor({ id, name });
  } catch (e) {
    recordException(e, 'fetchFinanceDistributor', { id });
    dispatch.setFinanceDistributorError({ error: e.message });
  }
});

addReducer('setFinanceDistributor', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      distributor: {
        data: payload,
        error: null,
      },
    },
  };
});

addReducer('setFinanceDistributorPaymentPreference', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      distributor: {
        ...finance.distributor,
        preference: payload,
      },
    },
  };
});

addReducer('setFinanceDistributorError', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      distributor: {
        ...finance.distributor,
        error: payload.error,
      },
    },
  };
});

addReducer('setInvoiceViewInfo', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceViewInfo: payload,
    },
  };
});

addReducer('approveInvoice', async (_, dispatch, { ids, retry }) => {
  try {
    dispatch.setApproveSelected({ ids });

    const approvedInvoice = await SwipeRxPt.instance.finance.approveInvoice(ids);

    dispatch.setFinanceSuccessMessage({
      message: financeStatusUpdateMessage(approvedInvoice, `been ${retry ? 'retried' : 'approved'}`),
    });
  } catch (err) {
    recordException(err, 'approveInvoice', { ids, retry });
    dispatch.setFinanceError({ error: err.message });
    dispatch.setApproveSelected({ ids: [] });
    dispatch.setApproveFailed({ ids });
  }
});

addReducer('reimburseInvoice', async (_, dispatch, { ids }) => {
  try {
    dispatch.setReimbursementSelected({ ids });

    const result = await SwipeRxPt.instance.finance.reimburseInvoice(ids);

    if (result.reimbursed.length === 0 && result.failed.length > 0) {
      dispatch.setReimbursementFailed({ ids: result.failed });
      throw new Error('Some of the invoices are not ready for reimbursement');
    }

    const reimburseMessages: string[] = [];
    if (result.reimbursed.length > 0) {
      reimburseMessages.push(
        `${result.reimbursed.length} ${pluralize(
          'Invoice has',
          result.reimbursed.length,
          'Invoices have',
        )} been mark as reimbursed`,
      );
    }
    if (result.failed.length > 0) {
      reimburseMessages.push(`${result.failed.length} ${pluralize('Invoice', result.failed.length)} failed`);
      dispatch.setReimbursementFailed({ ids: result.failed });
    }

    const message = [`${ids.length} ${pluralize('Invoice', ids.length)}`, reimburseMessages.join(', ')].join('. ');

    if (result.failed.length > 0) throw new Error(message);

    dispatch.setFinanceSuccessMessage({
      message,
    });
  } catch (err) {
    recordException(err, 'reimburseInvoice', { ids });
    dispatch.setFinanceError({ error: err.message });
  }
});

addReducer('toggleBulkReimburseDialog', async (state, _, { isOpen }) => {
  const { finance } = state;
  return {
    ...state,
    finance: {
      ...finance,
      isBulkReimburseOpen: isOpen,
    },
  };
});

addReducer('toggleBulkDisburseDialog', async (state, _, { isOpen }) => {
  const { finance } = state;
  return {
    ...state,
    finance: {
      ...finance,
      isBulkDisburseOpen: isOpen,
    },
  };
});

addReducer('reimburseInvoiceByNumber', async (_, dispatch, { invoiceNumbers }) => {
  try {
    dispatch.setFinanceLoading(true);

    const result = await SwipeRxPt.instance.finance.reimburseInvoiceByNumber(invoiceNumbers);

    const reimburseMessages: string[] = [];
    if (result.reimbursed.length > 0) {
      reimburseMessages.push(
        `${result.reimbursed.length} ${pluralize(
          'Invoice has',
          result.reimbursed.length,
          'Invoices have',
        )} been mark as reimbursed`,
      );
    }
    if (result.failed.length > 0) {
      reimburseMessages.push(`${result.failed.length} ${pluralize('Invoice', result.failed.length)} failed`);
      dispatch.setDisbursementFailed({
        ids: result.failed.map((item: DataObject) => item.id),
      });
    }

    const message = [
      `${invoiceNumbers.length} ${pluralize('Invoice', invoiceNumbers.length)}`,
      reimburseMessages.join(', '),
    ].join('. ');

    if (result.failed.length > 0) throw new Error(message);

    dispatch.setFinanceSuccessMessage({
      message,
    });

    await dispatch.fetchDataTableRows({ name: 'finance' });
  } catch (error) {
    recordException(error, 'reimburseInvoiceByNumber', { invoiceNumbers });
    dispatch.setFinanceError({ error: error.message });
  } finally {
    dispatch.setFinanceLoading(false);
  }
});

addReducer('reimburseInvoiceByCsv', async (_, dispatch, { file, params }) => {
  try {
    if (!file) return;

    dispatch.setFinanceLoading(true);

    const result = await SwipeRxPt.instance.finance.reimburseInvoiceCsv(file, params);

    const reimburseMessages: string[] = [];
    if (result.reimbursed.length > 0) {
      reimburseMessages.push(
        `${result.reimbursed.length} ${pluralize(
          'Invoice has',
          result.reimbursed.length,
          'Invoices have',
        )} been mark as reimbursed`,
      );
    }
    if (result.failed.length > 0) {
      reimburseMessages.push(`${result.failed.length} ${pluralize('Invoice', result.failed.length)} failed`);
      dispatch.setDisbursementFailed({
        ids: result.failed.map((item: DataObject) => item.id),
      });
    }

    const total = result.reimbursed.length + result.failed.length;

    const message = [`${total} ${pluralize('Invoice', total)}`, reimburseMessages.join(', ')].join('. ');

    if (result.failed.length > 0) throw new Error(message);

    dispatch.setFinanceSuccessMessage({
      message,
    });

    await dispatch.fetchDataTableRows({ name: 'finance' });
  } catch (error) {
    recordException(error, 'reimburseInvoiceByCsv', { file, params });
    dispatch.setFinanceError({ error: error.message });
  } finally {
    dispatch.setFinanceLoading(false);
  }
});

addReducer('disburseInvoiceByNumber', async (_, dispatch, { invoiceNumbers }) => {
  try {
    dispatch.setFinanceLoading(true);

    const result = await SwipeRxPt.instance.finance.disburseInvoiceByNumber(invoiceNumbers);
    await dispatch.fetchDataTableRows({ name: 'finance' });

    if (result.disbursed.length > 0) {
      dispatch.setFinanceSuccessMessage({
        message: financeStatusUpdateMessage(result.disbursed, 'been marked as disbursed'),
      });
    }
    if (result.failed.length > 0) {
      dispatch.setFinanceWarningMessage({
        message: `Invoice ${getInvoiceNumberTexts(result.failed)} failed to be marked as disbursed`,
      });
    }
  } catch (error) {
    recordException(error, 'disburseInvoiceByNumber', { invoiceNumbers });
    dispatch.setFinanceError({ error: error.message });
  } finally {
    dispatch.setFinanceLoading(false);
  }
});

addReducer('disburseInvoice', async (_, dispatch, { ids }) => {
  try {
    dispatch.setDisbursementSelected({ ids });

    const result = await SwipeRxPt.instance.finance.disburseInvoice(ids);
    if (result.failed.length > 0) {
      dispatch.setFinanceWarningMessage({
        message: `Invoice ${getInvoiceNumberTexts(result.failed)} failed to be marked as disbursed`,
      });
      dispatch.setDisbursementFailed({
        ids: result.failed.map((item: DataObject) => item.id),
      });
    }
    if (result.updated.length > 0) {
      dispatch.setFinanceSuccessMessage({
        message: financeStatusUpdateMessage(result.updated, 'been marked as disbursed'),
      });
    }
  } catch (err) {
    recordException(err, 'disburseInvoice', { ids });
    dispatch.setFinanceError({ error: err.message });
  }
});

addReducer(
  'createOrUpdatePaymentPreference',
  async (_, dispatch, { distributor_id, days_reimbursement, days_disbursement }) => {
    try {
      dispatch.setFinanceLoading(true);

      await SwipeRxPt.instance.finance.createOrUpdatePaymentPreference(distributor_id, {
        days_reimbursement,
        days_disbursement,
      });

      await dispatch.fetchDataTableRows({ name: 'finance' });
    } catch (err) {
      recordException(err, 'createOrUpdatePaymentPreference', {
        distributor_id,
        days_reimbursement,
        days_disbursement,
      });
      dispatch.setFinanceError({ error: err.message });
    } finally {
      dispatch.setFinanceLoading(false);
    }
  },
);

addReducer('getPaymentPreference', async (_, dispatch, { distributor_id }) => {
  try {
    dispatch.setFinanceLoading(true);

    const response = await SwipeRxPt.instance.finance.getPaymentPreference(distributor_id);

    dispatch.setFinanceDistributorPaymentPreference({
      days_disbursement: response.days_disbursement,
      days_reimbursement: response.days_reimbursement,
    });

    await dispatch.fetchDataTableRows({ name: 'finance' });
  } catch (err) {
    recordException(err, 'getPaymentPreference', { distributor_id });
    dispatch.setFinanceError({ error: err.message });
  } finally {
    dispatch.setFinanceLoading(false);
  }
});

addReducer('setFinanceBulkActionConfirmation', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      bulkActionConfirmation: payload,
    },
  };
});

addReducer('cancelRequestFinance', (_1, _2, isCancelRequest) => {
  if (isCancelRequest) {
    SwipeRxPt.instance.finance.cancelRequest();
  }
});

addReducer('exportInvoice', async (state, dispatch, payload) => {
  try {
    const {
      dataTables: {
        finance: { isAllSelected },
      },
      finance: { totalRowCount },
    } = state;

    const total = !isAllSelected ? payload.invoiceIds.length : (totalRowCount as number);

    if (total >= payload.limit)
      throw new Error(
        `You are trying to export ${total} invoices. Please reduce total invoices to be exported, only less than 10.000 invoices can be exported at once`,
      );

    dispatch.setFinanceLoading(true);

    dispatch.setFinanceSuccessMessage({
      message: `Exporting ${total} invoices. You can turn on email notifications to get alerts once export has completed.`,
    });

    await SwipeRxPt.instance.finance.exportInvoice(payload, !!isAllSelected);
  } catch (err) {
    recordException(err, 'exportInvoice', { payload });
    dispatch.setFinanceError({ error: err.message });
  } finally {
    dispatch.setFinanceLoading(false);
  }
});

addReducer('setInvoiceUpdateInfo', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceUpdateInfo: payload,
    },
  };
});

addReducer('setDisbursementDateUpdateInfo', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      disbursementDateUpdateInfo: payload,
    },
  };
});

addReducer('setReimbursementDateUpdateInfo', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      reimbursementDateUpdateInfo: payload,
    },
  };
});

addReducer('updateInvoiceFinance', async (_, dispatch, payload) => {
  try {
    const { invoice_id: invoiceId, ...data } = payload;

    const updatedInvoice = await SwipeRxPt.instance.finance.updateInvoice(invoiceId, data);

    dispatch.updateFinanceRow({
      where: {
        id: invoiceId,
      },
      data: {
        ...data,
        invoice_photo: updatedInvoice.invoice_photo,
        proof_photo: updatedInvoice.proof_photo,
        tax_photo: updatedInvoice.tax_photo,
        invoiced_at: updatedInvoice.invoiced_at,
      },
    });

    dispatch.setInvoiceUpdateInfo(null);
    dispatch.setFinanceSuccessMessage({
      message: 'Invoice has been updated.',
    });
  } catch (e) {
    recordException(e, 'updateInvoiceFinance', { payload });
    dispatch.setFinanceError({ error: e.message });
  }
});

addReducer('updateDisbursementDateInfo', async (_, dispatch, payload) => {
  const { id, disbursement_date } = payload;
  try {
    const updatedDisbursementDate = await SwipeRxPt.instance.finance.updateDisbursementDateInfo({
      id,
      disbursement_date,
    });

    dispatch.updateFinanceRowOnFinanceLatestStatus({
      where: {
        id,
      },
      data: {
        disbursed_at: updatedDisbursementDate.disbursement_date,
        disbursement_actor: {
          email: updatedDisbursementDate.disbursement_actor_email,
        },
      },
    });

    dispatch.setDisbursementDateUpdateInfo(null);
    dispatch.setFinanceSuccessMessage({
      message: 'Disbursement date has been updated.',
    });
  } catch (e) {
    recordException(e, 'updateDisbursementDate', { id, disbursement_date });
    dispatch.setFinanceError({ error: e.message });
  }
});

addReducer('updateReimbursementDateInfo', async (_, dispatch, payload) => {
  const { id, reimbursement_date } = payload;
  try {
    const updatedReimbursementDate = await SwipeRxPt.instance.finance.updateReimbursementDateInfo({
      id,
      reimbursement_date,
    });

    dispatch.updateFinanceRowOnFinanceLatestStatus({
      where: {
        id,
      },
      data: {
        reimbursed_at: updatedReimbursementDate.reimbursement_date,
        reimbursement_actor: {
          email: updatedReimbursementDate.reimbursement_actor_email,
        },
      },
    });

    dispatch.setReimbursementDateUpdateInfo(null);
    dispatch.setFinanceSuccessMessage({
      message: 'Reimbursement date has been updated.',
    });
  } catch (e) {
    recordException(e, 'updateReimbursementDate', { id, reimbursement_date });
    dispatch.setFinanceError({ error: e.message });
  }
});

addReducer('updateFinanceRow', (state, _, payload) => {
  const {
    dataTables: { finance },
  } = state;

  const { index, where, data } = payload;

  const dataTableParams: Partial<DataTable> = {};

  let currentIndex: number = -1;
  if (index) {
    currentIndex = index;
  } else if (!index && where) {
    currentIndex = findIndex(finance.rows, where);
  }

  if (currentIndex > -1) {
    const prevRows = finance.rows.slice(0, currentIndex);
    const row = finance.rows[currentIndex];
    const nextRows = finance.rows.slice(currentIndex + 1);

    dataTableParams.rows = [
      ...prevRows,
      {
        ...row,
        ...data,
      },
      ...nextRows,
    ];
  }
});

addReducer('updateFinanceRowOnFinanceLatestStatus', (state, _, payload) => {
  const {
    dataTables: { finance },
  } = state;

  const { index, where, data } = payload;

  const dataTableParams: Partial<DataTable> = {};

  let currentIndex: number = -1;
  if (index) {
    currentIndex = index;
  } else if (!index && where) {
    currentIndex = findIndex(finance.rows, where);
  }

  if (currentIndex > -1) {
    const prevRows = finance.rows.slice(0, currentIndex);
    const row = finance.rows[currentIndex];
    const financeLatestStatus = row.finance_latest_status;
    const nextRows = finance.rows.slice(currentIndex + 1);

    dataTableParams.rows = [
      ...prevRows,
      {
        ...row,
        finance_latest_status: {
          ...financeLatestStatus,
          ...data,
        },
      },
      ...nextRows,
    ];
  }

  return {
    ...state,
    dataTables: {
      ...state.dataTables,
      finance: {
        ...finance,
        ...dataTableParams,
      },
    },
  };
});

addReducer('setApproveSelected', (state, _, payload) => {
  const { finance } = state;

  const selected = payload.ids.length === 0 ? [] : [...finance.invoiceApprove.selected, ...payload.ids];

  return {
    ...state,
    finance: {
      ...finance,
      invoiceApprove: {
        ...finance.invoiceApprove,
        selected,
      },
    },
  };
});

addReducer('setApproveFailed', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceApprove: {
        ...finance.invoiceApprove,
        failed: [...finance.invoiceApprove.failed, ...payload.ids],
      },
    },
  };
});

addReducer('clearApprove', (state) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceApprove: {
        selected: [],
        failed: [],
      },
    },
  };
});

addReducer('setReimbursementSelected', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceReimbursement: {
        ...finance.invoiceReimbursement,
        selected: [...finance.invoiceReimbursement.selected, ...payload.ids],
      },
    },
  };
});

addReducer('setReimbursementFailed', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceReimbursement: {
        ...finance.invoiceReimbursement,
        failed: [...finance.invoiceReimbursement.failed, ...payload.ids],
      },
    },
  };
});

addReducer('clearReimbursement', (state) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceReimbursement: {
        selected: [],
        failed: [],
      },
    },
  };
});

addReducer('setDisbursementSelected', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceDisbursement: {
        ...finance.invoiceDisbursement,
        selected: [...finance.invoiceDisbursement.selected, ...payload.ids],
      },
    },
  };
});

addReducer('setDisbursementFailed', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceDisbursement: {
        ...finance.invoiceDisbursement,
        failed: [...finance.invoiceDisbursement.failed, ...payload.ids],
      },
    },
  };
});

addReducer('clearDisbursement', (state) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceDisbursement: {
        selected: [],
        failed: [],
      },
    },
  };
});

addReducer('setHoldToApproveIds', (state, _, { ids }) => {
  const { finance, dataTables } = state;

  const details = getSelectedInvoicesByIds(ids, dataTables);

  return {
    ...state,
    finance: {
      ...finance,
      invoiceHoldToApprove: {
        ...finance.invoiceHoldToApprove,
        ids,
        details,
      },
    },
  };
});
addReducer('setHoldToApproveSuccess', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceHoldToApprove: {
        ...finance.invoiceHoldToApprove,
        success: [...payload.ids],
      },
    },
  };
});
addReducer('setHoldToApproveFailed', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceHoldToApprove: {
        ...finance.invoiceHoldToApprove,
        failed: [...payload.ids],
      },
    },
  };
});

addReducer('holdToApproveInvoice', async (_, dispatch, { ids, reason }) => {
  try {
    dispatch.setHoldToApproveIds({ ids });

    const result = await SwipeRxPt.instance.finance.holdToApproveInvoice(ids, reason);
    if (result.failed.length > 0) {
      dispatch.setFinanceWarningMessage({
        message: `Invoice ${getInvoiceNumberTexts(result.failed)} failed to be marked as hold to approve`,
      });
      dispatch.setHoldToApproveFailed({
        ids: result.failed.map((item: Invoice) => item.id),
      });
    }
    if (result.updated.length > 0) {
      dispatch.setFinanceSuccessMessage({
        message: financeStatusUpdateMessage(result.updated, 'been marked as hold to approve'),
      });
      dispatch.setHoldToApproveSuccess({
        ids: result.updated.map((item: Invoice) => item.id),
      });
    }
  } catch (err) {
    recordException(err, 'holdToApproveInvoice', { ids });
    dispatch.setFinanceError({ error: err.message });
  }
});

addReducer('clearHoldToApprove', (state) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceHoldToApprove: {
        ids: [],
        details: [],
        failed: [],
        success: [],
      },
    },
  };
});

addReducer('setHoldToDisburseIds', (state, _, { ids }) => {
  const { finance, dataTables } = state;

  const details = getSelectedInvoicesByIds(ids, dataTables);

  return {
    ...state,
    finance: {
      ...finance,
      invoiceHoldToDisburse: {
        ...finance.invoiceHoldToDisburse,
        ids,
        details,
      },
    },
  };
});
addReducer('setHoldToDisburseSuccess', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceHoldToDisburse: {
        ...finance.invoiceHoldToDisburse,
        success: [...payload.ids],
      },
    },
  };
});
addReducer('setHoldToDisburseFailed', (state, _, payload) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceHoldToDisburse: {
        ...finance.invoiceHoldToDisburse,
        failed: [...payload.ids],
      },
    },
  };
});

addReducer('holdToDisburseInvoice', async (_, dispatch, { ids, reason }) => {
  try {
    dispatch.setHoldToDisburseIds({ ids });

    const result = await SwipeRxPt.instance.finance.holdToDisburseInvoice(ids, reason);
    if (result.failed.length > 0) {
      dispatch.setFinanceWarningMessage({
        message: `Invoice ${getInvoiceNumberTexts(result.failed)} failed to be marked as hold to disburse`,
      });
      dispatch.setHoldToDisburseFailed({
        ids: result.failed.map((item: Invoice) => item.id),
      });
    }
    if (result.updated.length > 0) {
      dispatch.setFinanceSuccessMessage({
        message: financeStatusUpdateMessage(result.updated, 'been marked as hold to disburse'),
      });
      dispatch.setHoldToDisburseSuccess({
        ids: result.updated.map((item: Invoice) => item.id),
      });
    }
  } catch (err) {
    recordException(err, 'holdToDisburseInvoice', { ids });
    dispatch.setFinanceError({ error: err.message });
  }
});

addReducer('clearHoldToDisburse', (state) => {
  const { finance } = state;

  return {
    ...state,
    finance: {
      ...finance,
      invoiceHoldToDisburse: {
        ids: [],
        details: [],
        failed: [],
        success: [],
      },
    },
  };
});
