export function getMarketPath(marketId: string): string {
  return `/market/${marketId}`;
}

export interface IMarketParams {
  marketId: string;
}

export function getMarketId(): string {
  const [, , market] = window.location.pathname.split('/');
  return market.trim().toLowerCase();
}
