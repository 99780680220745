import { AxiosInstance } from 'axios';
import { ProcurementAxios, PtAPIVersions } from './ProcurementAxios.class';

export class PtApiConfig {
  private marketId?: string;

  private static _instance = new PtApiConfig();

  setMarketId(marketId: string): void {
    this.marketId = marketId;
  }

  create(domain?: string, version?: PtAPIVersions): AxiosInstance {
    if (!this.marketId) {
      throw new Error('marketId is not provided');
    }
    const pAxios = new ProcurementAxios(this.marketId, domain, version);
    return pAxios.apiServer;
  }

  public static initialize(marketId: string): PtApiConfig {
    console.log('PTAPi.config initialized v2');
    PtApiConfig._instance.setMarketId(marketId);

    return PtApiConfig._instance;
  }

  public static get instance(): PtApiConfig {
    return PtApiConfig._instance;
  }
}
