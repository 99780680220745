import React from 'react';
import { v4 as uuid } from 'uuid';

import { ConditionalRender } from 'common/components/ConditionalRender';

import { S } from './AddedCriteria.styles';

import { AddedCriteriaProps, Criteria } from '../SearchWithCriteria.interface';
import { CloseButton } from 'common/components/Button';

export const AddedCriteria: React.FC<AddedCriteriaProps> = ({
  criterias,
  onRemoveCriteria,
}) => {
  const handleRemoveCriteria = (criteria: Criteria): void => {
    onRemoveCriteria(criteria);
  };

  const isIncludeCriteria = (criteria: Criteria): boolean => {
    return !criteria.name.includes(' not ');
  };

  return (
    <React.Fragment>
      {criterias.map((criteria: Criteria) => (
        <S.CriteriaContainer key={uuid()}>
          <span>
            {criteria.name}&nbsp;
            <ConditionalRender condition={isIncludeCriteria(criteria)}>
              has&nbsp;
            </ConditionalRender>
          </span>
          <strong className="criteria-value">{criteria.value}</strong>
          <CloseButton
            onClick={() => handleRemoveCriteria(criteria)}
            onKeyDown={() => handleRemoveCriteria(criteria)}
            color={'#EF2D2D'}
            backgroundColor={'#D8D8D8'}
          />
        </S.CriteriaContainer>
      ))}
    </React.Fragment>
  );
};
