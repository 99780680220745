import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';

import { ColorType } from 'types';
import { ActionMenuStyles } from 'common/components/ActionMenu';
import { ActionMenuSelectHander } from 'common/components/ActionMenu/types';

interface Props {
  color: ColorType;
  name: string;
  text: string;
  disabled?: boolean;
  onClick: ActionMenuSelectHander;
}

export const SplitButtonItem: React.FC<Props> = ({
  color,
  name,
  text,
  disabled = false,
  onClick,
}) => {
  const handleClick = (): void => {
    onClick(name);
  };

  return (
    <MenuItem key={name} value={name} onClick={handleClick} disabled={disabled}>
      <ActionMenuStyles.ItemText color={color}>
        {text}
      </ActionMenuStyles.ItemText>
    </MenuItem>
  );
};
