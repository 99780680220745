import React from 'react';
import { PdfViewer } from './PdfViewer';
import { ImageViewer } from './ImageViewer';
import { UnsupportedViewer } from './UnsupportedViewer';
import { UnavailableViewer } from './UnavailableViewer';

export interface Props {
  url: string;
  height?: string;
  width?: string;
};

const getViewer = (filename: string): React.FC<Props> => {
  if (!filename) {
    return UnavailableViewer;
  }
  const filenameSplitted = filename.split('?')[0];
  const extension = filenameSplitted.split('.').pop();
  if (['png', 'jpeg', 'jpg'].includes(extension as string)) {
    return ImageViewer;
  }
  if(extension === 'pdf') {
    return PdfViewer;
  }
  return UnsupportedViewer;
}

export const DocumentViewer: React.FC<Props> = (props: Props) => {
  const { url } = props;
  const Viewer: React.FC<Props> = getViewer(url);
  return (
      <Viewer {...props} />
  );
};
