/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { Meta } from 'types';
import { Product } from 'common/interfaces';
import productService from '../services/apis/v2/services/product.service';

export interface QueryParams {
  page_size: number;
  page: number;
  sort_by: string;
  order_by: string;
  query: string;
}

export interface ProductStore {
  products: Product[];
  meta: Meta;
}

export type GetAllProducts = (x: QueryParams) => Promise<void>;

export default {
  state: {
    products: [],
    meta: {
      count: 0,
      page: 0,
      limit: 0,
    },
  },
  reducers: {
    // @ts-ignore
    setProducts(state: ProductStore, payload: Product) {
      return { ...state, products: payload };
    },
    setMeta(state: ProductStore, payload: Meta) {
      return { ...state, meta: payload };
    },
  },
  // @ts-ignore
  effects: (dispatch) => ({
    async getAllProducts(params: QueryParams) {
      const { page, page_size, sort_by, order_by, query = '' } = params;
      const payload: any = await productService.fetchAllProducts({
        page: page + 1,
        page_size,
        sort_by,
        order_by,
        query,
      });

      const products = payload && payload.products ? payload.products : [];
      const meta = payload && payload.meta ? payload.meta : {};

      dispatch.productStore.setProducts(products);
      dispatch.productStore.setMeta(meta);
    },
  }),
};
