import { OrderItem, OrderItemParams } from './interfaces';
import { appendParamsToPath } from '../../../../../../utils/url.util';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';

export class SwipeRxPtOrderItems {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.ORDERS;
  }

  async bulkUpdate(
    orderId: number,
    payload: Array<Partial<OrderItem>>,
    params?: OrderItemParams,
  ): Promise<OrderItem[]> {
    const path = `${this.resource}/${orderId}/items`;
    const finalPath = params ? appendParamsToPath(path, params) : path;
    const { data } = await this.base.patch(finalPath, {
      data: payload,
    });

    return data;
  }
}
