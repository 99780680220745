import { PDFTableCol } from 'common/components';
import moment from 'moment';
import { DataObject } from 'types';
import { toCurrency } from 'utils';

interface IAcceptanceFormDataRow {
  date: string;
  invoiceNum: number;
  total: string;
  information: string;
}

export const acceptanceFormCols: PDFTableCol[] = [
  {
    name: 'Date',
    key: 'date',
    width: '18mm',
  },
  {
    name: 'Invoice No.',
    key: 'invoiceNum',
    width: '60mm',
  },
  {
    name: 'Total (THB)',
    key: 'total',
    width: '35mm',
  },
  {
    name: 'Information',
    key: 'information',
    width: '50mm',
  },
];

export const transfromAcceptanceFormData = (invoice: DataObject<any>): IAcceptanceFormDataRow[] => [
  {
    date: moment(invoice?.invoiced_at).format('DD/MM/YYYY'),
    invoiceNum: invoice?.invoice_number,
    total: toCurrency(invoice?.invoiced_amount),
    information: '',
  },
  { date: ' ', invoiceNum: ' ', total: ' ', information: ' ' },
  { date: ' ', invoiceNum: ' ', total: ' ', information: ' ' },
];

export const returnFormCols: PDFTableCol[] = [
  {
    name: 'No.',
    key: 'number',
    width: '18mm',
  },
  {
    name: 'Invoice No.',
    key: 'invoiceNum',
    width: '40mm',
  },
  {
    name: 'Product Name',
    key: 'productName',
    width: '35mm',
  },
  {
    name: 'Quantity',
    key: 'quantity',
    width: '28mm',
  },
  {
    name: 'Reason for return of product',
    key: 'reason',
    width: '50mm',
  },
];

export const returnFormData = [
  { number: ' ', invoiceNum: ' ', productName: ' ', quantity: ' ', reason: ' ' },
  { number: ' ', invoiceNum: ' ', productName: ' ', quantity: ' ', reason: ' ' },
  { number: ' ', invoiceNum: ' ', productName: ' ', quantity: ' ', reason: ' ' },
  { number: ' ', invoiceNum: ' ', productName: ' ', quantity: ' ', reason: ' ' },
];
