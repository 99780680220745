import React from 'reactn';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { getMarketPath, IMarketParams } from 'utils/get-router-path';
import { useRouteMatch } from 'react-router-dom';
import { S } from './OrderDetailsModal.styles';

interface Props {
  proofs: string[];
}

export const InvoicePhotos: React.FC<Props> = ({ proofs }) => {
  const {
    params: { marketId },
  } = useRouteMatch<IMarketParams>();
  const [invoice_photo, proof_photo, tax_photo] = proofs || [];

  // window.open(`${getMarketPath(marketId)}/preview-page/${btoa(invoice_photo)}`);
  const handlePreview = (url: string): void => {
    window.open(`${getMarketPath(marketId)}/preview-page/${btoa(url)}`);
  };

  return (
    <S.BasicDetailsWrapper>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <Grid item>
          <Button
            color="primary"
            disabled={!invoice_photo}
            onClick={() => handlePreview(invoice_photo)}
          >
            View Invoice
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            disabled={!proof_photo}
            onClick={() => handlePreview(proof_photo)}
          >
            View Delivery Proof
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            disabled={!tax_photo}
            onClick={() => handlePreview(tax_photo)}
          >
            View Tax
          </Button>
        </Grid>
      </Grid>
    </S.BasicDetailsWrapper>
  );
};
