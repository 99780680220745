/* eslint-disable no-param-reassign */
import numeral from 'numeral';

export const ID_LOCALE: Locale = 'id';
export const TH_LOCALE: Locale = 'th';
export const EN_LOCALE: Locale = 'en';

export type Locale = 'id' | 'th' | 'en';
export const SupportedLocaleList: Locale[] = [ID_LOCALE, TH_LOCALE, EN_LOCALE];

export const DEFAULT_LOCALE: Locale = ID_LOCALE;
export const FALLBACK_LOCALE: Locale = EN_LOCALE;

// Market language list
export type Language = 'id' | 'th' | 'en';
export const ID_LANG: Language = 'id';
export const TH_LANG: Language = 'th';
export const EN_LANG: Language = 'en';

export const DEFAULT_LANGUAGE: Language = ID_LANG;
export const FALLBACK_LANGUAGE: Language = EN_LANG;

export type PaymentActivation = 'modalku' | 'digio';

export const setNumeralLocale = (locale: Locale): void => {
  numeral.locale(locale);
};

export const setLocale = (locale: Locale): void => {
  if (!locale) locale = DEFAULT_LOCALE;
  if (!SupportedLocaleList.includes(locale)) locale = DEFAULT_LOCALE;

  setNumeralLocale(locale);
};
