/* eslint-disable no-restricted-globals */
import { addReducer } from 'reactn';

import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';
import { PtAPIv2 } from 'common/services/apis/v2/utils';
import { recordException } from 'utils/Reporting/Sentry';

import { DepositTransactionStatus } from 'common/services/apis/v3/swipe-rx-pt/resources/deposit-transaction/interface';
import { DepositTransactionState } from './interfaces/DepositTransactionState.interface';

export const DEPOSIT_TRANSACTION_INITIAL_STATE: DepositTransactionState = {
  depositTransactionList: {
    data: [],
    meta: {
      page: 1,
      page_count: 1,
      page_size: 10,
      total_count: 1,
    },
  },
  loading: false,
};

addReducer('fetchDepositTransactionDatatable', async (state, dispatch, { params }) => {
  dispatch.setDepositTransactionLoading(true);
  const { status: depositTransactionStatus, ...restParams } = params;

  try {
    const { meta, data } = await SwipeRxPt.instance.depositTransaction.list({
      ...restParams,
      status: depositTransactionStatus as DepositTransactionStatus,
    });

    dispatch.setDepositTransactionDataTable({ data, meta });
  } catch (error) {
    recordException(error, 'fetchDepositTransactionDatatable', { params });

    dispatch.setCalendarError(error);
  }
});

addReducer('resolveDepositTransactions', async (state, dispatch, { transactionIds, callback }) => {
  dispatch.setDepositTransactionLoading(true);

  try {
    await SwipeRxPt.instance.doku.resolve(transactionIds);

    dispatch.removeDepositTransactionFromList({ transactionIds });

    callback();
  } catch (error) {
    recordException(error, 'fetchDepositTransactionDatatable', { transactionIds });
  }
});

addReducer('clearDepositTransactionDataTable', (_, dispatch) => {
  dispatch.setDepositTransactionDataTable({
    data: [],
    meta: {
      page: 1,
      page_count: 1,
      page_size: 10,
      total_count: 1,
    },
  });
});

addReducer('setDepositTransactionDataTable', (state, _, { data, meta }) => {
  const { depositTransaction } = state;

  return {
    ...state,
    depositTransaction: {
      ...depositTransaction,
      loading: false,
      depositTransactionList: {
        data,
        meta,
      },
    },
  };
});

addReducer('removeDepositTransactionFromList', (state, _, { transactionIds }) => {
  const { depositTransaction } = state;
  const { depositTransactionList } = depositTransaction;

  return {
    ...state,
    depositTransaction: {
      ...depositTransaction,
      loading: false,
      depositTransactionList: {
        data: depositTransactionList.data.filter((item) => !transactionIds.includes(item.transaction_id)),
        meta: {
          ...depositTransactionList.meta,
          total_count: depositTransactionList.meta.total_count - transactionIds.length,
        },
      },
    },
  };
});

addReducer('setDepositTransactionLoading', (state, _, isLoading) => {
  const { depositTransaction } = state;

  return {
    ...state,
    depositTransaction: {
      ...depositTransaction,
      loading: isLoading,
    },
  };
});
