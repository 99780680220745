import styled from 'styled-components';

import BaseTable from '@material-ui/core/Table';
import BaseTableBody from '@material-ui/core/TableBody';
import BaseTableCell from '@material-ui/core/TableCell';
import BaseTableContainer from '@material-ui/core/TableContainer';
import BaseTableHead from '@material-ui/core/TableHead';
import BaseTableRow from '@material-ui/core/TableRow';
import BasePaper from '@material-ui/core/Paper';

import {
  BaseTableCellProps,
  TableRowProps,
  TableHeadProps,
} from './DataGrid.types';

export const TableCell = styled(BaseTableCell)<BaseTableCellProps>`
  padding: 5px !important;
  width: ${(props) => (props.width ? props.width : 100)}px !important;
  height: ${(props) => (props.height ? props.height : 32)}px !important;
  text-align: ${(props) => (props.align ? props.align : 'left')} !important;
  ${(props) =>
    props.fontSize ? `font-size: ${props.fontSize}px !important` : null}
`;

export const Table = styled(BaseTable)``;

export const TableBody = styled(BaseTableBody)``;

export const TableContainer = BaseTableContainer;

export const TableHead = styled(BaseTableHead)<TableHeadProps>`
  && {
    .MuiTableCell-head {
      ${(props) => (props && props.hide ? 'display: none;' : null)}
      font-weight: 700 !important;
    }
  }
`;
export const TableRow = styled(BaseTableRow)<TableRowProps>`
  background: ${(props) =>
    props && props?.index && props?.index % 2 ? 'rgba(0, 0, 0, 0.1)' : 'white'};
`;

export const Paper = styled(BasePaper).attrs({
  variant: 'outlined',
  elevation: 0,
})``;

export default {
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
};
