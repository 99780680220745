import {
  CreatableAutoComplete,
  CreatableAutoCompleteProps,
} from 'common/components';
import { OrderRollbackFormState } from './use-order-rollback-form';

type OrderRollbackFormKeys = keyof OrderRollbackFormState;

interface FieldOptions {
  name: OrderRollbackFormKeys;
  component: React.FC<CreatableAutoCompleteProps>;
  options?: RollbackOption[];
  label?: string;
}

enum RollbackReasons {
  VS_ACCEPT_DISPATCH_PO = 'VS accept/dispatch the PO suddenly',
  PO_HAS_CHANGES = 'PO has some change suddenly',
  GOODS_AVAILABLE = 'Goods available at PBF',
  WRONG_INPUT = 'VS wrong input comment',
  WRONG_DELETE = 'TM wrong delete PO',
}

interface RollbackOption {
  value: RollbackReasons;
  label: RollbackReasons;
}

const rollbackOption = (reason: RollbackReasons): RollbackOption => ({
  value: reason,
  label: reason,
});

export const rollbackOptions: RollbackOption[] = [
  rollbackOption(RollbackReasons.VS_ACCEPT_DISPATCH_PO),
  rollbackOption(RollbackReasons.PO_HAS_CHANGES),
  rollbackOption(RollbackReasons.GOODS_AVAILABLE),
  rollbackOption(RollbackReasons.WRONG_INPUT),
  rollbackOption(RollbackReasons.WRONG_DELETE),
];

export const fieldOptions: FieldOptions[] = [
  {
    name: 'reason',
    component: CreatableAutoComplete,
    options: rollbackOptions,
    label: 'Reason',
  },
];
