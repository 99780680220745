import id from './id';
import th from './th';
import { ITranslation } from './types';

export * from './types';

export interface ITranslations {
  [key: string]: ITranslation;
}

export const translations: ITranslations = { id, th };
