import { PrecursorRejectReason } from 'pages/transactions/components/PrecursorDetail/PrecursorDetail.type';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';

export class SwipeRxPtPrecursor {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.PRECURSOR;
  }

  cancelRequest(): void {
    this.base.cancelRequest();
  }

  async getHistory(id: number, params: { sort_by: string }): Promise<any> {
    const result = await this.base.get(`${this.resource}/${id}/details`, params);

    return result;
  }

  async reject(id: number, payload: { reason?: PrecursorRejectReason[]; description?: string }): Promise<any> {
    const result = await this.base.post(`${this.resource}/${id}/reject`, payload);

    return result;
  }

  async approve(id: number): Promise<any> {
    const result = await this.base.post(`${this.resource}/${id}/approve`, {});

    return result;
  }
}
