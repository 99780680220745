import styled from 'styled-components';

import Select from '@material-ui/core/Select';

import { COLORS } from 'common/constants';
import { ColorType } from 'types';

interface ItemTextProps {
  color?: ColorType;
}

const getItemTextColor = ({ color: colorType }: ItemTextProps): string =>
  colorType ? COLORS[colorType] : '#000';

const Wrapper = styled(Select)`
  && {
    .MuiOutlinedInput-root {
      height: 28px;
    }

    .MuiSelect-root {
      background: #fff;
      border: 1px solid #d6d6d6;
      border-radius: 5px;
      font-size: 12px;
      padding: 5px 10px;
      height: 16px;
    }

    svg {
      right: 3px;
    }
  }
`;

const ItemText = styled.span<ItemTextProps>`
  color: ${getItemTextColor};
`;

export const S = {
  Wrapper,
  ItemText,
};
