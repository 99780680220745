/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { PrecursorDetailTable } from './PrecursorDetailPage.style';
import { useDispatch, useGlobal } from 'reactn';
import { Container, Link } from '@material-ui/core';
import { ColorType } from 'types';
import { Attachment } from '@material-ui/icons';
import moment from 'moment';
import { OrderDetailsResponse } from './PrecursorDetail.type';
import { IPrecursorStatusHistory } from 'common/reducers/precursor/interfaces/PrecursorState.interface';
import { OrderPrecursorStatus } from 'pages/transactions/types';

interface Props {
  orderDetails?: OrderDetailsResponse;
  precursorStatusHistory?: IPrecursorStatusHistory[];
}
const DocumentHistoryLogPage: React.FC<Props> = (props: Props) => {
  const { orderDetails, precursorStatusHistory } = props;
  const getFilename = (documentUrl: string): string => {
    const urlSplitted = documentUrl.split('/');
    return urlSplitted[urlSplitted.length - 1];
  };
  return (
    <Container maxWidth="xl">
      <PrecursorDetailTable>
        <thead>
          <tr>
            <th>Pharmacy&apos;s Name</th>
            <th>Approval Name</th>
            <th>Status</th>
            <th>Reupload Date</th>
            <th>Order ID</th>
            <th>Precursor Document</th>
          </tr>
        </thead>
        <tbody>
          {precursorStatusHistory &&
            precursorStatusHistory.length > 0 &&
            precursorStatusHistory.map((data: IPrecursorStatusHistory) => {
              return (
                <>
                  <tr>
                    <td>{orderDetails?.data?.pharmacy.name}</td>
                    <td>
                      {[OrderPrecursorStatus.DOCUMENT_APPROVED, OrderPrecursorStatus.DOCUMENT_REJECTED].includes(
                        data.status,
                      )
                        ? data?.user?.username
                        : ''}
                    </td>
                    <td>{data.status.charAt(0).toUpperCase() + data.status.slice(1)}</td>
                    <td>{moment(data.event_time).format('MMM DD, YYYY')}</td>
                    <td>{data.purchase_order_number}</td>
                    <td>
                      {' '}
                      {!!data.document_url && (
                        <Link href={data.document_url} target="_blank" style={{ color: ColorType.SUCCESS }}>
                          {getFilename(data.document_url)}
                          <Attachment style={{ top: '8px', left: '3px', position: 'relative' }} />
                        </Link>
                      )}
                    </td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </PrecursorDetailTable>
    </Container>
  );
};

export default DocumentHistoryLogPage;
