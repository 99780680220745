import React from 'react';
import CreatableSelect from 'react-select/creatable';

import { defaultStyle } from '../sync-auto-complete.styles';
import { CreatableAutoCompleteProps } from './creatable-auto-complete.interface';

interface Props extends CreatableAutoCompleteProps {}

export const CreatableAutoComplete: React.FC<Props> = ({
  onChange,
  autoFocus,
  height,
  onBlur,
  styles,
  placeholder,
  onInputChange,
  options,
  name,
  value,
  isDisabled = false,
}) => (
  <CreatableSelect
    data-testid={`select-${name}`}
    name={name}
    autoFocus={autoFocus}
    value={value || null}
    onBlur={onBlur}
    maxMenuHeight={height || 300}
    menuPortalTarget={document.body}
    menuPosition="absolute"
    menuPlacement="bottom"
    styles={styles || defaultStyle}
    onChange={onChange}
    onInputChange={onInputChange}
    options={options}
    placeholder={placeholder}
    isDisabled={isDisabled}
  />
);
