/* eslint-disable consistent-return */
import { addReducer } from "reactn"
import { SwipeRxPt } from "common/services/apis/v3";
import { recordException } from "utils/Reporting/Sentry";
import { RootState } from "..";
import { PharmacyIdentity } from "./types/pharmacy-identity.type";
import { MarketingRewardsState } from "./interfaces";

export const MARKETING_REWARDS_INITIAL_STATE: MarketingRewardsState = {
  pharmacy_search: {
    collection: [],
    selected: null,
  },
  rewards: [],
  loading: false,
}

addReducer('searchPharmacies', async (_, dispatch, term) => {
  try {
    const { data } = await SwipeRxPt.instance.marketingRewards.searchPharmacies({
      term,
      fields: ['id', 'name'],
      page: 1,
      page_size: 10,
      sort_by: 'asc',
      order_by: 'pharmacy_id',
    });

    dispatch.setPharmacySearch(data as PharmacyIdentity[]);
  } catch (error) {
    recordException(error, 'searchPharmacies', { term });
  }
});

addReducer('cancelRequestRewards', (_1, _2, isCancelRequest) => {
  if (isCancelRequest) {
    SwipeRxPt.instance.marketingRewards.cancelRequest();
  }
});

addReducer(
  'setPharmacySearch',
  (state, _, data): RootState => {
    const { marketingRewards } = state;
    const { pharmacy_search } = marketingRewards;

    return {
      ...state,
      marketingRewards: {
        ...marketingRewards,
        pharmacy_search: {
          ...pharmacy_search,
          collection: data,
        },
      },
    };
  },
);

addReducer('setPharmacySearchSelected', (state, _, selected): RootState => {
  const { marketingRewards } = state;
  const { pharmacy_search } = marketingRewards;

  return {
    ...state,
    marketingRewards: {
      ...marketingRewards,
      pharmacy_search: {
        ...pharmacy_search,
        selected,
      },
    }
  }
});