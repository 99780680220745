import { useState } from 'reactn';

import { useUpdateQueryParams } from 'hooks';
import { PaymentMethodType } from 'types/PaymentMethod.type';
import { PaymentMethod } from 'common/constants';

type updatePaymentFilter = (method?: PaymentMethodType) => void;

type useTransactionPaymentFilter = () => {
  updatePaymentFilter: updatePaymentFilter;
};

export const useTransactionPaymentFilter: useTransactionPaymentFilter = () => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType | undefined>(undefined);

  const { queryParams, updateQueryParams } = useUpdateQueryParams();

  const updatePaymentFilter: updatePaymentFilter = (method?: PaymentMethodType): void => {
    const paymentMethodFilter = method || PaymentMethod.CREDIT_LINE;
    setPaymentMethod(paymentMethodFilter);

    delete queryParams.precursor_page; // case in transaction page
    delete queryParams.precursor_status;
    delete queryParams.only_document_pending;
    updateQueryParams({ page: '1', payment_method: paymentMethodFilter, status: null } as Record<string, any>);
  };

  return { paymentMethod, updatePaymentFilter };
};
