import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { useGetAuthenticatedUser } from 'hooks';

import { ButtonToggle, Input } from 'common/components';

import { UserRoles } from 'common/constants';
import { S } from './OrderItemFlagInputs.styles';

interface Props {
  index: number;
  values: {
    is_out_of_stock: boolean;
    stock_limit: string | null;
    price_change: string | null;
    discount_rate_change: string | null;
  };
  onChange: (
    index: number,
    name: string,
    value: boolean | string | null,
  ) => void;
}

type InputName = 'stock_limit' | 'price_change';

export const OrderItemFlagInputs: React.FC<Props> = ({
  index,
  values,
  onChange,
}) => {
  const { userType } = useGetAuthenticatedUser();

  const isDistributorStaff = userType === UserRoles.DISTRIBUTOR_STAFF
  const distributorStaffStyle = { cursor: 'not-allowed'};

  const [toggleState, setToggleState] = useState({
    hasStockLimit: false,
    hasPriceChange: false,
    hasDiscountRateChange: false,
  });

  const [inputValues, setInputValues] = useState({
    stock_limit: values.stock_limit,
    price_change: values.price_change,
    discount_rate_change: values.discount_rate_change,
  });

  useEffect(() => {
    setToggleState((prev) => ({
      ...prev,
      hasStockLimit: prev.hasStockLimit ? true : !!values.stock_limit,
      hasPriceChange: prev.hasPriceChange ? true : !!values.price_change,
      hasDiscountRateChange: prev.hasDiscountRateChange
        ? true
        : !!values.discount_rate_change,
    }));

    setInputValues((prev) => ({
      ...prev,
      stock_limit: !values.stock_limit ? prev.stock_limit : values.stock_limit,
      price_change: !values.price_change
        ? prev.price_change
        : values.price_change,
      discount_rate_change: !values.discount_rate_change
        ? prev.discount_rate_change
        : values.discount_rate_change,
    }));
  }, [values]);

  const handleStockoutChange = (): void => {
    onChange(index, 'is_out_of_stock', !values.is_out_of_stock);
  };

  const handleToggleChange = (value: boolean, name?: string): void => {
    setToggleState((prev) => {
      let valueFieldName = '';

      switch (name) {
        case 'hasStockLimit':
          valueFieldName = 'stock_limit';
          break;
        case 'hasPriceChange':
          valueFieldName = 'price_change';
          break;
        case 'hasDiscountRateChange':
          valueFieldName = 'discount_rate_change';
          break;
        default:
          break;
      }

      if (!value) {
        onChange(index, valueFieldName, null);
      } else {
        onChange(
          index,
          valueFieldName,
          inputValues[valueFieldName as InputName],
        );
      }

      return {
        ...prev,
        [name as string]: value,
      };
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name as InputName;
    let value = e.target.value || null;

    if (Number.isNaN(Number(value))) {
      return;
    }

    if (value && name === 'stock_limit') {
      value = parseInt(value, 10).toString();
    }

    onChange(index, e.target.name, value);

    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const { hasDiscountRateChange, hasPriceChange, hasStockLimit } = toggleState;

  return (
    <S.Wrapper
      container
      direction="column"
      justify="center"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item>
        <ButtonToggle
          disabled={hasStockLimit || hasPriceChange || hasDiscountRateChange}
          value={values.is_out_of_stock}
          text="Out of Stock"
          onChange={handleStockoutChange}
        />
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <ButtonToggle
              disabled={values.is_out_of_stock}
              value={hasStockLimit}
              text="Change Quantity"
              name="hasStockLimit"
              onChange={handleToggleChange}
            />
          </Grid>
          <Grid item>
            <Input
              disabled={!hasStockLimit}
              name="stock_limit"
              placeholder="New Qty"
              value={inputValues.stock_limit || ''}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <ButtonToggle
              disabled={isDistributorStaff || values.is_out_of_stock}
              value={hasPriceChange}
              text="Change Price"
              name="hasPriceChange"
              onChange={handleToggleChange}
              style={isDistributorStaff ? distributorStaffStyle : {}}
            />
          </Grid>
          <Grid item>
            <Input
              disabled={!hasPriceChange}
              name="price_change"
              placeholder="New Price"
              value={inputValues.price_change || ''}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <ButtonToggle
              disabled={isDistributorStaff || values.is_out_of_stock}
              value={hasDiscountRateChange}
              text="Change Discount Rate"
              name="hasDiscountRateChange"
              onChange={handleToggleChange}
              style={isDistributorStaff ? distributorStaffStyle : {}}
            />
          </Grid>
          <Grid item>
            <Input
              disabled={!hasDiscountRateChange}
              name="discount_rate_change"
              placeholder="New Discount Rate"
              value={inputValues.discount_rate_change || ''}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </S.Wrapper>
  );
};
