import React, { useState, useEffect } from 'reactn';

import QRCode from 'qrcode.react';
import { Document, PDFViewer, Font } from '@react-pdf/renderer';

import { ShowIf } from 'common/components';
import { TemplateOptions } from 'pages/PrintPage/Templates.constants';
import { LoadingComponent } from 'pages/PrintPage/components';

import { useWindowSize } from '../../helper';

import { DeliveryProofV2Props } from './types';
import { Invoice } from './components/invoice';
import { DeliveryInformation } from './components/delivery-information';

export const DeliveryProofV2: React.FC<DeliveryProofV2Props> = (props) => {
  const { match } = props;
  const [width, height] = useWindowSize();
  const [isLoading, setLoading] = useState(true);

  const { orders } = props;

  const {
    params: { template, id, marketId },
  } = match;

  useEffect(() => {
    Font.register({
      family: 'Sarabun',
      fonts: [
        { src: `/fonts/Sarabun-Regular.ttf` },
        {
          src: `/fonts/Sarabun-Bold.ttf`,
          fontWeight: 'bold',
        },
        {
          src: `/fonts/Sarabun-Light.ttf`,
          fontWeight: 'thin',
        },
        {
          src: `/fonts/Sarabun-Italic.ttf`,
          fontWeight: 'normal',
          fontStyle: 'italic',
        },
      ],
    });

    // Workaround to prevent the case the font loading is unfinished
    setTimeout(() => setLoading(false), 1500);
  }, []);

  return (
    <div style={{ width, height, backgroundColor: '#ffffff', zIndex: 2, position: 'absolute', top: 0, left: 0 }}>
      {orders.map((order) => {
        return (
          <div key={order.id}>
            <QRCode
              value={`PT-INV-${order?.po_number}`}
              size={200}
              id={`qrcode-invoice-${order.id}`}
              style={{ zIndex: 0, display: 'none' }}
            />
            <QRCode
              className="qr-code"
              value={`PT-DLV-${order?.po_number}`}
              size={200}
              id={`qrcode-delivery-${order.id}`}
              style={{ zIndex: 0, display: 'none' }}
            />
          </div>
        );
      })}
      <ShowIf condition={isLoading}>
        <LoadingComponent />
      </ShowIf>
      <ShowIf condition={!isLoading}>
        <div style={{ zIndex: 99, position: 'absolute', top: 0, left: 0 }}>
          <PDFViewer width={width} height={height} key={Math.random()}>
            <Document title={`${marketId}-${template}-${id}`}>
              {orders.map((order) => {
                const info = { ...props, order };
                return (
                  <>
                    {template !== TemplateOptions.PRINT_DELIVERY_PROOF && (
                      <>
                        <Invoice subtitle="Original Invoice / Delivery Note" {...info} />
                        <Invoice subtitle="Copy Invoice / Delivery Note" {...info} />
                      </>
                    )}
                    {(template === TemplateOptions.PRINT_DELIVERY_DOCS ||
                      template === TemplateOptions.PRINT_DELIVERY_PROOF) && <DeliveryInformation {...info} />}
                  </>
                );
              })}
            </Document>
          </PDFViewer>
        </div>
      </ShowIf>
    </div>
  );
};
