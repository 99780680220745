/* eslint-disable import/no-duplicates */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

import React, { useCallback, useDispatch, useEffect, useGlobal, useMemo, useState } from 'reactn';

import moment from 'moment';

import { useUpdateQueryParams, useGetAuthenticatedUser } from 'hooks';
import { ColorType, TransactionActionName } from 'types';

import { ButtonToggleFilter } from 'common/components/ButtonToggleFilter';
import { DataTable, PageWrapper, Snackbar, TableFilterCard, DistributorTitle, ShowIf } from 'common/components';

import { TabFilterPayment } from 'common/components/TabFilterPayment';
import { CardTableActionSelectHandler } from 'common/components/CardTable/types';
import { SearchWithCriteria, CriteriaOption, Criteria } from 'common/components/SearchRelated';
import {
  userRolesWithPartialTransactionAccess,
  CREDIT_SOURCE_MODALKU,
  CREDIT_SOURCE_DIGIO,
  PAYMENT_METHOD_CREDIT_LINE,
  PAYMENT_METHOD_DIRECT_PAYMENT,
  PaymentMethod,
  TRANSACTIONS_VIEW_ALL_DISTRIBUTOR_ROLES,
} from 'common/constants';
import { getTransactionBulkActionItems } from 'common/utils';
import { PaymentMethodType } from 'types/PaymentMethod.type';

import { Route, Switch } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { TabContext, TabPanel } from '@material-ui/lab';
import { ActionMenuItem } from 'common/components/ActionMenu/interfaces';
import {
  useTransactionBulkAction,
  useTransactionDataTableColumns,
  useTransactionDataTableRowFooter,
  useTransactionSearchFilter,
  useTransactionStatusFilter,
  useTransactionPaymentFilter,
  PrekursorFilterValues,
} from './hooks';
import * as C from './components';
import { OrderDataBlank } from './components/order-data-table-blank/order-data-table-blank';
import { PrecursorDetail } from './components';

interface IDefaultTransactionComponent {
  bulkActions: ((ActionMenuItem & {
    name: TransactionActionName;
  }) | undefined)[];
  defaultActions: ((ActionMenuItem & {
    name: TransactionActionName;
  }) | undefined)[];
  handleBulkActionSelect: CardTableActionSelectHandler;
}

const DefaultTransactionComponent: React.FC<IDefaultTransactionComponent> = ({
  bulkActions,
  handleBulkActionSelect,
  defaultActions,
}) => {
  const [{ market }] = useGlobal('config');
  const { queryParams } = useUpdateQueryParams();
  const {
    status,
    po_numbers,
    flag_reason,
    invoice_number,
    pharmacy_name,
    pharmacy_id,
    bill_reference,
    payment_method,
    distributor_id,
    ordered_at,
  } = queryParams;
  let default_payment_method = payment_method;
  const setTransactionError = useDispatch('setTransactionError');

  const multipleSelectPrefix = ['PT-DLV-', 'PT-INV-', 'PT-PO-'];
  const multipleSelectSeparator = ' ';
  const multipleSelectCriteria = 'po_number';
  const searchCriteria: CriteriaOption[] = [
    {
      name: 'PO',
      id: 'po_number',
      isArray: true,
      parameterMapping: 'po_numbers',
    },
    { name: 'Invoice', id: 'invoice_number' },
    { name: 'Pharmacy Name', id: 'pharmacy_name' },
    { name: 'Pharmacy ID', id: 'pharmacy_id' },
    { name: 'Order Date (DD-MMM-YYYY)', id: 'ordered_at' },
    { name: 'Flag Reason', id: 'flag_reason' },
  ];
  if (market.activations.includes(CREDIT_SOURCE_DIGIO)) {
    searchCriteria.push({ name: 'Bill Reference', id: 'bill_reference' });
    searchCriteria.push({ name: 'Transaction ID', id: 'bill_code' });
  }
  const [{ loading }] = useGlobal('transactions');
  const {
    statusFilterItems,
    prekursorFilterItems,
    selectedPrekursorFilter,
    updateStatusFilter,
    updatePrekursorFilter,
  } = useTransactionStatusFilter();

  const handleSearchbarChange = (searchCriterias?: Criteria[]): void => {
    const poNumbersArr = searchCriterias?.filter((item) => item.id === 'po_number').map((item) => item.value);

    if (poNumbersArr && poNumbersArr.length >= 50) {
      setTransactionError({ error: 'Maximum of 50 PO Number filters only' });
    }
  };

  const handleStatusFilterChange = (value?: string): void => {
    updateStatusFilter(value);
  };

  const handlePrekursorFilterChange = (value?: string): void => {
    updatePrekursorFilter(value as PrekursorFilterValues);
  };

  const handleSearch = (): void => {
    handleStatusFilterChange(status?.toString());
  };

  const columns = useTransactionDataTableColumns();
  const rowFooter = useTransactionDataTableRowFooter();

  const selectable = !!bulkActions.length;

  const getDefaultPaymentMethod = (): PaymentMethodType | undefined => {
    const paymentMethodParam = queryParams.payment_method;
    if (Object.values(PaymentMethod).includes(paymentMethodParam as PaymentMethod)) return paymentMethodParam;

    if (market.activations.includes(CREDIT_SOURCE_DIGIO)) {
      default_payment_method = PAYMENT_METHOD_DIRECT_PAYMENT;
    }

    if (market.activations.includes(CREDIT_SOURCE_MODALKU)) {
      default_payment_method = PAYMENT_METHOD_CREDIT_LINE;
    }

    return default_payment_method;
  };

  return (
  <>
    <TableFilterCard>
      <SearchWithCriteria
        searchCriteriaOptions={searchCriteria}
        multipleSelectCriteria={multipleSelectCriteria}
        multipleSelectPrefix={multipleSelectPrefix}
        multipleSelectSeparator={multipleSelectSeparator}
        onChangeCriteria={handleSearchbarChange}
        onSearch={handleSearch}
      />
      <ButtonToggleFilter
        data={statusFilterItems}
        label="Status"
        onChange={handleStatusFilterChange}
        value={status as string}
      />
      <ButtonToggleFilter
        data={prekursorFilterItems}
        label="Filter"
        onChange={handlePrekursorFilterChange}
        value={selectedPrekursorFilter}
      />
    </TableFilterCard>
    <ShowIf condition={!queryParams.status}>
      <OrderDataBlank />
    </ShowIf>
    <ShowIf condition={!!queryParams.status}>
      <DataTable
        config={{
          key: status ? `${status}-orders` : 'orders',
          columns,
          rowFooter,
          selectable,
          actions: {
            items: bulkActions,
            onSelect: handleBulkActionSelect,
          },
          /* buttons: {
          items: bulkActionButtons,
          onClick: handleBulkActionButton,
        }, */
          isAllSelectable: status ? status !== 'all' : true,
          isNoCount: true,
          isNoRow: true,
        }}
        isActionLoading={loading}
        name="orders"
        initialParams={{
          distributor_id,
          status,
          po_numbers,
          flag_reason,
          pharmacy_name,
          ordered_at,
          invoice_number,
          pharmacy_id,
          bill_reference,
          payment_method: getDefaultPaymentMethod(),
        }}
        defaultActionItems={defaultActions}
      />
    </ShowIf>
  </>
)};

const TransactionComponent: React.FC = () => {
  const [{ market, marketId }] = useGlobal('config');
  const [showFilterPayment, setShowFilterPayment] = useState(false);
  const [onMount, setOnMount] = useState(false);
  const { queryParams, updateQueryParams, clearQueryParams } = useUpdateQueryParams();
  const {
    status,
    detail,
    order_id,
    po_numbers,
    flag_reason,
    invoice_number,
    pharmacy_name,
    pharmacy_id,
    bill_reference,
    bill_code,
    precursor_status,
  } = queryParams;

  let { distributor_id, ordered_at } = queryParams;
  const { payment_method, precursor_page } = queryParams;
  const [tabValue, setTabValue] = useState<
    PaymentMethodType | PrekursorFilterValues
  >(((precursor_page as PrekursorFilterValues) || payment_method) || PaymentMethod.CREDIT_LINE);
  let default_payment_method = payment_method;
  ordered_at = ordered_at ? moment(ordered_at, 'DD-MMM-YYYY').format() : null;
  const user = useGetAuthenticatedUser();

  const userHasPartialAccess = userRolesWithPartialTransactionAccess.includes(user.userType);

  const [{ distributor, error, warning, successMessage }] = useGlobal('transactions');
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore how to fix this? FIXME: 'Type instantiation is excessively deep and possibly infinite.ts(2589)'
  const setTransactionError = useDispatch('setTransactionError');
  const setTransactionWarning = useDispatch('setTransactionWarning');
  const setTransactionSuccessMessage = useDispatch('setTransactionSuccessMessage');
  const resetTransactionRowCount = useDispatch('resetTransactionRowCount');

  const processTransactionBulkActionSelect = useTransactionBulkAction();
  // const processTransactionBulkActionButton = useTransactionBulkActionButton();
  const { updateSearchFilter } = useTransactionSearchFilter();
  const {
    updatePrekursorFilter,
  } = useTransactionStatusFilter();
  const { updatePaymentFilter } = useTransactionPaymentFilter();

  const removeDetailModalParams = useCallback(() => {
    updateQueryParams({
      detail: null,
      order_id: null,
    });
  }, [updateQueryParams]);

  if (userHasPartialAccess) {
    if (user.organization_id && Number(distributor_id) !== user.organization_id) {
      distributor_id = user.organization_id.toString();
    }
  }

  useEffect(() => {
    if (!detail || !order_id) {
      removeDetailModalParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (distributor.error) {
      clearQueryParams();
    }
  }, [clearQueryParams, distributor.error]);

  useEffect(() => {
    if (market.activations.includes(CREDIT_SOURCE_MODALKU)) {
      setShowFilterPayment(true);
    }

    updateQueryParams({
      payment_method,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market.activations]);

  const getDefaultPaymentMethod = (): PaymentMethodType | undefined => {
    const paymentMethodParam = queryParams.payment_method;
    if (Object.values(PaymentMethod).includes(paymentMethodParam as PaymentMethod)) return paymentMethodParam;

    if (market.activations.includes(CREDIT_SOURCE_DIGIO)) {
      default_payment_method = PAYMENT_METHOD_DIRECT_PAYMENT;
    }

    if (market.activations.includes(CREDIT_SOURCE_MODALKU)) {
      default_payment_method = PAYMENT_METHOD_CREDIT_LINE;
    }

    return default_payment_method;
  };

  const handleMessageClose = (): void => {
    setTransactionError({ error: null });
    setTransactionSuccessMessage({ message: null });
  };
  const handleMessageWarningClose = (): void => {
    setTransactionWarning({ warning: null });
  };

  const handleBulkActionSelect: CardTableActionSelectHandler = (name, ids): void => {
    processTransactionBulkActionSelect(
      name,
      ids.map((id) => parseInt(id, 10)),
    );
  };

  const bulkActionItems = useMemo(() => {
    if (precursor_page) {
      return getTransactionBulkActionItems(user.userType, 'precursor').map((item) => item?.actionItem);
    } 
    if (status === 'pending' && payment_method === PAYMENT_METHOD_DIRECT_PAYMENT && marketId === 'id') {
      return getTransactionBulkActionItems(user.userType, status as string)
        .map((item) => item?.actionItem)
        .filter((item) => !['cancel', undefined].includes(item?.name));
    }

    return getTransactionBulkActionItems(user.userType, (status as string) || 'all').map((item) => item?.actionItem);
  }, [user.userType, status, payment_method, marketId, precursor_page, precursor_status]);

  const showDetailModal = detail === 'order' && !!order_id;

  const handlePaymentTabChange = (method: PaymentMethodType | PrekursorFilterValues): void => {
    setTabValue(method);
    if (Object.values(PrekursorFilterValues).includes(method as PrekursorFilterValues)) {
      updatePrekursorFilter(method as PrekursorFilterValues);
      return;
    }
    updatePaymentFilter(method as PaymentMethodType);
  };

  const defaultActionItems = useMemo(
    () => getTransactionBulkActionItems(user.userType, 'all', status).map((item) => item?.actionItem),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.userType, status],
  );

  const distributorId = !TRANSACTIONS_VIEW_ALL_DISTRIBUTOR_ROLES.includes(user.userType)
    ? user?.organization_id
    : distributor_id?.toString() || null;

  const defaultPaymentMethod = getDefaultPaymentMethod();
  useEffect(() => {
    if (
      (queryParams.status ||
        queryParams.precursor_status ||
        po_numbers ||
        invoice_number ||
        pharmacy_id ||
        pharmacy_name ||
        ordered_at ||
        flag_reason ||
        bill_reference ||
        bill_code ||
        distributor_id) &&
      defaultPaymentMethod
    ) {
      resetTransactionRowCount({});
      updateSearchFilter(
        {
          po_numbers: po_numbers?.toString(),
          invoice_number: invoice_number?.toString(),
          pharmacy_id: pharmacy_id?.toString(),
          pharmacy_name: pharmacy_name?.toString(),
          ordered_at: ordered_at?.toString(),
          flag_reason: flag_reason?.toString(),
          bill_reference: bill_reference?.toString(),
          bill_code: bill_code?.toString(),
          distributor_id: distributorId?.toString(),
          payment_method: payment_method ? payment_method?.toString() : defaultPaymentMethod,
        },
        onMount,
      );
      if (!onMount) {
        setOnMount(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryParams.status,
    queryParams.only_document_pending,
    queryParams.precursor_status,
    po_numbers,
    invoice_number,
    pharmacy_id,
    pharmacy_name,
    ordered_at,
    flag_reason,
    bill_reference,
    bill_code,
    defaultPaymentMethod,
  ]);

  if (market.activations.length === 0) return null;
  return (
    <TabContext value={tabValue}>
      <PageWrapper transparent name="Transactions" title={<DistributorTitle page="TRANSACTIONS" />}>
        <ShowIf condition={showFilterPayment}>
          <TabFilterPayment
            value={tabValue}
            onChange={handlePaymentTabChange}
            enablePrekursorFilter
          />
        </ShowIf>

        <TabPanel value={PrekursorFilterValues.PREKURSOR_PAGE}>
          <C.PrekursorApproval
            queryParams={queryParams}
            bulkActions={bulkActionItems}
            defaultActions={defaultActionItems}
            handleBulkActionSelect={handleBulkActionSelect}
          />
        </TabPanel>
        <TabPanel value={PaymentMethod.CREDIT_LINE}>
          <DefaultTransactionComponent
            bulkActions={bulkActionItems}
            defaultActions={defaultActionItems}
            handleBulkActionSelect={handleBulkActionSelect}
          />
        </TabPanel>
        <TabPanel value={PaymentMethod.DIRECT_PAYMENT}>
          <DefaultTransactionComponent
            bulkActions={bulkActionItems}
            defaultActions={defaultActionItems}
            handleBulkActionSelect={handleBulkActionSelect}
          />
        </TabPanel>
      </PageWrapper>
      <ShowIf condition={showDetailModal}>
        <C.OrderDetailsModal />
      </ShowIf>
      <C.OrderInvoiceModalV0 />
      <C.OrderInvoiceModalV1 />
      <C.OrderReassignModal />
      <C.OrderCancelModal />
      <C.OrderDelayModal />
      <C.OrderInvoiceUpdateModal />
      <C.OrderRollbackModal />
      <C.BulkActionConfirmationModal />
      <C.AcceptOrderModal />
      <C.RestoreOrderModal />
      <C.MergeOrderModal />
      <C.OrderDeliverModal />
      <C.OrderFulfillmentModal />
      <C.OrderReturnModalContainer />
      <C.OrderExportToDeliveryPartnerModal />
      {/* <C.UpdateTrackingNumberModal /> */}
      {/* <C.OrderUpdateTrackingNumber /> */}
      {/* <C.UpdateDeliveryStatusModal /> */}
      <Snackbar
        open={!!successMessage}
        color={ColorType.PRIMARY}
        message={successMessage}
        onClose={handleMessageClose}
      />
      <Snackbar open={!!error} color={ColorType.DANGER} message={error} onClose={handleMessageClose} />
      <Snackbar
        open={!!warning}
        contentStyle={{
          maxHeight: '30vh',
          overflowY: 'auto',
          whiteSpace: 'pre-line',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        color={ColorType.WARNING}
        message={warning}
        onClose={handleMessageWarningClose}
      />
    </TabContext>
  );
};
export const TransactionsPage: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={TransactionComponent} />
        <Route
          exact
          path={`${path}/precursor-detail/:id`}
          render={(props) => {
            return <PrecursorDetail previousPath={props.history.location.state?.previousPath} {...props} />;
          }}
        />
      </Switch>
    </>
  );
};

export default TransactionsPage;
