import apiClient from '../services/apiClient.service';
import orderService from '../services/apis/v2/services/order.service';

export default {
  state: {
    orders: {
      result: [{}],
      count: 0,
      pages: 1,
    },
    order: {},
  },
  reducers: {
    setOrders(state, payload) {
      return { ...state, orders: payload };
    },
    setOrder(state, payload) {
      return { ...state, order: payload };
    },
  },
  effects: (dispatch) => ({
    // API - Related Middlewares
    async getActualOrders(
      { rowsPerPage = 10, page = 0, filters = {} },
      rootState,
    ) {
      try {
        const result = await orderService.get(rowsPerPage, page, filters);
        dispatch.orderStore.setOrders(result);
      } catch (e) {
        console.error(e);
        alert(e.message);
      }
    },

    async getActualOrder({ id }, rootState) {
      try {
        const result = await orderService.getOne({ id });
        dispatch.orderStore.setOrder(result);
        // console.log(result)
      } catch (e) {
        console.error(e);
        alert(e.message);
      }
    },

    // Deprecated none-API Middlewares
    async getOrderList({ page = 1, query = '' }, rootState) {
      try {
        await orderService.get();
        // console.log("ACTUAL RESPONSE", resultnew);

        const result = await apiClient.get(`/admin/orders`, {
          page,
          query,
        });
        // console.log("ORIGINAL RESPONSE", result);
        dispatch.orderStore.setOrders(result);
      } catch (e) {
        console.error(e);
        alert(e.message);
      }
    },

    async getOrderSpecific({ id }, rootState) {
      try {
        const result = await apiClient.get(`/admin/orders/${id}`);
        dispatch.orderStore.setOrder(result);
        // console.log(result)
      } catch (e) {
        console.error(e);
        alert(e.message);
      }
    },
  }),
};
