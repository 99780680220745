import React, { useDispatch, useState } from 'reactn';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';

import { formatToDatetime } from 'common/utils/formatters';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { S } from './OrderDetailsModal.styles';
import { ConfirmationDialog, ShowIf } from 'common/components';
import { Typography } from '@material-ui/core';

interface Props {
  poNumber: string;
  orderDate: string;
  awbNumber?: string;
  isOrderDateEditable?: boolean;
}

export const BasicOrderDetails: React.FC<Props> = ({ poNumber, orderDate, isOrderDateEditable, awbNumber = '-' }) => {
  const patchOrder = useDispatch('patchOrder');

  const [orderDateValue, setOrderDate] = useState<MaterialUiPickersDate>(moment(orderDate));
  const [newOrderDate, setNewOrderDate] = useState<MaterialUiPickersDate | null>(null);

  const handleOrderDateChange = (date: MaterialUiPickersDate): void => {
    if (date) {
      setNewOrderDate(date);
    }
  };

  const closeOrderDateChangeConfirmation = (): void => {
    setNewOrderDate(null);
  };

  const confirmOrderDateChange = (): void => {
    if (newOrderDate) {
      setOrderDate(newOrderDate);
      patchOrder({
        ordered_at: newOrderDate.toISOString(),
      });
    }

    closeOrderDateChangeConfirmation();
  };

  return (
    <S.BasicDetailsWrapper>
      <Grid container direction="row" justify="flex-start" alignItems="center" spacing={4}>
        <Grid item>
          <S.Label>PO Number</S.Label>
          <span>{poNumber}</span>
        </Grid>
        <Grid item>
          <S.Label>Order Date</S.Label>
          {isOrderDateEditable ? (
            <KeyboardDateTimePicker
              id="order-date"
              value={orderDateValue}
              showTodayButton
              ampm={false}
              format="YYYY/MM/DD H:mm"
              onChange={handleOrderDateChange}
              data-testid="edit-order-date"
            />
          ) : (
            <span>{formatToDatetime(orderDate)}</span>
          )}
        </Grid>
        <Grid item>
          <S.Label>AWB Number</S.Label>
          <span>{awbNumber}</span>
        </Grid>
      </Grid>

      <ShowIf condition={Boolean(newOrderDate)}>
        <ConfirmationDialog
          title="Are you sure to change order date?"
          message={
            <Typography>
              This action will cause delivery estimation recalculation
              <br />
              <b>The recalculation cannot be undone.</b>
            </Typography>
          }
          options={{
            accept: 'Confirm',
            cancel: 'Cancel',
            variant: 'danger',
          }}
          onAccept={confirmOrderDateChange}
          onCancel={closeOrderDateChangeConfirmation}
        />
      </ShowIf>
    </S.BasicDetailsWrapper>
  );
};
