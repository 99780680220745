import styled from 'styled-components';
const Information = styled.span`
  && {
    color: #000000;
  }
`;

const ActionInformation = styled.span`
  && {
    color: #40ae96;
    font-weight: bold;
    cursor: pointer;
  }
`;

const InformationContainer = styled.div`
  && {
    background-color: #fef9e8;
    padding: 0px 12px;
  }
`;

export const S = {
  Information,
  ActionInformation,
  InformationContainer,
};
