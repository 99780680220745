import { IDeposit } from './interfaces/deposit.interfaces';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../..';
import { ISearchable, ListResponse, ISearchParam } from '../../interfaces';
import { IDepositList } from './interfaces/deposit-list.interface';
import { DepositReportParams } from './interfaces/deposit-report-params.interface';
import { DepositCreateParams } from './interfaces/deposit-create-params.interface';

export class SwipeRxPtDeposit implements ISearchable<IDeposit> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.DEPOSIT;
  }

  async list(params: any): Promise<IDepositList> {
    return this.base.get(this.resource, params);
  }

  async create(params: DepositCreateParams): Promise<IDepositList> {
    return this.base.post(this.resource, params);
  }

  async approve(depositId: number): Promise<void> {
    await this.base.patch(`${this.resource}/${depositId}/approve`, {});
  }

  async decline(depositId: number): Promise<void> {
    await this.base.patch(`${this.resource}/${depositId}/decline`, {});
  }

  async delete(depositId: number, reason: string): Promise<void> {
    await this.base.patch(`${this.resource}/${depositId}/delete`, { delete_reason: reason });
  }

  async total(pharmacyId: number): Promise<{ total_payments: number }> {
    const { data } = await this.base.get(`${this.resource}/${pharmacyId}/total`);

    return data;
  }

  async check(pharmacyId: number, amount: number): Promise<IDeposit[]> {
    const { data } = await this.base.get(`${this.resource}/check-amount`, {
      pharmacy_id: pharmacyId,
      amount,
    });

    return data;
  }

  async search(params: ISearchParam): Promise<ListResponse<IDeposit>> {
    return this.base.get(this.resource, { ...params });
  }

  async report(params: DepositReportParams): Promise<any> {
    return this.base.get(`${this.resource}/report`, params);
  }

  async uploadProof(params: { file: File; pharmacyId: number }): Promise<any> {
    const { file, pharmacyId } = params;
    const form = new FormData();

    form.append('file', file);

    return this.base.post(`${this.resource}/upload-proof?pharmacy_id=${pharmacyId}`, form, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }
}
