import React from 'react';
import { Route, Redirect } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import { UserRoles } from 'common/constants';

import { RouteConfigOptions } from './routes.interface';
import { getMarketPath, IMarketParams } from '../utils/get-router-path';
import { DEFAULT_MARKET_ID } from '../common/services/constants';

interface Props {
  authenticated: boolean;
  route: RouteConfigOptions;
  userRole: UserRoles;
  exact: boolean;
}

export const ProtectedRoute: React.FC<Props> = ({ authenticated, route, exact, userRole }) => {
  const { params } = useRouteMatch<IMarketParams>();
  const isUserAdmin = userRole === UserRoles.ADMIN || userRole === UserRoles.SUPER_ADMIN;

  const isRouteRoleSpecific = route.roleAccess && route.roleAccess.includes(userRole);

  const prefix = getMarketPath(params.marketId || DEFAULT_MARKET_ID);

  if (!authenticated) {
    const path = `${prefix}/login`;
    return <Redirect to={path} />;
  }

  if (isUserAdmin) {
    return <Route path={route.path} component={route.component} exact={exact} />;
  }

  if (isRouteRoleSpecific) {
    return <Route path={route.path} component={route.component} exact={exact} />;
  }

  if (userRole === UserRoles.SALES_SUPERVISOR) {
    return <Redirect to={`${prefix}/pharmacies`} />; // based on sideBarMenuConfig
  }

  return null;
};
