import styled from 'styled-components';

import { wrapperStyle } from '../Pagination.styles';

const Wrapper = styled.span`
  color: #717786;
  font-size: 12px;
`;

const WrapperLiteral = styled.span`
  ${wrapperStyle}
  && {
    display: flex;
  }
`;

const TextLiteral = styled.span`
  && {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const S = {
  Wrapper,
  WrapperLiteral,
  TextLiteral,
};
