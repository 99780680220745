import { CardTableRowFooter } from 'common/components/CardTable/types';

import { getOrderElapsedTimeElement } from 'common/utils';
import { DataObject } from 'types';


import { useTransactionRowAction } from './use-transaction-row-action.hook';

export const usePrekursorApprovalDataTableRowFooter = (): CardTableRowFooter => {
  const processTransactionRowAction = useTransactionRowAction();

  const handleRowActionClick = (name: string, data: DataObject): void => {
    processTransactionRowAction(name, data);
  };

  const rowFooter: CardTableRowFooter = ({
    invoice,
    ordered_at,
    status_history,
    status,
    payment_method,
    payment_status,
  }: DataObject) => {
    const { labelElement: primaryLabelElement, labelType: primaryLabelType } = getOrderElapsedTimeElement(
      status as string,
      status_history,
      ordered_at,
      payment_method,
      invoice,
      payment_status,
    );

    return {
      actions: [],
      onActionClick: handleRowActionClick,
      primaryLabel: {
        id: 'elapsed_time',
        custom: true,
        type: primaryLabelType,
        node: primaryLabelElement,
      },
    };
  };

  return rowFooter;
};
