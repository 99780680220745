import React from 'reactn';

import {
  Button,
  Dialog,
  FieldWrapper,
  BulkActionItemCardMapper,
} from 'common/components';
import { ColorType } from 'types';

import { useOrderRollbackForm } from './use-order-rollback-form';
import { fieldOptions } from './order-rollback-form.config';
import {S} from './order-rollback-modal.style'

const formId = 'order-rollback-form';

export const OrderRollbackModal: React.FC = () => {
  const {
    form,
    rollback: { orderDetails, orderIds },
    clearOrderIds,
    selectedReason,
    setSelectedReason,
  } = useOrderRollbackForm();

  const renderFieldOptions = fieldOptions.map(({ name, component: Field, label, options }) => {
    const handleChange = (option: {
      value: string;
      label: string;
    }): void => {
      form.setFieldValue(name, option.value);
      setSelectedReason(option);
    };

    const handleInputChange = (inputValue: string): void => {
      if (!inputValue) return;
      form.setFieldValue(name, inputValue);
    };

    return (
      <FieldWrapper
        key={name}
        touched={form.touched[name]}
        error={form.errors[name]}
        label={label}
      >
        <Field
          value={selectedReason}
          name={name}
          onChange={handleChange}
          options={options}
          onInputChange={handleInputChange}
          onBlur={form.handleBlur}
        />
      </FieldWrapper>
    );
  })

  const renderContent = (): JSX.Element => (
    <>
      <BulkActionItemCardMapper data={orderDetails} />
      <S.Form>
        <form id={formId} onSubmit={form.handleSubmit}>
          {renderFieldOptions}
        </form>
      </S.Form>
    </>
  );

  const renderActions = (): JSX.Element => (
    <Button
      color={ColorType.PRIMARY}
      disabled={!form.dirty || form.isSubmitting}
      loading={form.isSubmitting}
      form={formId}
      type="submit"
    >
      Submit
    </Button>
  );

  return (
    <Dialog
      open={!!orderIds.length}
      primary
      title={orderIds.length > 1 ? 'Rollback Orders' : 'Rollback Order'}
      onClose={clearOrderIds}
      renderActions={renderActions}
      renderContent={renderContent}
      subTitle={orderIds.length > 1 ? 'Selected' : 'Order ID'}
      value={
        orderIds.length > 1
          ? `${orderIds.length} Orders to rollback`
          : orderDetails.length === 1
          ? orderDetails[0].po_number
          : ''
      }
    />
  );
};
