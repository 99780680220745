/* eslint-disable no-param-reassign */

import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { IBanner, IBannerList, IBannerParams, IBannerSubmissionForm } from './interfaces';

export class SwipeRxPtBanners {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.BANNERS;
  }

  async retrieve(id: number, params?: any): Promise<IBanner> {
    const { data } = await this.base.get(`${this.resource}/${id}`, params);

    return data as IBanner;
  }

  async fetchBanners(params?: IBannerParams): Promise<IBannerList> {
    return this.base.get<IBannerList>(`${this.resource}`, params);
  }

  async getAllPositions(marketing_id: number): Promise<IBannerList> {
    return this.base.get(`${this.resource}?marketing_id=${marketing_id}&active=true&page=1&page_size=1000&fields=id,display_priority`)
  }

async createOrUpdate(
    banner: Partial<IBannerSubmissionForm>, 
    options: {
      id?: number; 
      file?: File;
    },
  ): Promise<IBanner> {
    const form = new FormData();
    form.append('banner', JSON.stringify(banner));
    if (options.file) {
      form.append('file', options.file);
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    }

    const { data } = options.id 
      ? await this.base.patch(`${this.resource}/${options.id}`, form, config)
      : await this.base.post(`${this.resource}`, form, config)
    return data;
  }

  async delete(id: number): Promise<void> {
    return this.base.delete(`${this.resource}/${id}`);
  }
}
