import styled from 'styled-components';

const Wrapper = styled.div`
  && {
    background: #fff;
    border-bottom: 1px solid rgba(33, 33, 33, 0.08);
    margin: 15px 0 0;
    padding: 0 15px;
  }
`;

export const S = {
  Wrapper,
};
