import React from 'reactn';

import { Order } from 'common/reducers';
import { View, Text, Image } from '@react-pdf/renderer';
import { styles } from './styles';
import { DeliveryProofV2Props } from '../types';

interface FooterProps extends DeliveryProofV2Props {
  order: Order;
}

export const Footer: React.FC<FooterProps> = ({ order }) => {
  const qrId = `qrcode-invoice-${order.id}`;
  const qrcodeRef = document.getElementById(qrId)
    ? (document.getElementById(qrId) as any).toDataURL('image/png', 1)
    : null;

  return (
    <View style={styles.footerContainer}>
      <View wrap={false}>
        <Text style={styles.noteTitle}>Term &amp; Condition</Text>
        <Text style={styles.noteTitle}>Return Policy</Text>
        <Text style={styles.noteContent}>
          You have 1 business day from the shipping date of your order to return your purchase from SwipeRx free of
          charge.
        </Text>
        <Text style={styles.noteContent}>You may return at:</Text>
        <Text style={styles.noteContent}>1. Point of delivery</Text>
        <Text style={styles.noteContent}>2. By contacting customer support on Line: @swiperx_th{'\n\n'}</Text>
      </View>

      <View wrap={false}>
        <Text style={styles.noteTitle}>Special Conditions:</Text>
        <Text style={styles.noteContent}>1. Some of our items cannot be returned</Text>
        <Text style={styles.noteContent}>
          2. Returns after 1 day from the shipping date of your order are subject to distributor policy{'\n\n'}
        </Text>
        <Text style={styles.noteContent}>Note: VAT included, where applicable</Text>
      </View>

      <View style={styles.bottomSection} wrap={false}>
        <View style={styles.qrcodeContainer}>
          <Image source={qrcodeRef} style={styles.qrcode} />
        </View>
        <View style={styles.signatureContainer}>
          <Text style={styles.signatureHead}>
            I hereby acknowledge of receiving the above items as per{'\n'}mentioned quantity and product name
          </Text>
          <View style={styles.signatureField}>
            <View style={styles.signatureBlank} />
            <Text style={styles.signatureTitle}>Received by:</Text>
          </View>
          <View style={styles.signatureField}>
            <View style={styles.signatureBlank} />
            <Text style={styles.signatureTitle}>Delivery by:</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
