import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from 'common/components/DataGrid';

import { Colors } from 'common/themes/colors';
import {
  InformationWrapperProps,
  InformationContentProps,
  InformationTitleProps,
  DocumentDataGridWrapperProps,
  PageWrapperProps,
} from './DeliveryProof.types';

const PageWrapper = styled.div<PageWrapperProps>`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  * {
    font-family: sans-serif !important;
  }

  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .page {
    width: 210mm;
    min-height: 297mm;
    padding: 20mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    ${(props) => (props.lastPage ? 'padding-bottom: 0 !important' : '')}
  }

  .page .subpage {
    padding: 0;
    height: 257mm;
    // Don't delete for debugging purpose
    // border: 5px red solid;
    // outline: 2cm rgba(255, 200, 200, 0.5) solid;
  }

  @page {
    size: A4;
    margin: 0 !important;
    padding: 0 !important;
  }

  @media print {
    html,
    body {
      width: 210mm;
      height: 297mm;
    }

    * {
      font-family: sans-serif !important;
    }

    .page {
      page-break-after: always !important;
      break-after: always !important;
      margin: initial;
      margin-top: 0;
      padding-top: 18mm;
      padding-left: 0;
      padding-right: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      ${(props) => (props.lastPage ? 'padding-bottom: 0 !important' : '')}
    }

    .page .subpage {
    }

    && {
      .pagebreak {
        clear: both !important;
        page-break-after: always !important;
        break-after: always !important;
      }
    }

    .first-page {
      padding-top: 5mm !important;
    }

    .page:last-child {
    }
  }
`;

export const Logo = styled.img`
  width: 4cm;
`;

export const LogoGrayed = styled(Logo)`
  filter: grayscale(100%);
`;

export const PageDivider = styled.hr`
  width: 100%;
  border-bottom: 1px dotted #bbb;
  border-top: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const InformationWrapper = styled(Grid).attrs({
  container: true,
})<InformationWrapperProps>`
  display: flex;
  justify-content: space-between;
  ${(props) => (props.top ? `margin-top: ${props.top}px !important;` : '')}
  margin-bottom: ${(props) => (props.bottom ? props.bottom : 10)}px !important;
`;

export const InformationTitle = styled(Typography).attrs({
  variant: 'h3',
  className: 'information-title',
})<InformationTitleProps>`
  color: ${Colors.textNormal};
  font-weight: bold !important;
  ${(props) => (props.top ? `margin-top: ${props.top}px !important;` : '')}
  ${(props) => (props.size ? `font-size: ${props.size}px !important;` : '')}
  ${(props) => (props.align ? `text-align: ${props.align} !important;` : '')}

  @media print {
    color: ${Colors.textNormal} !important;
    font-weight: bold !important;
    ${(props) => (props.top ? `margin-top: ${props.top}px !important;` : '')}
    ${(props) => (props.size ? `font-size: ${props.size}px !important;` : '')}
    ${(props) => (props.align ? `text-align: ${props.align} !important;` : '')}
  }
`;

export const InformationContent = styled(Typography).attrs({
  variant: 'body2',
})<InformationContentProps>`
  color: ${Colors.textNormal};
  font-size: ${(props) => (props.size ? props.size : 11.5)}px !important;
  line-height: ${(props) => (props.height ? props.height : 1.5)} !important;
  font-weight: ${(props) => (props.weight ? props.weight : 'normal')} !important;
  text-align: ${(props) => (props.align ? props.align : 'left')};
  ${(props) => (props.bottom ? `margin-bottom: ${props.bottom}px !important;` : '')}
`;

export const InformationContentTitle = styled(InformationContent)<InformationContentProps>`
  font-weight: bold !important;
  margin-top: 10px !important;
`;

export const InvoiceInformationWrapper = styled(Grid).attrs({
  container: true,
  direction: 'row',
  spacing: 1,
})`
  width: 100%;
`;

export const DocumentDataGridWrapper = styled.div<DocumentDataGridWrapperProps>`
  width: 100%;
  min-height: 1px;
  margin-top: ${(props) => (props.top ? props.top : 20)}px;
  margin-bottom: 20px;

  .MuiTableCell-head {
    background-color: rgba(0, 0, 0, 0.13);
  }
`;

export const DocumentDataGrid = styled(DataGrid)`
  && {
    .MuiDataGrid-iconSeparator {
      display: none;
    }

    .MuiDataGrid-colCellTitle {
      font-weight: bold;
    }
  }
`;

export const SignatureArea = styled.div`
  border-bottom: solid 1px;
  margin-top: 25px;
`;

export const S = {
  PageWrapper,
  Logo,
  LogoGrayed,
  InformationWrapper,
  InformationTitle,
  InformationContent,
  InvoiceInformationWrapper,
  InformationContentTitle,
  DocumentDataGrid,
  DocumentDataGridWrapper,
  PageDivider,
  SignatureArea
};
