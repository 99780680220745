import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';

const Wrapper = styled(Grid)`
  && {
    input {
      height: 17px;
      width: 115px;
    }
  }
`;

export const S = {
  Wrapper,
};
