/* eslint-disable no-restricted-globals */
import { addReducer } from 'reactn';
import { Dispatch } from 'reactn/default';

import { VoucherTypeProgressEnum } from 'common/constants';
import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';
import { recordException } from 'utils/Reporting/Sentry';

import { VoucherTypeState } from './interfaces/VoucherTypeState.interface';

export const VOUCHER_TYPE_INITIAL_STATE: VoucherTypeState = {
  voucherTypeList: {
    data: [],
    meta: {
      page: 1,
      page_count: 1,
      page_size: 10,
      total_count: 1,
    },
  },
  detail: {
    voucherType: null,
    history: {
      data: [],
      meta: {
        page: 1,
        page_count: 1,
        page_size: 10,
        total_count: 1,
      },
    },
    summary: {
      total_allocated_voucher: 0,
      total_amount_redeemed: 0,
      total_eligible_pharmacy: 0,
      total_pharmacy_redeemed: 0,
      total_voucher_redeemed: 0,
      value: 0,
    },
  },
  loading: false,
};

addReducer('fetchVoucherTypeDatatable', async (state, dispatch, { params }) => {
  dispatch.setVoucherTypeLoading(true);

  try {
    const { meta, data } = await SwipeRxPt.instance.voucherType.list(params);

    dispatch.setVoucherTypeDataTable({ data, meta });
  } catch (error) {
    recordException(error as Error, 'fetchVoucherTypeDatatable', { params });

    dispatch.setVoucherTypeError(error);
  }
});

addReducer('fetchVoucherTypeDetail', async (state, dispatch, voucherTypeId) => {
  dispatch.setVoucherTypeLoading(true);

  try {
    const data = await SwipeRxPt.instance.voucherType.detail(voucherTypeId);

    dispatch.setVoucherTypeDetail(data);
  } catch (error) {
    recordException(error as Error, 'fetchVoucherTypeDetail', { voucherTypeId });

    dispatch.setVoucherTypeError(error);
  }
});

async function checkAndUpdateVoucher(dispatch: Dispatch, voucherTypeId: number, retries = 0): Promise<void> {
  setTimeout(async () => {
    const voucherType = await SwipeRxPt.instance.voucherType.detail(voucherTypeId);

    dispatch.updateVoucherData(voucherTypeId, voucherType);

    if (retries < 3 && voucherType.progress === VoucherTypeProgressEnum.QUEUED) {
      checkAndUpdateVoucher(dispatch, voucherTypeId, retries + 1);
    }
  }, 3000);
}

addReducer('activateVoucherType', async (state, dispatch, voucherTypeId) => {
  try {
    dispatch.updateVoucherData(voucherTypeId, { progress: VoucherTypeProgressEnum.QUEUED });

    await SwipeRxPt.instance.voucherType.activate(voucherTypeId);

    checkAndUpdateVoucher(dispatch, voucherTypeId);
  } catch (error) {
    recordException(error as Error, 'activateVoucherType', { voucherTypeId });

    dispatch.setVoucherTypeError(error);
    dispatch.updateVoucherData(voucherTypeId, { progress: VoucherTypeProgressEnum.IDLE });
  }
});

addReducer('deactivateVoucherType', async (state, dispatch, voucherTypeId) => {
  try {
    dispatch.updateVoucherData(voucherTypeId, { progress: VoucherTypeProgressEnum.QUEUED });

    await SwipeRxPt.instance.voucherType.deactivate(voucherTypeId);
    checkAndUpdateVoucher(dispatch, voucherTypeId);
  } catch (error) {
    recordException(error as Error, 'deactivateVoucherType ', { voucherTypeId });

    dispatch.setVoucherTypeError(error);
    dispatch.updateVoucherData(voucherTypeId, { progress: VoucherTypeProgressEnum.IDLE });
  }
});

addReducer('fetchRedemptionHistory', async (state, dispatch, { params }) => {
  const voucherTypeId = state.voucherType.detail.voucherType?.id;

  try {
    if (!voucherTypeId) {
      throw new Error('No Voucher info');
    }

    const { meta, data } = await SwipeRxPt.instance.voucherType.redemptionHistory(voucherTypeId, params);

    dispatch.setVoucherTypeRedemptionHistory({ data, meta });
  } catch (error) {
    recordException(error as Error, 'fetchRedemptionHistory ', { voucherTypeId });

    dispatch.setVoucherTypeError(error);
  }
});

addReducer('fetchRedemptionSummary', async (state, dispatch, voucherTypeId) => {
  try {
    const summary = await SwipeRxPt.instance.voucherType.redemptionSummary(voucherTypeId);

    dispatch.setVoucherTypeRedemptionSummary(summary);
  } catch (error) {
    recordException(error as Error, 'fetchRedemptionSummary ', { voucherTypeId });

    dispatch.setVoucherTypeError(error);
  }
});

addReducer('exportVoucherType', async (_, dispatch, { ids, orderBy, sortBy }) => {
  dispatch.setVoucherTypeLoading(true);

  const voucherTypeListExportParams: Record<string, any> = {
    voucher_type_ids: ids,
    sort_by: sortBy,
    order_by: orderBy,
  };

  try {
    await SwipeRxPt.instance.voucherType.export(voucherTypeListExportParams);
  } catch (error) {
    recordException(error as Error, 'exportVoucherType', { ids });
    dispatch.setVoucherTypeError({ error: (error as Error).message });
  } finally {
    dispatch.setVoucherTypeLoading(false);
  }
});

addReducer('deleteVoucherType', async (state, dispatch, voucherTypeId) => {
  try {
    await SwipeRxPt.instance.voucherType.delete(voucherTypeId);
  } catch (error) {
    recordException(error as Error, 'deleteVoucherType', { voucherTypeId });

    dispatch.setVoucherTypeError(error);
  }
});

addReducer('updateVoucherData', (state, _, voucherTypeId, data) => {
  const { voucherType } = state;

  return {
    ...state,
    voucherType: {
      ...voucherType,
      loading: false,
      voucherTypeList: {
        data: voucherType.voucherTypeList.data.map((voucher) => {
          if (voucher.id === voucherTypeId) return { ...voucher, ...data };
          else return voucher;
        }),
        meta: voucherType.voucherTypeList.meta,
      },
    },
  };
});

addReducer('setVoucherTypeDataTable', (state, _, { data, meta }) => {
  const { voucherType } = state;

  return {
    ...state,
    voucherType: {
      ...voucherType,
      loading: false,
      voucherTypeList: {
        data,
        meta,
      },
    },
  };
});

addReducer('clearVoucherTypeDataTable', (state) => {
  const { voucherType } = state;

  return {
    ...state,
    voucherType: {
      ...voucherType,
      loading: false,
      voucherTypeList: {
        data: [],
        meta: {
          page: 1,
          page_count: 1,
          page_size: 10,
          total_count: 1,
        },
      },
    },
  };
});

addReducer('setVoucherTypeDetail', (state, _, data) => {
  const { voucherType } = state;
  const { detail } = voucherType;

  return {
    ...state,
    voucherType: {
      ...voucherType,
      loading: false,
      detail: {
        ...detail,
        voucherType: data,
      },
    },
  };
});

addReducer('setVoucherTypeRedemptionHistory', (state, _, { data, meta }) => {
  const { voucherType } = state;
  const { detail } = voucherType;

  return {
    ...state,
    voucherType: {
      ...voucherType,
      loading: false,
      detail: {
        ...detail,
        history: {
          data,
          meta,
        },
      },
    },
  };
});

addReducer('setVoucherTypeRedemptionSummary', (state, _, summary) => {
  const { voucherType } = state;
  const { detail } = voucherType;

  return {
    ...state,
    voucherType: {
      ...voucherType,
      loading: false,
      detail: {
        ...detail,
        summary,
      },
    },
  };
});

addReducer('setVoucherTypeError', (state, _, error) => {
  const { voucherType } = state;

  return {
    ...state,
    voucherType: {
      ...voucherType,
      error: error.message,
    },
  };
});

addReducer('setVoucherTypeLoading', (state, _, isLoading) => {
  const { voucherType } = state;

  return {
    ...state,
    voucherType: {
      ...voucherType,
      loading: isLoading,
    },
  };
});
