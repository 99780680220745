import { ReturnOptions, FieldOptions } from './interfaces';

enum ReturnItemReasons {
  ADDRESS_DOESNT_MATCH = "The address doesn't match SIA",
  AREA_NOT_COVERED = 'Area not cover',
  BELOW_MINIMUM_INVOICE = 'Below minimum invoice',
  PBF_DOESNT_PROCESS = "PBF doesn't process",
  PHARMACY_CANCEL = 'Pharmacy request to cancel',
  PAYMENT_PROBLEM = 'Payment problem',
  PRECURSOR_PRODUCT = 'Precursor product',
  STOCKOUT = 'Stockout',
  DOUBLE_ORDER = 'The system created double order',
  DAMAGE_GOODS = 'Damage goods',
  NEAR_ED = 'Near ED',
  DISCOUNT_CHANGE = 'Discount change',
  PBF_SEND_WRONG_PRODUCT = 'PBF Send the wrong product',
  PHARMACY_REQUEST_CANCEL = 'Pharmacy request cancel',
  PRICE_CHANGING = 'Price changing',
  STOCK_DISCREPANCY = 'Stock discrepancy',
  USER_ERROR = 'User error',
  WRONG_ORDER = 'Wrong order',
  FULL_RETURN = 'Full return',
  OTHER = 'other',
}

interface ReturnItemOption {
  value: ReturnItemReasons;
  label: ReturnItemReasons;
}

enum ReturnReasons {
  PHARMACIST_INPUT_MISTAKE = 'The pharmacist input the item mistakenly',
  NEAR_EXPIRY_DATE = 'Near expiry date of the item',
  PHARMACIST_CANCEL_ITEM = 'The pharmacist cancel the item suddenly',
  ITEMS_AVAILABLE_PHARMACIST = 'The items still available on the pharmacist side',
  DAMAGE_GOODS = 'Damage goods',
  DISCOUNT_CHANGE = 'Discount change',
  NEAR_ED = 'Near ED',
  PBF_SEND_WRONG_PRODUCT = 'PBF Send the wrong product',
  PHARMACY_REQUEST_CANCEL = 'Pharmacy request cancel',
  PRICE_CHANGING = 'Price changing',
  STOCK_DISCREPANCY = 'Stock discrepancy',
  USER_ERROR = 'User error',
  WRONG_ORDER = 'Wrong order',
}

interface ReturnOption {
  value: ReturnReasons;
  label: ReturnReasons;
}

const returnItemOption = (reason: ReturnItemReasons): ReturnItemOption => ({
  value: reason,
  label: reason,
});

export const returnItemOptions: ReturnItemOption[] = [
  returnItemOption(ReturnItemReasons.ADDRESS_DOESNT_MATCH),
  returnItemOption(ReturnItemReasons.AREA_NOT_COVERED),
  returnItemOption(ReturnItemReasons.BELOW_MINIMUM_INVOICE),
  returnItemOption(ReturnItemReasons.PBF_DOESNT_PROCESS),
  returnItemOption(ReturnItemReasons.PHARMACY_CANCEL),
  returnItemOption(ReturnItemReasons.PAYMENT_PROBLEM),
  returnItemOption(ReturnItemReasons.PRECURSOR_PRODUCT),
  returnItemOption(ReturnItemReasons.STOCKOUT),
  returnItemOption(ReturnItemReasons.DOUBLE_ORDER),
  returnItemOption(ReturnItemReasons.DAMAGE_GOODS),
  returnItemOption(ReturnItemReasons.DISCOUNT_CHANGE),
  returnItemOption(ReturnItemReasons.NEAR_ED),
  returnItemOption(ReturnItemReasons.PBF_SEND_WRONG_PRODUCT),
  returnItemOption(ReturnItemReasons.PHARMACY_REQUEST_CANCEL),
  returnItemOption(ReturnItemReasons.PRICE_CHANGING),
  returnItemOption(ReturnItemReasons.STOCK_DISCREPANCY),
  returnItemOption(ReturnItemReasons.USER_ERROR),
  returnItemOption(ReturnItemReasons.WRONG_ORDER),
  returnItemOption(ReturnItemReasons.FULL_RETURN),
  returnItemOption(ReturnItemReasons.OTHER),
];

const returnOption = (reason: ReturnReasons): ReturnOption => ({
  value: reason,
  label: reason,
});

export const returnOptions: ReturnOptions[] = [
  returnOption(ReturnReasons.PHARMACIST_INPUT_MISTAKE),
  returnOption(ReturnReasons.NEAR_EXPIRY_DATE),
  returnOption(ReturnReasons.PHARMACIST_CANCEL_ITEM),
  returnOption(ReturnReasons.ITEMS_AVAILABLE_PHARMACIST),
  returnOption(ReturnReasons.DAMAGE_GOODS),
  returnOption(ReturnReasons.DISCOUNT_CHANGE),
  returnOption(ReturnReasons.NEAR_ED),
  returnOption(ReturnReasons.PBF_SEND_WRONG_PRODUCT),
  returnOption(ReturnReasons.PHARMACY_REQUEST_CANCEL),
  returnOption(ReturnReasons.PRICE_CHANGING),
  returnOption(ReturnReasons.STOCK_DISCREPANCY),
  returnOption(ReturnReasons.USER_ERROR),
  returnOption(ReturnReasons.WRONG_ORDER),
];

export const fieldOptions: FieldOptions[] = [
  {
    name: 'reason',
    type: 'select',
    options: returnOptions,
    label: 'Reason',
  },
  {
    name: 'date',
    type: 'date',
    label: 'Delivery Date',
  },
];
