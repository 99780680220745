import {
  FINANCE_BULK_ACTION_BUTTON_MAP_BY_STATUS,
  FINANCE_MEMBER_ROLES,
  UserRoles
} from 'common/constants';
import { FinanceActionButton } from 'types';

const isAllowed = (
  role: UserRoles,
  allowedRoles: UserRoles[] = FINANCE_MEMBER_ROLES
): boolean => [...allowedRoles, UserRoles.SUPER_ADMIN].includes(role);

export const getFinanceBulkActionButtons = (
  userRole: UserRoles,
  status: string,
): Array<FinanceActionButton> => {
  const actions = (
    FINANCE_BULK_ACTION_BUTTON_MAP_BY_STATUS[status] || []
  ).filter(item => {
    if (!item) return true;

    const { allowedRoles } = item;
    return isAllowed(userRole, allowedRoles)
  });

  return actions;
}