import React from 'react';
import { Route, Redirect } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import { RouteConfigOptions } from './routes.interface';
import { getMarketPath, IMarketParams } from '../utils/get-router-path';
import { DEFAULT_MARKET_ID } from '../common/services/constants';

interface Props {
  authenticated: boolean;
  route: RouteConfigOptions;
  exact: boolean;
}

export const PrivateRoute: React.FC<Props> = ({
  authenticated,
  route,
  exact,
}) => {
  const { params } = useRouteMatch<IMarketParams>();

  if (!authenticated) {
    const path = `${getMarketPath(params.marketId || DEFAULT_MARKET_ID)}/login`;
    return <Redirect to={path} />;
  } else {
    return (
      <Route path={route.path} component={route.component} exact={exact} />
    );
  }
};
