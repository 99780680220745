/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-empty-function */

import { addReducer } from 'reactn';

import { COLORS } from 'common/constants';
import { ColorType } from 'types';

import { SwipeRxPt } from 'common/services/apis/v3';
import { recordException } from 'utils/Reporting/Sentry';
import { UtilsState } from './interfaces/UtilsState.interface';
import { RootState } from '..';

export const UTILS_INITIAL_STATE: UtilsState = {
  snackbar: {
    open: false,
    message: '',
    onCloseSnackbar: () => {},
    contentProps: {},
    autoHideDuration: 8000,
  },
  signedUrl: '',
  signedUrls: {},
};

addReducer(
  'setUtilsSnackbar',
  (state, _, payload): RootState => {
    const { utils } = state;
    const { variant, ...snackbarProps } = payload;

    let contentProps = {};
    switch (variant) {
      case ColorType.DANGER: {
        contentProps = {
          backgroundColor: COLORS.danger,
        };
        break;
      }
      case ColorType.PRIMARY:
      default: {
        contentProps = {
          backgroundColor: COLORS.primary,
        };
        break;
      }
    }

    if (!snackbarProps.autoHideDuration) snackbarProps.autoHideDuration = 8000;
    return {
      ...state,
      utils: {
        ...utils,
        snackbar: {
          ...snackbarProps,
          contentProps,
        },
      },
    };
  },
);

addReducer(
  'closeSnackbar',
  (state, _): RootState => {
    const { utils } = state;
    return {
      ...state,
      utils: {
        ...utils,
        snackbar: UTILS_INITIAL_STATE.snackbar,
      },
    };
  },
);

addReducer('fetchSignedUrlByDocumentPharmacyId', async (_, dispatch, payload) => {
  dispatch.clearSignedUrl({});
  try {
    const { data } = await SwipeRxPt.instance.documentManagementPharmacy.fetchSignedUrl(payload);
    dispatch.setSignedUrl({ data });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchSignedUrlByDocumentPharmacyId', payload);
  }
});

addReducer('fetchSignedUrlByDocumentHistoryId', async (_, dispatch, payload) => {
  dispatch.clearSignedUrl({});
  try {
    const { data } = await SwipeRxPt.instance.documentManagementPharmacy.fetchSignedUrlByDocumentHistoryId(payload);
    dispatch.setSignedUrl({ data });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchSignedUrlByDocumentHistoryId', payload);
  }
});

addReducer('fetchSignedUrlByPharmacyIdAndShort', async (_, dispatch, payload) => {
  dispatch.clearSignedUrl({});
  try {
    const { data } = await SwipeRxPt.instance.documentManagementPharmacy.fetchSignedUrlByPharmacyIdAndShort(payload);
    dispatch.setSignedUrl({ data });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchSignedUrlByPharmacyIdAndShort', payload);
  }
});

addReducer('fetchSignedUrlByPharmacyIdAndShorts', async (_, dispatch, payload) => {
  dispatch.setSignedUrls({ data: {} });
  try {
    const { data } = await SwipeRxPt.instance.documentManagementPharmacy.fetchSignedUrlByPharmacyIdAndShorts(payload);
    const convertSignedUrls = data.reduce((prev: any, value: any) => ({ ...prev, [value.short]: value.signedUrl }), {});
    dispatch.setSignedUrls({ data: convertSignedUrls });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'fetchSignedUrlByPharmacyIdAndShort', payload);
  }
});

addReducer('clearSignedUrl', async (state) => {
  const { utils } = state;

  return {
    ...state,
    utils: {
      ...utils,
      signedUrl: '',
    },
  };
});

addReducer('setSignedUrl', (state, _, { data }) => {
  const { utils } = state;

  return {
    ...state,
    utils: {
      ...utils,
      signedUrl: data.signedUrl,
    },
  };
});

addReducer('setSignedUrls', (state, _, { data }) => {
  const { utils } = state;
  return {
    ...state,
    utils: {
      ...utils,
      signedUrls: data,
    },
  };
});
