import React from 'react';
import styled from 'styled-components';

import SnackbarContent, {
  SnackbarContentProps,
} from '@material-ui/core/SnackbarContent';

import { COLORS } from 'common/constants';
import { ColorType } from 'types';

interface ContentProps extends SnackbarContentProps {
  color: ColorType;
}

const getContentBackground = ({ color }: ContentProps): string => COLORS[color];

const Content = styled(SnackbarContent)`
  && {
    background: ${getContentBackground};
  }
`;

export const S = {
  Content: Content as React.FC<ContentProps>,
};
