import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import { S } from './CardTableMessage.styles';

interface Props {
  altText?: string;
  text: string | JSX.Element;
}

export const CardTableMessage: React.FC<Props> = ({ altText, text }) => (
  <S.Wrapper>
    <tr>
      <td colSpan={100}>
        {!altText ? (
          text
        ) : (
          <Tooltip title={altText} placement="bottom" interactive>
            <div>{text}</div>
          </Tooltip>
        )}
      </td>
    </tr>
  </S.Wrapper>
);
