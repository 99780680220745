/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDispatch } from 'reactn';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { InvoiceCreate } from 'common/services/apis/v3/swipe-rx-pt';
import { useCrypto } from 'hooks/useCrypto';
import { MAX_ADDRESS_LENGTH, MAX_NAME_LENGTH } from './OrderInvoiceDialog';

export interface OrderInvoiceFormState {
  id?: string;
  order_id: number;
  invoice_number: string;
  invoiced_amount: number;
  invoiced_at: string;
  pharmacy_name: string;
  pharmacy_address: string;
}

export const useOrderInvoiceForm = (
  initialValues: Partial<OrderInvoiceFormState>,
  callback?: any,
) => {
  const createInvoice = useDispatch('createInvoice');
  const updateInvoice = useDispatch('updateInvoice');
  const { sha256 } = useCrypto();

  const form = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      invoice_number: Yup.string().required('Invoice Number is required.'),
      invoiced_amount: Yup.string().required('Invoice Amount is required.'),
      invoiced_at: Yup.string().required('Invoice Date is required.'),
      pharmacy_address: Yup.string().required('Pharmacy address is required.'),
      pharmacy_name: Yup.string().required('Pharmacy name is required.'),
    }),
    onSubmit: async (
      {
        id,
        order_id,
        invoice_number,
        invoiced_amount,
        invoiced_at,
        pharmacy_address,
        pharmacy_name,
      },
      { setSubmitting },
    ) => {
      if (!order_id) {
        return;
      }

      const payload = {
        invoice_number,
        invoiced_amount,
        invoiced_at,
        custom_information: {
          pharmacy: {
            name: pharmacy_name?.substring(0, MAX_NAME_LENGTH),
            address: pharmacy_address?.substring(0, MAX_ADDRESS_LENGTH),
          },
        },
      };

      if (id) {
        await updateInvoice({ orderId: order_id, invoice: payload });
      } else {
        let idempotence_key = '';
        try {
          idempotence_key = await sha256(JSON.stringify(payload));
        } catch (e) {
          // do nothing
        }

        await createInvoice({
          orderId: order_id,
          invoice: {
            ...(payload as InvoiceCreate),
            idempotence_key,
          },
        });
      }

      setSubmitting(false);
      if (callback) callback();
    },
  });

  return form;
};
