import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import { COLORS } from 'common/constants';

const Text = styled(Typography)`
  && {
    font-size: 11px;
    color: ${COLORS.danger};
  }
`;

export const S = { Text };
