import { ITranslation } from './types';

export default {
  SwipeRx: 'SwipeRx',
  deliverer: 'Yang Menyerahkan',
  recipient: 'Yang Menerima',
  date: 'tanggal',
  time: 'jam',
  invoiceReceipt: 'TANDA TERIMA TUKAR FAKTUR',
  invoiceNo: 'NO',
  invoiceNumber: 'NO FAKTUR',
  invoiceDate: 'TGL FAKTUR',
  invoiceTotalAmount: 'JUMLAH',
  invoiceDueDate: 'TGL JT TEMPO',
  invoiceNote: 'KETERANGAN',
  retourNo: 'NO.',
  retourForm: 'FORM RETUR',
  retourItem: 'NAMA BARANG',
  retoruQuantity: 'QTY',
  retourReason: 'ALASAN RETUR BARANG',
  footerNoteTitle: 'Note',
  footerNotes: [
    'Langsung tukar faktur (isi lembar tanda terima tukar faktur,tanda tangan apotek & stempel)',
    'Bawa 3 copy faktur',
  ],
  footerPaymentMethod: 'Pembayaran melalui',
  footerCustomerService: 'Untuk info lebih lanjut hubungi CS di #CONTACT_NUMBER#',
} as ITranslation;
