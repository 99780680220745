/* eslint-disable consistent-return */
import { addReducer } from "reactn"
import { SwipeRxPt } from "common/services/apis/v3";
import { recordException } from "utils/Reporting/Sentry";
import { DistributorState } from "./interfaces";
import { RootState } from "..";
import { DistributorIdentity } from "./types";

export const DISTRIBUTORS_INITIAL_STATE: DistributorState = {
  distributor_search: {
    collection: [],
    selected: null,
  },
  distributor_detail: {
    currentDistributor: null,
  },
  distributor_product: {
    selected_distributor_product: null,
  },
  global: {
    loading: false,
    error: '',
  }
}

addReducer('createDistributor', async (_, dispatch, distributor) => {
  try {
    const { id } = await SwipeRxPt.instance.distributors.createDistributor(distributor);
    dispatch.fetchDistributorById(id.toString());
  } catch (e) {
    recordException(e, 'createDistributor', { distributor });
    dispatch.setDistributorGlobalError(e);
  }
});

addReducer('patchDistributor', async (_, dispatch, { id, distributor }) => {
  try {
    await SwipeRxPt.instance.distributors.patchDistributor(id, distributor);
    dispatch.fetchDistributorById(id.toString());
  } catch (e) {
    recordException(e, 'patchDistributor', { id, distributor });
    dispatch.setDistributorGlobalError(e);
  }
});


addReducer('searchDistributor', async (_, dispatch, term) => {
  try {
    const { data } = await SwipeRxPt.instance.distributors.search({
      term,
      fields: ['id', 'name'],
      page: 1,
      page_size: 5,
    });

    dispatch.setDistributorSearch(data as DistributorIdentity[]);
  } catch (error) {
    recordException(error, 'searchDistributor', { term });
    dispatch.setDistributorGlobalError(error);
  }
});

addReducer('fetchDistributorById', async (_, dispatch, id) => {
  try {
      const distributor = await SwipeRxPt.instance.distributors.retrieve(Number(id), {
        fields: [
          'id',
          'name',
          'country',
          'city',
          'address',
          'zipcode', 
          'region',
          'type',
          'minimum_invoice_amount',
          'notification_list',
          'status',
          'abbreviation',          
        ]
      })
      dispatch.setDistributorDetailCurrent(distributor);
  } catch (error) {
    recordException(error, 'fetchDistributorById', { id });
    dispatch.setDistributorGlobalError(error);
  }
})

addReducer('fetchDistributorProduct', async (_, dispatch, data) => {
  try {
    const distributorProduct = await SwipeRxPt
      .instance
      .distributors
      .getDistributorProduct(
        data.distributorId,
        data.distributorProductId,
        data.expand || ['product', 'product_tier_pricing'],
      );

    dispatch.setDistributorProductCurrent(distributorProduct);
  } catch (error) {
    recordException(error, 'fetchDistributorProduct', { data });
    dispatch.setDistributorGlobalError(error);
  }
})

addReducer('setDistributorSearch', (state, _, data): RootState => {
  const { distributors } = state;
  const { distributor_search } = distributors;

  return {
    ...state,
    distributors: {
      ...distributors,
      distributor_search: {
        ...distributor_search,
        collection: data,
      }
    }
  }
});

addReducer('setDistributorSearchSelected', (state, _, selected): RootState => {
  const { distributors } = state;
  const { distributor_search } = distributors;

  return {
    ...state,
    distributors: {
      ...distributors,
      distributor_search: {
        ...distributor_search,
        selected,
      }
    }
  }
})

addReducer('setDistributorDetailCurrent', (state, _, currentDistributor): RootState => {
  const { distributors } = state;
  const { distributor_detail } = distributors;
  
  return {
    ...state,
    distributors: {
      ...distributors,
      distributor_detail: {
        ...distributor_detail,
        currentDistributor,
      }
    }
  }
})

addReducer('setDistributorProductCurrent', (state, _, selected): RootState => {
  const { distributors } = state;
  const { distributor_product } = distributors;

  return {
    ...state,
    distributors: {
      ...distributors,
      distributor_product: {
        ...distributor_product,
        selected_distributor_product: selected,
      }
    }
  }
});

addReducer('setDistributorGlobalError', (state, _, error) => {
  const { distributors } = state;
  const { global } = distributors;

  return {
    ...state,
    global: {
      ...global,
      error: error.message,
    },
  }
});

addReducer('updateDistributorProduct', async (state, dispatch, data) => {
  try {
    const {
      distributorId,
      distributorProductId,
      payload,
    } = data;

    await SwipeRxPt
      .instance
      .distributors
      .updateDistributorProduct(
        distributorId,
        distributorProductId,
        payload,
      );

    dispatch.setDistributorProductCurrent(null);
  } catch (error) {
    recordException(error, 'updateDistributorProduct', { data });
    dispatch.setDistributorGlobalError(error);
  }
});

addReducer('setDistributorLoading', (state, _, loading): RootState => {
  const { distributors } = state;
  const { global } = distributors;
  return {
    ...state,
    distributors: {
      ...distributors,
      global: {
        ...global,
        loading,
      }
    }
  }
});