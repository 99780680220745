import React, { useGlobal, useDispatch, useState } from 'reactn';

import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { Typography } from '@material-ui/core';

import {
  Button,
  Dialog,
  FieldWrapper,
  BulkActionItemCardMapper,
  ConfirmationDialog,
  FieldDateInput,
} from 'common/components';
import { ColorType } from 'types';

const formId = 'order-invoice-update-form';

export const OrderInvoiceUpdateModal: React.FC = () => {
  const [modalView, setModalView] = useState('dateSelection');
  const isConfirmationModal = modalView === 'confirmationModal';
  const [{ updateInvoiceDates }] = useGlobal('transactions');

  const setOrderIdsForUpdateInvoiceDates = useDispatch('setOrderIdsForUpdateInvoiceDates');
  const updateInvoices = useDispatch('updateInvoiceDates');

  const clearOrderIds = (): void => {
    setOrderIdsForUpdateInvoiceDates({ orderIds: [] });
  };

  const { orderDetails, orderIds } = updateInvoiceDates;
  const InvoiceDate = 'newInvoiceDate';

  const convertToISOString = (date: any): moment.Moment => {
    const selectedDate = moment((date as unknown) as string);
    selectedDate.utcOffset(0);
    selectedDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    selectedDate.toISOString();
    return selectedDate;
  };

  const isDateValid = (dateStr: any): boolean => {
    if (!dateStr) return false;
    const date = moment(dateStr);
    if (!date.isValid()) return false;
    const isFuture = moment().diff(date) < 0;
    return !isFuture;
  };

  const form = useFormik({
    enableReinitialize: false,
    initialValues: {
      [InvoiceDate]: convertToISOString(new Date().toString()).toString(),
    },
    validationSchema: Yup.object({
      [InvoiceDate]: Yup.string().test('empty-check', 'Invalid Invoice Date', isDateValid),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      updateInvoices({ orderIds, date: values[InvoiceDate] });
      setSubmitting(false);
      clearOrderIds();
      setModalView('dateSelection');
      resetForm();
    },
  });

  const handleSelectedDate = (date: any): void => {
    const selectedDate = convertToISOString(date);
    form.setFieldValue(InvoiceDate, selectedDate);
  };

  const renderContent = (): JSX.Element => (
    <>
      <form id={formId} onSubmit={form.handleSubmit} style={{ height: '20vh' }}>
        <FieldWrapper
          isLabelBold
          minWidth={100}
          key={InvoiceDate}
          label="New Invoice Date"
          touched={form.touched[InvoiceDate]}
          error={form.errors[InvoiceDate]}
        >
          <FieldDateInput
            value={form.values[InvoiceDate]}
            onChange={handleSelectedDate}
            InputProps={{ readOnly: true }}
            disableFuture
          />
        </FieldWrapper>
        <BulkActionItemCardMapper data={orderDetails} />
      </form>
    </>
  );
  const renderActions = (): JSX.Element => (
    <Button
      color={ColorType.PRIMARY}
      disabled={!isDateValid(form.values[InvoiceDate]) || !form.dirty || form.isSubmitting}
      loading={form.isSubmitting}
      onClick={() => {
        setModalView('confirmationModal');
      }}
    >
      Submit
    </Button>
  );

  const dialogValue =
    orderIds.length > 1
      ? `${orderIds.length} Orders to update`
      : orderDetails.length === 1
      ? orderDetails[0].po_number
      : '';

  const confirmationModal = (
    <>
      {orderIds.length && (
        <ConfirmationDialog
          title={`Are you sure you want to bulk update ${orderIds.length} invoices?`}
          message={
            <Typography>
              Once confirmed, that is the time it will update all the invoice date.
              <br />
              <b>This cannot be undone.</b>
            </Typography>
          }
          options={{
            accept: 'Update Invoice Dates',
            cancel: 'Cancel',
            variant: 'danger',
            showCloseButton: false,
          }}
          onAccept={() => form.submitForm()}
          onCancel={() => {
            setModalView('dateSelection');
          }}
        />
      )}
    </>
  );

  return isConfirmationModal ? (
    confirmationModal
  ) : (
    <Dialog
      open={!!orderIds.length}
      primary
      title={isConfirmationModal ? 'Update Invoice' : 'Update Invoices'}
      onClose={clearOrderIds}
      renderActions={renderActions}
      renderContent={renderContent}
      style={{ height: '90%' }}
      subTitle={orderIds.length > 1 ? 'Selected' : 'Order ID'}
      value={dialogValue}
    />
  );
};
