import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { SidebarMenuConfigOptions as SideMenuConfig } from './sidebar-menu.interface';

interface Props {
  paths: SideMenuConfig[];
  onItemClick: (path: string) => void;
  isMenuActive: (path: string) => boolean;
  isMenuIconActive: (path: string) => { root: string };
  classes: any;
}

export const SideBarMenu: React.FC<Props> = ({ paths, classes, onItemClick, isMenuActive, isMenuIconActive }) => {
  return (
    <List
      component="nav"
      aria-label="Main Menu"
      classes={{ root: classes.list }}
      disablePadding
      style={{ overflow: 'auto' }}
    >
      {paths.map((path) => (
        <ListItem
          data-testid={`navbar-${path.label}`}
          key={path.label}
          button
          classes={{
            root: classes.listItem,
            selected: classes.listItemSelected,
          }}
          onClick={() => onItemClick(path.path)}
          selected={isMenuActive(path.path)}
        >
          <ListItemIcon classes={isMenuIconActive(path.path)}>{path.icon}</ListItemIcon>
          <ListItemText classes={{ primary: classes.listItemText }} primary={path.label} />
        </ListItem>
      ))}
    </List>
  );
};
