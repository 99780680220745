import { CRM_UPLOAD_ROLES, UserRoles } from 'common/constants';
import { getAllowedRolesWoForbidden } from 'utils/get-allowed-roles';
import { Action } from './Pharmacies.Details.container.type';

interface Config {
  label: string;
  path: string;
  roleAccess: UserRoles[];
}

const allRoles = Object.values(UserRoles);
const nonTransactionManager = allRoles.filter((role) => role !== UserRoles.TRANSACTION_MANAGER);
const nonSupervisor = allRoles.filter((role) => ![UserRoles.ADMIN, UserRoles.SALES_SUPERVISOR].includes(role));
const nonSalesSupervisorRoles = getAllowedRolesWoForbidden([UserRoles.SALES_SUPERVISOR]);

const crmRoles = allRoles.filter((role) =>
  [
    UserRoles.SUPER_ADMIN,
    UserRoles.ADMIN,
    UserRoles.TRANSACTION_MANAGER,
    UserRoles.CUSTOMER_SUPPORT,
    UserRoles.FULFILLMENT_ADMIN,
    UserRoles.SALES_SUPPORT,
    UserRoles.PHARMACIST,
    UserRoles.SALES_SUPERVISOR,
  ].includes(role),
);
const crmRolesWoSalesSupervisor = getAllowedRolesWoForbidden([UserRoles.SALES_SUPERVISOR], crmRoles);

export const TabsConfig: Config[] = [
  {
    label: 'Summary',
    path: '',
    roleAccess: allRoles,
  },
  {
    label: 'Credit',
    path: 'credit',
    roleAccess: nonTransactionManager,
  },
  {
    label: 'Transactions',
    path: 'payableinvoices',
    roleAccess: nonTransactionManager,
  },
  {
    label: 'Payments',
    path: 'paymenthistory',
    roleAccess: nonTransactionManager,
  },
  {
    label: 'Users',
    path: 'users',
    roleAccess: allRoles,
  },
  {
    label: 'Access',
    path: 'access',
    roleAccess: nonSalesSupervisorRoles,
  },
  {
    label: 'CRM',
    path: 'crm',
    roleAccess: crmRoles,
  },
];

export const ActionPermissions = [
  {
    action: Action.SyncCRM,
    roleAccess: crmRolesWoSalesSupervisor,
  },
  {
    action: Action.EditCRM,
    roleAccess: nonSalesSupervisorRoles,
  },
  {
    action: Action.UploadCRM,
    roleAccess: CRM_UPLOAD_ROLES,
  },
  // SPT-20453: Disable the get pharmacy link and change password link (20240222)
  // SPT-20574: Enable pharmacy link access for specific user list (20240306)
  {
    action: Action.GetPharmacyLink,
    roleAccess: nonSupervisor,
  },
  {
    action: Action.ForgotPassLink,
    roleAccess: nonSupervisor,
  },
  {
    action: Action.EditPharmacyDetail,
    roleAccess: nonSalesSupervisorRoles,
  },
  {
    action: Action.MessagePharmacy,
    roleAccess: nonSalesSupervisorRoles,
  },
  {
    action: Action.AddPayment,
    roleAccess: nonSalesSupervisorRoles,
  },
  {
    action: Action.ResolveDoku,
    roleAccess: nonSalesSupervisorRoles,
  },
];
