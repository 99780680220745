import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardArrowDown } from '@material-ui/icons';

import { CardTablePageSizeChangeHandler } from '../../../types';

import { S } from './PageSize.styles';

interface Props {
  defaultValue: number;
  disabled?: boolean;
  items: number[];
  onChange?: CardTablePageSizeChangeHandler;
  value: number;
}

export const CardTablePageSize: React.FC<Props> = ({
  defaultValue,
  disabled,
  items,
  onChange,
  value,
}) => {
  const handleChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
  ): void => {
    if (onChange) {
      onChange(parseInt(e.target.value as string, 10));
    }
  };

  const renderItems = (): JSX.Element[] =>
    items.map((item) => (
      <MenuItem key={item} value={item} dense>
        {item} rows
      </MenuItem>
    ));

  return (
    <S.Wrapper
      disabled={disabled}
      IconComponent={KeyboardArrowDown}
      value={value || defaultValue}
      onChange={handleChange}
    >
      {renderItems()}
    </S.Wrapper>
  );
};
