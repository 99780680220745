import React from 'react';
import { Grid } from '@material-ui/core';
import { DropzoneRootProps } from 'react-dropzone';
import { S } from './field-image-upload.styles';

interface Props {
  getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
  style?: React.CSSProperties;
}

export const FieldImageUploadArea: React.FC<Props> = ({ children, getRootProps, style }) => (
  <S.UploadArea data-testid="image-uploader" {...getRootProps()} style={style} >
    <Grid container direction="column" alignItems="center" justify="center" style={{ width: '100%', padding: 'auto' }}>
      {children}
    </Grid>
  </S.UploadArea>
);
