import React from 'react';

interface Props {
  condition: boolean;
}

export const ConditionalRender: React.FC<Props> = ({ condition, children }) => {
  if (!condition) {
    return null;
  }

  return <>{children}</>;
};
