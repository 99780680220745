import styled from 'styled-components';

interface Props {
  active: boolean;
}

const getBackgroundColor = (props: Props): string => {
  if (props.active) {
    return '#FFFFFF';
  }
  return 'rgba(64, 174, 150, 0.1)';
};

const getBorder = (props: Props): string => {
  if (props.active) {
    return 'none';
  }
  return '1px dashed #40AE96';
};

const getFontColor = (props: Props): string => {
  if (props.active) {
    return 'black';
  }
  return '#009688';
};

const DropzoneContainer = styled.div<Props>`
  height: 400px;
  padding: auto;
  radius: 8px;
  display: flex;

  background: ${getBackgroundColor};
  border: ${getBorder};
  box-sizing: border-box;
  border-radius: 3px;
  color: ${getFontColor};

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const FormTitle = styled.span`
  && {
    padding-top: 12px;
    padding-bottom: 9px;
    color: gray;
    font-size: 14px;
    color: #000000;
  }
`;

const UploadArea = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
`;

export const S = {
  DropzoneContainer,
  FormTitle,
  UploadArea,
};
