/* eslint-disable no-restricted-globals */
import { addReducer } from 'reactn';

import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';
import { recordException } from 'utils/Reporting/Sentry';
import { CreditLimitRequestStatusEnum } from 'common/constants/credit-limit-request-status.enum';
import { CreditLimitRequestState } from './interfaces/CreditLimitRequestState.interface';

export const CREDIT_LIMIT_REQUESTS_INITIAL_STATE: CreditLimitRequestState = {
  creditLimitRequestsList: {
    data: [],
    meta: {
      page: 1,
      page_count: 1,
      page_size: 10,
      total_count: 1,
    },
  },
  loading: false,
};

addReducer('fetchCreditLimitRequestDatatable', async (state, dispatch, { params }) => {
  dispatch.setCreditLimitRequestLoading(true);

  try {
    const { meta, data } = await SwipeRxPt.instance.creditLimitRequest.list(params);

    dispatch.setCreditLimitRequestDatatable({ data, meta });
  } catch (error) {
    recordException(error as Error, 'fetchCreditLimitRequestDatatable', { params });

    dispatch.setCreditLimitRequestLoading(error);
  }
});

addReducer('approveCreditLimitRequest', async (_, dispatch, { id, payload }) => {
  dispatch.setCreditLimitRequestLoading(true);

  try {
    await SwipeRxPt.instance.creditLimitRequest.approve(id, payload);

    dispatch.updateRequestDatatable({ id, status: CreditLimitRequestStatusEnum.APPROVED });
  } catch (error) {
    recordException(error as Error, 'approveCreditLimitRequest', { id });

    dispatch.setCreditLimitRequestError({ error });

    dispatch.setCreditLimitRequestLoading(error);
  }
});

addReducer('acceptCreditLimitRequest', async (_, dispatch, { id }) => {
  dispatch.setCreditLimitRequestLoading(true);

  try {
    await SwipeRxPt.instance.creditLimitRequest.accept(id);

    dispatch.updateRequestDatatable({ id, status: CreditLimitRequestStatusEnum.REVIEWED });
  } catch (error) {
    recordException(error as Error, 'acceptCreditLimitRequest', { id });

    dispatch.setCreditLimitRequestError({ error });

    dispatch.setCreditLimitRequestLoading(error);
  }
});

addReducer('rejectCreditLimitRequest', async (_, dispatch, { id, payload }) => {
  dispatch.setCreditLimitRequestLoading(true);

  try {
    await SwipeRxPt.instance.creditLimitRequest.reject(id, payload);

    dispatch.updateRequestDatatable({ id, status: CreditLimitRequestStatusEnum.REJECTED });
  } catch (error) {
    recordException(error as Error, 'rejectCreditLimitRequest', { id });

    dispatch.setCreditLimitRequestError({ error });

    dispatch.setCreditLimitRequestLoading(error);
  }
});

addReducer('deleteCreditLimitRequest', async (_, dispatch, { id }) => {
  dispatch.setCreditLimitRequestLoading(true);

  dispatch.updateRequestDatatable({ id });
  try {
    await SwipeRxPt.instance.creditLimitRequest.delete(id);
  } catch (error) {
    recordException(error as Error, 'deleteCreditLimitRequest', { id });

    dispatch.setCreditLimitRequestError({ error });

    dispatch.setCreditLimitRequestLoading(error);
  }
});

addReducer('setCreditLimitRequestLoading', (state, _, isLoading) => {
  const { creditLimitRequest } = state;

  return {
    ...state,
    voucherType: {
      ...creditLimitRequest,
      loading: isLoading,
    },
  };
});

addReducer('setCreditLimitRequestDatatable', (state, _, { data, meta }) => {
  const { creditLimitRequest } = state;

  return {
    ...state,
    creditLimitRequest: {
      ...creditLimitRequest,
      loading: false,
      creditLimitRequestsList: {
        data,
        meta,
      },
    },
  };
});

addReducer('updateRequestDatatable', (state, _, { id, status }) => {
  const { creditLimitRequest } = state;
  const { creditLimitRequestsList } = creditLimitRequest;

  return {
    ...state,
    creditLimitRequest: {
      ...creditLimitRequest,
      loading: false,
      creditLimitRequestsList: {
        data: creditLimitRequestsList.data.filter((item) => item.id !== id),
        meta: creditLimitRequestsList.meta,
      },
    },
  };
});

addReducer('setCreditLimitRequestError', (state, _, { error }) => {
  const { creditLimitRequest } = state;

  return {
    ...state,
    creditLimitRequest: {
      ...creditLimitRequest,
      error: error ? error.message : null,
    },
  };
});
