import { useHistory, useLocation } from 'react-router-dom';
import qs, { ParsedQuery } from 'query-string';
import { PaymentMethodType } from 'types/PaymentMethod.type';

type FilterQuery = ParsedQuery & {
  payment_method?: PaymentMethodType;
};
type UpdateQueryParams = (queryParams: FilterQuery, state?: any) => void;
type UseUpdateQueryParams = () => {
  queryParams: FilterQuery;
  updateQueryParams: UpdateQueryParams;
  clearQueryParams: () => void;
};

export const useUpdateQueryParams: UseUpdateQueryParams = () => {
  const history = useHistory();
  const location = useLocation();

  let queryParams = qs.parse(location.search);

  const updateQueryParams: UpdateQueryParams = (newQueryParams, state) => {
    if (newQueryParams) {
      queryParams = { ...queryParams, ...newQueryParams };

      history.push({
        search: qs.stringify(queryParams, { skipNull: true }),
        state,
      });
    }
  };

  const clearQueryParams = (): void => {
    history.push({ search: '' });
  };

  return {
    queryParams,
    updateQueryParams,
    clearQueryParams,
  };
};
