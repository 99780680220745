/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDispatch, useEffect, useGlobal } from 'reactn';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useOrderModalReason } from 'pages/transactions/hooks/use-order-modal-reason.hook';

export interface OrderCancelFormState {
  reason: string;
  date: string;
}

const formState: OrderCancelFormState = {
  reason: '',
  date: moment().format('YYYY-MM-DD'),
};

export const useOrderCancelForm = () => {
  const [{ cancel }] = useGlobal('transactions');
  const cancelOrders = useDispatch('cancelOrders');
  const setOrderIdsForCancel = useDispatch('setOrderIdsForCancel');

  const { selectedReason, setSelectedReason, clearReason } = useOrderModalReason();

  const clearOrderIds = (): void => {
    setOrderIdsForCancel({ orderIds: [] });
  };

  const form = useFormik({
    initialValues: {
      ...formState,
    },
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason field is required.'),
      date: Yup.date().required('Date field is required.'),
    }),
    onSubmit: ({ reason, date }, { setSubmitting }) => {
      const newDate = moment(date).format('YYYY-MM-DD');
      cancelOrders({ reason, date: newDate });
      setSubmitting(false);
      clearOrderIds();
    },
  });

  useEffect(() => {
    form.setValues({ ...formState });
    Object.keys(formState).map((key: string): void => {
      form.setTouched({ [key]: false });
    });

    return () => clearReason();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancel.orderIds]);

  return { form, cancel, selectedReason, clearOrderIds, setSelectedReason };
};
