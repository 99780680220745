import axios from 'axios';

/**
 * @deprecated Newer API calls should use their own axios instance and handle
 * the interceptor for that axios instance.
 *
 * TODO: if making specific interceptors for different cases, make them more modularized. e.g. authentication-interceptor.js
 *
 */

function getToken() {
  return localStorage.getItem('PT-ROLE-DATA')
    ? JSON.parse(localStorage.getItem('PT-ROLE-DATA') as string).session_token
    : null;
}

axios.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token) {
      config.headers['x-warp-session-token'] = token;
    }

    config.headers['x-warp-api-key'] = process.env.REACT_APP_API_KEY;

    return config;
  },
  (error) => {
    // TODO: Error handling
    return Promise.reject(error);
  },
);
