/* eslint-disable no-restricted-globals */
import { addReducer } from 'reactn';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';

import { pluralize, getSelectedOrdersByIds, parseStatus } from 'common/utils';
import { SwipeRxPt, Order, OrderItem, InvoiceStatus } from 'common/services/apis/v3/swipe-rx-pt';
import { DataObject } from 'types';
import { recordException } from 'utils/Reporting/Sentry';

import { TransactionState } from './interfaces';

export const TRANSACTIONS_INITIAL_STATE: TransactionState = {
  distributor: {
    data: null,
  },
  orderDetails: {
    data: null,
  },
  invoicing: {
    version: null,
    order: {},
  },
  merge: {
    poNumbers: [],
  },
  split: {
    orderId: 0,
    poNumber: '',
    orderItems: [],
  },
  reassign: {
    orderIds: [],
    options: [],
    orderDetails: [],
  },
  cancel: {
    orderIds: [],
    orderDetails: [],
  },
  delay: {
    orderIds: [],
    orderDetails: [],
  },
  updateInvoiceDates: {
    orderIds: [],
    orderDetails: [],
  },
  rollback: {
    orderIds: [],
    orderDetails: [],
  },
  fulfill: {
    orderIds: [],
    orderDetails: [],
  },
  returnOrder: {
    orderId: 0,
    orderDetails: [],
    orderItems: {},
  },
  deliver: {
    orderIds: [],
    orderDetails: [],
  },
  orderItemReassign: {
    poNumber: '',
    availableDistributors: [],
    orderItems: [],
  },
  deliveryTracking: {
    orderIds: [],
    number: '',
    pointId: '',
    logisticId: null,
    logisticName: '',
  },
  deliveryStatus: {
    orderIds: [],
    status: '',
    statusDetail: undefined,
  },
  exportToDeliveryPartner: {
    orderIds: [],
    orderDetails: [],
  },
  orderDeliveryPartner: {
    selected: undefined,
    data: [],
  },
  uploadTrackingNumber: {
    isDialogOpen: false,
    isUploading: false,
  },
  accept: {
    orderIds: [],
    amountDiffs: [],
  },
  restore: {
    orderIds: [],
    amountDiffs: [],
  },
  loading: false,
  totalRowCount: 0,
};

const getPoNumberTexts = (orders: Order[]): string => {
  const [firstOrder] = orders;
  const { po_number: firstPoNumber } = firstOrder;

  if (orders.length > 2) {
    const numOfOtherOrders = orders.length - 1;
    return `${firstPoNumber} and ${numOfOtherOrders} ${pluralize('other', numOfOtherOrders)}`;
  }

  if (orders.length === 2) {
    return `${firstPoNumber} and ${orders[1].po_number}`;
  }

  return firstPoNumber;
};

addReducer('setTransactionLoadStatus', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      loading: payload,
    },
  };
});

addReducer('setTransactionError', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      error: payload.error,
    },
  };
});

addReducer('setTransactionWarning', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      warning: payload.warning,
    },
  };
});

addReducer('setTransactionSuccessMessage', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      successMessage: payload.message,
    },
  };
});

addReducer('setTransactionBulkActionConfirmation', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      bulkActionConfirmation: payload,
    },
  };
});

addReducer('setTransactionDistributor', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      distributor: {
        data: payload,
        error: null,
      },
    },
  };
});

addReducer('setTransactionOrderDetails', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      orderDetails: {
        data: payload,
        error: null,
      },
    },
  };
});

addReducer('setTransactionDistributorError', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      distributor: {
        ...transactions.distributor,
        error: payload.error,
      },
    },
  };
});

addReducer('setTransactionOrderDetailsError', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      orderDetails: {
        ...transactions.orderDetails,
        error: payload.error,
      },
    },
  };
});

addReducer('setOrderItemsForSplit', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      split: payload,
    },
  };
});

addReducer('setOrderForInvoicing', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      invoicing: payload,
    },
  };
});

addReducer('setPoNumbersForMerge', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      merge: {
        poNumbers: payload.poNumbers,
      },
    },
  };
});

addReducer('fetchTransactionDistributor', async (_, dispatch, { id }) => {
  try {
    const { name } = await SwipeRxPt.instance.organizations.retrieve(id, {
      type: 'distributor',
    });

    dispatch.setTransactionDistributor({ id, name });
  } catch (e) {
    recordException(e, 'fetchTransactionDistributor', { id });
    dispatch.setTransactionDistributorError({ error: e.message });
  }
});

addReducer('fetchTransactionOrderDetails', async (_, dispatch, { id, params }) => {
  try {
    const order = await SwipeRxPt.instance.orders.retrieve(id, {
      expand: [
        'pharmacy',
        'pharmacy.crm',
        'items.product',
        'invoice',
        'logistics_po_delivery',
        'logistics_delivery_status',
        ...(params?.expand ?? []),
      ],
    });

    dispatch.setTransactionOrderDetails(order);
  } catch (e) {
    recordException(e, 'fetchTransactionOrderDetails', { id });
    dispatch.setTransactionOrderDetailsError({ error: e.message });
  }
});

addReducer('updateTransactionOrderItems', async (_, dispatch, { orderId, items }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    await SwipeRxPt.instance.orders.items.bulkUpdate(orderId, items);

    const updatedOrder = await SwipeRxPt.instance.orders.retrieve(orderId, {
      expand: ['pharmacy', 'items.product', 'invoice', 'logistics_po_delivery'],
    });

    dispatch.setTransactionOrderDetails(updatedOrder);
    dispatch.setOrderItemFlagSummary(null);
    dispatch.fetchDataTableRows({ name: 'orders' });
    dispatch.setTransactionSuccessMessage({
      message: 'Changes have been saved.',
    });
  } catch (e) {
    recordException(e, 'updateTransactionOrderItems', { orderId, items });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});
addReducer('cancelOrders', async ({ transactions }, dispatch, { reason, date }) => {
  try {
    const {
      cancel: { orderIds },
    } = transactions;
    dispatch.setTransactionLoadStatus(true);

    await SwipeRxPt.instance.orders.cancel({
      order_ids: orderIds,
      reason,
      date,
    });
    await dispatch.fetchDataTableRows({ name: 'orders' });

    dispatch.setTransactionSuccessMessage({
      message: `Orders have been cancelled.`,
    });
  } catch (e) {
    recordException(e, 'cancelOrders', { reason, date, transactions });
    dispatch.setTransactionOrderDetailsError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('exportTransactions', async ({ dataTables }, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);
    const { params, isAllSelected } = dataTables.orders;
    let orderListExportParams: Record<string, any>;
    if (isAllSelected) {
      const { total_count, distributor_id } = params || {};
      const { status, fulfillmentStatus, isReturned, isDelayed } = parseStatus(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        params?.status || '',
      );

      orderListExportParams = {
        distributor_id,
        page_size: total_count,
        status,
        fulfillment_status: fulfillmentStatus,
        is_returned: isReturned,
        is_delayed: isDelayed,
      };
    } else {
      const { page_size, sort_by, order_by } = params || {};
      orderListExportParams = {
        page_size,
        sort_by,
        order_by,
        order_ids: ids,
      };
    }

    // Remove falsy valued field
    Object.keys(orderListExportParams).forEach(
      (key) => [null, '', undefined].includes(orderListExportParams[key]) && delete orderListExportParams[key],
    );

    await SwipeRxPt.instance.orders.export(orderListExportParams);
  } catch (e) {
    recordException(e, 'exportTransactions', { ids });
    dispatch.setTransactionOrderDetailsError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('exportTransactionsFullOrderDetails', async ({ dataTables }, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);
    const { params, isAllSelected } = dataTables.orders;
    let orderListExportParams: Record<string, any>;
    if (isAllSelected) {
      const { total_count, distributor_id } = params || {};
      const { status, fulfillmentStatus, isReturned, isDelayed } = parseStatus(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        params?.status || '',
      );

      orderListExportParams = {
        distributor_id,
        page_size: total_count,
        status,
        fulfillment_status: fulfillmentStatus,
        is_returned: isReturned,
        is_delayed: isDelayed,
      };
    } else {
      const { page_size, sort_by, order_by } = params || {};
      orderListExportParams = {
        page_size,
        sort_by,
        order_by,
        order_ids: ids,
      };
    }

    // Remove falsy valued field
    Object.keys(orderListExportParams).forEach(
      (key) => [null, '', undefined].includes(orderListExportParams[key]) && delete orderListExportParams[key],
    );

    await SwipeRxPt.instance.orders.exportFullOrderDetails(orderListExportParams);
  } catch (e) {
    recordException(e, 'exportTransactionsFullOrderDetails', { ids });
    dispatch.setTransactionOrderDetailsError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('initializeInvoicing', async ({ dataTables }, dispatch, { orderId, version }) => {
  try {
    const selectedOrder = dataTables.orders.rows.find((order) => order.id === orderId);

    dispatch.setOrderForInvoicing({ order: selectedOrder, version });
  } catch (e) {
    recordException(e, 'initializeInvoicing', { orderId, version });
    dispatch.setTransactionError({ error: e.message });
  }
});

addReducer('createInvoice', async (_, dispatch, { orderId, invoice }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    await SwipeRxPt.instance.orders.createInvoice(orderId, invoice);

    dispatch.fetchDataTableRows({ name: 'orders' });
    dispatch.setOrderForInvoicing(null);
    dispatch.setTransactionSuccessMessage({
      message: 'Invoice has been created.',
    });
    dispatch.fetchDataTableRows({ name: 'orders' });
  } catch (e) {
    recordException(e, 'createInvoice', { orderId, invoice });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('updateInvoice', async (_, dispatch, { orderId, invoice }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    await SwipeRxPt.instance.orders.updateInvoice(orderId, invoice);

    dispatch.fetchDataTableRows({ name: 'orders' });
    dispatch.setOrderForInvoicing(null);
    dispatch.setTransactionSuccessMessage({
      message: 'Invoice has been updated.',
    });
  } catch (e) {
    recordException(e, 'updateInvoice', { orderId, invoice });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('initializeOrderMerge', async ({ dataTables }, dispatch, { ids }) => {
  try {
    if (ids.length < 2) {
      dispatch.setTransactionError({
        error: 'Must select 2 or more orders to be able to merge.',
      });

      return;
    }

    const ordersToMerge = getSelectedOrdersByIds(ids, dataTables);

    const mergeable = ordersToMerge.every((order) => order.pharmacy.id === ordersToMerge[0].pharmacy.id);

    if (!mergeable) {
      dispatch.setTransactionError({
        error: 'Orders must be from the same pharmacy to be able to merge.',
      });

      return;
    }

    dispatch.setPoNumbersForMerge({
      poNumbers: ordersToMerge.map(({ po_number }) => po_number),
    });
  } catch (e) {
    recordException(e, 'initializeOrderMerge', { ids });
    dispatch.setTransactionError({ error: e.message });
  }
});

addReducer('mergeOrders', async (_, dispatch, { poNumbers }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const order = await SwipeRxPt.instance.orders.merge(poNumbers);

    dispatch.fetchDataTableRows({ name: 'orders' });

    dispatch.setTransactionSuccessMessage({
      message: `The order has been created with a PO Number of ${order.po_number}.`,
    });
  } catch (e) {
    recordException(e, 'mergeOrders', { poNumbers });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('initializeOrderItemSplit', async ({ transactions }, dispatch, { ids, poNumber, orderId }) => {
  try {
    const {
      orderDetails: { data: order },
    } = transactions;

    if (!order) {
      return;
    }

    const { items } = order;

    if (items.length === ids.length) {
      dispatch.setTransactionError({
        error: 'Splitting all the order items is not allowed.',
      });

      return;
    }

    const indexedOrderItems = keyBy(items, 'id');

    const orderItemsToSplit: DataObject[] = [];

    ids.forEach((id) => orderItemsToSplit.push(indexedOrderItems[id]));

    dispatch.setOrderItemsForSplit({
      poNumber,
      orderId,
      orderItems: orderItemsToSplit,
    });
  } catch (e) {
    recordException(e, 'initializeOrderItemSplit', { ids, poNumber, orderId });
    dispatch.setTransactionError({ error: e.message });
  }
});

addReducer('splitOrderItems', async ({ transactions }, dispatch) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const { poNumber, orderItems, orderId } = transactions.split;

    const orderItemIds = orderItems.map(({ id }) => id);
    const [, newOrder] = await SwipeRxPt.instance.orders.splitOrderItems(poNumber, orderItemIds);

    const updatedOrder = await SwipeRxPt.instance.orders.retrieve(orderId, {
      expand: ['pharmacy', 'items.product', 'invoice', 'logistics_po_delivery'],
    });

    dispatch.setTransactionOrderDetails(updatedOrder);

    dispatch.fetchDataTableRows({ name: 'orders' });
    dispatch.setTransactionSuccessMessage({
      message: `The order has been created with a PO Number of ${newOrder.po_number}.`,
    });
  } catch (e) {
    recordException(e, 'splitOrderItems');
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('initializeOrderReassign', async ({ dataTables }, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);
    const { availableDistributors } = await SwipeRxPt.instance.orders.getAvailableDistributorForReassignment(ids);

    if (availableDistributors.length === 0) {
      dispatch.setTransactionError({
        error: 'The orders selected cannnot be reassigned to another distributor.',
      });

      return;
    }

    const options = availableDistributors.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    const selectedOrders = getSelectedOrdersByIds(ids, dataTables);

    dispatch.setOrderReassignState({
      orderIds: ids,
      orderDetails: selectedOrders,
      options,
    });
  } catch (e) {
    recordException(e, 'initializeOrderReassign', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('setOrderIdsForReassign', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      reassign: {
        ...transactions.reassign,
        orderIds: payload.orderIds,
      },
    },
  };
});

addReducer('setOptionsForReassign', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      reassign: {
        ...transactions.reassign,
        options: payload.options,
      },
    },
  };
});

addReducer('setOrderReassignState', (state, _, payload) => {
  const { transactions } = state;
  const { options, orderDetails, orderIds } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      reassign: {
        orderIds,
        orderDetails,
        options,
      },
    },
  };
});

addReducer('setOrderItemReassignDetails', (state, _, payload) => {
  const { transactions } = state;
  const { poNumber, availableDistributors, orderItems, selectedDistributorId } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      orderItemReassign: {
        poNumber,
        availableDistributors,
        orderItems,
        selectedDistributorId,
      },
    },
  };
});

addReducer(
  'reassignOrders',
  async ({ transactions }: any, dispatch: any, { distributorId }: any): Promise<void> => {
    const { reassign } = transactions;
    try {
      dispatch.setTransactionLoadStatus(true);

      await SwipeRxPt.instance.orders.reassign({
        order_ids: reassign.orderIds,
        distributor_id: distributorId,
      });

      await dispatch.fetchDataTableRows({ name: 'orders' });

      dispatch.setTransactionSuccessMessage({
        message: `Orders have been successfully reassigned.`,
      });
    } catch (e) {
      recordException(e, 'reassignOrders', { distributorId });
      dispatch.setTransactionError({ error: e.message });
    } finally {
      dispatch.setTransactionLoadStatus(false);
    }
  },
);

addReducer('initializeOrderItemReassign', async ({ transactions }, dispatch, { orderItemIds }) => {
  try {
    const {
      orderDetails: { data: order },
    } = transactions;

    if (!order) {
      return;
    }

    if (order.items?.length === orderItemIds.length) {
      dispatch.setTransactionError({
        error: 'Reassigning all order items is not allowed.',
      });

      return;
    }

    dispatch.setTransactionLoadStatus(true);

    const availableDistributors = await SwipeRxPt.instance.orders.getOrderItemReassignAvailableDistributors(
      order.id,
      orderItemIds,
    );

    if (!availableDistributors.length) {
      dispatch.setTransactionError({
        error: 'There are no available distributors.',
      });

      return;
    }

    const options = availableDistributors.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    const { po_number, items } = order;

    const indexedOrderItems = keyBy(items, 'id');

    const orderItemsToReassign: DataObject[] = [];

    orderItemIds.forEach((id) => orderItemsToReassign.push(indexedOrderItems[id]));

    dispatch.setOrderItemReassignDetails({
      availableDistributors: options,
      poNumber: po_number,
      orderItems: orderItemsToReassign,
    });
  } catch (e) {
    recordException(e, 'initializeOrderItemReassign', { orderItemIds });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('reassignOrderItems', async ({ transactions }, dispatch) => {
  try {
    const {
      orderDetails: { data: order },
      orderItemReassign,
    } = transactions;

    if (!order) {
      return;
    }

    dispatch.setTransactionLoadStatus(true);

    const { orderItems, selectedDistributorId } = orderItemReassign;

    if (!selectedDistributorId) {
      return;
    }

    const newOrder = await SwipeRxPt.instance.orders.reassignOrderItems({
      orderItemIds: orderItems.map(({ id }) => id),
      distributorId: selectedDistributorId,
      orderId: order.id,
    });

    const updatedOrder = await SwipeRxPt.instance.orders.retrieve(order.id, {
      expand: ['pharmacy', 'items.product', 'invoice', 'logistics_po_delivery'],
    });

    dispatch.setTransactionOrderDetails(updatedOrder);

    dispatch.fetchDataTableRows({ name: 'orders' });
    dispatch.setTransactionSuccessMessage({
      message: `The order has been created with a PO Number of ${newOrder.po_number}.`,
    });
  } catch (e) {
    recordException(e, 'reassignOrderItems');
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('processOrders', async (_, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const processedOrders = await SwipeRxPt.instance.orders.process(ids);

    await dispatch.fetchDataTableRows({ name: 'orders' });

    dispatch.setTransactionSuccessMessage({
      message: `Order ${getPoNumberTexts(processedOrders)} ${pluralize(
        'has',
        processedOrders.length,
        'have',
      )} been moved to In Progress.`,
    });
  } catch (e) {
    recordException(e, 'processOrders', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('initializeOrderCancel', async ({ dataTables }, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const orderDetails = getSelectedOrdersByIds(ids, dataTables);

    dispatch.setOrderCancelState({
      orderIds: ids,
      orderDetails,
    });
  } catch (e) {
    recordException(e, 'initializeOrderCancel', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('setOrderCancelState', (state, _, payload) => {
  const { transactions } = state;
  const { orderDetails, orderIds } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      cancel: {
        orderIds,
        orderDetails,
      },
    },
  };
});

addReducer('setOrderIdsForCancel', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      cancel: {
        ...transactions.cancel,
        orderIds: payload.orderIds,
      },
    },
  };
});

addReducer('acceptOrders', async (_, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const acceptedOrders = await SwipeRxPt.instance.orders.accept(ids);

    await dispatch.fetchDataTableRows({ name: 'orders' });

    dispatch.setTransactionSuccessMessage({
      message: `Order ${getPoNumberTexts(acceptedOrders)} ${pluralize(
        'has',
        acceptedOrders.length,
        'have',
      )} been accepted.`,
    });

    dispatch.resetOrderAcceptConfirmation({});
  } catch (e) {
    recordException(e, 'acceptOrders', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('confirmAcceptOrders', async ({ dataTables }, dispatch, { ids }) => {
  const orderDetails = getSelectedOrdersByIds(ids, dataTables);
  const invoicedOrders = orderDetails.filter((order) => Boolean(order.invoice?.id));

  if (invoicedOrders.length < orderDetails.length) {
    dispatch.setTransactionError({ error: 'Some of the Order does not have invoice' });
  } else {
    const orderAmountDiff = await SwipeRxPt.instance.orders.countAmountDiff(ids);

    if (orderAmountDiff.length) {
      dispatch.setOrderForAcceptConfirmation({ ids, diff: orderAmountDiff });
    } else {
      dispatch.acceptOrders({ ids });
    }
  }
});

addReducer('confirmRestoreOrders', async (_, dispatch, { ids }) => {
  const orderOverLimit = await SwipeRxPt.instance.orders.getOverLimitSummary(ids);

  if (orderOverLimit.length) {
    dispatch.setOrderForRestoreConfirmation({ ids, diff: orderOverLimit });
  } else {
    dispatch.restoreOrders({ ids });
  }
});

addReducer('resetOrderAcceptConfirmation', async (_, dispatch) => {
  dispatch.setOrderForAcceptConfirmation({ ids: [], diff: [] });
});

addReducer('setOrderForAcceptConfirmation', (state, _, { ids, diff }) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      accept: {
        orderIds: ids,
        amountDiffs: diff,
      },
    },
  };
});

addReducer('resetOrderRestoreConfirmation', async (_, dispatch) => {
  dispatch.setOrderForRestoreConfirmation({ ids: [], diff: [] });
});

addReducer('setOrderForRestoreConfirmation', (state, _, { ids, diff }) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      restore: {
        orderIds: ids,
        amountDiffs: diff,
      },
    },
  };
});

addReducer('initializeOrderDelay', async ({ dataTables }, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const orderDetails = getSelectedOrdersByIds(ids, dataTables);

    const containsDelayedOrder = orderDetails.find((order) => order.is_delayed === true);

    if (containsDelayedOrder) {
      dispatch.setTransactionError({
        error: `Some of the orders have already been marked as delayed.`,
      });

      return;
    }

    dispatch.setOrderDelayState({
      orderIds: ids,
      orderDetails,
    });
  } catch (e) {
    recordException(e, 'initializeOrderDelay', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('initializeUpdateInvoiceDates', async ({ dataTables }, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const orderDetails = getSelectedOrdersByIds(ids, dataTables);

    dispatch.setOrderInvoiceUpdateState({
      orderIds: ids,
      orderDetails,
    });
  } catch (e) {
    recordException(e, 'initializeUpdateInvoiceDates', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('setOrderInvoiceUpdateState', (state, _, payload) => {
  const { transactions } = state;
  const { orderDetails, orderIds } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      updateInvoiceDates: {
        orderIds,
        orderDetails,
      },
    },
  };
});

addReducer('setOrderDelayState', (state, _, payload) => {
  const { transactions } = state;
  const { orderDetails, orderIds } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      delay: {
        orderIds,
        orderDetails,
      },
    },
  };
});

addReducer('setOrderIdsForDelay', (state, _, payload) => {
  const { transactions } = state;
  const { orderIds } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      delay: {
        ...transactions.delay,
        orderIds,
      },
    },
  };
});

addReducer('setOrderIdsForUpdateInvoiceDates', (state, _, payload) => {
  const { transactions } = state;
  const { orderIds } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      updateInvoiceDates: {
        ...transactions.updateInvoiceDates,
        orderIds,
      },
    },
  };
});

addReducer('setOrderRollbackState', (state, _, payload) => {
  const { transactions } = state;
  const { orderDetails, orderIds } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      rollback: {
        orderIds,
        orderDetails,
      },
    },
  };
});

addReducer('initializeOrderRollback', async ({ dataTables }, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);
    const orderDetails = getSelectedOrdersByIds(ids, dataTables);
    dispatch.setOrderRollbackState({
      orderIds: ids,
      orderDetails,
    });
  } catch (e) {
    recordException(e, 'initializeOrderRollback', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('setOrderIdsForRollback', (state, _, payload) => {
  const { transactions } = state;
  const { orderIds } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      rollback: {
        ...transactions.rollback,
        orderIds,
      },
    },
  };
});

addReducer('setOrderItemFlagSummary', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      orderItemFlagSummary: payload,
    },
  };
});

addReducer('delayOrders', async ({ transactions }, dispatch, { reason }) => {
  try {
    const {
      delay: { orderIds },
    } = transactions;
    dispatch.setTransactionLoadStatus(true);

    await SwipeRxPt.instance.orders.delay({ order_ids: orderIds, reason });
    await dispatch.fetchDataTableRows({ name: 'orders' });
    dispatch.setTransactionSuccessMessage({
      message: `Orders have been marked as delayed.`,
    });
  } catch (e) {
    recordException(e, 'delayOrders', { reason });
    dispatch.setTransactionOrderDetailsError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('rollback', async ({ transactions }, dispatch, { reason }) => {
  try {
    const {
      rollback: { orderIds },
    } = transactions;
    dispatch.setTransactionLoadStatus(true);

    const dispatchedOrders = await SwipeRxPt.instance.orders.rollback({
      order_ids: orderIds,
      reason,
    });
    await dispatch.fetchDataTableRows({ name: 'orders' });
    dispatch.setTransactionSuccessMessage({
      message: `Order ${getPoNumberTexts(dispatchedOrders)} ${pluralize(
        'has',
        dispatchedOrders.length,
        'have',
      )} been rollbacked.`,
    });
  } catch (e) {
    recordException(e, 'rollback', { reason });
    dispatch.setTransactionOrderDetailsError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('sendToDispatcher', async (_, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const dispatchedOrders = await SwipeRxPt.instance.orders.sendToDispatcher(ids);

    await dispatch.fetchDataTableRows({ name: 'orders' });

    dispatch.setTransactionSuccessMessage({
      message: `Order ${getPoNumberTexts(dispatchedOrders)} ${pluralize(
        'has',
        dispatchedOrders.length,
        'have',
      )} been dispatched.`,
    });
  } catch (error) {
    recordException(error, 'sendToDispatcher', { ids });
    dispatch.setTransactionError({ error: error.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('markOrderDeliveryDocsAsPrinted', async (_, dispatch, { ids }) => {
  try {
    await SwipeRxPt.instance.orders.markDeliveryDocsAsPrinted(ids);
  } catch (e) {
    recordException(e, 'markOrderDeliveryDocsAsPrinted', { ids });
    dispatch.setTransactionError({ error: e.message });
  }
});

addReducer('markOrdersAsUndelivered', async (_, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const ordersMarkedAsUndelivered = await SwipeRxPt.instance.orders.markAsUndelivered(ids);

    await dispatch.fetchDataTableRows({ name: 'orders' });

    dispatch.setTransactionSuccessMessage({
      message: `Order ${getPoNumberTexts(ordersMarkedAsUndelivered)} ${pluralize(
        'has',
        ordersMarkedAsUndelivered.length,
        'have',
      )} been marked as undelivered.`,
    });
  } catch (e) {
    recordException(e, 'markOrdersAsUndelivered', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

/* addReducer('initializeDeliveryTracking', (state, _, payload) => {
  const { transactions, dataTables } = state;
  const { orderIds } = payload;
  const orders = getSelectedOrdersByIds(orderIds, dataTables);
  const { logistics_delivery_status } = orders[0];
  let { delivery_tracking_code: number, point_id: pointId, logistics_delivery_partner_id: logisticId } =
    logistics_delivery_status || {};
  const { logistics_delivery_partner } = logistics_delivery_status || {};
  let { name: logisticName } = logistics_delivery_partner || {};

  if (!logisticId) {
    logisticId = null;
  }
  if (!logisticName) {
    logisticName = '';
  }
  if (!number) {
    number = '';
  }
  if (!pointId) {
    pointId = '';
  }

  return {
    ...state,
    transactions: {
      ...transactions,
      deliveryTracking: {
        orderIds,
        number,
        pointId,
        logisticId,
        logisticName,
      },
    },
  };
});

addReducer('initializeDeliveryStatus', (state, _, payload) => {
  const { transactions, dataTables } = state;
  const { orderIds } = payload;

  const orders = getSelectedOrdersByIds(orderIds, dataTables);
  const { logistics_delivery_status } = orders[0];
  let { status, status_detail: statusDetail } = logistics_delivery_status || {};

  if (!status) {
    status = '';
  }
  if (!statusDetail) {
    statusDetail = undefined;
  }

  return {
    ...state,
    transactions: {
      ...transactions,
      deliveryStatus: {
        orderIds,
        status,
        statusDetail,
      },
    },
  };
});

addReducer('setDeliveryTracking', (state, _, payload) => {
  const { transactions } = state;
  const { orderIds, number, pointId, logisticId, logisticName } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      deliveryTracking: {
        orderIds,
        number,
        pointId,
        logisticId,
        logisticName,
      },
    },
  };
});

addReducer('setDeliveryStatus', (state, _, payload) => {
  const { transactions } = state;
  const { orderIds, status, statusDetail } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      deliveryStatus: {
        orderIds,
        status,
        statusDetail,
      },
    },
  };
});

addReducer('updateDeliveryTracking', async (state, dispatch, { ids, number, pointId, logisticId }) => {
  const { dataTables } = state;
  const orders = getSelectedOrdersByIds(ids, dataTables);
  const { logistics_delivery_status } = orders[0];
  const { status, status_detail: statusDetail } = logistics_delivery_status || {};

  try {
    dispatch.setTransactionLoadStatus(true);

    await SwipeRxPt.instance.orders.updateTracking(ids, number, pointId, logisticId, status, statusDetail);

    await dispatch.fetchDataTableRows({ name: 'orders' });
  } catch (e) {
    recordException(e, 'updateTracking', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('updateDeliveryStatus', async (state, dispatch, { ids, status, statusDetail }) => {
  const { dataTables } = state;
  const orders = getSelectedOrdersByIds(ids, dataTables);
  const { logistics_delivery_status } = orders[0];
  const { delivery_tracking_code: number, point_id: pointId, logistics_delivery_partner_id: logisticId } =
    logistics_delivery_status || {};

  try {
    dispatch.setTransactionLoadStatus(true);

    await SwipeRxPt.instance.orders.updateTracking(ids, number, pointId, logisticId, status, statusDetail);

    await dispatch.fetchDataTableRows({ name: 'orders' });
  } catch (e) {
    recordException(e, 'updateTracking', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
}); */

addReducer('restoreOrders', async (_, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const acceptedOrders = await SwipeRxPt.instance.orders.restore(ids);

    await dispatch.fetchDataTableRows({ name: 'orders' });

    dispatch.setTransactionSuccessMessage({
      message: `Order ${getPoNumberTexts(acceptedOrders)} ${pluralize(
        'has',
        acceptedOrders.length,
        'have',
      )} been restored.`,
    });

    dispatch.resetOrderRestoreConfirmation({});
  } catch (e) {
    recordException(e, 'restoreOrders', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('initializeOrderFulfillment', async ({ dataTables }, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const orderDetails = getSelectedOrdersByIds(ids, dataTables);

    dispatch.setOrderFulfillState({
      orderIds: ids,
      orderDetails,
    });
  } catch (e) {
    recordException(e, 'initializeOrderFulfillment', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('initializeOrderDeliver', async ({ dataTables }, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const selectedOrders = getSelectedOrdersByIds(ids, dataTables);

    dispatch.setOrderDeliverState({
      orderIds: ids,
      orderDetails: selectedOrders,
    });
  } catch (e) {
    recordException(e, 'initializeOrderDeliver', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('setOrderDeliverState', (state, _, payload) => {
  const { transactions } = state;
  const { orderDetails, orderIds } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      deliver: {
        orderIds,
        orderDetails,
      },
    },
  };
});

addReducer('setOrderIdsForDeliver', (state, _, payload) => {
  const { transactions } = state;
  const { orderIds } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      deliver: {
        ...transactions.deliver,
        orderIds,
      },
    },
  };
});

addReducer('deliverOrders', async ({ transactions }, dispatch, { date }) => {
  try {
    const {
      deliver: { orderIds },
    } = transactions;
    dispatch.setTransactionLoadStatus(true);

    const deliveredOrders = await SwipeRxPt.instance.orders.deliver(orderIds, date);

    await dispatch.fetchDataTableRows({ name: 'orders' });

    dispatch.setTransactionSuccessMessage({
      message: `Order ${getPoNumberTexts(deliveredOrders)} ${pluralize(
        'has',
        deliveredOrders.length,
        'have',
      )} been marked as delivered.`,
    });
  } catch (e) {
    recordException(e, 'deliverOrders', { date });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('updateInvoiceDates', async (_, dispatch, { orderIds, date }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    await SwipeRxPt.instance.orders.updateInvoiceDates({ order_ids: orderIds, invoiced_at: date });

    dispatch.fetchDataTableRows({ name: 'orders' });
    dispatch.setOrderInvoiceUpdateState({
      orderIds: [] as number[],
      orderDetails: [] as number[],
    });

    dispatch.setTransactionSuccessMessage({
      message: 'Invoices has been updated.',
    });
  } catch (e) {
    recordException(e, 'updateInvoiceDates', { orderIds, date });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('initializeOrderItemFlagSummary', async (_, dispatch, { order, initialItems, newItems }) => {
  try {
    const indexedNewItems = keyBy(newItems, 'id');

    const changes: Array<{
      name: string;
      package: string;
      stockout?: { before: boolean; after: boolean };
      stockLimit?: { before: number; after: number };
      priceChange?: { before: number; after: number };
      discountRateChange?: { before: number; after: number };
    }> = [];

    initialItems.forEach((item) => {
      const {
        id,
        product,
        quantity,
        selling_price,
        discount_rate,
        previous_quantity,
        previous_selling_price,
        previous_discount_rate,
        is_out_of_stock: initialStockout,
      } = item;

      const {
        is_out_of_stock: newStockout,
        stock_limit: newStockLimit,
        price_change: newPriceChange,
        discount_rate_change: newDiscountRateChange,
      } = indexedNewItems[id];

      const diff: any = {};

      if (!!initialStockout !== newStockout) {
        diff.stockout = { before: initialStockout, after: newStockout };
      }

      const newQuantity = newStockLimit || previous_quantity;
      const newSellingPrice = newPriceChange || previous_selling_price;

      const hasDiscountRateChange = newDiscountRateChange !== null;
      const newDiscountRate = hasDiscountRateChange ? newDiscountRateChange / 100 : previous_discount_rate;

      if (newQuantity && quantity !== newStockLimit) {
        diff.stockLimit = {
          before: quantity,
          after: newQuantity,
        };
      }

      if (newSellingPrice && selling_price !== newPriceChange) {
        diff.priceChange = {
          before: selling_price,
          after: newSellingPrice,
        };
      }

      if (newDiscountRate !== null && Number((discount_rate * 100).toFixed(2)) !== newDiscountRateChange) {
        diff.discountRateChange = {
          before: discount_rate,
          after: newDiscountRate,
        };
      }

      if (isEmpty(diff)) {
        indexedNewItems[id].discount_rate_change = hasDiscountRateChange ? newDiscountRateChange / 100 : discount_rate;

        return;
      }

      indexedNewItems[id].price_change = newSellingPrice;
      indexedNewItems[id].stock_limit = newQuantity;
      indexedNewItems[id].discount_rate_change = newDiscountRate;

      changes.push({
        id,
        name: product.name,
        package: product.package,
        ...diff,
      });
    });

    const { id, po_number, ordered_at } = order;

    dispatch.setOrderItemFlagSummary({
      id,
      changes,
      poNumber: po_number,
      orderDate: ordered_at,
      orderItemUpdates: Object.values(indexedNewItems),
    });
  } catch (e) {
    recordException(e, 'initializeOrderItemFlagSummary', { order, initialItems, newItems });
    dispatch.setTransactionError({ error: e.message });
  }
});

addReducer('setOrderFulfillState', (state, _, payload) => {
  const { transactions } = state;
  const { orderDetails, orderIds } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      fulfill: {
        orderIds,
        orderDetails,
      },
    },
  };
});

addReducer('initializeOrderReturn', async (_, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const data = await SwipeRxPt.instance.orders.retrieve(ids[0], {
      expand: ['pharmacy', 'items', 'items.product', 'invoice', 'distributor', 'logistics_po_delivery'],
    });

    if (data.is_returned) {
      dispatch.setTransactionError({
        error: `The order is already marked as returned.`,
      });

      return;
    }

    if ([InvoiceStatus.CLAIMED, InvoiceStatus.QUEUED].includes(data.invoice.status)) {
      dispatch.setTransactionError({
        error: `The order invoice status is either claimed or queued.`,
      });

      return;
    }

    const orderItems = keyBy(
      data.items.filter((item: OrderItem) => !item.is_out_of_stock),
      'id',
    );

    dispatch.setOrderReturnState({
      orderId: ids[0],
      orderDetails: [data],
      orderItems,
    });
  } catch (e) {
    recordException(e, 'initializeOrderReturn', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('setOrderReturnState', (state, _, payload) => {
  const { transactions } = state;
  const { orderDetails, orderId, orderItems } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      returnOrder: {
        orderId,
        orderDetails,
        orderItems,
      },
    },
  };
});

addReducer('setOrderIdsForFulfillment', (state, _, payload) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      fulfill: {
        ...transactions.fulfill,
        orderIds: payload.orderIds,
      },
    },
  };
});

addReducer('setOrderIdForReturn', (state, _, payload) => {
  const { transactions } = state;
  const { orderId } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      returnOrder: {
        ...transactions.returnOrder,
        orderId,
      },
    },
  };
});

addReducer('fulfillOrder', async ({ transactions }, dispatch, { invoice, ttf }) => {
  try {
    const {
      fulfill: { orderIds },
    } = transactions;
    dispatch.setTransactionLoadStatus(true);
    const order_id = orderIds[0];

    await SwipeRxPt.instance.orders.fulfill({ order_id, invoice, ttf });
    await dispatch.fetchDataTableRows({ name: 'orders' });

    dispatch.setTransactionSuccessMessage({
      message: 'The order has been fulfilled.',
    });
  } catch (e) {
    recordException(e, 'fulfillOrder', { invoice, ttf });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('updateDocuments', async (state, dispatch, payload) => {
  const {
    transactions: {
      fulfill: { orderIds },
    },
  } = state;
  const orderId = orderIds[0];

  try {
    const { data } = await SwipeRxPt.instance.orders.updateDocuments(orderId, payload);
    await dispatch.fetchDataTableRows({ name: 'orders' });

    dispatch.setTransactionSuccessMessage({
      message: data,
    });

    dispatch.setTransactionLoadStatus(true);
  } catch (e) {
    recordException(e, 'updateDocuments', { payload });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer(
  'returnOrder',
  async ({ transactions }, dispatch, { reason, return_type, item_updates, clearSteps, date }) => {
    try {
      const {
        returnOrder: { orderId },
      } = transactions;
      dispatch.setTransactionLoadStatus(true);

      await SwipeRxPt.instance.orders.return({
        order_id: orderId,
        reason,
        return_type,
        item_updates,
        date,
      });
      await dispatch.fetchDataTableRows({ name: 'orders' });
      dispatch.setTransactionSuccessMessage({
        message: `The order has been returned.`,
      });
    } catch (e) {
      recordException(e, 'returnOrder', { reason, return_type, item_updates, date });
      dispatch.setTransactionError({ error: e.message });
    } finally {
      clearSteps();
      dispatch.setTransactionLoadStatus(false);
    }
  },
);

addReducer('patchOrder', async ({ transactions }, dispatch, { ordered_at }) => {
  try {
    const { orderDetails } = transactions;
    dispatch.setTransactionLoadStatus(true);

    const orderId = orderDetails?.data?.id || 0;
    await SwipeRxPt.instance.orders.patchOrder(orderId, { ordered_at });
    await dispatch.fetchDataTableRows({ name: 'orders' });
    dispatch.setTransactionSuccessMessage({
      message: `The order has been updated.`,
    });
  } catch (e) {
    recordException(e, 'patchOrder', { ordered_at });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('initializeOrderExportToDeliveryPartner', async ({ dataTables }, dispatch, { ids }) => {
  try {
    dispatch.setTransactionLoadStatus(true);

    const orderDetails = getSelectedOrdersByIds(ids, dataTables);

    dispatch.setOrderExportToDeliveryPartnerState({
      orderIds: ids,
      orderDetails,
    });
  } catch (e) {
    recordException(e, 'initializeOrderExportToDeliveryPartner', { ids });
    dispatch.setTransactionError({ error: e.message });
  } finally {
    dispatch.setTransactionLoadStatus(false);
  }
});

addReducer('setOrderExportToDeliveryPartnerState', (state, _, payload) => {
  const { transactions } = state;
  const { orderIds, orderDetails } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      exportToDeliveryPartner: {
        ...transactions.exportToDeliveryPartner,
        orderIds,
        orderDetails,
      },
    },
  };
});

addReducer('setOrderIdsForExportToDeliveryPartner', (state, _, payload) => {
  const { transactions } = state;
  const { orderIds } = payload;

  return {
    ...state,
    transactions: {
      ...transactions,
      exportToDeliveryPartner: {
        ...transactions.exportToDeliveryPartner,
        orderIds,
      },
    },
  };
});

addReducer(
  'sendToDispatcherDeliveryPartner',
  async ({ dataTables, transactions }, dispatch, { logistics_delivery_partner_id }) => {
    try {
      const {
        exportToDeliveryPartner: { orderIds },
        orderDeliveryPartner: { selected },
      } = transactions;

      const { page_size, sort_by, order_by } = dataTables.orders.params || {};

      const orderListExportParams: Record<string, any> = {
        page_size,
        sort_by,
        order_by,
        order_ids: orderIds,
      };
      // Remove falsy valued field
      Object.keys(orderListExportParams).forEach(
        (key) => [null, '', undefined].includes(orderListExportParams[key]) && delete orderListExportParams[key],
      );

      const dispatchedOrders = await SwipeRxPt.instance.orders.sendToDispatcherDeliveryPartner(
        orderListExportParams,
        logistics_delivery_partner_id,
      );

      dispatch.setTransactionSuccessMessage({
        message: `Order ${getPoNumberTexts(dispatchedOrders)} ${pluralize(
          'has',
          dispatchedOrders.length,
          'have',
        )} been dispatched to ${selected?.name}`,
      });

      dispatch.searchDeliveryPartnerSelected({ selected: undefined });

      dispatch.setTransactionLoadStatus(true);

      await dispatch.fetchDataTableRows({ name: 'orders' });
    } catch (e) {
      const error = e as Error;
      recordException(error, 'exportToDeliveryPartner', { logistics_delivery_partner_id });
      dispatch.setTransactionError({ error: error.message });
    } finally {
      dispatch.setTransactionLoadStatus(false);
    }
  },
);

addReducer('searchDeliveryPartner', async (_, dispatch, payload) => {
  try {
    const data = await SwipeRxPt.instance.orders.getDeliveryPartners(payload);
    dispatch.setDeliveryPartnerData({ data });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'searchDeliveryPartner', payload);
    dispatch.setTransactionError({ error: error.message });
  }
});
addReducer('setDeliveryPartnerData', (state, _, { data }) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      orderDeliveryPartner: {
        ...transactions.orderDeliveryPartner,
        data,
      },
    },
  };
});
addReducer('searchDeliveryPartnerSelected', (state, _, { selected }) => {
  const { transactions } = state;

  return {
    ...state,
    transactions: {
      ...transactions,
      orderDeliveryPartner: {
        ...transactions.orderDeliveryPartner,
        selected,
      },
    },
  };
});

addReducer('requeueShipment', async (_, dispatch, { order_ids }) => {
  try {
    await SwipeRxPt.instance.orders.postRequeueShipment({ order_ids });
    dispatch.setTransactionSuccessMessage({
      message: `${pluralize('Order', order_ids.length, 'Orders')} ${pluralize(
        'has',
        order_ids.length,
        'have',
      )} been successfully requeued for shipment`,
    });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'requeueShipment', { order_ids });
    dispatch.setTransactionError({ error: error.message });
  }
});

addReducer('manualCreateAWB', async (_, dispatch, { order_ids }) => {
  try {
    await SwipeRxPt.instance.orders.postManualShipment({ order_ids });
    dispatch.setTransactionSuccessMessage({
      message: `AWB has been successfully created`,
    });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'manualCreateAWB', { order_ids });
    dispatch.setTransactionError({ error: error.message });
  }
});

addReducer('mergeShipment', async (_, dispatch, { order_ids }) => {
  try {
    await SwipeRxPt.instance.orders.postMergeShipment({ order_ids });
    dispatch.setTransactionSuccessMessage({
      message: `${pluralize('Order', order_ids.length, 'Orders')} ${pluralize(
        'has',
        order_ids.length,
        'have',
      )} been successfully merged for shipment`,
    });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'mergeShipment', { order_ids });
    dispatch.setTransactionError({ error: error.message });
  }
});

addReducer('deleteShipment', async (_, dispatch, { order_ids }) => {
  try {
    await SwipeRxPt.instance.orders.postDeleteShipment({ order_ids });
    dispatch.setTransactionSuccessMessage({
      message: `${pluralize('Order', order_ids.length, 'Orders')} shipment ${pluralize(
        'has',
        order_ids.length,
        'have',
      )} been successfully deleted`,
    });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'deleteShipment', { order_ids });
    dispatch.setTransactionError({ error: error.message });
  }
})

/* addReducer('setUploadTrackingNumberDialog', async (state, _, isDialogOpen) => {
  const { transactions } = state;
  return {
    ...state,
    transactions: {
      ...transactions,
      uploadTrackingNumber: {
        ...transactions.uploadTrackingNumber,
        isDialogOpen,
      },
    },
  };
});

addReducer('setUploadTrackingNumberUploading', async (state, _, isUploading) => {
  const { transactions } = state;
  return {
    ...state,
    transactions: {
      ...transactions,
      uploadTrackingNumber: {
        ...transactions.uploadTrackingNumber,
        isUploading,
      },
    },
  };
});

addReducer('bulkTrackingNumbers', async (_, dispatch, { file }) => {
  try {
    if (!file) {
      throw new Error('File CSV is required');
    }

    dispatch.setUploadTrackingNumberUploading(true);

    const { valid_rows, invalid_rows, errors } = await SwipeRxPt.instance.orders.bulkTrackingNumber(file);

    const messages: string[] = [];
    if (valid_rows > 0) {
      messages.push(
        `${valid_rows} ${pluralize('Order has', valid_rows, 'Orders have')} been updated the tracking numbers`,
      );
    }
    if (invalid_rows > 0) {
      messages.push(`${invalid_rows} ${pluralize('Order', invalid_rows)} failed`);
    }

    dispatch.setTransactionSuccessMessage({
      message: messages.join(', '),
    });
    if (errors.length) {
      dispatch.setTransactionWarning({
        warning: errors.map((e) => `row: ${e.row}. ${e.errors.join(',')}`).join(`\n`),
      });
    }

    dispatch.setUploadTrackingNumberDialog(false);

    await dispatch.fetchDataTableRows({ name: 'orders' });
  } catch (e) {
    const error = e as Error;
    recordException(error, 'bulkTrackingNumbers', { file });
    dispatch.setTransactionError({ error: error.message });
  } finally {
    dispatch.setUploadTrackingNumberUploading(false);
    dispatch.setTransactionLoadStatus(false);
  }
}); */

addReducer('fetchTransactionRowCount', async (state, dispatch, params) => {
  const { transactions } = state;
  try {
    const { status: initialStatusParam } = params || {};
    const { status, fulfillmentStatus, isReturned, isDelayed } = parseStatus(initialStatusParam);

    const { total_count } = await SwipeRxPt.instance.orders.getTotalRowCount({
      ...params,
      status,
      fulfillment_status: fulfillmentStatus,
      is_returned: isReturned,
      is_delayed: isDelayed,
    });
    return {
      ...state,
      transactions: {
        ...transactions,
        totalRowCount: total_count,
      },
    };
  } catch (err) {
    recordException(err, 'fetchTransactionRowCount', { params });
  }
  return {
    ...state,
    transactions: {
      ...transactions,
      totalRowCount: undefined,
    },
  };
});

addReducer('resetTransactionRowCount', (state) => {
  const { transactions } = state;
  return { ...state, transactions: { ...transactions, totalRowCount: undefined } };
});
