import {
  TRANSACTION_ACTION_ITEM_MAP_BY_STATUS,
  TRANSACTION_MEMBER_ROLES,
  UserRoles,
} from 'common/constants';
import { CardTableRowFooterAction } from 'common/components/CardTable/interfaces';
import { ColorType, TransactionAction } from 'types';

export const isAllowed = (
  role: UserRoles,
  allowedRoles: UserRoles[] = TRANSACTION_MEMBER_ROLES,
): boolean => [...allowedRoles, UserRoles.SUPER_ADMIN].includes(role);

const getNewPrimaryAction = (
  subActions: (TransactionAction | undefined)[],
): TransactionAction | undefined => {
  if (!subActions.length) {
    return undefined;
  }

  const newSubActions = [...subActions];
  let newPrimaryAction = newSubActions.shift();

  const isSeparator = newPrimaryAction === undefined;

  if (isSeparator) {
    newPrimaryAction = newSubActions.shift();
  }

  if (!newPrimaryAction) {
    return getNewPrimaryAction(newSubActions);
  }

  newPrimaryAction = {
    ...newPrimaryAction,
    subActions: newSubActions,
  };

  return newPrimaryAction;
};

const getPrimaryAction = (
  initialPrimaryAction: TransactionAction,
  role: UserRoles,
): TransactionAction | undefined => {
  const { allowedRoles, subActions } = initialPrimaryAction;

  if (isAllowed(role, allowedRoles)) {
    return initialPrimaryAction;
  }

  if (!subActions) {
    return undefined;
  }

  const newPrimaryAction = getNewPrimaryAction(subActions);

  if (!newPrimaryAction) {
    return undefined;
  }

  if (newPrimaryAction && isAllowed(role, newPrimaryAction.allowedRoles)) {
    return newPrimaryAction;
  }

  return getPrimaryAction(newPrimaryAction, role);
};

export const getTransactionActionItems = (
  userRole: UserRoles,
  status: string,
): CardTableRowFooterAction[] => {
  const actionList = TRANSACTION_ACTION_ITEM_MAP_BY_STATUS[status];

  if (!actionList) {
    return [];
  }

  const splitActions = actionList.multiple.reduce<TransactionAction[]>(
    (actions, initialPrimaryAction): TransactionAction[] => {
      const primaryAction = getPrimaryAction(initialPrimaryAction, userRole);

      if (primaryAction) {
        return [...actions, primaryAction];
      }

      return actions;
    },
    [],
  );

  const singleActions = splitActions.length
    ? []
    : actionList.single.filter((item) =>
        isAllowed(userRole, item.allowedRoles),
      );

  const result = [
    ...singleActions.map(({ actionItem: { name, text, disabled } }) => ({
      color: ColorType.PRIMARY,
      isSecondary: true,
      name,
      text,
      disabled,
    })),
    ...splitActions.map(
      ({ subActions, actionItem: { name, text, disabled } }) => {
        const items = !subActions
          ? []
          : subActions
              .filter((item) => {
                if (!item) {
                  return true;
                }

                return isAllowed(userRole, item.allowedRoles);
              })
              .map((action) => {
                if (!action) {
                  return undefined;
                }
                return {
                  color: ColorType.PRIMARY,
                  ...action.actionItem,
                };
              });

        if (!items[items.length - 1]) {
          items.pop();
        }

        return {
          color: ColorType.PRIMARY,
          name,
          text,
          items,
          disabled,
        };
      },
    ),
  ];

  if (['accepted', 'dispatched'].includes(status) && result.length === 2) {
    result.pop();
  }

  return result;
};
