/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Order, OrderItem } from 'common/services/apis/v3';
import { CompactOrderItem } from './OrderInvoiceDialog.types';

interface CalculatePageModel {
  startIndex: number;
  lastIndex: number;
  totalPages: number;
  pageIndex: number;
}

export const calculatePageModel = (
  totalCount: number,
  pageIndex: number,
  pageSize: number,
): CalculatePageModel => {
  let _pageIndex = pageIndex < 1 ? 1 : pageIndex;
  _pageIndex = (_pageIndex === 0 ? 1 : _pageIndex) - 1;

  const totalPages = Math.ceil(totalCount / pageSize);

  _pageIndex = _pageIndex >= totalPages ? totalPages - 1 : _pageIndex;

  const startIndex = totalCount !== 0 ? _pageIndex * pageSize + 1 : 0;

  let lastIndex = totalCount !== 0 ? startIndex + pageSize - 1 : 0;
  lastIndex = lastIndex > totalCount ? totalCount : lastIndex;

  _pageIndex += 1;

  return {
    startIndex,
    lastIndex,
    totalPages,
    pageIndex: _pageIndex,
  };
};

export const transformRows = (
  orderDetailsItems: OrderItem[],
): CompactOrderItem[] => {
  return orderDetailsItems?.map((item) => ({
    id: item?.product?.id,
    sku_code: item?.product?.sku_code,
    name: item?.product?.name,
    quantity: item?.quantity,
    net_price: item?.net_price,
  }));
};

export const calculateTotalPrice = (orderDetails: {
  data: Order | null;
}): number | undefined =>
  orderDetails?.data?.items.reduce(
    (acc: number, { net_price, quantity }: OrderItem) =>
      acc + quantity * net_price,
    0,
  );

export const updatePolicy = (
  order: any,
  marketId: string,
  config?: any,
): boolean => {
  let isAllowed = true;

  if (marketId === 'th') {
    const ALLOWED_STATUS = ['pending', 'processing'];

    if (!ALLOWED_STATUS.includes(order?.status)) {
      isAllowed = false;
    }
  }

  return isAllowed;
};
