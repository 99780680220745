import { OptionType } from 'common/interfaces/OptionType.interface';

import { GlobalMoment } from 'common/utils';
import { REGEX, DateFormats } from 'common/constants';
import { ColumnType, maskedText } from 'common/components';
import { LayoutColumnConfig } from '../AdvanceForm.interface';

export const getViewValue = (type: ColumnType, formikValue: any, config: LayoutColumnConfig | undefined): string => {
  switch (type) {
    case ColumnType.TEXT:
    case ColumnType.NUMBER:
    case ColumnType.LINK:
    case ColumnType.MARKDOWN:
      return formikValue;
    case ColumnType.FILEUPLOAD:
      return formikValue;
    case ColumnType.LOCALE_DATE:
      return formikValue
        ? GlobalMoment.instance.momentL(formikValue).format(config?.dateFormat || DateFormats.YYYY_MM_DD)
        : formikValue;
    case ColumnType.ARRAY:
      return formikValue.split(',').join(', ');
    case ColumnType.SWITCH: {
      if (config?.selectValue) {
        return formikValue ? config?.selectValue?.yes || 'yes' : config?.selectValue?.no || 'no';
      }
      return formikValue ? 'yes' : 'no';
    }
    case ColumnType.SELECT:
      return typeof formikValue === 'string'
        ? formikValue.split(config?.fieldMultiSelectProps.delimiter || ',').join(', ')
        : formikValue.map((option: OptionType) => option.label).join(', ');
    case ColumnType.MASK: {
      const isValid = REGEX.NUMBER_ONLY.test(formikValue);
      return isValid ? maskedText(formikValue, config?.mask) : formikValue;
    }

    default:
      return JSON.stringify(formikValue);
  }
};
