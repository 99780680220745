import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { CardTablePageChangeHandler } from 'common/components/CardTable/types';

import { S } from './PageJump.styles';

interface Props {
  currentPage: number;
  onChange?: CardTablePageChangeHandler;
  disabled?: boolean;
}

export const CardTablePageJump: React.FC<Props> = ({ currentPage, disabled, onChange }) => {
  const [selectPage, setSelectPage] = useState<number | ''>('');

  useEffect(() => {
    setSelectPage('');
  }, [currentPage])

  const handleGoClick = (): void => {
    if (onChange && selectPage && currentPage !== selectPage) {
      onChange(selectPage);
    }
  };

  const handlePageJumpChange = (event: any): void => {
    const { value } = event.target;
    if (value){
      const pageNumber = parseInt(value, 10);
      if (pageNumber && pageNumber > 0) {
        setSelectPage(pageNumber);
      }
    } else {
      setSelectPage('');
    }
  };

  return (
    <S.Wrapper>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <S.Text disabled={!!disabled}>Jump To Page :</S.Text>
        </Grid>
        <Grid item>
          <S.InputPage name="pagejump" onChange={(e) => handlePageJumpChange(e)} value={selectPage} placeholder={`${currentPage}`} />
        </Grid>
        <Grid item>
          <S.GoButton onClick={handleGoClick} disabled={!!disabled} />
        </Grid>
      </Grid>
    </S.Wrapper>
  );
};
