import { PrecursorCancelReason, PrecursorRejectReason } from './PrecursorDetail.type';

export const PrecursorCancelReasonEnglishText = {
    [PrecursorCancelReason.AUTO_CANCEL_AFTER_HOURS]: 'Exceeding submission deadline.'
}

export const PrecursorRejectReasonEnglishText = {
    [PrecursorRejectReason.PHOTO_UNCLEAR]: 'Photo Blurred',
    [PrecursorRejectReason.PRECURSOR_FORMAT_INVALID]: 'SP Format Invalid',
    [PrecursorRejectReason.ADDRESS_PBF_INVALID]: 'PBF Name & Address Invalid',
    [PrecursorRejectReason.NO_SIPNAP]: 'No SIPNAP Number',
    [PrecursorRejectReason.SIPNAP_NOT_MATCH]: 'Invalid SIPNAP Data',
    [PrecursorRejectReason.PHARMACY_DATA_INCOMPLETE]: 'Pharmacy Document Not Complete',
    [PrecursorRejectReason.SPECIMEN_NOT_UPDATED]: 'Specimen Not Updated',
    [PrecursorRejectReason.PHARMACY_DATA_INVALID]: 'Pharmacy Data Invalid',
    [PrecursorRejectReason.APJ_DATA_INVALID]: 'Pharmacy APJ Data Invalid',
    [PrecursorRejectReason.SIGNATURE_BLANK]: 'APJ Signature Blank',
    [PrecursorRejectReason.SIGNATURE_NOT_MATCH]: 'APJ Signature Invalid',
    [PrecursorRejectReason.SIGNATURE_DRY]: 'APJ Signature Not From Ink',
    [PrecursorRejectReason.STAMP_BLANK]: "Pharmacy's Stamp Blank",
    [PrecursorRejectReason.STAMP_NOT_MATCH]: "Pharmacy's Stamp Invalid",
    [PrecursorRejectReason.STAMP_DRY]: "Pharmacy's Stamp Not From Ink",
    [PrecursorRejectReason.WRONG_CORE_MOLECULES]: 'Core Molecules Invalid',
    [PrecursorRejectReason.WRONG_PRODUCT_NAME]: 'Product Name Invalid',
    [PrecursorRejectReason.WRONG_QUANTITY]: 'Product Quantity Invalid',
};

export const PrecursorRejectReasonFreeText = [
    PrecursorRejectReason.PHARMACY_DATA_INCOMPLETE,
    PrecursorRejectReason.SIPNAP_NOT_MATCH,
    PrecursorRejectReason.SPECIMEN_NOT_UPDATED,
    PrecursorRejectReason.PHARMACY_DATA_INVALID,
    PrecursorRejectReason.APJ_DATA_INVALID,
    PrecursorRejectReason.WRONG_CORE_MOLECULES,
    PrecursorRejectReason.WRONG_PRODUCT_NAME,
    PrecursorRejectReason.WRONG_QUANTITY,
]
