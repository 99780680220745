import { addReducer } from 'reactn';
import { recordException } from 'utils/Reporting/Sentry';
import { SwipeRxPt } from 'common/services/apis/v3';
// import { setStorageWithExpiry, getStorageWithExpiry } from 'pages/maintenance/utils/expiringStorage';

import { CrmState } from './interfaces';

export const CRM_INITIAL_STATE: CrmState = {
  loading: false,
  internalCrm: null,
  crmConfig: [],
};

addReducer('fetchInternalCrm', async (_, dispatch, { id, param }, ) => {
  try {
    const crm = await SwipeRxPt.instance.crm.getInternalCrm(id, { build_calculations: param?.build_calculations ?? false});
    dispatch.setInternalCrm(crm);
  } catch (e) {
    recordException(e, 'fetchInternalCrm', { id });
    dispatch.setInternalCrmError({ error: e.message });
  }
});

addReducer('fetchCrmConfiguration', async (_, dispatch, { id }) => {
  // const key = 'PT-CRM-CONFIG';
  // const ttlInMs = 900000;
  try {
    // let crmConfig;
    // const storageConfig = getStorageWithExpiry(key);
    // if (!storageConfig) {
    const response = await SwipeRxPt.instance.crm.getCrmConfig();

    if (response.error) throw new Error(response.error);

    const crmConfig = response.data;
    //   setStorageWithExpiry(key, JSON.stringify(crmConfig), ttlInMs);
    // } else {
    //   crmConfig = JSON.parse(storageConfig);
    // }

    dispatch.setCrmConfig(crmConfig);
  } catch (e) {
    recordException(e, 'fetchCrmConfiguration', { id });
    dispatch.setInternalCrmError({ error: e.message });
  }
});

addReducer('patchInternalCrm', async (_, dispatch, { id, internalCrm }) => {
  try {
    await SwipeRxPt.instance.crm.patchInternalCrm(id, internalCrm);
    dispatch.fetchInternalCrm({ id });
  } catch (e) {
    recordException(e, 'patchInternalCrm', { id, internalCrm });
    dispatch.setInternalCrmError({ error: e.message });
  }
});

addReducer('setInternalCrm', (state, _, payload) => {
  const { crm } = state;
  const {
    swiperx_pt_id_account,
    acquisition,
    transaction_information,
    pharmacy_personnel,
    metabase_links,
    lystloc_visits,
    swiperx_community,
  } = payload;

  return {
    ...state,
    crm: {
      ...crm,
      internalCrm: {
        swiperx_pt_id_account,
        acquisition,
        transaction_information,
        pharmacy_personnel,
        metabase_links,
        lystloc_visits,
        swiperx_community,
      },
    },
  };
});

addReducer('setCrmConfig', (state, _, crmConfig) => {
  const { crm } = state;

  return {
    ...state,
    crm: {
      ...crm,
      crmConfig,
    },
  };
});

addReducer('setInternalCrmError', (state, _, { error }) => {
  const { crm } = state;
  return { ...state, crm: { ...crm, error } };
});

addReducer('setInternalCrmEditMode', (state) => {
  const { crm } = state;
  return { ...state, crm: { ...crm, isEdit: true } };
});

addReducer('setInternalCrmViewMode', (state) => {
  const { crm } = state;
  return { ...state, crm: { ...crm, isEdit: false } };
});
