import React from 'reactn';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { COLORS } from 'common/constants/colors.constant';
import MaintenanceImage from 'common/images/maintenance.svg';
import { useMaintenaceState } from './context';

const MaintenancePage: React.FC = () => {
  const { config } = useMaintenaceState();
  const message = config ? config.message?.en : null;

  return (
    <Container style={{ height: '100vh', width: '100vw', background: COLORS.white }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <img src={MaintenanceImage} alt="page is under maintenance" width={300} height={300} />
        <Typography
          variant="h5"
          align="center"
          style={{ marginTop: 10, marginBottom: 10, fontWeight: 'bold', color: COLORS.black }}
        >
          {message?.title || 'Under Maintenance'}
        </Typography>

        <Typography variant="subtitle1" align="center" style={{ color: COLORS.black }}>
          {message?.description || "The page you're looking for is currently under maintenance and will be back very soon"}
        </Typography>

        <Button
          variant="outlined"
          onClick={(): void => {
            window.location.href = '/';
          }}
          style={{
            marginTop: 40,
            marginBottom: 40,
            background: COLORS.primary,
            color: COLORS.white,
            borderRadius: 20,
            borderColor: COLORS.primary,
            minWidth: 150,
            fontWeight: 'normal',
          }}
        >
          Refresh
        </Button>
      </Grid>
    </Container>
  );
};

export default MaintenancePage;
