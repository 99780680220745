import { FinanceBulkActionItemMapByStatus } from 'types';

import { UserRoles } from './roles.constants';
import { FINANCE_ACTIONS } from './finance-actions.constant';

export const FINANCE_BULK_ACTION_ITEM_MAP_BY_STATUS: FinanceBulkActionItemMapByStatus = {
  all: [
    {
      actionItem: FINANCE_ACTIONS.EXPORT
    }
  ],
  due_for_approval: [
    {
      actionItem: FINANCE_ACTIONS.APPROVE,
      allowedRoles: [
        UserRoles.FINANCE
      ]
    },
    {
      actionItem: FINANCE_ACTIONS.HOLD_TO_APPROVE
    },
    {
      actionItem: FINANCE_ACTIONS.EXPORT
    }
  ],
  due_for_reimbursement: [
    {
      actionItem: FINANCE_ACTIONS.MARK_AS_REIMBURSED,
      allowedRoles: [
        UserRoles.FINANCE
      ]
    },
    {
      actionItem: FINANCE_ACTIONS.EXPORT
    }
  ],
  due_for_disbursement: [
    {
      actionItem: FINANCE_ACTIONS.MARK_AS_DISBURSED,
      allowedRoles: [
        UserRoles.FINANCE
      ]
    },
    {
      actionItem: FINANCE_ACTIONS.HOLD_TO_DISBURSE
    },
    {
      actionItem: FINANCE_ACTIONS.EXPORT
    }
  ],
  previously_failed: [
    {
      actionItem: FINANCE_ACTIONS.EXPORT
    }
  ]
}