/* eslint-disable consistent-return */
import { addReducer } from "reactn"
import { PBFAssignmentState } from "./interfaces";
import { RootState } from "..";

export const PBF_INITIAL_STATE: PBFAssignmentState = {
  global: {
    loading: false,
  }
}

addReducer('setPBFLoading', (state, _, loading): RootState => {
  const { pbfAssignments } = state;
  const { global } = pbfAssignments;

  return {
    ...state,
    pbfAssignments: {
      ...pbfAssignments,
      global: {
        ...global,
        loading,
      }
    }
  }
});
