import keyBy from 'lodash/keyBy';
import { DataTable } from 'common/reducers';

export const getSelectedOrdersByIds = (
  ids: number[],
  dataTables: Record<'orders', DataTable>,
): any[] => {
  const { orders } = dataTables;

  const indexedOrders = keyBy(orders.rows, 'id');
  const selectedOrders: any[] = [];

  ids.forEach((id) => selectedOrders.push(indexedOrders[id]));

  return selectedOrders;
};
