export enum UserRoles {
  ADMIN = 'd033e22ae348aeb5660fc2140aec35850c4da997', // 'admin',
  SUPER_ADMIN = '736a95382da0c1b931dc87529706d25c636954e7', // 'super_admin',
  COMMERCIAL = 'f8ef5300ad1be3f603bf6867791ab8cc6c05c1d8', // 'commercial',
  DISTRIBUTOR = '5ffa7721e6d84150470a179e969643c0618e327a', // 'distributor',
  FINANCE = 'a1cf62af599e2c2403cd6542a3bbe8f828511be8', // 'finance',
  VENDOR_SPECIALIST = '96dc545549bcfd6a959816548d7aa36bdabd19df', // 'vendor_specialist',
  SENIOR_VENDOR_SPECIALIST = '923cbe7847e6c786441da8866e840e1a10976d87', // 'senior_vendor_specialist',
  FULFILLMENT_ADMIN = '371615acf27421922d1be3774b4bda91b7249d4c', // 'fulfillment_admin',
  DISPATCHER = 'bdf70eff0e4d79093bd5f318014dd13348b89cdb', // 'dispatcher',
  TRANSACTION_MANAGER = 'c2554a964e0958d5c3d1ddace81b0f634457f424', // 'transaction_manager',
  CUSTOMER_SUPPORT = 'e3a6ce42f0a81b6dd3f69f49108023247f72145a', // 'customer_support',
  MARKETING = 'a286075043d42dcdce8d6668944e827f7a64024f', // 'marketing',
  PHARMACEUTICAL_DATA_ANALYST = '359e77e3a1cc6d1a9769ef656c884c7067e81873', // 'pharmaceutical_data_analyst',
  DISTRIBUTOR_STAFF = '4f4c273956f6cd90677efb55a306c628f6c21d8b', // 'distributor_staff',
  SALES_SUPPORT = '1f10d27e565cae5d5208ff261556d937eca62a95', // 'sales_support',
  PHARMACIST = 'c0755c8ac8b27b615262ef7d938e0c1e9c83bf5f',
  SALES_SUPERVISOR = 'ca39afb3b9c9db4051e0d48905f59800623994ee',
}

export const TRANSACTION_ROLES = 'enabled'
  ? [
      UserRoles.DISPATCHER,
      UserRoles.VENDOR_SPECIALIST,
      UserRoles.FULFILLMENT_ADMIN,
      UserRoles.TRANSACTION_MANAGER,
      UserRoles.SENIOR_VENDOR_SPECIALIST,
      UserRoles.CUSTOMER_SUPPORT,
      UserRoles.FINANCE,
      UserRoles.DISTRIBUTOR_STAFF,
      UserRoles.PHARMACIST,
    ]
  : [
      UserRoles.DISPATCHER,
      UserRoles.VENDOR_SPECIALIST,
      UserRoles.FULFILLMENT_ADMIN,
      UserRoles.TRANSACTION_MANAGER,
      UserRoles.SENIOR_VENDOR_SPECIALIST,
      UserRoles.CUSTOMER_SUPPORT,
      UserRoles.FINANCE,
      UserRoles.PHARMACIST,
    ];

export const TRANSACTION_ACCESS_ROLES = [
  UserRoles.DISPATCHER,
  UserRoles.VENDOR_SPECIALIST,
  UserRoles.FULFILLMENT_ADMIN,
  UserRoles.TRANSACTION_MANAGER,
  UserRoles.SENIOR_VENDOR_SPECIALIST,
  UserRoles.CUSTOMER_SUPPORT,
  UserRoles.FINANCE,
  UserRoles.DISTRIBUTOR_STAFF,
  UserRoles.PHARMACIST,
];

export const userRolesWithPartialTransactionAccess = [
  UserRoles.VENDOR_SPECIALIST,
  UserRoles.FULFILLMENT_ADMIN,
  UserRoles.SENIOR_VENDOR_SPECIALIST,
  UserRoles.DISPATCHER,
  UserRoles.DISTRIBUTOR_STAFF,
  UserRoles.PHARMACIST,
];

export const TRANSACTION_MEMBER_ROLES = [
  UserRoles.SUPER_ADMIN,
  UserRoles.VENDOR_SPECIALIST,
  UserRoles.FULFILLMENT_ADMIN,
  UserRoles.SENIOR_VENDOR_SPECIALIST,
  UserRoles.TRANSACTION_MANAGER,
  UserRoles.DISTRIBUTOR_STAFF,
  UserRoles.ADMIN,
  UserRoles.PHARMACIST,
];

export const PHARMACY_MEMBER_ROLES = [
  UserRoles.CUSTOMER_SUPPORT,
  UserRoles.FINANCE,
  UserRoles.TRANSACTION_MANAGER,
  UserRoles.SALES_SUPPORT,
  UserRoles.PHARMACIST,
  UserRoles.MARKETING,
  UserRoles.SUPER_ADMIN,
];

export const DISTRIBUTOR_CALENDAR_VIEWER_ROLES = [
  UserRoles.SUPER_ADMIN,
  UserRoles.ADMIN,
  UserRoles.TRANSACTION_MANAGER,
  // UserRoles.VENDOR_SPECIALIST,
];

export const DISTRIBUTOR_CALENDAR_MANAGER_ROLES = [
  UserRoles.SUPER_ADMIN,
  UserRoles.ADMIN,
  UserRoles.TRANSACTION_MANAGER,
];

export const TRANSACTION_AND_CUSTOMER_SUPPORT_MEMBERS = [...TRANSACTION_MEMBER_ROLES, UserRoles.CUSTOMER_SUPPORT];
export const userRolesWithPartialDocumentManagementAccess = [
  UserRoles.VENDOR_SPECIALIST,
  UserRoles.FULFILLMENT_ADMIN,
  UserRoles.SENIOR_VENDOR_SPECIALIST,
  UserRoles.DISTRIBUTOR_STAFF,
];

export const FINANCE_MEMBER_ROLES = [UserRoles.SUPER_ADMIN, UserRoles.FINANCE];

export const PRODUCT_MEMBER_ROLES = [
  UserRoles.SUPER_ADMIN,
  UserRoles.MARKETING,
  UserRoles.PHARMACEUTICAL_DATA_ANALYST,
  UserRoles.PHARMACIST,
];

export const DISTRIBUTOR_MEMBER_ROLES = [
  UserRoles.SUPER_ADMIN,
  UserRoles.PHARMACEUTICAL_DATA_ANALYST,
  UserRoles.MARKETING,
  UserRoles.TRANSACTION_MANAGER,
  UserRoles.SENIOR_VENDOR_SPECIALIST,
  UserRoles.DISTRIBUTOR_STAFF,
  UserRoles.DISTRIBUTOR,
  UserRoles.PHARMACIST,
  UserRoles.ADMIN,
];

export const DISTRIBUTOR_STAFF_ROLES = [UserRoles.DISTRIBUTOR_STAFF, UserRoles.DISTRIBUTOR];

export const DISTRIBUTOR_PRODUCTLIST_MEMBER_ROLES = [
  UserRoles.SUPER_ADMIN,
  UserRoles.PHARMACEUTICAL_DATA_ANALYST,
  UserRoles.SENIOR_VENDOR_SPECIALIST,
  UserRoles.DISTRIBUTOR_STAFF,
  UserRoles.DISTRIBUTOR,
  UserRoles.PHARMACIST,
];

export const userRolesGetPharmacyLinkAccess = [UserRoles.SUPER_ADMIN];

export const userRolesForgotPassLinkAccess = [UserRoles.SUPER_ADMIN];

export const DOCUMENT_MANAGEMENT_REMOVE_UNASSIGNED_DOCUMENT_ROLES = [
  UserRoles.SUPER_ADMIN,
  UserRoles.ADMIN,
  UserRoles.FINANCE,
];

export const DOCUMENT_MANAGEMENT_REMOVE_DOCUMENT_PHARMACY_ROLES = [
  UserRoles.SUPER_ADMIN,
  UserRoles.ADMIN,
  UserRoles.SALES_SUPPORT,
  UserRoles.CUSTOMER_SUPPORT,
];

export const DOCUMENT_MANAGEMENT_VIEW_ALL_DISTRIBUTOR_ROLES = [
  UserRoles.TRANSACTION_MANAGER,
  UserRoles.SUPER_ADMIN,
  UserRoles.FINANCE,
];

export const TRANSACTIONS_VIEW_ALL_DISTRIBUTOR_ROLES = [
  UserRoles.TRANSACTION_MANAGER,
  UserRoles.SUPER_ADMIN,
  UserRoles.FINANCE,
  UserRoles.ADMIN,
];

export const VOUCHER_VIEWER_ROLES = [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.MARKETING];

export const VOUCHER_MANAGER_ROLES = [UserRoles.SUPER_ADMIN];

export const TRANSACTION_LOGISTIC_DELIVERY_ROLES = [UserRoles.SUPER_ADMIN, UserRoles.DISPATCHER];

export const LOYALTY_ROLES = [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.MARKETING];
export const LOYALTY_SPECIAL_CASHBACK_UPLOAD_ROLES = [
  UserRoles.SUPER_ADMIN,
  UserRoles.ADMIN,
  UserRoles.MARKETING,
  UserRoles.SALES_SUPPORT,
];
export const MARKETING_ROLES = [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.MARKETING];

export const CHANGE_MEMBERSHIP_ROLES = [
  UserRoles.SUPER_ADMIN,
  UserRoles.ADMIN,
  UserRoles.TRANSACTION_MANAGER,
  UserRoles.FULFILLMENT_ADMIN,
  UserRoles.SALES_SUPPORT,
];

export const PHARMACY_UPLOAD_ROLES = [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.SALES_SUPPORT];

export const PHARMACY_CREATE_ROLES = [
  UserRoles.FINANCE,
  UserRoles.ADMIN,
  UserRoles.TRANSACTION_MANAGER,
  UserRoles.SALES_SUPPORT,
  UserRoles.PHARMACIST,
  UserRoles.PHARMACEUTICAL_DATA_ANALYST,
  UserRoles.MARKETING,
  UserRoles.SUPER_ADMIN,
];

export const CRM_UPLOAD_ROLES = [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.SALES_SUPPORT];

export const CREDIT_LIMIT_REQUEST_ROLES = [UserRoles.SUPER_ADMIN, UserRoles.ADMIN];

export const TRANSACTION_LOGISTIC_PHARMACY_AREA_UPLOAD = [UserRoles.SUPER_ADMIN, UserRoles.TRANSACTION_MANAGER];

export const PHARMACY_USERS_VIEW_ROLES = [...PHARMACY_MEMBER_ROLES, UserRoles.ADMIN, UserRoles.SALES_SUPERVISOR];
