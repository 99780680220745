import React from 'react';

import DialogContentText from '@material-ui/core/DialogContentText';

import { ColorType } from 'types';
import { Button } from 'common/components';

import { Dialog } from './Dialog.component';

interface Props {
  onClose: () => void;
  open: boolean;
  text: string;
  title: string;
}

export const AlertDialog: React.FC<Props> = ({
  onClose,
  open,
  text,
  title,
}) => {
  const renderActions = (): JSX.Element => (
    <>
      <Button onClick={onClose} color={ColorType.PRIMARY} isSecondary autoFocus>
        OK
      </Button>
    </>
  );

  const renderContent = (): JSX.Element => (
    <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
  );

  return (
    <Dialog
      maxWidth="sm"
      onClose={onClose}
      open={open}
      renderActions={renderActions}
      renderContent={renderContent}
      showCloseButton={false}
      title={title}
    />
  );
};
