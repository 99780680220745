import { addReducer } from 'reactn';

import { SwipeRxPt } from 'common/services/apis/v3/swipe-rx-pt';

import { VirutalAccountsState } from './interfaces';

export const VIRTUAL_ACCOUNTS_INITIAL_STATE: VirutalAccountsState = {
  virtualAccountsHashMap: {},
};

addReducer(
  'fetchVirtualAccountsByPharmacyId',
  async (state, _, pharmacy_id) => {
    const targetVirtualAccounts = ['BCA', 'MANDIRI'];
    const { virtualAccounts } = state;
    const { virtualAccountsHashMap } = virtualAccounts;

    try {
      const data = await SwipeRxPt.instance.organizations.fetchVirtualAccountsById(
        pharmacy_id,
      );

      virtualAccountsHashMap[pharmacy_id] = data.filter((info: any) =>
        targetVirtualAccounts.includes(info.bank),
      );

      return {
        ...state,
        virtualAccounts: {
          ...virtualAccounts,
          virtualAccountsHashMap: {
            ...virtualAccountsHashMap,
          },
        },
      };
    } catch ({ message }) {
      return {
        ...state,
        virtualAccounts: {
          ...virtualAccounts,
          virtualAccountsHashMap: {
            ...virtualAccountsHashMap,
          },
          error: message,
        },
      };
    }
  },
);
