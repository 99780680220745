/* eslint-disable no-restricted-globals */
import React from 'reactn';

import { CardTableDetailButton } from 'common/components/CardTable';
import { CardTableColumn } from 'common/components/CardTable/interfaces';
import { CardTableCellType } from 'common/components/CardTable/types';
import { ColorType } from 'types';
import { Badge } from 'common/components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getMarketPath } from 'utils/get-router-path';
import { useUpdateQueryParams } from 'hooks';
import { OrderPrecursorStatus } from '../types';
import { PrecursorCancelReason, PrecursorRejectReason, PrecursorRejectReasonObject } from '../components/PrecursorDetail/PrecursorDetail.type';
import {
  PrecursorCancelReasonEnglishText,
  PrecursorRejectReasonEnglishText,
} from '../components/PrecursorDetail/PrecursorDetail.constant';
import { renderDocumentType } from '../utils/column-render.util';

interface PrekursorStatusLabelConfig {
  color: ColorType;
  bg: string;
  text: string;
}

export const usePrekursorApprovalDataTableColumns = (): CardTableColumn[] => {
  const { params } = useRouteMatch();
  const {
    queryParams: { precursor_status: precursorStatus },
  } = useUpdateQueryParams();
  const precursorStatusList = (precursorStatus as string)?.split(',');
  const history = useHistory();
  const getLabelConfig = (status: OrderPrecursorStatus): PrekursorStatusLabelConfig => {
    switch (status) {
      case OrderPrecursorStatus.DOCUMENT_REQUIRED: {
        return {
          color: ColorType.WARNING,
          bg: 'rgba(254, 172, 26, 0.19)',
          text: 'Document not Uploaded',
        };
      }
      case OrderPrecursorStatus.DOCUMENT_WAITING_APPROVAL:
      case OrderPrecursorStatus.DOCUMENT_RESUBMITTED: {
        return {
          color: ColorType.WARNING,
          bg: 'rgba(254, 172, 26, 0.19)',
          text: 'Waiting for Approval',
        };
      }
      case OrderPrecursorStatus.DOCUMENT_CANCELLED: {
        return {
          color: ColorType.GRAY,
          bg: 'rgba(0, 0, 0, 0.08)',
          text: 'Cancelled Document',
        };
      }
      case OrderPrecursorStatus.DOCUMENT_REJECTED: {
        return {
          color: ColorType.DANGER,
          bg: '#FFEBEB',
          text: 'Rejected Document',
        };
      }
      case OrderPrecursorStatus.DOCUMENT_APPROVED: {
        return {
          color: ColorType.SUCCESS,
          bg: 'rgba(20, 196, 125, 0.1)',
          text: 'Approved Document',
        };
      }
      default: {
        return {
          color: ColorType.WARNING,
          bg: 'rgba(254, 172, 26, 0.19)',
          text: '-',
        };
      }
    }
  };

  const isWaitingApproval = precursorStatusList?.some((item) =>
    [OrderPrecursorStatus.DOCUMENT_WAITING_APPROVAL, OrderPrecursorStatus.DOCUMENT_RESUBMITTED].includes(
      item as OrderPrecursorStatus,
    ),
  );

  const columns: CardTableColumn[] = [
    {
      path: 'pharmacy.name',
      text: 'Pharmacy Name',
      sort: true,
      width: 220,
    },
    {
      path: 'po_number',
      text: 'Order ID',
      sort: true,
      width: 150,
    },
    {
      path: 'ordered_at',
      text: 'Order Date',
      type: CardTableCellType.DATETIME,
      sort: true,
      width: 260,
    },
    {
      path: 'updated_at',
      text: 'Updated Date',
      type: CardTableCellType.DATETIME,
      sort: true,
      width: 260,
    },
    {
      path: 'submission',
      text: 'Submission',
      sort: false,
      width: 250,
      render: (_, data) => {
        const label: any = {
          [OrderPrecursorStatus.DOCUMENT_WAITING_APPROVAL]: 'New Submission',
          [OrderPrecursorStatus.DOCUMENT_RESUBMITTED]: 'Resubmitted',
        };
        return label[data?.precursor_status?.status];
      },
    },
    {
      id: 'document_type',
      text: 'Surat Pesanan',
      width: 270,
      render: renderDocumentType,
    },
    {
      path: 'precursor_status.status',
      text: 'Document Status',
      sort: false,
      width: 250,
      render: (_, data) => {
        const config = getLabelConfig(data?.precursor_status?.status);
        return (
          <Badge light color={config.color} bg={config.bg}>
            {config.text}
          </Badge>
        );
      },
    },
    {
      path: 'precursor_status.reason',
      text: 'Rejection Reason',
      type: CardTableCellType.DATETIME,
      sort: false,
      render: (_, data) => {
        if (!data.precursor_status) return '';
        // show any description value if it's exist
        if (data.precursor_status.description) return data.precursor_status.description;
        // else, check reasons data
        const reasons = data.precursor_status.reason;
        if (!reasons || reasons.length === 0) return '-';

        return reasons
          .map((reason: PrecursorRejectReasonObject) => {
            if (Object.values(PrecursorCancelReason).includes(reason.name as PrecursorCancelReason))
              return PrecursorCancelReasonEnglishText[reason.name as PrecursorCancelReason];

            const reasonText = PrecursorRejectReasonEnglishText[reason.name as PrecursorRejectReason];
            const reasonTextAdditional = reason.text ? ` - ${reason.text}` : '';
            return reasonText + reasonTextAdditional;
          })
          .join(', ');
      },
      width: 260,
    },
    {
      id: 'detail_button',
      render: (_, data) => {
        const handleClick = (): void => {
          history.push(`${getMarketPath(params.marketId)}/transactions/precursor-detail/${data.id}`, {
            previousPath: history.location.pathname + history.location.search,
          });
        };

        return <CardTableDetailButton dataId={data.id} onClick={handleClick} />;
      },
    },
  ];

  if (!isWaitingApproval) {
    columns.splice(3, 2);
  } else {
    columns.splice(2, 1);
  }
  return columns;
};
