import {
  userRolesWithPartialDocumentManagementAccess,
  userRolesWithPartialTransactionAccess,
  DOCUMENT_MANAGEMENT_VIEW_ALL_DISTRIBUTOR_ROLES,
  TRANSACTIONS_VIEW_ALL_DISTRIBUTOR_ROLES,
} from 'common/constants';

export type DistributorTitlePage = 'FINANCE' | 'DOCUMENT_MANAGEMENT' | 'TRANSACTIONS';

export enum DistributorTitleSource {
  FINANCE = 'finance',
  DOCUMENT_MANAGEMENT = 'documentManagement',
  TRANSACTIONS = 'transactions',
};

export enum FetchDistributorDispatch {
  FINANCE = 'fetchFinanceDistributor',
  DOCUMENT_MANAGEMENT = 'fetchDocumentManagementDistributor',
  TRANSACTIONS = 'fetchTransactionDistributor',
};

export enum DistributorDataTable {
  FINANCE = 'finance',
  DOCUMENT_MANAGEMENT = 'document-management',
  TRANSACTIONS = 'orders',
}

export enum SetDistributorDispatch {
  FINANCE = 'setFinanceDistributor',
  DOCUMENT_MANAGEMENT = 'setDocumentManagementDistributor',
  TRANSACTIONS = 'setTransactionDistributor',
};

export const VIEW_ALL_STATUS_DISTRIBUTOR = {
  FINANCE: true,
  DOCUMENT_MANAGEMENT: false,
  TRANSACTIONS: true,
};

export const VIEW_ALL_DISTRIBUTOR = {
  FINANCE: [],
  DOCUMENT_MANAGEMENT: DOCUMENT_MANAGEMENT_VIEW_ALL_DISTRIBUTOR_ROLES,
  TRANSACTIONS:  TRANSACTIONS_VIEW_ALL_DISTRIBUTOR_ROLES,
};

export const PARTIAL_ACCESS_ROLES = {
  FINANCE: [],
  DOCUMENT_MANAGEMENT: userRolesWithPartialDocumentManagementAccess,
  TRANSACTIONS: userRolesWithPartialTransactionAccess,
};
