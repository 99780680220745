import React from 'react';

import { FormikTouched, FormikErrors, FormikValues } from 'formik';
import { S } from './field-error-message.styles';

interface Props {
  style?: React.CSSProperties;
  className?: string;
  touched?: FormikTouched<FormikValues> | boolean;
  error?: FormikErrors<FormikValues> | string;
}

export const FieldErrorMessage: React.FC<Props> = ({ touched, error, ...props }) => {
  const isFieldValidationFailed = touched && error;

  return !isFieldValidationFailed ? null : <S.Text {...props}>{error}</S.Text>;
};
