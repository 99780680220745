import {
  ICreditLimitRequest,
  IMutableCreditLimitRequest,
  CreditLimitApprovePayload,
  CreditLimitRejectPayload,
} from 'common/interfaces/CreditLimitRequest.interface';
import { ISearchable, ListResponse } from '../../interfaces';
import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { IMutable } from '../../interfaces/IMutable.interface';

export class SwiperxPtCreditLimitRequest
  implements ISearchable<ICreditLimitRequest>, IMutable<IMutableCreditLimitRequest> {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.CREDIT_LIMIT_REQUEST;
  }

  async list(params: any): Promise<ListResponse<ICreditLimitRequest>> {
    return this.base.get(this.resource, { ...params });
  }

  async accept(id: any): Promise<ListResponse<ICreditLimitRequest>> {
    return this.base.postV2(`${this.resource}/${id}/approve`, {});
  }

  async approve(id: any, payload: CreditLimitApprovePayload): Promise<ListResponse<ICreditLimitRequest>> {
    return this.base.postV2(`${this.resource}/${id}/approve`, payload);
  }

  async reject(id: any, payload: CreditLimitRejectPayload): Promise<ListResponse<ICreditLimitRequest>> {
    return this.base.postV2(`${this.resource}/${id}/reject`, payload);
  }

  async delete(id: number): Promise<ICreditLimitRequest | null> {
    return this.base.delete(`${this.resource}/${id}/delete`);
  }

  // eslint-disable-next-line class-methods-use-this
  async search(params: any): Promise<ListResponse<ICreditLimitRequest>> {
    throw new Error('Not yet implemented');
  }

  // eslint-disable-next-line class-methods-use-this
  async createOrUpdate(params: any): Promise<ICreditLimitRequest> {
    throw new Error('Not yet implemented');
  }
}
