import React from 'react';

import { TextFieldProps } from '@material-ui/core/TextField';

import { S } from './field-input.styles';

export type FieldInputProps = TextFieldProps;

export interface OptionalProps {
  height?: string;
  mask?: string;
  minWidth?: number | string;
  maxLength?: number | string;
}

export const FieldInput: React.FC<FieldInputProps & OptionalProps> = ({
  label,
  placeholder,
  variant,
  maxLength,
  ...props
}) => {
  const InputLabelProps =
    label && placeholder
      ? {
          shrink: true,
        }
      : undefined;

  return (
    <S.Input
      data-testid={`input-${label}`}
      label={label}
      placeholder={placeholder}
      variant={variant}
      InputLabelProps={InputLabelProps}
      inputProps={{ maxLength }}
      {...props}
    />
  );
};

FieldInput.defaultProps = {
  variant: 'outlined',
};
