import React, { useDispatch, useGlobal, useState } from 'reactn';

import { Button, Dialog } from 'common/components';
import { ColorType } from 'types';
import { pluralize } from 'common/utils';

import { SplitOrderItemConfirmationModal } from './SplitOrderItemConfirmationModal.component';
import { S } from './SplitOrderItemModal.styles';

export const SplitOrderItemModal: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);

  const [{ split }] = useGlobal('transactions');
  const setOrderItemsForSplit = useDispatch('setOrderItemsForSplit');

  const { poNumber, orderItems } = split;

  const handleClose = (): void => {
    setOrderItemsForSplit({
      orderId: 0,
      poNumber: '',
      orderItems: [],
    });

    setSubmitted(false);
  };

  const handleSubmit = (): void => {
    setSubmitted(true);
  };

  const renderContent = (): JSX.Element => (
    <>
      <S.Description>
        You are about to split the following order
        <br />
        {pluralize('item', orderItems.length)} from order{' '}
        <strong>{poNumber}</strong>.
        <br />
        <br />
        Please review before submitting.
      </S.Description>
      <S.ListWrapper hideScroll={orderItems.length < 4}>
        {orderItems.map(({ product }) => (
          <S.ListItem>
            <S.ListHeader>{product.name}</S.ListHeader>
            <S.ListBody>{product.package}</S.ListBody>
          </S.ListItem>
        ))}
      </S.ListWrapper>
    </>
  );

  const renderActions = (): JSX.Element => (
    <Button color={ColorType.PRIMARY} onClick={handleSubmit}>
      Submit
    </Button>
  );

  return (
    <>
      <Dialog
        open={!!poNumber && !submitted}
        primary
        title="Split Order Items"
        onClose={handleClose}
        renderActions={renderActions}
        renderContent={renderContent}
      />
      <SplitOrderItemConfirmationModal open={submitted} onClose={handleClose} />
    </>
  );
};
