import { OrderCancelFormState } from './use-order-cancel-form';

type OrderCancelFormKeys = keyof OrderCancelFormState;

interface FieldOptions {
  name: OrderCancelFormKeys;
  type: 'select' | 'date';
  options?: CancelOptions[];
  label?: string;
}

interface CancelOptions {
  value: string;
  label: string;
}

export const cancelOptions: CancelOptions[] = [
  {
    value: 'pharmacy request to cancel',
    label: 'Canceled - Pharmacy request to cancel',
  },
  {
    value: 'the system creates double order',
    label: 'Canceled - The system creates double order',
  },
  {
    value: 'the area is not covered',
    label: 'Canceled - The area is not covered',
  },
  {
    value: 'below minimum invoice',
    label: 'Canceled - Below minimum invoice',
  },
  {
    value: 'price/quantity changed',
    label: 'Canceled - Price/Quantity changed',
  },
  {
    value: 'pharmacy documents problem',
    label: 'Canceled - Pharmacy documents problem',
  },
  {
    value: 'tax problem',
    label: 'Canceled - Tax problem',
  },
  {
    value: 'full return',
    label: 'Canceled - Full return',
  },
  {
    value: 'failed delivery',
    label: 'Canceled - Failed delivery',
  },
  {
    value: 'no sp',
    label: 'Canceled - No SP',
  },
  {
    value: 'overdue shipment',
    label: 'Canceled - Overdue shipment',
  },
  {
    value: 'stockout items',
    label: 'Canceled - Stockout items',
  },
  {
    value: 'near ed',
    label: 'Canceled - Near ED',
  },
  {
    value: 'no confirmed payment',
    label: 'Canceled - No confirmed payment',
  },
];

export const fieldOptions: FieldOptions[] = [
  {
    name: 'reason',
    type: 'select',
    options: cancelOptions,
    label: 'Reason',
  },
  {
    name: 'date',
    type: 'date',
  },
];
