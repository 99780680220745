/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { ChevronRight } from '@material-ui/icons';

import { S } from './CardTableDetailButton.styles';

interface Props {
  onClick: () => void;
  dataId: number;
}

export const CardTableDetailButton: React.FC<Props> = ({ onClick, dataId }) => (
  <S.Wrapper onClick={onClick} data-testid={`details-btn-${dataId}`}>
    Details <ChevronRight />
  </S.Wrapper>
);
