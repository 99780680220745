/* eslint-disable no-console */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

import React, { useGlobal } from 'reactn';

import { ButtonToggleFilter } from 'common/components/ButtonToggleFilter';
import { DataTable, TableFilterCard, ShowIf } from 'common/components';

import { SearchWithCriteria, CriteriaOption } from 'common/components/SearchRelated';

import { ActionMenuItem } from 'common/components/ActionMenu/interfaces';
import { CardTableActionSelectHandler } from 'common/components/CardTable/types';
import { TransactionActionName } from 'types';
import {
  usePrekursorApprovalDataTableColumns,
  usePrekursorApprovalDataTableRowFooter,
  useTransactionPrekursorApprovalStatusFilter,
} from '../../hooks';
import { OrderDataBlank } from '../order-data-table-blank';

interface PrekursorApprovalProps {
  queryParams: any;
  bulkActions: ((ActionMenuItem & {
    name: TransactionActionName;
  }) | undefined)[];
  defaultActions: ((ActionMenuItem & {
    name: TransactionActionName;
  }) | undefined)[];
  handleBulkActionSelect: CardTableActionSelectHandler;

}

export const PrekursorApproval: React.FC<PrekursorApprovalProps> = (
  { 
    queryParams,
    bulkActions,
    handleBulkActionSelect,
    defaultActions,
  }
) => {
  const [{ loading }] = useGlobal('transactions');
  const columns = usePrekursorApprovalDataTableColumns();

  const rowFooter = usePrekursorApprovalDataTableRowFooter();
  const { statusFilterItems, updateStatusFilter } = useTransactionPrekursorApprovalStatusFilter();

  const multipleSelectPrefix = ['PT-DLV-', 'PT-INV-', 'PT-PO-'];
  const multipleSelectSeparator = ' ';
  const multipleSelectCriteria = 'po_number';
  const searchCriteria: CriteriaOption[] = [
    {
      name: 'PO',
      id: 'po_number',
      isArray: true,
      parameterMapping: 'po_numbers',
    },
    { name: 'Invoice', id: 'invoice_number' },
    { name: 'Pharmacy Name', id: 'pharmacy_name' },
    { name: 'Pharmacy ID', id: 'pharmacy_id' },
    { name: 'Order Date (DD-MM-YYYY)', id: 'ordered_at' },
    { name: 'Flag Reason', id: 'flag_reason' },
  ];

  const handleStatusFilterChange = (value?: string): void => {
    updateStatusFilter(value);
  };
  const selectable = true;

  return (
    <>
      <TableFilterCard>
        <SearchWithCriteria
          searchCriteriaOptions={searchCriteria}
          multipleSelectCriteria={multipleSelectCriteria}
          multipleSelectPrefix={multipleSelectPrefix}
          multipleSelectSeparator={multipleSelectSeparator}
          onChangeCriteria={() => console.log('to do search criteria')}
          onSearch={() => console.log('to do search criteria')}
        />
        <ButtonToggleFilter
          data={statusFilterItems}
          label="Status"
          onChange={handleStatusFilterChange}
          value={queryParams.precursor_status as string}
        />
      </TableFilterCard>
      <ShowIf condition={!queryParams.precursor_status}>
        <OrderDataBlank />
      </ShowIf>
      <ShowIf condition={!!queryParams.precursor_status}>
        <DataTable
          config={{
            key: queryParams.precursor_status ? `${queryParams.precursor_status}-prekursor-orders` : 'prekursor-orders',
            columns,
            rowFooter,
            selectable,
            actions: {
              items: bulkActions,
              onSelect: handleBulkActionSelect,
            },
            /* buttons: {
              items: bulkActionButtons,
              onClick: handleBulkActionButton,
            }, */
            isAllSelectable: queryParams.precursor_status ? queryParams.precursor_status !== 'all' : true,
            isNoCount: true,
            isNoRow: true,
          }}
          isActionLoading={loading}
          name="orders"
          initialParams={queryParams}
          defaultActionItems={defaultActions}
        />
      </ShowIf>
    </>
  );
};

export default PrekursorApproval;
