import React from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Container } from '@material-ui/core';
import { DocumentViewer } from 'common/components';
import { FullHeightGrid } from './PreviewPage.styles';

interface IPreviewPage {
  url: string;
}

export const PreviewPage: React.FC = () => {
  const match = useRouteMatch<IPreviewPage>();
  const {
    params: { url },
  } = match;

  const location = useLocation();
  const queries = qs.parse(location.search);

  const renderUpdatedAt = (): React.ReactNode => {
    try {
      if (queries.updated_at) {
        const updatedAt = new Date(queries.updated_at as string);
        return <div style={{ marginTop: 20 }}>Last updated : {updatedAt.toString()}</div>;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }

    return null;
  };

  return (
    <Container maxWidth="md">
      <FullHeightGrid md={12}>
        <DocumentViewer url={atob(url)} />
        {renderUpdatedAt()}
      </FullHeightGrid>
    </Container>
  );
};
