import React from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';

const styles = (theme) => ({
  title: {
    fontSize: '11px',
    textTransform: 'uppercase',
    fontWeight: '500',
    color: 'gray',
    paddingBottom: '4px',
  },
  value: {
    fontSize: '12px',
    fontWeight: 400,
  },
  valueLink: {
    fontSize: '12px',
    cursor: 'pointer',
  },
  container: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
});

class ValueDetailBase extends React.Component {
  _goToLink = () => {
    const { link } = this.props;
    window.open(link, '_blank');
  };

  render() {
    const { value = '', title = '', link = '', noTitle = false, reverse = false, classes, style } = this.props;
    return (
      <Grid container wrap="nowrap" direction="column" classes={{ root: classes.container }}>
        {!reverse && !noTitle ? <Typography classes={{ root: classes.title }}>{title || '-'}</Typography> : null}
        {link === '' ? (
          <Typography classes={{ root: classes.value }} style={style}>
            {value || '-'}
          </Typography>
        ) : (
          <Typography color="primary" onClick={this._goToLink} classes={{ root: classes.valueLink }} style={style}>
            {value || '-'}
          </Typography>
        )}
        {reverse && !noTitle ? (
          <Typography classes={{ root: classes.title }} style={style}>
            {title || '-'}
          </Typography>
        ) : null}
      </Grid>
    );
  }
}
export const ValueDetail = withStyles(styles)(ValueDetailBase);
export default ValueDetail;
