import styled from 'styled-components';

const MainInputContainer = styled.div`
    flex: 1;
    position: relative;

    & .search-input {
        width: 100%;
        background: #FCFCFC;
        margin-left: 10px;
        border: none;
        outline: none;
    }

    & .criteria-suggestions {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        position: absolute;
        top: 31px;
        
        width: 400px;
        background: #fff;
        border-radius: 4px;
        z-index: 10;
        box-shadow: 0px 1px 2px rgba(0,0,0,0.15), 0px 4px 8px rgba(0,0,0,0.09), 0px 0px 1px rgba(0,0,0,0.19);

        & .suggestion {
            padding: 7px 16px;
            border-bottom: 1px solid #E9EEF4;
            
            &.selected {
                background: #eee;
            }
        }
    }
`

const SearchContainer = styled.div`
    border-radius: 7px;
    background: #FFFFFF;
    display: flex;
    flex: 1;
`

const SearchGroup = styled.div`
    display: flex;
    background: #FCFCFC;
    border: 1px solid #E3E3E3;
    width:96%;
    border-radius: 50px;
    padding: 8px 20px;
    align-items: center;

    & .magnifying-glass {
        display: flex;
        align-items: center;
    }
    
    & .controls-container {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
`

export const S = {
    SearchContainer,
    SearchGroup,
    MainInputContainer
}