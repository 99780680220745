export const defaultStyle = {
  option: (provided: React.CSSProperties) => ({
    ...provided,
    padding: '12px 20px',
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
    borderRadius: 0,
    '&:not(:last-child)': {
      borderBottom: '1px solid #ececec',
    },
  }),
  control: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 14,
    padding: '13px 10px',
    cursor: 'text',
    border: '1px solid #C6C6C6',
    borderRadius: 4,
    boxShadow: 'none',
    '&:focus-within': {
      border: '1px solid #C6C6C6',
    },
  }),
  input: (provided: React.CSSProperties) => ({
    ...provided,
    borderRadius: 0,
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
  }),
  placeholder: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
    color: '#a4a4a4',
  }),
  noOptionsMessage: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  loadingIndicator: () => ({
    display: 'none',
  }),
};

export const primaryStyle = {
  ...defaultStyle,
  option: (provided: React.CSSProperties) => ({
    ...provided,
    padding: '12px 20px',
    fontSize: 14,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 400,
    '&:not(:last-child)': {
      borderBottom: '1px solid #ececec',
    },
  }),
  control: (provided: React.CSSProperties) => ({
    ...provided,
    padding: '5px 5px',
    cursor: 'text',
    border: '2px solid #269781',
    borderRadius: 10,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#ececec',
      transition: 'ease-in 0.3s',
    },
  }),
  input: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 16,
    fontFamily: 'Nunito Sans, sans-serif',
  }),
  placeholder: (provided: React.CSSProperties) => ({
    ...provided,
    fontSize: 16,
    fontFamily: 'Nunito Sans, sans-serif',
    fontWeight: 300,
    color: '#333333',
  }),
  dropdownIndicator: () => ({
    display: 'inline-block',
    color: 'lightgray',
    padding: '5px 8px 2px 8px',
  })
}