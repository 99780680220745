import React from 'react';
import { ConditionalRender } from 'common/components';
import { CardTableAllPagesSelectionButton } from '../AllPagesSelectionButton';
import { CardTableOnAllPagesSelect } from '../../types';

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
interface Props {
  selectedAll: boolean;
  areAllPagesSelected: boolean;
  numOfSelectedRows: number | string;
  totalRowCount: number | string;
  onAllPagesSelect: CardTableOnAllPagesSelect;
  name?: string;
  isAllSelectable?: boolean;
}

export const CardTableAllPagesSelection: React.FC<Props> = ({
  selectedAll,
  areAllPagesSelected,
  numOfSelectedRows,
  onAllPagesSelect,
  totalRowCount,
  name = 'Pages',
  isAllSelectable,
}) => {
  const selectedOrdersElement = (
    <p>
      <b>{numOfSelectedRows}</b> {` ${name} are Selected.`}
    </p>
  );
  const isAllNotSelectable = isAllSelectable === false;
  return (
    <>
      <ConditionalRender condition={selectedAll && isAllNotSelectable}>{selectedOrdersElement}</ConditionalRender>
      <ConditionalRender condition={selectedAll && !areAllPagesSelected && !isAllNotSelectable}>
        <CardTableAllPagesSelectionButton
          onClick={onAllPagesSelect}
          actionInformation={`Select all ${totalRowCount} ${name}`}
          selectionValue
        >
          {selectedOrdersElement}
        </CardTableAllPagesSelectionButton>
      </ConditionalRender>
      <ConditionalRender condition={areAllPagesSelected}>
        <CardTableAllPagesSelectionButton
          onClick={onAllPagesSelect}
          actionInformation="Clear Selection"
          selectionValue={false}
        >
          <p>
            All <b>{numOfSelectedRows}</b> {` ${name} are selected`}
          </p>
        </CardTableAllPagesSelectionButton>
      </ConditionalRender>
    </>
  );
};
