import {
  CreatableAutoComplete,
  CreatableAutoCompleteProps,
} from 'common/components';
import { OrderDelayFormState } from './use-order-delay-form';

type OrderDelayFormKeys = keyof OrderDelayFormState;

interface FieldOptions {
  name: OrderDelayFormKeys;
  component: React.FC<CreatableAutoCompleteProps>;
  options?: DelayOptions[];
  label?: string;
}

interface DelayOptions {}

export const delayOptions: DelayOptions[] = [
  {
    value: 'New Pharmacy',
    label: 'New Pharmacy',
  },
  {
    value: `The PBF didn't print the invoice`,
    label: `The PBF didn't print the invoice`,
  },
  {
    value: `The PBF didn't prepare the goods`,
    label: `The PBF didn't prepare the goods`,
  },
  {
    value: `The PBF didn't operate`,
    label: `The PBF didn't operate`,
  },
];

export const fieldOptions: FieldOptions[] = [
  {
    name: 'reason',
    component: CreatableAutoComplete,
    options: delayOptions,
    label: 'Reason',
  },
];
