import React from 'react';

import Grid from '@material-ui/core/Grid';
import { ConditionalRender } from 'common/components';

import { S } from './PageStat.styles';

interface Props {
  page: number;
  pageSize: number;
  totalCount?: number;
  literal?: boolean;
  onClick?: () => void;
  isNoRow?: boolean;
  dataLength?: number;
}

export const CardTablePageStat: React.FC<Props> = ({
  page, 
  pageSize, 
  totalCount = 0, 
  literal, 
  onClick, 
  isNoRow,
  dataLength = 1,
}) => {
  if (literal) {
    const handleOnClickLiteral = (): void => {
      if (onClick) {
        onClick();
      }
    };

    const min = page === 1 ? page : page * pageSize - pageSize + 1;
    const max = totalCount && totalCount < page * pageSize
      ? totalCount
      : (page - 1) * pageSize + dataLength;
    return (
      <S.WrapperLiteral onClick={handleOnClickLiteral}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <S.TextLiteral>
              {isNoRow
                ? `${min} - ${max}`
                : `${page} - ${page + pageSize - 1}`}{' '}
              <ConditionalRender condition={!!totalCount}> of {totalCount}</ConditionalRender>
            </S.TextLiteral>
          </Grid>
        </Grid>
      </S.WrapperLiteral>
    );
  }

  const minRange = page === 1 ? 1 : pageSize * (page - 1) + 1;

  let maxRange = pageSize * page;

  if (maxRange > totalCount) {
    maxRange = totalCount;
  }

  return (
    <S.Wrapper>
      {minRange} - {maxRange} of {totalCount}
    </S.Wrapper>
  );
};
