import styled from 'styled-components';

const CheckboxCell = styled.th`
  && {
    width: 60px;
  }
`;

export const S = {
  CheckboxCell,
};
