import { SwipeRxPt, SwipeRxPtV3Resources } from '../../SwipeRxPt.class';
import { PaymentProvider, PaymentProviderParams, PaymentStatus } from './interface';
import { NewPaymentProviders } from 'common/constants';

export class SwipeRxPtPayment {
  private readonly base: SwipeRxPt;

  private readonly resource: SwipeRxPtV3Resources;

  constructor(base: SwipeRxPt) {
    this.base = base;
    this.resource = SwipeRxPtV3Resources.PAYMENT;
  }

  async fetchPaymentProviders(params: PaymentProviderParams): Promise<PaymentProvider[]> {
    const { data } = await this.base.get(`${this.resource}/payment-providers/${params.is_active}`);
    return data;
  }

  async checkPaymentStatus(provider: NewPaymentProviders, paymentId: string): Promise<PaymentStatus> {
    const { data } = await this.base.get(`${this.resource}/${provider}/check-va-status/${paymentId}`);
    return data;
  }
}
