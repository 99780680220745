

export enum FinanceActionGroupType {
  VIEW = 'View',
  EDIT = 'Edit',
  APPROVE = 'Approve',
  HOLD_TO_APPROVE = 'Hold to Approve',
  MARK_AS_DISBURSED = 'Mark as Disbursed',
  HOLD_TO_DISBURSE = 'Hold to Disburse',
  RETRY_CLAIM = 'Retry Claim'
};