import React, { useDispatch, useGlobal } from 'reactn';

import Grid from '@material-ui/core/Grid';
import ArrowRight from '@material-ui/icons/ArrowRightAlt';

import { Badge, Button, Dialog } from 'common/components';
import { formatToCurrency, formatToPercentage } from 'common/utils/formatters';
import { ColorType } from 'types';

import { BasicOrderDetails, BasicProductDetails } from '../OrderDetailsModal';

import { S } from './OrderItemFlagConfirmationModal.styles';

export const OrderItemFlagConfirmationModal: React.FC = () => {
  const [{ orderItemFlagSummary, loading }] = useGlobal('transactions');
  const setOrderItemFlagSummary = useDispatch('setOrderItemFlagSummary');
  const updateTransactionOrderItems = useDispatch('updateTransactionOrderItems');

  if (!orderItemFlagSummary) {
    return null;
  }

  const handleClose = (): void => {
    setOrderItemFlagSummary(null);
  };

  const handleSubmit = async (): Promise<void> => {
    const { id, orderItemUpdates } = orderItemFlagSummary;

    updateTransactionOrderItems({
      orderId: id,
      items: orderItemUpdates,
    });
  };

  const availableElement = (
    <Badge light color={ColorType.PRIMARY}>
      Available
    </Badge>
  );

  const stockoutElement = (
    <Badge light color={ColorType.DANGER}>
      Out of Stock
    </Badge>
  );

  const renderStockout = (stockout: boolean): JSX.Element => (stockout ? stockoutElement : availableElement);

  const renderStockoutDiff = (before: boolean, after: boolean): JSX.Element => (
    <tr>
      <td>{renderStockout(before)}</td>
      <td>
        <ArrowRight />
      </td>
      <S.DiffValueCell>{renderStockout(after)}</S.DiffValueCell>
    </tr>
  );

  const renderUnitPriceDiff = (before: number, after: number): JSX.Element => (
    <tr>
      <S.DiffValueCell>
        <S.BeforeDiffHeader>Unit Price</S.BeforeDiffHeader>
        {formatToCurrency(before)}
      </S.DiffValueCell>
      <td>
        <ArrowRight />
      </td>
      <S.DiffValueCell>
        <S.BeforeDiffHeader>New Unit Price</S.BeforeDiffHeader>
        <S.AfterDiffValue>{formatToCurrency(after)}</S.AfterDiffValue>
      </S.DiffValueCell>
    </tr>
  );

  const renderQuantityDiff = (before: number, after: number): JSX.Element => (
    <tr>
      <S.DiffValueCell>
        <S.BeforeDiffHeader>Quantity</S.BeforeDiffHeader>
        {before}
      </S.DiffValueCell>
      <td>
        <ArrowRight />
      </td>
      <S.DiffValueCell>
        <S.BeforeDiffHeader>New Quantity</S.BeforeDiffHeader>
        <S.AfterDiffValue>{after}</S.AfterDiffValue>
      </S.DiffValueCell>
    </tr>
  );

  const renderDiscountRateDiff = (before: number, after: number): JSX.Element => (
    <tr>
      <S.DiffValueCell>
        <S.BeforeDiffHeader>Discount Rate</S.BeforeDiffHeader>
        {formatToPercentage(before)}
      </S.DiffValueCell>
      <td>
        <ArrowRight />
      </td>
      <S.DiffValueCell>
        <S.BeforeDiffHeader>New Discount Rate</S.BeforeDiffHeader>
        <S.AfterDiffValue>{formatToPercentage(after)}</S.AfterDiffValue>
      </S.DiffValueCell>
    </tr>
  );

  const renderContent = (): JSX.Element => {
    const { poNumber, orderDate, changes } = orderItemFlagSummary;

    return (
      <>
        <BasicOrderDetails poNumber={poNumber} orderDate={orderDate} />
        <S.Message>You are about to change</S.Message>
        {changes.map((change) => {
          const { name, stockout, stockLimit, priceChange, discountRateChange, package: description } = change;

          return (
            <S.ItemWrapper>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                  <BasicProductDetails name={name} description={description} />
                </Grid>
                <Grid item>
                  <table>
                    <tbody>
                      {!stockout ? null : renderStockoutDiff(stockout.before, stockout.after)}
                      {!stockLimit
                        ? null
                        : stockout?.after
                        ? renderQuantityDiff(stockLimit.before, 0)
                        : renderQuantityDiff(stockLimit.before, stockLimit.after)}
                      {!priceChange ? null : renderUnitPriceDiff(priceChange.before, priceChange.after)}
                      {!discountRateChange
                        ? null
                        : renderDiscountRateDiff(discountRateChange.before, discountRateChange.after)}
                    </tbody>
                  </table>
                </Grid>
              </Grid>
            </S.ItemWrapper>
          );
        })}
      </>
    );
  };

  const renderActions = (): JSX.Element => (
    <>
      <Button isSecondary color={ColorType.DANGER} disabled={loading} onClick={handleClose}>
        Cancel
      </Button>
      <Button color={ColorType.PRIMARY} disabled={loading} loading={loading} onClick={handleSubmit}>
        Confirm Changes
      </Button>
    </>
  );

  return (
    <Dialog
      open
      primary
      maxWidth="md"
      title="Summary"
      onClose={handleClose}
      renderActions={renderActions}
      renderContent={renderContent}
      showCloseButton={false}
    />
  );
};
