import { PtAPIv2 } from '../services/apis/v2/utils';

export default {
  state: {
    invoices: {
      result: [{}],
      count: 0,
      pages: 1,
    },
    invoice: {},
  },
  reducers: {
    setInvoices(state, payload) {
      return { ...state, invoices: payload };
    },
    setInvoice(state, payload) {
      return { ...state, invoice: payload };
    },
  },
  effects: (dispatch) => ({
    async getInvoiceList({ page = 1, query = '' }, rootState) {
      try {
        const result = await PtAPIv2.admin.get(`invoices`, {
          page,
          query,
        });
        dispatch.invoiceStore.setInvoices(result);
      } catch (e) {
        console.error(e);
        alert(e.message);
      }
    },

    async getInvoiceSpecific({ id }, rootState) {
      try {
        const result = await PtAPIv2.admin.get(`invoices/${id}`);
        dispatch.invoiceStore.setInvoice(result);
      } catch (e) {
        console.error(e);
        alert(e.message);
      }
    },

    async getOverDueInvoices() {
      try {
        const result = await PtAPIv2.dashboard.get(`invoice/overdue`);
        dispatch.invoiceStore.setInvoices(result);
      } catch (e) {
        console.error(e);
        alert(e.message);
      }
    },
  }),
};
