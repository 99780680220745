import React, { useEffect, useState } from 'react';

import { ConditionalRender } from 'common/components';
import { SortOrder } from 'types';

import { CardTableSortHandler } from '../../types';
import { S } from './CardTableHeaderItem.styles';

interface ItemProps {
  initialSortOrder?: SortOrder | null;
  onSort?: CardTableSortHandler;
  path?: string;
  sort?: boolean;
  sortKey?: string;
  text?: string;
}

export const CardTableHeaderItem: React.FC<ItemProps> = ({
  initialSortOrder,
  onSort,
  path,
  sort,
  sortKey,
  text,
}) => {
  const [sortOrder, setSortOrder] = useState(initialSortOrder || null);

  useEffect(() => {
    if (sortKey !== path) {
      setSortOrder(null);
    }
  }, [path, sortKey]);

  const handleClick = (): void => {
    if (!(sort && onSort)) {
      return;
    }

    const newSortOrder =
      sortOrder === 'asc' ? 'desc' : sortOrder === 'desc' ? null : 'asc';

    const newSortKey = path as string;

    onSort(newSortKey, newSortOrder);
    setSortOrder(newSortOrder);
  };

  const active = path === sortKey && !!sortOrder;

  return (
    <S.Wrapper active={active} sort={sort} onClick={handleClick}>
      {text}
      <ConditionalRender condition={!!sort}>
        <S.SortIcon />
      </ConditionalRender>
    </S.Wrapper>
  );
};
