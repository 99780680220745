import { setSentryUser } from 'utils/Reporting/Sentry';

import authService from '../services/apis/v2/services/auth.service';

export default {
  state: {
    role: null,
    isUserRoleValid: true,
    data: localStorage.getItem('PT-ROLE-DATA')
      ? JSON.parse(localStorage.getItem('PT-ROLE-DATA'))
      : {
          id: -1,
          session_token: '',
          userType: 'anonymous',
          username: '',
        },
  },
  reducers: {
    setRole(state, payload) {
      return {
        ...state,
        role: payload.role,
        isRoleValid: payload.isUserRoleValid
      };
    },
    setData(state, payload) {
      return {
        ...state,
        data: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async authenticate(payload) {
      const returndata = await authService.login(
        payload.username,
        payload.password,
      );
      localStorage.setItem('PT-ROLE-DATA', JSON.stringify(returndata));
      setSentryUser();
      dispatch.authStore.setData(returndata);
      return returndata;
    },
    async logout(payload) {
      const returndata = await authService.logout();
      localStorage.removeItem('PT-ROLE-DATA');
      dispatch.authStore.setData({
        id: -1,
        session_token: '',
        userType: 'anonymous',
        username: '',
      });
      return returndata;
    },
  }),
};
