import React from 'react';

import { DialogProps } from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import { ConditionalRender } from 'common/components';

import { S } from './Dialog.styles';

type RenderElement = () => JSX.Element | null;

interface Props extends DialogProps {
  loading?: boolean;
  onClose: () => void;
  primary?: boolean;
  renderActions?: RenderElement;
  renderContent: RenderElement;
  showCloseButton?: boolean;
  showCloseText?: boolean;
  title: string;
  subTitle?: string;
  style?: React.CSSProperties;
  value?: string;
  closeButtonText?: string;
}

export const Dialog: React.FC<Props> = ({
  fullWidth,
  loading,
  onClose,
  primary,
  renderActions,
  renderContent,
  title,
  subTitle,
  value,
  style,
  showCloseButton = true,
  showCloseText = true,
  closeButtonText = 'Close',
  ...restProps
}) => (
  <>
    <S.Wrapper
      PaperProps={{
        style,
      }}
      onClose={onClose}
      {...restProps}
      fullWidth={loading ? false : fullWidth}
    >
      {loading ? (
        <S.LoaderWrapper>
          <CircularProgress disableShrink thickness={5} />
        </S.LoaderWrapper>
      ) : (
        <>
          <S.TitleContainer id="dialog-title-container" className="title" primary={primary} disableTypography>
            <Typography id="dialog-title" style={{ fontSize: !subTitle ? 20 : 15 }}>
              {title}
            </Typography>
            {subTitle && (
              <>
                <S.SubTitle>{subTitle}</S.SubTitle>
                <S.Text>{value}</S.Text>
              </>
            )}
            <ConditionalRender condition={showCloseButton}>
              <S.CloseButtonWrapper
                onClick={onClose}
                data-testid="dialog-close-btn"
                id="dialog-close-button"
                aria-label="close"
              >
                {showCloseText ? <span>{closeButtonText}</span> : null}
                <S.CloseButton size="small">
                  <CloseIcon />
                </S.CloseButton>
              </S.CloseButtonWrapper>
            </ConditionalRender>
          </S.TitleContainer>
          <DialogContent>{renderContent()}</DialogContent>
          {!renderActions ? null : <DialogActions>{renderActions()}</DialogActions>}
        </>
      )}
    </S.Wrapper>
  </>
);
