import { PaperSize, PaperTypeInfo } from '../interfaces';

const A4 = {
  paperSize: PaperSize.A4,
  pixel: 1122,
};

export const PAPERSIZE: Record<PaperSize, PaperTypeInfo> = {
  A4,
};
