export enum MarketingType {
  PRODUCT_LISTING = 'product-listing',
  SIMILAR_PRODUCT_LISTING = 'similar-product-listing',
  BANNER_LISTING = 'banner-listing',
  LOYALTY_BANNER_LISTING = 'loyalty-banner-listing',
  HOME_SQUARES = 'home-squares',
  RECENTLY_VIEWED_LISTING = 'recently-viewed-listing',
  REPURCHASE_LISTING = 'repurchase-listing',
  AUTO_PLAYLIST_LISTING = 'auto-playlist-listing',
  CAMPAIGN_SEARCH = 'campaign-search',
}

export enum MarketingTypeLabel {
  PRODUCT_LISTING_LABEL = 'Product Listing',
  BANNER_LISTING_LABEL = 'Banner Listing',
  HOME_SQUARES_LABEL = 'Home Squares',
  RECENTLY_VIEWED_LISTING_LABEL = 'Recently Viewed Listing',
}
