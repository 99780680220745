import { OrderDeliveryPartnerAutoComplete, OrderDeliveryPartnerAutoCompleteProps } from './order-delivery-partner-autocomplete.component';
import { OrderExportToDeliveryPartnerFormState } from './use-order-export-to-delivery-partner-form';

type OrderExportToDeliveryPartnerFormKeys = keyof OrderExportToDeliveryPartnerFormState;

export interface DeliveryPartnerOptions {
  value: number,
  label: string,
}

interface FieldOptions {
  name: OrderExportToDeliveryPartnerFormKeys
  component: React.FC<OrderDeliveryPartnerAutoCompleteProps>;
  options?: DeliveryPartnerOptions[];
  label?: string;
}

export const fieldOptions: FieldOptions[] = [
  {
    name: 'logistics_delivery_partner_id',
    label: 'Delivery Partner',
    component: OrderDeliveryPartnerAutoComplete,
  },
];
